import { useMemo } from 'react';
import styles from './SubNav.module.css';
import { NavLink } from 'react-router-dom';
import get from '../../../constants/get';

const SubNav = ({
    bottomIsWhite = false,
    // changeHeaderOnClick = false,
    headerOnClick = null,
    labelIsGradient = false,
    menuIcons = [],
    menuLabel = '',
    menuLabelHeaders = [],
    menuLabels = [],
    menuOnClicks = [],
    menuLinks = []
}) => {
    // const menuLabelsList = [].concat( ...menuLabels );

    // const [ headerLabelI, setHeaderLabelI ] = useState( !changeHeaderOnClick ? menuLabel
    //     : menuLabelsList.findIndex( label => label === menuLabel ) );
    // const [ showForm, setShowForm ] = useState( false );

    const [ isLinks, isFunctions ] = [ !!menuLinks[ 0 ], !!menuOnClicks[ 0 ] ];

    // COMPONENTS
    const MenuItems = useMemo( () => {
        const isFontAwesome = icon => typeof icon === 'string' && icon.includes( 'fa-' )
        // const toggleHeader = ({ target: { id } }) => headerOnClick
        //     || setHeaderLabelI( () => id.split( '-' ).pop() * 1 )

        const MenuItemsList = menuLabels.map( ( group, i ) => group.map( ( label, j ) => {
            const [ icon, keySuffix ] = [
                menuIcons[ i ]?.[ j ],
                !menuLabelHeaders[ i ] ? j : [ menuLabelHeaders[ i ], j ].join( '-' )
            ];

            const props = {
                menuItem: {
                    className: get.classNameFromArr([
                        !icon ? styles[ 'without-icon' ] : '',
                        isLinks ? styles.link : '',
                        styles[ 'menu-item' ],
                        bottomIsWhite && i === menuLabels.length - 1 && j === group.length - 1 ?
                            styles.frosty : '',
                    ]),
                    key: [ 'menu-item', keySuffix ].join( '-' ),
                },
                icon: { className: isFontAwesome( icon ) ? icon : styles.emoji },
            };

            props.menuItem.id = headerOnClick === null ? props.menuItem.key : menuLabels[ i ][ j ];
            props.icon.id = headerOnClick !== null ? label : [ 'menu-item-icon', keySuffix ].join( '-' );

            // if ( changeHeaderOnClick ) props.menuItem.onClick = toggleHeader
            if ( isFunctions ) props.menuItem.onClick = menuOnClicks[ i ][ j ]

            const menuItemInnerHTML = <>
                { menuIcons[ 0 ] && <i { ...props.icon }>{ isFontAwesome( icon ) ? '' : icon }</i> }
                { label }
            </>;

            return <li { ...props.menuItem }>{ isLinks ? <NavLink
                className={ ({ isActive, isPending }) => isPending ? styles[ 'pending-link' ]
                    : isActive ? styles[ 'active-link' ] : styles[ 'inactive-link' ] }
                to={ menuLinks[ i ]?.[ j ] }
                reloadDocument
            >{ menuItemInnerHTML }</NavLink> : menuItemInnerHTML
            }</li>
        } ) );

        const MenuCategories = menuLabelHeaders[ 0 ] && menuLabelHeaders.map( ( header, i ) => <li
            className={ styles.category }
            id={ `menu-category-${ i }` }
            key={ `menu-category-${ i }` }
        >
            <span>{ header }</span>
            <ul className={ styles[ 'category-items' ] }>{ MenuItemsList[ i ] }</ul>
        </li> );

        return MenuCategories || MenuItemsList

    }, [
        bottomIsWhite,
        headerOnClick,
        isFunctions,
        isLinks,
        menuIcons,
        menuLabelHeaders,
        menuLabels,
        menuLinks,
        menuOnClicks
    ] );

    // RENDER
    return <div className={ styles.subnav }>
        <header className={ labelIsGradient ? styles.gradient : '' }>
            <i className='fa-solid fa-angle-down'/>
            { menuLabel && <>&nbsp;{ menuLabel }</> }
        </header>

        <nav className={ styles.menu }>
            <ul>{ MenuItems }</ul>
        </nav>
    </div>
}

export default SubNav;