import { useContext, useEffect, useState } from 'react';
import Button from '../../pages/Button';
import AttachmentContainer from '../Attachment/AttachmentContainer';
import AttachmentInput from './Parts/AttachmentInput';
import DateInput from './Parts/DateInput';
import SelectInput from './Parts/SelectInput';
import TextInput from './Parts/TextInput';
import ToggleRadio from './Parts/ToggleRadio';
import styles from './PlanAssignmentForm.module.css';
import HiveContext from '../../../contexts/HiveContext';
import get from '../../../constants/get';
import SVG from '../Images/SVG';

const PlanAssignmentForm = ({ assignment={}, syllabus={}, saveAssignment=() => {} }) => {
    const { schoolYear } = useContext( HiveContext );

    const assignmentCategory = assignment.category
        || assignment?.id?.includes( 'prereq' ) ? 'assignment' : 'assessment';

    const [ isGraded, setIsGraded ] = useState( assignment.isGraded );
    const [ links, setLinks ] = useState( assignment.links || [] );
    const [ points, setPoints ] = useState( getInitialPoints( isGraded ) );

    useEffect( () => setLinks( () => assignment.links ), [ assignment.links ] )

    // FUNCTIONS
    function createID( suffix='' ) { return [ assignment.id, suffix ].join( '-' ) }

    function getInitialPoints( isGraded=false ) {
        return !isGraded ? 'not graded' : (
            syllabus?.assignmentCategories?.[ assignmentCategory ]?.defaultPoints
            || get.defaultSyllabus().assignmentCategories[ assignmentCategory ].defaultPoints
        )
    }

    function getButton( url='', className='', isCloseButton=false ) {
        return <Button
            className={ className }
            label={ <SVG type={{ [ isCloseButton ? 'xmark' : 'reset' ]: true }}/> }
            looksLikeLink
            onClick={ isCloseButton ? closeLinks : resetLinks }
            id={ `${ isCloseButton ? 'close' : 'reset' }-` + url }
        />
    }

    function togglePoints() {
        setIsGraded( isGraded => {
            setPoints( () => getInitialPoints( !isGraded ) )
            return !isGraded
        } )
    }

    const resetLinks = () => setLinks( () => [] )
    const closeLinks = ({ target: { id } }) => setLinks( links => {
        const url = id.split( '-' ).pop();
        return links.filter( link => link !== url )
    } );

    const updateLinks = value => links.includes( value ) || setLinks( () => [ ...links, value ] )
    const updatePoints = ({ target: { value } }) => setPoints( () => value * 1 )

    // console.log( assignment );
    // PROPS & ATTRIBUTES
    const props = {
        AttachmentContainer: {
            links,
            asSquares: true,
            className: styles[ 'attachment-container' ],
            getCloseButton: getButton,
            id: createID( '[attachments]' ),
        },
        AttachmentInput: {
            links,
            asSquares: true,
            className: styles[ 'atachment-input' ],
            attachmentPosition: 'bottom',
            id: createID( '[attachments]' ),
            passAttachmentUp: updateLinks,
        },
        DateInput: {
            schoolYear, id: createID( '[due-date]' ),
            label: 'due',
            labelPositionOn: 'left',
            dashedYearMonthDay: [
                assignment.date.due.year,
                `${ assignment.date.due.month }`.padStart( 2, '0' ),
                `${ assignment.date.due.day }`.padStart( 2, '0' ),
            ].join( '-' ),
        },
        Points: {
            className: styles.points,
            isRequired: isGraded,
            label: 'points',
            type: 'number',
            value: points,
            passValueUp: updatePoints,
            transformLabel: 'hide',
            min: 1,
        },
        TextArea: {
            className: styles.directions,
            label: 'directions',
            type: 'textarea',
            startingValue: assignment.directions || '',
            transformLabel: 'on-top',
        },
        Toggle: {
            className: styles[ 'toggle-button' ],
            groupName: createID( 'toggle-button' ),
            passValueUp: togglePoints,
            startOff: !isGraded,
        },
        SelectMenu: {
            className: styles[ 'gradebook-category' ],
            canReset: false,
            colours: [ 'wheat' ],
            // isVertical: true,
            label: 'category',
            labelPositionOn: 'top',
            options: syllabus.assignmentCategories ? Object.keys( syllabus?.assignmentCategories )
                : Object.keys( get.defaultSyllabus().assignmentCategories ),
            // startingValue: assignmentCategory,
            initialTags: [ assignmentCategory ],
            hideTypingFieldWhenTagIsSelected: true,
        },
    };

    props.SelectMenu.canReset = props.SelectMenu.canCreateOption = props.SelectMenu.canSelectMultiple = props.AttachmentContainer.showButton = props.AttachmentContainer.showCheckBox = false;
    props.SelectMenu.canTypeAnswer = props.TextArea.autofocus = props.TextArea.isRequired = props.Toggle.isVertical = props.AttachmentContainer.asSquares = props.Points.passTargetUp = true;

    props.Points.name = props.Points.id = createID( '[assignment-points]' );
    props.SelectMenu.id = props.SelectMenu.name = createID( '[gradebook-category]' );
    props.TextArea.name = props.TextArea.id = createID( '[assignment-directions]' );
    props.Toggle.ids = props.Toggle.labels = [ '', '' ];

    // COMPONENTS
    const [ Label, Points ] = [
        <span className={ styles[ 'directions-label' ] }>directions</span>,
        <fieldset className={ styles.points }>
            { !isGraded ? <span className={ styles.ungraded }>{ points }&nbsp;&nbsp;</span> : <>
                <TextInput { ...props.Points }/>
                <span className={ styles.suffix }>pt{ points === 1 ? '' : 's' }&nbsp;&nbsp;</span>
            </> }
            <ToggleRadio { ...props.Toggle }/>
        </fieldset>
    ];

    props.TextArea.label = <>
        { Label }
        { Points }
    </>;

    const [ Attach, AttachmentIcons, DueDate, SelectMenu, TextArea ] = [
        <AttachmentInput { ...props.AttachmentInput }/>,
        <AttachmentContainer { ...props.AttachmentContainer }/>,
        <DateInput { ...props.DateInput }/>,
        <SelectInput { ...props.SelectMenu }/>,
        <TextInput { ...props.TextArea }/>,
    ];

    // RENDER
    return <form className={ styles.form } onSubmit={ saveAssignment }>
        <fieldset className={ styles[ 'points-directions-date' ] }>
            { TextArea }
            <fieldset className={ styles.date }>
                { DueDate }
                <Button label={ <i className='fa-solid fa-microphone-lines'/> } type='button'/>
            </fieldset>
        </fieldset>

        <fieldset className={ styles[ 'category-and-attachments' ] }>
            { SelectMenu }
            <fieldset className={ styles.links }>
                { links.length === 2 ? AttachmentIcons : Attach }
            </fieldset>
            <Button label={ <span>save <i className='fa-solid fa-right-long'/></span> } type='submit'/>
        </fieldset>
    </form>
}

export default PlanAssignmentForm;