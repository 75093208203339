import { useState } from 'react';
import RadioButton from './RadioButton';
import styles from './RadioGroup.module.css';

const RadioGroup = ({
    classPrefix = '',
    defaultCheckedButtons = [ '' ],
    fieldLabel = '',
    fieldSuffixes = [ '', '' ],
    groupName = 'period-timing',
    isColumn = false,
    labelPositionOn = 'left',
    mainLabels = [],
    mainLabelType = 'radio',
    passValueUp = null,
    secondaryLabels = [],
    secondaryLabelTriggerI = 100,
    secondaryLabelType = 'checkbox',
}) => {
    // const [ checkedList, setCheckedList ] = useState([ ...defaultCheckedButtons ]);
    const [ show, setShow ] = useState({
        secondary: false,
        suffix: fieldSuffixes[0],
    });

    // passValueUp !== null && passValueUp( checkedList );
    secondaryLabelTriggerI = secondaryLabelTriggerI === 100 ? mainLabels.length - 1 : secondaryLabelTriggerI;

    // FUNCTIONS
    const toggleSecondary = ({ target: { id } }) => setShow( show => ({
        secondary: secondaryLabelTriggerI && id.endsWith( secondaryLabelTriggerI ),
        suffix: show.suffix === '' ? show.suffix
            : id.endsWith( secondaryLabelTriggerI ) ? ' '
            : fieldSuffixes[ id.endsWith( 0 ) ? 0 : 1 ],
    }) )

    // const updateCheckedList = ({ target: { checked, value } }) => passValueUp !== null ?
    //     passValueUp( checked, value ) : setCheckedList( checkedList => {
    //         const removeI = checked ? -1 : checkedList.findIndex( item => item === value );
    //         const newList = !checked ? [ ...checkedList.slice( 0, removeI ), ...checkedList.slice( removeI + 1 ) ]
    //             : checkedList.includes( value ) ? checkedList : [ ...checkedList, value ];

    //         return newList
    //     } )

    // PROPS & ATTRIBUTES
    const props = {
        RadioButton: {
            className: styles.button,
            type: mainLabelType,
        }
    };
    if ( secondaryLabels[ 0 ] ) props.RadioButton.toggleSecondary = toggleSecondary
    if ( passValueUp !== null ) props.RadioButton.toggleSecondary = passValueUp

    // COMPONENTS
    const Buttons = mainLabels.map( (label, i) => <RadioButton
        { ...props.RadioButton }
        defaultChecked={ defaultCheckedButtons.includes( label ) }
        groupName={ `${ classPrefix }-${ groupName }` }
        id={`${ classPrefix }-button-${ i }`}
        key={`main-button-${ i }`}
        label={ label }
    /> )

    // RENDER
    return <fieldset className={ `${ styles['radio-group'] } ${ isColumn ? styles.column : styles.row }` }>
        <fieldset className={ `${ styles.group } ${ styles.main }` }>
            { fieldLabel && <span>{ fieldLabel }</span> }
            { Buttons }
            { show.suffix && <span>{ show.suffix }</span> }
        </fieldset>
        { show.secondary && <fieldset
            className={`${ styles.group } ${ styles.secondary }`}
        >{
            secondaryLabels.map( (label, i) => <RadioButton
                groupName={ `${ classPrefix }-weekdays` }
                key={`secondary-button-${ i }`}
                label={ label }
                type={ secondaryLabelType }
            /> )
        }</fieldset> }
    </fieldset>
}

export default RadioGroup;