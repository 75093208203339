import styles from './Pricing.module.css';
import HiveNav from '../modular/Nav/HiveNav';
import CallToAction from '../modular/Buttons/CallToAction';
import ToggleButton from '../modular/Buttons/ToggleAndLabels';
import moduleInfo from '../../constants/moduleInfo';
import { useState } from 'react';

//Show 3 tiers for teachers, with a Google login & existing account toggle

const Pricing = () => {
    const [ showYearly, setShowYearly ] = useState( true );
    const { pricing } = moduleInfo;
    document.title = 'Pricing | BumbleBoard';

    const togglePricing = () => setShowYearly( showYearly => !showYearly )

    const listBenefits = (
        benefits=[],
        components=[]
    ) => components.length === benefits.length ? components
        : listBenefits( benefits, [ ...components, <li>{
            benefits[ components.length ]
        }</li> ] )

    const generateTiers = ( components=[] ) => {
        const moreTiers = Object.keys( pricing ).pop() * 1 >= components.length;
        const tier = pricing[ components.length ];

        return moreTiers ? generateTiers([ ...components, <li
            className={ styles.tier }
        >
            <h2>{ tier.title }</h2>
            <section className={`${ styles.benefits } ${ styles.teacher }`}>
                <h3>{ tier.benefits.teacher.title }</h3>
                <ul>{ listBenefits( tier.benefits.teacher.items ) }</ul>
            </section>
            <section className={`${ styles.benefits } ${ styles.student }`}>
                <h3>{ tier.benefits.student.title }</h3>
                <ul>{ listBenefits( tier.benefits.student.items ) }</ul>
            </section>
            <CallToAction
                onClick={ window.location.hostname === 'localhost' && console.log(`chose the ${ tier.title.toUpperCase() } tier`) }
                label={ tier.button.label[ showYearly ? 'yearly' : 'monthly' ] }
                isPrimary={ tier.button.isPrimary }
            />
        </li> ]) : components
    }

    return <article className='page sign-up-page' id='sign-up-page'>
        <HiveNav menu={ moduleInfo.navMenus.pricing }/>
        <h1>Pricing</h1>
        <section className={ styles['new-user'] }>
            <ToggleButton
                labels={[ 'Monthly', 'Yearly' ]}
                onClick={ togglePricing }
                showYearly={ showYearly }
            />
            <div className={ styles.tiers }><ul>{
                generateTiers()
            }</ul></div>
        </section>
    </article>
}

export default Pricing;