import { useContext, useMemo, useState } from 'react';
import Calendar from './Calendar';
import UnitsBanner from '../../../modular/Unit/UnitsBanner';
import CourseContext from '../../../../contexts/CourseContext';
import Modal from '../../../modular/Containers/Modal';
import FillBoard from '../../../modular/Form/FillBoard';
import get from '../../../../constants/get';
import UserContext from '../../../../contexts/UserContext';
import styles from './Planner.module.css';
import HiveContext from '../../../../contexts/HiveContext';
import courseInfo from '../../../../constants/courseInfo';
// import getSchoolYearObj from '../../../../constants/yearInfo';

const Planner = () => {
    const user = useContext( UserContext );
    const { course, schoolYear } = useContext( HiveContext );
    document.title = `Calendar ( ${ course.title } )`;

    const [ showModal, setShowModal ] = useState( false );
    const [ active, setActive ] = useState({ boardNum: 0, dayID: '', unit: 0 });

    const themes = {
        backgrounds: getThemeDetail( 'background' ),
        blocks: getThemeDetail( 'blocks' ),
        titles: getThemeDetail( 'theme' ),
    };

    // FUNCTIONS
    // console.log( getSchoolYearObj(
    //     [ 22, 7, 2022 ],
    //     [ 16, 5, 2023 ],
    //     'va-fairfax-county-public-schools',
    //     [
    //         { days: [ 2, 16, 26, 27 ], reasons: [] },
    //         { days: [ 20 ], reasons: [] },
    //         { days: [ 3 ], reasons: [] },
    //         { days: [ 3, 4, 5, 6, 7, 14, 21 ], reasons: [] },
    //         { days: [ 29 ], reasons: [] },
    //         { days: [ 19, 20, 21, 22, 23, 26, 27, 28, 29, 30 ], reasons: [] },
    //         { days: [ ...get.numRange( 1, 31 ) ], reasons: [] },
    //         { days: [ ...get.numRange( 1, 19 ) ], reasons: [] },
    //         { days: [ 2, 5, 26 ], reasons: [] },
    //         { days: [ 5, 10, 24, 31 ], reasons: [] },
    //         { days: [ 8, 11, 23, 24, 25 ], reasons: [] },
    //         { days: [ ...get.numRange( 19, 30 ) ], reasons: [] },
    //     ],
    //     { 9: { days: [ 12 ], reasons: [ 'PSAT' ] } },
    //     [ 1, 2, 3, 4 ],
    //     [ [ 28, 9, 2022 ], [ 25, 0, 2023 ], [ 13, 3, 2023 ], [ 16, 5, 2023 ] ]
    // ) );
    function getThemeDetail( key='', arr=[] ) {
        return !course.content.units[ arr.length ] ? arr
            : getThemeDetail( key, [ ...arr, course.content.units[ arr.length ][ key ] ] )
    }

    function toggleModal({ target: { className, id: assignmentI } }) {
        let [ dayID, boardNum ] = [
            className.includes( 'board-' ) ? className.split( 'board-' )[ 1 ] : null,
            assignmentI.split( 'board-' )[ 1 ] * 1
        ];

        dayID && setActive( active => ({ ...active, boardNum, dayID }) );
        setShowModal( () => !className.includes( 'modal-container' ) )
    }

    function toggleSidebar( boardNum=0, dayID='' ) {
        setActive( active => {
            const date = dayID.split( '-' ).slice( 1 ).join( '-' );

            return {
                ...active, boardNum,
                dayID: active.dayID.includes( date ) ? '' : dayID,
            }
        } );
    }

    function updateCourseUnits( obj={} ) {
        const newUnits = get.reorderObject( course.content.units, { ...themes, ...obj }, schoolYear );
        user.updateCourseUnits( course, newUnits );
    }

    // PROPS, CONTEXTS, AND ATTRIBUTES
    const contextValues = {
        toggleModal, toggleSidebar, // toggleModal in <Week />, toggleSidebar in <Day />
        activeBoardNum: active.boardNum, // <FillBoard /> <Sidebar />
        activeDayID: active.dayID, // <Day /> <AddAssignmentForm /> <Sidebar />
    };

    const props = {
        Modal: {
            toggleModal,
            modalClass: styles.modal,
            startingI: active.boardNum,
        },
        UnitsBanner: {
            themes,
            className: styles.banner,
            updateUser: updateCourseUnits,
        },
    };

    props.Modal.caretsLoop = props.Modal.needsCarets = true;

    // COMPONENTS
    const Boards = useMemo( () => {
        const placeholderBoards = courseInfo.getPlaceholderBoards( schoolYear );

        return placeholderBoards.map( ( placeholderBoard, i ) => {
            const board = course.content.boards[ i ];

            return typeof board?.isMergedWith !== 'number' && <FillBoard
                board={ board || placeholderBoard }
                key={ `board-filler-${ board ? board.num : placeholderBoard.num }` }
            />
        } ).filter( e => e );

    }, [ course.content.boards, schoolYear ] );

    // RENDER
    return <CourseContext.Provider value={ contextValues }>
        <article className={ styles.planner }>
            <section className={ styles[ 'calendar-container' ]  }>
                { user.data.uid === course.ownerID && <UnitsBanner { ...props.UnitsBanner }/> }
                <Calendar className={ styles.calendar }/>
                { showModal && <Modal { ...props.Modal } modalChildren={ Boards } /> }
            </section>
        </article>
    </CourseContext.Provider>
}

export default Planner;