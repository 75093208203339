import { useContext, useEffect, useState } from 'react';
import UserContext from '../../../contexts/UserContext';
import Button from '../Button';
import styles from './Admin.module.css';
import get from '../../../constants/get';
import Nav from '../Nav';
import Calendar from './Calendar/Calendar';
import County from './County/County';
import RadioGroup from '../../modular/Form/Parts/RadioGroup';
import SVG from '../../modular/Images/SVG';
import Wrapper from '../Wrapper';
import firePush from '../../../services/edding/pushFireData';
import fireRead from '../../../services/edding/readFireData';
import { collection, getDocs } from 'firebase/firestore';
import { db } from '../../../firebase_setup/firebase';
// import TextInputButton from '../TextInputButton';
import AddCountyForm from './AddCountyForm/AddCountyForm';
import courseInfo from '../../../constants/courseInfo';
import Units from './Units/Units';
// import Tree from './Tree/Tree';

const adminPages = {
    calendar: {
        icon: <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32">
            <g fill="none">
                <path fill="#B4ACBC" d="m2 9l13.267-2.843a3.5 3.5 0 0 1 1.466 0L30 9v15.8a5.2 5.2 0 0 1-5.2 5.2H7.2A5.2 5.2 0 0 1 2 24.8V9Z"/>
                <path fill="#F3EEF8" d="M3 10.884V24.5A4.5 4.5 0 0 0 7.5 29h16l5.5-5.674V10.884L16.464 8.5L3 10.884Z"/>
                <path fill="orange" d="M8.2 13a.2.2 0 0 0-.2.2v2.6c0 .11.09.2.2.2h2.6a.2.2 0 0 0 .2-.2v-2.6a.2.2 0 0 0-.2-.2H8.2Zm6.5 0a.2.2 0 0 0-.2.2v2.6c0 .11.09.2.2.2h2.6a.2.2 0 0 0 .2-.2v-2.6a.2.2 0 0 0-.2-.2h-2.6Zm6.3.2c0-.11.09-.2.2-.2h2.6c.11 0 .2.09.2.2v2.6a.2.2 0 0 1-.2.2h-2.6a.2.2 0 0 1-.2-.2v-2.6ZM8.2 18a.2.2 0 0 0-.2.2v2.6c0 .11.09.2.2.2h2.6a.2.2 0 0 0 .2-.2v-2.6a.2.2 0 0 0-.2-.2H8.2Zm6.3.2c0-.11.09-.2.2-.2h2.6c.11 0 .2.09.2.2v2.6a.2.2 0 0 1-.2.2h-2.6a.2.2 0 0 1-.2-.2v-2.6ZM8.2 23a.2.2 0 0 0-.2.2v2.6c0 .11.09.2.2.2h2.6a.2.2 0 0 0 .2-.2v-2.6a.2.2 0 0 0-.2-.2H8.2Zm6.3.2c0-.11.09-.2.2-.2h2.6c.11 0 .2.09.2.2v2.6a.2.2 0 0 1-.2.2h-2.6a.2.2 0 0 1-.2-.2v-2.6Zm6.7-.2a.2.2 0 0 0-.2.2v2.6c0 .11.09.2.2.2h2.6a.2.2 0 0 0 .2-.2v-2.6a.2.2 0 0 0-.2-.2h-2.6Zm8.81 3.24V23l-4.51 2.5l-2.49 4.5h3.24c2.08 0 3.76-1.69 3.76-3.76Z"/>
                <path fill="#E1D8EC" d="M23.01 26.76V30l7-7h-3.24c-2.07 0-3.76 1.69-3.76 3.76Z"/>
                <path fill="orangered" d="M30 8.785V11H2V8.785C2 6.691 3.69 4.99 5.759 5H26.24C28.311 5 30 6.691 30 8.785ZM21.2 18a.2.2 0 0 0-.2.2v2.6c0 .11.09.2.2.2h2.6a.2.2 0 0 0 .2-.2v-2.6a.2.2 0 0 0-.2-.2h-2.6Z"/>
                <path fill="#CDC4D6" d="M8 6.696C8 7.418 8.558 8 9.25 8s1.25-.582 1.25-1.304V3.304C10.5 2.582 9.942 2 9.25 2S8 2.582 8 3.304v3.392Zm13.5 0c0 .722.558 1.304 1.25 1.304S24 7.418 24 6.696V3.304C24 2.582 23.442 2 22.75 2s-1.25.582-1.25 1.304v3.392Z"/>
            </g>
        </svg>,
    },
    county: {
        icon: <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 24 24">
            <path fill='goldenrod' d="M3 13.037c0-1.103 0-1.655.393-1.976c.139-.114.308-.206.497-.269c.532-.177 1.231-.002 2.629.346c1.067.267 1.6.4 2.14.386c.198-.005.395-.025.588-.059c.525-.093.993-.326 1.929-.793l1.382-.69c1.2-.599 1.799-.898 2.487-.967c.688-.069 1.372.102 2.739.443l1.165.29c.99.247 1.485.371 1.768.665c.283.294.283.685.283 1.466v6.084c0 1.103 0 1.655-.393 1.976a1.563 1.563 0 0 1-.497.269c-.532.177-1.231.003-2.629-.346c-1.067-.267-1.6-.4-2.14-.386a3.951 3.951 0 0 0-.588.059c-.525.093-.993.326-1.929.793l-1.382.69c-1.2.599-1.799.898-2.487.967c-.688.069-1.372-.102-2.739-.443l-1.165-.29c-.99-.247-1.485-.371-1.768-.665C3 20.293 3 19.902 3 19.121v-6.084Z" opacity=".5"/>
            <g fill="orangered">
                <path fillRule="evenodd" d="M12 2C8.686 2 6 4.552 6 7.7c0 3.124 1.915 6.769 4.903 8.072a2.755 2.755 0 0 0 2.194 0C16.085 14.47 18 10.824 18 7.7C18 4.552 15.314 2 12 2Zm0 8a2 2 0 1 0 0-4a2 2 0 0 0 0 4Z" clipRule="evenodd"/>
            </g>
        </svg>,
    },
    courses: {
        icon: <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 24 24">
            <path fill="orangered" d="M23 2H1a1 1 0 0 0-1 1v18a1 1 0 0 0 1 1h22a1 1 0 0 0 1-1V3a1 1 0 0 0-1-1m-1 18h-2v-1h-5v1H2V4h20v16M10.29 9.71A1.71 1.71 0 0 1 12 8c.95 0 1.71.77 1.71 1.71c0 .95-.76 1.72-1.71 1.72s-1.71-.77-1.71-1.72m-4.58 1.58c0-.71.58-1.29 1.29-1.29a1.29 1.29 0 0 1 1.29 1.29c0 .71-.58 1.28-1.29 1.28c-.71 0-1.29-.57-1.29-1.28m10 0A1.29 1.29 0 0 1 17 10a1.29 1.29 0 0 1 1.29 1.29c0 .71-.58 1.28-1.29 1.28c-.71 0-1.29-.57-1.29-1.28M20 15.14V16H4v-.86c0-.94 1.55-1.71 3-1.71c.55 0 1.11.11 1.6.3c.75-.69 2.1-1.16 3.4-1.16c1.3 0 2.65.47 3.4 1.16c.49-.19 1.05-.3 1.6-.3c1.45 0 3 .77 3 1.71Z"/>
        </svg>
    },
    school: {
        icon: <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 128 128">
            <path fill="#F8C778" d="M6.33 66.37v57.72h116.06V66.15S99.17 50.13 98.1 49.98c-1.07-.15-32.26-28.7-32.26-28.7L31.46 49.01L6.33 66.37z"/>
            <path fill="#F5B03F" d="M6.33 66.31h27.4l-.03 57.72h4.55l.03-70.27L65.16 30.1l27.12 24.8l-.07 69.13h4.26l.07-58.05l25.85.18v-3.45L98.81 50.07L67.27 22.36L32.45 49.5L6.35 63.01z"/>

            <g className='roofAndDoorFrame' fill='#A62714'>
                <path className='roof' d="M13.1 42.57c-.18.29-7.15 19.51-7.28 19.91c-.13.4.2.87 1 .94c.8.07 28.39 0 28.39 0l-.08-11.78s29.62-26.66 29.95-26.66s30.18 27.35 30.25 27.76c.07.4.13 10.22.13 10.22s26.25.2 26.79.27s.67-.67.2-2.07c-.47-1.4-5.95-18.3-6.35-18.57c-.4-.27-22.04-.27-22.04-.27S66.95 17.36 66.48 16.96c-.47-.4-1.87-1.01-3.11.23c-1.09 1.09-28.13 25.33-28.13 25.33s-21.94-.29-22.14.05z"/>
                <path className='doorFrame' d="m43.91 80.59l-.01 43.48h42.36V80.59s-42.35-.11-42.35 0z"/>
            </g>

            <path className='bushes' fill="green" d="M3.64 124.25c.37.56 4.69.3 16.2.36c11.51.06 14.86.04 15.46-.08c.59-.12 1.07-2.71.68-4.96c-.43-2.47-1.91-4.8-4.31-5.55c-3.21-1-5.19-.08-6.73-.62c-1.54-.53-6.05-2.79-10.62-2.49c-3.92.25-6.71 1.4-9.14 4.98c-2.61 3.85-1.78 8-1.54 8.36zm122.45-.24c.59-.41.54-11.77-7.36-12.64c-5.93-.65-7.48 1.9-8.48 1.9c-1.01 0-4.85-1.87-10.15.18c-6.59 2.55-6.47 9.97-6.23 10.68s7.06.53 16.43.59s15.02-.17 15.79-.71z"/>

            <g className='windows' fill='gold'>
                <g className='frames' fill='orange'>
                    <path d="M11.57 69.63h18.69v15.05H11.32s.12-14.93.25-15.05zm-.25 22.08h18.61v14.56H11.16c0 .01.08-14.64.16-14.56z"/>
                    <path d="M99.67 70.52h18.61v14.97H99.59s.08-15.21.08-14.97z"/>
                    <path d="M99.43 92.6h18.53v14.16H99.43s-.12-14.03 0-14.16z"/>
                    <path d="m50.06 124.09l-.01-38.76h30.5v38.76H50.06z"/>
                    <path fill="orange" d="M64.28 94.65v26.11h2.64V94.38s-2.46.27-2.64.27zm.18-54.59c-10.37.09-17.32 7.82-16.79 17.76c.51 9.66 8.18 15.72 17.05 15.47c9.32-.26 16-7.74 16.26-16.61s-6.94-16.7-16.52-16.62z"/>
                </g>
                <g className='glass' fill='hsl( 45, 100%, 90% )'>
                    <path d="M14.4 72.38h12.7v9.95H14.24s-.09-9.87.16-9.95zm-.57 21.76h13.43v9.63H13.83v-9.63z"/>
                    <path d="M102.26 73.11h13.35v9.71h-13.35v-9.71z"/>
                    <path d="M102.34 95.19v8.74h12.7v-8.82s-12.82-.04-12.7.08z"/>
                    <path d="M76.94 93.24v-5.1H53.82v5.1h23.12zm-23.12 2.55h23.12v13.27H53.82zm0 16h23.12v6.94H53.82z"/>
                </g>
            </g>

            <g className='clock'>
                <path fill="#FFF" d="M64.2 43.05c-8.44 0-14.07 6.42-13.71 14.15c.34 7.46 5.36 13.1 13.98 13.1c7.91 0 13.11-6.5 13.27-13.19c.17-7.3-5.28-14.06-13.54-14.06z"/>
                <path fill="#2F2F2F" d="M61.74 57.46s-4.9 4.12-5.17 4.6s-.26 1.27.39 1.8c.66.53 1.31.22 1.71-.09c.39-.31 5.56-4.55 5.56-4.55s1.67-.03 2.06-2.01c.31-1.58-.83-2.32-.83-2.32s-.18-9.46-.26-10.16c-.07-.52-.61-1.01-1.09-.96c-.48.04-.96.31-1.01 1.09c-.05.78-.1 9.84-.1 9.84s-.7.26-1.09 1.01c-.39.74-.17 1.75-.17 1.75z"/>
            </g>
        </svg>,
    },
    units: {
        icon: <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 256 256">
            <path d="M48 72h64v112H48Zm142.64-33.61a8 8 0 0 0-9.5-6.21l-46.81 10a8.07 8.07 0 0 0-6.15 9.57L139.79 107l62.46-13.42Z" fill='orange'/>
            <path fill="orangered" d="m231.65 194.55l-33.19-157.8a16 16 0 0 0-19-12.39l-46.81 10.06a16.08 16.08 0 0 0-12.3 19l33.19 157.8A16 16 0 0 0 169.16 224a16.25 16.25 0 0 0 3.38-.36l46.81-10.06a16.09 16.09 0 0 0 12.3-19.03ZM136 50.15v-.09l46.8-10l3.33 15.87L139.33 66Zm6.62 31.47l46.82-10.05l3.34 15.9L146 97.53Zm6.64 31.57l46.82-10.06l13.3 63.24l-46.82 10.06ZM216 197.94l-46.8 10l-3.33-15.87l46.8-10.07l3.33 15.85v.09ZM104 32H56a16 16 0 0 0-16 16v160a16 16 0 0 0 16 16h48a16 16 0 0 0 16-16V48a16 16 0 0 0-16-16ZM56 48h48v16H56Zm0 32h48v96H56Zm48 128H56v-16h48v16Z"/>
        </svg>,
    },
    users: {
        icon: <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 256 256">
            <path fill='orange' d="M136 108a52 52 0 1 1-52-52a52 52 0 0 1 52 52Z" opacity=".5"/>
            <g fill="orangered">
                <path d="M117.25 157.92a60 60 0 1 0-66.5 0a95.83 95.83 0 0 0-47.22 37.71a8 8 0 1 0 13.4 8.74a80 80 0 0 1 134.14 0a8 8 0 0 0 13.4-8.74a95.83 95.83 0 0 0-47.22-37.71ZM40 108a44 44 0 1 1 44 44a44.05 44.05 0 0 1-44-44Zm210.14 98.7a8 8 0 0 1-11.07-2.33A79.83 79.83 0 0 0 172 168a8 8 0 0 1 0-16a44 44 0 1 0-16.34-84.87a8 8 0 1 1-5.94-14.85a60 60 0 0 1 55.53 105.64a95.83 95.83 0 0 1 47.22 37.71a8 8 0 0 1-2.33 11.07Z"/>
            </g>
        </svg>,
    },
    // tree: { icon: '🎄' }
};

const Admin = () => {
    const user = useContext( UserContext );
    const isBumbleBoard = user.email.startsWith( 'bumbleboard@' );

    const [ counties, setCounties ] = useState( user.counties );
    const [ activePage, setActivePage ] = useState( !isBumbleBoard && counties.length !== 1 && 'county' );
    const [ county, setCounty ] = useState( activePage && counties[ 0 ] );
    const [ showAddCountyForm, setShowAddCountyForm ] = useState( false );
    // console.log( counties, county );

    // Pull all counties if isBumbleBoard
    useEffect( () => {
        if ( isBumbleBoard ) {
            const countiesArr = [];
            async function findAllCounties() {
                try {
                    await getDocs( collection( db, 'counties' ) ).then( querySnapshot => {
                        querySnapshot.forEach( county => countiesArr.push( county.data() ) );
                    } );

                    countiesArr.forEach( county => {
                        Promise.all([
                            fireRead.findAllSchoolsInCounty( county.id ),
                            fireRead.countyCollections({ countyID: county.id, schoolYearID: get.schoolYearID() })
                        ]).then( schoolsAndSchoolYear => {
                            [ county.schools, county.schoolYear ] = schoolsAndSchoolYear;
                            if ( !county.schools ) county.schools = {}

                            if ( !county.schoolYear ) county.schoolYear = courseInfo.getSchoolYearObj(
                                [ 21, 7, 2023 ],
                                [ 12, 5, 2024 ],
                                county.id,
                                {},
                                {},
                                {},
                                [ 1, 2, 3, 4 ], [
                                    [ 3, 10, 2023 ],
                                    [ 25, 0, 2024 ],
                                    [ 22, 2, 2024 ],
                                    [ 12, 5, 2024 ],
                                ]
                            )
                        })
                    } )
                } catch( error ) { console.log( error ) }
            }

            // findAllCounties().then( countiesArr => setCounties( () => countiesArr ) )
            findAllCounties().then( () => setCounties( () => countiesArr ) )
        }
        // fireRead.findAllCounties().then( counties => setCounties( () => counties ) )
    }, [ isBumbleBoard ] )

    useEffect( () => { county && get.log({
        fileName: 'Admin.js',
        functionDirectionsOrComponentName: '{ county } state',
        lineNumber: 144,
        str: county.obj
    }) }, [ county ] );

    // Push counties to database
    useEffect( () => {
        if ( county ) {
            if ( !Array.isArray( county.obj.schoolYear.months ) ) county.obj.schoolYear.months = Object.values( county.obj.schoolYear.months )

            county.obj.schoolYear.months.forEach( ( monthObj, i ) => {
                const monthHolidays = county.obj.schoolYear.holidays[ i ];
                const monthHasDaysOff = !!monthHolidays?.days?.[ 0 ];
                if ( !monthHasDaysOff ) return

                Object.entries( monthObj.weeks ).forEach( weekIAndObj => {
                    const [ weekI, weekObj ] = weekIAndObj;
                    const weekHasDaysOff = monthHolidays.days.some(
                        dayNum => weekObj.days.includes( dayNum )
                    );

                    if ( weekHasDaysOff ) county.obj.schoolYear.months[ i ].weeks[ weekI ].daysInClass = weekObj.days.filter( dayNum => monthObj.days.all.includes( dayNum ) )
                } )
            } )
            firePush.pushCounty([ county.obj ])
        }
    }, [ county ] )

    // const [ todayDay, todayMonth, todayYear ] = get.dayMonthYearToday();
    // console.log( '🐝', county );

    // FUNCTIONS
    const addCounty = ( newCounty={} ) => {
        const countyAlreadyExists = counties.find( countyObj => countyObj.id === newCounty.id )
        if ( !countyAlreadyExists ) {
            setCounties( counties => [ ...counties, county ] )
            setCounty( () => newCounty )
            activePage( 'county' )
            showAddCounty()
        } else {
            window.location.hostname === 'localhost' && console.log( 'County Already Exists' )
        }
    }

    const showAddCounty = () => setShowAddCountyForm( showAddCountyForm => !showAddCountyForm )

    const changeCounty = ({ target: { checked, value } }) => {
        window.location.hostname === 'localhost' && console.log( checked, value );
        if ( checked ) {
            value = get.capitalise( value );
            const i = counties.findIndex( county => county.name.full === value )
            window.location.hostname === 'localhost' && console.log( value, i, counties[ i ] );
            setCounty( () => ({ i, obj: counties[ i ] }) )
            setActivePage( activePage => activePage || 'county' )
        }
    }

    const chooseCounty = () => setActivePage( () => null )

    const togglePage = ({ target: { id } }) => setActivePage( () => id.split( '-' )[ 0 ] )

    const updateCounty = ( keys=[ '' ], values=[ {} ] ) => setCounty( county => {
        const { obj: oldObj } = county;
        keys.forEach( ( key, i ) => oldObj[ key ] = values[ i ] );
        setCounties( counties => counties.map( countyObj => countyObj.id === county.id ? oldObj : countyObj ) )

        return { i: county.i, obj: oldObj }
    } )

    // RENDER
    return <article className={ styles.console }>
        <Nav
            middleMenu={ <>
                <h1>{ get.capitalise( activePage || 'admin console' ) }</h1>

                { activePage && <Wrapper
                    div={ !( !user.email.startsWith( 'bumbleboard@' ) && counties.length !== 1 ) }
                    props={{ className: styles.subtitle }}
                    Children={ <>
                        { !( !user.email.startsWith( 'bumbleboard@' ) && counties.length !== 1 ) && <Button
                            label={ <SVG type={{ edit: true }} fontSize='.5em'/> }
                            onClick={ chooseCounty }
                        /> }
                        <p>{ county.obj.name.full }</p>
                    </> }
                /> }
            </> }
            rightIcon={ <Button isLink label='BACK' linkTarget={ '/' + get.username( user.data.email ) }/> }
        />

        <header className={ styles.header }>
            { activePage && <nav className={ styles[ 'page-tabs' ] }>
                <ul>{ Object.keys( adminPages ).map( page => <li
                    className={ styles.button }
                    key={ page + '-admin-page' }
                ><Button
                    id={ page + '-button' }
                    label={ adminPages[ page ].icon }
                    onClick={ togglePage }
                    hoverColours={[ 'gold', 'yellow' ]}
                    hoverTextColour='black'
                /></li> ) }</ul>
            </nav> }
        </header>

        { showAddCountyForm ? <AddCountyForm
            addCounty={ addCounty }
            hideForm={ showAddCounty }
        /> : !activePage ? <form onChange={ changeCounty }>
            <RadioGroup
                classPrefix='county-to-select'
                fieldLabel='select a county'
                groupName='counties'
                isColumn
                mainLabelType='checkbox'
                mainLabels={ counties.map( county => county.name.full ) }
            />
            { isBumbleBoard && <Button
                label={ <><SVG type={{ add: true }}/> ADD COUNTY</> }
                type='button'
                onClick={ showAddCounty }
            /> }
        </form> : activePage === 'calendar' ? <Calendar county={ county.obj } updateSchoolYear={ updateCounty }/>
            : activePage === 'county' ? <County county={ county.obj } updateCounty={ updateCounty }/>
            : activePage === 'courses' ? 'courses'
            : activePage === 'school' ? 'school'
            // : activePage === 'tree' ? <Tree />
            : activePage === 'units' ? <Units county={ county.obj }/> : 'users'
        }
    </article>
}

export default Admin;