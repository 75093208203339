import { useEffect, useState } from 'react';
import styles from './Gandalf.module.css';
import TextInput from '../../../modular/Form/Parts/TextInput';
import Ellipsis from '../../../modular/Loaders/Ellipsis';
import fireRead from '../../../../services/edding/readFireData';
import { Outlet, useLocation, useNavigate, useParams } from 'react-router-dom';
import get from '../../../../constants/get';

const AccessCourse = ({ schoolYearID='', updateUser=() => {} }) => {
    const { id: courseID } = useParams();
    const location = useLocation();
    const navigate = useNavigate();

    const [ hive, setHive ] = useState({ counties: [], courses: [], queen: {} });
    const [ isValid, setIsValid ] = useState({ email: null, validEmailStr: '' });

    // Shake input if email does not belong to any worker bee in this hive
    useEffect( () => {
        if ( isValid.email === false ) {
            const timer = setInterval( () => setIsValid( isValid => ({ ...isValid, email: null }) ), 1000 );
            isValid.email === null && clearInterval( timer );

            return () => clearInterval( timer )
        }
    }, [ isValid ] )

    // Update user state & redirect us to the worker hive
    useEffect( () => {
        const getGuestUserObj = async () => await fireRead.loopSpecificCourseCollections(
            hive.courses[ 0 ].id.db, schoolYearID
        )

        if ( isValid.email && hive.courses[ 0 ].id.db ) getGuestUserObj().then( course => {
            const profileInfo = course.content.students.all.find( student => student.email === isValid.validEmailStr );

            updateUser({
                ...profileInfo, schoolYearID,
                courses: [{ ...hive.courses[ 0 ], content: course.content }],
                counties: hive.counties,
                courseIDs: [ course.id.db ],
                schoolIDs: Object.keys( hive.counties[ 0 ].schools ),
                data: { uid: null, isGuest: true },
                email: isValid.validEmailStr,
            });

            navigate( !profileInfo.nickname ? [ '', 'mrsbates', 'course', course.id.db, 'about-you' ].join( '/' )
                : location?.state?.from ? location.state.from.pathname : `/workerbee/${ hive.courses[ 0 ].id.db }` )
        } )
    }, [ hive, isValid, location, navigate, schoolYearID, updateUser ] )

    // Update hive state one at a time
    useEffect( () => {
        async function pullFromFirestore({ getHive=false, getQueen=false }) {
            getHive ? await fireRead.findCourseByID( courseID, [], schoolYearID ).then(
                course => setHive( hive => ({ ...hive, courses: [ course ] }) )
            ) : getQueen ? await fireRead.findUserByID( hive.courses[ 0 ].ownerID ).then(
                queen => setHive( hive => ({ ...hive, queen }) )
            ) : await fireRead.findCountyBySchoolID( hive.courses[ 0 ]?.schoolID, schoolYearID ).then(
                county => setHive( hive => ({ ...hive, counties: [ county ] }))
            );
        }

        if ( !hive.courses[ 0 ] ) pullFromFirestore({ getHive: true })
        if ( !hive.counties[ 0 ] && hive.courses[ 0 ]?.schoolID ) pullFromFirestore({})
        if ( 'email' in hive.queen === false && hive.courses[ 0 ] ) pullFromFirestore({ getQueen: true })
    }, [ hive, courseID, schoolYearID ] );

    const [ queenBeeTitle, queenBeeLastName ] = [ hive.queen?.name?.title, hive.queen?.name?.last ];
    const queenBee = ( queenBeeTitle && queenBeeLastName ) && get.classNameFromArr([
        queenBeeTitle, queenBeeLastName
    ]);
    if ( hive.queen?.title ) document.title = `${ hive.queen.title } beekeeper`;

    // FUNCTION
    function validateEmail( e ) {
        e.preventDefault();

        const email = Array.from( e.target.elements ).filter( el => el.id )[ 0 ].value;
        const isValidEmail = hive.courses[ 0 ]?.guests?.includes( email );

        setIsValid( () => ({ email: isValidEmail, validEmailStr: email }) );
    }

    // PROPS & ATTRIBUTES
    const props = {
        textInput: {
            className: styles.input,
            label: 'email',
            transformLabel: 'hide',
            styleVariables: { '--padding-left': '2.25rem' }
        },
        div: { className: get.classNameFromArr([
            styles.container,
            isValid.email ? styles.accessed : isValid.email === false ? styles[ 'not-accessed' ] : ''
        ]) }
    };

    props.textInput.autofocus = props.textInput.isRequired = true;
    props.textInput.id = props.textInput.name = 'worker-bee-email';

    // COMPONENTS
    const Message = <p>{ isValid.email === false ?
        `${ get.username( isValid.validEmailStr ) } who ...?` : isValid.email ? <>
            Oh hi, { get.username( isValid.validEmailStr ) }
            &nbsp;<i className='fa-solid fa-spinner fa-spin-pulse'/>
        </> : <Ellipsis />
    }</p>

    // RENDER
    return <div { ...props.div }>
        <form className={ styles.form } onSubmit={ validateEmail }>
            <header>
                <h1>{ hive.courses[ 0 ]?.title || <Ellipsis /> }</h1>
                <p>with <span>{ queenBee || <Ellipsis /> }</span></p>
            </header>
            <fieldset className={ styles[ 'input-field' ] }>{
                hive.courses?.[ 0 ]?.title && hive.queen?.email ? <ul className={ styles[ 'knock-knock' ] }>
                    <li><i className='fa-regular fa-circle-user'/><span>Knock knock !</span></li>
                    <li><i>🐝</i><span>Who's there ?</span></li>
                    <li className={ styles[ 'input-li' ] }>
                        <i className={ [ styles[ 'input-icon' ], 'fa-regular fa-circle-user' ].join(' ') }/>
                        <TextInput { ...props.textInput }/>
                    </li>
                    <li className={ styles.message }><i>🐝</i>{ Message }</li>
                </ul> : <p>One second <Ellipsis /></p>
            }</fieldset>
        </form>
        <Outlet />
    </div>
}

export default AccessCourse;