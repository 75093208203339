import styles from './Ellipsis.module.css';

const Ellipsis = () => {
    return <span className={ styles.dots }>
        <span className={ styles.dot }/>
        <span className={ styles.dot }/>
        <span className={ styles.dot }/>
    </span>
}

export default Ellipsis;