import { useState } from 'react';
import Button from './Button';
import Modal from './Modal';
import styles from './ModalContainer.module.css';
import get from '../../constants/get';

const ModalContainer = ({
    ButtonID = '',
    ButtonLabel = null,
    props = { modal: {}, button: {} },
    Children = <></>,
    isLocked = false,
    isOpenFromParent = false,
    maxHeightPercent = 0,
    maxWidthPercent = 0,
    position = {
        top: false,
        right: false,
        bottom: false,
        left: false,
    },
    toggleModalFromParent = null,
    updateParentState = null,
}) => {
    const [ isOpen, setIsOpen ] = useState( false );
    const openState = isOpenFromParent === null ? isOpen : isOpenFromParent;

    // openState && console.log(
    //     `%c EDITING FROM ${ ButtonID.split( '-' ).slice( 2, 4 ).join( ' #' ).toLocaleUpperCase() } \n ${ Children.props.assignments[ Children.props.activeSquare ].title } `,
    //     `background: #f2d338; color: #000; padding: 3px; border-radius: 3px`
    // );

    // FUNCTIONS
    function getPosition() { return Object.keys( position ).find( key => position[ key ] ) || 'centre' }

    function toggleModal() {
        if ( toggleModalFromParent === null ) {
            if ( updateParentState !== null ) updateParentState({ target: { id: openState ? '' : ButtonID } })
            if ( isOpenFromParent === null ) setIsOpen( isOpen => !isOpen )
        } else { toggleModalFromParent() }
    }

    // PROPS & ATTRIBUTES
    if ( props?.button ) {
        props.button.id = ButtonID;
        props.button.label = ButtonLabel;
        props.button.onClick = toggleModalFromParent || toggleModal;

        if ( !props?.button?.className?.includes( styles.button ) ) props.button.className = get.classNameFromArr([
            props?.button?.className, styles.button
        ])
    }

    if ( props?.modal ) {
        props.modal.Children = Children;
        props.modal.isLocked = isLocked;
        props.modal.isOpen = openState;
        props.modal.position = getPosition();
        props.modal.toggleModal = toggleModalFromParent || toggleModal;

        if ( maxHeightPercent > 0 ) props.modal.maxHeight = maxHeightPercent + '%'
        if ( maxWidthPercent > 0 ) props.modal.maxWidth = maxWidthPercent + '%'
    }

    // RENDER
    return <>
        <Modal { ...props.modal }/>
        { ButtonID && ButtonLabel && <Button { ...props.button }/> }
    </>
}

export default ModalContainer;