import ToggleButton from './ToggleButton';
import styles from './ToggleAndLabels.module.css';

const ToggleAndLabels = ({ labels=[], onClick=() => {}, showYearly=true }) => {
    const [ leftLabel, rightLabel ] = labels;
    const [ buttonClass, backgroundClass ] = [
        `${styles.button} ${ showYearly ? styles['on-right'] : styles['on-left'] }`,
        `${styles.toggle} ${ showYearly ? styles.active : styles.inactive }`
    ];

    return <div className={ styles['toggle-content'] }>
        <span className={`${styles.label} ${styles.left} ${ showYearly ? styles.inactive : styles.active }`}>{
            leftLabel
        }</span>
        <ToggleButton
            backgroundClass={ backgroundClass }
            buttonClass={ buttonClass }
            toggle={ onClick }
        />
        <span className={`${styles.label} ${styles.right} ${ showYearly ? styles.active : styles.inactive }`}>{
            rightLabel
        }</span>
    </div>
}

export default ToggleAndLabels;