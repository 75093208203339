import { useContext, useState } from 'react';
import Hamburger from '../modular/Buttons/Hamburger';
import AnimatedLogo from '../modular/Images/AnimatedLogo';
import styles from './Nav.module.css';
import UserIcon from '../modular/Images/UserIcon';
import UserContext from '../../contexts/UserContext';
import get from '../../constants/get';
import { Link } from 'react-router-dom';

const Nav = ({
    brandLinkTo = '',
    centreBrand = false,
    leftIcon = null,
    leftElement = null,
    leftElementIsNOTGradient = false,
    middleMenu = null,
    rightElement = null,
    rightIcon = null,
    menuIcons = [],
    menuLabels = [],
    menuOnClicks = [],
}) => {
    const [ showMenu, setShowMenu ] = useState( false );
    const user = useContext( UserContext );

    // FUNCTIONS
    const toggleSubMenu = () => setShowMenu( showMenu => !showMenu )

    // PROPS & ATTRIBUTES
    const props = {
        icons: [
            'fa-solid fa-hand-peace',
            'fa-solid fa-chalkboard-user',
            <div className={ styles[ 'img-container' ] }>
                <img alt='' src='https://em-content.zobj.net/thumbs/240/toss-face/342/crown_1f451.png'/>
            </div>
        ],
        menuLabels: [ 'about us', 'our pedagogy', 'pricing' ],
        menuOnClicks: [ () => {}, () => {}, () => {} ],
        subMenu: {
            className: get.classNameFromArr([ styles[ 'sub-menu' ], showMenu ? styles.show : styles.hide ])
        }
    }

    // COMPONENTS
    const BrandName = !brandLinkTo ? <span className={ styles.brand }>BumbleBoard</span>
        : <Link to={ brandLinkTo } className={ styles.brand }>BumbleBoard</Link>

    const SubMenu = <nav { ...props.subMenu }>
        <ul>{ menuOnClicks.map( ( func, i ) => {
            const icon = menuIcons[ i ];
            const isFontAwesome = typeof icon === 'string' && icon.includes( 'fa-' );

            return <li
                key={ `hamburguer-menu-item-${ i }` }
                onClick={ func }
                className={ styles[ 'menu-item' ] }
            >
                <i className={ isFontAwesome ? icon : styles.emoji }>{ isFontAwesome ? '' : icon }</i>
                <span className={ styles.label }>{ menuLabels[ i ] }</span>
            </li>
        } ) }</ul>
    </nav>

    // RENDER
    return <nav className={ get.classNameFromArr([
        styles.navbar,
        centreBrand && styles[ 'centre-brand' ]
    ]) }>
        <div className={ styles.background }/>

        <section className={ get.classNameFromArr([
            styles.left,
            leftElementIsNOTGradient && styles[ 'sans-gradient' ]
        ]) } data-nosnippet>
            { leftIcon !== null ? leftIcon : user.data.uid ? <UserIcon /> : <AnimatedLogo /> }
            { leftElement !== null ? leftElement : BrandName }
        </section>

        { centreBrand || <section className={ styles.centre } data-nosnippet>{ middleMenu }</section> }

        { centreBrand || <section className={ styles.right } data-nosnippet>
            { rightElement }
            { rightIcon === null ? <Hamburger onClick={ toggleSubMenu }/> : rightIcon }
            { SubMenu }
        </section> }
    </nav>
}

export default Nav;