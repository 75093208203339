import { useState } from 'react';
import RadioButton from './RadioButton';
import styles from './RadioSquare.module.css';
import get from '../../../../constants/get';

const RadioSquare = ({
    category = '',
    categoryType = 'radio',
    className = '',
    groupName = '',
    i = 0,
    isChecked = false,
    isDefaultChecked = false,
    passValueUp = null,
    passTargetUp = true,
    subCategoryVals = [],
    subCategoryType = 'checkbox',
}) => {
    const [ subCategories, setSubCategories ] = useState({
        show: isChecked || isDefaultChecked,
        selection: ( isChecked || isDefaultChecked ) ? subCategoryVals : []
    });

    // FUNCTIONS
    const addToProps = ( obj={} ) => {
        for ( let prop in obj ) {
            if ( prop === 'common' ) {
                continue;
            } else if ( prop === 'Category' ) {
                if ( isChecked ) {
                    obj[ prop ].checked = isChecked;
                } else if ( isDefaultChecked ) {
                    obj[ prop ].defaultChecked = isDefaultChecked;
                }
            } else if ( prop === 'SubCategory' ) {
                obj[ prop ].defaultChecked = true;
            }

            if ( obj.common ) obj[ prop ] = { ...obj[ prop ], ...obj.common };
        }
    }

    // const removeItemFromArr = ( arr=[], removeI=0 ) => [ ...arr.slice( 0, removeI ), ...arr.slice( removeI + 1 ) ]

    const toggleSubCategories = () => setSubCategories( subCategories => ({
        ...subCategories, show: !subCategories.show
    }) )

    const updateSelection = e => {
        // setSubCategories( subCategories => {
            // const newSelection = checked ? [ ...subCategories.selection, value ] : removeItemFromArr(
            //     subCategories.selection, subCategories.selection.findIndex( subCat => subCat === value )
            // );
        // } );
        if ( passTargetUp ) {
            passValueUp( e )
        } else if ( passValueUp !== null ) {
            const { checked, id, value } = e.target;
            passValueUp( checked, value, id );
        }
    }

    // CONTEXTS, PROPS & ATTRIBUTES
    const attributes = {
        fieldset: { className: get.classNameFromArr([ className, styles.square ]) },
    };

    const props = {
        RadioButton: {
            Category: {
                className: styles.category,
                groupName: groupName.includes(' ') ? groupName.split(' ').join( '-' ) : groupName,
                id: `${ groupName }-${ i }`,
                label: category,
                toggleSecondary: subCategoryVals[ 0 ] ? toggleSubCategories : updateSelection,
                type: categoryType,
            },
            SubCategory: {
                className: styles[ 'sub-category' ],
                groupName: `${ groupName }-sub-category`,
                toggleSecondary: updateSelection,
                type: subCategoryType,
            },
        },
    };

    addToProps( props.RadioButton );

    // COMPONENTS
    const SubCategories = <fieldset className={ styles[ 'sub-categories' ] }>
        { subCategoryVals.map( ( subCategory, j ) => <RadioButton
            { ...props.RadioButton.SubCategory }
            id={ `${ groupName }-${ i }-${ j }` }
            key={ `${ groupName }-${ i }-${ j }` }
            label={ subCategory }
        /> ) }
    </fieldset>

    // RENDER
    return <fieldset { ...attributes.fieldset }>
        <RadioButton { ...props.RadioButton.Category }/>
        { subCategories[ 0 ] && subCategories.show && SubCategories }
    </fieldset>
}

export default RadioSquare;