const Tree = ({ season='summer' }) => {
    // const [ light, dark ] = season === 'summer' ? [ 'yellowgreen', 'green' ]
    //     : season === 'spring' ? [ 'palegreen', 'springgreen' ]
    //     : season === 'winter' ? [ 'whitesmoke', 'gainsboro' ] : [ 'sandybrown', 'chocolate' ];

    const props = {
        branch: {
            common: {
                className: 'bark'
            },
            1: {},
        },
        trunk: {
            className: 'trunk',
            fill: 'hsl( 35, 60%, 25% )',
            stroke: 'hsl( 35, 60%, 22.5% )',
            strokeWidth: 5,
            start: { x: 3, y: 3 },
            width: 75,
            strokeLinejoin: 'round',
            strokeLinecap: 'round'
        },
    };

    props.trunk.height = props.trunk.width * 6;

    props.branch.common.height = props.trunk.height / 40;
    props.branch.common.start = {
        x: props.trunk.start.x + props.trunk.width,
        y: props.trunk.start.y + props.trunk.height /10
    };

    props.branch.common.strokeLinecap = props.branch.common.strokeLinejoin = props.trunk.strokeLinecap;
    props.branch.common.strokeWidth = props.trunk.strokeWidth;
    props.branch.common.stroke = props.trunk.stroke;
    props.branch.common.width = props.trunk.width;
    props.branch.common.fill = props.trunk.fill;

    props.branch[ 1 ] = { ...props.branch.common, ...props.branch[ 1 ] };

    // RENDER
    return <svg width={ 500 } height={ 500 } style={{ border: '3px solid red', zIndex: 10 }}>
        <path { ...props.trunk } d={`
            M ${ props.trunk.start.x },${ props.trunk.start.y }
            v${ props.trunk.height }
            h${ props.trunk.width }
            v -${ props.trunk.height }
            h -${ props.trunk.width }
            Z
        `}/>
        <g className='branch'>
            <path { ...props.branch.common } d={`
                M ${ props.branch.common.start.x },${ props.branch.common.start.y }
                v${ props.branch.common.height }
                h${ props.branch.common.width }
                v -${ props.branch.common.height + 20 }
                h -${ props.branch.common.height }
                v20
                h -${ props.branch.common.width - props.branch.common.height }
                Z
            `}/>
            <path
                className='branch-connector'
                stroke={ props.trunk.fill }
                strokeWidth={ 7 }
                strokeLinecap='round'
                strokeLinejoin='round'
                d={`
                    M ${ props.branch.common.start.x - 1 },${ props.branch.common.start.y + 2.5 }
                    v${ props.trunk.height / 70 }
                    Z
                `}
            />
            <path
                stroke={ props.trunk.fill }
                fill='red'
                strokeWidth={ 5 }
                d={`
                    M ${ props.branch.common.width / 3 + props.trunk.width },${ props.branch.common.start.x / 3 + 2 }
                    h 100
                    a 100,40 0 0 1 0,40
                    h -100
                    a ${ props.branch.common.width / 3 + props.trunk.width },${ props.branch.common.start.x / 3 + 2 } 0 0 1 -${ props.branch.common.width / 3 + props.trunk.width },-${ props.branch.common.start.x / 3 + 2 }
                    Z
                `}
            />
        </g>
    </svg>
}

export default Tree;