import { useEffect, useState } from 'react';
import AttachmentContainer from '../../Attachment/AttachmentContainer';
import styles from './AttachmentInput.module.css';
import TextInput from './TextInput';
import Button from '../../../pages/Button';
import get from '../../../../constants/get';

const AttachmentInput = ({
    // attachmentContainer,
    attachmentPosition = 'right',
    attachmentReset = { item: '', isFile: false },
    asSquares = false,
    className = '',
    id = '',
    links = [],
    linksLimit = 2,
    passAttachmentsUp = null,
}) => {
    // if a file, can get file.name, file.size, file.type (image/png), file.lastModifiedDate.toLocaleDateString()
    const [ showInput, setShowInput ] = useState( false );
    const [ attachment, setAttachment ] = useState({
        current: { ...attachmentReset }, list: links
    });

    // When the assignment changes ( and thus [ links ] ), update attachment state
    useEffect( () => setAttachment( attachment => ({ ...attachment, list: links }) ), [ links ] )

    // FUNCTIONS
    // attachment cannot be an array of attachments
    function getAttachmentValue( attachment ) {
        return !attachment ? '' : typeof attachment === 'string' ? attachment : attachment.name
    }

    function getFile({ target: { files } }) {
        setAttachment( attachment => ({ ...attachment, current: { isFile: true, item: files[ 0 ] } }) )
    }

    function getResetButton( value='', className=styles.reset ) {
        const attributesReset = {
            button: { className, onClick: resetCurrentAttachment },
            i: { className: 'fa-solid fa-xmark' },
        };

        if ( value !== '' ) {
            attributesReset.button.onClick = removeAttachment;
            attributesReset.button.id = `${ id }[${ value }]`;
            attributesReset.i.id = `${ id }[remove][${ value }]`;
        }

        return <button { ...attributesReset.button }><i { ...attributesReset.i }/></button>
    }

    function getURL({ target: { value } }) {
        setAttachment( attachment => ({ ...attachment, current: { isFile: false, item: value } }) )
    }

    // Currently only works for one file at a time, and one URL at a time
    function handleAttachingFiles() {
        if ( !showInput ) return setShowInput( showInput => !showInput )
        const isAlreadyInAttachments = attachment.list.includes( attachment.current.item );

        if ( !isAlreadyInAttachments ) {
            passAttachmentsUp !== null && passAttachmentsUp({ target : {
                id: '[links]', value: attachment.current.item
            } });

            const list = [ ...attachment.list, attachment.current.item ];

            setAttachment( () => ({ list, current: attachmentReset }) );
            if ( list.length >= linksLimit ) setShowInput( showInput => !showInput )
        }
        // setAttachment( attachment => ({ ...attachment, current: attachmentReset }) );
    }

    function removeAttachment({ target: { id } }) {
        const value = id.split( '[' ).pop().split( ']' )[ 0 ];
        const i = attachment.list.findIndex( item => typeof item === 'string' ?
            item === value : item.name === value );
        // console.log( value, i, attachment.list );

        i >= 0 && setAttachment( attachment => ({ ...attachment, list: get.arrWithoutElementAtI( i ) }) )
    }

    function resetCurrentAttachment() {
        setAttachment( attachment => ({ ...attachment, current: attachmentReset }) )
    }

    // CONTEXTS, PROPS, & ATTRIBUTES
    const attributes = {
        button: {
            className: styles.attach,
            disabled: showInput && !attachment.current.item,
            onClick: handleAttachingFiles,
            type: 'button',
        },
        div: {
            className: get.classNameFromArr([
                className,
                styles.container,
                styles[`on-${ attachmentPosition }`],
                styles[`${ showInput ? 'show' : 'hide' }-input`],
                styles[`${ attachment.current.item ? 'has' : 'sans' }-input`],
            ]),
        },
        input: {
            file: {
                onChange: getFile,
                type: 'file',
            },
            link: {
                className: styles.link,
                isDisabled: attachment.current.isFile,
                isRequired: false,
                label: 'URL',
                passTargetUp: true,
                passValueUp: getURL,
                styleVariables: {
                    '--padding-left': attachment.current.item ? '3.25rem' : '1.75rem',
                    '--padding-right': '1rem'
                },
                transformLabel: 'hide',
                value: getAttachmentValue( attachment.current.item ),
            },
        },
        label: { file: { className: styles[ 'file-input-label' ] }, },
    };
    attributes.input.file.id = attributes.input.file.name = attributes.input.file.key = `${ id }[file-input]`;
    attributes.input.link.id = attributes.input.link.name = `${ id }[url-input]`;

    const props = {
        AttachmentContainer: {
            asSquares, id, getResetButton,
            className: styles[ 'attachment-container' ],
            links: attachment.list,
            stackVertically: true,
        },
    }

    // COMPONENTS
    const Container = !passAttachmentsUp && <AttachmentContainer { ...props.AttachmentContainer }/>;
    const ResetButton = getResetButton( 'delete' );
    const AttachButton = <Button { ...attributes.button } label={ <div className={ styles.add }>
        <i className={`fa-solid fa-${ showInput ? 'arrow-up-from-bracket' : 'plus' }`}/>
        { showInput || <span className={ styles.label }>add link</span> }
    </div> }/>;
    // <button { ...attributes.button }>
    // </button>;

    const LinkInput = <TextInput { ...attributes.input.link}/>;
    const FileInput = <label { ...attributes.label.file }>
        <i className='fa-solid fa-file-arrow-up'/>
        <input { ...attributes.input.file }/>
    </label>;

    // RENDER
    return <div { ...attributes.div }>
        <fieldset className={ get.classNameFromArr([
            styles[ 'input-container' ],
            attachment.list.length === linksLimit && styles.full,
            showInput && styles[ 'shrink-button' ]
        ]) }>
            { attachment.list.length < linksLimit && AttachButton }

            <fieldset className={ styles.field }>
                <fieldset className={ styles.buttons }>
                    { FileInput }
                    { ResetButton }
                </fieldset>

                { LinkInput }
            </fieldset>

            { asSquares && Container }
        </fieldset>

        { !asSquares && Container }
    </div>
}

export default AttachmentInput;