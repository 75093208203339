import RoundedHoneycomb from '../Images/svg/RoundedHoneycomb';
import styles from './LoadingHive.module.css';

const LoadingHive = ({ width=null }) => {
    // COMPONENTS
    const Rungs = Array( 7 ).fill().map( ( e, i ) => <div
        className={ styles.rung }
        key={ `rung-${ i }` }
        style={{ animationDelay: `${ i * .2 }s` }}
    >{ i === 3 ? <RoundedHoneycomb background='maroon' borderColour='orange'/> : null }</div> );

    // RENDER
    return <div className={ styles.hive } style={{ '--width': width || '3rem' }}>{ Rungs }</div>
}

export default LoadingHive;