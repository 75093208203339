import { useState } from 'react';
import styles from './EditUnits.module.css';
import TextInputButton from '../../pages/TextInputButton';

const EditUnitsSquare = ({
    i = 0,
    keys = [],
    removeSquare = () => {},
    theme = {},
    updateUsedBlocksArr = () => {},
    updateUnits = () => {},
}) => {
    const [ inputs, setInputs ] = useState({
        background: theme.background,
        title: theme.title,
    });

    // FUNCTIONS
    const updateInputs = ( e, f ) => {
        let [ id, value ] = typeof e === 'string' ? [ e, f ] : [ e.target.id, e.target.value ];

        const key = Object.keys( inputs ).find( inputKey => id.includes( inputKey ) );
        setInputs( inputs => ({ ...inputs, [ key ]: value }) );
    }

    // PROPS & ATTRIBUTES
    const attributes = {
        button: {
            className: styles.close,
            id: `remove-${ i }`,
            onClick: removeSquare,
            type: 'button',
        },
        fieldset: {
            className: styles.inputs,
            style: { '--background': inputs.background },
        },
        i: {
            className: 'fa-solid fa-xmark',
            id: `icon-remove-${ i }`,
        },
        input: {
            id: `${ keys[ 0 ] }-${ i }`,
            type: 'color',
            value: inputs.background,
            className: styles.colour
        }
    };

    const props = {
        // blocks: {
        //     className: styles.number,
        //     label: '# blocks',
        //     transformLabel: 'on-left',
        //     type: 'number',
        //     value: theme.blocks,
        //     passValueUp: updateUsedBlocksArr,
        // },
        blocks: {
            className: styles.number,
            inputID: [ keys[ 1 ], i ].join( '-' ),
            inputLabel: '# blocks',
            inputLabelTransform: { toTop: true },
            inputType: 'number',
            passValueUp: updateUsedBlocksArr,
            uncontrolledValue: theme.blocks,
        },
        colours: {
            className: styles.colour,
            inputID: [ keys[ 0 ], i ].join( '-' ),
            inputType: 'color',
            // passValueUp: updateBackground,
            controlledValue: inputs.background,
        },
        title: {
            className: styles.text,
            label: `topic #${ i + 1 }`,
            styleVariables: {
                '--translate-x': '1.75rem',
                '--background': inputs.background,
            },
            transformLabel: 'to-top',
            value: inputs.title,
        },
    };
    props.blocks.isDisabled = props.blocks.isRequired = props.title.isDisabled = props.title.isRequired = false;

    props.colours.id = props.colours.name = `${ keys[ 0 ] }-${ i }`;
    props.blocks.id = props.blocks.name = `${ keys[ 1 ] }-${ i }`;
    props.title.id = props.title.name = `${ keys[ 2 ] }-${ i }`;

    props.title.passValueUp = props.colours.passValueUp = attributes.input.onChange = updateInputs;

    // RENDER
    return <section { ...attributes.fieldset }>
        <button { ...attributes.button }> <i { ...attributes.i }/> </button>
        {/* <TextInput { ...props.title }/> */}
        <TextInputButton
            className={ styles.text }
            controlledValue={ inputs.title }
            inputID={ `${ keys[ 2 ] }-${ i }` }
            inputLabel={ `theme #${ i + 1 }` }
            inputLabelTransform={{ toTop: true }}
            inputType='text'
            passValueUp={ updateInputs }
        />
        <fieldset className={ styles[ 'blocks-and-colour' ] }>
            <TextInputButton { ...props.blocks }/>
            <TextInputButton { ...props.colours }/>
            {/* <TextInput { ...props.blocks }/> */}
            {/* <input { ...attributes.input }/> */}
        </fieldset>
    </section>
}

export default EditUnitsSquare;