import { useState } from 'react';
import Caret from '../Buttons/Caret';
import styles from './CarouselViewport.module.css';
import Card from '../Containers/Card';

const CarouselViewport = ({ arrowsAreHorizontal=true, children=[] }) => {
    const [ activeI, setActiveI ] = useState(0);
    const directions = {
        x: [ 'left', 'right' ],
        y: [ 'up', 'down' ],
        all() { return [ ...this.x, ...this.y ] },
        type( i = 0 ) { return [ this.x[i], this.y[i] ] },
    };

    // const handleScroll = e => console.log(e)
    const handleChange = ({ target: { className } }) => {
        let direction = directions.all().find(
            direction => className.includes(direction)
        );

        directions.type( 0 ).includes(direction) && activeI > 0
            && setActiveI( activeI => activeI - 1 );
        directions.type( 1 ).includes(direction) && activeI < children.length-1
            && setActiveI( activeI => activeI + 1 );
    }

    const getCarets = (
        directions=['',''],
        limits=[0, 10],
        carets=[]
    ) => carets.length === 2 ? carets : getCarets(
        directions.slice(1),
        limits.slice(1),
        [ ...carets, <Caret
            key={`${ directions[0] }-caret`}
            onClick={ handleChange }
            isPrimaryColour
            disabled={ activeI === limits[0] }
            direction={ directions[0] }
        /> ]
    )

    const [ leftCaret, rightCaret ] = getCarets(
        arrowsAreHorizontal ? [ 'left', 'right' ] : [ 'up', 'down' ],
        [ 0, children.length - 1]
    );

    return <Card
        children={[
            leftCaret,
            children[ activeI ],
            rightCaret,
        ]}
        className={ styles.carousel }
        withDecor
    />
}

export default CarouselViewport;