import { Link, useParams } from 'react-router-dom';
import Nav from '../Nav';
import styles from './Contact.module.css';
import AnimatedLogo from '../../modular/Images/AnimatedLogo';
import get from '../../../constants/get';
import HiveNav from '../../modular/Nav/HiveNav';
import Button from '../Button';
import { useState } from 'react';
import ContactForm from '../../modular/Form/ContactForm';

const Contact = ({ isWaitlist=false }) => {
    const { planName } = useParams();
    const [ show, setShow ] = useState({
        message: true,
        signup: false,
        waitlist: false,
    });

    document.title = `Bumbleboard | ${ planName } Plan`;
    const [ lightColour, darkColour ] = [ 'gold', 'orange' ];

    // FUNCTIONS
    const showComponent = ({ target: { className } }) => setShow( show => {
        const key = className.split(' ').pop();

        return {
            message: key === 'message' ? !show.message : false,
            signup: key === 'signup' ? !show.signup : false,
            waitlist: key === 'waitlist' ? !show.waitlist : false
        }
    } )

    // COMPONENTS
    const [ WaitlistLabel, SignInLabel ] = [
        <>
            <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 36 36"><path fill={ lightColour } d="M26.751 8.007a4.672 4.672 0 0 0 5.046 1.032l3.613 3.611c.737.74.738 1.938-.004 2.68L15.319 35.405a1.9 1.9 0 0 1-2.681 0l-3.606-3.612A4.686 4.686 0 0 0 8 26.746a4.676 4.676 0 0 0-5.047-1.032l-2.359-2.36a1.893 1.893 0 0 1 0-2.68L20.678.596a1.891 1.891 0 0 1 2.679.002l2.361 2.361a4.676 4.676 0 0 0 1.033 5.048z"/><path fill={ darkColour } d="m5.42 18.527l-2.149 2.148a1.898 1.898 0 0 0-.003 2.68l.542.541a5.668 5.668 0 0 1 5.482 1.468a5.668 5.668 0 0 1 1.467 5.481l1.878 1.883a1.898 1.898 0 0 0 2.682-.002l2.189-2.187L5.42 18.527zm13.521 10.58l10.158-10.152L17.008 6.943L6.854 17.095l12.087 12.012zm1.99-13.993c2.245 2.228 2.745 5.356 1.121 6.989c-1.627 1.635-4.762 1.151-7.001-1.075c-2.239-2.228-2.738-5.358-1.114-6.991c1.625-1.63 4.755-1.15 6.994 1.077zm11.8-2.464l-1.881-1.881a5.67 5.67 0 0 1-5.483-1.468a5.668 5.668 0 0 1-1.47-5.484l-.541-.546a1.9 1.9 0 0 0-2.678.003L18.442 5.51l12.092 12.011l2.196-2.195c.74-.737.74-1.936.001-2.676z"/></svg> waitlist
        </>, <>
            <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 256 256"><g><path d="M168 100a60 60 0 1 1-60-60a60 60 0 0 1 60 60Z" fill={ lightColour }/><path fill={ darkColour } d="M256 136a8 8 0 0 1-8 8h-16v16a8 8 0 0 1-16 0v-16h-16a8 8 0 0 1 0-16h16v-16a8 8 0 0 1 16 0v16h16a8 8 0 0 1 8 8Zm-57.87 58.85a8 8 0 0 1-12.26 10.3C165.75 181.19 138.09 168 108 168s-57.75 13.19-77.87 37.15a8 8 0 0 1-12.25-10.3c14.94-17.78 33.52-30.41 54.17-37.17a68 68 0 1 1 71.9 0c20.65 6.76 39.23 19.39 54.18 37.17ZM108 152a52 52 0 1 0-52-52a52.06 52.06 0 0 0 52 52Z"/></g></svg> create an account
        </>
    ];
    const NavMenuItems = Array( 2 ).fill().map( ( e, i ) => <li
        className={ styles[ 'nav-item' ] }
        key={ `nav-menu-item-${ i }` }
    ><Button
        label={ i === 0 ? WaitlistLabel : SignInLabel }
        labelColour='darkorange'
        looksLikeLink
    /></li> )

    // RENDER
    return <article className={ styles.page }>
        <Nav
            leftIcon={ <AnimatedLogo /> }
            middleMenu={ <HiveNav menu={{ labels: { 1: { to: '/', label: 'Home' } } }}/> }
            rightElement={ <Link to={ '/login' }>login</Link>}
            rightIcon={ <Button label='sign up'/> }
        />

        <section>
            <header>
                <h1>Join our waitlist</h1>
            </header>

            { show.message && <article className={ styles.message }>
                <p className={ styles.subtitle }>
                    Thank you for considering our { get.capitalise([ planName ]) } Plan!
                    <br /><br />We are at capacity but still want to help you.
                    <br /><br />So <span className={ styles.highlight }>reserve your spot</span> on our waitlist, and after we have satisfied the customers ahead of you, we will assist you.
                </p>
                <fieldset className={ styles.buttons }>
                    <Button
                        // activeLabelColour=''
                        // backgroundGradientColours={[ 'hsl( 45, 0%, 90% )' ]}
                        // boxShadowColours={}
                        boxShadowColours={[ darkColour, lightColour ]}
                        className={ get.classNameFromArr([ styles.button, 'login' ]) }
                        label='just log me in'
                        // labelColour=''
                        onClick={ showComponent }
                    />
                    <Button
                        // activeLabelColour={ darkColour }
                        backgroundGradientColours={[ lightColour, darkColour ]}
                        boxShadowColours={[ darkColour, lightColour ]}
                        className={ get.classNameFromArr([ styles.button, 'waitlist' ]) }
                        hoverColours={[ darkColour ]}
                        hoverTextColour='white'
                        label='save my spot first'
                        labelColour='white'
                        onClick={ showComponent }
                    />
                </fieldset>
            </article> }

            { show.waitlist && <div className={ styles.forms }>
                <aside className={ styles.sidenav }>
                    <ul className={ styles.menu }>{ NavMenuItems }</ul>
                </aside>
                <ContactForm isWaitlist={ isWaitlist }/>
            </div> }
        </section>
    </article>
}

export default Contact;