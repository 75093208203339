import { useContext, useMemo } from 'react';
import Day from './Day';
import CourseContext from '../../../contexts/CourseContext';
import FillBoardButton from '../Buttons/FillBoardButton';
import UserContext from '../../../contexts/UserContext'
import Contexts from '../../../contexts/CalendarContext';
import styles from './Month.module.css';
import get from '../../../constants/get';
import HiveContext from '../../../contexts/HiveContext';
import courseInfo from '../../../constants/courseInfo';

const Week = ({ unitIndexes = [], units = {}, weekNum=0 }) => {
    const { updateBoardMerge, data: { uid: userID } } = useContext( UserContext );
    const { toggleModal } = useContext( CourseContext );
    const { course, schoolYear } = useContext( HiveContext );
    const { month } = useContext( Contexts.Month );

    const [ boards, week ] = [ course.content.boards, month.weeks[ weekNum ] ];
    const isHolidayWeek = week.daysInClass.length === 0;
    const [ isLastWeekOfMonth, isShortWeek ] = [
        Object.keys( month.weeks ).pop() * 1 === weekNum,
        !isHolidayWeek && week.daysInClass.length < 4,
    ];

    let board = get.boardByWeekNum( weekNum, boards )
        || get.boardByWeekNum( weekNum, courseInfo.getPlaceholderBoards( schoolYear ) );

    const boardNum = board?.num;
    const isMergedBoard = board && typeof board.isMergedWith === 'number';

    const boardCompletion = useMemo( () => {
        if ( !board ) return { req: 0, prereq: 0 }

        let boardCopy = board;
        if ( isMergedBoard ) boardCopy = boards[ board.isMergedWith ];

        const boardAssignments = get.assignmentsByBoardNum(
            isMergedBoard ? boardCopy.num : board.num, course.content.assignments
        );

        if ( boardAssignments ) boardAssignments.forEach(
            assignment => !assignment.directions ? null
                : assignment.isPrereq ? board.completion.prereq++ : board.completion.req++
        )

        return boardCopy.completion
    }, [ board, boards, course.content.assignments, isMergedBoard ] );

    // FUNCTIONS
    const handleTogglingModal = () => toggleModal({ target: {
        className: `board-${ attributes.section.id.split( 'week-' )[ 1 ] }`,
        id: `board-${ boardNum }`
    } })

    const mergeBoards = ({ target: { id } }) => {
        window.location.hostname === 'localhost' && console.log( '🫂 MERGING BOARDS', id );
        const increment = id.includes( 'up' ) ? -1 : 1;
        updateBoardMerge( board, increment );
    }

    // PROPS & ATTRIBUTES
    const attributes = {
        section: {
            className: get.classNameFromArr([
                !board?.isMergedWith ? '' : board.isMergedWith < boardNum ?
                    styles[ 'merged-with-prev' ] : styles[ 'merged-with-next' ],
                isHolidayWeek ? styles[ 'holiday-week' ] : '',
                styles.week,
                isShortWeek ? styles.short : '',
                userID === course.ownerID ? styles[ 'with-button' ] : styles[ 'sans-button' ],
            ]),
            id: `week-${ get.monthName( week.months[ 0 ] ) }-${ week.days[ 0 ] }-${ month.year.short }`,
        },
    }

    // COMPONENTS
    const Days = useMemo( () => {
        return week.days.map( ( day, i ) => {
            const monthName = get.monthName( week.months[ i ], course.locale, true );
            return <Day
                board={ board }
                currentMonth={ month.i }
                day={ day }
                dayMonth={ week.months[ i ] }
                id={`${ monthName }-${ day }-${ month.year.short }`}
                isLastDayOfWeek={ day === week.daysInClass[ week.daysInClass.length - 1 ] }
                isMergedBoard={ isMergedBoard }
                key={`${ monthName }-${ day }-${ month.year.short }`}
                unit={ units[ unitIndexes[ i ] ] }
            />
        } )
    }, [ board, month, unitIndexes, units, week, course.locale, isMergedBoard ] );

    // RENDER
    return <section { ...attributes.section }>
        { userID === course.ownerID && <FillBoardButton
            boardCompletion={ boardCompletion }
            board={ isMergedBoard ? course.content.boards[ board.isMergedWith ] : board }
            handleTogglingModal={ isHolidayWeek ? null : handleTogglingModal }
            isLastWeekOfMonth={ isLastWeekOfMonth }
            mergeBoards={ isShortWeek ? mergeBoards : null }
            mergedWith={ board?.isMergedWith }
        /> }
        <div className={ styles.days }>{ Days }</div>
    </section>
}

export default Week;