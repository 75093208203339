import { useState } from 'react';
import TextInputButton from '../../../pages/TextInputButton';
import styles from './InputGroup.module.css';

const InputGroup = ({
    classPrefix = '',
    fieldLabel = '',
    fieldPrefix = '',
    fontSize = '1em',
    inputLabelTransform = '',
    inputsLabel = '',
    inputType = '',
}) => {
    const [ visibleInputs, setVisibleInputs ] = useState({
        amount: 1, values: [ '' ]
    });

    // FUNCTIONS
    function generateInputs( i=0, Inputs=[] ) {
        const props = {
            inputType, fontSize,
            className: styles.input,
            isRequired: false,
            inputLabel: inputsLabel,
            controlledValue: visibleInputs.values[ i ] || '',
            passValueUp: updateValues,
        };

        props.inputID = props.key = `${ fieldLabel }-${ classPrefix }-${ i }`;
        return i === visibleInputs.amount ? Inputs
            : generateInputs( i + 1, [ ...Inputs, <TextInputButton { ...props }/> ] )
    }

    function updateValues({ target: { id, type, value } }) {
        setVisibleInputs( visibleInputs => {
            const [ i, max ] = [ id.split( '-' ).pop() * 1, visibleInputs.amount - 1 ];
            const mustAddInput = i === max && value !== '';

            visibleInputs.values[ i ] = type === 'number' ? value * 1 : value;

            return {
                amount: mustAddInput ? visibleInputs.amount + 1 : visibleInputs.amount,
                values: mustAddInput ? [ ...visibleInputs.values, '' ] : visibleInputs.values
            }
        } )
    }

    return <fieldset className={ styles['input-group'] }>
        { fieldLabel && <legend>{ fieldLabel }</legend>}
        { fieldPrefix && <span>{ fieldPrefix }</span> }
        <section>{ generateInputs() }</section>
    </fieldset>
}

export default InputGroup;