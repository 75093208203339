import get from '../../../constants/get';
import SVG from '../../modular/Images/SVG';
import styles from './Solutions2.module.css';

const Solutions2 = ({ background='', Header, hsl={}, solutionsArr=[] }) => {
    // const [ activeSolutionI, setActiveSolutionI ] = useState( 0 );
    const detailsHaveCover = false;

    // FUNCTIONS
    // const getActiveSolution = () => {
    //     const activeSolution = solutionsArr[ activeSolutionI ];

    //     return <div className={ styles[ 'active-solution' ] }>
    //         <header>
    //             { activeSolution.icon }
    //             { activeSolution.title }
    //         </header>
    //         <p>{ activeSolution.description }</p>
    //     </div>
    // }

    // const toggleActiveSolutionI = ({ target: { id } }) => setActiveSolutionI(
    //     () => id.split( '-' ).pop() * 1
    // )
    const getDetails = ( solution={} ) => <>
        <SVG { ...solution.SVGattributes }/>
        <p>
            <span className={ styles.title }>{ solution.title }</span>
            <br />{ solution.description }
        </p>
    </>

    // RENDER
    return <section className={ styles.container } style={{ background }} data-nosnippet>
        <article className={ styles.solutions }>
            { Header }
            <div className={ styles[ 'list-container' ] }>
                <ul className={ styles.list }>{ solutionsArr.map( ( solution, i ) => <li
                    key={ `solution-${ i }` }
                    className={ get.classNameFromArr([ styles.solution, detailsHaveCover && styles[ 'with-cover' ] ]) }
                >{ detailsHaveCover ? <section className={ styles.details }>
                    { getDetails( solution ) }
                    <div
                        className={ styles.cover }
                        id={ `solution-${ i }` }
                        // onClick={ toggleActiveSolutionI }
                    />
                </section> : getDetails( solution ) }</li> ) }</ul>
            </div>
            {/* <div className={ styles[ 'active-solution' ] }>{ getActiveSolution() }</div> */}
        </article>
    </section>
}

export default Solutions2;