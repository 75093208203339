import { useState } from 'react';
import styles from './SelectInput.module.css';
import Tag from './Tag';
import get from '../../../../constants/get';

const SelectInput = ({
    canCreateOption = true,
    canSelectMultiple = false,
    canTypeAnswer = true,
    canReset = true,
    className = '',
    colours = [],
    defaultTags = [],
    hideTypingFieldWhenTagIsSelected = false,
    initialTags = [],
    id = '',
    isDisabled = false,
    isVertical = false,
    label = '',
    labelPositionOn = 'top',
    name = '',
    options = [],
    passValueUp = null,
    passTargetUp = false,
    startingValue = ''
}) => {
    const [ filter, setFilter ] = useState( startingValue );
    const [ createdOption, setCreatedOption ] = useState( null );
    const [ showOptions, setShowOptions ] = useState( false );
    // const [ highlightedOptionI, setHighlightedOptionI ] = useState( 0 ); For when user keys down and selects from the menu
    const [ userSelections, setUserSelections ] = useState( [ ...defaultTags, ...initialTags ] );

    // FUNCTIONS
    function deleteTag({ target : { id } }) {
        setUserSelections( userSelections => {
            const deletedTag = id.split( 'close-' )[ 1 ];
            return userSelections.filter( tag => tag !== deletedTag )
        } )
    }

    function filterOptionsList( allOptions=[] ) {
        const arr = canCreateOption && createdOption ? [ ...options, createdOption ] : options;
        const result = options.includes( filter ) ? allOptions
            : allOptions.filter( ( e, i ) => arr[ i ]
                && arr[ i ].toLocaleLowerCase().includes( filter.toLocaleLowerCase() ) );

        return result[ 0 ] ? result : setShowOptions( () => false )
    }

    function getButton( iconClass='', buttonAttributes={} ) {
        return <button { ...buttonAttributes }>
            <i className={ iconClass }/>
        </button>
    }

    function getIconBackground() {
        let i = options.findIndex( option => option === filter );
        return i < 0 ? 'transparent' : colours[ i + defaultTags.length ]
    }

    function getListItem( innerHTML='', i=0, onClick=null, isCustom=false ) {
        const liAttributes = {
            id: `${ id }-li-${ isCustom ? 'custom-' : '' }${ i }`,
            key: `${ id }-li-${ isCustom ? 'custom-' : '' }${ i }`
        };

        if ( onClick !== null ) liAttributes.onClick = onClick

        return <li { ...liAttributes }>{ innerHTML }</li>
    }

    function getOptionList( maxI=-1, i=0, optionComponents=[] ) {
        const isCustomOption = maxI > options.length && i === maxI - 1;
        const innerHTML = isCustomOption ? createdOption : options[ i ];

        return i < maxI ? getOptionList( maxI, i + 1, [ ...optionComponents, getListItem(
            innerHTML, i, makeSelection, isCustomOption
        ) ] ) : optionComponents
    }

    function getSelectionIcon() {
        return <i
            className={`${ styles[ 'selection-icon' ] } fa-solid fa-circle`}
            style={{ color: getIconBackground() }}
        />
    }

    function getTagList( i=0, tagComponents=[] ) {
        if ( i === userSelections.length ) return tagComponents

        const [ text, title ] = typeof userSelections[ i ] === 'string' ?
            [ userSelections[ i ], '' ] : userSelections[ i ];

        return getTagList( i + 1, [ ...tagComponents, getListItem(
            <Tag
                canDelete={ i >= defaultTags.length }
                colour={ colours[ i ] }
                deleteTag={ deleteTag }
                text={ text }
                title={ title }
            />, i
        ) ] )
    }

    function makeSelection({ target }) {
        const { id } = target;
        const i = id.split( '-' ).pop() * 1;

        const newSelection = id.includes( 'custom' ) ? filter.toLocaleLowerCase() : options[ i ];
        setUserSelections( userSelections => userSelections.includes( newSelection ) ? userSelections
            : canSelectMultiple ? [ ...userSelections, newSelection ] : [ newSelection ] )

        setShowOptions( () => false );
        // setFilter( () => newSelection );
        setFilter( () => '' );
        passValueUp !== null && passValueUp( passTargetUp ? { target } : newSelection );
    }

    function resetUserSelections() {
        setUserSelections( () => defaultTags )
    }

    function toggleSelectMenu() {
        setShowOptions( showOptions => !showOptions )
    }

    function updateFilter({ key, target: { value } }) {
        window.location.hostname === 'localhost' && console.log( '⌨️', key );
        setFilter( () => value );
        value !== '' && setCreatedOption( () => `Create: ${ value }` );
        setShowOptions( () => value !== '' );
    }

    function wrapList( list=[], divClass ) {
        return <div className={ divClass }><ul>{ list }</ul></div>
    }

    // PROPS & ATTRIBUTES
    const attributes = {
        button: {
            common: { type: 'button' },
            reset: {
                className: styles.reset,
                onClick: resetUserSelections,
            },
            select: {
                className: styles.select,
                onClick: toggleSelectMenu,
            }
        },
        fieldset: {
            className: get.classNameFromArr([
                className,
                styles.select,
                styles.container,
                isDisabled ? styles.disabled : styles.active,
                showOptions ? styles.expanded : styles.collapsed,
                styles[ canSelectMultiple ? 'multi' : 'single' ],
                isVertical ? styles.vertical : styles.horizontal,
                labelPositionOn === 'left' && styles[ 'label-on-left' ],
                hideTypingFieldWhenTagIsSelected && userSelections.length > 0 && styles[ 'hide-field' ]
            ]),
        },
        input: {
            // autoComplete: 'off',
            role: 'presentation',
            className: styles.text,
            disabled: isDisabled || !canTypeAnswer,
            id: id,
            name: name,
            // onChange: updateFilter,
            onKeyDown: updateFilter,
            placeholder: `${ canTypeAnswer ? 'Type / ' : '' }Select`,
            type: 'text',
            defaultValue: typeof filter === 'object' ? '- - -' : filter,
        },
        label: {
            className: styles.label,
            htmlFor: id,
        },
        options: { className: styles.options },
        tags: { className: styles.tags }
    };

    attributes.button.reset = { ...attributes.button.reset, ...attributes.button.common };
    attributes.button.select = { ...attributes.button.select, ...attributes.button.common };

    // COMPONENTS
    const ResetButton = getButton( 'fa-solid fa-xmark', attributes.button.reset );
    const SelectButton = getButton( 'fa-solid fa-caret-down', attributes.button.select );

    const OptionList = getOptionList( ( canCreateOption ? 1 : 0 ) + options.length );
    const TagList = getTagList();

    const TypeIcon = <i className={`${ styles[ 'selection-icon' ] } ${ styles.pending } fa-solid fa-keyboard`}/>;

    // RENDER
    return <fieldset { ...attributes.fieldset }>
        <label { ...attributes.label }>{ labelPositionOn === 'top' ? label : `${ label } :` }</label>
        <div className={ styles.field }>
            { wrapList( TagList, attributes.tags.className ) }

            <fieldset className={ styles[ 'input-and-buttons' ] }>
                <div className={ styles[ 'input-container' ] }>
                    { canTypeAnswer && TypeIcon }
                    {/* { !canSelectMultiple && ( options.includes( filter.toLocaleLowerCase() ) && TypeIcon ) } */}
                    { canSelectMultiple && !( options.includes( filter.toLocaleLowerCase() )
                        || options.includes( filter ) ) && getSelectionIcon() }
                    <input { ...attributes.input }/>
                </div>
                { canReset && userSelections.length > defaultTags.length && ResetButton }
                { SelectButton }
                { showOptions && wrapList( filterOptionsList( OptionList ), attributes.options.className ) }
                <input type='hidden' value={ userSelections } id={ [ id, '[value]' ].join( '' ) }/>
            </fieldset>
        </div>
    </fieldset>
}

export default SelectInput;