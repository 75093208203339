import { useState } from 'react';
import styles from './DefinedTerm.module.css';
import ToolTip from './ToolTip';
import get from '../../constants/get';

const DefinedTerm = ({
    alternateTerm='',
    className='',
    definition='',
    definitionID='',
    i=-1,
    term='',
    hideStyle=false
}) => {
    const [ showAlternativeTerm, setShowAlternativeTerm ] = useState( false );

    // FUNCTIONS
    const getDefinitionAsStr = ( jsxArr=[], jsxStr='' ) => jsxArr.length === 0 ? jsxStr
        : getDefinitionAsStr( jsxArr.slice( 1 ), jsxStr + (
            typeof jsxArr[ 0 ] === 'string' ? jsxArr[ 0 ] : jsxArr[ 0 ].props?.term
        ) )

    const getTitleStr = () => typeof definition === 'string' ? definition : getDefinitionAsStr( definition?.props?.children )

    const toggleTerm = () => setShowAlternativeTerm( showAlternativeTerm => !showAlternativeTerm )


    // PROPS & ATTRIBUTES
    const attributes = {
        definition: { href: '#' + definitionID },
        alternate: { onClick: toggleTerm },
    };

    if (
        definition &&
        !( term?.type === 'abbr' && term?.props?.title )
    ) attributes.definition.title = getTitleStr()

    attributes[ definition ? 'definition' : 'alternate' ].className = get.classNameFromArr([
        className,
        styles[ 'defined-term' ],
        hideStyle && styles[ 'blend-in' ],
        definition ? styles[ 'has-definition' ] : styles[ 'has-alternate-term' ]
    ]);

    // RENDER
    return <dfn { ...attributes[ definition ? 'definition' : 'alternate' ] }>{
        definition ? <>
            <a href={ '#' + definitionID }>{ term }{ i >= 0 && <sup>{ i }</sup> }</a>

            <ToolTip
                hasArrow
                arrowDirection='down'
                // onClick={}
                pElement={ <p>{ definition }</p> }
                // style={}
            />
        </> : showAlternativeTerm ? alternateTerm : term
    }</dfn>
}

export default DefinedTerm;