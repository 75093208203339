import AttachmentContainer from '../../modular/Attachment/AttachmentContainer';
import BoardProgress from '../Hive/BoardView/BoardProgress';
import get from '../../../constants/get';
import styles from './Hero2.module.css';

// const defaultBoardColours = {
//     q4: {
//         lightBox: 'hsl( 42, 82%, 90% )',
//         darkBox: 'hsl( 42, 82%, 63% )',
//         border: 'hsl( 42, 82%, 43% )',
//         hsl: { h: 42, s: '82%', l: '63%' }
//     },
//     q2: {
//         lightBox: 'hsl( 36, 81%, 90% )',
//         darkBox: 'hsl( 36, 81%, 63% )',
//         border: 'hsl( 36, 81%, 43% )',
//         hsl: { h: 36, s: '81%', l: '63%' }
//     },
//     q1: {
//         lightBox: 'hsl( 31, 80%, 89% )',
//         darkBox: 'hsl( 31, 80%, 62% )',
//         border: 'hsl( 31, 80%, 42% )',
//         hsl: { h: 31, s: '80%', l: '62%' }
//     },
// };

const waveDs = [
    "M0,32 L80,26.7 C160,21,320,11,480,37.3 C640,64,800,128,960,138.7 C1120,149,1280,107,1360,85.3 L1440,64 L1440,320 L1360,320 C1280,320,1120,320,960,320 C800,320,640,320,480,320 C320,320,160,320,80,320 L0,320 Z",
    "M0,32 L80,69.3 C160,107,320,181,480,181.3 C640,181,800,107,960,85.3 C1120,64,1280,96,1360,112 L1440,128 L1440,320 L1360,320 C1280,320,1120,320,960,320 C800,320,640,320,480,320 C320,320,160,320,80,320 L0,320 Z"
];


// const schoolYear = courseInfo.getSchoolYearObj(
//     [ 22, 7, 2022 ],
//     [ 16, 5, 2023 ],
//     'va-bumble-county', [
//         { days: [ 2, 16, 26, 27 ], reasons: [] },
//         { days: [ 20 ], reasons: [] },
//         { days: [ 3 ], reasons: [] },
//         { days: [ 3, 4, 5, 6, 7, 14, 21 ], reasons: [] },
//         { days: [ 29 ], reasons: [] },
//         { days: [ 19, 20, 21, 22, 23, 26, 27, 28, 29, 30 ], reasons: [] },
//         { days: [ ...get.numRange( 1, 31 ) ], reasons: [] },
//         { days: [ ...get.numRange( 1, 19 ) ], reasons: [] },
//         { days: [ 2, 5, 26 ], reasons: [] },
//         { days: [ 5, 10, 24, 31 ], reasons: [] },
//         { days: [ 8, 11, 23, 24, 25 ], reasons: [] },
//         { days: [ ...get.numRange( 19, 30 ) ], reasons: [] },
//     ], { 9: { days: [ 12 ], reasons: [ 'PSAT' ] } },
//     [ 1, 2, 3, 4 ],
//     [ [ 28, 9, 2022 ], [ 25, 0, 2023 ], [ 13, 3, 2023 ], [ 16, 5, 2023 ] ]
// );
// schoolYear.quarters = [{
//     "end": {
//         "year": 2022,
//         "day": 28,
//         "month": 9
//     },
//     "weeks": [ 3, 4, 5, 6, 7, 8, 9, 10, 11, 12 ],
//     "id": 1,
//     "start": {
//         "month": 7,
//         "year": 2022,
//         "day": 22
//     }
// }, {
//     "id": 2,
//     "start": {
//         "year": 2022,
//         "day": 29,
//         "month": 9
//     },
//     "end": {
//         "day": 25,
//         "month": 0,
//         "year": 2023
//     },
//     "weeks": [ 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25
//     ]
// }, {
//     "id": 3,
//     "weeks": [ 25, 26, 27, 28, 29, 30, 31, 32, 33, 34, 35, 36 ],
//     "start": {
//         "year": 2023,
//         "month": 0,
//         "day": 26
//     },
//     "end": {
//         "day": 13,
//         "month": 3,
//         "year": 2023
//     }
// }, {
//     "id": 4,
//     "end": {
//         "month": 5,
//         "year": 2023,
//         "day": 16
//     },
//     "weeks": [ 36, 37, 38, 39, 40, 41, 42, 43, 44, 45 ],
//     "start": {
//         "year": 2023,
//         "day": 14,
//         "month": 3
//     }
// }];

const assignments = [{
    directions: 'Tell the teacher what you\'re doing this summer and answer their followup question',
    links: [ 'thisislanguage.com' , 'conjuguemos.com' ],
    isChecked: true,
}, {
    directions: 'Test: future plans unit, listening, speaking, and reading',
    links: [ 'wizer.me' ],
    isChecked: true,
}, {
    directions: 'Practice asking & answering questions with a partner using the Q&A guide',
    links: [ 'docs.google.com' ],
}, {
    directions: 'Review the vocab solo ( minimum 70% ), then complete the dictation peardeck',
    links: [ 'blooket.com', 'peardeck.com' ],
}, {
    isDirectionsBox: true
}, {
    directions: 'Review the near future tense, then orally answer 2 of the teacher\'s questions',
    links: [ 'schoology.com' ],
    isChecked: true,
}, {
    directions: '',
    links: [],
}, {
    directions: '',
    links: [],
    isChecked: true,
}, {
    directions: '',
    links: [],
}];

const Hero2 = ({
    background='',
    Form,
    HeadlinePrefix,
    HeadlineH1,
    hsl={},
}) => {
    const style = {
        '--h': hsl.h,
        '--s': hsl.s,
        '--l': hsl.l,
    };

    // FUNCTIONS
    const getWave = ( withRectBoost=false ) => <svg
        className={ styles.wave }
        xmlns="http://www.w3.org/2000/svg"
        viewBox={ `0 0 1440 ${ withRectBoost ? 500 : 320 }` }
        fill={ background }
    >
        <path d={ waveDs[ 0 ] }/>
        { withRectBoost && <rect height={ 300 } width={ 1440 } y={ 300 }/> }
    </svg>

    // COMPONENTS
    const Board = <article className={ styles.board }>{[
        get.classNameFromArr( [ styles.box, styles.left, styles.top, styles.corner ] ),
        get.classNameFromArr( [ styles.box, styles.middle, styles.top ] ),
        get.classNameFromArr( [ styles.box, styles.right, styles.top, styles.corner ] ),
        get.classNameFromArr( [ styles.box, styles.left, styles.centre ] ),
        get.classNameFromArr( [ styles.box, styles.middle, styles.centre ] ),
        get.classNameFromArr( [ styles.box, styles.right, styles.centre ] ),
        get.classNameFromArr( [ styles.box, styles.left, styles.bottom, styles.corner ] ),
        get.classNameFromArr( [ styles.box, styles.middle, styles.bottom ] ),
        get.classNameFromArr( [ styles.box, styles.right, styles.bottom, styles.corner ] ),
    ].map( ( className, i ) => <div
        className={ get.classNameFromArr([ className, assignments[ i ].isChecked ? styles.checked : '' ]) }
        key={ `box-${ i }` }
    >{
        assignments[ i ]?.isDirectionsBox || <>
            { Array( 3 ).fill( styles.line ).map( ( e, i ) => <div className={ e } key={ `line-${ i }` }/> ) }
            <div className={ styles[ 'checkbox-and-attachments' ] }>
                <div className={ styles.checkbox }>
                    <span className={ styles.checkmark }>✓</span>
                </div>
                <AttachmentContainer
                    asSquares
                    className={ styles.attachments }
                    isForDisplay
                    isSmall
                    links={ assignments[ i ].links }
                />
            </div>
        </>
    }</div> )}</article>;

    // RENDER
    return <header className={ styles.hero } style={ style }>
        <h2 className={ styles.description } id='description'>BumbleBoards are stunning weekly choiceboards that give students the choice and voice to own their learning. Built by a teacher for teachers, teach your virtual and in-person students the thrill of good habits!</h2>
        <section className={ styles[ 'left-pane' ] } data-nosnippet>
            { HeadlinePrefix }
            { HeadlineH1 }
            { Form }
        </section>
        <section className={ styles.visuals } data-nosnippet>
            { HeadlinePrefix }
            <BoardProgress checkedList={[ 'prereq', 'prereq', 'prereq', 'req' ]} hsl={ hsl }/>

            <section className={ styles[ 'board-and-wave' ] }>
                { Board }
                <div className={ styles[ 'wave-and-h1' ] }>
                    { getWave( true ) }
                    <header>
                        { HeadlinePrefix }
                        { HeadlineH1 }
                    </header>
                </div>
            </section>
        </section>
        { getWave( false ) }
    </header>
}

export default Hero2;