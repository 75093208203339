import { useEffect, useState } from 'react';
import styles from './County.module.css';
import DateInput from '../../../modular/Form/Parts/DateInput';
import get from '../../../../constants/get';
import TextInputButton from '../../TextInputButton';
import Button from '../../Button';
import SVG from '../../../modular/Images/SVG';
import RadioGroup from '../../../modular/Form/Parts/RadioGroup';
import Wrapper from '../../Wrapper';

const County = ({ county={}, updateCounty=( keys=[''], values=[{}] ) => {} }) => {
    const [ isForm, setIsForm ] = useState({ forYear: false, forSchools: false });
    const [ schools, setSchools ] = useState( null );

    const [ blankSchoolFieldsetNum, setBlankSchoolFieldsetNum ] = useState( 0 );
    const [ schoolNames, setSchoolNames ] = useState([ '' ]);
    // const [ quarters, setQuarters ] = useState( county?.schoolYear?.quarters || [] );

    useEffect( () => county?.schools && setSchools( () => county.schools ), [ county.schools ] ) // county.id, schools
    useEffect( () => {
        schools && get.log({
            fileName: 'County.js',
            functionDirectionsOrComponentName: '{ schools } state',
            lineNumber: 20,
            str: schools
        })
    }, [ schools ] )

    const quarters = county?.schoolYear?.quarters;

    // FUNCTIONS
    function getSchoolForm( i=0 ) {
        const colours = [ 'maroon', 'gold' ];
        const hsls = colours.map( colour => get.hslFromName( colour, true ) );
        const schoolID = 'new-school-' + i;

        const [ Colours, NameAndLogo, Levels ] = [
            <fieldset className={ styles.colours }>{ colours.map( ( colour, i ) => {
                const { h, s, l } = hsls[ i ];

                return <fieldset className={ styles.colour } key={ schoolID + '-colours' }>
                    <TextInputButton
                        inputID={ [ schoolID, 'colour', i ].join( '-' ) }
                        inputType='color'
                        uncontrolledValue={{ h, s, l }}
                    />
                    <TextInputButton
                        inputID={ [ schoolID, 'colour', 'label', i ].join( '-' ) }
                        inputType='text'
                        inputLabel='color name'
                        uncontrolledValue={ colour }
                    />
                </fieldset>
            } ) }</fieldset>, <fieldset className={ styles[ 'logo-and-name' ] }>
                <TextInputButton
                    inputID={ [ schoolID, 'school', 'logo' ].join( '-' ) }
                    inputType='text'
                    inputLabel='mascot'
                    uncontrolledValue='🐝'
                />
                <TextInputButton
                    inputID={ [ schoolID, 'school', 'name' ].join( '-' ) }
                    inputType='text'
                    inputLabel='school name'
                    controlledValue={ schoolNames[ i ] }
                    passValueUp={ handleSchoolNames }
                />
            </fieldset>, <RadioGroup
                classPrefix={ [ schoolID, 'school', 'level' ].join( '-' ) }
                defaultCheckedButtons={[ 'High' ]}
                groupName='school-level'
                mainLabels={[ 'High', 'Secondary', 'Middle', 'Elementary' ]}
                mainLabelType='radio'
            />
        ];

        return <fieldset
            id={ schoolID }
            key={ schoolID }
            className={ styles.school }
        >
            { Colours }
            { NameAndLogo }
            { Levels }
        </fieldset>
    }

    function handleSchoolNames({ target: { id, value } }) {
        setSchoolNames( schoolNames => {
            const i = id.split( '-' )[ 2 ] * 1;
            schoolNames[ i ] = get.capitalise( value.trim() );

            if ( get.lastArrayElement( schoolNames ).length > 0 ) setBlankSchoolFieldsetNum( () => schoolNames.length )

            return schoolNames
        } )
    }

    function handleSubmit( e ) {
        e.preventDefault();
        let { target: { elements } } = e;
        // console.log( '1️⃣', elements );

        elements = Array.from( elements ).filter( target => target.type === 'radio' ? target.checked : target.value );
        const [ keys, values ] = [ [], [] ];
        // console.log( '2️⃣', elements );

        // UPDATE SCHOOL YEAR DATES
        if ( elements.find( e => e.id.includes( '-day-date' ) ) ) {
            const dates = {
                start: { year: 1967, month: 0, day: 17 },
                end: { year: 1967, month: 0, day: 17 },
                quarters: []
            };

            elements.filter( e => e.id.includes( '-day-date' ) ).forEach( e => {
                const [ idArr, valueArr ] = [
                    e.id.split( '-' ),
                    e.value.split( '-' ).map( f => f * 1 )
                ];

                const [ isSchoolYearDate, isStartDate ] = [
                    idArr[ 1 ] === 'day',
                    idArr.includes( 'first' ),
                ];

                const [ quarterID, quarterType ] = [
                    !isSchoolYearDate && idArr[ 1 ] * 1,
                    !isSchoolYearDate && idArr[ 0 ],
                ];

                const i = county.schoolYear.quarters.findIndex( q => q.id === quarterID );
                // console.log( idArr, valueArr, isSchoolYearDate, isStartDate, quarterID, quarterType, i );
                if ( !isSchoolYearDate ) {
                    if ( dates.quarters[ i ] ) {
                        dates.quarters[ i ][ isStartDate ? 'start' : 'end' ] = {
                            year: valueArr[ 0 ],
                            month: valueArr[ 1 ],
                            day: valueArr[ 2 ]
                        };
                    } else {
                        dates.quarters[ i ] = {
                            [ isStartDate ? 'start' : 'end' ]: {
                                year: valueArr[ 0 ],
                                month: valueArr[ 1 ],
                                day: valueArr[ 2 ]
                            },
                            id: quarterID,
                            type: quarterType,
                            weeks: county.schoolYear.quarters[ dates.quarters.length ].weeks
                        }
                    }
                } else {[
                    dates[ isStartDate ? 'start' : 'end' ].year,
                    dates[ isStartDate ? 'start' : 'end' ].month,
                    dates[ isStartDate ? 'start' : 'end' ].day,
                ] = valueArr }
            } );

            // console.log({ ...county.schoolYear, ...dates, id: dates.start.year + ' - ' + dates.end.year });
            keys.push( 'schoolYear' );
            values.push({ ...county.schoolYear, ...dates, id: dates.start.year + ' - ' + dates.end.year });
        } else if ( elements.find( e => e.id.includes( 'school' ) ) ) {  // UPDATE SCHOOL OBJECTS
            const schoolsElements = elements.filter( e =>  e.id.includes( 'school-' ) || e.id.includes( '-colour-' ) );
            const [ obj, schoolNames ] = [ {}, Object.keys( schools ) ];

            [
                ...schoolNames,
                ...get.numRange( 0, blankSchoolFieldsetNum ).map( i => `new-school-${ i }` )
            ].forEach( schoolID => {
                const isNewSchool = schoolID.includes( 'new-school-' );
                const schoolElements = Object.fromEntries(
                    schoolsElements.filter( e => e.id.includes( schoolID ) ).map( e => {
                        let key = e.id.includes( '-school-school-' ) ? e.id.split( '-school-school-' )
                            : isNewSchool ? e.id.split( schoolID.split( '-' )[ 2 ] + '-' )
                            : e.id.split( 'school-' );

                        key = key[ 1 ].includes( 'level' ) ? 'level' : (
                            isNewSchool && ( key[ 1 ].includes( 'logo' ) || key[ 1 ].includes( 'name' ) )
                        ) ? key[ 1 ].split( '-' )[ 1 ] : key[ 1 ];
                        return [ key, e.value.trim() ]
                    } )
                );
                if ( !schoolElements.name ) return

                const [ school, schoolName ] = [
                    schools[ schoolID ] || {},
                    get.capitalise( schoolElements.name )
                ];
                // console.log( schoolElements, schoolsElements );

                if ( isNewSchool ) schoolID = get.stringInNewFormat(
                    `${ schoolName } ${ get.capitalise( schoolElements.level ) } School`,
                    ' ',
                    { dashed: true }
                );

                obj[ schoolID ] = {
                    name: { full: schoolName, abbr: schoolName.split(' ').map( e => e[ 0 ] ).join( '' ) },
                    logo: schoolElements.logo,
                    locale: school?.locale || 'en-US',
                    level: schoolElements.level,
                    id: get.stringInNewFormat( `${ schoolName } ${ schoolElements.level } School`, ' ', { dashed: true } ),
                    hsls: [ get.hslFromHex( schoolElements[ 'colour-0' ] ), get.hslFromHex( schoolElements[ 'colour-1' ] ) ],
                    holidays: school?.holidays || {},
                    countyID: school?.countyID || county.id,
                    colours: [ schoolElements[ 'colour-label-0' ], schoolElements[ 'colour-label-1' ] ],
                };
            } );

            keys.push( 'schools' );
            values.push( obj );
        }

        window.location.hostname === 'localhost' && console.log( keys, values );
        toggleForm( e );
        updateCounty( keys, values );
    }

    function toggleForm({ target: { className, id } }) {
        id = id.startsWith( 'for' ) ? id
            : id.includes( 'schoolyear' ) ? 'forYear'
            : 'forSchools';

        setIsForm( isForm => ({ ...isForm, [ id ]: className.includes( 'edit' ) }) )
        setBlankSchoolFieldsetNum( () => 0 )
        setSchoolNames( () => [ '' ] )
    }

    function wrapDetail( value='', label='', isDate=false, className='', showLabel=false, isForForm=false ) {
        const props = { className };
        props[ ( isForForm && !value?.props && !isDate ) ? 'inputID' : 'id' ] = props.key = get.stringInNewFormat( label, ' ', { dashed: true } ) + ( !isForForm ? '-detail' : isDate ? '-date' : '-str' );
        // console.log( value?.type === 'Symbol(react.fragment)', value?.type, value );
        window.location.hostname === 'localhost' && console.log( !isForForm ? 'div props' : value?.props ? 'fieldset props' : isDate ? 'date props' : 'text input props', value, props );

        return !isForForm ? <div { ...props }>
            { showLabel && <span className={ styles.label }>{ label }</span> }
            { value }
        </div> : value?.props ? <fieldset { ...props }>{
            value
        }</fieldset> : isDate ? <DateInput
            { ...props }
            label={ label }
            schoolYear={ county.schoolYear }
            dashedYearMonthDay={ value }
        /> : <TextInputButton
            { ...props }
            buttonLabel={ label }
            inputLabel={ label }
            uncontrolledValue={ value }
        />
    }

    function wrapSection( id='', Children, className='', HeaderChildren, isForForm=false ) {
        const isSchools = id.includes( 'schools' );

        const props = {
            wrapper: { className: get.classNameFromArr([ styles.field, className ]) },
            section: { className: styles[ 'section-contents' ] },
            Button: {
                label: <SVG type={{ [ isForForm ? 'save' : 'edit' ]: true }}/>,
                type: isForForm ? 'submit' : 'button',
                className: isForForm ? styles.save : styles.edit,
                id: isSchools ? 'forSchools' : 'forYear',
            },
        };
        props.wrapper.key = props.wrapper.id = id + '-wrap-section';
        if ( !isForForm ) props.Button.onClick = toggleForm
        if ( isForForm ) props.wrapper.onSubmit = handleSubmit

        const EditOrSaveButton = <Button { ...props.Button }/>;
        const Contents = <>
            <header>
                { !isForForm && EditOrSaveButton }
                { HeaderChildren }
            </header>

            { isForForm ? <section { ...props.section }>{ Children }</section>
                : <fieldset { ...props.section }>{ Children }</fieldset> }

            { isForForm && EditOrSaveButton }
        </>;

        return isForForm ? <form { ...props.wrapper }>{ Contents }</form>
            : <div { ...props.wrapper }>{ Contents }</div>
    }

    function getDateElement( className='', date={}, label='' ) {
        const props = {
            label, className,
            schoolYear: county.schoolYear,
            dashedYearMonthDay: [
                date.year,
                `${ date.month }`.padStart( 2, '0' ),
                `${ date.day }`.padStart( 2, '0' )
            ].join( '-' )
        };
        props.id = props.key = get.stringInNewFormat( label, ' ', { dashed: true } ) + ( !isForm.forYear ? '-detail' : '-date' );

        return isForm.forYear ? <DateInput { ...props }/> : <p key={ props.key }>
            <span>
                { get.monthName( date.month ) }&nbsp;
                { date.day }&nbsp;
                <sup>{ get.ordinalSuffix( date.day, county.locale ) }</sup>
            </span>
            <sup>( { date.year } )</sup>
        </p>
    }

    // COMPONENTS
    const Children = <>
        { wrapSection( 'schoolyear-dates', [ 'schoolyear', 'quarters' ].map( category => {
            const isSchoolYear = category === 'schoolyear';

            return <Wrapper
                key={ category + '-wrapper' }
                fieldset={ isForm.forYear }
                section={ !isForm.forYear }
                props={{
                    className: isSchoolYear ? styles.dates : styles.quarters,
                    key: category + ( isForm.forYear ? '-fieldset' : '-section')
                }}
                Children={ isSchoolYear ? [ 'first day', '⬇', 'last day' ].map(
                    ( label, i ) => i % 2 ? label : getDateElement(
                        styles[ 'schoolyear-day-month' ],
                        county.schoolYear[ label.includes( 'first' ) ? 'start' : 'end' ],
                        label,
                    )
                ) : quarters.map( ( quarter, i ) => {
                    const [ quarterID, quarterIsLessThanTen, quarterType ] = [
                        quarter?.id ? `${ quarter.id }` : `${ i + 1 }`,
                        typeof quarter.id === 'number' && quarter.id < 10,
                        quarter?.type || 'quarter'
                    ];

                    return <div className={ styles.quarter } key={`quarter-${ i }`}>{[
                        !isForm.forYear && <header key='county-header'>
                            { quarterID }
                            { quarterIsLessThanTen && <sup>{
                                get.ordinalSuffix( quarter.id, county.locale.split( '-' )[ 0 ] )
                            }</sup> }
                            &nbsp;{ get.capitalise( quarterType ) }
                        </header>,
                        ( quarterIsLessThanTen ? [ quarterType, quarter.id ].join(' ') : [ quarter.id, quarterType ].join(' ') ) + ' first day',
                        '⮕',
                        ( quarterIsLessThanTen ? [ quarterType, quarter.id ].join(' ') : [ quarter.id, quarterType ].join(' ') ) + ' last day',
                    ].map( ( subLabel, j ) => j % 2 ? getDateElement(
                        styles.date,
                        quarter[ subLabel.includes( 'first' ) ? 'start' : 'end' ],
                        subLabel
                    ) : subLabel )}</div>
                } ) }
            />

            // return wrapDetail( YearSection, label, true, styles[ 'schoolyear-day-month' ], false, isForm.forYear )
        } ), styles.schoolyear, 'School Year', isForm.forYear ) }

        { wrapSection( county.id + '-schools', (
            schools === null ? []
                : !isForm.forSchools ? Object.keys( schools )
                : [ ...Object.keys( schools ), ...get.numRange( 0, blankSchoolFieldsetNum ) ]
        ).map( schoolID => {
            if ( typeof schoolID === 'number' ) return isForm.forSchools ? getSchoolForm( schoolID ) : ''

            const school = schools[ schoolID ];
            const [ level, ValueChildren ] = [
                get.capitalise( school?.level || 'high' ), <>{
                    school.colours.map( ( colour, i ) => {
                        const { h, s, l } = school?.hsls?.[ i ] || get.hslFromName( colour, true );

                        return <Wrapper
                            key={ [ school.id, colour, 'wrapper' ].join( '-' ) }
                            fieldset={ isForm.forSchools }
                            div={ !isForm.forSchools }
                            props={{
                                className: styles.colour,
                                key: [
                                    school.id, colour, isForm.forSchools ? 'fieldset' : 'div'
                                ].join( '-' )
                            }}
                            Children={ isForm.forSchools ? <>
                                <TextInputButton
                                    inputID={ [ school.id, 'colour', i ].join( '-' ) }
                                    inputType='color'
                                    uncontrolledValue={{ h, s, l }}
                                />
                                <TextInputButton
                                    inputID={ [ school.id, 'colour', 'label', i ].join( '-' ) }
                                    inputType='text'
                                    inputLabel='color name'
                                    uncontrolledValue={ colour }
                                />
                            </> : <>
                                <sup>{ colour }</sup>
                                <div style={{
                                    background: `hsl( ${ h }, ${ s }, ${ l } )`,
                                    color: get.textColour( get.hexFromHSL( h, s, l ) )
                                }}/>
                            </> }
                        />
                    } )
                }</>
            ];

            const value = isForm.forSchools ? <>
                <fieldset className={ styles.colours }>{ ValueChildren }</fieldset>
                <fieldset className={ styles[ 'logo-and-name' ] }>
                    <TextInputButton
                        inputID={ [ school.id, 'school', 'logo' ].join( '-' ) }
                        inputType='text'
                        inputLabel='logo'
                        uncontrolledValue={ school?.logo || '🐝' }
                    />
                    <TextInputButton
                        inputID={ [ school.id, 'school', 'name' ].join( '-' ) }
                        inputType='text'
                        inputLabel='school name'
                        uncontrolledValue={ school.name.full.includes( school?.level || 'High' ) ?
                            school.name.full.split( get.capitalise( school?.level || 'High' ) )[ 0 ] : school.name.full }
                    />
                </fieldset>
                <RadioGroup
                    classPrefix={ [ school.id, 'school', 'level' ].join( '-' ) }
                    defaultCheckedButtons={[ 'High' ]}
                    groupName='school-level'
                    mainLabels={[ 'High', 'Secondary', 'Middle', 'Elementary' ]}
                    mainLabelType='radio'
                />
            </> : <>
                <div className={ styles.colours }>{ ValueChildren }</div>
                <div className={ styles.logo }>{ school?.logo || '🐝' }</div>
                <span>{ school.name.full.split( get.capitalise( level ) )[ 0 ] }</span>
                <span>{ school?.level || get.capitalise( school?.level || 'high' ) }</span>
            </>;

            return wrapDetail( value, schoolID, false, styles.school, false, isForm.forSchools )
        } ), styles.schools, county.name.abbr + ' Schools', isForm.forSchools ) }
    </>;

    // RENDER
    return <section className={ get.classNameFromArr([
        Object.values( isForm ).some( e => e ) && styles[ 'form-mode' ],
        styles.details,
    ]) }>{ Children }</section>
}

export default County;