import { useSortable } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import styles from './SortableItem.module.css';
import Button from '../../pages/Button';
import SVG from '../Images/SVG';
import get from '../../../constants/get';

const SortableItem = ({
    Child,
    className = '',
    handlePosition = { top: false, right: false, bottom: false, left: false },
    id = '',
    // isDisabled = false,
}) => {
    const {
        attributes,
        listeners,
        setNodeRef,
        // setActivatorNodeRef,
        transform,
        transition,
        isSorting,
        isDragging,
        isOver,
    } = useSortable({ id });
    // } = useSortable({ id, disabled: isDisabled });

    const hasHandle = Object.values( handlePosition ).some( position => position );

    // PROPS & ATTRIBUTES
    const props = {
        item: {
            ...attributes,
            ...( hasHandle ? {} : listeners ),
            className: get.classNameFromArr([
                className,
                styles.sortable,
                // isDisabled && styles.disabled,
                isSorting && styles.sorting,
                isOver && styles.over,
                isDragging && styles.dragging,
                hasHandle && styles[ 'with-handle' ],
                hasHandle && styles[ `on-${ Object.keys( handlePosition )
                    .find( key => handlePosition[ key ] ) }` ],
            ]),
            ref: setNodeRef,
            style: {
                transition,
                transform: CSS.Transform.toString( transform )
            },
        },
        button: {
            className: styles.button,
            hoverColours: [ 'hsla( 45, 0%, 90%, .5 )' ],
            label: <SVG type={{ [ [
                ( handlePosition.top || handlePosition.bottom ) ? 'horizontal' : 'vertical',
                'dotted drag handle'
            ].join(' ') ]: true }} />,
            looksLikeLink: true,
            propsFromParent: {
                ...( hasHandle ? listeners : {} ),
                className: styles.handle,
            },
        },
    };

    // COMPONENTS
    const Item = hasHandle ? <><Button { ...props.button }/>{ Child }</> : Child;

    // RENDER
    return <li { ...props.item }>{ Item }</li>
}

export default SortableItem