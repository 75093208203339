import { useContext, useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import Kebab from '../../modular/Buttons/Kebab';
import UserContext from '../../../contexts/UserContext';
import AddCourseForm from '../../modular/Form/AddCourseForm';
import get from '../../../constants/get';
import styles from './CourseloadPane.module.css';
import Button from '../Button';
import SVG from '../../modular/Images/SVG';

const CourseCard = ({
    addCourse = () => {},
    course = {},
    currentBoard = {},
    deleteCourse = () => {},
    domain = ''
}) => {
    const navigate = useNavigate();
    const { data: { uid: userID }, email, plan } = useContext( UserContext );

    const [ mode, setMode ] = useState({ delete: false, confirmCopy: false });
    const [ showForm, setShowForm ] = useState( false );

    // Animate card after copying successfully
    useEffect( () => {
        const timer = setInterval( () => setMode( () => ({ delete: false, confirmCopy: false }) ), 1300 );
        !mode.confirmCopy && clearInterval( timer );

        return () => clearInterval( timer )
    }, [ mode.confirmCopy ] )

    // Animate card after copying successfully
    // useEffect( () => {
    //     const timer = setInterval( () => setMode( () => ({ delete: false, confirmCopy: false }) ), 1300 );
    //     !mode.confirmCopy && clearInterval( timer );

    //     return () => clearInterval( timer )
    // }, [ mode.confirmCopy ] )

    const [ isButton, isQueenBee ] = [ course === 'add-course', userID === course.ownerID ];

    let [ ReqAssignments, courseURL, boardStatus, boardClass, boardAssignments ] = [
        <li className={ styles[ 'nothing-due' ] }>-- no boxes due --</li>,
        !isButton && [ '', isQueenBee ? get.username( email ) : 'workerbee', course.id.db ].join( '/' ),
        'missing board',
        ...Array( 2 ).fill( '' )
    ];

    const inviteURL = [ domain, 'workerbee', course?.id?.db ].join( '/' );
    const boardURL = [ courseURL, 'boards', currentBoard.num + 1 ].join( '/' );
    // console.log( '🧑‍🏫', course );

    // FUNCTIONS
    const copyInviteLink = async () => await navigator.clipboard.writeText( inviteURL )
    const goToLeaderBoard = () => navigate( [ '', get.username( email ), course?.id?.db, 'activity' ].join( '/' ) )
    const handleCopyingLink = () => copyInviteLink() && setMode( () => ({ delete: false, confirmCopy: true }) )
    const toggleForm = () => setShowForm( showForm => !showForm )
    const toggleDeleteMode = () => setMode( mode => ({ delete: !mode.delete, confirmCopy: false }) )

    // PROPS & ATTRIBUTES
    // console.log( '🐝 COURSE:\n', course );
    const attributes = {
        button: { className: styles.add, onClick: toggleForm },
        div: { copy: { className: get.classNameFromArr([ styles.copied, mode.confirmCopy && styles.show ]) } },
        li: {
            className: get.classNameFromArr([
                styles.square,
                mode.delete ? styles[ 'delete-mode' ]
                    : isButton && !showForm ? styles.button
                    : isButton && showForm ? styles.form : styles.card,
                course?.guests?.includes( email ) && styles[ 'worker-hive' ],
            ]),
            key: showForm ? 'new-course-form'
                : showForm === null ? `${ course?.id?.db }-card` : 'add-course-button',
        },
    };

    const props = {
        AddCourseForm: {
            addCourse, toggleForm,
            courseID: course?.id?.db || course,
            isFree: plan.isFree,
        },
        CancelDeleteButton: { label: 'cancel', looksLikeLink: true, onClick: toggleDeleteMode },
        DeleteButton: {
            backgroundGradientColours: [ 'orangered', 'maroon' ],
            id: `delete-${ course?.id?.db }`,
            onClick: deleteCourse,
        },
        Kebab: {
            className: styles.kebab,
            icons: [ 'fa-solid fa-trash-can', 'fa-regular fa-copy', 'fa-solid fa-user-plus' ],
            menuLabels: [ `delete ${ course.title }`, 'copy invite link', 'add students/guests' ],
            menuOnClicks: [ toggleDeleteMode, handleCopyingLink, goToLeaderBoard ],
        },
        SVG: { type: { trash: true } }
    };

    props.DeleteButton.hoverColours = [ props.DeleteButton.backgroundGradientColours[ 0 ] ];
    props.DeleteButton.hoverTextColour = props.DeleteButton.labelColour = props.SVG.fill1 = '#fdfdfd';
    props.DeleteButton.label = <><SVG { ...props.SVG }/> delete</>;

    // COMPONENTS
    if ( !isButton && !showForm && currentBoard && 'weekNum' in currentBoard ) {
        boardAssignments = course.content?.assignments?.[ currentBoard.num ];

        const boardCompletion = currentBoard.completion;
        const boardCompletionSum = Object.values( boardCompletion ).reduce( ( sum, num ) => sum + num, 0 );

        [ boardClass, boardStatus ] = boardCompletionSum === 0 ? [ styles.empty, 'board empty' ]
            : boardCompletionSum === 8 ? [ styles.full, 'board full' ]
            : boardCompletion.req === 2 && boardCompletion.prereq > 2 ? [ styles.enough, 'board sufficient' ]
            : [ styles[ 'in-progress' ], 'board in progress' ];

        if ( boardAssignments ) ReqAssignments = boardAssignments.filter( assignment => !assignment.isPrereq )
            .map( ( assignment, i ) => {
                const isValidAssignment = [ '', ' ' ].every( str => str !== assignment.directions );

                let listItem = false;
                if ( isValidAssignment ) listItem = <li
                    className={ styles.assignment }
                    key={ `req-assignment-${ i }-${ assignment.id }` }
                >
                    <span><i className={ `fa-solid fa-${ assignment.isChecked ? 'check' : 'square' } fw` }/></span>
                    { assignment.directions }
                </li>

                return listItem
            } ).filter( e => e );
    }

    // RENDER
    return <li { ...attributes.li }>
        { mode.delete ? <div>
            <span>Delete</span><br />{ course.title.toLocaleUpperCase() } <span>?</span>
            <br /><br />
            <section className={ styles.buttons }>
                <Button { ...props.DeleteButton }/>
                <Button { ...props.CancelDeleteButton }/>
            </section>
        </div> : showForm ? <AddCourseForm
            { ...props.AddCourseForm }
        /> : isButton ? <button { ...attributes.button }>
            <i className='fa-solid fa-plus'/>&nbsp;{ course.includes( 'add' ) ? 'Add' : 'Join' } a Hive
        </button> : <>
            <Link className={ styles[ 'course-name' ] } to={ boardURL }>
                <span className={ styles.name }>{ course.title }</span>
                <span className={ get.classNameFromArr([ styles.status, boardClass ]) }>
                    <div className={ styles.light }/> { boardStatus }
                </span>
            </Link>
            { course.ownerID === userID && <Kebab { ...props.Kebab }/> }
            <Link className={ styles[ 'relevant-assignments' ] } to={ boardURL }>
                <ul>{ ReqAssignments }</ul>
            </Link>
        </> }
        { mode.confirmCopy && <div { ...attributes.div.copy }>Copied!</div> }
    </li>
}

export default CourseCard;