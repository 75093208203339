import { useState } from 'react';
import CallToAction from './CallToAction';
import Modal from '../Containers/Modal';
import styles from './ModalButton.module.css';

const ModalButton = ({
    autoFocus=false,
    buttonClass='',
    isLink=false,
    isPrimary=false,
    label='',
    labelClass='',
    modalChildren=[],
    modalClass='',
    needsCarets = false,
}) => {
    const [ showModal, setShowModal ] = useState( false );

    // FUNCTIONS
    const toggleModal = ({ target: { className } }) => {
        const isValidTrigger = className.includes( 'container' )
            || ( !className.includes( 'ToggleAndLabel' ) && className.includes( 'button' ) );

        isValidTrigger && setShowModal( showModal => !showModal )
    }

    // COMPONENTS
    const labelComponent = label ? <span className='button-span'>{ label }</span>
        : <i className={`button-icon ${ labelClass }`} />

    // RENDER
    return showModal ? <Modal
        modalChildren={ modalChildren }
        modalClass={`${ modalClass } ${ styles.modal }`}
        toggleModal={ toggleModal }
        needsCarets={ needsCarets }
    /> : <CallToAction
        label={ labelComponent }
        autoFocus={ autoFocus }
        isPrimary={ isPrimary }
        onClick={ toggleModal }
        className={`${ buttonClass } ${ styles[`modal-${ isLink ? 'link' : 'button' }`] }`}
    />
}

export default ModalButton;