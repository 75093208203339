import { useEffect, useState } from 'react';
import styles from './CopyToClipboard.module.css';

const CopyToClipboard = ({ className='', url='' }) => {
    const [ justCopied, setJustCopied ] = useState( false );

    useEffect( () => {
        const timer = setInterval( () => setJustCopied( () => false ), 1300 );
        !justCopied && clearInterval( timer );

        return () => clearInterval( timer )
    }, [ justCopied ] );

    // FUNCTIONS
    async function copyShareLink() { return await navigator.clipboard.writeText( url ) }
    function handleClick () { copyShareLink() && setJustCopied( () => true ) }

    // RENDER
    return <>
        { justCopied && <div className={ justCopied ? styles.visible : '' }>COPIED !</div> }
        <button className={ className } onClick={ handleClick }><i className='fa-regular fa-copy'/></button>
    </>
}

export default CopyToClipboard;