import styles from './Logo.module.css'

const Logo = ({
    href='',
    isBoardCutOut=false,
    isJustBee=false,
    isMirrored=false,
    isPremium=false,
}) => {
    const isLogo = !isBoardCutOut && !isJustBee && !isJustBee && !isPremium;

    const img = <img
        className={ styles.logo }
        src={ isLogo ? '/favicon.png'
            : isPremium ? '/bumble-coat-of-arms.png'
            : isMirrored ? '/Transparent Favicon.svg'
            : isJustBee ? '/RedBottom.png' : '/bumble-cut-out.png' }
        alt={ isPremium ? 'coat of arms' : 'logo' }
    />;

    return (
        href === '' ? img  : <a href={href}>{ img }</a>
    )
}

export default Logo;