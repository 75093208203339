import { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { Link, Outlet, useParams } from 'react-router-dom';
import moduleInfo from '../../../constants/moduleInfo';
import UserContext from '../../../contexts/UserContext';
import HiveNav from '../../modular/Nav/HiveNav';
import styles from './Hive.module.css';
import HiveContext from '../../../contexts/HiveContext';
import get from '../../../constants/get';
import courseInfo from '../../../constants/courseInfo';
import fireRead from '../../../services/edding/readFireData';
import Nav from '../Nav';
import HomeHive from '../../modular/Images/svg/HomeHive';
import SubNav from '../../modular/Nav/SubNav';
import GradientText from '../../modular/GradientText';
import Button from '../Button';
import SVG from '../../modular/Images/SVG';
import Footer from '../../modular/Footer';
import LoadingHive from '../../modular/Loaders/LoadingHive';

const [ subMenuHeaders, subcollections ] = [
    [ 'queen hives', 'worker hives' ],
    [ 'Assignments', 'Boards', 'Students', 'Submissions', 'Syllabus', 'Units' ],
];

const Hive = () => {
    let { id: courseID } = useParams();
    const user = useContext( UserContext );

    const [ course, setCourse ] = useState([
        ...user.courses.queen,
        ...user.courses.worker
    ].find( courseObj => courseObj.id.db === courseID ));

    const [ hasLoaded, setHasLoaded ] = useState( course?.content?.students );
    const [ isQueenBee, setIsQueenBee ] = useState( user.data.uid === course.ownerID );

    const {
        county,
        currentBoard,
        currentQuarter,
        currentWeekNum,
        getBoard,
        getQuarter,
        isActuallyQueenBee,
        langs,
        // lastWeekOfSchoolNum,
        placeholderBoards,
        school,
        schoolYear
    } = useMemo( () => {
        // if ( !course.content?.students ) return Object.fromEntries([
        //     [ 'currentBoard', {} ],
        //     [ 'county', {} ],
        //     [ 'currentQuarter', null ],
        //     [ 'currentWeekNum', null ],
        //     [ 'getBoard', null ],
        //     [ 'getQuarter', null ],
        //     [ 'isActuallyQueenBee', null ],
        //     [ 'langs', [] ],
        //     [ 'placeholderBoards', null ],
        //     [ 'school', {} ],
        //     [ 'schoolYear', {} ],
        // ])

        const county = user.counties.find( countyObj => course.schoolID in countyObj.schools );
        const [ lastWeekOfSchoolNum, placeholderBoards ] = [
            get.lastArrayElement( get.lastArrayElement( county?.schoolYear?.quarters )?.weeks ),
            courseInfo.getPlaceholderBoards( county.schoolYear )
        ];
        // console.log( county );
        const getQuarter = ({ id='', weekNum=0 }) => county.schoolYear.quarters.find(
            quarter => id ? quarter.id === id : quarter.weeks.includes( weekNum )
        )

        const getBoard = ( weekNum=1 ) => {
            let [ placeholderBoard, board, lastBoard ] = [
                placeholderBoards.find( board => board?.weekNum === weekNum ),
                course.content.boards.find( board => board?.weekNum === weekNum ),
                get.lastArrayElement( course.content.boards )
            ];

            const isAfterYearEnd = !placeholderBoard;
            if ( isAfterYearEnd ) return lastBoard

            const boardExists = !!board;
            if ( boardExists && isQueenBee ) return board

            if ( !isQueenBee ) {
                const minimumRequirement = 5;
                const submissions = get.workerBeeSubmissions(
                    placeholderBoard.num, course.content.submissions, user.email, true
                );

                if ( submissions.length >= minimumRequirement ) {
                    return getBoard( weekNum + 1 )
                } else { return boardExists ? board : placeholderBoard }
            }

            if ( !boardExists ) {
                course.content.boards[ placeholderBoard.num ] = { ...placeholderBoard };
                return course.content.boards[ placeholderBoard.num ]
            }
        }

        const { months } = county?.schoolYear; // holidays
        let currentWeekNum = get.currentWeekNum( months );
        if ( currentWeekNum > lastWeekOfSchoolNum ) currentWeekNum = lastWeekOfSchoolNum

        // if ( !Array.isArray( months ) ) county.schoolYear.months = Object.values( months ).map(
        //     monthObj => {
        //         // window.location.hostname === 'localhost' && console.log( '🟢 monthObj:', monthObj );
        //         const weeks = Object.entries( monthObj.weeks ).forEach( weekNumAndObj => {
        //             const [ weekNum, weekObj ] = weekNumAndObj;
        //             const daysInClass = weekObj?.days?.filter( ( dayNum, i ) => {
        //                 const [ dayIsHoliday, isCurrentMonthsDay ] = [
        //                     holidays?.[ monthObj.i ]?.days?.includes( dayNum ),
        //                     weekObj?.months?.[ i ] === monthObj.i
        //                 ];
        //                 // get.log({
        //                 //     fileName: 'Hive.jsx',
        //                 //     functionDirectionsOrComponentName: 'filter out holidays',
        //                 //     lineNumber: 105,
        //                 //     str: `${ county.schoolYear.months[ weekObj?.months?.[ i ] ].name.short }. ${ get.ordinalNum( dayNum ) } is ${ holidays?.[ monthObj.i ]?.days?.includes( dayNum ) ? '' : 'NOT ' }a holiday: ([ ${ holidays?.[ monthObj.i ]?.days ? holidays?.[ monthObj.i ]?.days?.join(' ') : '' } ])`,
        //                 //     format: { isNeeded: true, isTrueFalseToggle: true },
        //                 //     bool: { trueFalse: holidays?.[ monthObj.i ]?.days?.includes( dayNum ) }
        //                 // });
        //                 return !isCurrentMonthsDay || !dayIsHoliday
        //             } )

        //             if ( daysInClass.length < 5 ) monthObj.weeks[ weekNum ].daysInClass = daysInClass
        //             // get.log({
        //             //     fileName: 'Hive.jsx',
        //             //     functionDirectionsOrComponentName: 'transform { months } into [ months ]',
        //             //     lineNumber: 105,
        //             //     str: [
        //             //         `📆 ${ daysInClass.length < 5 ? 'NEW ': '' }WEEK #${ weekNum }`,
        //             //         `[ daysInClass ]: ${ daysInClass.join('-') }`,
        //             //         ...Object.entries( weekObj ).map( keyVal => `${ keyVal[ 0 ].toLocaleUpperCase() }: ${ keyVal[ 1 ].join(' ') }` )
        //             //     ].join( '\n' ),
        //             //     format: { isNeeded: true, isTrueFalseToggle: true },
        //             //     bool: { trueFalse: daysInClass.length < 5 }
        //             // });
        //         } );

        //         // window.location.hostname === 'localhost' && console.log( monthObj.weeks );

        //         return monthObj
        //     }
        // )

        const memoObj = {
            currentWeekNum, county, getBoard, getQuarter, lastWeekOfSchoolNum, placeholderBoards,
            currentBoard: getBoard( currentWeekNum ),
            currentQuarter: getQuarter({ weekNum: currentWeekNum }),
            isActuallyQueenBee: user.data.uid === course.ownerID,
            langs: [
                user.locale.includes( '-' ) ? user.locale.split( '-' )[ 0 ] : user.locale,
                course.subject === 'french' ? 'fr' : course.subject === 'spanish' ? 'es' : null
            ],
            school: county?.schools?.[ course.schoolID ],
            schoolYear: county.schoolYear,
        }

        get.log({
            fileName: 'Hive.js',
            lineNumber: 163,
            functionDirectionsOrComponentName: 'useMemo',
            isFunction: true,
            str: memoObj
        });
        return memoObj
    }, [ course, isQueenBee, user ] );

    const [ lang, setLang ] = useState( langs[ 1 ] || langs[ 0 ] );
    const [ active, setActive ] = useState({ board: currentBoard, quarter: currentQuarter });

    const unit = useMemo( () => get.unit( active.board, course ) || {
        background: { ...get.randomHSL( false ) }
    }, [ active.board, course ] );

    const menu = useMemo( () => {
        const { hive } = moduleInfo.navMenus;

        for ( let entry of Object.entries( hive.labels ) ) {
            const [ key, labelObj ] = entry;

            if ( [ 'grade' ].includes( labelObj.label )
                && user.data.email !== 'bumbleboard@gmail.com'
            ) {
                delete hive.labels[ key ];
            } else {
                let URLArr = [
                    '',
                    isQueenBee ? get.username( user.email ) : 'workerbee',
                    courseID,
                    labelObj.URLlabel
                ];

                if ( labelObj.URLlabel === 'boards' && currentBoard ) URLArr.push( currentBoard.num + 1 )
                labelObj.to = URLArr.join( '/' );
            }
        }

        return hive
    }, [ courseID, currentBoard, isQueenBee, user ] );

    const getWeekStartAndEnd = useCallback( () => {
        const { monthNum, weekNum } = active.board;
        // get.log({
        //     fileName: 'Hive.js',
        //     functionDirectionsOrComponentName: 'getWeekStartAndEnd',
        //     isFunction: true,
        //     lineNumber: 209,
        //     str: `MONTHNUM: ${ monthNum } WEEKNUM: ${ weekNum }`
        // });

        const week = schoolYear.months[ monthNum ].weeks[ weekNum ];
        const getIndex = ( searchArr=[], searchElement ) => searchArr.findIndex( e => e === searchElement )

        const [ startI, endI ] = [
            getIndex( week.days, week.daysInClass[ 0 ] ),
            getIndex( week.days, week.daysInClass.slice().pop() )
        ];

        let [ startMonth, endMonth ] = [ week.months[ startI ], week.months[ endI ] ];
        let [ startDay, endDay ] = [ week.days[ startI ], week.days[ endI ] ];
        [ startMonth, endMonth ] = [
            get.monthName( startMonth, lang, false ),
            get.monthName( endMonth, lang, false ),
        ];

        const isSameMonth = startMonth === endMonth;

        return [ 'fr', 'es' ].includes( lang ) ? <h2>
            { isSameMonth ? startDay : [
                startDay,
                lang === 'fr' ? startMonth : `de ${ startMonth }`
            ].join(' ') }
            &nbsp;- { [
                endDay,
                lang === 'fr' ? endMonth : `de ${ endMonth }`
            ].join(' ') }
        </h2> : <h2>
            { [ startMonth, startDay ].join(' ') }
            <sup>{ get.ordinalSuffix( startDay, lang, false ) }</sup>
            &nbsp;- { isSameMonth ? endDay : [ endMonth, endDay ].join(' ') }
            <sup>{ get.ordinalSuffix( endDay, lang, false ) }</sup>
        </h2>
    }, [ active.board, lang, schoolYear.months ] )

    const getBoardInfo = useCallback( ( Buttons=<></> ) => {
        function getHeaderStr( str={ week: false, quarter: false, unit: false, no: false } ) {
            switch ( lang ) {
                case 'en': return str.week ? 'week' : str.quarter ? 'quarter' : str.unit ? 'theme' : 'no';
                case 'es': return str.week ? 'semana' : str.quarter ? 'trimestre' : str.unit ? 'tema' : 'ningún';
                case 'fr': return str.week ? 'semaine' : str.quarter ? 'trimestre' : str.unit ? 'thème' : 'aucun';

                default: return str.week ? 'week' : str.quarter ? 'quarter' : str.unit ? 'theme' : 'no theme';
            }
        }

        const [ week, quarter, boardNum, quarterID, unitStr, unitTheme ] = [
            getHeaderStr({ week: true }),
            getHeaderStr({ quarter: true }),
            active.board?.num * 1 + 1,
            active.quarter?.id || null,
            unit?.theme || [ getHeaderStr({ no: true }), getHeaderStr({ unit: true }) ].join(' '),
        ];

        const [ boardNumOrdinal, quarterIDOrdinal ] = [
            get.ordinalSuffix( boardNum, lang, false ),
            quarterID * 1 ? get.ordinalSuffix( quarterID, lang, true ) : quarterID,
        ];

        return <section className={ styles[ 'about-board' ] }>
            { getWeekStartAndEnd() }
            { Buttons.props?.children && <fieldset className={ styles.buttons }>{ Buttons }</fieldset> }

            <ul>
                <li>{ week === 'week' ? `${ week } ${ boardNum }` : <>
                    { boardNum }<sup>{ boardNumOrdinal }</sup> { week }
                </> }</li>

                { quarterID && <li>{ quarterID }<sup>{ quarterIDOrdinal }</sup> { quarter }</li> }

                <li>{ unitTheme } <span>{ unitStr }</span></li>
            </ul>
        </section>
    }, [
        active.board?.num,
        active.quarter?.id,
        lang,
        unit.theme,
        getWeekStartAndEnd
    ] )
    // const courseInfoIsLoaded = 'units' in course.content;

    // Pull all course subcollection data
    useEffect( () => {
        if ( !course?.content?.units ) {
            const subcollectionsToRead = subcollections.filter(
                subcollection => subcollection.toLocaleLowerCase() in course.content === false
            );

            fireRead.findCourseDocData(
                course.id.db, user.schoolYearID, subcollectionsToRead, 'id' in course
            ).then( obj => {
                setCourse( oldCourse => {
                    oldCourse.content = { ...course.content, ...obj };
                    // window.location.hostname === 'localhost' && console.log( 'oldCourse', oldCourse );
                    return oldCourse
                } )
                setHasLoaded( () => true )
            } );
        }
        // if ( !course?.content?.units ) setCourse( course => {
        //     const filteredSubcollections = subcollections.filter(
        //         subcollection => subcollection.toLocaleLowerCase() in course.content === false
        //     );

        //     fireRead.findCourseDocData(
        //         course.id.db, user.schoolYearID, filteredSubcollections, 'id' in course
        //     ).then( obj => { course.content = { ...course.content, ...obj } } );

        //     return course
        // } )
    }, [ course, user.schoolYearID ] );

    const [ emailHandle, rules ] = [
        get.username( user.email ),
        user.premium ? user.premium.rules.board : courseInfo.getPremiumObj().rules.board
    ];

    if ( !user.premium ) rules.isLinear = rules.isWeekly = true
    schoolYear.quarters?.forEach( quarter => {
        if ( 'nextQuarterI' in quarter === false ) {
            const i = schoolYear.quarters.findIndex( q => q.id === quarter.id );
            [ quarter.nextQuarterI, quarter.prevQuarterI ] = [
                i === ( schoolYear.quarters.length - 1 ) ? 0 : i,
                ( i === 0 ? schoolYear.quarters.length : i ) - 1,
            ];

            if ( quarter.weeks.length <= 1 ) quarter.weeks = Array(
                get.lastArrayElement( quarter.weeks ) - get.lastArrayElement( schoolYear.quarters[ quarter.prevQuarterI ].weeks )
            ).fill( get.lastArrayElement( schoolYear.quarters[ quarter.prevQuarterI ].weeks ) + 1 )
                .map( ( num, i ) => num + i )
        }
    } );

    // FUNCTIONS
    function changeBoardOrQuarter(
        change={ board: false, quarter: false },
        value={ boardObj: null, quarterObj: null, weekNum: null }
    ) {
        // console.log('2️⃣', { board: value.boardObj, quarterObj: value.quarterObj, weekNum: value.weekNum });
        setActive( active => ({
            board: change.board ? ( value.boardObj || getBoard( value.weekNum ) ) : active.board,
            quarter: change.quarter ? ( value.quarterObj || getQuarter({ weekNum: value.weekNum }) ) : active.quarter
        }) )
    }

    function playAudio() { window.location.hostname === 'localhost' && console.log( 'playing audio...' ) }
    function toggleBeeType() { setIsQueenBee( isQueenBee => !isQueenBee ) }
    function toggleLanguage() { setLang( oldLang => langs.find( l => l !== oldLang ) ) }

    // CONTEXTS, PROPS, & ATTRIBUTES
    const contextValues = {
        activeBoard: active.board,
        activeQuarter: active.quarter,
        changeBoardOrQuarter,
        county,
        course,
        currentBoard,
        currentQuarter,
        currentWeekNum,
        getQuarter,
        Header: getBoardInfo(<>
            { langs[ 1 ] && <Button label={ <i className='fa-solid fa-language'/> } onClick={ toggleLanguage }/> }
            <Button label={ <i className='fa-solid fa-volume-high'/> } onClick={ playAudio }/>
            { isActuallyQueenBee && <Button label={ <SVG type={ isQueenBee ? { eye: true } : { edit: true } }/> } onClick={ toggleBeeType }/> }
        </>),
        isActuallyQueenBee,
        isQueenBee,
        langs,
        lang,
        placeholderBoards,
        rules,
        school,
        schoolYear,
        toggleBeeType,
        toggleLanguage,
    };
    // console.log( '🐝', user, contextValues );

    // COMPONENTS
    const HomeButton = <Link
        to={ `/${ emailHandle }` }
        className={ styles.home }
    ><HomeHive /></Link>;
    // const CourseMenu = <div className={ styles.course }>{ CourseNameAndBoardInfo }</div>

    const CoursesNav = <SubNav
        labelIsGradient
        // menuLabel={ course.title }
        menuLabelHeaders={ user.courses.queen[ 0 ] && user.courses.worker[ 0 ] && subMenuHeaders }
        menuLinks={[
            user.courses.queen.map( course => [
                '', get.username( user.data.email ), course.id.db, 'boards', active.board?.num + 1
            ].join( '/' ) ),
            user.courses.worker.map( course => [ '', 'workerbee', course.id.db ].join( '/' ) ),
        ]}
        menuLabels={[
            user.courses.queen.map( course => course.title ),
            user.courses.worker.map( course => course.title ),
        ]}
    />;

    // RENDER
    return <HiveContext.Provider value={ contextValues }>
        <article className={ get.classNameFromArr([
            styles.hive, user.data.uid && styles[ 'logged-in' ]
        ]) }>
            <Nav
                leftIcon={ CoursesNav }
                leftElementIsNOTGradient
                leftElement={ <header className={ styles.info }>
                    <GradientText getTextJSX={
                        ( className='' ) => <h1 className={ className }>{ course.title }</h1>
                    }/>
                    { getBoardInfo() }
                </header> }

                rightIcon={ <article style={{ display: 'flex' }}>
                    { typeof menu !== 'boolean' && <HiveNav isOutsideHero menu={ menu }/> }
                    { HomeButton }
                </article> }
            />

            { hasLoaded ? <Outlet /> : <LoadingHive width='2em'/> }

            <Footer showLogo/>
        </article>
    </HiveContext.Provider>
}

export default Hive;