import get from '../../../constants/get';
import Button from './Button';

const CallToAction = ({
    autoFocus = false,
    className = '',
    id = '',
    isPrimary = true,
    label = 'SIGN UP / SIGN IN',
    onClick = () => {},
    style = {},
    type = 'button',
}) => {
    // PROPS & ATTRIBUTES
    const props = {
        autoFocus ,id, label, onClick, style, type,
        className: get.classNameFromArr([
            className,
            isPrimary ? 'primary' : 'secondary'
        ]),
    };

    // RENDER
    return <Button { ...props }/>
}

export default CallToAction;