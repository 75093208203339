import Card from '../Containers/Card';

const FeatureText = ({ title='', description='', classNames='' }) => {
    return <Card
        className={ classNames }
        children={[
            <h4>{ title }</h4>,
            <hr />,
            <p>{ description } </p>,
        ]}
    />
}

export default FeatureText;