import { useContext, useState } from 'react';
import { Link } from 'react-router-dom';
import styles from './Section.module.css';
import get from '../../../constants/get';
import UserContext from '../../../contexts/UserContext';

const Section = ({ className='', courseId='', num=0, toggleForm=() => {} }) => {
    const { email } = useContext( UserContext );
    const [ buttonIsActive, setButtonIsActive ] = useState( false );

    const courseName = courseId.split( '-' ).join('');

    // FUNCTIONS
    const activateButton = e => {
        setButtonIsActive( buttonIsActive => !buttonIsActive );
        toggleForm( e );
    }

    const getFontAwesomeNums = ( numStr='', nums=[] ) => !numStr[ 0 ] ? nums : getFontAwesomeNums(
        numStr.slice(1), [ ...nums, <i
            key={`icon-for-num-${ numStr[0] }-#${ nums.length }`}
            className={`fa-solid fa-${ numStr[0] }`}
        /> ] )

    // CONTEXTS, PROPS, & ATTRIBUTES
    const attributes = {
        button: {
            id: courseId + '-add-section',
            onClick: activateButton,
            type: 'button',
        },
        link: {
            id: courseId + '-' + num,
            to: `/${ get.username( email ) }/${ courseName }/${ num }/activity`,
        }
    };
    attributes.button.className = attributes.link.className = get.classNameFromArr([
        className,
        styles.section,
        styles[ `${ num < 0 ? 0 : num }`.length > 1 ? 'multiple-icons' : 'single-icon' ],
        buttonIsActive ? styles.active : '',
        num < 0 ? styles[ 'add-period' ] : '',
    ]);

    // COMPONENTS
    const Nums = num >= 0 ? getFontAwesomeNums( num + '' ) : <i className='fa-solid fa-plus'/>;

    // RENDER
    return num >= 0 ? <Link { ...attributes.link }>{ Nums }</Link>
        : <button { ...attributes.button }>{ Nums }</button>
}

export default Section;