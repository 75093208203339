import { useEffect, useState } from 'react';
import styles from './DateInput.module.css';
import get from '../../../../constants/get';

const DateInput = ({ label='', labelPositionOn='top', schoolYear={}, dashedYearMonthDay='1967-01-17', id='' }) => {
    const [ date, setDate ] = useState( dashedYearMonthDay || get.dayMonthYearToday().reverse().map(
        ( dayMonthOrYear, i ) => i === 2 ? dayMonthOrYear
            : `${ i === 1 ? dayMonthOrYear + 1 : dayMonthOrYear }`.padStart( 2, '0' )
    ).join( '-' ) );

    // If dashedYearMonthDay changes, change date state
    useEffect( () => dashedYearMonthDay && setDate( () => {
        const strWithMonthPlusOne = dashedYearMonthDay.split( '-' ).map(
            ( e, i ) => i !== 1 ? e : `${ ( e * 1 ) + 1 }`.padStart( 2, '0' )
        ).join( '-' );

        return strWithMonthPlusOne
    } ), [ dashedYearMonthDay ] );

    labelPositionOn = labelPositionOn.toLocaleLowerCase() === 'left' ? 'left' : 'top';

    // FUNCTIONS
    function updateDate({ target : { value } }) { setDate( () => value ) }

    // PROPS, CONTEXTS, & ATTRIBUTES
    const attributes = {
        fieldset: {
            className: get.classNameFromArr([
                styles[ 'date-input' ],
                styles[ `label-on-${ labelPositionOn }` ],
            ]),
        },
        input: {
            id, onChange: updateDate,
            type: 'date',
            value: date,
        },
    };

    if ( schoolYear.end ) {
        attributes.input.max = Object.values( schoolYear.end ).join( '-' );
        attributes.input.min = Object.values( schoolYear.start ).join( '-' );
    }

    // RENDER
    return <fieldset { ...attributes.fieldset }>
        <input { ...attributes.input }/>
        <label>{ label }</label>
    </fieldset>
}

export default DateInput;