import { useState } from 'react';
import CallToAction from '../Buttons/CallToAction';
import TextInput from './Parts/TextInput';
import styles from './AddBar.module.css';
import RadioGroup from './Parts/RadioGroup';
import InputGroup from './Parts/InputGroup';
import AddLine from '../Bar/AddLine';
import get from '../../../constants/get';

const AddBar = ({ className='', addBar=() => {}, justForm=false }) => {
    const [ showForm, setShowForm ] = useState( justForm );
    const [ extraInputs, setExtraInputs ] = useState( 1 );

    // FUNCTIONS
    function addPeriodInputs() { setExtraInputs( extraInputs => extraInputs + 1 ) }

    const generateInputs = ( amt=1, inputs=[] ) => amt > 0 ? generateInputs(
        amt - 1,
        [ ...inputs, <section
            className={ styles['period-group'] }
            key={`period-group-${ inputs.length }`}
        >
            <div className={ styles['period-frequency'] }>
                { inputs.length < 1 && <span>Meets</span> }
                <RadioGroup
                    classPrefix={ `new-course-${ inputs.length }` }
                    fieldSuffixes={[ 'day', 'days' ]}
                    mainLabels={[ 'every', 'odd', 'even', 'custom' ]}
                    secondaryLabels={[ 'mon', 'tue', 'wed', 'thur', 'fri' ]}
                    secondaryLabelTriggerI={ 3 }
                />
            </div>
            <div className={ styles['period-inputs'] }>
                { inputs.length < 1 && <span>Periods</span> }
                <InputGroup
                    classPrefix={`new-course-${ inputs.length }`}
                    inputLabels={[ '#', '#', '#', '#' ]}
                    inputLabelTransform='hide'
                    inputType='number'
                />
            </div>
        </section> ]
    ) : inputs;

    function submitForm( e ) {
        toggleForm();
        addBar( e );
    }

    function toggleForm() { setShowForm( showForm => !showForm ) }

    // CONTEXTS, PROPS, & ATTRIBUTES
    const props = {
        AddLine: {
            className: styles[ 'add-period-line' ],
            onClick: addPeriodInputs,
        },
        CallToAction: {
            add: {
                className: styles[ 'submit-button' ],
                label: <span className={ styles['button-label'] }>Add</span>,
                type: 'submit',
            },
            cancel: {
                className: styles[ 'cancel-button' ],
                isPrimary: false,
                label: <span className={ styles['button-label'] }>Cancel</span>,
                type: 'button',
            },
        },
        TextInput: {
            className: get.classNameFromArr([ styles[ 'text-input' ], className ]),
            isRequired: true,
            label: 'course name',
            name: 'new-course-name',
            transformLabel: 'hide',
        },
    };

    const attributes = {
        button: { className: styles.button }, //.add-button
    }
    props.AddLine.classPrefix = props.TextInput.id = 'new-course';
    props.CallToAction.cancel.onClick = attributes.button.onClick = toggleForm;

    // RENDER
    return <div className={ get.classNameFromArr([
        styles.form, showForm ? styles.visible : styles.hidden
    ]) }>{
        showForm ? <form onSubmit={ submitForm }>
            <TextInput { ...props.TextInput }/>
            { generateInputs( extraInputs ) }
            <AddLine { ...props.AddLine }/>
            <fieldset className={ styles.buttons }>
                <CallToAction { ...props.CallToAction.cancel }/>
                <CallToAction { ...props.CallToAction.add }/>
            </fieldset>
        </form> : <button { ...attributes.button }>
            <i className='fa-solid fa-plus'/><span className={ styles['button-label'] }> add course </span>
        </button>
    }</div>

}

export default AddBar;