import { Navigate, Outlet, useLocation } from 'react-router-dom';

const ProtectedRoute = ({
    children=[],
    isAllowed=null,
    isLoggedIn=true,
    isWorkerBee=null,
    redirectPath='/'
}) => {
    const location = useLocation();

    const courseID = location.pathname.split( '/' ).pop();
    if ( isWorkerBee && !isLoggedIn ) redirectPath = `/gandalf/${ courseID }`
    // console.log( location.pathname, isAllowed, isWorkerBee, redirectPath );

    /*
     * If a user is NOT allowed into this path, redirect them to redirectPath
     * Else, if children were passed, render them
     * Else, render child routes via <Outlet />
     */
    return !isAllowed ? <Navigate
        state={{ from: location }}
        to={ redirectPath }
        replace
    /> : children[ 0 ] ? children : <Outlet />
}

export default ProtectedRoute;