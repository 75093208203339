import styles from './AttachmentLink.module.css';

const AttachmentLink = ({ 
    href='', 
    label='', 
    iframeSrc='', 
    icon={ iconClass: 'fa-solid fa-link' } 
}) => {
    const iconIsImage = !!icon.imgSrc;
    const iconClass = iconIsImage ? styles.logo 
        : `${ styles.icon } ${ icon.iconClass }`;

    return <a 
        className={ styles.link }
        href={ href }
    >
        { iframeSrc !== '' && <div className={ styles.iframe }>{
            <iframe src={ iframeSrc } title={ iframeSrc }/>
        }</div> }
        <div className={ styles.label }>
            { iconIsImage ? <img 
                alt='' 
                src={ icon.imgSrc } 
                className={ iconClass }
            /> : <i className={ iconClass } /> }
            <span>{ label }</span>
        </div>
    </a>
}

export default AttachmentLink;