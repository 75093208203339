import { useContext, useMemo, useState } from 'react';
import Month from '../../../modular/calendar/Month';
import styles from './Calendar.module.css';
import Contexts from '../../../../contexts/CalendarContext';
import HiveContext from '../../../../contexts/HiveContext';

const Calendar = ({ className = '' }) => {
    const { course: { content: { units } }, schoolYear } = useContext( HiveContext );

    const [ activeMonth, setActiveMonth ] = useState( getActiveMonth() );

    // FUNCTIONS
    function changeMonth({ target: { className } }) {
        setActiveMonth( activeMonth => {
            const newMonth = activeMonth + getMonthIncrement( className.includes( 'right' ) || className.includes( 'coming' ) );

            return newMonth === -1 ? 11 : newMonth === 12 ? 0 : newMonth
        } );
    }

    function getActiveMonth() { return new Date().getMonth() }
    function getMonthIncrement( goRight=true ) { return goRight ? 1 : -1 }

    // COMPONENTS
    const Months = useMemo( () => {
        const getRelevantUnits = ( monthI=0, relevantUnits=[] ) => {
            for ( let i in units ) {
                const unit = units[ i ];
                const unitMonth = unit.months?.find( obj => obj.i === monthI );
                const isRelevant = unitMonth?.days?.odd[ 0 ] || unitMonth?.days?.even[ 0 ];

                if ( isRelevant ) relevantUnits.push([ i, unit ])
            }

            return Object.fromEntries( relevantUnits )
        }

        return Array(12).fill().map( ( e, i ) => {
            const month = schoolYear.months[ i ];
            const id = `${ month.name.short }-${ month.year.short }`;
            const units = getRelevantUnits( i );

            return <Contexts.Month.Provider value={{ month: month }}>
                <Month id={ id } units={ units }/>
            </Contexts.Month.Provider>
        } )
    }, [ schoolYear, units ] );

    // PROPS, CONTEXTS, & ATTRIBUTES
    const contextValues = { changeMonth, getTodayDate: () => new Date() };

    // RENDER
    return <Contexts.Calendar.Provider value={ contextValues }>
        <article className={`${ styles.calendar } ${ className }`}>
            { Months[ activeMonth ] }
        </article>
    </Contexts.Calendar.Provider>
}

export default Calendar;