import { useCallback, useContext, useMemo, useState } from 'react';
import styles from './Settings.module.css';
import UserContext from '../../../contexts/UserContext';
import get from '../../../constants/get';
import Button from '../../pages/Button';
import SVG from '../Images/SVG';
import productInfo from '../../../constants/productInfo';

const [ navLabels, plans ] = [
    [ 'Account', 'Work', 'Schedule' ], productInfo.getFullPlans()
];

const Settings = () => {
    const user = useContext( UserContext );
    const [ activeTab, setActiveTab ] = useState( navLabels[ 0 ] );
    const [ isForm, setIsForm ] = useState({
        plan: false, bio: false, position: false, locale: false, schedule: false
    });

    const thereIsAnActiveForm = useMemo( () => Object.values( isForm ).some( e => e ), [ isForm ] );
    const schools = Object.fromEntries( user.schoolIDs.map( id => [
        id, user.counties.find( county => id in county.schools ).schools[ id ]
    ] ) );
    const mainSchool = Object.values( schools )[ 0 ];
    const [ oddColour, evenColour ] = mainSchool.colours;

    const userDetails = useMemo( () => ({
        bio: {
            Title: user.name.title,
            Name: [ user.name.first, user.name.last ].join(' '),
            Email: user.email,
        },
        locale: {
            [ user.counties.length > 1 ? 'Counties' : 'County' ]: user.counties.map(
                county => county.name.full === '-- None --' ? county.name.full
                    : county.name.full + ' ( ' + county.name.abbr + ' )'
            ),
            [ user.schoolIDs.length > 1 ? 'Schools' : 'School' ]: user.schoolIDs.map(
                schoolID => {
                    const school = user.counties.find(
                        county => schoolID in county.schools
                    )?.schools?.[ schoolID ];
                    return school && (
                        school.name.full === 'independent' ? '-- None --'
                            : school.name.full + ' ( ' + school.name.abbr + ' )')
                }
            ),
        },
        plan: user.plan.isFree ? { type: plans[ 0 ] } : {
            type: plans[ user.plan.i ].title,
            billing: user.plan.billing
        },
        position: {
            Title: get.capitalise([ user.position ]),
            [ Array.isArray( user.departments ) && user.departments.length > 1 ? 'Departments' : 'Department' ]: user.departments,
        },
        schedule: {
            'courses With Sections': [ ...user.courses.queen, ...user.courses.worker ].filter(
                course => course.sections?.all?.length > 0
            ),
            'courses Without Sections': [ ...user.courses.queen, ...user.courses.worker ].filter(
                course => !course.sections?.all
            ),
        },
    }), [ user ] );

    const [
        activeTabDetails,
        isMobileEmojiStr,
        osStr,
    ] = [
        activeTab === navLabels[ 0 ] ? [ 'bio', 'plan' ]
            : activeTab === navLabels[ 1 ] ? [ 'position', 'locale' ]
            : [ 'schedule' ],
        user.isMobile ? '📱' : '🌐',
        user.OS
    ];

    // FUNCTIONS
    const getWrapper = useCallback( ( label='', labelValue={}, wrapInForm=false ) => {
        function getCoursesWithThisSection( sectionNum=0, isOdd=false ) {
            const key = isOdd ? 'odd' : 'even';
            const courses = labelValue[ 'courses With Sections' ].filter(
                course => course.sections?.[ key ]?.includes( sectionNum + '' )
                    || course.sections?.[ key ]?.includes( sectionNum )
            );

            return courses.length > 0 ? courses : null
        }

        function saveChanges() {
            setIsForm( oldIsForm => Object.fromEntries(
                Object.keys( oldIsForm ).map( key => [ key, false ] )
            ) );
        }

        function toggleForm({ target: { id: label } }) {
            setIsForm( oldIsForm => ({ ...oldIsForm, [ label ]: !oldIsForm[ label ] }) )
        }

        const scheduleBackdrop = label === 'schedule' && <div className={ styles.schedule }>
            <ul>{ [ [ 1, 2 ], [ 3, 4 ], [ 5, 6 ], [ 7, 8 ] ].map( periodArr => {
                const [ oddPeriod, evenPeriod ] = periodArr;
                const [ oddCourses, evenCourses ] = [
                    getCoursesWithThisSection( oddPeriod, true ), getCoursesWithThisSection( evenPeriod )
                ];
                const [ oddCoursesArr, evenCoursesArr ] = [
                    oddCourses || [{ id: { dashed: 'empty' }, title: '-- N/A --' }],
                    evenCourses || [{ id: { dashed: 'empty' }, title: '-- N/A --' }]
                ];
                // console.log( '🎨', get.hueRange() );

                return <li
                    key={ [ 'periods', periodArr.join( '' ), 'slot' ].join( '-' ) }
                    className={ styles[ 'schedule-row'] }
                >
                    <div className={ get.classNameFromArr([
                        styles[ 'time-slot-label' ], styles.odd
                    ]) }>{ oddPeriod }</div>

                    <div className={ get.classNameFromArr([
                        styles[ 'time-slot-contents' ], styles.odd, !oddCourses && styles.empty
                    ]) }>
                        <div className={ styles.colour }/>
                        <div className={ styles.courses }>{ oddCoursesArr.map( course => <span
                            key={ [ course.id.dashed, 'p' + oddPeriod ].join( '-' ) }
                        >{ course.title }</span> ) }</div>
                    </div>

                    <div className={ get.classNameFromArr([
                        styles[ 'time-slot-label' ],
                        styles.even
                    ]) }>{ evenPeriod }</div>

                    <div className={ get.classNameFromArr([
                        styles[ 'time-slot-contents' ], styles.even, !evenCourses && styles.empty
                    ]) }>
                        <div className={ styles.colour }/>
                        <div className={ styles.courses }>{ evenCoursesArr.map( course => <span
                            key={ [ course.id.dashed, 'p' + evenPeriod ].join( '-' ) }
                        >{ course.title }</span> ) }</div>
                    </div>
                </li>
            } ) }</ul>
        </div>
        const [ wrapperClassName, FieldsetChildren ] = [ styles.section, <>
            <legend className={ styles.label }>
                { thereIsAnActiveForm ? ( wrapInForm && <Button
                    hoverColours={[ 'orange', 'gold' ]}
                    id={ label }
                    label={ <SVG type={{ save: true }} fontSize='.75em'/> }
                    onClick={ saveChanges }
                /> ) : <Button
                    className={ styles[ 'edit-button' ] }
                    disabled={ thereIsAnActiveForm }
                    hoverColours={[ 'orange', 'gold' ]}
                    id={ label }
                    label={ <SVG type={{ edit: true }} fontSize='.6rem'/> }
                    onClick={ toggleForm }
                /> } { get.capitalise([ label ]) }
            </legend>

            <ul className={ styles[ 'user-details-list' ] }>{ Object.keys( labelValue ).map( subLabel => <li
                key={ [ label, subLabel, 'item' ].join( '-' ) }
                className={ styles[ 'section-item' ] }
            >
                <span className={ styles[ 'sub-label' ] }>{ get.capitalise([ subLabel ]) } : </span>

                { label === 'schedule' ?
                    subLabel.includes( 'With Sections' ) ? scheduleBackdrop : <div>{
                        labelValue[ 'courses Without Sections' ].map( course => <span
                            key={ course.id.dashed + '-without-sections' }
                            className={ styles[ 'user-detail' ] }
                        >{ course.title }</span> )
                    }</div>
                    : <span className={ styles[ 'user-detail' ] }>{ labelValue[ subLabel ] }</span> }
            </li> ) }</ul>
        </> ];

        return wrapInForm ? <form className={ wrapperClassName } key={ label + '-wrapper' }>
            <fieldset>{ FieldsetChildren }</fieldset>
        </form> : <fieldset className={ wrapperClassName } key={ label + '-wrapper' }>
            { FieldsetChildren }
        </fieldset>
    }, [ thereIsAnActiveForm ] )

    // RENDER
    return <div
        className={ styles.container }
        style={{ '--odd-colour': oddColour, '--even-colour': evenColour }}
    >
        <header>
            <h1>⚙️ SETTINGS ⚙️</h1>
            <p>{ [ isMobileEmojiStr, osStr ].join(' ') } || { userDetails.plan.type } Plan { user.plan.isFree && <Button
                backgroundGradientColours={[ 'orange', 'gold' ]}
                hoverColours={[ 'gold' ]}
                label='UPGRADE'
                style={{ fontSize: '.5rem', padding: '.2em .6em' }}
                onClick={ () => {} }
            />}</p>
        </header>
        <nav>
            <ul>{ navLabels.map( label => <li
                className={ styles[ 'nav-menu-item' ] }
                key={ label + '-menu-item' }
            ><Button
                hoverColours={[ 'gold' ]}
                label={ get.capitalise( label ) }
                onClick={ () => {
                    setActiveTab( () => label )
                    setIsForm( () => false )
                } }
            /></li> ) }</ul>
        </nav>
        <article>
            { activeTabDetails.map(
                label => getWrapper( label, userDetails[ label ], isForm[ label ] )
            ) }
            { activeTab === navLabels[ 0 ] && <Button
                backgroundGradientColours={[ 'red', 'orangered' ]}
                hoverColours={[ 'red' ]}
                label={ <>
                    <SVG type={{ trash: true }} fill1='white'/>
                    DELETE ACCOUNT
                </> }
                labelColour='white'
            /> }
        </article>
    </div>
}

export default Settings;