import Nav from './Nav';
import styles from './SideNav.module.css';

const SideNav = ({
    className='',
    menuItems=[''],
    toggleSideNav=() => {} ,
}) => {
    return [
        <Nav
            classNames={`${ styles['side-nav'] } ${ className }`}
            key='side-nav'
            menuItems={ menuItems }
            />,
            <div
            className={`${ styles.cover } ${ className }`}
            onClick={ toggleSideNav }
            key='side-nav-grey-area'
        ></div>,
    ]
}

export default SideNav;