import get from '../../../constants/get';
import styles from './Squares.module.css';

const Squares = ({ children=[], amount=5, className='' }) => {
    // FUNCTIONS
    const getSquare = ( i=0 ) => <div
        key={ `square-#${ i + 1 }` }
        className={ styles.square }
        style={{ '--i': `${ i }` }}
    />

    const getSquares = ( squares=[] ) => squares.length < amount ? getSquares([
        ...squares, getSquare( squares.length )
    ]) : squares

    // RENDER
    return <div className={ get.classNameFromArr([ styles.squares, styles[ className ] ]) }>
        { children }
        { getSquares() }
    </div>
}

export default Squares;