import { useState } from 'react';
import styles from './AddCountyForm.module.css';
import TextInputButton from '../../TextInputButton';
import SelectInput from '../../../modular/Form/Parts/SelectInput';
import get from '../../../../constants/get';
import courseInfo from '../../../../constants/courseInfo';
import Button from '../../Button';
import SVG from '../../../modular/Images/SVG';

const languages = [ 'english', 'français', 'español', 'deutsch' ];
const languageCodes = [ 'en', 'fr', 'es', 'de' ];

const AddCountyForm = ({
    addCounty= () => {},
    hideForm= () => {}
}) => {
    const [ countyName, setCountyName ] = useState('');
    const [ isCorrectCountyAbbr, setIsCorrectCountyAbbr ] = useState( true );
    const [ locale, setLocale ] = useState({ country: 'US', lang: 'en', state: 'VA' });

    const countyAbbr = countyName?.split(' ')?.map( e => e[ 0 ]?.toLocaleUpperCase() )?.join( '' );

    // FUNCTIONS
    const handleCountyAbbr = () => setIsCorrectCountyAbbr( isCorrectCountyAbbr => !isCorrectCountyAbbr )
    const handleCountyName = ({ target: { value } }) => setCountyName( () => value )
    const handleLocale = ({ target: { value, id } }) => setLocale( locale => ({
        ...locale,
        [ id ]: id !== 'lang' ? value : languageCodes[ languages.findIndex( lang => lang === value ) ]
    }) )

    const handleSubmit = ( e ) => {
        e.preventDefault();
        let countyAbbrInput = e.target.elements?.[ 'name-abbr' ]?.value;

        const county = courseInfo.getCountyObj(
            get.capitalise( countyName ),
            locale?.state?.toLocaleLowerCase(),
            locale.country,
            [ locale.lang, locale.country.toLocaleUpperCase() ].join( '-' )
        );

        if ( countyAbbrInput ) county.name.abbr = countyAbbrInput
        // console.log( countyAbbrInput, county );
        addCounty( county );
    }

    // COMPONENTS

    // RENDER
    return <form className={ styles.form } onSubmit={ handleSubmit }>
        <fieldset className={ styles.name }>
            <TextInputButton
                className={ styles.full }
                controlledValue={ countyName }
                inputID='name-full'
                inputLabel='county name'
                inputType='text'
                isRequired
                passValueUp={ handleCountyName }
            />
            { !isCorrectCountyAbbr ? <TextInputButton
                className={ styles.abbr }
                uncontrolledValue={ countyAbbr }
                inputID='name-abbr'
                inputLabel='county name abbr'
                inputType='text'
            /> : countyAbbr ? <Button
            onClick={ handleCountyAbbr }
            label={ <>
                <span className={ styles.label }>county abbr</span>
                <span><SVG type={{ close: true }}/> { countyAbbr }</span>
            </> }
            className={ styles[ 'abbr-button' ] }
        /> : null }
        </fieldset>
        <fieldset className={ styles.locale }>
            <SelectInput
                canReset
                canTypeAnswer
                className={ styles.country }
                // defaultTags={[ 'US' ]}
                hideTypingFieldWhenTagIsSelected
                id='country'
                initialTags={[ locale.country ]}
                // isVertical
                label='country'
                name='country'
                options={[ 'US' ]}
                passTargetUp
                passValueUp={ handleLocale }
            />
            <SelectInput
                canReset
                canTypeAnswer
                className={ styles.state }
                // defaultTags={[ 'US' ]}
                hideTypingFieldWhenTagIsSelected
                id='state'
                initialTags={[ locale.state ]}
                // isVertical
                label='state'
                name='state'
                options={ get.USStates( true ) }
                passTargetUp
                passValueUp={ handleLocale }
            />
            <SelectInput
                canReset
                canTypeAnswer
                className={ styles.language }
                // defaultTags={[ 'US' ]}
                hideTypingFieldWhenTagIsSelected
                id='lang'
                initialTags={[ locale.lang ]}
                // isVertical
                label='lang'
                name='lang'
                options={ languages }
                passTargetUp
                passValueUp={ handleLocale }
            />
        </fieldset>

        <fieldset className={ styles.buttons }>
            <Button
                // labelColour='black'
                className={ styles.cancel }
                id='cancel'
                label='cancel'
                looksLikeLink
                onClick={ hideForm }
            />
            <Button
                // backgroundGradientColours={[  ]}
                className={ styles.submit }
                id='submit'
                label='submit'
                type='submit'
                hoverColours={[ 'gold', 'yellow' ]}
            />
        </fieldset>
    </form>
}

export default AddCountyForm;