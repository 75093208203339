import get from '../../../constants/get';
import Pill from '../../modular/Buttons/Pill';
import styles from './ShopSidebar.module.css';

const ShopSidebar = ({
    all={ subjects: [], levels: [] },
    active={ subjects: [], levels: [] },
    handleClickOn={ subjects: () => {}, levels: () => {} }
}) => {
    const categoryHeaders = [ 'subjects', 'levels' ];

    // RENDER
    return <aside className={ styles.sidebar }>{ categoryHeaders.map(
        category => <section className={ styles.category } key={ category + '-section' }>
            <header className={ styles[ 'category-header' ] }>
                { category.toLocaleUpperCase() } :
            </header>

            <ul>{ all[ category ].map( e => <li
                className={ get.classNameFromArr([
                    styles[ category.slice( 0, category.length - 1 ) ],
                    active[ category ].includes( e ) && styles.active
                ]) }
                key={ e + '-' + styles[ category.slice( 0, category.length - 1 ) ] }
            ><Pill
                activeState={ active[ category ].includes( e ) }
                label={ e }
                onClick={ handleClickOn[ category ] }
            /></li> ) }</ul>
        </section>
    ) }</aside>
}

export default ShopSidebar;