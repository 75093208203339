import { useContext, useState } from 'react';
import courseInfo from '../../../constants/courseInfo';
import get from '../../../constants/get';
import styles from './Board.module.css';
import BoardSquare from './BoardSquare';
import HiveContext from '../../../contexts/HiveContext';
import TextToSpeech from '../Buttons/TextToSpeech';

const Board = ({
    activeSquare = 2,
    assignmentsArr = [],
    checkboxes = [],
    checkList = [],
    unit = {}
}) => {
    const { activeBoard, lang, schoolYear } = useContext( HiveContext );
    const [ textForSpeech, setTextForSpeech ] = useState( '' );

    const { h, s, l } = 'background' in unit === false ? get.randomHSL( false )
        : unit?.background?.h ? unit.background : get.hslFromHex( unit.background.hex );

    activeSquare = activeSquare === 0 ? 3
        : activeSquare === 1 ? 5
        : activeSquare < 5 ? activeSquare - 2
        : activeSquare + 1

    assignmentsArr = assignmentsArr.map( ( assignment, i ) => {
        if ( 'isFakeChecked' in assignment === false ) assignment.isFakeChecked = false
        return [ assignment, i >= 4 ? checkboxes[ i + 1 ] : checkboxes[ i ] ]
    } );

    const [ reqAssignments, prereqAssignments ] = fillAssignmentArr([
        assignmentsArr.filter( assignmentCheckbox => !assignmentCheckbox[ 0 ].isPrereq ),
        assignmentsArr.filter( assignmentCheckbox => assignmentCheckbox[ 0 ].isPrereq )
    ]);

    assignmentsArr = putAssignmentsInOrder();
    // console.log( '🟧', assignmentsArr );

    // FUNCTIONS
    function fillAssignmentArr( arr=[ [], [] ], isPrereq=false, newArr=[] ) {
        let currentArr = arr[ 0 ] && arr[ 0 ].slice();
        if ( !currentArr ) return newArr

        const maxLength = isPrereq ? 6 : 2;

        if ( currentArr.length < maxLength ) {
            const month = schoolYear.months[ activeBoard.monthNum ];
            const boardWeek = month.weeks[ activeBoard.weekNum ];

            const dueDay = get.lastArrayElement( boardWeek.daysInClass );
            const dueMonth = boardWeek.months[ boardWeek.days.findIndex( d => d === dueDay ) ];

            currentArr = [ ...currentArr, ...Array( maxLength - currentArr.length ).fill().map(
                ( e, i ) => courseInfo.getAssignmentObj(
                    i,
                    activeBoard.num,
                    maxLength === 6,
                    maxLength === 6 ? 'assignment' : 'assessment',
                    maxLength === 6 ? 0 : 1,
                    new Date( month.year.full, dueMonth, dueDay )
                )
            ) ];
        }

        return fillAssignmentArr( arr.slice( 1 ), !isPrereq, [ ...newArr, currentArr ] )
    }

    function putAssignmentsInOrder() {
        let arr = [ ...prereqAssignments.slice( 0, 3 ), ...reqAssignments, ...prereqAssignments.slice( 3 ) ];

        arr.splice( 4, 0, [ { checkList, boardNum: activeBoard.num }, checkList[ 4 ] ] );
        return arr
    }

    function readText( text='' ) { setTextForSpeech( () => text ) }
    function clearText() { setTextForSpeech( () => '' ) }

    // CONTEXTS, PROPS & ATTRIBUTES
    const props = {
        div: {
            className: get.classNameFromArr([
                styles.board, !!textForSpeech && styles[ 'with-text-to-speech']
            ]),
            style: {
                '--hue': h,
                '--saturation': s,
                '--lightness': l,
                '--req-text-colour': unit?.background ? get.textColour( { h, s, l } ) : '#000',
            },
        },
    };

    // COMPONENTS
    const Squares = assignmentsArr.map( ( assignmentCheckbox, i ) => i !== 4 ? <BoardSquare
        assignmentsArr={ assignmentsArr }
        AttachmentContainerAndCheckBox={ assignmentCheckbox[ 1 ] }
        details={ assignmentCheckbox[ 0 ] }
        i={ i }
        isActive={ i === activeSquare }
        isChecked={ assignmentCheckbox[ 0 ].isChecked }
        key={ `board-square-${ i }` }
        readText={ readText }
    /> : <BoardSquare details={ assignmentCheckbox[ 0 ] } key={ `board-square-${ i }` }/> );

    // RENDER
    return <div { ...props.div }>
        { Squares }
        { !!textForSpeech && <TextToSpeech
            text={ textForSpeech }
            language={ lang }
            startPlaying
            hsl={{ h, s, l }}
            onClose={ clearText }
        /> }
    </div>
}

export default Board;