// CUSTOMER REVIEWS
const customers = {
    0: {
        img: {
            src: {
                mini: '/founder-photo.png',
                full: '/portrait brighter border.svg',
            },
            alt: 'African American woman',
        },
        name: 'Mme. N\'komba',
        credential: 'Department Chair<br>FR/SP/PT Teacher',
        review: 'The way my students made decisions completely changed. I spent <b>more class time teaching and bonding</b>, not motivating and disciplining',
        rating: 4.5,
    },
    1: {
        img: {
            src: {
                mini: 'https://www.pngmart.com/files/15/Smiling-Business-Woman-PNG-File.png',
                full: 'https://www.pngmart.com/files/15/Smiling-Business-Woman-PNG-File.png',
            },
            alt: '',
        },
        name: 'Title Teacher 1',
        credential: 'Subject Teacher',
        review: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vestibulum nec pellentesque lorem.',
        rating: 4,
    },
    2: {
        img: {
            src: {
                mini: 'https://purepng.com/public/uploads/large/purepng.com-standing-girlwomenpeoplepersonsfemale-1121525088120hvcah.png',
                full: 'https://purepng.com/public/uploads/large/purepng.com-standing-girlwomenpeoplepersonsfemale-1121525088120hvcah.png',
            },
            alt: '',
        },
        name: 'Title Teacher 2',
        credential: 'Subject Teacher',
        review: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vestibulum nec pellentesque lorem.',
        rating: 5,
    },
    3: {
        img: {
            src: {
                mini: 'https://pngimg.com/uploads/girls/girls_PNG6481.png',
                full: 'https://pngimg.com/uploads/girls/girls_PNG6481.png',
            },
            alt: '',
        },
        name: 'Title Teacher 3',
        credential: 'Subject Teacher',
        review: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vestibulum nec pellentesque lorem.',
        rating: 4,
    },
    4: {
        img: {
            src: {
                mini: 'https://www.pngall.com/wp-content/uploads/8/Thinking-Woman-PNG-Image.png',
                full: 'https://www.pngall.com/wp-content/uploads/8/Thinking-Woman-PNG-Image.png',
            },
            alt: '',
        },
        name: 'Title Teacher 4',
        credential: 'Subject Teacher',
        review: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vestibulum nec pellentesque lorem.',
        rating: 4.5,
    },
    5: {
        img: {
            src: {
                mini: 'https://pngimg.com/uploads/thinking_woman/thinking_woman_PNG11637.png',
                full: 'https://pngimg.com/uploads/thinking_woman/thinking_woman_PNG11637.png'
            },
            alt: '',
        },
        name: 'Title Teacher 5',
        credential: 'Subject Teacher',
        review: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vestibulum nec pellentesque lorem.',
        rating: 4,
    },
    6: {
        img: {
            src: {
                mini: 'https://www.pngall.com/wp-content/uploads/8/Business-Women.png',
                full: 'https://www.pngall.com/wp-content/uploads/8/Business-Women.png',
            },
            alt: '',
        },
        name: 'Title Teacher 6',
        credential: 'Subject Teacher',
        review: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vestibulum nec pellentesque lorem.',
        rating: 4.5,
    },
    // placeholder: {
    //     img: {
    //         src: {
    //             focused: '',
    //             unfocused: '',
    //         },
    //         alt: '',
    //     },
    //     name: 'Title Teacher',
    //     credential: 'Subject Teacher',
    //     review: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vestibulum nec pellentesque lorem.',
    //     rating: 4,
    // },
};

const linkObjEntries = ( obj = {} ) => {
    const vals = Object.values( obj );
    const objMaxKey = vals.length - 1;

    vals.forEach( ( e, i ) => {
        e.prev = i === 0 ? objMaxKey : i - 1;
        e.next = i === objMaxKey ? 0 : i + 1;
        e.current = i;
    } );
}

const fillReviews = ( customerVals=[], customersAmt=0 ) => {
    if ( customersAmt >= 7 ) return linkObjEntries( customers )

    customersAmt = customerVals.length;
    customerVals.forEach( (customer, i) => {
        customers[ (customersAmt + i) ] = customer;
    } );
    return fillReviews( customerVals, customersAmt );
    // const customerVals = Object.values( customers );
    // let customersAmt = customerVals.length;

    // while ( customersAmt < 7 ) {
    //     customerVals.forEach( ( customer, i ) => {
    //         customers[ (customersAmt + i) ] = customer[1];
    //     } );

    //     customersAmt += customerVals.length;
    // }

    // linkObjEntries( customers );
}

fillReviews( Object.values( customers ) );

// SCENARIOS
const scenarios = {
    '1': {
        parties: {
            me: '👩🏿‍🏫',
            them: ['🧑🏻‍🎓', '👩🏼‍🎓'],
            name: 'French 2 Favs'
        },
        dialogue: {
            me1: 'here are this week\'s assignments, Bumble',
            board1: 'Here are the week\'s 2 required tasks',
            board2: 'Finish any 3 light boxes before tackling those 2',
            them1: 'Ugh one of the dark boxes is a speaking',
            them2: 'I <i>hate</i> speaking French',
            me2: '👀',
            them01: 'But only 1 of the light boxes is speaking',
            them02: 'you can do the readings or games',
            them3: 'Yeah but I\'m already good at those', 
            them4: 'I need to practice speaking',
            them5: '*despairs in Franglais',
            board3: '🧑🏻‍🎓 chose their light boxes: 1 speaking, 1 vocab game, 1 reading',
            me3: 'Proud of you!',
        },
    },
    '2': {
        parties: {
            me: '👩🏿‍🏫',
            them: ['👨🏽‍🎓'],
            name: '[F2] Student'
        },
        dialogue: {
            them1: '*is absent for 4 days',
            board1: 'It is Week 5, and you are on Week 4',
            board2: 'You need: 1 light box & the left dark box (with 👩🏿‍🏫)',
            them2: '*shows up during study hall',
            them3: 'I still need to finish the top-left light box',
            them4: 'But then I wanna do the left dark box',
            them5: 'It says I have to do it with you',
            me1: 'Before you change your mind: YEEESSSS'
        },
    },
    '3': {
        parties: {
            me: '👩🏿‍🏫',
            them: ['👩🏻‍🎓'],
            name: '[F3] Student'
        },
        dialogue: {
            board1: '👩🏻‍🎓 finished this week\'s board ahead of schedule!',
            them1: '*completes 3 light boxes',
            me1: 'Whooooaaaaa you\'re doing next week\'s board?',
            them2: 'Yeah I wasn\'t going to',
            them3: 'But I saw that 👩🏻‍🦰 started it, so I had to'
        },
    },
    '4': {
        parties: {
            me: '👩🏿‍🏫',
            them: ['👩🏽‍🎓'],
            name: '[F4] Student (Pandemic)',
        },
        dialogue: {
            them1: '*life happens',
            them2: '*grade drops significantly',
            them3: 'Oh 🦭 I have an F ?!?!',
            me1: 'Well-',
            board1: 'Do the following boxes to raise your grade',
            them5: '*completes missing boxes with high marks',
            me2: '-so here\'s what you can do',
            them6: 'lol nvm',
            me3: '. . .'
        },
    },
    '5': {
        parties: {
            me: '👩🏿‍🏫',
            them: ['👩🏼‍💼'],
            name: 'Parent Conference'
        },
        dialogue: {
            board1: 'does all the heavy lifting',
            me1: 'Bonjour, Mme 👩🏼‍💼 !',
            them1: 'I wanted to update you on 👩🏻‍💻',
            them2: '*details redacted',
            them3: 'Also, 👩🏻‍💻 really loves the way you organise your class',
            me2: 'How sweet ; thank you !',
            board2: '. . .'
        },
    },
    '6': {
        parties: {
            me: '👩🏿‍🏫',
            them: ['👩🏻‍💻'],
            name: '[F3] Student'
        },
        dialogue: {
            me1: 'here are the assignments for the next 3 weeks',
            board1: 'New board!',
            them1: '*finishes this week\'s board',
            them2: '*chooses to start next week\'s board',
            board2: '(1 week later) New board!',
            them3: '*finishes this week\'s board',
            them4: '*chooses to wait until next week to start next week\'s board',
            board3: '(1 week later) New board!',
            them5: '*finishes this week\'s board',
            them6: '*chooses to start next week\'s board',
        },
    },
    '7': {
        parties: {
            me: '👩🏿‍🏫',
            them: ['👩🏻‍💼'],
            name: '[F2] Parent'
        },
        dialogue: {
            them1: '👨🏻‍🎓\'s grade is unnacceptable; fix it!',
            me1: 'I happily will once I receive their work',
            them2: 'They say they <i>have</i> been. We are displeased.',
            me2: 'My mistake. Let\'s consult their boards',
            board1: 'It is Week 9. Total Assignments: 45',
            board3: '26 students on pace, 17 behind, 5 ahead',
            board4: '👨🏻‍🎓 has 6 incomplete boards (weeks)',
            board5: '👨🏻‍🎓 should start with Week 3\'s left dark box',
            them3: 'Oh my...',
            me3: 'If it helps, it looks like all the missing work is the same category: speaking',
            me4: 'So if we both apply pressure, maybe we can get 👨🏻‍🎓 to improve their grade!',
            me5: 'Everybody wins!',
            them4: 'We can do that'
        },
    },
    '8': {
        parties: {
            me: '👩🏿‍🏫',
            them: ['👩🏽‍💻', '👩🏽‍💼'],
            name: 'student & parent'
        },
        dialogue: {
            board1: `You're missing, 6/9 boards (28*) & the quarter ends in 5hrs`,
            them1: '*checks off 28 boxes',
            board3: 'Great news! 👩🏽‍💻 has done all their boards! Check your Gradebook',
            me1: 'Yay!',
            me2: 'Hmm... only 3 were turned in',
            board5: 'Hey 👩🏽‍💻, You\'re missing, 6/9 boards & the quarter has ended',
            them01: 'Hello! I really appreciate what you do for 👩🏽‍💻',
            them02: '👩🏽‍💻 says their grade is wrong',
            them03: 'They turned everything in before the quarter ended',
            them2: 'I literally had all the boxes checked',
            them3: 'but now it says I don\'t',
            me3: 'Let\'s take a look',
            board7: '28 boxes were checked at 11:51pm',
            board8: '3 boxes were confirmed as valid',
            me4: 'also, some of those dark boxes were 1-on-1 with me... Maybe you clicked the wrong box?',
            them4: 'oh my bad, yeah I think I got confused',
            me5: 'Honestly, it happens, but we can fix it all now',
            me8: 'For each assignment, you can share the link to your work, and I will grade it with you both right now',
            them5: '<i>. . .</i>'
        },
    }
    // '6': {
    //     parties: {
    //         me: '👩🏿‍🏫',
    //         them: '',
    //     },
    //     dialogue: {
    //         me1: '',
    //         them1: ''
    //     },
    // }
};

// EXPORT
const reviewInfo = {
    customers: customers,
    scenarios: scenarios,
};

export default reviewInfo;