import { useContext } from 'react';
import Tree from '../modular/Images/svg/Tree';
import Button from './Button';
import styles from './Error.module.css';
import UserContext from '../../contexts/UserContext';
import get from '../../constants/get';
import { useLocation, useNavigate } from 'react-router-dom';
// import { Link } from "react-router-dom";
// import Logo from "../modular/Images/Logo";

const Error = () => {
    const { email } = useContext( UserContext );
    const navigate = useNavigate();
    const location = useLocation();
    document.title = 'BumbleError...';

    // FUNCTIONS
    const getLeaves = ( rows=1 ) => <div className={ styles.leaves }>
        { rows === 3 && <div { ...attributes.leaves.top }/> }
        { rows === 2 && <div { ...attributes.leaves.middle }/> }
        <div { ...attributes.leaves.bottom }/>
    </div>

    const goBack = () => {
        navigate( -1 );
        setTimeout( reload, 200 );
    }

    const reload = () => window.location.reload()

    // PROPS & ATTRIBUTES
    const attributes = {
        leaves: {
            top: { className: styles.top, style: { '--i': 1 } },
            middle: { className: styles.middle, style: { '--i': 1.5 } },
            bottom: { className: styles.bottom, style: { '--i': 2 } },
        },
    };

    return <article className={ styles.error }>
        <header>
            <h1>Error 404</h1>
            <div className={ styles.tree }>
                <div className={ styles.trunk }>
                    <div className={ styles.leaves }>{ getLeaves( 3 ) }</div>
                    <div className={ styles.branch }>{ getLeaves( 2 ) }</div>
                    <div className={ styles.branch }>{ getLeaves( 2 ) }</div>
                </div>
            </div>
        </header>
        <section className={ styles.message }>
            <svg viewBox="0 0 200 200" xmlns="http://www.w3.org/2000/svg">
                <path
                    fill="#F1C21B"
                    transform="translate(100 100)"
                    d="M37.9,-50.6C50.8,-42.8,64,-34,64.2,-23.3C64.4,-12.5,51.6,0,43.6,10.9C35.6,21.8,32.3,30.9,25.9,40.2C19.4,49.5,9.7,58.9,-2.7,62.6C-15.2,66.4,-30.4,64.6,-38.5,55.8C-46.6,47.1,-47.7,31.5,-52.5,17C-57.3,2.5,-65.9,-10.9,-65.7,-24.3C-65.4,-37.8,-56.3,-51.3,-43.8,-59.3C-31.4,-67.2,-15.7,-69.6,-1.6,-67.4C12.5,-65.2,25,-58.4,37.9,-50.6Z"
                />
            </svg>
            <div className={ styles.text }>
                <h2>This is embarassing...</h2>
                <p>We have misplaced the page you are looking for.</p>
                <br/>
                <div className={ styles.buttons }>
                    <Button onClick={ goBack } label='BACK'/>
                    <Button
                        isLink
                        linkTarget={ `/${ get.username( email ) }` }
                        label='🏠'
                        propsFromParent={{ reloadDocument: true }}
                    />
                    {/* <Button isLink onClick={ () => goBack( true ) } label='🏠'/> */}
                </div>
            </div>
        </section>
        <Tree />
        {/* <Link
            to={ '/' }
            className={ styles.logo }
        > <Logo /> </Link> */}
    </article>
}

export default Error;