import { useState } from 'react';
import AddBar from '../Form/AddBar';
import styles from './AddLine.module.css';
import get from '../../../constants/get';

const AddLine = ({ className='', classPrefix='', onClick=() => {} }) => {
    const [ showForm, setShowForm ] = useState( false );

    const classNames = get.classNameFromArr([
        styles[ 'add-line' ],
        classPrefix,
        className,
    ]);

    // FUNCTIONS
    const showAddBar = e => {
        showForm && onClick( e );
        setShowForm( showForm => !showForm )
    }

    // RENDER
    return showForm ? <AddBar
        className={ styles.form }
        justForm={ showForm }
        addBar={ showAddBar }
    /> : <button
        onClick={ showAddBar }
        className={ classNames }
    ><i className={`${ classPrefix } fa-solid fa-plus`}/></button>
}

export default AddLine;