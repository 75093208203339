import { useState } from 'react';
import ToolTip from '../ToolTip';
import styles from './InfoButton.module.css';
import get from '../../../constants/get';

const InfoButton = ({
    arrowDirection = '',
    faIconClass = 'fa-solid fa-circle-question',
    hasArrow = true,
    iconComponent,
    onClick = null,
    pElement,
    style = {}
}) => {
    const [ isClicked, setIsClicked ] = useState( false );

    // FUNCTIONS
    function toggleTooltip() { setIsClicked( isClicked => !isClicked ) }

    // PROPS & ATTRIBUTES
    const props = {
        button: {
            type: 'button',
            className: get.classNameFromArr([
                isClicked ? styles.clicked : '',
                styles[ 'info-button' ],
                faIconClass.endsWith( 'circle-question' ) && !iconComponent ? styles.default : ''
            ]),
        },
        icon: {
            className: faIconClass,
            onClick: onClick === null ? toggleTooltip : onClick
        },
        ToolTip: { arrowDirection, hasArrow, pElement, style, onClick: toggleTooltip }
    };

    // COMPONENTS
    if ( !iconComponent ) iconComponent = <i { ...props.icon }/>

    // RENDER
    return <button { ...props.button }> { iconComponent }<ToolTip { ...props.ToolTip }/> </button>
}

export default InfoButton;