import { Outlet, useParams } from 'react-router-dom'
import ShopContext from '../../../contexts/ShopContext'
import { useContext, useEffect, useState } from 'react'
// import LoadingHive from '../../modular/Loaders/LoadingHive';
import UserContext from '../../../contexts/UserContext';
import courseInfo from '../../../constants/courseInfo';
import { collection, getDocs } from 'firebase/firestore';
import { db } from '../../../firebase_setup/firebase';

const ShopWrapper = () => {
    const { courses, counties } = useContext( UserContext );
    const [ units, setUnits ] = useState( null );
    const { username, subject, level } = useParams();

    const relevantCoursesArr = getRelevantCoursesArr();

    useEffect( () => {
        if ( units === null ) {
            const dbPathNestedArr = getDBPathArr( relevantCoursesArr, counties );
            Promise.allSettled( dbPathNestedArr.map( arr => {
                if ( !arr[ 2 ] ) return arr

                const [ course, countyID, snapshots ] = [
                    courses.queen.find( course => course.subject === arr[ 3 ]
                        && course.level === arr[ 4 ].split( '-' ).pop() * 1 ),
                    arr.pop(),
                    getUnitsFromDB( arr )
                ];
                // getUnitsFromDB( arr );//.then( docs => docs.forEach( doc => snapshots.push( doc.data() ) ) );

                return [ snapshots, countyID, course ]
            } ) ).then( async snapshotAndCountyID => {
                const unitsArr = [];
                // console.log({ snapshotAndCountyID });

                for ( let i=0; i<snapshotAndCountyID.length; i++ ) {
                    // await parseSnapshot( snapshotAndCountyID[ i ].value, unitsArr );
                    const results = await parseSnapshot( snapshotAndCountyID[ i ], [], username, counties );
                    console.log( '🟢', { results });
                    unitsArr.push( ...results );
                }

                handleUnits( unitsArr )
                // setUnits( () => unitsArr )
            } )
        }
    }, [ counties, courses.queen, username, relevantCoursesArr, units ] )

    const { languages, languageUnits } = courseInfo;

    // FUNCTIONS
    function handleUnits( newUnits ) { setUnits( () => newUnits ) }

    function getRelevantCoursesArr( filterKey, filterValue=[] ) {
        let dashedID = subject ? subject.split(' ')?.join( '-' ) : '';
        if ( level ) dashedID = dashedID + '-' + level

        let arr = dashedID ? [
            courses.queen.find( course => {
                course.id.dashed === dashedID && console.log( course.id.dashed, dashedID );
                return course.id.dashed === dashedID
            } )
        ] : courses.queen;

        if ( filterKey ) {
            const filterKeyArr = filterKey.split( '.' );
            arr = arr.filter( course => filterValue.includes(
                filterKeyArr.length === 1 ? course[ filterKey ]
                    : course[ filterKeyArr[ 0 ] ][ filterKeyArr[ 1 ] ]
            ) )
        }

        return arr
    }

    // PROPS & ATTRIBUTES
    const shopContext = { courses, counties, getRelevantCoursesArr, languages, languageUnits, relevantCoursesArr, units, username, handleUnits };
    console.log( shopContext );

    // RENDER
    return <ShopContext.Provider value={ shopContext }>
        <Outlet />
    </ShopContext.Provider>
}

// OTHER FUNCTIONS
function getDBPathArr( coursesArr=[], counties=[] ) {
    const { languages } = courseInfo;
    const dbPathArr = coursesArr.map( course => {
        const [ isLanguageCourse, countyID ] = [
            languages.includes( course.subject ),
            counties.length === 1 ? counties[ 0 ].id : counties.find( c => c.schools[ course.schoolID ] )?.id
        ];

        return isLanguageCourse ? [
            'units',
            'world-languages',
            'subjects',
            course.subject,
            'level-'+course.level,
            countyID
        ] : [ course.subject, course.level ]
        // /units/world-languages/subjects/spanish/level-1/it's-me-va-fairfax-county-public-schools
    } )

    return dbPathArr
}

async function getUnitsFromDB( pathArr=[] ) {
    const arr = [];

    try {
        await getDocs( collection( db, ...pathArr ) )
            .then( docItem => docItem.docs.forEach( e => arr.push( e.data() ) ) );

        return arr;
    } catch ( error ) { console.error( error ) }
}

async function parseSnapshot( values, unitsArr=[], username='', counties=[] ) {
    const [ snapshots, countyID, course ] = values.value;
    if ( typeof snapshots === 'string' ) return [
        username === 'bumbleboard' ? 'ADD' : 'NO',
        'UNITS FOR',
        course.subject.toLocaleUpperCase() + ( course.level ? ' ' + course.level : '' )
    ]

    // console.log( '🟡', snapshots );
    await Promise.all([ snapshots ]).then( docs => {
        docs.forEach( data => data.forEach( unit => { // doc
            // console.log( '🟠', { unit });
            const subject = course.subject;
            const { schoolYear } = counties.length === 1 ? counties[ 0 ]
                : counties.find( county => county.id === countyID );
            console.log({ schoolYear, unit });

            if ( !unit.expressions ) {
                const obj = courseInfo.getUnitObj({
                    subject, schoolYear,
                    background: unit.background,
                    level: unit.level,
                    theme: unit.theme,
                    numOfBoards: 8,
                    startingMonthNum: schoolYear.start.month
                });

                [ obj.i, obj.translation, obj.icon ] = [ unit.i, unit.translation, unit.icon ];
                console.log( '🔴', obj );
                unitsArr.push( obj );
            } else { unitsArr.push( unit ) }
        } ) );
    } );

    console.log( '🟢', { unitsArr });
    return unitsArr
}

export default ShopWrapper