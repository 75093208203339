import reviewInfo from '../../../constants/reviewInfo';
import CarouselViewport from '../../modular/Carousels/CarouselViewport';
import InfiniteCarousel from '../../modular/Carousels/InfiniteCarousel';
import Scenario from './Scenario';
import styles from './Reviews.module.css';

const Reviews = () => {
    // const [ cardsInPosition, setCardsInPosition ] = useState({
    //     '-3': reviewInfo.customers[0].prev - 2,
    //     '-2': reviewInfo.customers[0].prev - 1,
    //     '-1': reviewInfo.customers[0].prev,
    //     '0': 0,
    //     '1': 1,
    //     '2': 2,
    //     '3': 3,
    //     ...Object.assign( {}, Object.keys( reviewInfo.customers ).map(
    //         (e, i, customers) => ( customers.length > 7 && i > 3 ) ? [ `${i}`, i ] : [ '', '' ]
    //     ).filter( entry => entry[0] !== '' ) ),
    // });

    const { customers, scenarios } = reviewInfo;
    // const { directions, carouselPositions: positions } = moduleInfo;

    const lastCardI = customers[0].prev;
    const initialCards = Object.keys( customers ).map(
        key => key * 1 > 2 ? key - 3 : lastCardI - 2 + (key * 1)
    );

    // const changePosition = ( i=0, increment=-1 ) => {
    //     const positionMax = Object.keys( cardsInPosition ).pop();

    //     return (increment === 1 && i === positionMax) ? -3
    //         : (increment === -1 && i === -3) ? positionMax : i + increment
    // }

    // const getReviewClass = ( position=0 ) => {
    //     const positionClass = styles[
    //         positions[ position ] ? positions[ position ] : 'hidden'
    //     ];

    //     return [ styles.customer, positionClass ].join(' ')
    // }

    // const getReview = ( entries = [] ) => {
    //     const [ position, i ] = entries;
    //     const customer = customers[i];

    //     return <Review
    //         key={`review-${ customer.name }-${ position }`}
    //         reviewDetails={ customer }
    //         positionX={ position * 1 }
    //         className={ getReviewClass( position ) }
    //     />
    // }

    // const rotateReviews = (
    //     increment=-1,
    //     reviews=[],
    //     positionsAndReview=[],
    //     newPositions={}
    // ) => {
    //     if ( reviews.length === 0 ) return

    //     const [ oldPosition, review ] = positionsAndReview;
    //     [].
        // const newPosition = newPosition > -3 ? positionAndReview
        // reviews[0].className
    //     rotateReviews( increment, reviews.slice(1), positionsAndReview, newPositions )
    // }
    // setReviews( reviews => reviews.map(
        // customer => customers[ customer[ goLeftOrUp ? 'prev' : 'next' ] ]
    // ) )

    // const handleChange = ({ target }) => {
    //     const { className } = target;
    //     const { children } = target.parentElement.parentElement.parentElement.children[2];
    //     console.log(className, children);

    //     let increment = directions.type( 0 ).includes(
    //         directions.all().find( direction => className.includes(direction) )
    //     ) ? -1 : 1;

    //     setCardsInPosition( cardsInPosition => {
    //         const iMax = Object.keys( customers ).length - 1;
    //         const newPositions = {};

    //         Object.entries( cardsInPosition ).forEach( keyVal =>
    //             newPositions[ keyVal[0] ] = keyVal[1] + increment < 0 ?
    //                 iMax : keyVal[1] + increment > iMax ?
    //                 0 : keyVal[1] + increment
    //         )
    //         return newPositions
    //     } )

    //     rotateReviews(
    //         directions.type( 0 ).includes(direction) ? -1 : 1,
    //         children,
    //         Object.entries( cardsInPosition )
    //     );
    // }

    // const getCarets = ( directions=['',''], carets=[] ) => carets.length === 2 ? carets : getCarets(
    //     directions.slice(1),
    //     [ ...carets, <Caret
    //         key={`${ directions[0] }-caret`}
    //         onClick={ handleChange }
    //         isPrimaryColour
    //         direction={ directions[0] }
    //     /> ]
    // )
    // const [ leftCaret, rightCaret ] = getCarets( directions.x );

    const scenarioJsxArr = Object.entries(scenarios).map(
        scenario => <Scenario
            key={`scenario-${scenario[0]}`}
            scenarioNum={ scenario[0] }
            parties={ scenario[1].parties }
            dialogue={ scenario[1].dialogue }
    />)

    // pass carets down to infinite carousel, & generate reviews here too
    // that way, on click, you modify just them?

    return <section className={styles.reviews} id='reviews'>
        <article className={styles.scenarios}>
            <header><h2>How it helped</h2></header>
            <CarouselViewport arrowsAreHorizontal children={scenarioJsxArr}/>
        </article>
        <InfiniteCarousel isHorizontal initialOrder={ initialCards }/>
    </section>
}

export default Reviews;