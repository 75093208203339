import productInfo from '../../../constants/productInfo';
import styles from './HowItWorks.module.css';
import Solutions from './Solutions';

const HowItWorks = () => {
    const { steps, features } = productInfo;
    
    const getSteps = () => <ul>{ Object.values(steps).map(
        ( step, i ) => <li 
            className={styles.step}
            key={`step-${i}`}
        >
            <i className={step.icon} /><br /><br />
            <p>
                <span>{ step.upper.toLocaleUpperCase() }</span>
                <br />
                { step.lower.toLocaleLowerCase() }
            </p>
        </li>
    ) }</ul>

    return (
        <section className={styles['how-it-works']} id='features'>
            <article>
                <h2>how it works</h2>
                <div className={styles.steps}>{ getSteps() }</div>
            </article>
            <Solutions features={features} />
        </section>
    )
}

export default HowItWorks;