import { useContext } from 'react';
import Contexts from '../../../contexts/CalendarContext';
import Week from './Week';
import styles from './Month.module.css';
import get from '../../../constants/get';
import Caret from '../Buttons/Caret';
import HiveContext from '../../../contexts/HiveContext';

const Month = ({ id = '', units = {} }) => {
    const { month } = useContext( Contexts.Month );
    const { changeMonth } = useContext( Contexts.Calendar );
    const { course } = useContext( HiveContext );
    const { weeks } = month;

    // FUNCTIONS
    function getDaysArr( unit={}, isOdd=true, arr=[] ) {
        const key = isOdd ? 'odd' : 'even';
        const newArr = unit.months?.find( obj => obj.i === month.i )?.days?.[ key ] || [];

        return isOdd ? getDaysArr( unit, !isOdd, [ ...newArr ] ) : [ ...arr, ...newArr ]
    }

    function getRelevantUnits( daysArr=[ 1 ] ) {
        const unitKeys = Object.keys( units );
        if ( unitKeys.length === 1 ) return daysArr.map( () => unitKeys[ 0 ] )

        const unitDays = unitKeys.map( key => [ key, getDaysArr( units[ key ] ) ] );
        return daysArr.map( monthDay => {
            let unitI = unitDays.find( arr => arr[ 1 ].includes( monthDay ) );
            return unitI ? unitI[ 0 ] : null
        } )
    }

    // PROPS & ATTRIBUTES
    const caretProps = { disabled: false, onClick: changeMonth };
    caretProps.hasBorder = caretProps.isPrimaryColour = true;

    // COMPONENTS
    const weekComponents = Object.keys( weeks ).map( weekNum => <Week
        key={`week-${ weekNum }`}
        units={ units }
        unitIndexes={ getRelevantUnits( weeks[ weekNum ].days ) }
        weekNum={ weekNum * 1 }
    /> );

    // RENDER
    return <section className={ styles.month } id={ id }>
        <header>
            <Caret { ...caretProps } direction='left'/>
            <h2>{ get.monthName( month.i, course.locale ) }</h2>
            <Caret { ...caretProps } direction='right'/>
        </header>
        <div className={ styles.weeks }>{ weekComponents }</div>
    </section>
}

export default Month;