import styles from './Bee.module.css';

const actions = [
    { move: 'float', steps: 9, },
    { move: 'hit', steps: 3, },
    { move: 'attack', steps: 6, },
    { move: 'die', steps: 7, },
]

const Bee = ({
    hue=0,
    frameWidth=64,
    frameHeight=64,
    actionI=3
}) => {
    let url = '/bee_' + ( hue <= 45 && hue > 20 ? 'orange.png'
        : hue <= 65 ? 'yellow.png'
        : hue <= 165 ? 'green.png'
        : hue <= 255 ? 'blue.png'
        : 'purple.png' );

    const attributes = {
        className: styles.sprite,
        style: {
            backgroundImage: `url( ${ url } )`,
            '--steps': actions[ actionI ].steps,
            '--frame-width': `${ frameWidth }px`,
            '--frame-height': `${ frameHeight }px`,
            '--animation-row': actionI,
        }
    };

    return <div { ...attributes }/>
}

export default Bee;