import get from '../../../constants/get';
import Button from './Button';

const Caret = ({
    direction='down',
    disabled=false,
    hasBorder=true,
    isPrimaryColour=false,
    onClick=() => {}
}) => {
    // PROPS & ATTRIBUTES
    const props = {
        disabled, hasBorder, onClick,
        className: get.classNameFromArr([
            'caret',
            direction,
            isPrimaryColour ? 'primary' : 'secondary',
            hasBorder && 'clip-to-text',
            disabled && 'inactive',
        ]),
        label: <i className={ `fa-solid fa-caret-${ direction.toLocaleLowerCase() }` } />,
        type: 'button',
    };

    // RETURN
    return <Button { ...props }/>
}

export default Caret;