import { useContext } from 'react';
import { Link } from 'react-router-dom';
import Nav from '../Nav';
import Button from '../Button';
import AnimatedLogo from '../../modular/Images/AnimatedLogo';
import Hero2 from './Hero2';
import Solutions2 from './Solutions2';
import productInfo from '../../../constants/productInfo';
// import HeroForm from './HeroForm';
import get from '../../../constants/get';
import UserContext from '../../../contexts/UserContext';
import AccountContext from '../../../contexts/AccountContext';
import styles from './Landing2.module.css';
import Footer from '../../modular/Footer';

const [ HeadlineH1, HeadlinePrefix, hsl ] = [
    <h1 className={ styles.headline }>Empower students to own their choices</h1>,
    <p className={ styles[ 'headline-prefix' ] }>more than just a pretty choice board</p>,
    get.randomHSL( false, 55, 35 ),
    <p className={ styles[ 'launch-announcement' ] }>coming August 2023!</p>,
];
const nonWhiteBackground = `hsl( ${ hsl.h - 10 }, 100%, 50% )`;
// const Form = <HeroForm background={ nonWhiteBackground }/>;

const Landing2 = () => {
    document.title = 'BumbleBoard | Easy ChoiceBoards For the Modern Classroom';
    const user = useContext( UserContext );
    const { callLogout } = useContext( AccountContext );

    // const avatars = {
    //     student: '🧑🏿‍💻 👩🏿‍💻 👨🏿‍💻 🧑🏾‍💻 👩🏾‍💻 👨🏾‍💻 🧑🏽‍💻 👩🏽‍💻 👨🏽‍💻 🧑🏼‍💻 👩🏼‍💻 👨🏼‍💻 🧑🏻‍💻 👩🏻‍💻 👨🏻‍💻'.split(' '),
    //     teacher: '🧑🏿‍🏫 👩🏿‍🏫 👨🏿‍🏫 🧑🏾‍🏫 👩🏾‍🏫 👨🏾‍🏫 🧑🏽‍🏫 👩🏽‍🏫 👨🏽‍🏫 🧑🏼‍🏫 👩🏼‍🏫 👨🏼‍🏫 🧑🏻‍🏫 👩🏻‍🏫 👨🏻‍🏫'.split(' '),
    //     guardian: '🧑🏿‍💼 👩🏿‍💼 👨🏿‍💼 🧑🏾‍💼 👩🏾‍💼 👨🏾‍💼 🧑🏽‍💼 👩🏽‍💼 👨🏽‍💼 🧑🏼‍💼 👩🏼‍💼 👨🏼‍💼 🧑🏻‍💼 👩🏻‍💼 👨🏻‍💼'.split(' ')
    // };

    // const questions = {
    //     students: [
    //         [ <>Is this going in the gradebook ?</> ],
    //         [ <>What can I do to raise my grade ? </> ],
    //         [
    //             <>The gradebook says I'm missing { getRedacted( 'assignment-name' ) }</>,
    //             <>I can't find it in { getRedacted( 'insert-LMS' ) }</>
    //         ],
    //         [ <>I don't want to do that assignment</> ],
    //         [ <>All done! Which assignments can I start early ?</> ],
    //         [
    //             <>My parents took me to Germany for 2 weeks;</>,
    //             <>that's why I was absent.</>,
    //             <>Did we do anything?</>
    //         ],
    //         [ <>I'm pretty sure I turned { getRedacted( 'assignment-name' ) } in on time!</> ],
    //     ],
    //     teachers: [
    //         [ <>How did { getRedacted( 'student' ) } use class time ?</> ],
    //         [
    //             <>Lah</>,
    //             <>Dee</>,
    //             <>Dah</>
    //         ],
    //     ],
    //     guardians: [
    //         [
    //             <>Our child says that they turned a bunch of work in</>,
    //             <>why does your gradebook show otherwise ?</>,
    //         ],
    //         [
    //             <>The gradebook says that our child has missing work</>,
    //             <>but we cannot find them in your { getRedacted( 'insert-LMS' ) }</>
    //         ],
    //         [
    //             <>My child says you assign too much work</>,
    //             <>They're not the only one who has fallen behind</>
    //         ],
    //         [
    //             <>We are getting our child a tutor</>,
    //             <>Could we get an outline of what you're going over in class ?</>,
    //             <>The syllabus isn't exactly clear</>
    //         ],
    //         [
    //             <>We're taking our child to Lebanon for a week</>,
    //             <>What work will they need to do ?</>
    //         ],
    //     ],
    // };
    const isLoggedIn = !!user.lastLoginOn;

    // PROPS & ATTRIBUTES
    const navProps = { leftIcon: <AnimatedLogo className={ styles.logo }/> };
    navProps.rightElement = isLoggedIn ? <Button label='log out' looksLikeLink onClick={ callLogout }/>
        : <Link className={ styles.login } to={ '/login' }>log in</Link>;

    navProps.rightIcon = <Button
        backgroundGradientColours={[ nonWhiteBackground ]}
        label={ isLoggedIn ? 'Dashboard' : 'Sign Up' }
        isLink
        linkTarget='/signup'
    />;

    // COMPONENTS
    // const [ StudentBubbles, TeacherBubbles, GuardianBubbles ] = Object.values( questions ).map(
    //     ( questionCategory, i ) => {
    //         const category = i === 0 ? 'student' : i === 1 ? 'teacher' : 'guardian';

    //         return <div
    //             className={ styles[ 'bubble-container' ] }
    //             key={ [ category, 'questions' ].join( '-' ) }
    //         >{ questionCategory.map( ( questionArr, j ) => {
    //             const bubbleI = questionArr.length;

    //             return <div
    //                 className={ styles.question }
    //                 key={ [ category, 'question', j ].join( '-' ) }
    //             >{ questionArr.map( ( message, k ) => <SpeechBubble
    //                 avatar={ avatars[ category ][ get.randomNum( avatars[ category ].length, false ) ] }
    //                 fontSize='.8rem'
    //                 key={ [ category, 'question', 'bubble', k ].join( '-' ) }
    //                 isAlone={ bubbleI === 1 }
    //                 isBottom={ bubbleI > 1 && k === bubbleI - 1 }
    //                 isThought={ i === 1 }
    //                 isReverse
    //                 isTop={ bubbleI > 1 && k === 0 }
    //                 message={ message }
    //             /> ) }</div>
    //         } ) }</div>
    //     }
    // );

    // const SpeechBubbles = [ ...questions.students, ...questions.guardians ].map(
    //     ( questionArr, i ) => {
    //         const [ bubbleI, isStudent ] = [ questionArr.length, i < questions.students.length ];
    //         const containerProps = {
    //             className: styles.question,
    //             key: [
    //                 isStudent ? 'student' : 'parent',
    //                 'question',
    //                 isStudent ? i : i - questions.students.length
    //             ].join( '-' ),
    //         };

    //         return <div { ...containerProps }>{ questionArr.map( ( message, j ) => <SpeechBubble
    //             key={ [ containerProps.key, 'bubble', j ].join( '-' ) }
    //             isAlone={ bubbleI === 1 }
    //             isBottom={ bubbleI >= 2 && j === bubbleI - 1 }
    //             isReverse={ !isStudent }
    //             isTop={ bubbleI >= 2 && j === 0 }
    //             message={ message }
    //         /> ) }</div>
    //     }
    // )

    // RENDER
    return <article className={ styles.page }>
        <Nav { ...navProps }/>
        <Hero2
            background={ nonWhiteBackground }
            // Form={ Form }
            HeadlineH1={ HeadlineH1 }
            HeadlinePrefix={ HeadlinePrefix }
            hsl={ hsl }
        />
        {/* { Form } */}
        <Solutions2
            background={ nonWhiteBackground }
            solutionsArr={ productInfo.getSolutions() }
        />
        <Footer />
    </article>
}

export default Landing2;