import { useState } from 'react';
import moduleInfo from '../../../constants/moduleInfo';
import HiveNav from '../../modular/Nav/HiveNav';
import ToggleButton from '../../modular/Buttons/ToggleAndLabels';
import CourseSection from './CourseSection';
import SideNavButton from '../../modular/Buttons/SideNavButton';
import Footer from '../../modular/Footer';
import styles from './Dashboard.module.css';

/*
 * 1. main sidebar
 * 2. nav button
 * 3. footer
 * 4. main area with sortable components
 * 5. views: student activity, courses
 */
const Dashboard = () => {
    const [ viewByCourse, setViewByCouse ] = useState( true );

    const [ menuItems, toggleLabels ] = [
        [ 'Account', 'Courses', 'Activity' ],
        [ 'period view', 'course view' ]
    ];

    // FUNCTIONS
    const toggleView = () => setViewByCouse( viewByCourse => !viewByCourse )

    // COMPONENTS
    const menuListItems = menuItems.map( ( item, i ) => <li key={`home-menu-item-${ i }`}>{ item }</li> );

    // RENDER
    return <article className={ styles.home }>
        <HiveNav menu={ moduleInfo.navMenus.dashboard }/>
        <SideNavButton
            className={ styles['side-nav'] }
            menuItems={ menuListItems }
        />
        <section className={ styles.content }>
            <ToggleButton
                labels={ toggleLabels }
                onClick={ toggleView }
                showYearly={ viewByCourse }
            />
            <CourseSection
                className={ styles.course }
                viewByCourse={ viewByCourse }
            />
        </section>
        <Footer />
    </article>
}

export default Dashboard;