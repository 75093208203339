// import TextInputButton from '../../pages/TextInputButton';
import styles from './UnitsBanner.module.css';
// import { useSortable } from '@dnd-kit/sortable';
import { useState } from 'react';

const UnitSquare = ({
    barBackground = '',
    changeBarBackground = () => {},
    i = 0,
    onClick = null,
    theme = '',
}) => {
    const [ editColour, setEditColour ] = useState( true );

    // const {
    //     attributes,
    //     listeners,
    //     setNodeRef,
    //     transform,
    //     transition,
    // } = useSortable({ id: theme });

    // const transformStyle = transform ? {
    //     transform: `translate3d( ${ transform.x }px, ${ transform.y }px, 0 )`,
    //     transition,
    // } : {}

    // FUNCTIONS
    const changeColourBar = ({ target: { value } }) => changeBarBackground( i, value )

    const titleStr = ( str='' ) => {
        if ( !str ) return str

        const titleWord = ( word='' ) => word[ 0 ].toLocaleUpperCase() + word.slice( 1 ).toLocaleLowerCase();

        const strArr = str.includes(' ') ? str.split(' ') : [ str ];

        const strInTitleCase = strArr[ 1 ] ? strArr.map(
            word => titleWord( word )
        ).join(' ') : titleWord( str );

        return strInTitleCase;
    }

    const toggleColourInput = () => setEditColour( editColour => !editColour )

    // PROPS & ATTRIBUTES
    const attr = {
        sortable: {
            // ...attributes,
            // ...listeners,
            // ref: setNodeRef,
            className: styles.theme,
            id: theme,
            style: { '--background': barBackground }, // ...transformStyle
        },
        colourInput: {
            type: 'color',
            value: barBackground,
            onChange: changeColourBar,
        },
        colourBar: {
            className: styles.bar,
            onClick: toggleColourInput,
        },
        cover: { className: styles.cover, id: `unit-${ i }` }
    };

    if ( onClick !== null ) attr.cover.onClick = onClick

    // COMPONENTS
    const ColourInput = <input { ...attr.colourInput }/>;
    // const ColourInput = <TextInputButton
    //     // autocomplete
    //     // buttonLabel=''
    //     // className=''
    //     controlledValue={ barBackground }
    //     // inputID=''
    //     // inputLabel=''
    //     // inputLabelTransform={}
    //     inputType='color'
    //     // isDisabled
    //     // isRequired
    //     // fillAllBorders
    //     // uncontrolledValue={ barBackground }
    //     passValueUp={ changeColourBar }
    // />

    // RENDER
    return <div { ...attr.sortable }>
        <div { ...attr.cover }/>
        <div className={ styles.text }>
            <h3 className={ styles.title }>{ titleStr( theme ) }</h3>
            <p className={ styles.subtitle }>{`Theme ${ i + 1 }`}</p>
        </div>
        <div { ...attr.colourBar }>{ editColour && ColourInput }</div>
    </div>
}

export default UnitSquare;