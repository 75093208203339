import { GoogleAuthProvider, signInWithCredential, sendSignInLinkToEmail, isSignInWithEmailLink, signInWithEmailLink } from 'firebase/auth';
import { auth, db } from '../../firebase_setup/firebase';
import { doc, getDoc } from 'firebase/firestore';
import courseInfo from '../../constants/courseInfo';
import get from '../../constants/get';
// import { getUserUid, createUserV0 } from './userServices';
// import firePush from '../edding/pushFireData';


// const googleProvider = new GoogleAuthProvider();
const getActionCodeSettings = ( email = '' ) => ({
    url: [
        'http:/',
        window.location.href.includes( 'localhost' ) ? 'localhost:3000'
            : [ window.location.href.split( '.com' )[ 0 ], '.com' ].join( '' ),
        'login',
        `link?email=${ email }`
    ].join( '/' ),
    // iOS: {
    //     bundleId: 'com.example.ios'
    // },
    // android: {
    //     packageName: 'com.example.android',
    //     installApp: true,
    //     minimumVersion: '12'
    // },
    handleCodeInApp: true,
    // dynamicLinkDomain: 'example.page.link'
})

const getUserObjFromResponse = async ( response={}, rawUserInfo=null ) => {
    const { user } = response;
    const [ isGoogle, tokenResponse ] = [
        response.providerId && response.providerId.includes( 'google' ),
        response[ '_tokenResponse' ]
    ];

    // Search backend db for existing Auth Uid
    const userDoc = await getDoc( doc( db, 'users', user.uid ) );
    const isNewUser = tokenResponse.isNewUser || !userDoc.exists();

    if ( !isNewUser ) {
        const userData = userDoc.data();
        userData.data = { ...user, authProvider: response.providerId };

        if ( isGoogle ) {
            userData.data.name = user.displayName;
            if ( tokenResponse.firstName ) userData.data.first = tokenResponse.firstName
            if ( tokenResponse.lastName ) userData.data.last = tokenResponse.lastName
            if ( rawUserInfo.locale ) userData.data.locale = rawUserInfo.locale
        }
        userData.schoolYearID = get.schoolYearID();
        // console.log( '👤', userData );
        // user?.courses?.queen?.forEach( course => {
        //     [ 'submissions', 'units', 'syllabus', 'students', 'boards', 'assignments' ].forEach(
        //         ( e, i ) => {
        //             const item = i === 0 ? []
        //                 : i ===
        //         }
        //     )
        //     if ( !course.submissions ) course.submissions = [];
        // } );
        return userData

    } else if ( isNewUser ) {
        const userTemplate = courseInfo.getUserObj(
            user,
            'en',
            { position: 'teacher', departments: 'World Languages' },
            { adminEmail: 'bumbleboard@gmail.com', adminLastName: 'BumbleBoard', adminTitle: 'Mx.'},
            {
                email: user.email,
                firstName: ( isGoogle && tokenResponse.firstName ) ? tokenResponse.firstName : '',
                lastName: ( isGoogle && tokenResponse.lastName ) ? tokenResponse.lastName : '',
            }, { isTeacher: true }, { schoolYearID: get.schoolYearID() }, { isFree: true }
        );

        // await firePush.createUser( user, userTemplate );
        userTemplate.data = isGoogle ? { ...rawUserInfo, ...user } : { ...user };
        // console.log( 'TEMPLATE', userTemplate );

        return [ userTemplate ]
    }
}

export async function signUserInWithEmailLink( email='' ) {
    const actionCodeSettings = getActionCodeSettings( email );
    window.location.hostname === 'localhost' && console.log( 'is it a sign in link?', auth.currentUser, email, actionCodeSettings );
    let obj = null;

    if ( isSignInWithEmailLink( auth, window.location.href ) ) {
        window.location.hostname === 'localhost' && console.log( 'YES!' );

        await signInWithEmailLink( auth, email, window.location.href ).then( response => {
            window.location.hostname === 'localhost' && console.log( response );
            window.localStorage.removeItem( 'emailForSigningIn' );
            obj = getUserObjFromResponse( response );
        } );
    } else {
        window.location.hostname === 'localhost' && console.log( 'nope! sending an email' );

        window.localStorage.setItem( 'emailForSigningIn', email );
        sendSignInLinkToEmail( auth, email, actionCodeSettings );
    }

    return obj
}

export async function signInWithGoogleV0( token='' ) {
    try {
        const credential = GoogleAuthProvider.credential( token );
        const response = await signInWithCredential( auth, credential );

        // Get the Authentication User Info
        // const { user } = response; // USE TO GET NAME
        const rawUserInfo = JSON.parse( response._tokenResponse.rawUserInfo );
        // console.log( '🔐', credential, '\n🎉', response, '\n🚧', '\n👤', user );

        let obj = {};
        await getUserObjFromResponse( response, rawUserInfo ).then( response => { obj = response } );
        return obj

    } catch( error ) { console.error( '❌', error.message ) }
}

// export async function registerWithEmailAndPasswordV0( name, email, password ) {
//     try {
//         const res = await createUserWithEmailAndPassword( auth, email, password );
//         // console.log("user")
//         const user = res.user;
//         const docs = await getUserUid( user.uid );
//         console.log("-----printing docs from registerWithEmailAndPasswordV0----" )
//         console.log(docs)

//         if ( docs.length === 0 ) {
//             const userData = {
//                 uid: user.uid,
//                 name: user.displayName,
//                 authProvider: 'google',
//                 email: user.email,
//             };

//             const response = await createUserV0( user.uid, userData );
//             return response
//         }
//         // Add Record Doc for User in Native Project
//     //   await addDoc(collection(db, "users"), {
//     //     uid: user.uid,
//     //     name,
//     //     authProvider: "local",
//     //     email,
//     //   })

//     } catch ( error ) {
//         console.error( error );
//         alert( error.message );
//     }
// };