import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { useState } from 'react';
import Landing from './components/pages/Landing/Landing';
import Pricing from './components/pages/Pricing';
import SignIn from './components/pages/SignIn/SignIn';
import Profile from './components/pages/Profile/Profile';
import Error from './components/pages/Error';
import Sheets from './components/pages/Sheets';
import Dashboard from './components/pages/Dashboard/Dashboard';
import Hive from './components/pages/Hive/Hive';
import Planner2 from './components/pages/Hive/Calendar/Planner';
import Planner from './components/pages/Hive/Planner/Planner';
import Activity from './components/pages/Hive/Activity/Activity';
import Gradebook from './components/pages/Hive/Gradebook/Gradebook';
import BoardView from './components/pages/Hive/BoardView/BoardView';
import GuestBoard from './components/pages/Hive/BoardView/GuestBoard';
import AuthProvider from './AuthProvider';
import ProtectedRoute from './components/modular/Routes/ProtectedRoute';
import Gandalf from './components/pages/Beekeepers/Gandalf/Gandalf';
import './App.css';
import MrsBates from './components/pages/Beekeepers/MrsBates/MrsBates';
import Landing2 from './components/pages/Landing/Landing2';
import get from './constants/get';
import Pricing2 from './components/pages/Pricing/Pricing';
import Contact from './components/pages/Pricing/Contact';
import Policy from './components/pages/Policies/Policy';
import Shop from './components/pages/Shop/Shop';
import ShopItem from './components/pages/Shop/ShopItem';
import Admin from './components/pages/Admin/Admin';
import ShopWrapper from './components/pages/Shop/ShopWrapper';

const localStorageVar = 'bumbleBoardUserData';

function App() {
// localStorage.removeItem( localStorageVar );
// console.log( '🫙', JSON.parse( localStorage.getItem( localStorageVar ) ) );

// https://blog.logrocket.com/user-authentication-firebase-react-apps/
// https://menubar.io/firebase-facebook-login-react
    const [ user, setUser ] = useState(
        localStorageVar in localStorage && 'data' in JSON.parse( localStorage.getItem( localStorageVar ) ) ?
            JSON.parse( localStorage.getItem( localStorageVar ) ) : initiateUserObj( true )
    );

    get.log({
        fileName: 'App.js',
        functionDirectionsOrComponentName: '👤 { user }',
        lineNumber: 44,
        str: user,
    });

    const [ isEUCompliant, isWaitlistSeason, schoolYearID ] = [
        false,
        new Date().getMonth() < 7,
        get.schoolYearID(),
    ];

    // FUNCTIONS
    function initiateUserObj( isInitialUserState=false ) {
        const { userAgentData: { mobile, platform } } = navigator;
        const deviceInfo = { isMobile: mobile, OS: platform };

        return isInitialUserState ? { ...deviceInfo, data: { uid: null } } : { ...deviceInfo, ...user }
    }

    function updateUser( obj={} ) {
        localStorage.setItem( localStorageVar, JSON.stringify( obj ) );

        setUser( () => ({ ...obj }) );
    }

    // PROPS & ATTRIBUTES
    const props = {
        noSignInProtectedRoute: {
            isLoggedIn: false,
            isAllowed: user.data?.uid === null,
            redirectPath: user?.email?.split( '@' )?.shift(),
            // redirectPath: ( user.data.email && typeof user?.plan?.i === 'number' ) ? user?.email?.split( '@' )?.shift()
            //     : [ user?.email?.split( '@' )?.shift(), 'mrsbates' ].join( '/' ),
        },
        needSignInProtectedRoute: {
            isAllowed: !!user.data.photoURL,
            redirectPath: '/signin'
        },
        guestHiveProtectedRoute: {
            isAllowed: !!user.data.isGuest,
            isWorkerBee: true
        },
        gandalf: { schoolYearID, updateUser },
    };

    props.authProvider = { ...props.gandalf, user };
    props.buildProfile = { ...props.authProvider, localStorageVar };

    // RENDER
    return <div className='App'>
        <Router>
            <Routes>
                <Route path='/' element={ <AuthProvider { ...props.authProvider }/> }>
                    <Route path='' element={ <ProtectedRoute { ...props.noSignInProtectedRoute } /> }>
                        <Route path='/login' element={ <SignIn /> }>
                            <Route path='/login/link' element={ <SignIn /> }/>
                        </Route>
                        <Route path='/signin' element={ <SignIn /> }/>
                        <Route path='/signup' element={ <SignIn toCreateAccount/> }/>
                        <Route path='/pricing2' element={ <Pricing /> }/>
                    </Route>

                    <Route path='/:username' element={ <ProtectedRoute { ...props.needSignInProtectedRoute }/> }>
                        <Route index element={ <Profile /> }/>
                        <Route path='/:username/admin' element={ <Admin /> } />
                        <Route path='/:username/mrsbates/:idType?/:id?' element={ <MrsBates { ...props.buildProfile } /> }/>
                        <Route path='/:username/export-sheet' element={ <Sheets /> }/>
                        <Route path='/:username/dashboard' element={ <Dashboard /> }/>
                        <Route path='/:username/shop' element={ <ShopWrapper /> }>
                            <Route path='/:username/shop/:subject/:level/:unit/:unitI/:dbID' element={ <ShopItem /> }/>
                            <Route path='/:username/shop/:subject?/:level?/' element={ <Shop /> }/>
                        </Route>
                        <Route path='/:username/:id/' element={ <Hive /> }>
                            {/* <Route index element={ <Planner /> }/> */}
                            <Route index element={ <BoardView /> }/>
                            <Route path='/:username/:id/:section?/activity' element={ <Activity /> }/>
                            <Route path='/:username/:id/:section?/boards/:boardNum?' element={ <BoardView /> }/>
                            <Route path='/:username/:id/:section?/*' element={ <Planner /> }/>
                            <Route path='/:username/:id/:section?/gradebook' element={ <Gradebook /> }/>
                        </Route>
                            {/* <Route path='/:username/*' element={ <Error /> }/> */}
                    </Route>

                    <Route path='/workerbee/:id' element={ <ProtectedRoute { ...props.guestHiveProtectedRoute }/> }>
                        <Route path='' element={ <Hive /> }>
                            <Route index element={ <Activity /> }/>
                            <Route path='/workerbee/:id/activity' element={ <Activity /> }/>
                            <Route path='/workerbee/:id/boards/:boardNum?' element={ <GuestBoard /> }/>
                            <Route path='/workerbee/:id/planner' element={ <Planner /> }/>
                            <Route path='/workerbee/:id/calendar' element={ <Planner2 /> }/>
                            <Route path='/workerbee/:id/gradebook' element={ <Gradebook /> }/>
                            {/* <Route path='*' element={ <Error /> }/> */}
                        </Route>
                    </Route>

                    <Route index element={ <Landing2 /> }/>

                    <Route path='/privacy' element={ <Policy isEUCompliant={ isEUCompliant } type={{ privacy: true }}/> }/>
                    {/* <Route path='/terms' element={ <Policy isEUCompliant={ isEUCompliant } type={{ terms: true }}/> }/> */}
                    <Route path='/landing'  element={ <Landing /> }/>
                    <Route path='/pricing' element={ <Pricing2 /> }/>
                    <Route path='/gandalf/:id' element={ <Gandalf { ...props.gandalf } /> }/>

                    <Route path='/contact' element={ <Contact /> }/>
                    <Route path='/plan/:planName' element={ <Contact isWaitlist={ isWaitlistSeason }/> }/>

                    <Route path='*' element={ <Error /> }/>
                </Route>
            </Routes>
        </Router>
    </div>
}

export default App;