import { useState } from 'react';
import CallToAction from '../../modular/Buttons/CallToAction';
import styles from './Contact.module.css';

const Contact = () => {
    const [data, setData] = useState({
        first: '',
        last: '',
        email: '',
        'org-type': '- Select -',
        'org-name': '',
    });

    const orgTypes = {
        '- Select -': '- Select an org. type -',
        'K-12': 'County / Parish',
        'Higher Ed': 'College / University',
        'Corporate Ed': 'Company'
    };

    const handleChange = ({ target }) => {
        setData( data => ({
            ...data,
            [target.name]: target.value
        }) );
    }

    const handleSubmit = e => {
        e.preventDefault();
        // send data to server
    }

    return <section className={styles.contact} id='contact'>
        <h2>Ready to get students working?</h2>
        <form onSubmit={handleSubmit}>
            <h3>REQUEST A DEMO</h3>
            <fieldset className={styles.name}>
                <div>
                    <input
                        className={styles[data.first ? 'has-value' : 'empty']}
                        type='text'
                        name='first'
                        aria-invalid='true'
                        value={data.first}
                        onChange={handleChange}
                        autoComplete='given name'
                        required
                    />
                    <label htmlFor='first'>First:</label>
                </div>
                <div>
                    <input
                        className={styles[data.last ? 'has-value' : 'empty']}
                        type='text'
                        name='last'
                        value={data.last}
                        onChange={handleChange}
                        autoComplete='family name'
                        required
                    />
                    <label htmlFor='last'>Last:</label>
                </div>
            </fieldset>
            <fieldset className={styles.email}>
                <div>
                    <input
                        className={styles[data.email ? 'has-value' : 'empty']}
                        type='text'
                        name='email'
                        value={data.email}
                        onChange={handleChange}
                        autoComplete='email'
                        required
                    />
                    <label htmlFor='email'>Email:</label>
                </div>
            </fieldset>
            <fieldset className={styles.organisation}>
                <div>
                    <select
                        className={styles[data['org-type'] !== Object.keys(orgTypes)[0] ? 'has-value' : 'empty']}
                        id='org-type-select'
                        name='org-type'
                        onChange={handleChange}
                        required
                    >{ Object.keys(orgTypes).map( orgType => <option
                        value={orgType}
                        key={orgType}
                    >{orgType}</option> ) }</select>
                    <label htmlFor='org-type-select'>Organisation Type:</label>
                </div>
                <div>
                    <input
                        className={styles[data['org-name'] ? 'has-value' : 'empty']}
                        type='text'
                        name='org-name'
                        value={data['org-name']}
                        onChange={handleChange}
                        autoComplete='organization'
                        required
                        disabled={ data['org-type'] === Object.keys( orgTypes )[0] }
                    />
                    <label htmlFor='org-name'>{ orgTypes[data['org-type']] }</label>
                </div>
            </fieldset>
            <br />
            <div className={styles.button}>
                <CallToAction type='submit' label='REQUEST A DEMO'/>
            </div>
        </form>
    </section>
}

export default Contact;