const directions = {
    x: [ 'left', 'right' ],
    y: [ 'down', 'up' ],
    all() { return [ ...this.x, ...this.y ] },
    type( i = 0 ) { return [ this.x[i], this.y[i] ] },
};

const carouselPositions = {
    '-3': 'far-left',
    '-2': 'near-left',
    '-1': 'left',
    0: 'centre',
    1: 'right',
    2: 'near-right',
    3: 'far-right',
};

const navMenus = {
    landing: {
        logo: {
            href: '#hero'
        },
        labels: {
            0: {
                href: '#features',
                label: 'features',
            },
            1: {
                href: '#reviews',
                label: 'reviews',
            },
            2: {
                href: '#contact',
                label: 'contact',
            },
            3: {
                callToAction: true,
                to: '/',
                label: 'log in',
            },
        },
    },
    pricing: {
        labels: {
            1: {
                to: '/',
                label: 'home',
            },
        },
    },
    profile: {
        labels: {
            1: {
                to: '/',
                label: 'dashboard',
            },
        },
    },
    signIn: {
        labels: {
            1: {
                to: '/',
                label: 'home',
            },
        },
    },
    sheets: {
        labels: {
            1: {
                to: '/',
                label: 'home',
            },
        },
    },
    dashboard: {
        logo: {
            isPremium: true,
            href: '/'
        },
        labels: {
            1: {
                to: '/profile',
                label: 'profile',
                URLlabel: '/'
            },
        },
    },
    hive: {
        labels: {
            1: {
                to: '/hive/',
                label: 'work',
                URLlabel: 'boards',
            },
            2: {
                to: '/hive/',
                label: 'plan',
                URLlabel: 'calendar',
            },
            3: {
                to: '/hive/',
                label: 'track',
                URLlabel: 'activity',
            },
            4: {
                to: '/hive/',
                label: 'grade',
                URLlabel: 'gradebook',
            }
        },
    },
};

const pricing = {
    0: {
        title: 'Live',
        benefits: {
            teacher: {
                title: 'Teachers can',
                items: [
                    'create classes',
                    'assign work',
                ],
            },
            student: {
                title: 'Students can',
                items: [
                    'choose the path of their learning'
                ],
            },
        },
        button: {
            label: {
                monthly: 'free',
                yearly: 'free',
            },
            isPrimary: false,
        }
    },
    1: {
        title: 'Laugh',
        benefits: {
            teacher: {
                title: 'Teachers can',
                items: [
                    'create classes w/ sections',
                    'assign work to all sections at once with appropriate due dates',
                ],
            },
            student: {
                title: 'Students can',
                items: [
                    'choose the path of their learning',
                    'search for assignments by link or title',
                    'suggest preferred websites to teacher',
                    'choose whether their work can be shared',
                ],
            },
        },
        button: {
            label: {
                monthly: 'free trial',
                yearly: 'free trial',
            },
            isPrimary: true,
        }
    },
    2: {
        title: 'Love',
        benefits: {
            teacher: {
                title: 'Teachers can',
                items: [
                    'create classes w/ sections',
                    'assign work to all sections at once with appropriate due dates',
                    'plan with Collaborative Team (CT)',
                    'share common CT assignments & assessments',
                ],
            },
            student: {
                title: 'students can',
                items: [
                    'choose the path of their learning',
                    'search for assignments by link or title',
                    'suggest preferred websites to teacher',
                    'choose whether their work can be shared',
                    'collaborate with students from other sections',
                ],
            },
        },
        button: {
            label: {
                monthly: 'Contact us',
                yearly: 'Contact us',
            },
            isPrimary: false,
        }
    },
};

const setupSteps = {
    native: {
        10: {
            visual: {
                src: '',
                type: 'image, gif',
            },
            directions: {
                title: '',
                details: [
                    '',
                ],
            },
            attachments: {
                type: 'plain-link, iframe-link, doc-link',
                url: '',
                title: {
                    icon: {
                        imgSrc: '',
                        iconClass: 'fa-solid...',
                    },
                },
            }
        },
    },
    external: {
        0: {
            visual: {
                src: '/board-view1.png',
                alt: '',
                type: 'gif',
            },
            directions: {
                title: 'Get your TeacherBoard<sup>TM</sup>',
                details: [
                    'Click on the link below',
                    'Click on "USE TEMPLATE" (blue button, right corner)',
                    'Return here while your sheet opens',
                ],
            },
            attachments: [ [], [{
                iframeSrc: 'https://docs.google.com/spreadsheets/d/e/2PACX-1vSKQBrL8EXKs0NEU6SzSmAooiNRH8Bgyh4gtdg0ZfyJA9O5gwWYfBZVVSyj5D-F26TbEUyzv6yQ0wIN/pubhtml?gid=0&amp;single=true&amp;widget=true&amp;headers=false',
                icon: {
                    imgSrc: '/favicon.png',
                },
                label: 'TeacherBoard<sup>TM</sup>',
                href: 'https://docs.google.com/spreadsheets/d/1jVMGk2LJvRJRXk242YDmn-5__SjBFvK04cEmo-ge1PA/template/preview',
            }] ]
        },
        1: {
            visual: {
                src: '/board-view1.png',
                alt: '',
                type: 'gif',
            },
            directions: {
                title: 'Activate your TeacherBoard<sup>TM</sup>',
                details: [
                    'Go to your open sheet',
                    'Hover over any cell with a red mark in the corner',
                    'Click on "ALLOW" (blue button) in the popup',
                    'Return here while your sheet connects to ours',
                ],
            },
        },
        2: {
            visual: {
                src: '/board-view1.png',
                alt: '',
                type: 'gif',
            },
            directions: {
                title: 'Set up ESSENTIALS & WEEKS',
                details: [
                    'Go to your open sheet\'s settings page',
                    'Type in any of the pale-colour boxes under the ESSENTALS banner',
                    'Press the [+] button next to the WEEKS banner in the left (grey) margin',
                    'Type in any of the pale-colour boxes under the WEEKS banner',
                    'Return here once your dates are all correct',
                ],
            },
        },
        3: {
            visual: {
                src: '/board-view1.png',
                alt: '',
                type: 'gif',
            },
            directions: {
                title: 'Get your StudentBoard<sup>TM</sup>',
                details: [
                    'Click on the link below',
                    'Click on "USE TEMPLATE" (blue button, right corner)',
                    'Return here while your sheet opens',
                ],
            },
            attachments: [ [], [{
                iframeSrc: 'https://docs.google.com/spreadsheets/d/e/2PACX-1vR1tm0lqmcTfImliQRd6nsJUPntIx-EZ9Ll3Nlu6UEuQcMl1kX8VCCZzH_EByz1WXr7zmNvuQ8s6O3d/pubhtml?gid=1072681622&amp;single=true&amp;widget=true&amp;headers=false',
                icon: {
                    imgSrc: '/favicon.png',
                },
                label: 'StudentBoard',
                href: 'https://docs.google.com/spreadsheets/d/1bU9TNMfb2x8MPKG7TAEAjix1V3FXeOKR4wmhh-OqPHg/template/preview',
            }] ]
        },
    },
    10: {
        visual: {
            src: '',
            type: 'image, gif',
        },
        directions: {
            title: '',
            details: [
                '',
            ],
        },
        attachments: {
            type: 'plain-link, iframe-link, doc-link',
            url: '',
            title: {
                icon: {
                    imgSrc: '',
                    iconClass: 'fa-solid...',
                },
            },
        }
    },
};

const assignmentTypes = {
    board: {
        type: 'board',
        icon: {
            closed: 'fa-solid fa-table-cells',
            open: 'fa-brands fa-buromobelexperte',
        },
    },
    file: {
        type: 'file',
        icon: 'fa-solid fa-file'
    },
    folder: {
        type: 'folder',
        icon: {
            closed: 'fa-solid fa-folder',
            open: 'fa-regular fa-folder-open',
        }
    },
    graded: {
        type: 'graded',
        icon: 'fa-solid fa-file-lines'
    },
    link: {
        type: 'link',
        svg: {
            google: {
                jamboard: <svg xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" width="100" height="100" viewBox="0 0 48 48">
                <circle cx="24" cy="31" r="14" fill="#fdd835"></circle><rect width="14" height="14" x="24" y="3" fill="#ff8f00"></rect><rect width="14" height="14" x="24" y="17" fill="#ffb300"></rect>
                </svg>,
                docs: <svg xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" width="100" height="100" viewBox="0 0 48 48">
                    <linearGradient id="pg10I3OeSC0NOv22QZ6aWa_v0YYnU84T2c4_gr1" x1="-209.942" x2="-179.36" y1="-3.055" y2="27.526" gradientTransform="translate(208.979 6.006)" gradientUnits="userSpaceOnUse">
                        <stop offset="0" stop-color="#55adfd"></stop>
                        <stop offset="1" stop-color="#438ffd"></stop>
                    </linearGradient>
                    <path fill="url(#pg10I3OeSC0NOv22QZ6aWa_v0YYnU84T2c4_gr1)" d="M39.001,13.999v27c0,1.105-0.896,2-2,2h-26	c-1.105,0-2-0.895-2-2v-34c0-1.104,0.895-2,2-2h19l2,7L39.001,13.999z"></path>
                    <path fill="#fff" fill-rule="evenodd" d="M15.999,18.001v2.999	h17.002v-2.999H15.999z" clip-rule="evenodd"></path>
                    <path fill="#fff" fill-rule="evenodd" d="M16.001,24.001v2.999	h17.002v-2.999H16.001z" clip-rule="evenodd"></path>
                    <path fill="#fff" fill-rule="evenodd" d="M15.999,30.001v2.999	h12.001v-2.999H15.999z" clip-rule="evenodd"></path>
                    <linearGradient id="pg10I3OeSC0NOv22QZ6aWb_v0YYnU84T2c4_gr2" x1="-197.862" x2="-203.384" y1="-4.632" y2=".89" gradientTransform="translate(234.385 12.109)" gradientUnits="userSpaceOnUse">
                        <stop offset="0" stop-color="#427fdb"></stop>
                        <stop offset="1" stop-color="#0c52bb"></stop>
                    </linearGradient>
                    <path fill="url(#pg10I3OeSC0NOv22QZ6aWb_v0YYnU84T2c4_gr2)" d="M30.001,13.999l0.001-9l8.999,8.999L30.001,13.999z"></path>
                </svg>,
            }
        },
        icon: {
            blooket: '/blooket.webp',
            // blooket: 'https://static.wikia.nocookie.net/logopedia/images/b/b5/Blooket.png',
            canvas: '/canvas-logo.jpeg',
            // canvas: 'https://www.wabash.edu/images2/technology/canvas.png',
            canva: '/canva-logo.svg',
            conjuguemos: '/conjuguemos.svg',
            // conjuguemos: 'https://cdn.conjuguemos.com/images-r/coqui/coqui-book.svg',
            flipgrid: '/flip-logo.png',
            // flipgrid: 'https://theme.zdassets.com/theme_assets/2073696/5646c99fe9236d3c24557137f96bf5e5e32e2d60.png',
            flippity: 'flippity-logo.jpeg',
            // flippity: 'https://www.flippity.net/images/resources/Flippity-Logo-Happy.jpg',
            formative: '/formative-logo.jpeg',
            // formative: '/formative.png',
            google: {
                drawing: 'google-drawings-logo.jpeg',
                // drawing: 'https://w7.pngwing.com/pngs/430/768/png-transparent-google-drawings-google-logo-g-suite-google.png',
                form: '/google-forms-logo.png',
                // form: 'https://upload.wikimedia.org/wikipedia/commons/thumb/c/c2/Google_Forms_logo_%282014-2020%29.svg/1489px-Google_Forms_logo_%282014-2020%29.svg.png',
                jamboard: 'https://upload.wikimedia.org/wikipedia/commons/thumb/c/c0/Google_Jamboard_logomark.svg/1200px-Google_Jamboard_logomark.svg.png',
                sheet: 'https://upload.wikimedia.org/wikipedia/commons/thumb/3/30/Google_Sheets_logo_%282014-2020%29.svg/800px-Google_Sheets_logo_%282014-2020%29.svg.png',
                site: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSyg2WMZHTU61uDV0WLwTqFzkvjBPJJq0l81M7kuOqJ2TN-AALnm2w4liv3800EL2M3wrg&usqp=CAU',
                slide: 'https://upload.wikimedia.org/wikipedia/commons/thumb/1/16/Google_Slides_2020_Logo.svg/1489px-Google_Slides_2020_Logo.svg.png',
                firebase: 'https://cdn.cdnlogo.com/logos/f/48/firebase.svg',
                search: 'https://upload.wikimedia.org/wikipedia/commons/thumb/5/53/Google_%22G%22_Logo.svg/2008px-Google_%22G%22_Logo.svg.png',
                docs: 'https://upload.wikimedia.org/wikipedia/commons/thumb/0/01/Google_Docs_logo_%282014-2020%29.svg/555px-Google_Docs_logo_%282014-2020%29.svg.png',
            },
            goosechase: '/goosechase-logo.png',
            // goosechase: 'https://images.g2crowd.com/uploads/product/image/social_landscape/social_landscape_6ad2be8f6c05537ac50f5700e638a718/goosechase.png',
            kahoot: '/kahoot-logo.png',
            // kahoot: 'https://companieslogo.com/img/orig/KAHOT.OL-e50e329b.png?t=1603470544',
            peardeck: '/peardeck-logo.png',
            // peardeck: 'https://app.peardeck.com/static/images/logo-mark.png',
            quizizz: '/quizizz-logo.png',
            schoology: '/schoology-logo.png',
            lms: '/schoology-logo.png',
            // schoology: 'https://copyleaks.com/wp-content/uploads/2022/08/Schoology-Logo.png',
            thisislanguage: '/this-is-language.png',
            thisisschool: '/thisisschool-logo.jpeg',
            tv5: 'https://www.campusfrance.org/sites/default/files/medias/images/2020-03/icones.png',
            wizer: '/wizer-logo.webp',
            // wizer: '/wizer-me.png',
            default: 'fa-solid fa-globe',
        }
    },
    ungraded: {
        type: 'ungraded',
        icon: 'fa-regular fa-clipboard',
    },
}

const roles = {
    teacher: 'teacher',
    admin: 'admin',
    chair: 'chair',
    student: 'student',
    leader: 'leader'
}


const moduleInfo = {
    assignmentTypes,
    carouselPositions,
    directions,
    navMenus,
    pricing,
    roles,
    setupSteps,
};

export default moduleInfo;