// FUNCTIONS
const getFeatures = () => [
    'unlimited courses',
    'unlimited students',
    'text to speech',
    // 'assignment types',
    'custom syllabus',
    'gamified productivity',
    // 'lesson plans',
    // 'parent view',
    'roll course over',
    'searchable boards',
    'collaborative teams',
    'admin console',
    'school assets',
    'student & team data',
].map( feature => ({ feature }) )

const getSolutions = () => {
    const [ emojiYellow, emojiOrange, emojiLightYellow, emojiYellowOrange, fontSize ] = [
        '#ffdc5d', '#ef9645', '#fffbcc', '#ffb636', '1.5em'
    ];

    return [{
        SVGattributes: {
            fontSize,
            fill1: emojiYellow,
            fill2: emojiOrange,
            type: { 'hand palm up': true }
        },
        title: 'Student Choice',
        description: 'Easily nurture students in choosing the path of their learning'
    }, {
        SVGattributes: {
            fontSize,
            fill1: emojiYellow, // gold
            fill2: emojiOrange,
            fill3: emojiLightYellow,
            type: { 'magnifying glass handle right': true }
        },
        title: 'searchable assignments',
        description: 'Looking for an assignment? Type to find it, no endless scrolling.'
    }, {
        SVGattributes: {
            fontSize,
            fill1: emojiYellow, // gold
            fill2: emojiOrange, // orange
            type: { 'cloche serving plate': true }
        },
        title: 'assignments on a silver platter',
        description: 'The latest assignments are automatically easy to find', //"What are we doing today?" the student never asked again...'
    }, {
    //     icon: <BoardIcon
    //         completion={ Array( 9 ).fill().map( () => true ) }
    //         hsl={{ h: 35, s: '100%', l: '50%'}}
    //     />,
    //     title: 'self-organising',
    //     description: 'All hives ( classes ) are organised in the same familiar way'
    // }, {
    //     icon: <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 256 256">
    //         {/* <g fill="#888888"> */}
    //             <path fill="gold" d="M96 208a8 8 0 0 1-8 8H40a24 24 0 0 1-20.77-36l34.29-59.25l-14.05 3.75a8 8 0 1 1-4.14-15.5l32.77-8.77a8 8 0 0 1 9.8 5.66l8.79 32.77A8 8 0 0 1 81 148.5a8.37 8.37 0 0 1-2.08.27a8 8 0 0 1-7.72-5.93l-3.8-14.15L33.11 188A8 8 0 0 0 40 200h48a8 8 0 0 1 8 8Zm140.73-28l-23.14-40a8 8 0 0 0-13.84 8l23.14 40a8 8 0 0 1-6.89 12h-68.69l10.34-10.34a8 8 0 0 0-11.31-11.32l-24 24a8 8 0 0 0 0 11.32l24 24a8 8 0 0 0 11.31-11.32L147.31 216H216a24 24 0 0 0 20.77-36ZM128 32a7.85 7.85 0 0 1 6.92 4l34.29 59.25l-14.08-3.78a8 8 0 0 0-4.13 15.45l32.78 8.79a8.23 8.23 0 0 0 2.07.27a8 8 0 0 0 7.72-5.93l8.79-32.79a8 8 0 1 0-15.45-4.14l-3.8 14.17L148.77 28a24 24 0 0 0-41.54 0L84.07 68a8 8 0 0 0 13.85 8l23.16-40a7.85 7.85 0 0 1 6.92-4Z"/>
    //             <path fill='none' d="M216 208H40a16 16 0 0 1-13.84-24l88-152a16 16 0 0 1 27.7 0l88 152A16 16 0 0 1 216 208Z" opacity=".2"/>
    //             <path fill='orangered' d="M96 208a8 8 0 0 1-8 8H40a24 24 0 0 1-20.77-36l34.29-59.25l-14.05 3.75a8 8 0 1 1-4.14-15.5l32.77-8.77a8 8 0 0 1 9.8 5.66l8.79 32.77A8 8 0 0 1 81 148.5a8.37 8.37 0 0 1-2.08.27a8 8 0 0 1-7.72-5.93l-3.8-14.15L33.11 188A8 8 0 0 0 40 200h48a8 8 0 0 1 8 8Z"/>
    //             {/* <path fill='grey' d="m140.73-28l-23.14-40a8 8 0 0 0-13.84 8l23.14 40a8 8 0 0 1-6.89 12h-68.69l10.34-10.34a8 8 0 0 0-11.31-11.32l-24 24a8 8 0 0 0 0 11.32l24 24a8 8 0 0 0 11.31-11.32L147.31 216H216a24 24 0 0 0 20.77-36Z"/> */}
    //             <path fill='orange' d="M128 32a7.85 7.85 0 0 1 6.92 4l34.29 59.25l-14.08-3.78a8 8 0 0 0-4.13 15.45l32.78 8.79a8.23 8.23 0 0 0 2.07.27a8 8 0 0 0 7.72-5.93l8.79-32.79a8 8 0 1 0-15.45-4.14l-3.8 14.17L148.77 28a24 24 0 0 0-41.54 0L84.07 68a8 8 0 0 0 13.85 8l23.16-40a7.85 7.85 0 0 1 6.92-4Z"/>
    //         {/* </g> */}
    //     </svg>,
    //     title: 'Reduce. Reuse. Recycle.',
    //     description: 'Assignments can repeat, but you don\'t have to. Copy and tweak them in one go.'
    // }, {
        SVGattributes: {
            fontSize,
            fill1: emojiYellow, // #ffd469
            fill2: emojiOrange, // #e2a042
            fill3: emojiYellowOrange,
            type: { trophy: true }
        },
        title: 'Gamified Productivity',
        description: 'Encourage students to set their own goals and celebrate consistency'
    // }, {
    //     icon: <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 64 64">
    //         <path fill="#ff9d33" d="M57 26.2s-3 2.8-8.1 6.1C47.5 24.2 43.6 14.2 36 2c0 0-2.5 13.1-10.8 25.4c-3.6-5.6-5.2-10-5.2-10C-6 43.5 15.6 62 29.2 62c17.4 0 32.7-8.4 27.8-35.8"/>
    //         <path fill="#ffce31" d="M46.7 49.4c1.5-3.3 2.6-7.6 2.8-13c0 0-2.1 1.8-5.7 4.1c-1-5.4-3.7-12-9-20.2c0 0-1.7 8.7-7.5 17c-2.5-3.7-3.6-6.7-3.6-6.7c-4.3 6.8-6 12.2-6.1 16.5c-2.4-.9-3.9-1.6-3.9-1.6c4.1 12.2 12.6 14.9 16.4 14.9c6.8 0 13.7-2 20.5-11.7c0-.1-1.5.3-3.9.7"/>
    //         <path fill="#ffdf85" d="M21.9 43.9s2.8 3.8 4.9 2.9c0 0 4-6.3 9.8-9.8c0 0-1.2 9.6.2 11.3c1.8 2.3 6.7-2.5 6.7-2.5c0 5.7-6.2 12.8-11.8 12.8c-5.4 0-13.2-6.2-9.8-14.7"/>
    //         <path fill="#ff9d33" d="M49.8 18.1c2.1-3 3.5-6.2 3.5-6.2c3.5 5.8 1.4 9.3-.1 10.4c-2.1 1.6-5.8-.7-3.4-4.2m-38.2-1c-2.1-3.5-2.3-7.9-2.3-7.9c-5 7.5-3.1 11.7-1.4 12.9c2.2 1.7 6-.9 3.7-5m11.6-7.8c.3-2.4-.7-4.8-.7-4.8c4.7 3.1 4.7 5.7 4.1 6.8c-.9 1.3-3.7.7-3.4-2"/>
    //     </svg>,
    //     title: 'streaks',
    //     description: 'We celebrate students with consistent habits'
    // }, {
    //     icon: <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 20 20">
    //         <path fill="orange" d="M10 9a3 3 0 1 0 0-6a3 3 0 0 0 0 6ZM6 8a2 2 0 1 1-4 0a2 2 0 0 1 4 0Zm-4.51 7.326a.78.78 0 0 1-.358-.442a3 3 0 0 1 4.308-3.516a6.484 6.484 0 0 0-1.905 3.959c-.023.222-.014.442.025.654a4.97 4.97 0 0 1-2.07-.655Zm14.95.654a4.97 4.97 0 0 0 2.07-.654a.78.78 0 0 0 .357-.442a3 3 0 0 0-4.308-3.517a6.484 6.484 0 0 1 1.907 3.96a2.32 2.32 0 0 1-.026.654ZM18 8a2 2 0 1 1-4 0a2 2 0 0 1 4 0ZM5.304 16.19a.844.844 0 0 1-.277-.71a5 5 0 0 1 9.947 0a.843.843 0 0 1-.277.71A6.975 6.975 0 0 1 10 18a6.974 6.974 0 0 1-4.696-1.81Z"/>
    //         <path fill="gold" d="M10 9a3 3 0 1 0 0-6a3 3 0 0 0 0 6Z"/>
    //         <path fill="gold" d="M5.304 16.19a.844.844 0 0 1-.277-.71a5 5 0 0 1 9.947 0a.843.843 0 0 1-.277.71A6.975 6.975 0 0 1 10 18a6.974 6.974 0 0 1-4.696-1.81Z"/>
    //     </svg>,
    //     title: 'Teamwork',
    //     description: 'Students can work together toward common goals'
    // }, {
        // icon: <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 20 20">
        //     <g fill="gold">
        //         <g opacity=".8" fill='orange'>
        //             <path d="M10.59 5.939c3.8 1.018 6.105 4.772 5.135 8.395c-1.094 4.08-6.298 7.344-8.882 4.56c-.823-.886-1.035-1.86-.89-3.16c.025-.225.046-.372.113-.794c.162-1.032.16-1.44-.054-1.788c-.207-.336-.578-.423-1.678-.436l-.108-.002c-1.548-.022-2.3-.28-2.682-1.363c-1.27-3.589 4.779-6.556 9.046-5.412Z"/>
        //             <path fillRule="evenodd" d="M13.843 4.43c-.512-.02-.982-.338-1.306-.946c-.557-1.048-.679-2.567.111-2.987c.79-.42 1.98.53 2.538 1.579c.3.565.322 1.096.107 1.518a.995.995 0 0 1 .448.454l4.093 8.4a.652.652 0 0 1-1.149.611l-4.708-8.07a.994.994 0 0 1-.134-.559Z" clipRule="evenodd"/>
        //         </g>
        //         <path fillRule="evenodd" d="M14.725 13.334c.97-3.623-1.336-7.377-5.135-8.395C5.323 3.795-.725 6.762.544 10.35c.383 1.084 1.134 1.341 2.682 1.363l.108.002c1.1.013 1.471.1 1.678.436c.214.348.216.756.054 1.788c-.067.422-.088.569-.113.794c-.145 1.3.067 2.274.89 3.16c2.584 2.784 7.788-.48 8.882-4.56ZM1.486 10.017c-.908-2.57 4.217-5.084 7.845-4.112c3.277.878 5.252 4.093 4.428 7.17c-.924 3.446-5.287 6.181-7.183 4.14c-.594-.64-.745-1.333-.63-2.37c.024-.205.044-.344.108-.75c.2-1.275.197-1.836-.19-2.467c-.465-.756-1.059-.894-2.517-.912l-.107-.002c-1.159-.016-1.562-.154-1.754-.697Z" clipRule="evenodd"/>
        //         <path d="M4.75 9.5a1.25 1.25 0 1 1 0-2.5a1.25 1.25 0 0 1 0 2.5Zm4 0a1.25 1.25 0 1 1 0-2.5a1.25 1.25 0 0 1 0 2.5Zm2.5 3a1.25 1.25 0 1 1 0-2.5a1.25 1.25 0 0 1 0 2.5ZM9.75 16a1.25 1.25 0 1 1 0-2.5a1.25 1.25 0 0 1 0 2.5Zm3.62-12.395a1 1 0 0 1 1.371.443l4.093 8.4a.652.652 0 0 1-1.149.611l-4.708-8.07a.998.998 0 0 1 .394-1.384Z"/>
        //         <path fillRule="evenodd" d="M11.537 3.484c.487.915 1.306 1.171 2.098.75c.791-.42 1.038-1.243.551-2.158C13.63 1.028 12.438.078 11.648.497c-.79.42-.668 1.939-.11 2.987Zm.883-.47a3.165 3.165 0 0 1-.32-1.137a1.975 1.975 0 0 1 .018-.496l.018.009c.05.024.205.096.403.254c.302.241.602.596.764.901c.229.43.164.646-.138.807c-.3.16-.516.092-.745-.337Z" clipRule="evenodd"/>
        //     </g>
        // </svg>,
        // title: 'Engaging Visuals',
        // description: 'We infuse our UI with delightful easter eggs so students keep coming back for more'
    }]
}

const getFullPlans = ( className='' ) => {
    const plans = getPlanIcons( className );

    plans.forEach( ( plan, i ) => {
        if ( i === plans.length - 1 ) plan.slider = {
            category: { singular: 'teacher', plural: 'teachers' },
            min: 2,
            max: 5000
        }

        plan.title = i === 0 ? 'Sampler'
            : i === 1 ? 'Lite'
            : i === 2 ? 'Standard' : 'Complete';

        plan.price = {
            yearly: getPrice( i, true ),
            monthly: getPrice( i, false ),
        };
        plan.discount = i === 0 ? 0 : Math.floor(
            ( ( plan.price.monthly - plan.price.yearly ) / plan.price.monthly ) * 100
        );

        plan.studentState = i === 0 ? 'any kind'
            : i === 1 ? 'aware'
            : i === 2 ? 'motivated' : 'self-sufficient';

        plan.description = i === 0 ? 'sampler'
            : i === 1 ? 'educate'
            : i === 2 ? 'engage' : 'collaborate';
        plan.audience = i > 0 ? `For more ${ plan.studentState } students`
            : `For ${ plan.studentState } of student`;

        plan.i = i;
        plan.buttonLabel = 'Select';
        plan.linkLabel = i === 3 ? 'contact us' : 'get started';
        plan.linkTarget = i === 0 ? '/signup' : `/plan/${ plan.title.toLocaleLowerCase() }`;

        plan.features = getFeatures();
        plan.features.forEach( ( feature, j ) => {
            if ( i === 0 && j < 2 ) {
                feature.alternative = j === 0 ? 1 : 100;
                feature.longAlternative = [
                    feature.alternative,
                    j > 0 ? 'students' : 'course'
                ].join(' ');
            }

            feature.applies = j < 3 ? i > 0
                : j < 8 ? i > 1 : i > 2;
        } );
    } );

    return plans
}

const getNewPrice = ( price=9.99, teachers=2, slider={} ) => {
    if ( teachers < slider.changeStart ) return price
    teachers -= slider.changeStart;

    const exponent = `${ slider.decrement }`.split( '.' )[ 1 ].length;
    let decrementMultiplierToInteger = 10 ** exponent;
    const decrementMultiplierToHundrethsPlace = 10 ** ( exponent <= 2 ? 0 : exponent - 2 );

    const decrement = slider.decrement * decrementMultiplierToInteger * teachers;
    price = ( price * decrementMultiplierToInteger ) - decrement;

    if ( decrementMultiplierToHundrethsPlace > 0 ) {
        price = Math.ceil( price / decrementMultiplierToHundrethsPlace );
        decrementMultiplierToInteger /= decrementMultiplierToHundrethsPlace;
    }

    return price / decrementMultiplierToInteger
}

const getPlanIcons = ( className='' ) => [{
    icon: <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 384 512">
        <path className={ className } d="M192 512C86 512 0 426 0 320C0 228.8 130.2 57.7 166.6 11.7c6-7.5 14.9-11.7 24.5-11.7h1.8c9.6 0 18.5 4.2 24.5 11.7C253.8 57.7 384 228.8 384 320c0 106-86 192-192 192zM96 336c0-8.8-7.2-16-16-16s-16 7.2-16 16c0 61.9 50.1 112 112 112c8.8 0 16-7.2 16-16s-7.2-16-16-16c-44.2 0-80-35.8-80-80z"></path>
    </svg>,
}, {
    icon: <svg xmlns="http://www.w3.org/2000/svg" enableBackground="new 0 0 66 66" viewBox="0 0 66 66">
        <path d="M3.932 26.988c1.457.837 3.295.31 4.1-1.11l6.72-11.78c.836-1.463.317-3.278-1.12-4.12-1.45-.81-3.3-.3-4.12 1.13l-6.7 11.77C1.954 24.378 2.523 26.183 3.932 26.988zM21.742 18.078l-6.71 11.77c-.857 1.5-.274 3.322 1.12 4.11 1.451.834 3.305.318 4.12-1.12l6.71-11.76c.857-1.5.274-3.322-1.12-4.11C24.471 16.151 22.593 16.612 21.742 18.078zM7.942 34.158c1.341.805 3.256.412 4.11-1.12l10.91-19.13c.83-1.45.33-3.3-1.11-4.13-1.45-.81-3.3-.3-4.12 1.13C8.162 28.22 4.106 32.021 7.942 34.158zM61.412 40.958l-33.69-17.14-4.71 8.26 31.91 20.28c2.88 1.83 7.02 1 8.99-2.43C65.792 46.628 64.602 42.568 61.412 40.958z"/>
        <path className={ className } d="M22.012,33.828c-1.373,2.406-4.461,3.249-6.86,1.86c-0.6-0.34-1.12-0.82-1.54-1.39c-1.45,2.256-4.453,2.93-6.7,1.57   c-1.17-0.65-1.95-1.57-2.26-2.7c-0.3-1.11-0.15-2.28,0.44-3.8c-0.76-0.05-1.5-0.27-2.16-0.65c-0.71-0.41-1.3-0.97-1.74-1.65v17.83   c0,2.11,1.71,3.83,3.82,3.83s3.83-1.72,3.83-3.83v-1.35c0-0.87,0.71-1.59,1.59-1.59s1.59,0.72,1.59,1.59v9.18   c0,2.14,1.74,3.88,3.87,3.88c2.14,0,3.88-1.74,3.88-3.88v-6.02c0-0.81,0.66-1.47,1.47-1.47s1.47,0.66,1.47,1.47v0.94   c0,2.1,1.71,3.8,3.8,3.8c2.1,0,3.81-1.7,3.81-3.8v-8.55l-8.3-5.28L22.012,33.828z"/>
    </svg>,
}, {
    icon: <svg xmlns="http://www.w3.org/2000/svg" enableBackground="new 0 0 66 66" viewBox="0 0 66 66">
        <path d="M33.3 33.969l-2.87-1.1c-1.723-.663-3.564.24-4.2 1.83l-.05.14c-.15.39-.21.8-.18 1.21-1.679-.522-3.418.425-4 1.96-.636 1.681.18 3.438 1.68 4.12-1.503 1.503-1.157 4.381 1.19 5.32l7 2.66c2.254.853 4.568-.973 4.41-3.18 1.703.494 3.379-.382 4.01-1.96.639-1.735-.25-3.48-1.69-4.13 1.532-1.532 1.119-4.396-1.19-5.31l-2.24-.85.02-.05c.649-1.707-2.352 6.182 6.35-16.69H39.4C31.04 39.906 33.924 32.33 33.3 33.969zM46.88 1.069c-.52-.2-1.09.06-1.29.58l-1.16 3.05h2.14l.89-2.34C47.66 1.839 47.4 1.259 46.88 1.069z"/>
        <path className={ className } stroke='black' d="M48.48,15.569c1.66-0.7,2.82-2.34,2.82-4.25c0-2.545-2.068-4.62-4.62-4.62H19.32c-2.55,0-4.62,2.07-4.62,4.62   c0,1.91,1.17,3.55,2.83,4.26c-2.994,4.55-5.32,10.093-5.32,15.54v24.9c0,4.95,4.03,8.98,8.99,8.98h23.61   c4.95,0,8.98-4.03,8.98-8.98v-24.81C53.79,25.767,51.429,20.126,48.48,15.569z M41.82,22.819l-4.07,10.69l0.37,0.15   c2.69,1.02,4.05,4.01,3.08,6.71c1.37,1.577,1.617,3.576,0.96,5.31c-0.5,1.31-1.48,2.35-2.77,2.93c-0.48,0.21-0.98,0.35-1.49,0.42   c-1.094,2.704-4.123,3.953-6.74,2.95l-7-2.66c-2.645-1.01-4.067-3.975-3.07-6.71c-1.398-1.652-1.609-3.609-0.96-5.31   c0.69-1.82,2.35-3.1,4.25-3.35c1.051-2.587,4.01-4.001,6.75-2.95l1.01,0.38l2.66-6.99c-2.27,0.72-3.94,1.44-4.43,1.66   c-2.25,1.01-5.93-0.27-8.89-1.3c-0.45-0.15-0.89-0.31-1.31-0.44c-1.96-0.66-3.79,0.22-5.17,1.31c0.875-3.129,3.362-7.874,4.71-9.68   h26.58c1.379,1.82,3.24,5.78,4.04,7.78C47.8,22.599,44.76,22.479,41.82,22.819z"/>
        <path d="
            M48.48,15.569
            c1.66-0.7,2.82-2.34,2.82-4.25
            c0-2.545-2.068-4.62-4.62-4.62
            H19.32
            c -2.55,0 -4.62,2.07 -4.62,4.62
            c 0,1.91,1.17,3.55,2.83,4.26 z
        "/>
    </svg>,
}, {
    icon: <svg xmlns="http://www.w3.org/2000/svg" enableBackground="new 0 0 66 66" viewBox="0 0 66 66">
        <path d="M39.065 23.792l6.107-10.577-5.52-9.57c-.357-.63-1.028-1.018-1.752-1.018h-9.885c-.714 0-1.385.388-1.742 1.018l-5.488 9.497 6.149 10.651H39.065zM25.035 24.841l-6.107-10.577H7.952c-.714 0-1.385.378-1.742 1.007l-4.942 8.563c-.357.619-.357 1.396 0 2.015l5.52 9.57h12.141L25.035 24.841zM20.786 36.531l5.488 9.507c.357.619 1.028 1.007 1.742 1.007H37.9c.724 0 1.396-.388 1.752-1.007l5.52-9.57-6.107-10.577H26.935L20.786 36.531zM64.732 23.834l-4.942-8.563c-.357-.63-1.028-1.007-1.742-1.007h-11.05l-6.107 10.577 6.107 10.577h11.05c.714 0 1.385-.388 1.742-1.007l4.942-8.563C65.089 25.23 65.089 24.453 64.732 23.834z"/>
        <path className={ className } d="M41.468,47.088c-0.724,1.27-2.099,2.057-3.568,2.057h-9.885c-1.469,0-2.823-0.787-3.557-2.057l-5.53-9.57H7.868v10.347   c0,2.141,1.742,3.883,3.893,3.883c2.141,0,3.883-1.742,3.883-3.883v-3.757c0-0.787,0.64-1.417,1.417-1.417   c0.787,0,1.427,0.63,1.427,1.417V55.01c0,2.13,1.721,3.862,3.851,3.862c2.13,0,3.851-1.731,3.851-3.862v-0.997   c0-1.028,0.839-1.857,1.857-1.857c1.028,0,1.868,0.829,1.868,1.857v5.541c0,2.109,1.71,3.82,3.82,3.82s3.82-1.71,3.82-3.82v-4.796   c0-0.986,0.798-1.794,1.794-1.794c0.986,0,1.784,0.808,1.784,1.794v1.354c0,2.067,1.689,3.746,3.757,3.746   c2.067,0,3.757-1.679,3.757-3.746V37.517h-1.658L41.468,47.088z"/>
    </svg>,
}]

const getPrice = ( i=0, isYearly=true ) => i === 1 ?
    isYearly ? 9.99 : 11.99 : i === 2 ? //15%
    isYearly ? 11.99 : 14.99 : i === 3 ? //20%
    isYearly ? 14.99 : 19.99 : 0.00 //25%

// OBJECTS
// features
const features = {
    1: {
        title: 'They find what they need',
        description: 'Your assignments are organised into a searchable flow of choiceboards that guides students forward.',
        icon: {
            active: 'fa-solid fa-table-cells',
            default: 'fa-solid fa-square',
        },
        img: {
            src: './board-view1.png',
            alt: 'choice board on laptop screen',
        },
    },
    2: {
        title: 'They choose to work',
        description: 'The boards are gamified to offer various ways for students to feel the catharsis of progress',
        icon: {
            active: 'fa-solid fa-table-cells',
            default: 'fa-solid fa-square',
        },
        img: {
            src: './board-view.svg',
            alt: 'choice board on laptop screen',
        },
    },
    3: {
        title: 'one-stop shop',
        description: 'All the info you need comes to your board. Watch students work as they work.',
        icon: {
            active: 'fa-solid fa-table-cells',
            default: 'fa-solid fa-square',
        },
        img: {
            src: './board-view.svg',
            alt: 'choice board on laptop screen',
        },
    },
    4: {
        title: 'act once',
        description: 'Repeating yourself is passé. Create an assignment once and all class periods will see it. No extra steps.',
        icon: {
            active: 'fa-solid fa-table-cells',
            default: 'fa-solid fa-square',
        },
        img: {
            src: './board-view.svg',
            alt: 'choice board on laptop screen',
        },
    },
    5: {
        title: 'use us anywhere',
        description: 'We play nice with LMSes that play nice with Google Docs. We will walk you through the extra steps!',
        icon: {
            active: 'fa-solid fa-table-cells',
            default: 'fa-solid fa-square',
        },
        img: {
            src: './board-view.svg',
            alt: 'choice board on laptop screen',
        },
    },
    // 5: {
    //     title: 'Empower students',
    //     description: 'It\'s their grade. Give students ownership of their choices and progress so you don\'t have to beg.',
    //     icon: 'fa-solid fa-key',
    //     img: {
    //         src: './dash-view.png',
    //         alt: 'choice board on laptop screen',
    //     },
    // },
    // 6: {
    //     title: 'Accomodate more',
    //     description: 'Too much information slows students down. We show less so that they can do better.',
    //     icon: 'fa-solid fa-hands-holding-child',
    //     img: {
    //         src: './board-view.svg',
    //         alt: 'choice board on laptop screen',
    //     },
    // },
    // 7: {
    //     title: 'save time',
    //     description: 'Spend less time clicking around. Field fewer emails from students and parents about what needs doing.',
    //     icon: 'fa-solid fa-clock',
    //     img: {
    //         src: './board-view.svg',
    //         alt: 'choice board on laptop screen',
    //     },
    // },
    // 8: {
    //     title: 'update live',
    //     description: 'Tweak your plans (sometimes at the last minute) in fewer clicks',
    //     icon: 'fa-solid fa-wand-sparkles',
    //     img: {
    //         src: './board-view.svg',
    //         alt: 'choice board on laptop screen',
    //     },
    // },
};

// steps
const steps = {
    1: {
        upper: 'set',
        lower: 'teacher settings',
        icon: 'fa-solid fa-gear',
    },
    2: {
        upper: 'assign',
        lower: 'student boards',
        icon: 'fa-brands fa-buromobelexperte',
    },
    3: {
        upper: 'add',
        lower: 'assignments',
        icon: 'fa-solid fa-table-columns',
    }
};

// plans
const billing = {
    frequency: {
        monthly: 'monthly',
        yearly: 'yearly'
    },
    slider: {
        changeStart: 250,
        decrement: .002,
    },
};

const getBillingFrequencies = ( discount=20 ) => [
    billing.frequency.monthly,
    [ billing.frequency.yearly, `( save ${ discount }% )` ].join(' ')
]

const productInfo = {
    billing,
    features,
    getBillingFrequencies,
    getFeatures,
    getFullPlans,
    getNewPrice,
    getSolutions,
    steps,
};

export default productInfo;