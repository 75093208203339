import { useContext } from 'react';
import HiveContext from '../../../../contexts/HiveContext';
import BoardIcon from '../../../modular/Images/BoardIcon';
import styles from './HiveSideNav.module.css';
// import get from '../../../../constants/get';

const HiveSideNav = ({ hsl = {}, isForDisplay = false }) => {
    const {
        activeBoard,
        activeQuarter,
        changeBoardOrQuarter,
        course,
        currentWeekNum,
        getQuarter,
        placeholderBoards,
        schoolYear
    } = useContext( HiveContext );

    const { h, s, l } = hsl;
    const boardIconCompletion = Array( 9 ).fill( true );

    // FUNCTIONS
    function changeQuarter({ target: { id } }) {
        const quarterID = id.split( '-' ).pop() * 1;
        const quarter = getQuarter({ id: quarterID })
        const board = getQuarterBoards( quarter ).find( boardObj => boardObj.weekNum === quarter.weeks[ 0 ] );

        changeBoardOrQuarter(
            { board: true, quarter: true },
            { boardObj: board, quarterObj: quarter }
        )
    }

    function changeWeek({ target: { id } }) {
        let boardNum = id.split( '-' ).pop() * 1;
        const newBoard = course.content.boards[ boardNum ] || placeholderBoards[ boardNum ];
        const quarter = getQuarter({ weekNum: newBoard.weekNum })
        const isNewQuarter = quarter.id !== activeQuarter.id;
        // console.log('1️⃣', { boardNum, isNewQuarter, quarter, newBoard, boards: course.content.boards, placeholderBoards });

        changeBoardOrQuarter(
            { board: true, quarter: isNewQuarter },
            { boardObj: newBoard, quarterObj: isNewQuarter && quarter }
        )
    }

    function getQuarterBoards( quarter={} ) {
        const boards = course?.content?.boards[ 0 ] ? course?.content?.boards : placeholderBoards;

        const quarterBoards = quarter?.weeks?.map( weekNum => {
            const board = boards.find( board => board?.weekNum === weekNum );
            // get.log({
            //     fileName: 'HiveSideNav.js',
            //     functionDirectionsOrComponentName: 'ℹ️ { board }',
            //     lineNumber: 53,
            //     str: board
            // });

            return board
        } ).filter( e => e );

        // get.log({
        //     fileName: 'HiveSideNav.js',
        //     functionDirectionsOrComponentName: 'getQuarterBoards',
        //     isFunction: true,
        //     str: { quarter, quarterBoards, boards: placeholderBoards || course?.content?.boards },
        //     lineNumber: 47,
        // });

        return quarterBoards
    }

    // PROPS & ATTRIBUTES
    const sidenavAttributes = {
        className: styles.container,
        style: { '--h': h, '--s': s, '--l': l },
    };

    // COMPONENTS
    const QuarterSubMenu = <ul className={ styles[ 'sub-menu' ] }>{
        ( schoolYear?.quarters ).map( quarter => quarter.id !== activeQuarter.id && <li
            id={`quarter-option-${ quarter.id }`}
            key={`quarter-option-${ quarter.id }`}
            onClick={ changeQuarter }
        >Q{ quarter.id }</li> )
    }</ul>;

    const QuarterBoardList = getQuarterBoards( activeQuarter ).map( board => {
        const isActiveBoard = activeBoard.num === board.num;
        const newS = ( isForDisplay || board.weekNum <= currentWeekNum ) ? s : '0%';

        return <li
            className={`${ styles.week } ${ isActiveBoard ? styles.active : '' }`}
            id={`week-${ board.num }`}
            key={`week-${ board.num }`}
            onClick={ changeWeek }
        >
            <BoardIcon
                borderColour={`hsl( ${ h }, ${ newS }, ${ l.split( '%' )[ 0 ] * 1 - 20 }% )`}
                completion={ boardIconCompletion }
                hsl={{ h, s: newS, l: isActiveBoard ? l : `${ l.split( '%' )[ 0 ] * 1 + 25 }%` }}
                squareClassName={ styles.square }
                size='.5em'
            />
            <span
                id={`label-week-${ board.num }`}
                className={ styles[ 'board-num' ] }
            >{ board.num + 1 }</span>
        </li>
    } );

    // RENDER
    return <aside { ...sidenavAttributes }>
        <header className={`${ styles.quarter } ${ styles[ 'sub-menu-trigger' ] }`}>
            <span className={ styles[ 'active-quarter'] }>Q{ activeQuarter.id }</span>
            <nav className={ styles[ 'quarter-nav' ] }>{ QuarterSubMenu }</nav>
        </header>

        <nav className={ styles[ 'board-nav' ] }>
            <ul>{ QuarterBoardList }</ul>
        </nav>
    </aside>
}

export default HiveSideNav;