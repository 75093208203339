import { useContext } from 'react';
import Contexts from '../../../contexts/CalendarContext';
import CourseContext from '../../../contexts/CourseContext';
import get from '../../../constants/get';
import HiveContext from '../../../contexts/HiveContext';
import UserContext from '../../../contexts/UserContext';
import { Link } from 'react-router-dom';
import styles from './Month.module.css';

const Day = ({
    board = {},
    currentMonth = 0,
    day = 1,
    dayMonth = 0,
    id = '',
    isLastDayOfWeek = false,
    isMergedBoard = false,
    unit = {},
}) => {
    const { toggleSidebar, activeDayID } = useContext( CourseContext );
    const { course, school, schoolYear, isQueenBee } = useContext( HiveContext );
    const { changeMonth, getTodayDate } = useContext( Contexts.Calendar );
    const { month } = useContext( Contexts.Month );
    const { data: { uid: userID }, email } = useContext( UserContext );

    let { odd : oddDays } = month.days;
    let [ countyOffDays, schoolOffDays ] = [ schoolYear.holidays[ month.i ], school.holidays[ month.i ] ];
    [ countyOffDays, schoolOffDays, oddDays ] = [
        countyOffDays ? countyOffDays : [],
        schoolOffDays ? schoolOffDays : [],
        oddDays ? oddDays : [],
    ];

    let wasMergedWithByFollowingBoard = false;
    if ( isMergedBoard ) wasMergedWithByFollowingBoard = course.content.boards[ board.num + 1 ].isMergedWith === board.num;

    const [ dayMonthClass, dayTypeClass ] = [ getDayMonthClass(), getDayTypeClass() ];

    // FUNCTIONS
    function activateDay( e ) {
        !e.target.className.includes( 'off' ) && toggleSidebar( board.num, id );
        !e.target.className.includes( 'current' ) && changeMonth( e );
    }

    function getChildComponentClass( ownClass='' ) {
        return get.classNameFromArr([
            ownClass,
            dayMonthClass.includes( 'current' ) ? 'current'
                : dayMonthClass.includes( 'coming' ) ? 'coming'
                : 'previous',
        ])
    }

    function getDayDetails() {
        const boardURLArr = [ course.id.db, 'boards', board.num + 1 ];
        boardURLArr.unshift( '', isQueenBee ? get.username( email ) : 'workerbee' );

        const boardIsAvailable = course.content.boards[ board.num ];
        if ( isLastDayOfWeek && !wasMergedWithByFollowingBoard && boardIsAvailable ) return <Link
            className={ styles.pill } to={ boardURLArr.join( '/' ) }
        ><i className='fa-brands fa-buromobelexperte'/> Board Due</Link>
    }

    function getDayMonthClass() {
        return dayMonth === currentMonth ? 'current-month'
            : dayMonth > currentMonth ? 'coming-month' : 'previous-month'
    }

    function getDayTypeClass() {
        const offClass = styles.off;

        return isCountyHoliday() ? get.classNameFromArr([ offClass, styles.county ])
            : isSchoolHoliday() ? get.classNameFromArr([ offClass, styles.school ])
            : oddDays.includes( day ) ? styles.odd : styles.even
    }

    function isCountyHoliday() { return !!countyOffDays?.days?.includes( day ) }
    function isSchoolHoliday() { return !!schoolOffDays?.days?.includes( day ) }
    function isHoliday() {
        if ( !isSchoolHoliday() && !isCountyHoliday() ) return [ false ]

        const obj = isCountyHoliday() ? countyOffDays : schoolOffDays;
        const i = obj.days.findIndex( offDay => offDay === day );
        return [ true, obj.reasons[ i ] ]
    }

    function isToday() {
        const [ monthToday, dayToday ] = [ getTodayDate().getMonth(), getTodayDate().getDate() ];

        return monthToday === dayMonth && dayToday === day
    }

    // PROPS & ATTRIBUTES
    const attributes = {
        day: {
            id,
            className: get.classNameFromArr([
                isQueenBee ? styles.queen : styles.worker,
                styles.day,
                styles[ getDayMonthClass() ],
                activeDayID.includes( id ) ? styles.active : '',
                dayMonthClass.includes( 'current' ) ? dayTypeClass : '',
                isToday() ? styles.today : ''
            ]),
            onClick: isQueenBee ? activateDay : () => {},
        },
        details: {
            className: get.classNameFromArr([
                getChildComponentClass( styles.details ),
                dayTypeClass.includes( 'off' ) ? 'off' : ''
            ]),
            id: `div-${ id }`,
        },
        unit: {
            className: styles.unit,
            style: { '--background': unit.background.hex },
        }
    };

    // COMPONENTS
    const [ AddAssignmentSpan, DayDetails, DayNumberSpan, HolidayReasonSpan, PlanningSpinnerSpan, Unit ] = [
        <span
            className={ getChildComponentClass( styles.add ) }
            id={`span4-${ id }`}
        ><i className='fa-solid fa-circle-plus' /> due this day</span>,
        <span
            className={ getChildComponentClass( styles.detail ) }
            id={`span2-${ id }`}
        >{ getDayDetails() }</span>,
        <span
            className={ getChildComponentClass( styles.date ) }
            id={`span1-${ id }`}
        >{ day }</span>,
        <span
            className={ getChildComponentClass( styles.reason ) }
            id={`span5-${ id }`}
        >{ [ get.holidayEmoji( isHoliday()[ 1 ] ), isHoliday()[ 1 ] ].join(' ') }</span>,
        <span
            className={ getChildComponentClass( styles.planning ) }
            id={`span3-${ id }`}
        ><i className='fa-solid fa-spinner fa-pulse' />planning...</span>,
        <div { ...attributes.unit }> <div><span>{ unit.theme }</span></div> </div>
    ];

    // RENDER
    const showUnit = !attributes.day.className.includes( 'off' )
        && attributes.day.className.includes( 'current' ) && Object.keys( unit )[ 0 ];

    return <div { ...attributes.day }>
        <div { ...attributes.details }>
            { DayNumberSpan }<br />
            { activeDayID.includes( id ) ? PlanningSpinnerSpan : <>
                { isHoliday()[ 0 ] ? HolidayReasonSpan : userID === course.ownerID ? AddAssignmentSpan : '' }
                { DayDetails }
            </> }
        </div>
        { showUnit && Unit }
    </div>
}

export default Day;