import get from '../../../constants/get';
import styles from './Stars.module.css';

const stars = {
    full: 'fa-solid fa-star',
    empty: 'fa-regular fa-star',
    half: 'fa-solid fa-star-half-stroke'
};

const Stars = ({ rating=4.5, className='' }) => {
    // FUNCTIONS
    const getStar = ( num = 1 ) => {
        rating -= num;
        const type = rating >= 1 ? 'full' : rating > 0 ? 'half' : 'empty';

        return get.classNameFromArr([ styles.star, stars[ type ] ])
    }

    // RENDER
    return <div className={ get.classNameFromArr([ styles.rating, className ]) }>
        <ul>{ [ 0, 1, 1, 1, 1 ].map( (num, i) => <li key={`star-${i}`}>
            <i key={`star-icon-${i}`} className={ getStar(num) }/>
        </li> )}</ul>
    </div>
}

export default Stars;