import moduleInfo from '../../constants/moduleInfo';
import HiveNav from '../modular/Nav/HiveNav';
import ModalButton from '../modular/Buttons/ModalButton';
import Slide from '../modular/Layout/Slide';
import styles from './Sheets.module.css';

const Sheets = () => {
    const { setupSteps: { external : sheetSetup } } = moduleInfo;

    const modalChildren = Object.values( sheetSetup ).map(
        (step, i) => <Slide details={ step } i={ i }/>
    );

    return <article className={ styles.sheets }>
        <HiveNav menu={ moduleInfo.navMenus.sheets } />
        <ModalButton
            modalChildren={ modalChildren }
            label={ 'walk through set-up'.toLocaleUpperCase() }
            autoFocus
            isLink
        />
    </article>
}

export default Sheets;