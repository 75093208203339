import styles from './Card.module.css';
import Squares from '../Decor/Squares';
import get from '../../../constants/get';

const Card = ({ children=[], className='', withDecor=false }) => {
    children = <div className={ get.classNameFromArr([ styles.card, className ]) }>{ children }</div>

    // RENDER
    return !withDecor ? children : <Squares
        className={ styles.decor }
        children={ children }
    />
}

export default Card;