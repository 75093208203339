import get from '../../../../constants/get';
import styles from './Tag.module.css';

const Tag = ({ canDelete=true, colour='#fff', deleteTag=() => {}, text='', title='' }) => {
    // PROPS & ATTRIBUTES
    const attributes = {
        div: {
            className: get.classNameFromArr([
                styles[ 'tag-container' ],
                canDelete ? styles.removeable : styles.untouchable,
                title !== '' ? styles[ 'has-tooltip' ] : '',
            ]),
            style: { background: colour }
        },
        cover: {
            className: styles.cover,
            id: `cover-close-${ text }`,
            onClick: deleteTag
        },
    };

    // RENDER
    return <div { ...attributes.div }>
        <div { ...attributes.cover }/>
        <span className={ styles.tag }>
            { canDelete && <i className='fa-solid fa-xmark'/> }
            <span className={ styles.label }>{ text }</span>
        </span>
        { title !== '' && <div className={ styles.tooltip }>{ title }</div> }
    </div>
}

export default Tag;