import { useEffect, useState } from 'react';
import Button from '../../pages/Button';
import styles from './TextToSpeech.module.css';

const getVoices = ( isBrowserCompatible=true, language='' ) => !isBrowserCompatible ? []
    : window.speechSynthesis.getVoices().filter( voice => voice.lang.includes( language ) )

const TextToSpeech = ({
    hsl = {},
    justPlay = false,
    language = 'en',
    onClose = null,
    startPlaying = false,
    text = '',
}) => {
    // Stop here if the browser is not compatible
    // if ( 'speechSynthesis' in window === false ) return <i className='fa-solid fa-volume-xmark'/>

    const [ voice, setVoice ] = useState({
        isPaused: false,
        isSpeaking: false,
        hasFinishedSpeaking: false
    });

    const [ settings, setSettings ] = useState({
        language,
        pitch: 1,
        speed: 1,
        utterance: null,
        voice: null,
        volume: .5,
    });

    const isBrowserCompatible = 'speechSynthesis' in window;
    const voices = getVoices( isBrowserCompatible, settings.language );

    useEffect( () => {
        if ( isBrowserCompatible ) {
            const [ synth, utterance, voices ] = [
                window.speechSynthesis,
                new SpeechSynthesisUtterance( text ),
                getVoices( isBrowserCompatible, settings.language )
            ];

            if ( !startPlaying && !settings.utterance ) {
                setSettings( settings => ({ ...settings, utterance, voice: voices[ 0 ] }) )
            } else if ( startPlaying ) {
                utterance.voice = settings.voice;
                utterance.pitch = settings.pitch;
                utterance.rate = settings.speed;
                utterance.volume = settings.volume;
                utterance.language = settings.language;

                synth.speak( utterance );
            }

            return () => synth.cancel()
        }

    }, [ text, settings, startPlaying, isBrowserCompatible ] );

    // FUNCTIONS
    const handlePlay = () => {
        const synth = window.speechSynthesis;

        if ( voice.isPaused ) { synth.resume() } else {
            settings.utterance.voice = settings.voice;
            settings.utterance.pitch = settings.pitch;
            settings.utterance.rate = settings.speed;
            settings.utterance.volume = settings.volume;
            settings.utterance.language = settings.language;
            synth.speak( settings.utterance );
        }

        setVoice( () => ({ isPaused: false, isSpeaking: true, hasFinishedSpeaking: false }) );
    }

    const handlePause = () => {
        window.speechSynthesis.pause();

        setVoice( voice => ({ isPaused: true, isSpeaking: false, hasFinishedSpeaking: false }) );
    }

    // const handleStop = () => {
    //     const synth = window.speechSynthesis;
    //     synth.cancel();

    //     setVoice( () => ({ isPaused: false, isSpeaking: false, hasFinishedSpeaking: true }) );
    // }

    const changeSetting = ({ target: { id, value } }) => setSettings( settings => ({
        ...settings,
        [ id ]: id === 'voice' ? voices.find( voice => voice.name === value ) : parseFloat( value )
    }) )

    const closeControls = () => {
        if ( onClose !== null ) onClose()
    }

    const stopPlayback = () => {
        window.speechSynthesis.cancel();
        setVoice( () => ({ isPaused: false, isSpeaking: false, hasFinishedSpeaking: true }) );
    }

    const togglePlayback = () => voice.isSpeaking ? handlePause() : handlePlay()

    // PROPS & ATTRIBUTES
    const attributes = {
        container: {
            className: styles.container,
            style: {
                '--hue': hsl.h,
                '--saturation': hsl.s,
                '--lightness': hsl.l,
            },
        },
        PitchRange: { id: 'pitch', value: settings.pitch },
        SpeedRange: { id: 'speed', value: settings.speed },
        VoiceSelect: { id: 'voice', value: settings.voice?.name },
        VolumeRange: { id: 'volume', value: settings.volume, min: 0, max: 1 }
    };

    attributes.PitchRange.onChange = attributes.SpeedRange.onChange = attributes.VoiceSelect.onChange = attributes.VolumeRange.onChange = changeSetting;
    attributes.PitchRange.type = attributes.SpeedRange.type = attributes.VolumeRange.type = 'range';
    attributes.PitchRange.step = attributes.SpeedRange.step = attributes.VolumeRange.step = .1;
    attributes.PitchRange.min = attributes.SpeedRange.min = .5;
    attributes.PitchRange.max = attributes.SpeedRange.max = 2;

    // COMPONENTS
    const [ PlayPauseButton, StopButton, CloseButton, VoiceOptions ] = [
        <Button
            label={ <i className={`fa-solid fa-${ voice.isSpeaking && !justPlay ? 'pause' : 'play' }`}/> }
            onClick={ togglePlayback }
        />,
        <Button
            label={ <i className='fa-solid fa-stop'/> }
            onClick={ stopPlayback }
        />,
        <Button
            label={ <i className='fa-solid fa-xmark'/> }
            onClick={ closeControls }
        />,
        voices.map( voice => <option
            key={ voice.name }
            value={ voice.name }
        >{ voice.name }</option> )
    ];

    const [ PitchRange, SpeedRange, VoiceSelect, VolumeRange ] = [
        <label className={ [ styles.pitch, styles.range ].join(' ') }>
            <i className='fa-solid fa-wave-square'/>
            <input { ...attributes.PitchRange }/>
        </label>,
        <label className={ [ styles.speed, styles.range ].join(' ') }>
            <i className='fa-solid fa-gauge-simple-high'/>
            <input { ...attributes.SpeedRange }/>
        </label>,
        <label className={ styles.voices }>
            <i className='fa-solid fa-comment'/>
            <select { ...attributes.VoiceSelect }>{ VoiceOptions }</select>
        </label>,
        <label className={ [ styles.volume, styles.range ].join(' ') }>
            <i className='fa-solid fa-volume-high'/>
            <input { ...attributes.VolumeRange }/>
        </label>,
    ];

    // RENDER
    return isBrowserCompatible ? <fieldset { ...attributes.container }>
        { CloseButton }
        { !startPlaying && <fieldset className={ styles.buttons }>
            { PlayPauseButton }
            { !justPlay && StopButton }
        </fieldset>}
        <fieldset className={ styles.inputs }>
            { VoiceSelect }
            { VolumeRange }
            { SpeedRange }
            { PitchRange }
        </fieldset>
    </fieldset> : <div className={ styles.error }>Compatibility Issue. Try using Google Chrome.</div>
}

export default TextToSpeech;