import React from 'react';
import { Outlet } from 'react-router-dom';
import Error from './Error';

class ErrorBoundary extends React.Component {
    constructor( props ) {
        super( props );
        this.state = { hasError: false };
    }

    // Update state so the next render will show the fallback UI.
    static getDerivedStateFromError( error ) { return { hasError: true } }

    // You can also log the error to an error reporting service
    componentDidCatch( error, errorInfo ) {
        console.error( '❌ ERROR', error, errorInfo );
    }

    // render any custom fallback UI
    render() {
        return this.state.hasError ? <Error /> : <Outlet />;
    }
}

export default ErrorBoundary;