import { useEffect, useState } from 'react';
import Pill from '../modular/Buttons/Pill';
import SVG from '../modular/Images/SVG';
import Button from './Button';
import styles from './FilterAndSort.module.css';
import TextInputButton from './TextInputButton';

const FilterSidebar = ({ filters={ categories: [], subCategories: [] }, passSelectionUp=( selection={} ) => {} }) => {
    const [ selections, setSelections ] = useState( {} );
    const [ query, setQuery ] = useState( '' );
    const [ options, setOptions ] = useState( null );
    const obj = query ? options : filters;

    // FUNCTIONS
    function getCategoryAccordeon( i=-1 ) {
        const [ category, subcategories ] = [
            obj.categories[ i ],
            filters.subCategories[ 0 ][ 0 ] ? obj.subCategories[ i ] : []
        ];

        return <div
            className={ styles.accordeon }
            key={ [ 'accordeon', i, category ].join( '-' ) }
            id={ [ 'accordeon', i, category ].join( '-' ) }
        >
            <header>
                <p>{ category }</p>
                <Button
                    id={ `accordeon-for-${ category }` }
                    label={ <SVG type={{ 'down-caret': true }}/> }
                    onClick={ selectSubject }
                />
            </header>

            <div className={ styles.subcategories }>{
                subcategories.map( subcategory => <Pill
                    key={ [ category, 'subcategory', subcategory ].join( '-' ) }
                    label={ subcategory }
                    activeState={ true }
                    className={ styles.pill }
                    onClick={ () => passSelectionUp({ [ category ]: subcategory }) }
                    // gradientDuo={}
                    // style={{  }}
                /> )
            }</div>
        </div>
    }

    function handleTyping({ target: { value } }) {
        setQuery( () => value );
        setOptions( () => {
            if ( !value ) return {
                categories: filters.categories,
                subCategories: filters.subCategories
            }

            let [ categories, subCategories ] = [ [], [] ];
            filters.subCategories.forEach( ( arr, i ) => {
                const validSubcategories = arr.filter(
                    subcategory => subcategory.toLocaleLowerCase().includes( value.toLocaleLowerCase() )
                );

                if ( validSubcategories[ 0 ] ) {
                    categories.push( filters.categories[ i ] );
                    subCategories.push( validSubcategories );
                }
            } );

            return { categories, subCategories }
        } )
    }

    function selectSubject({ target: { id } }) {
        setSelections( selections => ({ ...selections, [ id.split( '-' )[ 1 ] ]: [] }) )
    }

    // RETURN
    return <aside className={ styles.sidebar }>
        <TextInputButton
            autocomplete
            className={ styles.search }
            controlledValue={ query }
            inputIcon='🔎'
            inputLabel='search'
            passValueUp={ handleTyping }
        />

        <section className={ styles.subjects }>{
            obj.categories.map( ( e, i ) => getCategoryAccordeon( i ) )
        }</section>
    </aside>
}

export default FilterSidebar;