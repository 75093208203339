const Wrapper = ({
    Children,
    props = { key: '' },
    a = false,
    article = false,
    div = false,
    fieldset = false,
    figure = false,
    form = false,
    header = false,
    h1 = false,
    h2 = false,
    h3 = false,
    h4 = false,
    h5 = false,
    h6 = false,
    img = false,
    legend = false,
    p = false,
    section = false,
    span = false,
    sup = false,
}) => {
    // console.log( `<${ a ? 'a' : article ? 'article' : div ? 'div' : fieldset ? 'fieldset' : figure ? 'figure' : form ? 'form' : header ? 'header' : h1 ? 'h1' : h2 ? 'h2' : h3 ? 'h3' : h4 ? 'h4' : h5 ? 'h5' : h6 ? 'h6' : img ? 'img' : legend ? 'legend' : p ? 'p' : section ? 'section' : span ? 'span' : 'sup' } key=${ props.key }/>\n`, props );
    return a ? <a { ...props }>{ Children }</a>
        : article ? <article { ...props }>{ Children }</article>
        : div ? <div { ...props }>{ Children }</div>
        : fieldset ? <fieldset { ...props }>{ Children }</fieldset>
        : figure ? <figure { ...props }>{ Children }</figure>
        : form ? <form { ...props }>{ Children }</form>
        : header ? <header { ...props }>{ Children }</header>
        : h1 ? <h1 { ...props }>{ Children }</h1>
        : h2 ? <h2 { ...props }>{ Children }</h2>
        : h3 ? <h3 { ...props }>{ Children }</h3>
        : h4 ? <h4 { ...props }>{ Children }</h4>
        : h5 ? <h5 { ...props }>{ Children }</h5>
        : h6 ? <h6 { ...props }>{ Children }</h6>
        : img ? <img { ...props } alt={ props.alt || '' }>{ Children }</img>
        : legend ? <legend { ...props }>{ Children }</legend>
        : p ? <p { ...props }>{ Children }</p>
        : section ? <section { ...props }>{ Children }</section>
        : span ? <span { ...props }>{ Children }</span>
        : sup ? <sup { ...props }>{ Children }</sup>
        : <>{ Children }</>
}

export default Wrapper;