import { useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import {
    KeyboardSensor,
    PointerSensor,
    closestCenter,
    DragOverlay,
    DndContext,
    useSensors,
    useSensor,
} from '@dnd-kit/core';
import {
    arrayMove,
    SortableContext,
    sortableKeyboardCoordinates,
    verticalListSortingStrategy,
} from '@dnd-kit/sortable';
import Sortable from '../../modular/DragAndDrop/Sortable';
import Bar from '../../modular/Bar/Bar';
import AddBar from '../../modular/Form/AddBar';
import UserContext from '../../../contexts/UserContext';
import styles from './CourseSection.module.css';
import { createCourse } from '../../../services/course/courseServices';


const CourseSection = ( viewByCourse=true, className='' ) => {
    const navigate = useNavigate();
    const user = useContext( UserContext );

    const [ activeId, setActiveId ] = useState( null );
    const [ barIds, setBarIds ] = useState( Object.keys( user.courseload ) );

    useEffect( () => { if ( !user.data.uid ) return navigate( '/signup' ) }, [ user.data.uid, navigate ] );

    // FUNCTIONS
    // API
    async function addBar( e ) {
        e.preventDefault();
        const inputs = Array.from( e.target.elements ).filter( e => e.id );
        const [ course, sections ] = [
            inputs.find( input => input.id === 'new-course' ).value.toLocaleLowerCase(),
            inputs.filter( input => input.id.includes( 'period' ) && input.value ).map( e => e.value ),
        ];

        const splitCourseName = course.split(' ');
        const courseId = splitCourseName.join('-');
        const [ subjectStr, levelNum ] = [
            splitCourseName.slice( 0, splitCourseName.length - 1 ).join(' ').trim(),
            splitCourseName.pop() * 1
        ];

        var coursePayload = {
            "courseId": courseId,
            "courseName": course,
            "subjectName": subjectStr,
            "levelNum": levelNum,
            "curriculumIds": [],
            "periods": sections,
            "sections": {}
        }
        // console.log(`COURSE: ${ course }\nSECTIONS: ${ sections }`);
        // console.log(`(...adding before ${ e.target.parentNode.id })`);

        user.addCourse( subjectStr, levelNum, sections );
        // API : Add Course to Backend
        // make Post Call to Backend to Create Course
        try {
            const response = await createCourse( courseId, coursePayload )
            window.location.hostname === 'localhost' && console.log( response )
        } catch ( err ) {
        // handle error
            console.error( err );
        }

        setBarIds( barIds => [ ...barIds, subjectStr + '-' + levelNum ] );
    }

    function generateDraggableContainers( unitDetails=[{}], containers=[] ) {
        return unitDetails[ 0 ] ? generateDraggableContainers(
            unitDetails.slice( 1 ), [ ...containers, <Sortable
                children={[ unitDetails[ 0 ] ]}
                key={`sortable-${ containers.length }`}
                onLineClick={ addBar }
            /> ]
        ) : containers
    }

    function handleDragEnd({ active: { idActive }, over: { idOver } }) {
        if ( idActive !== idOver ) setBarIds( barIds => {
            const [ iOld, iNew ] = [ barIds.indexOf( idActive ), barIds.indexOf( idOver ) ];
            return arrayMove( barIds, iOld, iNew )
        } )

        setActiveId( null );
    }

    function handleDragStart({ active: { id } }) { setActiveId( () => id ) }

    const sensors = useSensors(
        useSensor( PointerSensor ),
        useSensor( KeyboardSensor, { coordinateGetter: sortableKeyboardCoordinates } ),
    );

    // CONTEXTS, PROPS, & ATTRIBUTES
    const props = {
        AddBar: {
            addBar,
            className: styles[ 'add-bar' ],
        },
        Bar: {
            details: activeId && user.courseload[ activeId.split( '-' ).join('') ],
            id: activeId,
            // isExpandable: true,
            isOverlay: true,
        },
        DndContext: {
            collisionDetection: closestCenter,
            onDragEnd: handleDragEnd,
            onDragStart: handleDragStart,
            sensors,
        },
        SortableContext: {
            items: barIds,
            strategy: verticalListSortingStrategy,
        },
    };

    // COMPONENTS
    const SortableBars = generateDraggableContainers( barIds.map(
        barId => user.courseload[ barId.split( '-' ).join('') ]
    ) );

    // RENDER
    return <article className={`${ styles.course } ${ className }`}>
        <h1>{ viewByCourse ? 'COURSES' : 'SECTIONS' }</h1>

        <DndContext { ...props.DndContext }>
            <SortableContext { ...props.SortableContext }>{ SortableBars }</SortableContext>
            <DragOverlay>{ activeId ? <Bar { ...props.Bar }/> : null }</DragOverlay>
        </DndContext>

        <AddBar { ...props.AddBar }/>
    </article>
}

export default CourseSection;