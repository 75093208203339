import { useState } from 'react';
import { Link } from 'react-router-dom';
import CallToAction from '../../modular/Buttons/CallToAction';
import FeatureText from '../../modular/Layout/FeatureText';
import Nav from '../../modular/Nav/Nav';
import styles from './Solutions.module.css';

const Solutions = ({ features={} }) => {
    const [ activeFeature, setActiveFeature ] = useState( features[1] );
    const featureNums = Object.keys(features);

    const changeActiveFeature = ({ target : { id }}) => {
        let num = id.split('-')[1];
        setActiveFeature( activeFeature => features[num] );
    }

    const isActive = ( num=1 ) => activeFeature.title === features[num].title

    const getBox = ( num = 1 ) => <li
        className={`${styles['box']} ${isActive(num) ? styles['active-box'] : ''}`}
        title={ features[num].title }
        id={`box-${num}`}
        key={`box-${num}`}
        onClick={changeActiveFeature}
    ><i className={ features[num].icon.default } id={`icon-${num}`}/></li>

    return (
        <article className={styles.solutions}>
            <div className={styles.carousel}>
                <div className={styles.content}>
                    <img
                        className={styles['screen-mockup']}
                        src={'/display1.svg'}
                        alt=''
                    />
                    <div className={styles['screenshot-container']}>
                        <img
                            className={styles.screenshot}
                            src={activeFeature.img.src}
                            alt={activeFeature.img.alt}
                        />
                    </div>
                    <FeatureText
                        classNames={ styles.text }
                        title={ activeFeature.title }
                        description={ activeFeature.description }
                    />
                </div>
                <Nav
                    classNames={styles['box-nav']}
                    menuItems={ featureNums.map( num => getBox(num) ) }
                />
            </div>
            <div className={styles.buttons}>
                <Link to={ '/signup' }>
                    <CallToAction label='START YOUR OWN BOARDS'/>
                </Link>
                <Link to={ '/export-sheet' }>
                    <CallToAction label='TAKE BOARDS TO GO'/>
                </Link>
            </div>
        </article>
    )
}

export default Solutions;