// import { useState } from 'react';
// import Bar from '../Bar/Bar';
// import AddLine from '../Bar/AddLine';
// import AddPeriod from '../Form/AddPeriod';
import { useSortable } from '@dnd-kit/sortable';
import styles from './Sortable.module.css';
import get from '../../../constants/get';

const Sortable = ({ children=[], id='', onLineClick=() => {} }) => {
    // const [ addSection, setAddSection ] = useState( false );

    // const toggleForm = () => setAddSection( addSection => !addSection )

    // id = children[0].title ? children[0].title.toLocaleLowerCase().split(' ').join('-') : id;

    // children = 'title' in children[ 0 ] ? <Bar
    //     id={ id }
    //     isExpandable
    //     details={ children[ 0 ] }
    //     toggleForm={ toggleForm }
    // /> : children;

    // SORTABLE VARIABLES
    const {
        attributes,
        listeners,
        setNodeRef,
        transform,
        transition,
        isSorting,
        isDragging,
        isOver,
    } = useSortable({ id });

    const style = transform ? {
        transform: `translate3d( ${ transform.x }px, ${ transform.y }px, 0 )`,
        transition,
    } : undefined;

    // RENDER
    return <div
        className={ get.classNameFromArr([
            styles.draggable,
            isSorting && styles.sorting,
            isDragging && styles.dragging,
            isOver && styles.over
        ]) }
        id={`draggable-${ id }`}
        style={ style }
        ref={ setNodeRef }
    >
        <section className={ styles.content }>
            <button
                { ...listeners }
                { ...attributes }
                className={ styles['drag-handle'] }
            ><i className='fa-solid fa-bars'/></button>

            { children }
        </section>
        <p>{ id }</p>

        {/* { addSection && <AddPeriod classPrefix={ id } /> } */}

        {/* { addSection || <AddLine
            classPrefix={ id }
            className={ styles['add-line'] }
            onClick={ onLineClick }
        /> } */}
    </div>
}

export default Sortable;