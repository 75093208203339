import { useContext } from 'react';
import Contact from "./Contact";
import Hero from "./Hero";
import HowItWorks from './HowItWorks';
import HiveNav from '../../modular/Nav/HiveNav';
import Reviews from './Reviews';
import moduleInfo from "../../../constants/moduleInfo";
import UserContext from '../../../contexts/UserContext';
import get from '../../../constants/get';

const Landing = () => {
    const user = useContext( UserContext );
    moduleInfo.navMenus.landing.labels[ 3 ].to = [ '', get.username( user.email ) || 'placeholder' ].join( '/' );
    document.title = 'Home Page | BumbleBoard';

    // RENDER
    return <article className='page landing-page' id='landing-page'>
        <HiveNav menu={ moduleInfo.navMenus.landing }/>
        <section className='hero-section'><Hero /></section>
        <section className='other-sections'>
            <HowItWorks />
            <Reviews />
            <Contact />
        </section>
    </article>
}

export default Landing;