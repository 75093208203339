import { useContext } from 'react'
import UserContext from '../../../contexts/UserContext';
import styles from './UserIcon.module.css';
import get from '../../../constants/get';

const UserIcon = () => {
    const user = useContext( UserContext );

    // PROPS & ATTRIBUTES
    const iconProps = {
        className: get.classNameFromArr([
            styles[ 'user-icon' ],
            user.plan.isFree ? '' : styles.premium
        ])
    }

    // RENDER
    return <div { ...iconProps }>
        { !user.plan.isFree && <img
            alt=''
            className={ styles.crown }
            src='https://em-content.zobj.net/thumbs/240/toss-face/342/crown_1f451.png'
        /> }
        <img src={ user.data.photoURL } alt='' className={ styles.icon }/>
    </div>
}

export default UserIcon;