import { useState } from 'react';
import Button from '../../Button';
import SVG from '../../../modular/Images/SVG';
import SideBar from './SideBar';
import get from '../../../../constants/get';
import styles from './Calendar.module.css';
// import ModalContainer from '../../ModalContainer';
// import DayForm from './DayForm';
// import ToggleRadio from '../../../modular/Form/Parts/ToggleRadio';
// import TextInputButton from '../../TextInputButton';
// import ToggleAndLabels from '../../../modular/Buttons/ToggleAndLabels';

const getPlaceholderMonths = ( start={}, end={}, holidays={}, locale='en-US' ) => {
    const monthsObj = get.calendarMonths( start, end, holidays, locale );
    return monthsObj
}


const Calendar = ({
    canShowSingleMonthView = false,
    canShowQuarterView = false,
    startWithActiveMonth = false,
    county={},
    updateSchoolYear=( key=[ 'schoolYear' ], value=[ {} ] ) => {}
}) => {
    const [ activeDayI, setActiveDayI ] = useState( null );
    const [ activeMonthI, setActiveMonthI ] = useState(
        startWithActiveMonth ? get.dayMonthYearToday()[ 1 ] : null
    );

    const [ todayDay, todayMonth, ] = get.dayMonthYearToday();
    const {
        schoolYear: { end, holidays, start },
        schoolYear,
        schools,
        locale,
    } = county;

    let school = Object.values( schools );
    school = school.length === 1 && school[ 0 ];

    if ( 'all' in schoolYear.months[ 0 ].days === false ) schoolYear.months = getPlaceholderMonths( start, end, holidays, locale )
    const { months } = schoolYear;
    // console.log( '🏫', schools, school );
    // console.log( '⏳', schoolYear );
    // console.log( '📆', months );

    // FUNCTIONS
    function generateMonth( month={} ) {
        const schoolYearDayMonthsInDB = [];
        Object.values( month.weeks ).forEach( week => week.days.map(
            ( day, i ) => schoolYearDayMonthsInDB.push([ day, week.months[ i ] ])
        ) );

        const year = get.schoolYearID().split( ' - ' )[ month.i < start.month ? 1 : 0 ] * 1;
        if ( month.year.full !== year ) month.year.full = year

        // const firstDay = new Date( year, month.i, 1 );
        // const firstDayWeekday = firstDay.getDay();
        // const firstMondayDate = firstDay.getDate() - ( firstDayWeekday === 1 ? 0
        //     : firstDayWeekday === 0 ? 6
        //     : firstDayWeekday - 1 );

        // const actualCalendarDayMonthsArr = generateCalendarMonth( month, firstMondayDate );

        return <li
            className={ get.classNameFromArr([
                styles.month,
                monthIsLessThan( month.i, todayMonth, false ) ? styles[ 'past-month' ]
                    : month.i === todayMonth ? styles[ 'current-month' ]
                    : styles[ 'future-month' ]
            ]) }
            key={ [ month.name.full ].join( '-' ) }
        >
            <header>
                { canShowSingleMonthView && activeDayI && <Button
                    label={ <SVG type={{ 'left caret': true }}/> }
                    id={ 'button-to-' + ( activeMonthI === 0 ? 11 : activeMonthI - 1 ) }
                    onClick={ toggleActiveMonth }
                /> }
                <h2 className={ styles[ 'long-name' ] }>{ month.name.full }</h2>
                <h2 className={ styles[ 'short-name' ] }>{ month.name.short }</h2>
                { canShowSingleMonthView && activeDayI && <Button
                    label={ <SVG type={{ 'right caret': true }}/> }
                    id={ 'button-to-' + month.nextI }
                    onClick={ toggleActiveMonth }
                /> }
            </header>

            <ul className={ styles.days }>{ schoolYearDayMonthsInDB.map( dayMonth => {
                const [ day, monthNum ] = dayMonth;
                const monthHolidays = holidays[ monthNum ];
                const holidayReasonI = monthHolidays?.days?.findIndex( dayNum => dayNum === day );

                const props = {
                    li: {
                        key: [ ...dayMonth, 'for', month.i ].join( '-' ),
                        className: get.classNameFromArr([
                            styles.day,
                            !month.days.all.includes( day ) ? styles.holiday
                                : month.days.odd.includes( day ) ? styles.odd
                                : styles.even,
                            [ 'workday', 'development', 'school planning' ].some(
                                e => monthHolidays?.reasons?.[ holidayReasonI ]?.includes( e )
                            ) && styles[ 'teacher-workday' ],
                            monthNum === month.i ? styles[ 'for-this-month' ]
                                : monthNum === month.nextI ? styles[ 'for-next-month' ]
                                : styles[ 'for-last-month' ],
                            ( monthIsLessThan( monthNum, todayMonth, false ) || (
                                monthNum === todayMonth && day < todayDay
                            ) ) ? styles[ 'past-day' ] : (
                                monthNum === todayMonth && day === todayDay
                            ) ? styles[ 'present-day' ] : styles[ 'future-day' ]
                        ])
                    },
                    Button: {
                        className: styles[ [
                            monthNum === month.i ? 'current' : 'next-or-previous', 'month-button'
                        ].join( '-' ) ],
                        id: [
                            ...dayMonth,
                            'button',
                            monthNum === month.i ? 'for' : 'to',
                            monthNum
                        ].join( '-' ),
                        label: monthNum === month.i && <SVG type={{ add: true }}/>,
                        onClick: monthNum === month.i ? toggleSidebar2 : toggleActiveMonth,
                    },
                };

                return <li { ...props.li }>
                    <Button { ...props.Button }/>
                    { monthNum === month.i && <span>{ day }</span> }
                    {/* { actualCalendarDayMonthsArr[ i ]?.[ 1 ] === month.i && <span className={ styles[ 'actual-date' ] }>{ actualCalendarDayMonthsArr[ i ]?.[ 0 ] }</span> } */}
                </li>
            } ) }</ul>
        </li>
    }

    // function generateCalendarMonth( month={}, startingDate=0, dayMonths=[] ) {
    //     const date = new Date( month.year.full, month.i, startingDate );
    //     const [ monthI, day, weekday ] = [ date.getMonth(), date.getDate(), date.getDay() ];

    //     return ( startingDate > month.days.max && monthI === month.nextI ) ? dayMonths : generateCalendarMonth(
    //         month,
    //         startingDate + 1,
    //         [ 0, 6 ].includes( weekday ) ? dayMonths : [ ...dayMonths, [ day, monthI ] ]
    //     )
    // }

    function generateMonths( month={}, maxMonths=12, Components=[] ) {
        return Components.length === maxMonths ? Components : generateMonths(
            months[ month.nextI ],
            maxMonths,
            [ ...Components, generateMonth( month ) ]
        )
    }
    // function toggleActiveDay({ target: { id } }) { setActiveDayI( () => id.split( '-' ).shift() * 1 ) }

    function toggleActiveMonth({ target: { id } }) {
        const newMonthI = id.split( '-' ).pop() * 1;
        window.location.hostname === 'localhost' && console.log( id );

        setActiveMonthI( () => newMonthI )
        setActiveDayI( () => months[ newMonthI ].days.all[ 0 ] || get.dateOfWeekdayOfTheMonth(
            { monday: true }, newMonthI, months[ newMonthI ].year.full, true
        ) )
    }

    // function toggleEditActiveDayOffReason() { setEditActiveDayOffReason( editActiveDayOffReason => !editActiveDayOffReason ) }

    // function toggleSidebar({ target: { id } }) {
    //     const [ day, month, ] = id.split( '-' ).slice( 0, 2 ).map( e => e * 1 );
    //     console.log(`${ day }.${ month } ( ${ id } )`);

    //     setShowSidebar( showSidebar => {
    //         const mustShowSidebar = !!id || !showSidebar;
    //         console.log( activeDayI, day, holidays[ activeMonthI ], observances[ activeMonthI ] );
    //         if ( activeDayI !== day ) {
    //             setActiveDayIsDayOff( () => holidays[ activeMonthI ]?.days?.includes( day ) )
    //             setEditActiveDayOffReason( () => false )
    //         }

    //         if ( mustShowSidebar ) setActiveDayI( () => day )
    //         return mustShowSidebar
    //     } )
    // }

    function toggleSidebar2({ target: { id } }) {
        const [ day, month, ] = id.split( '-' ).slice( 0, 2 ).map( e => e * 1 );
        window.location.hostname === 'localhost' && console.log(`${ day }.${ month } ( ${ id } )`);

        if ( activeDayI !== day ) {
            // setActiveDayIsDayOff( () => holidays[ activeMonthI ]?.days?.includes( day ) )
            // setEditActiveDayOffReason( () => false )
            setActiveDayI( () => day || null )
            setActiveMonthI( () => month )
        }
    }

    function monthIsGreaterThan( monthI=0, comparedMonthI=0, orEqualTo=false ) {
        if ( monthI < start.month ) monthI += 12
        if ( comparedMonthI < start.month ) comparedMonthI += 12

        return orEqualTo ? monthI >= comparedMonthI : monthI > comparedMonthI
    }

    function monthIsLessThan( monthI=0, comparedMonthI=0, orEqualTo=false ) {
        if ( monthI < start.month ) monthI += 12
        if ( comparedMonthI < start.month ) comparedMonthI += 12

        return orEqualTo ? monthI <= comparedMonthI : monthI < comparedMonthI
    }

    // PROPS & ATTRIBUTES
    const props = {
        calendar: {
            style: {}, className: get.classNameFromArr([
                styles.calendar,
                styles[ 'show-all-months' ],
                // !activeDayI && styles[ 'show-all-months' ]
            ]),
        },
        SideBar: {
            activeDayI, activeMonthI, monthIsGreaterThan, monthIsLessThan, school, schoolYear, updateSchoolYear,
            actionOnClose: toggleSidebar2, // toggleSidebar2
            className: get.classNameFromArr([ styles.sidebar, !activeDayI && styles.hide ]),
            lang: locale.split( '-' )[ 0 ],
        },
    };

    if ( school ) [
        get.hslFromName( school?.colours[ 0 ], true ),
        get.hslFromName( school?.colours[ 1 ], true ),
    ].forEach( ( hsl, i ) => Object.keys( hsl ).forEach(
        e => props.calendar.style[ '--' + e + ( i + 1 ) ] = hsl[ e ]
    ) );

    // RENDER
    return <section { ...props.calendar }>
        <SideBar { ...props.SideBar }/>

        <div className={ styles.content }>
            <header className={ styles.legend }>{ [ 'odd', 'even', 'student' ].map( ( e, i ) => {
                // console.log( 'ACTIVE MONTH I:', !!activeMonthI );
                const isSchoolDay = i < 2;
                let [ label, monthsArr ] = [ [ e, isSchoolDay ? 'days' : 'holidays' ].join(' '), [] ];
                let [ daysCompleted, totalDays ] = [ 0, 0 ];

                monthsArr = !isSchoolDay ? schoolYear.holidays
                    : Array.isArray( months ) ? months
                    : Object.values( months );

                if ( !activeMonthI ) { // if showing all months
                    [ daysCompleted, totalDays ] = monthsArr.reduce( ( prevValsArr, currentMonth, j ) => {
                        const [ daysUsedRunningTotal, daysTotalRunningTotal ] = prevValsArr;
                        const daysArr = isSchoolDay ? currentMonth.days[ e ].slice()
                            : ( currentMonth?.days?.slice() || [] );
                        const spliceI = isSchoolDay && holidays[ j ]?.reasons?.findIndex(
                            reason => reason.includes( 'psat' )
                        );

                        if ( isSchoolDay && spliceI >= 0 ) daysArr.includes( holidays[ j ].days[ spliceI ] )
                            && daysArr.splice( spliceI, 1 )

                        const [ daysUsed, daysTotal ] = [
                            j === todayMonth ? daysArr.filter( day => day < todayDay ).length
                                : monthIsLessThan( j, todayMonth, false ) ? daysArr.length
                                : 0,
                            daysArr.length
                        ];

                        return [ daysUsed + daysUsedRunningTotal, daysTotal + daysTotalRunningTotal ]
                    }, [ 0, 0 ] );

                } else if ( isSchoolDay ) { // if calculating activeMonthI's odd or even days
                    const daysArr = monthsArr[ activeMonthI ].days[ e ] || [];

                    [ daysCompleted, totalDays ] = [
                        activeMonthI === todayMonth ? daysArr.filter( day => day < todayDay ).length
                            : monthIsLessThan( activeMonthI, todayMonth, false ) ? daysArr.length
                            : 0,
                        daysArr.length
                    ];

                } else {  // if calculating activeMonthI's holidays
                    const daysArr = monthsArr[ activeMonthI ]?.days || [];

                    [ daysCompleted, totalDays ] = [ daysArr.filter( day => day < todayDay ).length, daysArr.length ];
                }

                return <p
                    className={ styles.label }
                    key={ get.stringInNewFormat( label, ' ', { dashed: true } ) }
                >
                    <span className={ styles[ 'label-text' ] }>{ get.capitalise( label ) }</span>
                    <span className={ styles.quantity }>{ daysCompleted } / { totalDays }</span>
                </p>
            } ) }</header>

            <ul className={ get.classNameFromArr([
                styles.months, !activeDayI && styles[ 'without-sidebar' ]
            ]) }>{ ( canShowSingleMonthView && activeDayI ) ? generateMonths(
                months[ activeMonthI ], 1
            ) : generateMonths( months[ start.month ], 12 ) }</ul>
        </div>

    </section>
}

export default Calendar;