import { useEffect, useState } from 'react';
import styles from '../ShopItem.module.css';
import Button from '../../Button';
import SVG from '../../../modular/Images/SVG';
import get from '../../../../constants/get';
import TextInputButton from '../../TextInputButton';
// import styles from './ShopItemDetails.module.css';

const [ assessments, keyTerms ] = [[
    'pre-assessment',
    'formative vocab assessment',
    'formative language structure assessment',
    'formative reading assessments',
    'formative writing assessments',
    'formative speaking assessments',
    'formative listening assessments',
    'a summative 4-skill assessment'
], [
    'vocabulary',
    'structures',
    'expressions',
    'skills',
]];

const ShopItemDetails = ({ subject='', unitObj={}, updateUnitObj=() => {} }) => {
    const [ userInput, setUserInput ] = useState([]);
    const [ edit, setEdit ] = useState({
        exMode: '',
        expressions: false,
        questions: false,
        skills: false,
        structures: false,
        targets: false,
        vocabulary: false,
    });

    useEffect( () => {
        console.log(`EDIT MODE: ${ editModeIsOn( edit ) ? '🟢' : '🔴' }${ Object.entries( edit ).find( arr => arr[ 1 ] && arr[ 0 ] !== 'exMode' )?.[ 0 ] || '' }`)
        if ( !editModeIsOn( edit ) && userInput[ 0 ] ) {
            setUserInput( () => [] )

            const values = userInput.slice( 0, userInput.length - 1 );
            unitObj[ edit.exMode ] = Array.isArray( unitObj[ edit.exMode ] ) ?
                values : Object.fromEntries( values );
            updateUnitObj( unitObj );
        }
    }, [ edit, setUserInput, unitObj, updateUnitObj, userInput ] )

    const [ langCode, somethingIsInEditMode ] = [
        subject === 'french' ? 'fr'
            : subject === 'spanish' ? 'es'
            : subject === 'german' ? 'de'
            : subject === 'japanese' ? 'jp'
            : 'en',
        editModeIsOn( edit )
    ];

    // FUNCTIONS
    function getButton({
        isRemoveButton = false,
        isEditButton = false,
        isSaveButton = false,
        i = 0,
        label = '',
    }) {
        const props = {
            className: styles[ ( isEditButton ? 'edit' : isSaveButton ? 'save' : 'remove' ) + '-button' ],
            label: isSaveButton ? 'save'
                : isRemoveButton ? <SVG type={{ close: true }}/>
                : <SVG fontSize='.75em' type={{ edit: true }}/>,
            onClick: isRemoveButton ? removeUserInput : () => toggleEditMode( label )
        };

        if ( isRemoveButton ) props.id = 'remove-at-index-' + i
        return <Button { ...props }/>
    }

    function getTextInput({
        autocomplete = false,
        className = styles.input,
        inputIcon = null,
        inputLabel = '',
        uncontrolledValue = ''
    }) {
        const props = { autocomplete, className, inputIcon, inputLabel, uncontrolledValue };
        return <TextInputButton { ...props }/>
    }

    function removeUserInput({ target: { id } }) {
        const i = id.split( '-' ).pop() * 1;
        setUserInput( oldUserInput => oldUserInput.filter( ( e, j ) => j !== i ) )
    }

    function toggleEditMode( key='' ) {
        console.log( `editing ${ key.toLocaleUpperCase() }` );
        if ( !edit[ key ] ) setUserInput( () => [
            ...( Array.isArray( unitObj[ key ] ) ? unitObj[ key ]
                : Object.entries( unitObj[ key ] ) ),
            'form'
        ] ) // initiate userInput if we're about to toggle edit mode on

        setEdit( edit => Object.fromEntries(
            Object.keys( edit ).map( k => [
                k, k === key ? !edit[ k ] : k === 'exMode' ? key : false
            ] )
        ) )
    }

    function updateUserInput( e ) {
        e.preventDefault();
        console.log( e );

        setUserInput( oldUserInput => {
            const formStr = oldUserInput.pop();
            let [ val1, val2 ] = [
                e.target.elements[ 1 ].value.trim(),
                e.target.elements?.[ 3 ]?.value?.trim(),
            ];

            if ( edit.questions && [ 'es', 'fr' ].includes( langCode ) ) {
                if ( langCode === 'es' ) val1 = val1.startsWith( '¿ ' ) ? val1
                    : !val1.startsWith( '¿' ) ? '¿ ' + val1[ 0 ].toLocaleUpperCase() + val1.slice( 1 )
                    : '¿ ' + val1[ 1 ].toLocaleUpperCase() + val1.slice( 2 )

                if ( [ 'es', 'fr' ].includes( langCode ) ) val1 = val1.endsWith( ' ?' ) ? val1
                    : !val1.endsWith( '?' ) ? val1 + ' ?'
                    : val1.slice( 0, val1.length - 1 ) + ' ?'
            }

            console.log({ val1, val2 });
            return [ ...oldUserInput, val2 ? [ val2, val1 ] : val1, formStr ]
        } )
    }

    // COMPONENTS
    const Target = <svg className={ styles.bullseye } xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32">
        <g fill="none">
            <path className={ styles[ 'white-rings' ] } d="M14 28.292c5.684 0 10.292-4.608 10.292-10.292S19.684 7.708 14 7.708S3.708 12.316 3.708 18S8.316 28.292 14 28.292Z"/>
            <path className={ styles[ 'red-rings' ] } d="M26 18c0-6.627-5.373-12-12-12S2 11.373 2 18s5.373 12 12 12s12-5.373 12-12Zm-3 0a9 9 0 1 1-18 0a9 9 0 0 1 18 0Zm-9 6a6 6 0 1 1 0-12a6 6 0 0 1 0 12Zm3-6a3 3 0 1 0-6 0a3 3 0 0 0 6 0Z"/>
            <path className={ styles[ 'dart-tip' ] } d="M14.25 17.742a.864.864 0 0 0 1.232 0l4.26-4.254a.861.861 0 0 0 0-1.23a.864.864 0 0 0-1.232 0l-4.26 4.254a.88.88 0 0 0 0 1.23Z"/>
            <path className={ styles[ 'dart-wings' ] } d="m19.658 10.093l-.45-1.59c-.54-1.9-.01-3.95 1.39-5.34l.89-.89a.906.906 0 0 1 1.52.39L24 6l-4.342 4.093ZM22 12.33l1.572.458c1.878.55 3.904.01 5.278-1.416l.88-.906c.494-.5.277-1.356-.385-1.55L26 8l-4 4.33Z"/>
            <path className={ styles[ 'dart-body' ] } d="M15.85 16.152a2.892 2.892 0 0 0 4.307-.24l5.424-6.798a1.92 1.92 0 0 0-2.698-2.696l-6.802 5.42a2.904 2.904 0 0 0-.231 4.314Z"/>
            <path className={ styles[ 'dart-wings' ] } d="M21.258 10.742a.862.862 0 0 0 1.23 0l4.254-4.254a.862.862 0 0 0 0-1.23a.862.862 0 0 0-1.23 0l-4.254 4.254a.862.862 0 0 0 0 1.23Z"/>
        </g>
    </svg>;

    // RENDER
    return <>
        <section className={ styles.targets }>
            <header>
                <h2>{ somethingIsInEditMode || getButton({ isEditButton: true, label: 'targets' }) }Learning Targets</h2>
                <p>What do we want them to be able to do</p>
                { edit.targets && getButton({ isSaveButton: true, label: 'targets' }) }
            </header>

            <ul className={ get.classNameFromArr([ styles.list, edit.targets && styles.editing ]) }>{(
                edit.targets ? userInput : unitObj.targets
            )?.map( ( target, i ) => <li
                className={ get.classNameFromArr([ target === 'form' && styles.form, styles.target ]) }
                key={ [ unitObj.theme.split(' ').join( '-' ), 'target', i ].join( '-' ) }
            >{ !edit.targets ? <>{ Target }{ target }</>
                : target === 'form' ? <form onSubmit={ updateUserInput }>
                    { getTextInput({ inputIcon: Target, inputLabel: 'target' }) }
                </form> : <>{ getButton({ isRemoveButton: true, i })}{ Target }{ target }</>
            }</li> ) }</ul>
        </section>

        <section className={ styles.assessments }>
            <header>
                <h2>Assessments</h2>
                <p>How will we know that they can do that</p>
            </header>

            <ul className={ styles.list }>{
                assessments.map( ( assessment, i ) => <li key={ `assessment-${ i }` }>❌ { assessment }</li> )
            }</ul>
        </section>

        <section className={ styles.questions }>
            <header>
                <h2>
                    { somethingIsInEditMode || getButton({ isEditButton: true, label: 'questions' }) }
                    Unit Questions
                </h2>
                { edit.questions && getButton({ isSaveButton: true, label: 'questions' }) }
            </header>

            <ul className={ get.classNameFromArr([ styles.list, edit.questions && styles.editing ]) }>{(
                edit.questions ? userInput : unitObj.questions
            )?.map( ( question, i ) => <li
                className={ get.classNameFromArr([ question === 'form' && styles.form, styles.question ]) }
                key={ `unit-question-${ i }` }
            >{ !edit.questions ? <>{ question }</>
                : question === 'form' ? <form onSubmit={ updateUserInput }>{ getTextInput({
                    inputLabel: 'question',
                    uncontrolledValue: langCode === 'es' ? '¿  ?' : '?'
                }) } </form> : <>{ getButton({ isRemoveButton: true, i })}{ question }</>
            }</li> ) }</ul>
        </section>

        <section className={ styles[ 'key-terms' ] }>{ keyTerms.map( keyLabel => <section key={ 'key-' + keyLabel }>
            <header>
                <h3>
                    { somethingIsInEditMode || getButton({ isEditButton: true, label: keyLabel }) }
                    { get.capitalise( keyLabel ) }
                    { edit[ keyLabel ] && getButton({ isSaveButton: true, label: keyLabel }) }
                </h3>
            </header>

            <ul className={ get.classNameFromArr([ styles.list, edit[ keyLabel ] && styles.editing ]) }>{(
                edit[ keyLabel ] ? userInput
                    : Array.isArray( unitObj[ keyLabel ] ) ? unitObj[ keyLabel ]
                    : Object.keys( unitObj )[ 0 ] ? Object.entries( unitObj[ keyLabel ] )
                    : []
            ).map( ( entry, i ) => <li
                className={ get.classNameFromArr([ entry === 'form' && styles.form, styles[
                    keyLabel.endsWith( 's' ) ? keyLabel.slice( 0, keyLabel.length-1 ) : keyLabel
                ] ]) }
                key={ keyLabel + '-' + i }
            >{ !edit[ keyLabel ] ? <>
                <span className={ styles.term }>{ entry[ 1 ] }</span>
                <span className={ styles.definition }>{ entry[ 0 ] }</span>
            </> : entry === 'form' ? <form
                onSubmit={ updateUserInput }
                onKeyDown={ e => e.key === 'enter' && updateUserInput( e ) }
            >
                { getTextInput({ inputLabel: keyLabel.endsWith( 's' ) ? keyLabel.slice( 0, keyLabel.length-1 ) : 'term' }) }
                { getTextInput({ inputLabel: keyLabel === 'skills' ? 'detail' : 'in English' }) }
                <Button label='add' type='submit'/>
            </form> : <>
                { getButton({ isRemoveButton: true, i })}
                <span className={ styles.term }>{ entry[ 1 ] }</span>
                <span className={ styles.definition }>{ entry[ 0 ] }</span>
            </>
            }</li> ) }</ul>
        </section> ) }</section>
    </>
}

// OTHER FUNCTIONS
function editModeIsOn( edit={} ) {
    return Object.entries( edit ).findIndex( arr => arr[ 1 ] && arr[ 0 ] !== 'exMode' ) >= 0
}

export default ShopItemDetails;