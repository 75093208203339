// import { arrayRemove, arrayUnion, collection, deleteDoc, doc, getDocs, query, setDoc, updateDoc, where, writeBatch } from 'firebase/firestore';
import { arrayUnion, collection, deleteDoc, doc, getDocs, query, setDoc, updateDoc, where, writeBatch } from 'firebase/firestore';
import { db } from '../../firebase_setup/firebase';
import getSchoolYearObj from '../../constants/yearInfo';
import get from '../../constants/get';
import courseInfo from '../../constants/courseInfo';
// import fireRead from './readFireData';


const pushAllFireData = async ( user={}, overwrite=false ) => {
    window.location.hostname === 'localhost' && console.log( '🚨 PUSHING TO FIREBASE 🚨' );
    const [ batch, fireData ] = [ writeBatch( db ), { counties: [], courses: [], user: {}, premium: {} } ];

    await pushUser( user, overwrite, batch )
        .then( async response => {
            response.forEach( ( obj, i ) => fireData[ [ 'user', 'premium' ][ i ] ] = obj );

            return await pushCounty( user.counties, overwrite, batch );
        } ).then( async response => {
            response.forEach( ( obj, i ) => fireData.counties = obj );

            return await pushCourses( user.courses, user.schoolYearID, overwrite, batch );
        } ).then( async response => { fireData.courses = response } );

    await batch.commit();
    window.location.hostname === 'localhost' && console.log( '🗂️ ALL DATA:', fireData );
    return fireData
}

// ============ FIRESTORE ============
// ***** ARCHIVE *****
// const archiveCourse = async ( course={}, schoolYearID='' ) => {
//     try {
//         await Promise.all([
//             fireRead.courseCollections( { readStudents: true }, courseData.title, courseData.id.db, schoolYearID ),
//             fireRead.courseCollections( { readSubmissions: true }, courseData.title, courseData.id.db, schoolYearID ),
//             fireRead.courseCollections( { readSyllabus: true }, courseData.title, courseData.id.db, schoolYearID ),
//             fireRead.courseCollections( { readUnits: true }, courseData.title, courseData.id.db, schoolYearID ),
//         ]).then( receipts => {
//             course.content.students = receipts[ 0 ];
//             course.content.submissions = receipts[ 1 ];
//             course.content.syllabus = receipts[ 2 ];
//             course.content.units = receipts[ 3 ];
//         } );

//         const { content, ...courseData } = course;
//         const { assignments, boards, students, submissions, syllabus, units } = content;

//         const deletedCourseDoc = doc( db, 'deleted', schoolYearID, 'courses', courseData.id.db );
//         setDoc( deletedCourseDoc, courseData, { merge: true } );

//         for ( let subcollection in content ) {
//             const deleteCourseSubcollection = doc( deletedCourseDoc, subcollection, schoolYearID )
//         }


//         // Push the new course details to a randomly-generated doc and update user.courseIDs
//         if ( isNewCourse ) {
//             pushCourses( [ course ], user.schoolYearID, true );

//         }
//         console.log( `✅ archived: ${ courseData.title } (${ courseData.id.db })`, course );

//     } catch( error ) { console.error( `❌ Error archiving ${ courseData.title }`, error, course ) }
// }

// ***** CREATE *****
const createUser = async ( userData={}, userTemplate={} ) => {
    try {
        const userRef = doc( db, 'users', userData.uid );
        await setDoc( userRef, userTemplate, { merge: true } );

    } catch ( error ) { console.error( '❌ Error creating a user', userData ) }
}

const createCourse = async ( course={}, user={}, courseIDs=[] ) => {
    try {
        const coursesCollection = collection( db, 'courses' );
        const coursesRef = doc( coursesCollection );
        let isNewCourse = true;
        // course.id.db = coursesRef.id;

        // Check if there is already a course with this title owned by this user
        const q = query(
            coursesCollection,
            where( 'ownerID', '==', user.data.uid ),
            where( 'title', '==', course.title )
        );

        const querySnapshot = await getDocs( q );
        querySnapshot.forEach( doc => isNewCourse = !doc.exists );
        window.location.hostname === 'localhost' && console.log( isNewCourse ? '➕ NEW COURSE' : '🛑 OLD COURSE' );

        // Push the new course details to a randomly-generated doc and update user.courseIDs
        if ( isNewCourse ) {
            pushCourses( [ course ], user.schoolYearID, true );
            updateUserObj( user.data.uid, `courseIDs.queen`, courseIDs );

            window.location.hostname === 'localhost' && console.log( `✅ Created a new course: ${ course.title } (${ coursesRef.id })`, course );
        }

        return course
    } catch( error ) { console.error( `❌ Error adding ${ course.title }`, error, course ) }
}

const createSubmissionsDoc = async ( courseID='', schoolYearID='' ) => await setDoc(
    doc( db, 'courses', courseID, 'submissions', schoolYearID ),
    { submissions: [] },
    { merge: true }
)

// ***** DELETE *****
const deleteCourse = async ( course={}, user={}, courseIDs=[] ) => {
    const subCollectionNames = [ 'assignments', 'boards', 'students', 'submissions', 'syllabus', 'units' ];

    try {
        // Delete courseID from user in database
        updateUserObj( user.data.uid, 'courseIDs.queen', courseIDs );

        await deleteCourseSubCollections( user.schoolYearID, course.id.db, subCollectionNames )
            .then( () => deleteDoc( doc( db, 'courses', course.id.db ) ) );

        window.location.hostname === 'localhost' && console.log( `✅ Deleted ${ course.title } and all its subcollections` );
    } catch( error ) { console.error( `❌ Error deleting ${ course.title }`, error, course ) }
}

// Individually delete each doc in each subcollection in order to delete them from the database (it's the only way as of April 2023)
const deleteCourseSubCollections = async ( schoolYearID='', courseID='', subCollectionNames=[] ) => {
    const subCollectionName = subCollectionNames[ 0 ];

    try {
        deleteDoc( doc( db, 'courses', courseID, subCollectionName, schoolYearID ) );

        window.location.hostname === 'localhost' && console.log( `✅ Deleted ${ subCollectionName }` );
    } catch( error ) { console.error( `❌ Error deleting ${ subCollectionName }`, error ) }

    return !subCollectionNames[ 1 ] ? ( window.location.hostname === 'localhost' && console.log( '🐝 BumbleDone deleting subcollections !' ) )
        : deleteCourseSubCollections( schoolYearID, courseID, subCollectionNames.slice( 1 ) )
}

// ***** PUSH *****
const pushCounty = async ( counties=[ {} ], overwrite=false, batch=null, arr=[] ) => {
    // console.log( '🚨', counties[ 0 ] );
    if ( 'schools' in counties[ 0 ] === false ) {
        counties[ 0 ] = courseInfo.getCountyObj();
        counties[ 0 ].schools = [ courseInfo.getSchoolObj() ];
        counties[ 0 ].schoolYear = { id: 'us-no-county' };
    }
    // console.log( '🚨', counties[ 0 ] );
    const { schools, schoolYear, ...countyData } = counties[ 0 ];
    // console.log( '🚨', schools, schoolYear, countyData );

    try {
        // get the doc/collection reference, the data object to push, and whether or not to overwrite or merge it in the database
        const refDataMerge = getRefDataMergeParams( countyData, overwrite, getDBRef(
            { counties: true }, { countyID: countyData.id }, { getRandomDoc: false }
        ) );

        // if a batch was passed, queue this doc-setting to the batch, else push to the database
        batch !== null ? batch.set( ...refDataMerge ) : await setDoc(
            doc( db, 'counties', countyData.id ), countyData, { merge: true }
        );
        // batch !== null ? batch.set( ...refDataMerge ) : await setDoc( ...refDataMerge );

        await pushSchools( Object.values( schools ), overwrite, batch );
        await pushSchoolYear( schoolYear, overwrite, batch );


    } catch( error ) { console.error( `❌ Error adding ${ countyData.name.full }`, error, countyData, schoolYear, schools ) }
    window.location.hostname === 'localhost' && console.log( `✅ ${ batch === null ? 'Wrote/Updated' : 'Queued' } ${ countyData.name.full }, schoolYear, & schools`, countyData, schoolYear, schools );

    arr.push( counties[ 0 ] );
    return counties[ 1 ] ? pushCounty( counties.slice( 1 ), overwrite, batch, arr ) : arr
}

const pushCourses = async ( courses=[ {} ], schoolYearID='', overwrite=false, batch=null, arr=[] ) => {
    const { content: { assignments, boards, students, syllabus, units }, ...courseData } = courses[ 0 ];
    const entries = Object.entries( { units, students, syllabus, boards, assignments } );
    entries.push( [ 'submissions', { submissions: [] } ] )

    try {
        // get the doc/collection reference, the data object to push, and whether or not to overwrite or merge it in the database
        const refDataMerge = getRefDataMergeParams( courseData, overwrite, getDBRef(
            { courses: true }, { courseID: courseData.id.db }, { getRandomDoc: false }
        ) );

        // if a batch was passed, queue this doc-setting to the batch, else push to the database
        batch !== null ? batch.set( ...refDataMerge ) : await setDoc( ...refDataMerge );
        await pushCourseSubCollections( entries, schoolYearID, courseData.id.db, overwrite, batch );

        window.location.hostname === 'localhost' && console.log( `✅ ${ batch === null ? 'Wrote/Updated' : 'Queued' } ${ courseData.title } & relevant sub-collections to ${ courseData.id.db }` );
    } catch( error ) { console.error( `❌ Error updating ${ courseData.title }`, error, courseData ) }

    arr.push( courses[ 0 ] );
    if ( !courses[ 1 ] ) window.location.hostname === 'localhost' && console.log( `✅ Added ${ arr.length > 1 ? `${ arr.length > 2 ? 'all' : 'both' } courses` : arr[ 0 ].title }\nℹ️ COURSES DATA`, arr )

    // Recurse so long as there are objects left in [ courses ]
    return !courses[ 1 ] ? arr : await pushCourses( courses.slice( 1 ), schoolYearID, overwrite, batch, arr )
}

const pushCourseSubCollections = async ( entries=[ [] ], schoolYearID='', courseID='', overwrite=false, batch=null ) => {
    let [ collection, data ] = entries[ 0 ];
    data = { [ collection ]: data };

    try {
        // get the doc/collection reference, the data object to push, and whether or not to overwrite or merge it in the database
        const refDataMerge = getRefDataMergeParams(
            data, overwrite, doc( db, 'courses', courseID, collection, schoolYearID )
        );

        // if a batch was passed, queue this doc-setting to the batch, else push to the database
        batch !== null ? batch.set( ...refDataMerge ) : await setDoc( ...refDataMerge );

        window.location.hostname === 'localhost' && console.log( `✅ ${ batch === null ? 'Wrote/Updated' : 'Queued' } ${ collection } to ${ courseID }`, data );
    } catch( error ) { console.error( `❌ Error adding ${ collection }`, error, data ) }

    // Recurse so long as there are objects left in [ entries ]
    return entries[ 1 ] ? pushCourseSubCollections( entries.slice( 1 ), schoolYearID, courseID, overwrite, batch ) : ( window.location.hostname === 'localhost' && console.log('👍🏾 BumbleDone!') )
}

const pushSchools = async ( schools=[ {} ], overwrite=false, batch=null ) => {
    const school = schools[ 0 ];
    // get.log({
    //     fileName: 'pushFireData.js',
    //     functionDirectionsOrComponentName: 'school',
    //     lineNumber: 223,
    //     str: school
    // });

    try {
        // get the doc/collection reference, the data object to push, and whether or not to overwrite or merge it in the database
        const refDataMerge = getRefDataMergeParams( school, overwrite, getDBRef(
            { countySchools: true }, { countyID: school.countyID, id: school.id }, { getRandomDoc: false }
        ) );

        // if a batch was passed, queue this doc-setting to the batch, else push to the database
        batch !== null ? batch.set( ...refDataMerge ) : await setDoc( ...refDataMerge );

        window.location.hostname === 'localhost' && console.log( `✅ Queued ${ school.name.full } to ${ school.countyID }`, school );
    } catch( error ) { console.error( `❌ Error adding ${ school.name.full }`, error, school ) }

    // Recurse so long as there are objects left in [ schools ]
    return schools[ 1 ] ? await pushSchools( schools.slice( 1 ), overwrite, batch ) : ( window.location.hostname === 'localhost' && console.log('👍🏾 BumbleDone!') )
}

const pushSchoolYear = async ( schoolYear={ id: 'us-no-county' }, overwrite=false, batch=null ) => {
    schoolYear = schoolYear.months ? schoolYear : getSchoolYearObj(
        [ 21, 7, 2023 ],
        [ 16, 5, 2024 ],
        schoolYear.id,
        [
            { days: [ 19 ], reasons: [ 'Orthodox Epiphany' ] },
            { days: [  ], reasons: [  ] },
            { days: [ 11, 29 ], reasons: [ 'Ramadan', 'Good Friday' ] },
            { days: [ 9, 10, ...get.numRange( 22, 24 ) ], reasons: [ 'Eid al Fitr', 'Eid al Fitr', 'Passover', 'Passover', 'Theravada New Year' ] },
            { days: [  ], reasons: [  ] },
            { days: [ 17, 19 ], reasons: [ 'Eid al Adha', 'Juneteenth' ] },
            { days: [  ], reasons: [  ] },
            { days: [  ], reasons: [  ] },
            { days: [ 15, 25 ], reasons: [ 'Rosh Hashanah', 'Yom Kippur' ] },
            { days: [ 9, 31 ], reasons: [ 'Indigenous People\'s Day', 'Halloween' ] },
            { days: [ 1, 7 ], reasons: [ 'All Saints Day & Día de los Muertos', 'Election Day' ] },
            { days: [ 7, 8 ], reasons: [ 'Chanukah', 'Bodhi' ] },
        ], [
            { days: [ 1, 15 ], reasons: [ 'New Year\'s Day', 'MLK Jr.\'s Birthday' ] },
            { days: [ 19 ], reasons: [ 'Washington\'s Birthday & Presidents\' Day'] },
            { days: [  ], reasons: [  ] },
            { days: [  ], reasons: [  ] },
            { days: [ 27 ], reasons: [ 'Memorial Day' ] },
            { days: [  ], reasons: [  ] },
            { days: [ 4 ], reasons: [ 'Independence Day' ] },
            { days: [  ], reasons: [  ] },
            { days: [ 4 ], reasons: [ 'Labor Day' ] },
            { days: [  ], reasons: [  ] },
            { days: [ 23 ], reasons: [ 'Thanksgiving' ] },
            { days: [ 24, 25 ], reasons: [ 'Christmas Eve', 'Christmas' ] },
        ],
        { 13: { days: [ 1 ], reasons: [ 'PSAT' ] } },
        [ 1, 2, 3, 4 ],
        [ [ 3, 10, 2023 ], [ 25, 0, 2024 ], [ 22, 2, 2024 ], [ 12, 5, 2024 ] ]
    );
    // get.log({
    //     fileName: 'pushFireData.js',
    //     functionDirectionsOrComponentName: 'schoolYear',
    //     lineNumber: 282,
    //     str: schoolYear
    // });

    try {
        // get the doc/collection reference, the data object to push, and whether or not to overwrite or merge it in the database
        const refDataMerge = getRefDataMergeParams( schoolYear, overwrite, getDBRef(
            { countySchoolYears: true },
            { countyID: schoolYear.countyID, id: schoolYear.id },
            { getRandomDoc: false }
        ) );

        // if a batch was passed, queue this doc-setting to the batch, else push to the database
        batch !== null ? batch.set( ...refDataMerge ) : await setDoc( ...refDataMerge );

        window.location.hostname === 'localhost' && console.log( `✅ Queued ${ schoolYear.id } school year to ${ schoolYear.countyID }`, schoolYear );
        return schoolYear

    } catch( error ) { console.error( `❌ Error adding ${ schoolYear.countyID }'s ${ schoolYear.id } school year`, error ) }
}

// const updateTeams = async ( dashedCourseID='' ) => {
//     return { county: [], school: [], custom: [] }
// }

const pushUser = async ( user={}, overwrite=false, batch=null ) => {
    window.location.hostname === 'localhost' && console.log( '🐝', user );
    const [ isBatch, isPremium ] = [ !!batch, !user.plan.isFree ];
    const [ userData, premiumData ] = [
        getData( user, { userObj: true }, {} ),
        getData( user, { premiumObj: true }, {} )
    ];

    // get the doc/collection reference, the data object to push, and whether or not to overwrite or merge it in the database
    const [ userRefDataMerge, premiumRefDataMerge ] = [
        getRefDataMergeParams( userData, overwrite, getDBRef(
            { users: true }, { userID: user.data.uid }, { getRandomDoc: false }
        ) ), getRefDataMergeParams( premiumData, overwrite, getDBRef(
            { userPremiumYears: true }, { userID: user.data.uid, id: user.schoolYearID }, { getRandomDoc: false }
        ) )
    ];
    // console.log( user.data.uid, '\n', userData, '\n', premiumData, '\n', userRefDataMerge, '\n', premiumRefDataMerge );

    try {
        if ( isBatch ) {
            window.location.hostname === 'localhost' && console.log( '...Setting to batch' );
            batch.set( ...userRefDataMerge );

            window.location.hostname === 'localhost' && console.log( `✅ Queued ${ userData.name.preferred }'s data`, userData );

        } else {
            window.location.hostname === 'localhost' && console.log( '...Setting doc' );
            // setDoc( doc( db, 'users', user.data.uid ), userData );
            createUser( user.data, userData );

            window.location.hostname === 'localhost' && console.log( `✅ Wrote/Updated ${ userData.name.preferred }'s data$`, userData );
        }

    } catch( error ) { console.error( `❌ Error adding ${ userData.name.preferred }'s data`, error, user, userData ) }

    try {
        if ( isBatch && isPremium ) {
            window.location.hostname === 'localhost' && console.log( '...Setting premium to batch' );
            batch.set( ...premiumRefDataMerge );

            window.location.hostname === 'localhost' && console.log( `✅ Queued ${ userData.name.preferred }'s premium data`, premiumData );

        } else if ( !isBatch && isPremium ) {
            window.location.hostname === 'localhost' && console.log( '...Setting premium doc' );
            setDoc(
                doc( db, 'users', user.data.uid, 'premium', get.schoolYearID() ),
                premiumData,
                { merge: true }
            );

            window.location.hostname === 'localhost' && console.log( `✅ Wrote/Updated ${ userData.name.preferred }'s premium data`, premiumData );
        }

    } catch( error ) { console.error( `❌ Error adding ${ userData.name.preferred }'s premium data`, error, user, premiumData ) }

    return [ userData, premiumData ]
}

const pushUnits = async ( course={}, units=[], schoolYearID='' ) => {
    try {
        await setDoc( doc( db, 'courses', course.id.db, 'units', schoolYearID ), { units }, { merge: true } );
    } catch ( error ) { console.error( '❌ ERROR PUSHING UNITS', error ) }
}

// ***** UPDATE *****
// const updateUserArr = async ( userID='', arrKey='', value, mustDelete=false ) => await updateDoc(
//     getDBRef( { users: true }, { userID }, { getRandomDoc: false } ),
//     { [ arrKey ]: mustDelete ? arrayRemove( value ) : arrayUnion( value ) }
// )

const updateUserObj = async ( userID='', dottedKey='', value ) => await updateDoc(
    doc( db, 'users', userID ), { [ dottedKey ]: value }
)

const updateAssignmentsObj = async ( courseID='', schoolYearID='', boardNum=0, assignments=[] ) => {
    try {
        await updateDoc(
            getDBRef( { courseAssignments: true }, { courseID, id: schoolYearID }, { getRandomDoc: false } ),
            { [ [ 'assignments', boardNum ].join( '.' ) ]: assignments }
        )
    } catch( error ) {
        console.error( '❌', error );
        await setDoc(
            doc( db, 'courses', courseID, 'assignments', schoolYearID ),
            { assignments: { [ boardNum ]: assignments } },
            { merge: true }
        );
    }
}
const updateSubmissionsObj = async ( courseID='', schoolYearID='', obj=[] ) => {
    await setDoc(
        doc( db, 'courses', courseID, 'submissions', schoolYearID ),
        { 'submissions': arrayUnion( obj ) },
        { merge: true }
    );

    // await updateDoc(
    //     doc( db, 'courses', courseID, 'submissions', schoolYearID ), { 'submissions': arrayUnion( obj ) }
    // )
}

const updateBoardsArr = async ( courseID='', schoolYearID='', boards=[] ) => await setDoc(
    getDBRef( { courseBoards: true }, { courseID, id: schoolYearID }, { getRandomDoc: false } ),
    { boards },
    { merge: true }
)

const updateStudentsObj = async ( courseID='', schoolYearID='', studentsObj={} ) => await setDoc(
    getDBRef( { courseStudents: true }, { courseID, id: schoolYearID }, { getRandomDoc: false } ),
    { students: studentsObj },
    { merge: true }
)

const updateCourseObj = async ( courseID='', dottedKey='', value ) => await updateDoc(
    getDBRef( { courses: true }, { courseID }, { getRandomDoc: false } ), { [ dottedKey ]: arrayUnion( value ) }
)

const updateGuestMap = async (
    schoolYearID='',
    dottedKey='',
    obj={ courseIDs: [], firstName: 'Louboutin', lastName: 'BumbleBoard' },
    { isWorkerBee=false, isPrincessBee=false }
) => {
    if ( isWorkerBee ) obj.role = 'student'
    if ( isPrincessBee ) obj.role = 'leader'

    await updateDoc( doc( db, 'guests', schoolYearID ), { [ dottedKey ]: obj } )
}

// ============ UTILITIES ============
// Get only the pieces of { user } that you need
const getData = (
    user={},
    { userObj = false, premiumObj = false, countiesObj = false, coursesObj = false, schoolYearsObj = false, schoolsObj = false },
    { countyID = '', schoolID = '' }
) => {
    const obj = {};
    const { counties, courses, data, premium, ...userData } = user;

    if ( userObj ) return userData
    if ( premiumObj ) return premium
    if ( countiesObj ) obj.counties = counties
    if ( coursesObj ) obj.courses = courses
    if ( schoolYearsObj ) obj.schoolYear = counties.find( county => county.id === countyID ).schoolYear
    if ( schoolsObj ) obj.schools = counties.find( county => county.id === countyID )?.schools[ schoolID ]

    window.location.hostname === 'localhost' && console.warn( '🧨 Excluded', data, premium );
    window.location.hostname === 'localhost' && console.log( '🛠️ Got the data', obj );

    return obj;
}

// Get the reference to a specific doc or collection in the database
const getDBRef = ({
    users = false,
    userPremiumYears = false,
    courses = false,
    courseAssignments = false,
    courseBoards = false,
    courseStudents = false,
    courseSyllabus = false,
    courseUnits = false,
    counties = false,
    countySchools = false,
    countySchoolYears = false
}, { userID='', courseID='', countyID='', id='' },
{ getRandomDoc=false } ) => {
    let [ collectionRef, key ] = [ null, ( users || userPremiumYears ) ? 'users'
        : ( counties || countySchools || countySchoolYears ) ? 'counties' : 'courses' ];
    const getDBDoc = ( docID='' ) => doc( collectionRef, docID );

    switch ( key ) {
        case 'courses': collectionRef = courseBoards ? collection( db, key, courseID, 'boards' )
            : courseAssignments ? collection( db, key, courseID, 'assignments' )
            : courseStudents ? collection( db, key, courseID, 'students' )
            : courseSyllabus ? collection( db, key, courseID, 'syllabus' )
            : courseUnits ? collection( db, key, courseID, 'units' ) : collection( db, key ); break;

        case 'counties': collectionRef = countySchools ? collection( db, key, countyID, 'schools' )
            : counties ? collection( db, key ) : collection( db, key, countyID, 'schoolYears' );  break;

        case 'users': collectionRef = users ? collection( db, key )
            : collection( db, key, userID, 'premium' );  break;

        default: console.error( `❌ INVALID PARAMS. That doc does not exist & cannot be created.` ); break;
    }

    if ( !getRandomDoc ) collectionRef = getDBDoc( users ? userID
        : counties ? countyID
        : courses ? courseID : id
    )

    return collectionRef
}

// Get the db doc or collection reference, the { data } to push, & whether to overwrite or merge it ( in that order, for firestore )
const getRefDataMergeParams = ( data={}, overwrite=true, ref ) => {
    let arr = [ ref, data ];
    return overwrite ? [ ...arr, { merge: true } ] : arr
}

// ============ EXPORT ============
const firePush = {
    createCourse,
    createSubmissionsDoc,
    createUser,
    deleteCourse,
    pushAllFireData,
    pushCounty,
    pushCourses,
    pushSchoolYear,
    pushUnits,
    pushUser,
    updateAssignmentsObj,
    updateBoardsArr,
    updateCourseObj,
    updateGuestMap,
    updateStudentsObj,
    updateSubmissionsObj,
    updateUserObj,
};

export default firePush;