import get from '../../../constants/get';
import Button from '../../pages/Button';
import TextInputButton from '../../pages/TextInputButton';
import InfoButton from '../Buttons/InfoButton';
import SVG from '../Images/SVG';
import styles from './AddCourseForm.module.css';
import InputGroup from './Parts/InputGroup';

const AddAssignment = ({ addCourse = () => {}, courseID='', isFree=false, toggleForm = () => {} }) => {
    const [ premiumOptionLabels, premiumOptionDescriptions ] = [[
        'add units', 'add lesson plans', 'add collaborative team'
    ], [
        'a school year\'s set of BumbleBoards & 5-6 subject units',
        'a detailed hyperdoc of each unit',
        'share notes, a common calendar, assignments, assessments, & data with a team'
    ]];

    // FUNCTIONS
    const getInfoButtonPElement = ( i=0 ) => <p>{ premiumOptionDescriptions[ i ] }</p>

    const handleSubmit = e => {
        toggleForm();
        addCourse( e );
    }

    // PROPS & ATTRIBUTES
    const props = {
        CloseFormButton: {
            id: `cancel-${ courseID }`,
            label: <SVG type={{ xmark: true }} fill1='orangered'/>,
            onClick: toggleForm,
        },
        CourseNameInput: {
            className: get.classNameFromArr([ styles.input, styles.text ]),
            fontSize: '1.5em',
            inputLabel: 'Course Name',
            inputType: 'text',
        },
        CourseSectionsInputGroup: {
            fieldLabel: 'sections / periods',
            fontSize: '1em',
            inputsLabel: '#',
            inputType: 'number',
        },
        form: { className: styles.form, onSubmit: handleSubmit },
        InfoButton: { arrowDirection: 'left', pElement: <p>for premium users</p> },
        SubmitFormButton: { label: 'ADD COURSE', type: 'submit' },
    };

    props.CloseFormButton.className = props.SubmitFormButton.className = styles.button;
    props.CourseSectionsInputGroup.classPrefix = props.CourseNameInput.inputID = props.form.id = courseID; // form & input have same ID ? sus...
    props.CourseNameInput.isRequired = props.CourseSectionsInputGroup.isRequired = props.CloseFormButton.isSecondary = props.SubmitFormButton.isPrimary = true;

    // COMPONENTS
    const InfoButtons = premiumOptionLabels.map( ( label, i ) => <li
        className={ styles[ 'premium-option' ] }
        key={ `premium-option-for-${ label.split(' ').join( '-' )}` }
    >
        <input type='checkbox' disabled={ isFree }/>
        <label>
            { label } <i className={ styles[ 'premium-logo' ] }> 👑 </i>
            { isFree || <InfoButton arrowDirection='left' pElement={ getInfoButtonPElement( i ) }/>}
        </label>
    </li> );

    // RENDER
    return <form { ...props.form }>
        <div className={ styles[ 'form-colour-pop' ] }/>

        <fieldset className={ styles[ 'fields-and-submit-button' ] }>
            <fieldset className={ styles.fields }>
                <fieldset className={ styles.course }>
                    <Button { ...props.CloseFormButton }/>
                    <TextInputButton { ...props.CourseNameInput }/>
                </fieldset>

                <fieldset className={ styles[ 'sections-and-premium-choices' ] }>
                    <InputGroup { ...props.CourseSectionsInputGroup }/>

                    <fieldset className={ styles[ 'premium-choices' ] }>
                        {/* <header>add</header> */}
                        <ul>{ InfoButtons }</ul>
                    </fieldset>
                </fieldset>
            </fieldset>

            <fieldset className={ styles[ 'submit-button' ] }>
                <Button { ...props.SubmitFormButton }/>
            </fieldset>
        </fieldset>
    </form>
}

export default AddAssignment;