import Logo from './Images/Logo';
import styles from './Footer.module.css';
import { Link } from 'react-router-dom';

const Footer = ({ showLogo=false }) => {
    return <footer className={ styles.footer }>
        <Link to='/privacy'>Privacy Policy</Link>
        { showLogo && <>
            <Logo /> <span>BumbleBoard<sup>TM</sup></span>
        </> }
        <Link to='/'>Terms of Service</Link>
    </footer>
}

export default Footer;