import AttachmentGroup from '../Attachment/AttachmentGroup';
import styles from './Slide.module.css';
import StrToJsx from '../StrToJsx';

const Slide = ({ details={}, i=0 }) => {
    const { visual, directions: text, attachments: links } = details;
    const [ hasImage, hasText, hasLinks ] = [ !!visual, !!text, !!links];

    return <article className={ styles.slide }>
        <h1>step { i + 1 }</h1>
        { !!hasImage && <section className={ styles.image }>
            <img alt={ visual.alt } src={ visual.src } />
        </section> }
        { !!hasText && <section className={ styles.text }>
            <h2>{ StrToJsx( text.title ) }</h2>
            <hr />
            <ul class='fa-ul'>{ text.details.map( ( bullet, j ) => <li key={`step-${ i }-bullet-${ j }`}>
                <span class='fa-li'>
                    <i class='fa-solid fa-square' />
                </span>
                { StrToJsx( bullet ) }
            </li> ) }</ul>
        </section> }
        {/* <hr /> */}
        { !!hasLinks && <section className={ styles.links }>
            <AttachmentGroup linkGroups={ links }/>
        </section> }
    </article>
}

export default Slide;