import { useContext, useEffect, useState } from 'react';
import styles from './WeekContainer.module.css';
import SVG from '../../../modular/Images/SVG';
import HiveContext from '../../../../contexts/HiveContext';
import get from '../../../../constants/get';
import ToolTip from '../../../modular/ToolTip';
import AssignmentTypeSection from './Week/AssignmentTypeSection';
import Droppable from '../../../modular/DragAndDrop/Droppable';

const WeekContainer = ({
    addAssignment = () => {},
    assignments = [],
    board = {},
    idPrefix = 'bar',
    rules = {},
    toggleMode = () => {},
    unit = {}
}) => {
    const { schoolYear, course } = useContext( HiveContext );
    const [ isOpen, setIsOpen ] = useState( getFilteredAssignments().length > 0 );

    const [ boardIsFull, filteredAssignments ] = [
        assignments.every( assignment => assignment.directions ),
        getFilteredAssignments()
    ];

    useEffect( () => {
        window.location.hostname === 'localhost' && console.log('Setting open state...');
        if ( isOpen && filteredAssignments.length === 0 ) setIsOpen( () => false )
        if ( !isOpen && filteredAssignments.length > 0 ) setIsOpen( () => true )
    }, [ filteredAssignments, isOpen ] );

    const [ isBoard, assignmentNum ] = [
        typeof board?.num === 'number',
        filteredAssignments.length
    ];

    const [ hasAssignments, headerSpanText, headerCounter ] = [
        assignmentNum > 0,
        isBoard ? `Week ${ board.num + 1 }` : `${ unit.theme } Resources`,
        !isBoard ? assignmentNum : `${ assignmentNum } / ${ rules.size }`
    ];

    // FUNCTION
    function getAssignmentTypeSection( attributes={}, isForDarkBoxes=false ) {
        attributes.isForDarkBoxes = isForDarkBoxes;
        attributes.assignments = isForDarkBoxes ? assignments.slice( 0, 2 ) : assignments.slice( 2 );

        return <AssignmentTypeSection { ...attributes }/>
    }

    function getDateRange() {
        const week = schoolYear.months[ board.monthNum ].weeks[ board.weekNum ];
        const [ iStart, iEnd ] = [
            week.days.findIndex( day => day === week.daysInClass[ 0 ] ),
            week.days.findIndex( day => day === get.lastArrayElement( week.daysInClass ) ),
        ];

        return [ iStart, iEnd ].map( i => get.date({
            day: week.days[ i ],
            month: week.months[ i ],
            year: schoolYear.months[ week.months[ i ] ].year.full
        }, course.language ) ).join(' - ')
    }

    function getFilteredAssignments() { return assignments.filter( assignment => assignment.directions ) }

    function handleClick() {
        addAssignment({
            target: { id: [ 'cover' + idPrefix, 'board', board.num ].join( '-' ) }
        })
    }

    function toggleAccordeon() { setIsOpen( isOpen => !isOpen ) }

    // PROPS & ATTRIBUTES
    const attributes = {
        Assignments: { toggleMode, idPrefix, showEditButton: true },
        AssignmentTypeSection: {
            toggleMode, unit,
            activeUnitBoardNums: unit.boards,
            // activeUnitBoardNums: Object.keys( unit.boards ).flat(),
            boardNum: board.num,
        },
        cover: {
            className: styles.cover,
            onClick: hasAssignments ? toggleAccordeon : handleClick,
        },
        header: {
            className: get.classNameFromArr([
                styles.header, !hasAssignments && styles.empty
            ]),
        },
        li: {
            style: { '--h': unit.background.h, '--s': unit.background.s },
            id: [ idPrefix, 'board', board.num ].join( '-' ),
            className: get.classNameFromArr([
                styles.week,
                isOpen ? styles.open : styles.collapsed,
                hasAssignments && styles.collapsible,
                hasAssignments && ( isOpen ? styles.open : styles.closed )
            ])
        },
        SVG: {
            type: { add: true },
            fontSize: '1rem',
            fill1: `hsl( ${ unit.background.h }, ${ unit.background.s }, 40% )`
        },
    }

    // COMPONENTS
    const Cover = <div { ...attributes.cover }>{
        !hasAssignments && <span><SVG { ...attributes.SVG }/>New Assignment</span>
    }</div>;

    // RENDER
    return <li { ...attributes.li }>
        <Droppable
            Container={<>
                <header { ...attributes.header }>
                    <span className={ styles.pill }>{ headerCounter }</span>
                    { Cover }
                    <span>{ headerSpanText }</span>
                    <ToolTip
                        hasArrow
                        arrowDirection='down'
                        pElement={ <p className={ styles.date }>{ getDateRange() }</p> }
                        style={{ left: '0', fontSize: '.75em' }}
                    />
                </header>

                <div className={ styles.assignments }>
                    { assignmentNum > 0 && <>
                        { getAssignmentTypeSection( attributes.AssignmentTypeSection, true ) }
                        { getAssignmentTypeSection( attributes.AssignmentTypeSection, false ) }
                    </> }
                    {/* { assignments[ 0 ] ? <ul>{ Assignments }</ul> : <> */}
                    {/* </> } */}
                </div>
            </>}
            id={ [ 'weekbar', 'board', board.num ].join( '-' ) }
            isDisabled={ boardIsFull }
        />
    </li>
}

export default WeekContainer;