import get from '../../../constants/get';
import styles from './AnimatedLogo.module.css';
import Logo from './Logo';

const AnimatedLogo = ({ className='', onClick=null, style={} }) => {
    // ATTRIBUTES
    const attributes = { style, className: get.classNameFromArr([ styles.logo, className ]) };
    if ( onClick !== null ) attributes.onClick = onClick

    // RENDER
    return <div { ...attributes }>
        <Logo isBoardCutOut key='logo-1'/>
        <Logo isJustBee key='logo-2'/>
    </div>
}

export default AnimatedLogo;