import styles from './Assignment.module.css';

const Assignment = ({ details={}, isBar=false }) => {
    const [ totalStudents, totalTurnedIn, dueDate ] = [
        objSum( details.students.total ),
        objSum( details.students.turnedIn ),
        'postedDate' in details ? details.postedDate.split(' ').slice( 1, 3 ).join(' ') : 'N/A',
    ];

    // FUNCTIONS
    function objSum( obj={}, initialValue=0 ) {
        return Object.values( obj ).reduce( ( sum, num ) => sum + num, initialValue )
    }

    // RENDER
    return isBar ? '' : <div className={ styles.assignment }>
        <span className={ styles.title }>{ details.title }</span>
        <span className={ styles.completion }>
            <span>{ totalTurnedIn }</span>
            <span> / </span>
            <span>{ totalStudents }</span>
        </span>
        <span className={ styles['due-date'] }>{ dueDate }</span>
    </div>
}

export default Assignment;