import { useEffect, useState } from 'react';
import get from '../../../../constants/get';
import styles from './ToggleRadio.module.css';

const ToggleRadio = ({
    className = '',
    ids = '',
    isVertical = false,
    groupName = 'group',
    labels = [],
    passValueUp = null,
    startOff = true,
}) => {
    const [ buttonIsOnLeft, setButtonIsOnLeft ] = useState( null );
    useEffect( () => { setButtonIsOnLeft( () => startOff ) }, [ startOff ] );

    // FUNCTIONS
    const getStr = ( type='', label='' ) => {
        if ( typeof label !== 'string' ) return label

        const isOneWord = !label?.includes(' ');
        label = label.toLocaleLowerCase();

        return !isOneWord && type === 'value' ? label
            : label.split(' ').join('-');
    }

    const slideButton = ({ target: { id }}) => {
        setButtonIsOnLeft( buttonIsOnLeft => !buttonIsOnLeft )

        let value = getStr( 'value', labels[ !buttonIsOnLeft ? 1 : 0 ] );
        value = value !== '' ? value : buttonIsOnLeft;

        passValueUp !== null && passValueUp({ target: { id, value } })
    }

    // PROPS & ATTRIBUTES
    const attributes = {
        button: {
            className: buttonIsOnLeft ? styles['on-left'] : '',
            id: 'toggle-slider',
        },
        cover: {
            className: styles.cover,
            id: `cover-${ ids[ 0 ] }`,
            onClick: slideButton,
        },
        div: {
            className: get.classNameFromArr([
                styles[ 'placeholder-label' ],
                labels.some( label => label.length > 0 ) ? '' : styles.empty
            ]),
        },
        fieldset: {
            className: get.classNameFromArr([
                styles[ 'toggle-container' ],
                isVertical ? styles.vertical : styles.horizontal,
                buttonIsOnLeft ? styles.inactive : ''
            ]),
        },
    };

    // COMPONENTS
    const inputs = labels.map( ( label, i ) => [ <input
        checked={ i === 0 ? !buttonIsOnLeft : buttonIsOnLeft }
        id={ getStr( 'id', ids[ i ] ) }
        key={ getStr( 'id', ids[ i ] ) }
        name={ groupName }
        onChange={ slideButton }
        type='radio'
        value={ getStr( 'value', label ) }
    />, <label
        className={ className }
        htmlFor={ getStr( 'id', ids[ i ] ) }
        id={ getStr( 'id', ids[ i ] ) + '-toggled' }
        key={ getStr( 'id', ids[ i ] ) + '-toggled' }
    >{ label }</label> ] )

    // RENDER
    return <fieldset { ...attributes.fieldset }>
        { isVertical && <div className={ styles[ 'toggle-field' ] }></div> }
        { isVertical ? <fieldset className={ styles.inputs }>
            { inputs }
        </fieldset> : inputs }
        {/* { isVertical && <div { ...attributes.div }>
            { labels[ 0 ]?.length > labels[ 1 ].length ? labels[ 0 ] : labels[ 1 ] }
        </div> } */}
        <button { ...attributes.button }/>
        <div { ...attributes.cover }/>
    </fieldset>
}

export default ToggleRadio;