import { useState } from 'react';
import get from '../../../../constants/get';
import Button from '../../Button';
import TextInputButton from '../../TextInputButton';
import Wrapper from '../../Wrapper';
import styles from './Curriculum.module.css';
import SVG from '../../../modular/Images/SVG';
import Pill from '../../../modular/Buttons/Pill';

const noTitleCaseLanguages = [ 'français', 'french', 'español', 'spanish', 'deutsch', 'german' ];
const plusIcon = '✚';

const UnitTile = ({
    department = {},
    fillerI = -1,
    handleButtonFromParent = ( value={} ) => { console.log( `UNIT #${ value.unitI }`, value ) },
    isAddButton = false,
    isExpanded = false,
    isHeader = false,
    isForm = false,
    isPlaceholder = false,
    isInDB = false,
    subject = '',
    level = '',
    unit = {},
    unitI = -1,
}) => {
    const [ editTile, setEditTile ] = useState( false );
    const colour = unit?.background?.hex || '#edd149';
    const { h, s, l } = get.hslFromHex( colour );

    // FUNCTIONS
    function getAddButton() {
        return <Button
            className={ get.classNameFromArr([
                styles[ 'add-button' ],
                isHeader && styles.level
            ]) }
            label={ plusIcon }
            onClick={ toggleEditMode }
        />
    }

    function getLevelChildren() {
        return !isForm ? <Button
            label={ level }
            onClick={ getValuesForParent }
        /> : ( isAddButton && !editTile ) ? getAddButton() : <>
            { !editTile && <Button
                className={ styles[ 'level-edit-button' ] }
                label={ <SVG type={{ edit: true }} noTransition/> }
                onClick={ toggleEditMode }
            /> }
            { !editTile ? <span>{ level }</span> : <TextInputButton
                inputID={ [ subject, isAddButton ? `newLevel${ unitI }` : level, 'input' ].join( '-' ) }
                inputLabel='level'
                uncontrolledValue={ level || '' }
            /> }
            { editTile && <Button
                className={ styles[ 'level-save-button' ] }
                label={ <SVG type={{ save: true }} noTransition/> }
                onClick={ toggleEditMode }
            /> }
        </>
    }

    function getUnitChildren() {
        return fillerI >= 0 ? <div
            className={ styles.filler }
            key={ [ 'level', unitI + 1, 'filler-cell' ].join( '-' ) }
        >&nbsp;</div>
        : ( isAddButton && !editTile ) ? getAddButton()
        : !editTile ? <>
            <i className={ get.classNameFromArr([
                styles.icon,
                unit.icon * 1 ? styles.number : styles.emoji
            ]) }>{ unit.emoji || unit.icon || String.fromCodePoint( parseInt( 2460 + unitI, 16 ) ) }</i>

            <header className={ styles.details }>
                <p className={ styles.title }>
                    <SVG type={{ edit: true }} fontSize='.75em' noTransition/>&nbsp;
                    { noTitleCaseLanguages.some( l => subject.includes( l ) ) ?
                    unit.theme : get.capitalise( unit.theme ) }
                </p>

                <Pill
                    label={ unit.duration?.boards + ' BOARDS' }
                    activeState={ true }
                    className={ styles.duration }
                    noTransition
                    style={{
                        background: `hsl( ${ h }, ${ s }, ${ l } )`,
                        colour: get.textColour( colour )
                    }}
                />

                <Button
                    label={ editTile ? 'save' : '' }
                    backgroundGradientColours={[ 'transparent', 'transparent' ]}
                    onClick={ toggleEditMode }
                    className={ get.classNameFromArr([
                        styles.button,
                        editTile ? styles.save : styles.edit
                    ]) }
                    type={ editTile ? 'submit' : 'button' }
                />
            </header>

            <div className={ styles.badge }>{ unit?.duration?.boards }</div>
        </> : <>
            <TextInputButton
                className={ styles.icon }
                fillAllBorders
                fontSize='.75em'
                inputID={ [ subject, level, 'theme' + unitI, 'icon' ].join( '-' ) }
                inputLabel='icon'
                uncontrolledValue={ unit.emoji || unit.icon || '🐝' }
            />
            <TextInputButton
                className={ styles.topic }
                fontSize='1em'
                inputID={ [ subject, level, 'theme' + unitI, 'theme' ].join( '-' ) }
                inputLabel='theme'
                isRequired
                uncontrolledValue={ unit.theme || '' }
            />
            <Button label='save' onClick={ getValuesForParent } className={ styles[ 'save-button' ] }/>
        </>
    }

    function getValuesForParent( e ) {
        e.preventDefault();
        setEditTile( editTile => !editTile );

        const value = { department, subject, level, unitI, details: {} };
        Array.from( e.target.parentNode.children )
            .filter( Child => Child.tagName === 'FIELDSET' )
            .forEach( Child => value.details[ Child.firstChild.id.split( '-' ).pop() ] = Child.firstChild.value );

        // console.log( `UNIT #${ value.unitI }`, value );
        handleButtonFromParent( value )
    }

    function toggleEditMode() {
        setEditTile( editTile => !editTile )
        if ( isAddButton ) isExpanded = !isExpanded
    }

    // PROPS & ATTRIBUTES
    const props = {
        container: {
            key: ( fillerI < 0 ? [
                subject || 'new',
                subject ? level : 'subject',
                unit?.theme || 'placeholder'
            ] : [ 'level', unitI, 'filler-cell', fillerI ] ).join( '-' ),
            className: get.classNameFromArr([
                styles[ 'tile-container' ],
                !isForm && ( isExpanded ? styles[ 'is-expanded' ] : styles[ 'is-hidden' ] ),
                fillerI >= 0 && styles.filler,
                isHeader && styles.level
            ])
        },
        Wrapper: {
            style: { '--badge-colour': get.textColour( colour ) },
            className: get.classNameFromArr([
                styles.theme,
                isExpanded ? styles[ 'is-expanded' ] : styles[ 'is-hidden' ],
                isPlaceholder && styles.placeholder,
                editTile && styles[ 'edit-mode' ]
            ]),
        }
    };

    [ props.Wrapper.style[ '--h' ], props.Wrapper.style[ '--s' ], props.Wrapper.style[ '--l' ] ] = [ h, s, l ];
    if ( isForm || !isInDB || isPlaceholder ) {
        props.Wrapper.style.border = `3px dashed ${ colour }`
    } else {
        props.Wrapper.style[ '--opacity' ] = unit?.background?.hex ? '.5' : '1'
    }

    // console.log( props.container.key );
    // RENDER
    return <Wrapper
        div={ !isForm }
        fieldset={ isForm }
        key={ props.container.key }
        props={{ className: props.container.className }}
        Children={ fillerI < 0 && <div { ...props.Wrapper }>{
            isHeader ? getLevelChildren() : getUnitChildren()
        }</div> }
    />
}

export default UnitTile;