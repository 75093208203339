import { useContext, useMemo } from 'react';
import UserContext from './contexts/UserContext';
import AccountContext from './contexts/AccountContext';
import firePush from './services/edding/pushFireData';
import courseInfo from './constants/courseInfo';
import get from './constants/get';
import ErrorBoundary from './components/pages/ErrorBoundary';
import fireRead from './services/edding/readFireData';

const Main = ({ data={}, user={}, updateUser=() => {} }) => {
    const { callLogout } = useContext( AccountContext );
    // callLogout();

    // CONTEXTS, PROPS, & ATTRIBUTES
    const userContext = useMemo( () => {
        const addCourse = async ( course={}, courses=[], courseIDs=[] ) => {
            user.courseIDs.queen = courseIDs;
            user.courses.queen = courses;

            firePush.createCourse( course, user, courseIDs );
            // await firePush.createCourse( course, user );
            updateUser( user );
        }

        // API
        const addCourseGuest = async (
            course={},
            email='',
            sectionNum='all',
            role={ isAdmin: false, isTeacher: false, isStudent: false, isParent: false },
            isWorkerBee=true
        ) => {
            const obj = { email, token: get.randomToken( true ), ...role };
            obj.first = obj.last = obj.nickname = null;

            if ( !course.content.students.all ) course.content.students.all = []
            const existingObj = course.content.students.all.find( obj => obj.email === email );

            if ( typeof course.guests === 'string' ) course.guests = [ course.guests ]
            course.guests.push( email );

            // console.log( role, course, email, sectionNum, obj );
            if ( sectionNum && sectionNum !== 'all' ) {
                if ( sectionNum in course.content.students ) {
                    course.content.students[ sectionNum ].push( email )
                } else { course.content.students[ sectionNum ] = [ email ] }
            } else {
                window.location.hostname === 'localhost' && console.log('it\'s the "all" section...');
            }

            await fireRead.findUser( email, true ).then( userObj => {
                if ( userObj?.name ) {
                    // console.log( userObj ); // GET ID FROM DB TOO?
                    obj.first = userObj.name.first;
                    obj.last = userObj.name.last;
                    obj.nickname = userObj.name.nickname;
                    obj.role = userObj.role;

                    if ( 'courseIDs' in userObj === false ) userObj.courseIDs = { queen: [], worker: [] }
                    userObj.courseIDs[ isWorkerBee ? 'worker' : 'queen' ].push( course.id.db );

                    firePush.updateUserObj( userObj.id, 'courseIDs', userObj.courseIDs );
                }
            } );

            if ( existingObj === undefined ) course.content.students.all.push( obj )
            window.location.hostname === 'localhost' && console.log({ existingObj, role, course, email, sectionNum, obj });

            firePush.updateCourseObj( course.id.db, 'guests', email );
            firePush.updateStudentsObj( course.id.db, user.schoolYearID, course.content.students );

            updateUser( user )
        }

        const deleteCourse = async ( courses={}, courseIDs=[], course={} ) => {
            // Delete course from user.courses
            user.courses.queen = courses;

            // Delete course from user.courseIDs
            user.courseIDs.queen = courseIDs;

            // Delete course from database
            await firePush.deleteCourse( course, user, courseIDs );

            updateUser( user );
        }

        // API
        const initiateCourseSubmissions = async ( course={} ) => {
            // delete course.content.submissions;
            course.content.submissions = [];
            await firePush.createSubmissionsDoc( course.id.db, user.schoolYearID, [] );

            updateUser( user );
        }

        // API
        const updateAssignments = async ( assignments=[], board={}, course={} ) => {
            if ( course.content.boards.length < board.num + 1 ) course.content.boards.push( board )
            // return console.log( assignments, board, course )

            if ( Array.isArray( course.content.assignments ) ) course.content.assignments = {};
            course.content.assignments[ board.num ] = assignments;

            // console.log( board.completion );
            [ board.completion.req, board.completion.prereq ] = [
                assignments.filter(
                    assignment => !assignment.isPrereq && ![ ' ', '' ].includes( assignment.directions )
                ).length,
                assignments.filter(
                    assignment => assignment.isPrereq && ![ ' ', '' ].includes( assignment.directions )
                ).length,
            ];
            // console.log( board.completion );

            const placeholderBoards = courseInfo.getPlaceholderBoards( user.counties[ 0 ].schoolYear );

            let j = 0;
            const boards = [];
            course.content.boards.forEach( ( board, i, arr ) => {
                const hasEmptyBoard = j !== i;
                const missingBoardsNum = hasEmptyBoard ? ( i - j ) : 0;

                if ( missingBoardsNum > 0 ) Array( missingBoardsNum ).fill().forEach(
                    e => boards.push( placeholderBoards[ boards.length ] )
                )

                boards.push( board );
                j++;
            } );

            await firePush.updateAssignmentsObj( course.id.db, user.schoolYearID, board.num, assignments );
            await firePush.updateBoardsArr( course.id.db, user.schoolYearID, boards );
            updateUser( user )
        }

        // API
        const updateCheckedAssignments = async ( checkList=[ '' ], boardNum=0, course={}, isFakeWorkerBee=false ) => {
            const userIsActualWorkerBee = user.data.uid !== course.ownerID;

            if ( userIsActualWorkerBee ) {
                const guestChecklist = get.workerBeeSubmissions( boardNum, course.content.submissions, user.email );

                let [ newChecks, newUnchecks ] = [ [], [] ];
                checkList.forEach( boardID => checkList.includes( boardID ) && !guestChecklist.includes( boardID ) && newChecks.push( boardID ) );
                guestChecklist.forEach( boardID => guestChecklist.includes( boardID ) && !checkList.includes( boardID ) && newUnchecks.push( boardID ) );

                [ newChecks, newUnchecks ] = [
                    newChecks.filter( ( id, i ) => newChecks.findIndex( e => e === id ) === i ),
                    newUnchecks.filter( ( id, i ) => newUnchecks.findIndex( e => e === id ) === i ),
                ]
                window.location.hostname === 'localhost' && console.log({ boardNum, guestChecklist, newChecks, newUnchecks, checkList });

                const [ isChecked, isNOTChecked ] = [
                    courseInfo.getSubmissionObj( user.email, newChecks.join( ',' ), true ),
                    courseInfo.getSubmissionObj( user.email, newUnchecks.join( ',' ), false )
                ];

                for ( let i = 0; i < 2; i++ ) {
                    let obj = i === 0 ? isChecked : isNOTChecked
                    window.location.hostname === 'localhost' && console.log( '🟥', { obj } );
                    if ( obj.what ) {
                        obj.isChecked = i === 0;
                        course.content.submissions.push( obj );
                        await firePush.updateSubmissionsObj( course.id.db, user.schoolYearID, obj )
                    }
                }
            } else {
                const assignments = course.content.assignments[ boardNum ];
                assignments.forEach( assignment => assignment[
                    isFakeWorkerBee ? 'isFakeChecked' : 'isChecked'
                ] = checkList.includes( assignment.id ) );

                await firePush.updateAssignmentsObj( course.id.db, user.schoolYearID, boardNum, assignments );
            }

            updateUser( user )
        }

        // API
        const updateBoard = ( boardAssignmentsArr=[], board={}, course={} ) => {
            if ( board === 'all' ) {
                delete course.content.boards;
                course.content.boards = boardAssignmentsArr;

            } else {
                const assignments = course.content.assignments.filter( assignment => assignment.boardNum === board.num );

                // insert to course.content: [ assignments ]
                if ( assignments[ 0 ] ) {
                    assignments.forEach( assignment => assignment = { ...boardAssignmentsArr.find(
                        userAssignment => userAssignment.id === assignment.id
                    ) } )
                } else { course.content.assignments.push( boardAssignmentsArr ) }

                [ board.completion.req, board.completion.prereq ] = [
                    boardAssignmentsArr.filter( assignment => !assignment.isPrereq && assignment.isChecked ),
                    boardAssignmentsArr.filter( assignment => assignment.isPrereq && assignment.isChecked ),
                ];
            }

            return updateUser( user );
        }

        const updateBoardMerge = ( board={}, increment=-1 ) => {
            board.isMergedWith = typeof board.isMergedWith === 'number' ? null : board.num + increment;
            return updateUser( user )
        }

        const updateBoardPrivacy = ( board={} ) => {
            board.isPrivate = !board.isPrivate;
            return updateUser( user )
        }

        const updateCourseUnits = async ( course={}, newUnits={}, schoolYearID='' ) => {
            delete course.content.units;
            course.content.units = newUnits;

            await firePush.pushUnits( course, newUnits, schoolYearID );
            return updateUser( user )
        }

        const updateUserObj = ( keys=[ '' ], values=[ ] ) => {
            keys.forEach( ( key, i ) => {
                if ( key.includes( '.' ) ) {
                    const keyElements = key.split( '.' );
                    user[ keyElements[ 0 ] ][ keyElements[ 1 ] ] = values[ i ];

                } else { user[ key ] = values[ i ] }

            } );
            updateUser( user );
        }

        if ( 'uid' in data === false ) data.uid = null


        return {
            ...user,
            data,
            addCourse,
            addCourseGuest,
            callLogout,
            deleteCourse,
            initiateCourseSubmissions,
            updateAssignments,
            updateBoard,
            updateBoardMerge,
            updateBoardPrivacy,
            updateCheckedAssignments,
            updateCourseUnits,
            updateUserObj,
        }
    }, [ callLogout, data, updateUser, user ] );

    // RENDER
    return <main>
        <UserContext.Provider value={ userContext }>
            <ErrorBoundary/>
        </UserContext.Provider>
    </main>
}

export default Main;