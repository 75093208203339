import { useState } from 'react';
import AttachmentContainer from '../../Attachment/AttachmentContainer';
import AttachmentInput from './AttachmentInput';
import styles from './FillBoardBar.module.css';
import TextInput from './TextInput';
import ToggleRadio from './ToggleRadio';

const FillBoardBar = ({ details={}, id='', isActiveAssignment=false }) => {
    const [ barDetails, setBarDetails ] = useState({
        directions: details.directions,
        isGraded: details.isGraded,
        isPrereq: details.isPrereq,
        links: details.links,
        points: details.points,
        submissions: details.submissions,
        title: details.title,
        type: details.type,
    });
    const [ passContainerDown, thereIsALink ] = [ false, barDetails.links.length === 1 ];

    // FUNCTIONS
    const handleProps = ( propsObj={} ) => {
        for ( let component in propsObj ) {
            if ( 'common' in propsObj[ component ] ) {
                for ( let type in propsObj[ component ] ) {
                    if ( type !== 'common' ) propsObj[ component ][ type ] = {
                        ...propsObj[ component ][ type ],
                        ...propsObj[ component ].common
                    }
                }
            }
        }
    }

    const handleUserInput = ({ target: { id, value } }) => {
        let key = id.split( '[' ).pop();
        const pointsEqualsZero = key.includes( 'points' ) && value < 1;

        key = key.includes( 'true' ) || key.includes( 'false' ) || pointsEqualsZero ?
            'isGraded' : key.split( ']' )[ 0 ];
        value = key === 'links' ? [ ...barDetails.links, value ]
            : pointsEqualsZero ? false : value;
        // console.log( key, value );
        // console.log( id, key, value );

        if ( key in barDetails ) setBarDetails( barDetails => ({ ...barDetails, [ key ]: value }) )
    }

    const removeAttachment = ({ target: { id }}) => setBarDetails( barDetails => {
        const i = barDetails.links.findIndex( e => id.split( '[' ).pop().includes( e ) );
        const slicedLinks = i === 0 ? barDetails.links.slice( 1 )
            : [ ...barDetails.links.slice( 0, i ), ...barDetails.links.slice( i + 1 ) ];

        return { ...barDetails, links: slicedLinks }
    } )

    function getAttachmentContainer( links=[] ) {
        return <AttachmentContainer
            asSquares
            className={ styles[ 'attachment-container' ] }
            getResetButton={ getResetButton }
            id={ `${ id }[attachments]` }
            links={ links }
            stackVertically
        />
    }

    function getResetButton( value='', className=styles.reset ) {
        return <button
            className={ className }
            onClick={ removeAttachment }
            type='button'
            id={ `${ id }[attachment][${ value }]` }
        ><i
            className='fa-solid fa-xmark'
            id={ `${ id }[remove][${ value }]` }
        /></button>
    }

    // PROPS & ATTRIBUTES
    const props = {
        AttachmentInput: {
            attachmentContainer: getAttachmentContainer(),
            asSquares: true,
            // className: styles.attachments,
            id: `${ id }[attachments]`,
            links: barDetails.links,
            passAttachmentsUp: handleUserInput,
        },
        TextInput: {
            common: {
                isDisabled: false,
                isRequired: false,
                passValueUp: handleUserInput,
                passTargetUp: true,
                transformLabel: 'hide',
            },
            directions: {
                autofocus: isActiveAssignment,
                className: styles.directions,
                id: `${ id }[directions]`,
                label: 'brief directions',
                name: `${ id }[directions]`,
                type: 'textarea',
                value: barDetails.directions
            },
            points: {
                className: styles.value,
                id: `${ id }[points]`,
                label: 'points',
                name: `${ id }[points]`,
                type: 'number',
                value: barDetails.points
            },
        },
        ToggleRadio: {
            className: styles.toggle,
            ids: [
                `${ id }[isGraded][true]`,
                `${ id }[isGraded][false]`
            ],
            isVertical: true,
            groupName: '',
            labels: [ '', '' ],
            passValueUp: handleUserInput,
            startOff: !barDetails.isGraded,
        }
    };

    handleProps( props );
    if ( passContainerDown ) props.AttachmentInput.attachmentContainer = getAttachmentContainer( barDetails.list )

    // RENDER
    return <fieldset className={ styles.bar }>
        <legend><h3>{ isActiveAssignment ? '! ' : ''}{ details.title.slice( 6 ) }</h3></legend>
        <fieldset className={`${ styles.details } ${ barDetails.links.length < 2 ? styles[ 'with-button' ] : styles[ 'sans-button' ] }`}>
            <TextInput { ...props.TextInput.directions }/>
            <fieldset className={ styles[ 'points-and-attachments' ] }>
                <fieldset className={ styles.points }>
                    <ToggleRadio { ...props.ToggleRadio }/>
                    { barDetails.isGraded ? <TextInput { ...props.TextInput.points }/>
                        : <span className={ styles.ungraded }>not graded</span> }
                    { barDetails.isGraded && <span>Points</span> }
                </fieldset>
                <fieldset className={ styles.attachments }>
                    { barDetails.links.length < 2 && <AttachmentInput { ...props.AttachmentInput }/> }
                    <div className={ styles.squares }>
                        { thereIsALink && <i className={`${ styles.chain } fa-solid fa-chain`}/> }
                        { getAttachmentContainer( barDetails.links ) }
                    </div>
                </fieldset>
            </fieldset>
        </fieldset>
    </fieldset>
}

export default FillBoardBar;