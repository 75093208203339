import { useState, useContext } from 'react';
import { useNavigate } from "react-router-dom";
import styles from './SignIn.module.css';
import UserContext from "../../../contexts/UserContext";
import AccountContext from '../../../contexts/AccountContext';
import get from '../../../constants/get';
import Nav from '../Nav';
import SignInButton from './SignInButton';
import TextInput from '../../modular/Form/Parts/TextInput';
import Button from '../Button';

const SignIn = ({ toCreateAccount=false }) => {
    const navigate = useNavigate();
    document.title = `BumbleBoard | ${ toCreateAccount ? 'Sign Up' : 'Log In' }`;

    const user = useContext( UserContext );
    const account = useContext( AccountContext );

    const [ inputs, setInputs ] = useState({ email: '', password: '', username: '', recoveryEmail: '' });
    // const [ accountIs, setAccountIs ] = useState({
    //     new: toCreateAccount,
    //     inRecovery: { current: false, prev: null },
    // }); // useState( true )

    const storedEmail = localStorage.emailForSigningIn;
    const isEmailLinkLoginReturn = window.location.href.includes( '/login/link' );

    // FUNCTIONS
    // login / logout
    function callEmailLogin() {
        window.location.hostname === 'localhost' && console.log( 'My Login Info is...')
        window.location.hostname === 'localhost' && console.log( `👤 USERNAME: ${ inputs.username }\n📧 EMAIL: ${ inputs.email }\n🔐 PASSWORD: ${ inputs.password }` );
        setInputs( inputs => {
            account.emailLogin( inputs.email, inputs.password );

            return resetInputs()
        } );
    }

    // function callEmailPasswordRecover() {
    //     console.log( `Retrieving Password Recovery for ${ inputs.recoveryEmail }` );
    //     setInputs( inputs => {
    //         account.emailPasswordRecover( inputs.recoveryEmail );
    //         setAccountIs( accountIs => ({ ...accountIs, inRecovery: {
    //             current: false, prev: accountIs.inRecovery.current
    //         } }) )
    //         return resetInputs()
    //     } );
    // }

    // function callEmailRegister() {
    //     // setInputs('')
    //     setInputs( inputs => {
    //         account.emailRegister( inputs.username, inputs.email, inputs.password );
    //         callGetAuthState()
    //         callProfilePage()

    //         return resetInputs()
    //     } );
    // }

    function callGoogleLogin( e ) { account.handleLogin( e.credential ) }

    function callProfilePage() { if ( user.data.uid ) return navigate( '/' + get.username( user.data.email ) ) }

    function resetInputs() {
        const emptyInputs = Object.fromEntries(
            Object.keys( inputs ).map( key => [ key, '' ] )
        );
        return emptyInputs
    }

    // function signIn( e ) {
    //     e.preventDefault();
    //     //authenticate user
    //     // Possibly dlet e/ replace by login / logout function
    // }

    // const googleAuth = useGoogleLogin({
    //     onSuccess: async tokenResponse => {
    //         console.log( tokenResponse );
    //         const userInfo = await axios.get(
    //             'https://www.googleapis.com/oauth2/v3/userinfo',
    //             { headers: { Authorization: `Bearer ${ tokenResponse.access_token }` } }
    //         );
    //         console.log( userInfo );
    //     },
    //     onError: errorResponse => console.log( '❌ ERROR LOGGING IN', errorResponse )
    // })

    // Other functions
    function handleInputChange({ target: { name, value } }) {
        setInputs( inputs => ({ ...inputs, [ name ]: value }) )
    }

    function handleProps( props={}, isSubLoop=false ) {
        for ( let prop in props ) {
            if ( isSubLoop ) {
                Object.keys( props.common ).forEach( commonProp => {
                    const commonPropAlreadySet = commonProp in props[ prop ];
                    if ( !commonPropAlreadySet ) props[ prop ][ commonProp ] = props.common[ commonProp ]
                } );
            } else if ( 'common' in props[ prop ] ) {
                handleProps( props[ prop ], true );
            }
        }
    }

    // function toggleRecoveryEmailInput() {
    //     setAccountIs( accountIs => {
    //         const resetState = !accountIs.inRecovery.current && accountIs.inRecovery.prev;

    //         return { ...accountIs, inRecovery: {
    //             current: resetState ? false : !accountIs.inRecovery.current,
    //             prev: resetState ? null : accountIs.inRecovery.current
    //         } }
    //     } )
    // }

    // const toggleSignIn = () => setAccountIs( accountIs => ({
    //     ...accountIs, new: !accountIs.new
    // }) ) // !!!!!!!!!!!!!!!!

    // const getButton = ( details=[], components=[] ) => details[ 0 ] ? getButton(
    //     details.slice( 1 ), [ ...components, <button
    //         key={`button-${ components.length }`}
    //         onClick={ details[ 0 ].onClick }
    //         type={ details[ 0 ].type }
    //     >{[
    //         details[0].alt ? <img
    //             alt={ details[ 0 ].alt }
    //             key='icon-img'
    //             src={ details[ 0 ].src }
    //         /> : <i
    //             className={ details[ 0 ].iconClass }
    //             key='icon-i'
    //         />,
    //         details[ 0 ].label
    //     ]}</button> ]
    // ) : components

    // CONTEXTS, PROPS, & ATTRIBUTES
    const attributes = {
        button: {
            common: {
                type: 'button'
            },
            callEmailLogin: {
                type: 'submit',
                label: 'SIGN IN',
                isPrimary: true,
                onClick: callEmailLogin,
            },
            callEmailPasswordRecover: {
                key: 'recover-password-button',
                // onClick: callEmailPasswordRecover,
            },
            callProfilePage: {
                className: styles[ 'to-profile-page' ],
                isPrimary: false,
                key: 'go-to-prof-page-button',
                label: 'Go to Profile Page',
                onClick: callProfilePage
            },
            confirmation: {
                className: styles.confirmation,
                key: 'recover-password-again-button',
                // onClick: toggleRecoveryEmailInput,
            },
            googleSignIn: {
                alt: 'Google icon',
                label: 'Sign in with Google',
                onClick: callGoogleLogin,
                src: 'https://companieslogo.com/img/orig/GOOG-0ed88f7c.png?t=1633218227',
            },
            toggleRecoveryEmailInput: {
                key: 'toggle-email-input-button',
                // onClick: toggleRecoveryEmailInput
            },
            toggleSignIn: {
                // onClick: toggleSignIn
            },
        },
        input: {
            common: {
                onChange: handleInputChange,
                required: true,
            },
            email: {
                className: get.classNameFromArr([
                    styles.input,
                    styles[ inputs.email ? 'input-has-value' : 'empty-input' ],
                ]),
                value: inputs.email,
            },
            password: {
                className: get.classNameFromArr([
                    styles.input,
                    styles[ inputs.password ? 'input-has-value' : 'empty-input' ],
                ]),
                value: inputs.password,
            },
            recoveryEmail: {
                className: get.classNameFromArr([
                    styles.input,
                    styles[ inputs.recoveryEmail ? 'input-has-value' : 'empty-input' ],
                ]),
                key: 'recovery-email-input',
                value: inputs.recoveryEmail,
                required: false,
            },
            username: {
                className: get.classNameFromArr([
                    styles.username,
                    styles[ inputs.username ? 'input-has-value' : 'empty-input' ],
                ]),
                value: inputs.username,
            }
        }
    };

    attributes.input.email.id = attributes.input.email.name = attributes.input.email.type = attributes.input.email.autoComplete = attributes.input.email.key = attributes.input.recoveryEmail.autoComplete = 'email';
    attributes.input.password.id = attributes.input.password.name = attributes.input.password.type = attributes.input.password.autoComplete = 'password';
    attributes.input.username.id = attributes.input.username.name = attributes.input.username.type = attributes.input.username.autoComplete = 'username';
    attributes.input.recoveryEmail.id = attributes.input.recoveryEmail.name = attributes.input.recoveryEmail.type = 'recoveryEmail';
    handleProps( attributes );

    // COMPONENTS
    // const RecoveryEmailInput = <input { ...attributes.input.recoveryEmail }/>;
    // const [ CallProfileButton, TogglePasswordRecovery, SubmitRecoveryEmail, RecoverPasswordButton ] = [
    //     <CallToAction { ...attributes.button.callProfilePage }/>,
    //     <button { ...attributes.button.toggleRecoveryEmailInput }>Forgot your<br />email/password ?</button>,
    //     <button { ...attributes.button.callEmailPasswordRecover }><i className='confirmation fa-solid fa-right-long'/></button>,
    //     <button { ...attributes.button.confirmation }>Recovery details sent!</button>
    // ];

    // const [ EmailInputDiv, PasswordInputDiv, UsernameInputDiv ] = [
    //     <div className={ styles.email }>
    //         <input { ...attributes.input.email }/>
    //         <label htmlFor='email'>Email</label>
    //         <i className={`${ styles.icon } fa-solid fa-at`}/>
    //     </div>,
    //     <div className={ styles.password }>
    //         <input { ...attributes.input.password }/>
    //         <label htmlFor='password'>Password</label>
    //         <i className={`${ styles.icon } fa-solid fa-key`}/>
    //     </div>,
    //     <div className={ styles.username }>
    //         <input { ...attributes.input.username }/>
    //         <label htmlFor='username'>Username</label>
    //         <i className={`${ styles.icon } fa-solid fa-at`}/>
    //     </div>
    // ];
    // console.log( isEmailLinkLoginReturn, window.location.href );

    const ActionZone = <section className={ styles['action-zone'] }>{
        isEmailLinkLoginReturn ? <form
            className={ styles.email }
            onSubmit={ e => { e.preventDefault(); account.handleLogin( '', storedEmail ) } }
        >
            { storedEmail || <TextInput
                className={ styles.input }
                label='email'
                transformLabel='to-top'
            /> }
            <Button label='sign in' type='submit'/>
        </form> : <>
            <SignInButton isGoogle isMobile={ user.isMobile }/>
            <SignInButton toCreateAccount={ toCreateAccount } isMobile={ user.isMobile }/>
        </>
        // <div>{ getButton( [ attributes.button.googleSignIn ] ) }</div>
        // <hr/>
        // <form onSubmit={ signIn }>
        //     <fieldset className={ styles.credentials }>
        //         { accountIs.new && UsernameInputDiv }
        //         { EmailInputDiv }
        //         { PasswordInputDiv }
        //         <div><CallToAction { ...attributes.button.callEmailLogin }/></div>
        //     </fieldset>
        // </form>
    }</section>;

    // RENDER
    return <article className={ styles['sign-in'] }>
        <Nav
            brandLinkTo='/'
            centreBrand
            rightIcon=''
        />
        { ActionZone }
        {/* <div className={ styles.options }> */}
            {/* <section className={ styles['reading-zone'] }>
                { user.data.uid ? CallProfileButton : <h2>Sign { accountIs.new ? 'Up' : 'In'}</h2> }
                { ![ null, '' ].includes( user.data.uid ) || <button { ...attributes.button.toggleSignIn }>
                    { accountIs.new ? 'Already have an account ' : 'Are you a new user ' }?
                </button> }
                { !accountIs.new && [
                    <br key='line-break-1'/>,
                    accountIs.inRecovery.current ? RecoveryEmailInput : !accountIs.inRecovery.prev ? TogglePasswordRecovery : '',
                    ( accountIs.inRecovery.current || !accountIs.inRecovery.prev ) && <br key='line-break-3'/>,
                    accountIs.inRecovery.prev ? RecoverPasswordButton : accountIs.inRecovery.current ? SubmitRecoveryEmail : ''
                ] }
            </section> */}
            {/* { ActionZone } */}
        {/* </div> */}
    </article>
}

export default SignIn;