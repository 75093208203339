import { useContext, useMemo, useState } from 'react';
// import BoardView from './BoardView';
import styles from './BoardView.module.css';
import HiveContext from '../../../../contexts/HiveContext';
import get from '../../../../constants/get';
import UserContext from '../../../../contexts/UserContext';
import { useParams } from 'react-router-dom';
import CopyToClipboard from '../../../modular/Buttons/CopyToClipboard';
import BoardProgress from './BoardProgress';
import Board from '../../../modular/Board/Board';

const GuestBoard = () => {
    let { boardNum: urlBoardNum } = useParams();
    const user = useContext( UserContext );
    const { course, currentBoard, schoolYear, currentWeekNum } = useContext( HiveContext );

    urlBoardNum -= 1; // index starts at 0, but URL will show the human number ( starts at 1)
    const urlBoard = course.content.boards[ urlBoardNum ];
    const urlWeekNum = urlBoard.weekNum;

    const [ currentBoardColour, setCurrentBoardColour ] = useState( get.randomHSL( false ) );
    const [ board, setBoard ] = useState({
        today: {
            board: currentBoard,
            quarter: getQuarter( currentWeekNum ),
        },
        current: {
            checked: getCheckedList( urlBoardNum !== currentBoard.num ? urlBoardNum : currentBoard.num ),
            board: urlBoardNum !== currentBoard.num ? urlBoard : currentBoard,
            quarter: getQuarter( urlBoardNum !== currentBoard.num ? urlWeekNum : currentWeekNum ),
        },
    });
    document.title = `👑 Board ${ board.current.board.num } ( ${ course.title } )`;

    const { h, s, l } =  useMemo( () => { return get.randomHSL( false ) }, [] );
    const lang = course.language;
    const boardObj = board.current.board;

    const unit = useMemo( () => {
        return get.unit( boardObj, course, schoolYear ) || { background: { h, s, l } }
    }, [ h, s, l, boardObj, course, schoolYear ] );

    // const submissions = get.workerBeeSubmissions( boardObj.num, course.content.submissions, user.email );
    const assignments = `${ boardObj.num }` in course.content.assignments ? course.content.assignments[ boardObj.num ]
        : get.boardAssignments( course, boardObj.num, get.dueDayMonthYear( schoolYear.months, boardObj ) );

    // FUNCTIONS
    function changeColour() { setCurrentBoardColour( () => get.randomHSL( false ) ) }

    function changeQuarter({ target: { id } }) {
        const quarterID = id.split( '-' ).pop() * 1;
        const quarter = schoolYear.quarters.find( quarter => quarter.id === quarterID );

        setBoard( board => ({ ...board, current: {
            quarter, checked: [],
            board: course.content.boards.find( boardObj => boardObj.weekNum === quarter.weeks[ 0 ] )
        } }) )
    }

    function changeWeek({ target : { id } }) {
        setBoard( board => {
            let boardNum = id.split( '-' ).pop() * 1;
            const newBoard = course.content.boards[ boardNum ];

            return { ...board, current: {
                checked: getCheckedList( boardNum ),
                board: newBoard,
                quarter: getQuarter( newBoard.weekNum )
            } }
        } )
    }

    function checkBox({ target: { id } }) {
        setBoard( board => {
            const [ checkedList, spliceI ] = [
                board.current.checked,
                board.current.checked.findIndex( boxID => boxID === id ),
            ];

            const checked = spliceI > 0 ? [
                ...checkedList.slice( 0, spliceI ), ...checkedList.slice( spliceI + 1 )
            ] : spliceI === 0 ? checkedList.slice( 1 ) : [ ...checkedList, id ];

            return { ...board, current: { ...board.current, checked } }
        } );
    }

    function getCheckedList( boardNum=0 ) {
        return get.workerBeeSubmissions( boardNum, course.content.submissions, user.email, true )
    }

    function getIndex( searchArr=[], searchElement ) { return searchArr.findIndex( e => e === searchElement ) }

    function getQuarter( weekNum=0 ) { return schoolYear.quarters.find( quarter => quarter.weeks.includes( weekNum ) ) }

    // function getUnit( board={} ) {
    //     course.content.units.filter( unit => {
    //         let { days: { even: evenDays, odd: oddDays } } = unit;
    //         let week = schoolYear.months[ board.monthNum ].weeks[ board.weekNum ];

    //         let [ weekMonths, weekDays ] = [ week.months, week.days ];
    //         let daysArr = [ oddDays, evenDays ];

    //         // for each day-month pair in a week, if any pair is in this unit's days, this is the right unit
    //         return weekMonths.find( ( month, i ) => daysArr.find(
    //             daysObj => month in daysObj && daysObj[ month ].includes( weekDays[ i ] )
    //         ) ) !== undefined
    //     } );
    // }

    function getWeekStartAndEnd() {
        const { monthNum, weekNum } = boardObj;
        const week = schoolYear.months[ monthNum ].weeks[ weekNum ];

        const [ startI, endI ] = [
            getIndex( week.days, week.daysInClass[ 0 ] ),
            getIndex( week.days, week.daysInClass.slice().pop() )
        ];

        let [ startMonth, endMonth ] = [ week.months[ startI ], week.months[ endI ] ];
        let [ startDay, endDay ] = [ week.days[ startI ], week.days[ endI ] ];
        [ startMonth, endMonth ] = [
            get.monthName( startMonth, course.locale, true ),
            get.monthName( endMonth, course.locale, true ),
        ];

        return [
            [ startMonth, get.ordinalNum( startDay, lang ) ].join(' '),
            [ endMonth, get.ordinalNum( endDay, lang ) ].join(' '),
        ].join(' - ')
    }

    // PROPS, & ATTRIBUTES
    const attributes = {
        aside: {
            className: styles.sidebar,
            style: { '--h': h, '--s': s, '--l': l },
            // style: { '--h': h, '--s': s, '--l': l, '--sidebar-text-colour': textColour },
        },
        div: {
            className: get.classNameFromArr([
                styles[ 'share-status' ],
                boardObj.isPrivate ? styles.private : styles.shared
            ]),
        },
        button: { className: styles.status },
        CopyToClipboard: {
            className: styles.share,
            url: [ 'www.bumble-board.com', user.data.uid, 'boards', board.current.board.num, 'shared' ].join( '/' ), // boardID should be generated so link bullseyes to it and our database can easily find it
        },
    };

    // COMPONENTS
    const BoardComponent = useMemo( () => {
        window.location.hostname === 'localhost' && console.log( 're-render...' );
        const CheckBoxes = Array( 9 ).fill().map( ( e, i ) => i === 4 ? null : <input
            defaultChecked={ assignments[ i < 4 ? i : i - 1 ].isChecked }
            id={ assignments[ i < 4 ? i : i - 1 ].id }
            key={ assignments[ i < 4 ? i : i - 1 ].id }
            onChange={ checkBox }
            type='checkbox'
        /> );

        return <Board
            assignmentsArr={ assignments }
            board={ board.current.board }
            checkboxes={ CheckBoxes }
            checkList={ board.current.checked }
            unit={ unit }
        />
    }, [ assignments, board, unit ] );

    const QuarterSubMenu = <ul className={ styles[ 'sub-menu' ] }>{ schoolYear.quarters.map(
        quarter => quarter.id !== board.current.quarter.id && <li
            id={`quarter-option-${ quarter.id }`}
            key={`quarter-option-${ quarter.id }`}
            onClick={ changeQuarter }
        >{ quarter.id }<sup id={`ordinal-suffix-${ quarter.id }`}>{ get.ordinalSuffix( quarter.id, lang ) }</sup>
        </li>
    ) }</ul>;

    const SidebarMenu = <ul>
        <li className={`${ styles.quarter } ${ styles[ 'sub-menu-trigger' ] }`}>
            <span>{ board.current.quarter.id }<sup>{ get.ordinalSuffix( board.current.quarter.id, lang ) }</sup></span> quarter
            { QuarterSubMenu }
        </li>
        { board.current.quarter.weeks.map(
            weekNum => course.content.boards.find( board => board.weekNum === weekNum )
        ).filter( e => e ).map( boardObj => {
            let unit = get.unit( boardObj, course, schoolYear );
            let { h, s, l } = unit?.background || currentBoardColour;
            let textColour = get.textColour( unit?.background || currentBoardColour );
            // let styleObj = { '--h': h, '--s': s, '--l': l };
            let styleObj = { '--h': h, '--s': s, '--l': l, '--week-text-colour': textColour };

            return <li
                className={`${ styles.week } ${ board.current.board.num === boardObj.num ? styles.active : '' }`}
                id={`week-${ boardObj.num }`}
                key={`week-${ boardObj.num }`}
                onClick={ changeWeek }
                style={ styleObj }
            ><span id={`label-week-${ boardObj.num }`}>week { boardObj.num + 1 }</span></li>
        } ) }
    </ul>;

    // RENDER
    return <div className={ styles.container }>
        <aside>
            <nav>{ SidebarMenu }</nav>
            <button onClick={ changeColour }>CHANGE COLOUR</button>
        </aside>
        <section className={ styles.board }>
            <header>
                <h2>
                    week { boardObj.num + 1 }
                    <div { ...attributes.div }>
                        <CopyToClipboard { ...attributes.CopyToClipboard }/>
                    </div>
                </h2>
                <p>
                    { getWeekStartAndEnd() }<br />
                    { get.ordinalNum( board.current.quarter.id, lang ) } Quarter<br />
                    { unit.theme && <><span>Unit:</span> { unit.theme }</> }
                </p>
            </header>
            <BoardProgress checkedList={ board.current.checked } hsl={ currentBoardColour }/>
            { BoardComponent }
        </section>
    </div>
}

export default GuestBoard;