import { useCallback, useMemo } from 'react';
import { auth, db, getAuthState, logout, sendPasswordReset } from './firebase_setup/firebase';
import { signUserInWithEmailLink, signInWithGoogleV0 } from './services/user/authenticationServices';
import { useLocation, useNavigate } from 'react-router-dom';
import AccountContext from './contexts/AccountContext';
import Main from './Main';
import fireRead from './services/edding/readFireData';
import firePush from './services/edding/pushFireData';
import courseInfo from './constants/courseInfo';
import './App.css';
import get from './constants/get';
import { getDoc, doc } from 'firebase/firestore';
import { GoogleOAuthProvider } from '@react-oauth/google';
import { signOut } from 'firebase/auth';

const AuthProvider = ({ localStorageVar='', updateUser=() => {}, user={}, schoolYearID='' }) => {
    const navigate = useNavigate();
    const location = useLocation();

    const isLocalHost = window.location.href.includes( 'localhost' );
    const HIDETHIS = isLocalHost ? '329103355826-g5i5uh3hnrjurebreg0of5bj78du7fcg.apps.googleusercontent.com'
        : '53603342886-5102dmmbbe96dlfml4rodh2u4pghl258.apps.googleusercontent.com';

    // FUNCTIONS
    const resetStorage = useCallback( () => { return localStorage.removeItem( localStorageVar ) }, [ localStorageVar ] )

    // CONTEXTS, PROPS, & ATTRIBUTES
    const accountContext = useMemo( () => {
        const { data } = user;

        const callLogout = async () => {
            // debugger;
            // auth.preventDefault();
            // window.location.reload( false );
            // console.log( 'logging out' );
            try {
                await signOut( user.data.auth ).then( () => {
                    window.location.hostname === 'localhost' && console.log( '1️⃣' );
                } );
            } catch( error ) {
                window.location.hostname === 'localhost' && console.log( '2️⃣' );
                await logout( user.data.auth );
            }

            resetStorage();
            updateUser( { data: { uid: null } } );
            navigate( '/' );
        }

        const emailPasswordRecover = async ( email = '' ) => await sendPasswordReset( email )

        // const emailRegister = async ( name='', email='', password='' ) => await registerWithEmailAndPasswordV0( name, email, password )

        const getOrigin = ( email='' ) => location.state?.from?.pathname || `/${ get.username( email ) }`;

        const parseUserObjOnLogin = ( user={} ) => {
            // find out if user email is in db/customers docs FOR CURRENT ACCOUNTS
            const [ isNewUser, isSendingEmail ] = [ Array.isArray( user ), user === null ];
            // console.log( '🟥', isNewUser ? 'NEW user\n' : 'current user\n', user );
            // console.log( isSendingEmail ? 'sending an email' : 'authenticating email link' )

            // update user state here that tracks stripeID
            if ( !isSendingEmail ) {
                updateUser( isNewUser ? user[ 0 ] : user )
                getAuthState( auth, () => handleUserChange( user ) )
            }

        }

        const handleLogin = async ( credential='', email='', password='' ) => {
            const isGoogleLogin = email === '' && password === '';

            switch ( isGoogleLogin ) {
                case true:
                    await signInWithGoogleV0( credential ).then( user => parseUserObjOnLogin( user ) );
                    break;
                case false:
                    await signUserInWithEmailLink( email ).then( user => parseUserObjOnLogin( user ) );
                    break;

                default: console.error( '❌ Error with login' ); break;
            }
            // isGoogleLogin ? await signInWithGoogleV0( credential ).then( user => {
            //     // find out if user email is in db/customers docs FOR CURRENT ACCOUNTS
            //     const isNewUser = Array.isArray( user );
            //     if ( isNewUser ) user = user[ 0 ]
            //     console.log( '🟥', isNewUser ? 'NEW user\n' : 'current user\n', user );

            //     // update user state here that tracks stripeID
            //     updateUser( user );

            //     // THEN do the below
            //     isNewUser ? navigate(
            //         `mrsbates/login/${ get.username( user.data.email ) }`,
            //         { state: user }
            //     ) : getAuthState( auth, () => handleUserChange( user ) )
            // }) : isLinkLogin ? await signUserInWithEmailLink( email ).then( response => {
            //     window.localStorage.setItem( 'emailForSigningIn', email )
            // }).catch( error => console.log( '❌', error ) ) : await logInWithEmailAndPassword();
        }

        const handleUserChange = async ( userObj={} ) => {
            // console.log( '🟧', userObj );
            const isNewUser = Array.isArray( userObj );
            if ( isNewUser ) userObj = userObj[ 0 ]

            navigate( getOrigin( userObj.data?.email ) );
            if ( userObj.plan && !userObj.plan.isFree ) userObj.premium = courseInfo.getPremiumObj( '', '', '', 'en-US' )

            if ( !isNewUser ) {
                const isInDB = { userID: false, email: false };

                await Promise.all([
                    getDoc( doc( db, 'users', userObj.data?.uid ) ),
                    fireRead.findUser( userObj.data?.email )
                ]).then( arr => {
                    isInDB.userID = arr[ 0 ].exists();
                    isInDB.email = arr[ 1 ];

                    // console.log( arr );
                    // console.log( `🐝 ${ userObj.data?.uid } is ${ isInDB.userID ? '' : 'NOT ' }in the database` );
                    // console.log( `2️⃣ ${ userObj.data?.email } is ${ isInDB.email ? 'an old' : 'a new' } user` );
                });

                try {
                    if ( isInDB.userID ) {
                        await fireRead.findUserByID(
                            userObj.data.uid, !userObj.plan.isFree, schoolYearID
                        ).then( userDBData => updateUser({ ...userObj, ...userDBData }) )

                    } else if ( isInDB.email ) {
                        await fireRead.findUser( userObj.data?.email, true ).then( async userDBData => {
                            userObj.courseIDs = userDBData.courseIDs;
                            userObj.createdOn = userDBData.createdOn;

                            await initiateNewUser( userObj );
                        } );
                    }

                    // GET PREMIUM
                    // fireRead.findUserByID( userObj.data.uid, true, schoolYearID )
                    //     .then( premium => updateUser({ ...userObj, premium }) );

                    // fireRead.findOwnedCourses( userObj.data.uid, schoolYearID ).then( courses => {
                    //     userObj.courseIDs = [ ...userObj.courseIDs, ...courses?.map( course => course.id.db ) ];
                    //     updateUser({ ...userObj, courses: userObj.courses ? [ ...userObj.courses, courses ] : courses })
                    // });

                    // console.log( `✅ Success! Pulled fireData from database` );
                } catch( error ) { console.error( `❌ ERROR! Could not pull fireData from database`, error?.message, error, isInDB, userObj ) }
            } else { initiateNewUser( userObj ) }
        }

        async function initiateNewUser( data={} ) {
            // const [ plan, role, position ] = [
            //     data.email.includes( 'bumbleboard' ) ? { isPremium: true } : { isFree: true },
            //     data.email.includes( 'bumbleboard' ) ? { isAdmin: true } : { isTeacher: true },
            //     { position: data.email.includes( 'bumbleboard' ) ? 'Admin' : 'teacher' },
            // ];

            // position[ role.isStudent ? 'grade' : 'departments' ] = role.isStudent ? '10' : [ 'World Languages' ];

            // let admin = {};
            // if ( role.isTeacher ) admin = {
            //     adminEmail: 'bumbleboard@gmail.com',
            //     adminTitle: 'Mx.',
            //     adminLastName: 'BumbleBoard'
            // }

            // const newUser = courseInfo.getUserObj(
            //     data,
            //     'en-US',
            //     position,
            //     admin,
            //     {
            //         email: data.email,
            //         firstName: data.first,
            //         lastName: data.last,
            //         preferredName: data.preferredName || data.first,
            //         nickname: get.username( data.email ),
            //         title: 'Ms.'
            //     },
            //     role,
            //     {
            //         countyIDs: [ 'us-no-county' ],
            //         courseIDs: user?.courses?.map( course => course.id.db ),
            //         schoolIDs: [ 'bumbleboard-academy' ],
            //         schoolYearID: get.schoolYearID()
            //     },
            //     plan,
            // );

            updateUser( data )
            await firePush.pushUser( data, false );
        }

        // callLogout();

        return {
            auth,
            callLogout,
            data,
            db,
            emailPasswordRecover,
            // emailRegister,
            getAuthState,
            handleLogin,
            handleUserChange,
            localStorageVar,
            resetStorage
        }
    }, [ location, localStorageVar, navigate, resetStorage, schoolYearID, updateUser, user ] );

    // RENDER
    return <AccountContext.Provider value={ accountContext }>
        <GoogleOAuthProvider clientId={ HIDETHIS }>
            {/* <button onClick={ resetStorage }>RESET STORAGE</button> */}
            <Main data={ user.data } user={ user } updateUser={ updateUser }/>
        </GoogleOAuthProvider>
    </AccountContext.Provider>
}

export default AuthProvider;