import { useState } from 'react';
import styles from './InfiniteCarousel.module.css';
import moduleInfo from '../../../constants/moduleInfo';
import Caret from '../Buttons/Caret';
import Review from '../Review/Review';

const InfiniteCarousel = ({ isHorizontal=true, initialOrder=[] }) => {
    const [ increment, setIncrement ] = useState( 0 );

    const { directions, carouselPositions: positions } = moduleInfo;

    const [ axis, directionI ] = [
        isHorizontal ? 'x' : 'y',
        isHorizontal ? 0 : 1,
    ];

    const getPositions = () => {
        const max = initialOrder.length;
        return increment === 0 ? initialOrder : initialOrder.map( (e, i) => initialOrder[
            i + increment >= max ? i + increment - max
                : i + increment < 0 ? max + i + increment
                : i + increment
        ] )
    }

    const rotateCards = ({ target: { className } }) => {
        setIncrement( increment => {
            const max = initialOrder.length - 1;
            const inReverse = className.includes(
            directions.type( 0 )[ directionI ]
            );

            return (inReverse ? increment - 1 : increment + 1) % max
        } );
    }

    const getCarets = ( carets=[] ) => {
        const i = carets.length;

        return carets.length === 2 ? carets : getCarets([
            ...carets,
            <Caret
                direction={ directions[ axis ][ i ] }
                isPrimaryColour
                onClick={ rotateCards }
                hasBorder
            />,
        ])
    }

    const getReviews = ( components=[] ) => {
        const i = components.length;
        const j = i - 3;
        const classes = { noPosition: styles.hidden }

        Object.keys( positions ).forEach(
            position => classes[position] = styles[ positions[position] ]
        );

        return components.length === 7 ? components : getReviews( [
            ...components,
            <Review
                positionX={ j }
                classes={classes}
                increment={ increment }
                reviewKey={ getPositions()[ i ] }
                className={ styles[ positions[ j ] ? positions[ j ] : 'hidden' ] }
            />,
        ] );
    }

    const [ leftCaret, rightCaret ] = getCarets();

    return <article className={styles.carousel}>
        <div className={ styles.edge }>{ leftCaret }</div>
        <div className={ styles['reviews-container']}>{ getReviews() }</div>
        <div className={ styles.edge }>{ rightCaret }</div>
    </article>
}

export default InfiniteCarousel;