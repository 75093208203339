import { Link } from 'react-router-dom';
import CallToAction from '../../modular/Buttons/CallToAction';
import styles from './Hero.module.css';
import Card from '../../modular/Containers/Card';

const Hero = () => {
    return <header className={styles.hero} id='hero'>
        {/* <article className={styles['hero-image']}></article> */}
        <article className={styles['hero-text']}>
            <Card
                children={[
                    <h1>Get your students to &nbsp;
                        <span>want to learn</span>
                    </h1>,
                    <p>We turn your assignments into an organised flow of searchable choiceboards that push students forwards.<br/><br/>
                        <span>Less</span> confusion<br/>
                        <span>Fewer</span> clicks<br/>
                        <span>Minimal</span> effort
                    </p>,
                    <Link to={ '/signup' }>
                        <CallToAction autoFocus label='Enter Your Virtual Classroom'/>
                    </Link>,
                ]}
                className={ styles['text'] }
            />
        </article>
    </header>
}

export default Hero;