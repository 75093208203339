import { useContext, useEffect, useState } from 'react';
import { doc, onSnapshot } from 'firebase/firestore';
import { db } from '../../../../firebase_setup/firebase';
import styles from './Activity.module.css';
import functions from '../../../../constants/get';
import HiveContext from '../../../../contexts/HiveContext';
import RadioButton from '../../../modular/Form/Parts/RadioButton';
import get from '../../../../constants/get';
import UserContext from '../../../../contexts/UserContext';
import Modal from '../../../modular/Containers/Modal';
import TextInput from '../../../modular/Form/Parts/TextInput';
import RadioGroup from '../../../modular/Form/Parts/RadioGroup';

const Activity = () => {
    const user = useContext( UserContext );
    const { course, county, isQueenBee, rules } = useContext( HiveContext );
    document.title = `Feed ( ${ course.title } )`;

    const [ sections, students, tokens ] = [
        course?.sections?.all?.slice().sort( ( a, b ) => a - b ) || [],
        getSectionStudentEntries( course.content.students ),
        getTokens( getSectionStudentEntries( course.content.students ) )
    ];

    const [ selectedSections, setSelectedSections ] = useState( 'all' );
    const [ submissions, setSubmissions ] = useState({ i: 0, list: getSubmissions( 0, tokens, students ) });
    const [ showForm, setShowForm ] = useState( false );
    const [ deleteMode, setDeleteMode ] = useState( false );
    // console.log( '🟧', sections, students );

    // Live-read submissions submissions subcollection
    useEffect( () => {
        const unsubscribe = onSnapshot(
            doc( db, 'courses', course.id.db, 'submissions', user.schoolYearID ), doc => {
                const list = doc.data();
                list.length !== submissions.length && setSubmissions( submissions => ({ ...submissions, list }) );
            }
        );

        return () => unsubscribe()
    }, [ submissions, course.id.db, user.schoolYearID ] );


    // FUNCTIONS
    function getAssignment( who='', what='', isChecked=false ) {
        const [ boardNum, assignmentNum ] = [ what.split( '-' )[ 1 ] * 1, what.split( '-' )[ 3 ] * 1 ];
        const boardIsComplete = getBoardIsComplete( who, what, boardNum );

        const [ attributes, week ] = [
            { className: boardIsComplete ? styles.finished : isChecked ? styles.positive : styles.negative },
            [ 'Week', boardNum + 1 ].join(' ')
        ];

        return boardIsComplete ? <span { ...attributes }>Finished { week } ! <i>🎉</i></span> : <>
            <span { ...attributes }>
                { isChecked ? 'checked off ' : 'unchecked ' }
                { !isChecked && <i>😢</i>}
            </span>
            the { getAssignmentLocation( assignmentNum ) } box ( { week } )
        </>
    }

    function getAssignmentLocation( assignmentNum=0 ) {
        const vertical = assignmentNum < 2 ? 'centre-' : assignmentNum < 5 ? 'top-' : 'bottom-';
        const horizontal = [ 0, 2, 5 ].includes( assignmentNum ) ? 'left'
            : [ 1, 4, 7 ].includes( assignmentNum ) ? 'right' : 'middle'

        return vertical + horizontal
    }

    function getBoardAssignments( who='', boardNum=0 ) {
        return course.content.submissions.filter( submission => submission.who === who )
            .filter( submission => submission.isChecked && submission.what.includes( `-${ boardNum }-` ) );
    }

    function getBoardIsComplete( who='', what='', boardNum=0 ) {
        const assignments = getBoardAssignments( who, boardNum );
        const isFinalBoardAssignment = get.lastArrayElement( assignments ).what === what;
        if ( !isFinalBoardAssignment ) return false

        const completion = [ '-req', '-prereq' ].map(
            type => assignments.filter( assignment => assignment.what.includes( type ) ).length
        );

        return rules.board.req <= completion[ 0 ] && rules.board.prereq <= completion[ 1 ]
    }

    function getSectionStudentEntries( courseStudents={} ) { return Object.entries( courseStudents ) }

    function getStudent( email='', allStudents=[] ) { return allStudents?.find( student => student.email === email ) }

    function getSubmissions( i=0, tokens=null, students=null ) {
        if ( students === null ) students = getSectionStudentEntries( course.content.students )
        if ( tokens === null ) tokens = getTokens( students )
        const allStudents = students?.[ 0 ]?.[ 1 ] || [{}];

        if ( 'submissions' in course.content === false ) {
            user.initiateCourseSubmissions( course );
            return []
        }

        return course.content.submissions.slice( 25 * i, 25 * ( i + 1 ) )
            ?.filter( submission => submission )
            ?.map( ( submission, j ) => {
                const student = getStudent( submission.who, allStudents );

                return <li
                    key={`submissions-${ j }`}
                    id={`submissions-${ j }`}
                ><header>
                    <span className={ styles.name }>
                        { get.token( ...allStudents.find( student => student.email === submission.who ).token ) }
                        { student?.nickname || get.username( student.email ) }&nbsp;
                    </span>
                    <span className={ styles.timestamp }>{ get.dateTime( submission.when, county.locale, true, true ) }</span>
                </header>
                    <span>{ getAssignment( submission.who, submission.what, submission.isChecked ) }</span>
                </li>
            } ) || <li>No activity yet</li>;
    }

    function getTokens( sectionStudents=[ [ '', [] ] ] ) {
        return sectionStudents.map( entry => entry[ 1 ].map( student => student.nickname ? get.token(
            `${ student.nickname }-token`, ...student?.token?.slice( 1 )
        ) : get.token( `${ get.username( student.email) }-token`, ...get.randomToken( true ).slice( 1 ) )
        ) )
    }

    function handleAddingStudents( e ) {
        e.preventDefault();
        const inputs = {};

        Array.from( e.target.elements ).filter( e => e.id ).forEach( e => {
            if ( sections.includes( e.value ) ) {
                if ( e.checked ) {
                    const sectionNum = Number( e.value );
                    // console.log( e.id, e.value, sectionNum, typeof sectionNum );
                    inputs.section = ( sectionNum === 0 || !!sectionNum ) ? sectionNum : e.value;
                }

            } else if ( e.id.includes( 'email' ) ) {
                // console.log( 'email:', e.value );
                inputs.email = e.value;

            } else if ( e.checked ) {
                // console.log( 'is' + get.capitalise( e.value ), e.checked );
                inputs[ 'is' + get.capitalise( e.value ) ] = e.checked;
            }
        } );

        let { email, section, ...role } = inputs;
        if ( section !== 0 && !section ) section = 'all'

        const alreadyInSection = section !== 'all' ? course.content.students?.[ section ]?.includes( email )
            : course.content.students?.all?.find( obj => obj.email === email );
        // console.log( email, section, role );

        if ( alreadyInSection ) {
            window.location.hostname === 'localhost' && console.log( email, 'is already a worker bee' );
        } else if ( email.includes( '@' ) ) user.addCourseGuest( course, email, section, role )
    }

    function handleSectionSelection({ target: { value } }) {
        setSelectedSections( selectedSections => {
            if ( value === 'all' ) return value
            if ( typeof selectedSections === 'string' ) return [ value ]

            const i = !selectedSections.includes( value ) ? null
                : selectedSections.findIndex( section => section === value );

            return i === null ? [ ...selectedSections, value ]
                : [ ...selectedSections.slice( 0, i ), ...selectedSections.slice( i + 1 ) ];
        } )
    }

    function toggleDelete() { setDeleteMode( deleteMode => !deleteMode ) }

    function toggleModal({ target: { className } }) { setShowForm( () => !className.includes( 'modal-container' ) ) }



    // COMPONENTS
    window.location.hostname === 'localhost' && console.log( students );
    let Students = students[ 0 ] ? students.map( ( sectionStudents, i ) => (
        selectedSections === 'all' || selectedSections.includes( sectionStudents[ 0 ] )
    ) && <ul key={`list-${ i }`} className={ deleteMode ? styles.delete : '' }>{
        sectionStudents[ 1 ].map( ( student, j ) => <li key={`student-${ j }`} className={ styles.student }>
            { typeof student === 'string' ? student : student.nickname || student.email }
            { selectedSections !== 'all' && `(${ functions.ordinalNum( sectionStudents[ 0 ], course.language ) })` }

            { deleteMode ? <i className='fa-solid fa-user-xmark'/>
                : student.token?.[ 2 ] ? get.token( ...student.token ) : tokens[ i ][ j ] }
        </li>
    ) }</ul> ) : <ul>
        <li className={ styles[ 'no-students' ] }>No students / guests yet{ get.token( null, true, 'animals', 0 ) }</li>
    </ul>;

    const SectionButtons = [ 'all', ...sections ].map( section => <RadioButton
        className={ styles.section }
        checked={ selectedSections.includes( section + '' ) }
        groupName={`${ course.id.db }-hive-sections`}
        id={`${ course.id.db }-hive-sections-${ section }`}
        key={`${ course.id.db }-hive-sections-${ section }`}
        label={ section }
        toggleSecondary={ handleSectionSelection }
        type='checkbox'
    />);

    // RENDER
    return <div className={ styles.activity }>
        { showForm && <Modal
            modalChildren={[ <form onSubmit={ handleAddingStudents }>
                <fieldset className={ styles.inputs }>
                    <TextInput
                        id='new-student-email'
                        isRequired
                        label='@ email'
                        name='new-student-email'
                        transformLabel='hide'
                        type='text'
                    />
                    { sections.length > 0 && <RadioGroup
                        defaultCheckedButtons={ sections[ 0 ] }
                        groupName='new student sections'
                        labelPositionOn='top'
                        mainLabelType='radio'
                        mainLabels={ sections }
                    /> }
                    <RadioGroup
                        defaultCheckedButtons={[ 'Student' ]}
                        groupName='worker bee roles'
                        labelPositionOn='left'
                        mainLabelType='radio'
                        mainLabels={[ 'Student', 'Admin', 'Teacher' ]}
                    />
                </fieldset>
                <button type='submit'>ADD STUDENT</button>
            </form> ]}
            modalClass=''
            toggleModal={ toggleModal }
        /> }
        <section className={ styles[ 'worker-bees' ] }>
            <div className={ styles.buttons }>{ isQueenBee && <>
                <button onClick={ toggleDelete } className={ styles[ 'delete-button' ] }>
                    { deleteMode ? 'CANCEL' : 'REMOVE' }&nbsp;
                    <i className={`fa-solid fa-user-${ deleteMode ? 'group' : 'minus' }`}/>
                </button>
                <button onClick={ toggleModal } className={ styles[ 'add-button' ] }>
                    ADD <i className='fa-solid fa-user-plus'/>
                </button>
            </> }</div>

            { Students }
        </section>
        <section className={ get.classNameFromArr([
            styles.details, !sections[ 0 ] && styles[ 'just-feed' ]
        ]) }>
            { sections[ 0 ] && <div className={ styles.sections }>
                <span>Sections:</span>{ SectionButtons }
            </div> }
            <div className={ styles.feed }>{ <ul>{ submissions.list }</ul> }</div>
        </section>
    </div>
}

export default Activity;