import { useState } from 'react';
// import Button from '../../../pages/Button';
import Step from './Step';
import styles from './Stepper.module.css';
import StepNav from './StepNav';
import get from '../../../../constants/get';

const Stepper = ({
    isHorizontal = false,
    stepChildren = [],
    stepChildrenAreForms = false,
    submitResults = () => {},
}) => {
    const [ activeStepI, setActiveStepI ] = useState( 0 );
    const [ userInput, setUserInput ] = useState( stepChildren.map(
        child => child.data.map( () => null )
    ) );
    // console.log( ...userInput );
    const allStepsAreComplete = userInput.every( arr => arr.every( e => e !== null ) );
    const stepperIsComplete = activeStepI === stepChildren.length && allStepsAreComplete;
    window.location.hostname === 'localhost' && console.log( ...userInput );

    // FUNCTIONS
    const submitStepForm = e => {
        e.preventDefault();
        // console.log( e );
        if ( e.target.id.startsWith( 'open' ) ) return toggleSteps( e )

        const valuesAndButtons = Array.from( e.target.elements ).filter(
            e => ( [ 'radio', 'checkbox' ].includes( e.type ) && e.checked )
                || ( ![ 'radio', 'checkbox' ].includes( e.type ) && e.value )
        ).map( e => [
            e.id || 'button',
            ( [ 'radio', 'checkbox' ].includes( e.type ) && e.checked ) ? e.id : e.value
        ] );
        const values = valuesAndButtons.filter( arr => arr[ 0 ] !== 'button' );

        let nextStep = e.target.id.split( '-' ).pop() * 1;
        const formStep = nextStep - 1;

        const isIncomplete = !values[ 0 ];
        if ( isIncomplete ) return

        window.location.hostname === 'localhost' && console.log( e.target.name );
        const buttonAction = e.target.name;
        nextStep = buttonAction === 'back' ? formStep - 1 : nextStep;

        const newUserInput = userInput.slice();
        newUserInput[ formStep ] = values.map( arr => arr[ 1 ] );

        window.location.hostname === 'localhost' && console.log( '🐝', '\n', e.target.name, '\nvalues:', ...values, '\nNext Step:', nextStep, '\nCurrent Form Step: ', formStep, '\nButton Action:', buttonAction );

        toggleSteps({ target: { id: `to-${ nextStep }` } });
        setUserInput( () => newUserInput );

        if ( nextStep === stepChildren.length ) submitResults( newUserInput )
    }

    const toggleSteps = ({ target: { id } }) => setActiveStepI( () => id.split( '-' ).pop() * 1 )

    // COMPONENTS
    // RENDER
    return <div className={ get.classNameFromArr([
        styles.stepper,
        isHorizontal && styles.isHorizontal,
        stepperIsComplete && styles.complete
    ]) }>
        { stepperIsComplete || <StepNav
            headers={ stepChildren.map( child => child.header ) }
            isHorizontal={ isHorizontal }
            stepCompletions={ userInput.map( arr => arr.every( e => e !== null ) ) }
            stepIcons={ stepChildren.map( child => child.icon ) }
            toggleStep={ toggleSteps }
        /> }

        { stepperIsComplete ? <div className={ styles[ 'form-finish' ] }>
            <header>All Done!</header>
            <i className='fa-solid fa-check'/>
            <p>Thank you!</p>
        </div> : <Step
            contentIsForm={ stepChildrenAreForms }
            isActive
            lastStepI={ stepChildren.length - 1 }
            stepChildren={ stepChildren[ activeStepI ].data }
            stepI={ activeStepI }
            toggleStep={ stepChildrenAreForms ? submitStepForm : toggleSteps }
        /> }
    </div>
}

export default Stepper;