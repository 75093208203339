import styles from './AttachmentGroup.module.css';
import AttachmentLink from './AttachmentLink';
import StrToJsx from '../StrToJsx';

const AttachmentGroup = ({ linkGroups=[[], []] }) => {
    const [ plainLinks, boards ] = linkGroups;

    const capitaliseFirstLetter = ( str='' ) => `${ str[0].toLocaleUpperCase() }${ str.slice(1) }`

    const formatLabel = ( str='' ) => StrToJsx( 
        capitaliseFirstLetter( str ) 
    )

    const generateLinks = ( links=[], components=[] ) => links[0] ? generateLinks(
        links.slice(1),
        [ ...components, <AttachmentLink 
            iframeSrc={ links[0].iframeSrc ? links[0].iframeSrc : '' }
            label={ formatLabel(links[0].label) }
            key={`${ links[0].iframeSrc ? 'board' : 'plain-link' }-${ components.length + 1 }`}
            href={ links[0].href }
        /> ]
    ) : components

    return <article className={ styles.group }>
        <section className={ styles['plain-links'] }>{
            generateLinks( plainLinks )
        }</section>
        <section className={ styles.boards }>{
            generateLinks( boards )
        }</section>
    </article>
}

export default AttachmentGroup;