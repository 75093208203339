import Logo from '../../modular/Images/Logo';
import StrToJsx from '../../modular/StrToJsx';
import get from '../../../constants/get';
import styles from './Scenario.module.css';

const Scenario = ({ parties={}, dialogue={}, scenarioNum=1 }) => {
    const { me, them, name } = parties;

    const generateLineJSX = ( arr=[], speakerClass='', i=0 ) => {
        const [ newSpeaker, lastOfGroup ] = [
            isNewSpeaker( arr, speakerClass, i ),
            isLastOfGroup( arr, speakerClass, i ),
        ];

        const [ classLine, contentLine] = generateLine( speakerClass );
        const [ classSpeaker, contentSpeaker ] = generateSpeaker( speakerClass );

        const speakerClassNames = get.classNameFromArr([ styles.speaker, newSpeaker && styles.show ]);
        const liClassNames = get.classNameFromArr([
            styles[ classSpeaker ],
            newSpeaker && styles[ 'first-of-group' ],
            lastOfGroup && styles[ 'last-of-group' ]
        ]);

        const speakerAndLineArr = [
            <span className={ speakerClassNames }>{ newSpeaker && contentSpeaker }</span>,
            <span className={ classLine }>{ contentLine }</span>
        ];

        return <li
            key={ speakerClass }
            id={ speakerClass }
            className={ liClassNames }
        >{ classSpeaker === 'me' ? speakerAndLineArr.reverse() : speakerAndLineArr }</li>
    }

    const generateHeader = () => <header>
        <h4>{ name }</h4>
        <div className={ styles.icons }>{
            them.length === 1 ? <i>{ them[ 0 ] }</i>
                : them.map( ( emoji, i ) => <i key={ `emoji-${ i }` }>{ emoji }</i>)
        }</div>
    </header>

    const generateLine = ( speakerClass='' ) => {
        let { [ speakerClass ]: line } = dialogue;

        if ( line.startsWith( '*' ) ) return [
            [ styles.line, styles.narration ].join(' '),
            <em>{ line.slice( 1 ) }</em>
        ]

        return [ styles.line, StrToJsx( line, { i : 'strong'} ) ]
    }

    const generateSpeaker = ( speakerClass='' ) => speakerClass.includes( 'them' ) ?
        speakerClass.includes( '0' ) ? [ 'them1', them[ 1 ] ] : [ 'them', them[ 0 ] ]
        : speakerClass.includes( 'me' ) ? [ 'me', me ] : [ 'board', <Logo /> ]

    const getSpeakersMinusSuffix = ( speakers=[] ) => speakers.map(
        speaker => speaker.length > 1 && speaker.slice( 0, speaker.length - 1 )
    )

    const isNewSpeaker = ( arr=[], speakerClass='', i=0 ) => {
        let [ prevSpeaker, currentSpeaker ] = [
            i === 0 ? '' : arr[ i-1 ], speakerClass
        ];

        let [ prevSpeakerSliced, currentSpeakerSliced ] = getSpeakersMinusSuffix([
            prevSpeaker, currentSpeaker
        ]);

        return prevSpeakerSliced !== currentSpeakerSliced;
    }

    const isLastOfGroup = ( arr=[], speakerClass='', i=0 ) => {
        const [ currentSpeaker, nextSpeaker ] = [
            speakerClass, i === ( arr.length - 1 ) ? '' : arr[ i + 1 ]
        ];

        const [ currentSpeakerSliced, nextSpeakerSliced ] = getSpeakersMinusSuffix([
            currentSpeaker, nextSpeaker
        ]);

        return nextSpeakerSliced !== currentSpeakerSliced
    }

    // RENDER
    return <div
        className={ styles.scenario }
        id={ styles[ `scenario-${ scenarioNum }` ] }
    >
        { generateHeader() }
        <div className={ styles.conversation }>
            <ul>{ Object.keys( dialogue ).map(
                ( speakerClass, i, arr ) => generateLineJSX( arr, speakerClass, i )
            ) }</ul>
        </div>
    </div>
}

export default Scenario;