import { useContext, useState } from 'react';
import get from '../../../../constants/get';
import styles from './Gradebook.module.css';
import HiveContext from '../../../../contexts/HiveContext';
import { DndContext, KeyboardSensor, PointerSensor, rectIntersection, useSensor, useSensors } from '@dnd-kit/core';
import SortableWrapper from '../../../modular/DragAndDrop/SortableWrapper';
import { arrayMove, sortableKeyboardCoordinates } from '@dnd-kit/sortable';

const ContainerIDsAndChildIDs = {
    vowels: 'AEIOUY'.split( '' ),
    liquidConsonants: 'LRMN'.split( '' ),
    voicedConsonants: 'BZDVGJ'.split( '' ),
    unvoicedConsonants: 'PCSTFKHQWX'.split( '' ),
};

const Gradebook = () => {
    const { course } = useContext( HiveContext );
    document.title = `Gradebook ( ${ course.title } )`;

    const [ items, setItems ] = useState( ContainerIDsAndChildIDs );
    const [ activeID, setActiveID ] = useState( null );
    window.location.hostname === 'localhost' && console.log( items, activeID );

    // DND
    const sensors = useSensors(
        useSensor( PointerSensor ),
        useSensor( KeyboardSensor, {
            coordinateGetter: sortableKeyboardCoordinates,
        } ),
    )

    // FUNCTIONS
    // function changeColour() { setCurrentBoardColour( () => get.randomHSL() ) }

    function findContainer( id='' ) {
        return ( id in items ) ? id : Object.keys( items ).find( key => items[ key ].includes( id ) )
    }

    // function generateNewID( suffix ) { return suffix }
    function generateSortableSection( category='' ) {
        const arr = items[ category ];

        return <section
            className={ styles.container }
            id={ `section-${ category }` }
            key={ `section-${ category }` }
        >
            <header>{ category.toLocaleUpperCase() }</header>
            <SortableWrapper
                Children={ arr[ 0 ] ? arr.map( letter => <article
                    className={ styles.child }
                    key={ `letter-${ letter }` }
                    id={ `letter-${ letter }` }
                ><p>{ letter }</p></article> ) : [ <article
                    className={ styles.child }
                    key={ `empty-${ category }` }
                    id={ `empty-${ category }` }
                ><p>Add an item here</p></article> ]}
                handlePosition={{ left: true }}
                header={ category }
                items={ items[ category ] }
                sortingStrategy={{ vertical: true }}
                wrapInDroppable
            />
        </section>
    }

    function getSortableIDsAndContainers( e, isDragEnd=false ) {
        if ( !e.over ) return {}
        // console.log( e );
        const [ activeID, overID ] = [
            e.active.id,
            e.over.id.startsWith( 'nested' ) ? e.over.id.split( '-' ).pop() : e.over.id
        ];

        const [ activeContainerID, overContainerID ] = [
            findContainer( activeID ),
            findContainer( overID ),
        ];
        window.location.hostname === 'localhost' && console.log( 'ACTIVE:', activeID, '\nOVER:', overID );
        window.location.hostname === 'localhost' && console.log( 'ACTIVE CONTAINER:', activeContainerID, '\nOVER CONTAINER:', overContainerID );

        if ( !activeContainerID
            || !overContainerID
            || ( isDragEnd ? ( overContainerID !== activeContainerID )
                : ( overContainerID === activeContainerID ) )
        ) return {}

        let [ activeI, overI ] = [
            items[ activeContainerID ].indexOf( activeID ),
            items[ overContainerID ].indexOf( overID ),
        ];

        // If hovering over the droppable area, not sortable area, add the element at the bottom of the sortable array
        if ( overI < 0 ) overI = items[ overContainerID ].length;
        window.location.hostname === 'localhost' && console.log( 'ACTIVE I:', activeI, '\nOVER I:', overI );

        return { activeI, overI, activeID, overID, activeContainerID, overContainerID }
    }

    function handleDragOver( e ) {
        const {
            activeI,
            activeID,
            activeContainerID,
            overI,
            overID,
            overContainerID
        } = getSortableIDsAndContainers( e, false );
        if ( !activeID && !overID && !activeContainerID && !overContainerID ) return

        const { draggingRect, over } = e;
        setItems( items => {
            let newI = overI;
            const [ activeItems, overItems ] = [ items[ activeContainerID ], items[ overContainerID ] ];
            window.location.hostname === 'localhost' && console.log( 'ACTIVE ITEMS:', activeItems, '\nOVER ITEMS:', overItems );

            // We're at the root droppable of a container
            if ( overID in items && over.id in items ) newI = overItems.length + 1;
            if ( overID in items === false ) {
                const isBelowLastItem = over
                    && overI === overItems.length - 1
                    && draggingRect?.offsetTop > over?.rect?.offsetTop + over?.rect?.height;

                const increment = isBelowLastItem ? 1 : 0;
                newI = overI >= 0 ? overI + increment : overItems.length + 1;
                window.location.hostname === 'localhost' && console.log( 'NEW I:', newI );
            }

            return {
                ...items,
                [ overContainerID ]: get.arrWithElementAtI( newI, overItems, activeItems[ activeI ], 0 ),
                [ activeContainerID ]: items[ activeContainerID ].filter( item => item !== activeID ),
            }
        } )
    }

    function handleItemDragEnd( e ) {
        const {
            activeI,
            activeID,
            activeContainerID,
            overI,
            overID,
            overContainerID
        } = getSortableIDsAndContainers( e, true );
        if ( !activeID && !overID && !activeContainerID && !overContainerID ) return

        ( activeI !== overI ) && setItems( items => ({
            ...items, [ overContainerID ]: arrayMove( items[ overContainerID ], activeI, overI )
        }) );

        // console.log( 'NEW { ITEMS }:', { ...items, [ overContainerID ]: arrayMove( items[ overContainerID ], activeI, overI ) } )

        setActiveID( null )
    }

    function handleDragStart({ active: { id } }) { setActiveID( () => id ) }

    // COMPONENTS
    const Children = Object.keys( ContainerIDsAndChildIDs )
        .map( category => generateSortableSection( category ) );
    // console.log( Children );

    // RENDER
    return <div className={ styles.gradebook } style={ { background: get.randomHSL() } }>
        <div className={ styles.dnd }>
            <DndContext
                sensors={ sensors }
                collisionDetection={ rectIntersection }
                onDragEnd={ handleItemDragEnd }
                onDragOver={ handleDragOver }
                onDragStart={ handleDragStart }
            >{ Children }</DndContext>
        </div>
        {/* <div className={ styles.content }>
            <p><span className={ styles.icon }>🚧</span><br />GRADEBOOK UNDER CONSTRUCTION</p>

            <button onClick={ changeColour }>
                <span className={ styles.line }/>
                CHANGE COLOUR
                <span className={ styles.line }/>
                <svg xmlns="http://www.w3.org/2000/svg" width="56" height="51" className={ styles.shine }>
                    <g fillRule="evenodd">
                        <rect width="8" height="2" x="48" y="24" rx="2" />
                        <rect width="8" height="2" x="11.73" y="2.46" rx="2" transform="rotate(60 15.73 4.46)"/>
                        <rect width="8" height="2" x="35.73" y="2.46" rx="2" transform="rotate(120 39.73 4.46)"/>
                        <rect width="8" height="2" x="12" y="45" rx="2" transform="scale(1 -1) rotate(60 97.4 0)"/>
                        <rect width="8" height="2" x="36" y="45" rx="2" transform="scale(-1 1) rotate(-60 0 116.28)"/>
                        <rect width="8" height="2" y="24" rx="2" />
                    </g>
                </svg>
            </button>
        </div> */}
    </div>
}

export default Gradebook;