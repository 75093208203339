import { useContext } from 'react';
import styles from './Board.module.css';
import UserContext from '../../../contexts/UserContext';
import HiveContext from '../../../contexts/HiveContext';
import get from '../../../constants/get';
import Button from '../../pages/Button';
import SVG from '../Images/SVG';

const BoardSquare = ({
    assignmentsArr = [],
    AttachmentContainerAndCheckBox = null,
    details = {},
    i = 0,
    isActive = false,
    isChecked = false,
    readText = () => {},
}) => {
    // console.log( '🟧', links );
    const user = useContext( UserContext );
    const { course, isActuallyQueenBee, isQueenBee, lang } = useContext( HiveContext );

    const [ isAssignment, isCentreBox, showButton ] = [
        'directions' in details, 'checkList' in details, details.boardNum >= 0 && getShowButtonBool()
    ];

    const [ isPlaceholder, isPrereq ] = [
        isAssignment && [ ' ', '' ].includes( details.directions ),
        isAssignment && details.isPrereq,
    ];

    const boardRules = user.premium ? user.premium.rules.board : { prereq: 3, req: 2, size: 8 };
    if ( !user.premium ) boardRules.isLinear = boardRules.isWeekly = true


    // FUNCTIONS
    function getCheckedReqsAndPrereqs() {
        return [
            details.checkList?.filter( boxID => !boxID.includes( 'prereq' ) )?.length,
            details.checkList?.filter( boxID => boxID.includes( 'prereq' ) )?.length,
        ]
    }

    function getInstructions() {
        let [ numCheckedReqBoxes, numCheckedPrereqBoxes ] = getCheckedReqsAndPrereqs();
        const neededPrereqBoxes = boardRules.prereq - numCheckedPrereqBoxes;
        const neededReqBoxes = neededPrereqBoxes > 0 ? boardRules.req : boardRules.req - numCheckedReqBoxes;

        if ( neededReqBoxes > 0 && numCheckedPrereqBoxes > boardRules.prereq ) numCheckedPrereqBoxes = boardRules.prereq
        if ( numCheckedPrereqBoxes < boardRules.prereq ) numCheckedReqBoxes = 0;

        const completion = numCheckedPrereqBoxes < boardRules.prereq ? numCheckedPrereqBoxes
            : numCheckedReqBoxes < boardRules.req ? boardRules.prereq + numCheckedReqBoxes
            : numCheckedReqBoxes + numCheckedPrereqBoxes;
        const minimumTotal = boardRules.req + boardRules.prereq;

        const { darkBoxDirections, directionsStart, lightBoxDirections } = getInstructionsInLang();
        const Instructions = ( neededPrereqBoxes > 0 || neededReqBoxes > 0 ) ? <>
            { directionsStart( neededPrereqBoxes > 0 ) }<br />
            <span className={ styles.hint }>{
                neededPrereqBoxes > 0 ? lightBoxDirections( neededPrereqBoxes ) : darkBoxDirections( neededReqBoxes )
            }</span>
            { lang === 'en' ? ' !' : '' }
        </> : <SVG type={{ check: true }} fontSize='3rem'/>; //<>All done! 😎</>

        return <p>{ Instructions }<br /><br />{( !isQueenBee && ( showButton ? <Button
            type='button'
            onClick={ saveChanges }
            label='turn in'
        /> : `${ completion } / ${ minimumTotal }` ) )}</p>
    }

    function getInstructionsInLang() {
        let verb = isQueenBee ?
            lang === 'fr' ? 'ajoutez' : lang === 'es' ? 'agregue' : 'add'
            : lang === 'fr' ? 'fais' : lang === 'es' ? 'haz' : 'do';

        switch ( lang ) {
            case 'fr': return {
                darkBoxDirections( num=1 ) { return num > 1 ? `${ num } cases foncées` : '1 dernière case foncée' },
                directionsStart( isForDarkBoxes=false ) {
                    return isForDarkBoxes ? get.capitalise([ verb ]) + ' ' + ( isQueenBee ? 'au moins' : '& coche' )
                        : `${ isQueenBee ? 'On' : 'Tu' } y ${ isQueenBee ? 'est' : 'es' } presque !\n${ isQueenBee ? get.capitalise([ verb ]) : 'Allez, ' + verb }`
                },
                lightBoxDirections( num=1 ) { return `${ num } case${ num > 1 ? 's' : '' } claire${ num > 1 ? 's' : '' }` },
            }
            case 'es': return {
                darkBoxDirections( num=1 ) { return num > 1 ? `${ num } casillas oscuras más` : '1 última casilla oscura' },
                directionsStart( isForDarkBoxes=false ) {
                    return isForDarkBoxes ? get.capitalise([ verb ]) + ' ' + ( isQueenBee ? 'al menos' : '& tilda' )
                        : `¡ Casí ${ isQueenBee ? 'estamos' : 'estás' } !\n${ isQueenBee ? get.capitalise([ verb ]) : 'Venga ' + verb }`
                },
                lightBoxDirections( num=1 ) { return `${ num } casilla${ num > 1 ? 's' : '' } clara${ num > 1 ? 's' : '' }` },
            }

            default: return {
                darkBoxDirections( num=1 ) { return num > 1 ? `${ num } dark boxes` : '1 final dark box' },
                directionsStart( isForLightBoxes=false ) {
                    return isForLightBoxes ? get.capitalise([ verb ]) + ' ' + ( isQueenBee ? 'at least' : '& check off' )
                        : `Almost done! Just ${ verb }`
                },
                lightBoxDirections( num=1 ) { return `${ num } light box${ num > 1 ? 'es' : '' }` },
            }
        }
    }

    function getShowButtonBool() {
        const assignments = course?.content ? course.content.assignments[ details.boardNum ] : assignmentsArr;
        const assignmentsChecked = isQueenBee ? assignments.filter( assignment => assignment.isChecked )
            : isActuallyQueenBee ? assignments.filter( assignment => assignment.isFakeChecked )
            : get.workerBeeSubmissions( details.boardNum, course.content.submissions, user.email, true );

        const dbCheckedReqBoxes = assignmentsChecked.filter( assignment => assignment.id.includes( '-req' ) ).length;
        const dbCheckedPrereqBoxes = assignmentsChecked.length - dbCheckedReqBoxes;
        let [ numCheckedReqBoxes, numCheckedPrereqBoxes ] = getCheckedReqsAndPrereqs();

        return dbCheckedReqBoxes !== numCheckedReqBoxes || dbCheckedPrereqBoxes !== numCheckedPrereqBoxes
    }

    function setTextToRead() { readText( details.directions ) }

    function saveChanges() {
        return user.updateCheckedAssignments(
            details.checkList,
            details.boardNum,
            course,
            user.data.uid === course.ownerID && !isQueenBee
        )
    }

    function fillSquare( Directions ) {
        // return details.links.length > 0 ?
        return <div className={ styles.content }>
            { Directions }
            {/* <section className={ styles.links }> */}
            <section className={ get.classNameFromArr([
                styles[ 'links-and-button' ],
                details.links?.length > 0 ? styles[ 'with-links' ] : styles[ 'sans-links' ]
            ]) }>{ AttachmentContainerAndCheckBox }</section>
        </div>
        // : <a href={ details.links[ 0 ] } target='_blank' rel='noopener noreferrer'>{ Directions }</a>
    }

    // CONTEXTS, PROPS & ATTRIBUTES
    const attributes = {
        section: {
            className: get.classNameFromArr([
                styles.square,
                !isCentreBox && ( i < 3 ? styles.top : i < 6 ? styles.middle : styles.bottom ),
                !isCentreBox && ( i % 3 === 0 ? styles.left : i % 3 === 1 ? styles.centre : styles.right ),
                isActive && styles.active,
                isChecked && styles.checked,
                showButton ? styles[ 'save-changes' ] : styles[ 'not-editable' ],
                isAssignment && isPlaceholder && styles.blank,
                AttachmentContainerAndCheckBox !== null ? styles[ 'board-view ' ] : styles.preview,
                !isAssignment ? styles[ 'directions-box' ]
                    : isPrereq ? styles.prereq : styles.req,
            ])
        },
    };

    // COMPONENTS
    const Directions = isPlaceholder ? AttachmentContainerAndCheckBox : <span>{ details.directions }</span>;
    // <Button
    //     label={ <i className='fa-solid fa-feather-pointed'/> }
    //     onClick={ activateEditButton }
    // />

    const Details = isPlaceholder && isAssignment ? Directions
        : !isAssignment ? getInstructions()
        : fillSquare( Directions );

    // RENDER
    return <section { ...attributes.section }>
        <div className={ styles.details }>
            { isActive || <>{
                !!details.directions && <Button
                    className={ styles.playback }
                    label={ <i className='fa-solid fa-volume-high'/> }
                    onClick={ setTextToRead }
                />
            }</> }

            { isActive && <div className={ styles.skeleton }>
                <section className={ styles.lines }>{ Array( 4 ).fill().map( ( e, i ) => <div
                    key={`skeleton-line-${ i }`}
                    className={ styles[ 'directions-skeleton' ] }
                /> ) }</section>
                <section className={ styles.boxes }>
                    <div className={ styles[ 'checkbox-skeleton' ] }/>
                    <div className={ styles[ 'links-skeleton' ] }/>
                </section>
            </div> }

            { Details }
        </div>
        {/* { !isPreview && !isPlaceholder && !isActive && CheckBox } */}
    </section>
}

export default BoardSquare;