import { useState } from 'react';
import styles from './Review.module.css'
import Avatar from '../Images/Avatar';
import Card from '../Containers/Card';
import Credentials from './Credentials';
import ReviewFooter from './ReviewFooter';
import ReviewText from './ReviewText';
import Stars from './Stars';
import reviewInfo from '../../../constants/reviewInfo';
import get from '../../../constants/get';

const Review = ({
    reviewDetails={},
    positionX=0,
    reviewKey=-1,
    classes={},
    increment=0
}) => {
    const [ position, setPosition ] = useState(positionX + increment);

    reviewDetails = reviewKey === -1 ? reviewDetails
        : reviewInfo.customers[ reviewKey ];

    // console.log(position, reviewDetails.name, classes[position]);

    const updatePosition = () => setPosition(
        position => position + increment
    )

    let [ stars, text, avatar ] = [
        <Stars
            rating={ reviewDetails.rating }
            className={styles.stars}
        />,
        <ReviewText
            review={ reviewDetails.review }
            className={ styles.text }
            inFocus={ position === 0 }
        />,
        <Avatar
            mini={ position !== 0 }
            reviewDetails={ reviewDetails }
        />,
    ];

    let footer = <ReviewFooter
        inFocus={ position === 0 }
        children={ position === 0 ? [
            stars,
            <Credentials reviewDetails={ reviewDetails } />,
        ] : [ avatar ] }
    />;

    return <div className={ get.classNameFromArr([
        styles.review,
        styles[ position !== 0 ? 'not-in-focus' : 'in-focus' ],
        classes[ position ] ? classes[position] : classes.noPosition
    ]) }>< div className={styles.container}
        onDoubleClick={ updatePosition }
    >
        { position === 0 && <div className={styles.portrait}>{ avatar }</div> }
        <Card
            className={ styles.card }
            children={[
                position !== 0 && stars,
                text,
                footer,
            ]}
        />
    </div></div>
}

export default Review;