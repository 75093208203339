import { createContext } from "react";

const UserContext = createContext({
    data: {
        accessToken: '',
        auth: null,
        displayName: '',
        email: '',
        emailVerified: true,
        isAnonymous: false,
        metadata: null,
        phoneNumber: null,
        photoURL: '',
        proactiveRefresh: null,
        providerData: [{}],
        providerId: '',
        refreshToken: () => {},
        reloadListener: null,
        reloadUserInfo: null,
        stsTokenManager: null,
        tenantId: null,
        uid: '',
    },
    addCourse: () => {},
    callLogout: () => {},
    updateAssignments: () => {},
    updateBoard: () => {},
    updateBoardMerge: () => {},
    updateBoardPrivacy: () => {},
    updateCheckedAssignments: () => {},
    updateCourseUnits: () => {},
    updateUserObj: () => {},
    counties: [],
    countyIDs: [],
    courseIDs: [],
    courses: [],
    createdOn: { seconds: 0, nanoseconds: 0 },
    email: '',
    isMobile: false,
    lastLoginOn: { seconds: 0, nanoseconds: 0 },
    locale: '',
    name: {},
    OS: '',
    permissions: {},
    photoURL: '',
    plan: {},
    position: '',
    premium: {},
    role: {},
    schoolIDs: [],
    schoolYearID: ''
})

export default UserContext;