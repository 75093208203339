// import { useEffect, useState } from 'react';
import {
    SortableContext,
    verticalListSortingStrategy,
    horizontalListSortingStrategy,
    rectSwappingStrategy,
    rectSortingStrategy
} from '@dnd-kit/sortable';
import Droppable from './Droppable';
import SortableItem from './SortableItem';
import Button from '../../pages/Button';
import styles from './SortableItem.module.css';
import SVG from '../Images/SVG';

const SortableWrapper = ({
    ButtonLabelVerb = 'ADD',
    ButtonOnClick = () => window.location.hostname === 'localhost' && console.log( 'PRETENDED TO ADD A SORTABLE ITEM...' ),
    ButtonSVG,
    Children = [],
    ChildrenClassNames = [],
    header = '',
    handlePosition = { top: false, right: false, bottom: false, left: false },
    items = [],
    maxCapacity = -1,
    sortableTypeWithArticle = 'an item',
    sortingStrategy = { vertical: false, horizontal: false, swap: false },
    wrapInDroppable = false,
}) => {
    const isFull = maxCapacity >= 0 && !Children.some( Child => !Child );

    // PROPS & ATTRIBUTES
    const props = {
        items, strategy: sortingStrategy.vertical ? verticalListSortingStrategy
            : sortingStrategy.horizontal ? horizontalListSortingStrategy
            : sortingStrategy.swap ? rectSwappingStrategy
            : rectSortingStrategy,
    };

    // COMPONENTS
    const ActionItems = isFull || <div className={ styles[ 'action-items' ] }>
        <p>
            <SVG type={{ expand: true }}/>
            Drop { sortableTypeWithArticle.toLocaleLowerCase() } here - OR -
        </p>
        <Button
            className={ styles.button }
            fillSVGOnHover
            id={ [ 'activate', items[ Children.findIndex( Child => !Child ) ], 'edit' ].join( '-' ) }
            label={ <>{ ButtonSVG }{ [ ButtonLabelVerb, sortableTypeWithArticle ].join(' ').toLocaleUpperCase() }</> }
            onClick={ ButtonOnClick }
        />
    </div>;

    // If there are no ids in [ items ] but there are Children, render the Children
    // Else, render a <ul /> of each truthy Child
    const ChildrenUL = ( !items[ 0 ] && Children[ 0 ] ) ? Children
        : ( Children.length > 0 && <ul>{
            items.map( ( id, i ) => Children[ i ] && <SortableItem
                Child={ Children[ i ] }
                className={ ChildrenClassNames[ i ] }
                handlePosition={ handlePosition }
                isDisabled={ isFull }
                id={ id }
                key={ id }
                wrapInDroppable={ wrapInDroppable }
            /> )
        }</ul> );

    // RENDER
    return <SortableContext { ...props }>
        { wrapInDroppable ? <Droppable
            Container={ ChildrenUL }
            Directions={ ActionItems }
            id={ `nested-droppable-for-${ header }` }
            isDisabled={ isFull }
            isWrappingSortable
        /> : ChildrenUL }
    </SortableContext>
}

export default SortableWrapper;