import { useContext, useEffect, useState } from 'react';
// import { useNavigate } from "react-router-dom";
import CourseloadPane from './CourseloadPane';
import moduleInfo from '../../../constants/moduleInfo';
import UserContext from "../../../contexts/UserContext";
import LoadingHive from '../../modular/Loaders/LoadingHive';
import get from '../../../constants/get';
import styles from './Profile.module.css';
import fireRead from '../../../services/edding/readFireData';
import Nav from '../Nav';
import firePush from '../../../services/edding/pushFireData';
import AccountContext from '../../../contexts/AccountContext';
import ModalContainer from '../ModalContainer';
import Settings from '../../modular/Settings/Settings';
import Button from '../Button';

const stripe_portal = process.env.REACT_APP_STRIPE_CUSTOMER_PORTAL;

const Profile = () => {
    // const navigate = useNavigate();
    const user = useContext( UserContext );
    const { callLogout } = useContext( AccountContext );
    // console.log( '👤 userContext  ( Profile.js )', user );

    document.title = 'Dashboard | BumbleBoard';
    const [ modalIsOpen, setModalIsOpen ] = useState( false );

    // const [ rules, setRules ] = useState({
    //     changed: false,
    //     board: !user.plan.isFree ? user.premium.rules.board
    //         : { isLinear: true, isWeekly: true, prereq: 3, req: 2, size: 8 },
    //     boardOriginal: !user.plan.isFree ? user.premium.rules.board
    //         : { isLinear: true, isWeekly: true, prereq: 3, req: 2, size: 8 }
    // });

    // get courses
    useEffect( () => {
        async function fetchCourses( fetchQueen=true ) {
            await Promise.all( user.courseIDs[ fetchQueen ? 'queen' : 'worker' ].map(
                courseID => fireRead.findCourseDocData( courseID, user.schoolYearID, [ 'Assignments', 'Boards' ] )
            ) ).then( courses => {
                // console.log( '🟧', courses );
                user.updateUserObj(
                    [ 'courses' ],
                    [ { ...( user.courses || [] ), [ fetchQueen ? 'queen' : 'worker' ]: courses } ]
                );

                // console.log( `${ fetchQueen ? '👑' : '🐝' } Fetched ${ fetchQueen ? 'queen' : 'worker' } courses`, user.courseIDs, user.courses );
            } );
        };

        const mustFetchCourses = ( key='' ) => 'courses' in user === false
            || user?.courses?.[ key ]?.length !== user?.courseIDs?.[ key ]?.length

        if ( user.data && mustFetchCourses( 'queen' ) ) fetchCourses( true )
        if ( user.data && mustFetchCourses( 'worker' ) ) fetchCourses( false )

    }, [ user ] )

    // get counties, schoolyears, & schools
    useEffect( () => {
        window.location.hostname === 'localhost' && get.log({
            fileName: 'Profile.jsx',
            functionDirectionsOrComponentName: 'the user object as fetched from db (in useEffect)',
            lineNumber: 62,
            str: user,
        });
        async function fetchCounties() {
            await Promise.all( user.countyIDs.map(
                countyID => fireRead.findCountyByID( countyID, user.schoolYearID, user.schoolIDs )
            ) ).then( async counties => {
                const isMissingSchoolYear = counties.some( county => !county?.schoolYear?.months );
                // console.log( counties, isMissingSchoolYear );

                if ( isMissingSchoolYear ) await Promise.all( counties.map(
                    county => county.schoolYear?.months ? county.schoolYear
                        : county.id === 'us-no-county' ? firePush.pushSchoolYear({ id: county.id })
                        : fireRead.findCountyByID( 'us-no-county' )
                ) ).then( schoolYears => counties.forEach(
                    ( county, i ) => county.schoolYear = schoolYears[ i ]
                ) );

                user.updateUserObj( [ 'counties' ], [ counties ] );
                // console.log( '🔍 Fetched counties & schools', counties );
            } );
        }

        if ( user.data && 'counties' in user === false ) fetchCounties()
    }, [ user ] )

    const { profile: menu } = moduleInfo.navMenus;
    user.email && setNavPaths();

    // FUNCTIONS
    function callLogoutAndRefresh( e ) {
        e.preventDefault();
        callLogout();
    }

    function callStripeCustomerPotal() {
        // This is the TEST link. needs to be replaced with prod using env variables
        window.open(stripe_portal, "_blank")
    }

    // function changeRules({ target: { id, value } }) {
    //     setRules( rules => {
    //         const hasChanged = rules.board[ id ] !== rules.boardOriginal[ id ];

    //         return {
    //             ...rules,
    //             changed: hasChanged,
    //             board: { ...rules.board, [ id ]: value }
    //         }
    //     } )
    // }

    // function saveNewRules() { console.log( 'get form elements & update state with their values' ) }

    // function getSubjects( courseIDs=[ '' ], user={}, subjects=[] ) {
    //     const courseID = courseIDs[ 0 ];
    //     if ( !courseID ) return subjects

    //     const { subject } = user.courses.find( course => course.id.db === courseID );
    //     if ( !subjects.includes( subject ) ) subjects.push( subject )

    //     return getSubjects( courseIDs.slice( 1 ), user, subjects )
    // }

    // function getSchoolNames( schoolIDs=[], names=[] ) {
    //     let schoolID = schoolIDs[ 0 ];
    //     if ( !schoolID ) return names

    //     if ( schoolID.includes( '-school' ) ) schoolID = schoolID.split( '-school' )[ 0 ]
    //     const suffix = [ 'high', 'middle', 'elementary' ].find( level => schoolID.endsWith( level ) );
    //     schoolID = schoolID.split( '-' );

    //     if ( suffix ) schoolID.pop()
    //     return getSchoolNames( schoolIDs.slice( 1 ), [ ...names, functions.capitalise( schoolID ) ] )
    // }

    // function stringifyArray( arr=[ '' ] ) {
    //     const len = arr.length;

    //     return len < 2 ? arr[ 0 ] : arr.map(
    //         ( e, i ) => i === len - 2 ? `${ e }${ len > 2 ? ',' : '' } &` : e
    //     ).join(' ')
    // }

    function getCourseCards( userIsQueenBee=true ) { return <CourseloadPane userIsQueenBee={ userIsQueenBee }/> }

    function setNavPaths() {
        for ( let i = 1; menu.labels[ i ]; i++ ) {
            const label = menu.labels[ i ];
            label.to = [ '', get.username( user.email ), label.label ].join( '/' );
        }
    }

    function toggleModal() { setModalIsOpen( modalIsOpen => !modalIsOpen ) }

    // PROPS & ATTRIBUTES
    const props = {
        className: styles.kebab,
        menuIcons: [ 'fa-solid fa-gear', 'fa-solid fa-door-open', 'fa-solid fa-cart-shopping', user.plan?.isFree ? '🍯' : '👑' ],
        menuLabels: [ 'settings', 'logout', 'subscription', user.plan?.isFree ? 'upgrade' : 'Premium' ],
        menuOnClicks: [ toggleModal, callLogoutAndRefresh, callStripeCustomerPotal,() => {} ],
    }
    if ( user.email === 'bumbleboard@gmail.com' ) props.rightElement = <ul className={ styles[ 'page-links' ] }>{ [
        'shop', 'admin'
    ].map( ( e, i ) => <li key={ 'link-to-' + e }>
        <Button
            className={ styles[ 'page-link' ] }
            isLink
            looksLikeLink
            label={ i === 0 ? <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32">
                <path fill="orangered" d="M17 24h4v4h-4zm7 0h4v4h-4zm-7-7h4v4h-4zm7 0h4v4h-4z"/>
                <path fill="orange" d="M28 11h-6V7c0-1.7-1.3-3-3-3h-6c-1.7 0-3 1.3-3 3v4H4c-.6 0-1 .4-1 1v.2l1.9 12.1c.1 1 1 1.7 2 1.7H15v-2H6.9L5.2 13H28v-2zM12 7c0-.6.4-1 1-1h6c.6 0 1 .4 1 1v4h-8V7z"/>
            </svg> : <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 24 24">
                <path fill="orange" d="M12 23C6.443 21.765 2 16.522 2 11V5l10-4l10 4v6c0 5.524-4.443 10.765-10 12ZM4 6v5a10.58 10.58 0 0 0 8 10a10.58 10.58 0 0 0 8-10V6l-8-3Z"/>
                <circle cx="12" cy="8.5" r="2.5" fill="orangered"/>
                <path fill="orangered" d="M7 15a5.782 5.782 0 0 0 5 3a5.782 5.782 0 0 0 5-3c-.025-1.896-3.342-3-5-3c-1.667 0-4.975 1.104-5 3Z"/>
            </svg> }
            linkTarget={ [ '', get.username( user.email ), e ].join( '/' ) }
        />
    </li> ) }</ul>

    // const props = {
    //     InfoButton: {
    //         arrowDirection: 'down',
    //         style: { '--width': '13rem' },
    //     },
    //     liRules: {
    //         className: [
    //             styles.rules,
    //             styles[ user.plan.isFree ? 'free-plan' : 'premium-plan' ]
    //         ].join(' ')
    //     },
    //     TextInput: {
    //         common: {
    //             className: styles[ 'rule-input' ],
    //             isDisabled: user.plan.isFree,
    //             passTargetUp: true,
    //             passValueUp: changeRules,
    //             type: 'number',
    //         },
    //         size: { value: rules.board.size },
    //         prereq: { value: rules.board.prereq },
    //         req: { value: rules.board.req },
    //     },
    // };
    // props.TextInput.size.id = props.TextInput.size.name = 'size';
    // props.TextInput.prereq.id = props.TextInput.prereq.name = 'prereq';
    // props.TextInput.req.id = props.TextInput.req.name = 'req';

    // props.TextInput.size = { ...props.TextInput.common, ...props.TextInput.size };
    // props.TextInput.prereq = { ...props.TextInput.common, ...props.TextInput.prereq };
    // props.TextInput.req = { ...props.TextInput.common, ...props.TextInput.req };

    // COMPONENTS
    // const SideBar = <section>
    //     <header>
    //         <img alt='avatar' src={ details.avatarSRC }/>
    //         <h2>{ details.name }</h2>
    //         <ul>
    //             <li><i className='fa-solid fa-school'/> { details.school }</li>
    //             <li><InfoButton
    //                 arrowDirection='down'
    //                 iconComponent={ <span><i className='fa-solid fa-chalkboard-user'/> { details.title }</span> }
    //                 pElement={ <p>{ details.position }</p> }
    //                 style={{ 'left': '-1.25rem', 'transform': 'translateY( .5rem )' }}
    //             /></li>
    //             <li>{ todayDate }</li>
    //         </ul>
    //     </header>
    //     <ul className={ styles.details }>
    //         <li><i className='fa-solid fa-paper-plane'/> <a href={ details.email }>@{ details.email.split( '@' )[ 0 ] }</a></li><br />
    //         <li { ...props.liRules }><i className='fa-solid fa-crown'/> Board Settings:<br /><ul>
    //             <li className={ styles.rule }>
    //                 <span className={ styles[ 'setting-header' ] }>Size</span>&nbsp;
    //                 <InfoButton { ...props.InfoButton }
    //                     pElement={ <p>Students will see a board with <span>{ rules.board.size } (max.)</span> choices</p> }
    //                 /><br />
    //                 <TextInput { ...props.TextInput.size }/>
    //             </li>
    //             <li className={ styles.rule }>
    //                 <span className={ styles[ 'setting-header' ] }>Boxes</span>&nbsp;
    //                 <InfoButton { ...props.InfoButton } pElement={ <p>
    //                     Students complete <span>at least { rules.board.req + rules.board.prereq }</span>/{ rules.board.size } boxes per board<br /><br />
    //                     <span>{ rules.board.req }</span> chosen by the teacher<br />
    //                     <span>{ rules.board.prereq }</span> chosen by the student
    //                 </p> }/><br />
    //                 <ul className={ styles.boxes }>
    //                     <li>
    //                         <span className={ styles[ 'sub-setting' ] }>Req:</span>&nbsp;
    //                         <TextInput { ...props.TextInput.req }/>
    //                     </li>
    //                     <li>
    //                         <span className={ styles[ 'sub-setting' ] }>Prereq:</span>&nbsp;
    //                         <TextInput { ...props.TextInput.prereq }/>
    //                     </li>
    //                 </ul>
    //             </li>
    //             <li className={ styles.rule }>
    //                 <span className={ styles[ 'setting-header' ] }>Continuity</span>&nbsp;
    //                 <InfoButton { ...props.InfoButton }
    //                     style={{ '--width': '15rem' }}
    //                     pElement={ !user.premium || user.premium.rules.board.isLinear ? <p>
    //                     Students <span>must</span> complete boards in order<br /><br />
    //                     Completing this week's board will <span>not</span> affect their progress until they complete <span>all previous ones</span>
    //                 </p> : <p>Students can complete boards in <span>any order</span></p> }/><br />
    //                 { !user.premium || user.premium.rules.board.isLinear ? 'linear' : 'NONlinear' }
    //             </li>
    //         </ul></li><br />
    //     </ul>
    // </section>;

    // RENDER
    return <article className={ styles.profile }>
        <Nav { ...props }/>

        <section className={ styles.courses }>{
            user.courses && 'queen' in user.courses && 'worker' in user.courses && 'counties' in user ?
                getCourseCards( true ) : <LoadingHive width='2rem'/>
        }</section>

        <ModalContainer
            Children={ modalIsOpen && <Settings /> }
            isOpenFromParent={ modalIsOpen }
            maxWidthPercent={ 80 }
            toggleModalFromParent={ toggleModal }
        />
    </article>
}

export default Profile;