import { useContext, useEffect, useState } from 'react';
import styles from './Planner.module.css'
import HiveContext from '../../../../contexts/HiveContext';
import UnitsBanner from '../../../modular/Unit/UnitsBanner';
import UserContext from '../../../../contexts/UserContext';
import get from '../../../../constants/get';
import WeekContainer from './WeekContainer';
import AssignmentBar from './AssignmentBar';
import AssignmentEditor from '../BoardView/AssignmentEditor';
import SVG from '../../../modular/Images/SVG';
import Button from '../../Button';
import { DndContext } from '@dnd-kit/core';
import { arrayMove } from '@dnd-kit/sortable';
import Draggable from '../../../modular/DragAndDrop/Draggable';
import DragOverlayComponent from '../../../modular/DragAndDrop/DragOverlayComponent';
import courseInfo from '../../../../constants/courseInfo';

const Planner = () => {
    const { course, currentBoard, placeholderBoards, rules, schoolYear } = useContext( HiveContext );
    const { updateCourseUnits, updateAssignments } = useContext( UserContext );
    const { assignments, boards } = course.content;
    const units = course.content.units || [];

    const [ active, setActive ] = useState({
        assignment: { i: 0, obj: getBoardAssignments( currentBoard )[ 0 ] },
        board: {
            completion: Array( 9 ).fill().map( ( e, i ) => i === 4 ? false
                : !!getBoardAssignments( currentBoard )[ i < 4 ? i : ( i + 1 ) ]?.directions ),
            obj: currentBoard },
        id: null,
        ids: [],
        unit: { i: -1, obj: {} },
    });

    const [ mode, setMode ] = useState({
        edit: false,
        initial: true,
        planning: false,
    });

    // Update unit weekNums when units change
    useEffect( () => {
        const units = course.content.units || [];
        window.location.hostname === 'localhost' && console.log( 'updating unit weekNums...' );
        addUnitWeeksAndBoards( units, schoolYear, placeholderBoards );
    }, [ course.content.units, placeholderBoards, schoolYear ] )

    const assignmentsArr = get.assignmentsArr( assignments );

    const themes = units.length ? {
        backgrounds: getThemeDetail( units, 'background' ),
        blocks: getThemeDetail( units, 'blocks' ),
        titles: getThemeDetail( units, 'theme' ),
    } : { backgrounds: [], blocks: [], titles: [] };

    const boardsArr = mode.initial ? [] : active.unit.obj.boardNums;

    // console.log(
    //     'UNITS:', units,
    //     '\n\BOARD NUMS:', boardsArr,
    //     '\n\nCOURSE ASSIGNMENTS:', course.content.assignments,
    //     '\n\nASSIGNMENTS:', assignments,
    //     '\n\nRULES:', rules, '\n\nSCHOOL YEAR:', schoolYear,
    //     active.unit.i >= 0 && '\n\nACTIVE UNIT:', active.unit.i >= 0 && active.unit.obj
    // );

    // FUNCTIONS
    function addAssignment({ target: { id } }) {
        const boardNum = id.split( '-' ).pop() * 1;
        const board = getBoard( boardNum );
        const boardAssignments = getBoardAssignments( board );
        const completion = get.boardCompletion( boardAssignments );
        // console.log( id, boardNum, board, boardAssignments, completion );

        setMode( () => ({ ...setAllModesToFalse(), edit: true }) );
        setActive( active => ({
            ...active,
            assignment: { i: 0, obj: boardAssignments[ 0 ] },
            board: { completion, obj: board }
        }) );

        updateAssignments( boardAssignments, board, course );
    }

    function changeAssignment({ target: { id } }) {
        // console.log( 'CHANGING ASSIGNMENT', id );
        if ( !id ) return setMode( () => ({ ...setAllModesToFalse(), planning: true }) )
        let [ boardNum, , i ] = id.split( '-' ).slice( 1 );

        // console.log( id, boardNum, i );
        i *= 1;
        const boardAssignments = assignments[ boardNum ];
        // console.log( 'NEW-I:', i );

        setActive( active => ({
            ...active,
            assignment: { i, obj: boardAssignments[ i ] },
            board: {
                completion: get.boardCompletion( boardAssignments ),
                obj: boards[ boardNum ] || placeholderBoards[ boardNum ]
            }
        }) )
    }

    function changeUnit({ target: { id } }) {
        const getI = ( isPrevious=true ) => {
            const [ activeUnitI, increment ] = [ active.unit.i, isPrevious ? -1 : 1 ];
            const newI = activeUnitI + increment;

            return newI < 0 ? units.length - 1
                : newI >= units.length ? 0
                : newI
        }

        const i = getI( id.includes( 'previous' ) );
        setActive( active => ({ ...active, unit: { i, obj: units[ i ] } }) )
    }

    function getAssignmentBar( assignment={} ) {
        const isActiveAssignment = !assignment.id;
        if ( active.id && isActiveAssignment ) {
            const [ , boardI, , assignmentI ] = active.id.split( '-' );
            assignment = assignments[ boardI ][ assignmentI ];
        }

        const props = {
            assignment, activeUnitBoardNums: boardsArr,
            className: [
                boardsArr.includes( assignment.boardNum ) && styles.disabled,
                !isActiveAssignment && assignment.id === active.id && styles.active,
            ],
            idPrefix: isActiveAssignment ? 'overlay' : '',
            isDragOverlay: isActiveAssignment,
        };

        props.showCopyButton = props.showEditButton = false;
        return <AssignmentBar { ...props }/>
    }

    function getBoard( boardNum=0 ) { return boards[ boardNum ] || placeholderBoards[ boardNum ] }

    function getBoardAssignments( board={} ) {
        if ( board?.num ) {
            const { num: boardNum } = board;

            return assignments[ boardNum ] || get.boardAssignments(
                course, boardNum, get.dueDayMonthYear( schoolYear.months, board )
            )
        } else { return [] }
    }

    function getDateHeader( isForOddDay=true ) {
        const dayType = isForOddDay ? 'odd': 'even';
        const [ endMonth, startMonth ] = [
            active.unit.obj?.months?.slice()?.reverse()?.find( month => month.days[ dayType ][ 0 ] ),
            active.unit.obj?.months?.slice()?.find( month => month.days[ dayType ][ 0 ] ),
        ];

        if ( !endMonth || !startMonth ) return ''
        const [ start, end ] = [ startMonth, endMonth ].map( ( month, i ) => get.date({
            year: schoolYear.months[ month.i ].year.full,
            month: month.i,
            day: i === 0 ? month.days[ dayType ][ 0 ] : get.lastArrayElement( month.days[ dayType ] )
        }, course.language, true, course.language.startsWith( 'en' ), ' ', false ) );

        return [ start, end ].join(' - ');
    }

    function handleDragStart({ active: { id } }) { setActive( active => ({ ...active, id }) ) }
    function handleDragOver({ active, over }) { window.location.hostname === 'localhost' && console.log( active?.id, 'IS ON TOP OF', over?.id ) }
    function handleDragEnd({ active, over }) {
        if ( over ) {
            const { assignments } = course.content;
            const [
                activeIsFromWeekContainer,
                isPlaceholderButton,
                isStudentChoiceContainer,
                isTeacherChoiceContainer
            ] = [
                active.id.startsWith( 'inContainerBoard' ),
                over.id.includes( 'inContainerBoard' ),
                over.id.includes( '-Student Choice ' ),
                over.id.includes( '-Teacher Choice ' ),
            ];

            const overIDArr = over.id.split( ( isStudentChoiceContainer || isTeacherChoiceContainer ) ? ' ' : '-' );
            const destinationBoardI = ( isPlaceholderButton ? overIDArr[ 1 ] : get.lastArrayElement( overIDArr ) ) * 1;

            const allDestinationBoardAssignments = assignments[ destinationBoardI ];
            const destinationBoardAssignments = allDestinationBoardAssignments.filter( assignment => assignment.directions );

            const copiedAssignmentI = isPlaceholderButton ? overIDArr[ 3 ] * 1
                : isTeacherChoiceContainer ? get.emptyAssignmentIndexInArr( allDestinationBoardAssignments, 0 )
                : isStudentChoiceContainer ? get.emptyAssignmentIndexInArr( allDestinationBoardAssignments, 2 )
                : destinationBoardAssignments.length;

            let newAssignmentsArr = [];
            let [ , startingBoardI, , assignmentI ] = active.id.split( '-' );
            startingBoardI *= 1;
            assignmentI *= 1;

            if ( activeIsFromWeekContainer && destinationBoardI === startingBoardI ) {
                newAssignmentsArr = arrayMove( allDestinationBoardAssignments, assignmentI, copiedAssignmentI );
                newAssignmentsArr.forEach( ( assignment, i ) => {
                    assignment.isPrereq = i > 1;
                    assignment.id = get.assignmentID( startingBoardI, i > 1, i );
                    assignment.title = get.assignmentTitle( startingBoardI, assignmentI );
                } )
                // console.log( assignments[ startingBoardI ], assignmentI, copiedAssignmentI, arrayMove( allDestinationBoardAssignments, assignmentI, copiedAssignmentI ) );
                assignments[ startingBoardI ] = newAssignmentsArr;
            } else {
                const assignment = assignments[ startingBoardI ][ assignmentI ];
                if ( destinationBoardAssignments.some( obj => obj.id === assignment.id ) ) return

                const { due: dueDate } = assignments[ destinationBoardI ][ 0 ].date;
                // console.log( destinationBoardI, copiedAssignmentI, dueDate, assignments, assignment );

                const copiedAssignment = get.assignmentObj(
                    copiedAssignmentI,
                    destinationBoardI,
                    copiedAssignmentI > 1,
                    assignment.category,
                    assignment.points,
                    [ dueDate.day, dueDate.month, dueDate.year ],
                    get.dayMonthYearToday(),
                    get.dayMonthYearToday()
                );
                // console.log( dueDate, copiedAssignment );

                copiedAssignment.isCopyOf = assignment.isCopyOf || { id: assignment.id };
                copiedAssignment.links = assignment.links;
                copiedAssignment.directions = assignment.directions;

                if ( assignment.copies ) {
                    assignment.copies.includes( copiedAssignment.id )
                    || assignment.copies.push( copiedAssignment.id )
                } else { assignment.copies = [ copiedAssignment.id ] }

                assignments[ destinationBoardI ][ copiedAssignmentI ] = copiedAssignment;
                newAssignmentsArr = assignments[ destinationBoardI ];
            }

            // console.log( newAssignmentsArr );
            updateAssignments( newAssignmentsArr, boards[ destinationBoardI ], course );
        }
        setActive( active => ({ ...active, id: null }) )
    }

    function setAllModesToFalse() {
        return Object.fromEntries( Object.keys( mode ).map( key => [ key, false ] ) )
    }

    function toggleMode({ target: { id } }) {
        let assignment = id.split( '-' ).slice( 1 );
        const modeKey = ( id.endsWith( 'edit' ) || id.endsWith( 'initial' ) ) ? assignment.pop() : 'planning';

        const [ isPlanning, isInitial, isEdit ] = [
            modeKey === 'planning',
            modeKey === 'initial',
            modeKey === 'edit'
        ];

        // console.log( 'ID:', id, '\nASSIGNMENT:', assignment, '\nMODE-KEY:', modeKey );
        if ( isEdit ) changeAssignment({ target: { id: id.split( 'activate-' )[ 1 ] } })
        if ( isInitial ) setActive( active => ({ ...active, unit: { i: -1, obj: {} } }) )
        if ( isPlanning ) {
            const i = assignment.pop()[ 0 ] * 1;
            setActive( active => ({ ...active, unit: { i, obj: units[ i ] } }) )
        }

        setMode( () => ({ ...setAllModesToFalse(), [ modeKey ]: true }) );
    }

    function updateUnits( oldI=0, newI=0 ) {
        const isEditedUnits = typeof oldI === 'object';
        const newThemesObj = { ...( isEditedUnits ? oldI : themes ) };
        let newUnits = [];

        if ( !isEditedUnits ) [ newThemesObj.blocks, newThemesObj.titles ] = [
            arrayMove( themes.blocks, oldI, newI ), arrayMove( themes.titles, oldI, newI )
        ]

        newThemesObj.titles.forEach( ( theme, i ) => {
            let unit = units[ i ] ? { ...units[ i ] } : null;
            let prevUnitMonthDay = !units[ i - 1 ] ? [] : [ units[ i - 1 ].end.month, units[ i - 1 ].end.day ];
            const prevUnit = i > 0 && get.lastArrayElement( newUnits );
            console.log({ unit, prevUnit, prevUnitMonthDay, prevUnitFromUnits: units[ i - 1 ] })

            if ( !unit
                || unit.blocks !== newThemesObj.blocks[ i ]
                || ( prevUnitMonthDay
                    && ( prevUnit?.end?.month !== prevUnitMonthDay?.[ 0 ]
                    || prevUnit?.end?.day !== prevUnitMonthDay?.[ 1 ]
                )
            ) ) {
                const prevUnitLastMonth = i > 0 && get.lastArrayElement( prevUnit.months );

                let [ startingWeekNum, startingMonthNum, boardNumsStart ] = [
                    prevUnitLastMonth ? get.lastArrayElement( prevUnitLastMonth.weekNums )
                        : Object.entries( schoolYear.months[ schoolYear.start.month ].weeks ).find(
                            weekKeyVal => weekKeyVal[ 1 ].days.includes( schoolYear.start.day )
                        )[ 0 ] * 1,
                    prevUnitLastMonth ? prevUnitLastMonth.i : schoolYear.start.month,
                    0
                ];
                // debugger;

                if ( prevUnit ) {
                    boardNumsStart = get.lastArrayElement( prevUnit.boardNums ) || 0;
                    const nextDayI = schoolYear.months[ startingMonthNum ].weeks[ startingWeekNum ].days.findIndex(
                        dayNum => dayNum === prevUnit?.end?.day
                    );

                    if ( nextDayI === 4 ) {
                        startingWeekNum++ && boardNumsStart++;
                        startingMonthNum = schoolYear.months[ startingMonthNum ].weeks[ startingWeekNum ]?.months?.[ 0 ]
                            || schoolYear.months[ startingMonthNum ].nextI;
                    } else if ( nextDayI >= 0 ) {
                        startingMonthNum = schoolYear.months[ startingMonthNum ].weeks[ startingWeekNum ]?.months?.[ nextDayI + 1 ]
                    }
                }

                unit = schoolYear.months[ startingMonthNum ].weeks[ startingWeekNum ] ? courseInfo.getUnitObj({
                    boardNumsStart, schoolYear, startingMonthNum, startingWeekNum, theme,
                    background: newThemesObj.backgrounds[ i ],
                    blocks: newThemesObj.blocks[ i ] * 1,
                    level: unit?.level || course.level || 'all',
                    subject: unit?.subject || course.subject,
                }) : [];

                console.log(`if !unit || unit.blocks (${ unit.blocks }) !== newThemesObj.blocks[ ${ i } ] (${ newThemesObj.blocks[ i ] }) newUnit.push({ unit }) ->`, { unit });
            } else {
                unit.background = newThemesObj.backgrounds[ i ];
                unit.theme = theme;
            }

            newUnits.push( unit );
        } );

        // debugger;
        get.log({
            fileName: 'Planner.js',
            functionDirectionsOrComponentName: '🟢 { newThemesObj } & { newUnit }',
            lineNumber: 333,
            str: { newThemesObj, newUnits, schoolYear: schoolYear.id }
        });
        updateCourseUnits( course, newUnits, schoolYear.id );
    }

    // PROPS & ATTRIBUTES
    const props = {
        Draggable: {
            common: {
                dragHandleIsDotted: true,
                dragHandleIsOn: { left: true },
                role: 'assignment'
            },
        },
        UnitsBanner: {
            themes, units,
            className: styles.banner,
            updateUser: updateUnits,
            onClick: e => toggleMode( e ),
        },
        WeekContainer: {
            addAssignment, rules, toggleMode,
            idPrefix: 'weekbar',
            unit: active.unit.obj?.background ? active.unit.obj
                : { background: get.randomHSL( false ) },
        }
    };

    // COMPONENTS
    const Assignments = assignmentsArr.map( assignment => !assignment.isCopyOf?.id && <section
        className={ styles[ 'assignment-list' ] }
        key={ `assignment-list-for-${ assignment.id }` }
    >
        <header><Draggable
            { ...props.Draggable.common }
            Component={ getAssignmentBar( assignment ) }
            id={ assignment.id }
            roleDescription='draggable original assignment'
        /></header>
        { assignment.copies && <ul className={ styles.copies }>{ assignment.copies.map(
            copyID => {
                const [ , boardNum, , assignmentI ] = copyID.split( '-' );
                const copyAssignment = course.content.assignments[ boardNum ][ assignmentI ];
                // console.log( copyAssignment, boardNum, assignmentI );

                return <li><Draggable
                    { ...props.Draggable.common }
                    Component={ getAssignmentBar( copyAssignment ) }
                    id={ copyID }
                    roleDescription='draggable assignment copy'
                /></li>
            }
        ) }</ul> }
    </section> ).filter( e => e );

    const [ LeftCaret, RightCaret ] = [ 'left', 'right' ].map( e => {
        const [ unitI, isLeft ] = [ active.unit.i, e === 'left' ];
        const isAtLimit = ( isLeft && unitI === 0 ) || ( !isLeft && unitI === ( units.length - 1 ) );
        const background = active.unit.obj?.theme ? active.unit.obj.background : get.randomHSL( false );

        const isYellowOrLime = background.h < 60 && background.h > 40;
        const hsl = `hsl( ${[ background.h, background.s, isYellowOrLime ? '45%' : background.l ].join(', ') } )`;

        const props = {
            className: styles.caret,
            id: isAtLimit ? 'activate-initial' : `${ isLeft ? 'previous' : 'following' }-unit`,
            label: <SVG fontSize='1.5em' fill1={ hsl } type={{ [ isAtLimit ? 'add to group' : `${ e } caret` ]: true }}
            />,
            looksLikeLink: true,
            onClick: isAtLimit ? toggleMode : changeUnit,
        };

        return <Button { ...props } key={ props.id }/>
    } );
    // console.log( '✨', Assignments[ 0 ].props.assignment );
    // console.log( 'UNITS:', ...units );
    // console.log( 'ACTIVE', active );

    const [ AssignmentEditingSection, AssignmentsSection, UnitHeader, WeeksSection ] = [
        <section className={ styles.editor }>
            <AssignmentEditor
                activeSquare={ active.assignment.i }
                assignments={ getBoardAssignments( active.board.obj ) }
                board={ active.board.obj }
                completion={ active.board.completion }
                hsl={ get.hslFromHex( active.unit.obj?.background?.hex || '#fdfdfd' ) }
                showBoardNum
                updateActiveSquare={ changeAssignment }
            />
        </section>, <section className={ styles.assignments }>
            <header>ALL ASSIGNMENTS</header>
            <ul>{ Assignments }</ul>
        </section>, <nav className={ units.length > 1 ? styles.multiple : '' }>
            { units.length > 1 && LeftCaret }
            <div className={ styles.unit }>
                <header className={ styles.theme }>{ active.unit.obj?.theme }</header>
                <div className={ styles[ 'date-range' ] }>
                    <span className={ styles.odd }>{ active.unit.i < 0 || getDateHeader( true ) }</span><br />
                    <span className={ styles.even }>{ active.unit.i < 0 || getDateHeader( false ) }</span>
                </div>
                {/* <br /> */}
            </div>
            { units.length > 1 && RightCaret }
        </nav>, <section className={ styles.weeks }>
            <ul>{ boardsArr.map( boardNum => {
                const boardProps = { board: getBoard( boardNum ) };
                // if ( boardProps.board !== boardNum ) boardProps.board.num = boardNum

                boardProps.key = `bar-board-${ boardNum }`;
                boardProps.assignments = getBoardAssignments( boardProps.board )
                boardProps.filteredAssignments = boardProps.assignments.filter(
                    assignment => assignment.directions
                );

                return <WeekContainer { ...props.WeekContainer } { ...boardProps }/>
            } ) }</ul>
        </section>,
    ];
    // console.log( active.id, Assignments );

    // RENDER
    return <DndContext
        onDragStart={ handleDragStart }
        onDragOver={ handleDragOver }
        onDragEnd={ handleDragEnd }
    >
        <article className={ get.classNameFromArr([
            styles.page,
            mode.edit && styles.edit,
            mode.initial ? styles.initial : styles.planning,
        ]) }>{ mode.initial ? <UnitsBanner { ...props.UnitsBanner }/> : <>
            { UnitHeader }
            { AssignmentsSection }
            { WeeksSection }
            { mode.edit && AssignmentEditingSection }
        </> }</article>

        <DragOverlayComponent
            activeID={ !!active.id }
            child={ getAssignmentBar() }
        />
    </DndContext>
}

// ============================================
// MORE FUNCTIONS
function addUnitWeeksAndBoards( units=[], schoolYear={}, placeholderBoards=[] ) {
    if ( units.length < 2 ) return
    const { months, start } = schoolYear;

    units.forEach( ( unit, i ) => {
        const prevUnit = units[ i - 1 ];
        const { blocks, background, level, subject, theme, duration: { boards: numOfBoards } } = unit;

        let [ boardNumsStart, startingMonthNum ] = prevUnit ? [
            get.lastArrayElement( prevUnit.boardNums ), get.lastArrayElement( prevUnit.months ).i,
        ] : [ 0, start.month ];

        let startingWeekNum = i === 0 ? Object.entries( months[ startingMonthNum ].weeks ).find(
            weekNumAndObj => weekNumAndObj[ 1 ].days.find(
                ( day, j ) => weekNumAndObj[ 1 ].months[ j ] === startingMonthNum && day === start.day
            )
        )[ 0 ] * 1 : get.lastArrayElement( prevUnit.weekNums );

        const nextDayI = months[ startingMonthNum ].weeks[ startingWeekNum ].days
            .findIndex( dayNum => dayNum === prevUnit?.end?.day );

        if ( nextDayI === 4 ) {
            startingWeekNum++ && boardNumsStart++;
            startingMonthNum = months[ startingMonthNum ].weeks[ startingWeekNum ]?.months?.[ 0 ]
                || months[ startingMonthNum ].nextI;
        } else if ( nextDayI >= 0 ) {
            startingMonthNum = months[ startingMonthNum ].weeks[ startingWeekNum ]?.months?.[ nextDayI + 1 ]
        }

        unit = courseInfo.getUnitObj({ background, blocks, boardNumsStart, level, numOfBoards, schoolYear, startingMonthNum, startingWeekNum, subject, theme });
    } );
}

function getThemeDetail( units=[], key='', arr=[] ) {
    const i = arr.length;
    return !units[ i ] ? arr : getThemeDetail( units, key, [ ...arr, units[ i ][ key ] ] )
}

// EXPORT
export default Planner