import styles from './Curriculum.module.css';
import get from '../../../../constants/get';
import UnitTile from './UnitTile';
import Wrapper from '../../Wrapper';
import Button from '../../Button';

const languages = [ 'english', 'french', 'français', 'spanish', 'español', 'deutsch', 'german', 'japanese', 'nihongo' ];
const plusIcon = '✚';

const Curriculum = ({
    changeSubject = () => {},
    className = '',
    curriculum = {},
    isForm = false,
    subject = '',
    unitsView = {},
    updateUnits = () => {}
}) => {
    console.log({ curriculum, subject, unitsView });

    if ( !curriculum.subject ) curriculum.subject = subject
    if ( !curriculum.department ) curriculum.department = {
        name: isLanguage( subject ) ? 'world languages' : 'N/A',
        subjects: isLanguage( subject ) ? [ 'french', 'spanish', 'german', 'english-wl' ] : [ subject ]
        // subjects: isLanguage( subject ) ? {
        //     french: { icon: '🇫🇷', subject: 'français' },
        //     spanish: { icon: '🇪🇸', subject: 'español' },
        //     german: { icon: '🇩🇪', subject: 'deutsch' },
        //     japanese: { icon: '🇯🇵', subject: 'nihongo' },
        // } : { [ subject ]: { icon: curriculum.icon || '🫥', subject: subject } }
    }

    // FUNCTIONS
    // function handleActiveSubject({ target: { id } }) { setActiveSubject( () => id.slice( 10 ) ) }

    function fillRows() {
        const levels = curriculum.levels.arr.slice();
        if ( isForm ) levels.push( null )

        const unitsByLevelMatrix = levels.map( level => !level ? [{ icon: plusIcon }]
            : Object.values( curriculum.levels[ level ] ) );

        const maxRows = Math.max( ...unitsByLevelMatrix.map( arr => arr.length ) );
        const rows = Array( maxRows + ( isForm ? 2 : 1 ) ).fill()
            .map( ( e, rowI ) => rowI > 0 ? [] : levels.map( level => ({
                isForm, level,
                isAddButton: level === null,
                isHeader: true,
                isInDB: level !== null && subject[ 0 ] !== subject[ 0 ].toLocaleUpperCase(),
                subject: subject,
                unit: {},
                unitI: rowI
            }) ) );

        levels.forEach( ( level, columnI ) => unitsByLevelMatrix[ columnI ].forEach(
            ( unit, rowI ) => {
                const cellDetails =  {
                    isForm, level, unit,
                    department: curriculum.department,
                    handleButtonFromParent: updateUnits,
                    isAddButton: level === null,
                    isExpanded: level !== null
                        && ( unitsView[ subject ].showAll || unitsView[ subject ][ level ] ),
                    isPlaceholder: level === null || !subject || unit?.isPlaceholder,
                    isInDB: level !== null && subject[ 0 ] === subject[ 0 ].toLocaleUpperCase(),
                    subject: subject,
                    unitI: rowI
                };

                rowI++;
                // if we're starting at column > 1, pad the nested row array with fillers
                if ( columnI && !rows[ rowI ][ columnI - 1 ] ) rows[ rowI ].push( ...Array( columnI )
                    .fill().map( ( e, fillerI ) => ({ ...cellDetails, fillerI }) ) )

                // push { cellDetails } to its place
                rows[ rowI ].push({ ...cellDetails } );
            } )
        );

        return rows
    }

    // COMPONENTS
    const CurriculumNav = <nav className={ styles[ 'curriculum-nav' ] }>
        <header className={ styles.department }>{ curriculum.department.name }</header>

        <ul>{( Array.isArray( curriculum.department.subjects ) ? curriculum.department.subjects
            : Object.keys( curriculum.department.subjects )
        ).map( subj => {
            const obj = Array.isArray( curriculum.department.subjects ) ? {}
                : curriculum.department.subjects[ subj ];

            const [ icon, isActiveSubject ] = [
                obj.flag || obj.icon || '🫥',
                subject.toLocaleLowerCase() === subj.toLocaleLowerCase()
            ];

            return <li
                className={ get.classNameFromArr([
                    styles[ 'subject-nav-item' ],
                    isActiveSubject && styles.active
                ]) }
                key={[
                    'nav',
                    get.stringInNewFormat( curriculum.subject, ' ', { camelCase: true } ),
                    get.stringInNewFormat( subj, ' ', { camelCase: true } ),
                ].join( '-' ) }
            ><Button
                id={ `change-to-${ subj }` }
                label={ <>{ icon }<span className={ styles[ 'subject-name' ] }> { get.capitalise( subj ) }</span></> }
                isDisabled={ isActiveSubject }
                looksLikeLink={ isActiveSubject }
                onClick={ !isActiveSubject ? changeSubject : undefined }
            /></li>
        } ) }</ul>
    </nav>

    const UnitTiles = fillRows().map( rowArr => rowArr.map( cellDetails => <UnitTile { ...cellDetails }/> ) );

    // RENDER
    return <article className={ className }>
        <Wrapper
            div={ !isForm }
            form={ isForm }
            props={{
                className: styles[ 'curriculum-container' ],
                key: 'curriculum-wrapper'
            }}
            Children={<>
                { CurriculumNav }
                <section
                    className={ styles.curriculum }
                    key={{ key: [ 'curriculum', isForm ? 'form' : 'div' ].join( '-' ) }}
                    style={{ gridTemplateColumns: `repeat( ${ UnitTiles[ 0 ].length }, 1fr )` }}
                >{ UnitTiles }</section>
            </>}
        />
    </article>
}

// OTHER FUNCTIONS
function isLanguage( subject ) { return languages.includes( subject.toLocaleLowerCase() ) }

export default Curriculum;