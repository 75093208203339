const RoundedHoneycomb = ({ className='', borderColour=null, background=null, rotate=false, strokeWidth=null }) => {
    // PROPS & ATTRIBUTES
    const attributes = {
        svg: {
            className,
            viewBox: '0 0 120 100',
            style: { transition: '.3s ease-in-out', height: 'inherit' }
        },
        path: {
            d: [
                'M38,2',
                'L82,2',
                'A12,12 0 0,1 94,10',
                'L112,44',
                'A12,12 0 0,1 112,56',
                'L94,90',
                'A12,12 0 0,1 82,98',
                'L38,98',
                'A12,12 0 0,1 26,90',
                'L8,56',
                'A12,12 0 0,1 8,44',
                'L26,10',
                'A12,12 0 0,1 38,2',
            ].join(' '),
            stroke: borderColour || 'white',
            fill: background || 'white',
            strokeWidth: strokeWidth || '.25rem',
        },
    };

    if ( rotate ) attributes.svg.style.transform = 'rotate3d( 0, 0, 1, 90deg )'

    // RENDER
    return <svg { ...attributes.svg }> <path { ...attributes.path }/> </svg>
}

export default RoundedHoneycomb;