import { useCallback, useContext, useEffect, useMemo, useState } from 'react';
// import { useParams } from 'react-router-dom';
import get from '../../../../constants/get';
// import UserContext from '../../../../contexts/UserContext';
import Board from '../../../modular/Board/Board';
import BoardProgress from './BoardProgress';
import HiveContext from '../../../../contexts/HiveContext';
import AssignmentEditor from './AssignmentEditor';
// import Button from '../../Button';
import HiveSideNav from './HiveSideNav';
// import CheckBox from '../../../modular/Board/CheckBox';
import styles from './BoardView.module.css';
import ModalContainer from '../../ModalContainer';
import SVG from '../../../modular/Images/SVG';
import AttachmentContainer from '../../../modular/Attachment/AttachmentContainer';
import Button from '../../Button';

// const defaultBoardColours = {
//     q4: {
//         lightBox: 'hsl( 42, 82%, 90% )',
//         darkBox: 'hsl( 42, 82%, 63% )',
//         border: 'hsl( 42, 82%, 43% )',
//         hsl: { h: 42, s: 82, l: 63 }
//     },
//     q2: {
//         lightBox: 'hsl( 36, 81%, 90% )',
//         darkBox: 'hsl( 36, 81%, 63% )',
//         border: 'hsl( 36, 81%, 43% )',
//         hsl: { h: 36, s: 81, l: 63 }
//     },
//     q1: {
//         lightBox: 'hsl( 31, 80%, 89% )',
//         darkBox: 'hsl( 31, 80%, 62% )',
//         border: 'hsl( 31, 80%, 42% )',
//         hsl: { h: 31, s: 80, l: 62 }
//     },
// };

const BoardView = () => {
    // let { boardNum: urlBoardNum } = useParams();
    const {
        activeBoard,
        course,
        currentBoard,
        Header,
        isActuallyQueenBee,
        isQueenBee,
        schoolYear,
    } = useContext( HiveContext );

    const getCheckedList = useCallback( ( boardNum=-1 ) => {
        if ( boardNum < 0 ) boardNum = activeBoard.num
        const list = `${ boardNum }` in course.content.assignments === false ?
            [] : course.content.assignments[ boardNum ].filter(
                assignment => isQueenBee ? !!assignment.directions
                    : isActuallyQueenBee ? assignment.isFakeChecked
                    : assignment.isChecked
            ).map( assignment => assignment.id );

        // console.log( 'BOARDVIEW LIST:', list );
        return list
    }, [ activeBoard.num, course.content.assignments, isActuallyQueenBee, isQueenBee ] );

    // urlBoardNum -= 1; // index starts at 0, but URL will show the human number ( starts at 1)
    // const urlBoard = getOrFillBoard( urlBoardNum );
    // const urlWeekNum = urlBoard?.weekNum || 6;

    const [ activeSquare, setActiveSquare ] = useState( -1 );
    const [ isPlanning, setIsPlanning ] = useState( false );
    const [ checkedList, setCheckedList ] = useState( getCheckedList( activeBoard.num ) );

    useEffect(
        () => setCheckedList( () => getCheckedList( activeBoard.num ) ),
        [ activeBoard.num, getCheckedList, isQueenBee ]
    )

    document.title = `👑 Board ${ activeBoard?.num + 1 } ( ${ course.title } )`;
    const assignments = course.content.assignments?.[ activeBoard.num ] || get.boardAssignments(
        course, activeBoard.num, get.dueDayMonthYear( schoolYear.months, activeBoard )
    );

    const unit = useMemo( () => get.unit( activeBoard, course ) || {
        background: { ...get.randomHSL( false ) }
    }, [ course, activeBoard ] );
    window.location.hostname === 'localhost' && console.log( 'UNIT:', unit, 'BOARD:', activeBoard.num + 1, 'CHECKED LIST:', checkedList );


    // FUNCTIONS
    function checkBox({ target: { id } }) {
        const spliceI = checkedList.findIndex( boxID => boxID === id );

        setCheckedList( () => spliceI > 0 ? [
            ...checkedList.slice( 0, spliceI ), ...checkedList.slice( spliceI + 1 )
        ] : spliceI === 0 ? checkedList.slice( 1 ) : [ ...checkedList, id ] )
        // changeBoardOrQuarter( { board: true }, { obj: {} } )
    }

    const togglePlanningMode = useCallback( ( i=0 ) => setIsPlanning( oldIsPlanning => {
        const newIsPlanning = i >= 0;
        window.location.hostname === 'localhost' && oldIsPlanning !== newIsPlanning && console.log( `%c PLANNING MODE ${ newIsPlanning ? 'ON' : 'OFF' } `, `background: ${ newIsPlanning ? '#79f238' : '#f02727' }; color: #000; padding: 3px; border-radius: 3px` );

        return newIsPlanning
    } ), [] )

    const updateActiveSquare = useCallback( ({ target: { id } }) => {
        if ( id.includes( '-assignment-' ) ) {
            const assignmentI = id.split( '-' ).pop() * 1;
            id = assignments[ assignmentI ].id
        }

        const i = assignments.findIndex( assignment => assignment.id === id );
        setActiveSquare( () => i )
        setCheckedList( () => getCheckedList() )
        togglePlanningMode( i )
    }, [ getCheckedList, assignments, togglePlanningMode ] );

    // PROPS & ATTRIBUTES
    const props = {
        AssignmentEditor: { activeSquare, assignments, updateActiveSquare, completion: [] },
        ModalContainer: {
            updateParentState: updateActiveSquare,
            maxWidthPercent: 100,
            position: { bottom: true },
        },
        Board: {
            activeSquare, unit,
            assignmentsArr: assignments,
            checkboxes: [],
        },
        BoardProgress: {},
        HiveSideNav: {},
    };

    props.AssignmentEditor.hsl = props.BoardProgress.hsl = props.HiveSideNav.hsl = unit.background;
    props.Board.checkList = props.BoardProgress.checkedList = checkedList;
    props.ModalContainer.Children = <AssignmentEditor { ...props.AssignmentEditor }/>;

    Array( 9 ).fill().forEach( ( e, i ) => {
        const [ editorAssignment, checkboxAssignment ] = [
            assignments[ i > 1 ? i - 1 : i ], assignments[ i < 4 ? i : i - 1 ]
        ];

        props.AssignmentEditor.completion.push( i === 1 ? false : editorAssignment.directions );
        props.Board.checkboxes.push( i === 4 ? null : <AttachmentContainer
            asSquares
            EditButton={ <Button
                id={ checkboxAssignment.id }
                label={ <SVG type={{ [ currentBoard.num > checkboxAssignment.boardNum ? 'eye' : 'edit' ]: true }}/> }
                onClick={ updateActiveSquare }
            /> }
            CheckBoxProps={{
                defaultChecked: checkboxAssignment[
                    ( !isQueenBee && isActuallyQueenBee ) ? 'isFakeChecked' : 'isChecked'
                ],
                id: checkboxAssignment.id,
                onChange: checkBox
            }}
            links={ checkboxAssignment.links } // editorAssignment
            showButton={ isQueenBee }
            showCheckBox={ !isQueenBee }
        /> );
    } );

    // RENDER
    return <article className={ styles.container }>
        <header>{ Header }</header>

        <BoardProgress { ...props.BoardProgress }/>

        <section className={ styles.board }>
            <HiveSideNav { ...props.HiveSideNav }/>
            <Board { ...props.Board }/>
        </section>

        <ModalContainer
            { ...props.ModalContainer }
            isOpenFromParent={ isPlanning }
        />
    </article>
}

export default BoardView;