import { useState } from 'react';
import ToggleRadio from '../../../modular/Form/Parts/ToggleRadio';
import BoardIcon from '../../../modular/Images/BoardIcon';
import RadioGroup from '../../../modular/Form/Parts/RadioGroup';
import DateInput from '../../../modular/Form/Parts/DateInput';
import Pill from '../../../modular/Buttons/Pill';
import courseInfo from '../../../../constants/courseInfo';
import Button from '../../Button';
import SVG from '../../../modular/Images/SVG';
import AssignmentEditor from '../../Hive/BoardView/AssignmentEditor';
import ModalContainer from '../../ModalContainer';
import get from '../../../../constants/get';
import styles from './ShopItemAssignments.module.css';
import AttachmentContainer from '../../../modular/Attachment/AttachmentContainer';

const [ today, toggleLabels ] = [
    get.dayMonthYearToday().reverse(), [
    <BoardIcon emptyColour='orange'/>,
    <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 512 512">
        <path fill="#DCE2E2" d="M505.751 492.072c0 8.011-6.494 14.506-14.506 14.506H20.754c-8.011 0-14.506-6.494-14.506-14.506V76.329c0-8.011 6.494-14.506 14.506-14.506h470.492c8.011 0 14.506 6.494 14.506 14.506v415.743z"/>
        <path fill="#0096D1" d="M505.751 260.413V74.777c0-7.154-6.494-12.954-14.506-12.954H20.754c-8.011 0-14.505 5.8-14.505 12.954v185.636h499.502z"/>
        <path fill="#FFF" d="M131.004 107.585c0 12.637-10.244 22.881-22.881 22.881s-22.881-10.244-22.881-22.881s10.244-22.881 22.881-22.881s22.881 10.244 22.881 22.881zm260.115-22.881c-12.637 0-22.881 10.244-22.881 22.881s10.244 22.881 22.881 22.881S414 120.222 414 107.585s-10.244-22.881-22.881-22.881z"/>
        <path fill="#BFBFBB" d="M172.451 61.823h-22.214c0-19.108-15.546-34.655-34.654-34.655S80.928 42.715 80.928 61.823s15.546 34.655 34.655 34.655c6.134 0 11.107 4.973 11.107 11.107s-4.973 11.107-11.107 11.107c-31.357 0-56.869-25.511-56.869-56.869S84.226 4.955 115.583 4.955s56.868 25.511 56.868 56.868zM414.64 6.795a57.03 57.03 0 0 0-14.396-1.84c-31.357 0-56.868 25.511-56.868 56.869s25.511 56.869 56.868 56.869c6.135 0 11.107-4.973 11.107-11.107s-4.973-11.107-11.107-11.107c-19.108 0-34.653-15.546-34.653-34.655s15.545-34.655 34.653-34.655c2.982 0 5.938.377 8.785 1.121c15.231 3.976 25.869 17.766 25.869 33.534h22.215c-.001-25.873-17.467-48.501-42.473-55.029z"/>
        <path fill="#FFF" d="M68.534 208.183h-16.88l-2.911 12.39H34.608l17.379-57.624h16.547l17.379 57.624H71.445l-2.911-12.39zm-2.411-9.896l-6.07-25.444l-6.069 25.444h12.139zm67.349 3.16c0 11.641-7.484 20.539-22.534 20.539s-22.452-8.398-22.452-20.539v-38.499h13.637v37.917c0 7.401 2.66 10.976 8.814 10.976c6.236 0 8.897-3.575 8.897-10.976v-37.917h13.637v38.499zm54.125-32.844l-7.069 7.401c-3.742-3.16-6.902-4.573-11.558-4.573c-8.065 0-14.052 5.987-14.052 20.289c0 15.134 3.991 20.289 11.974 20.289c2.827 0 5.405-.582 7.816-1.913V197.04h-7.484l-1.331-9.645h22.036v28.521c-5.905 3.825-13.388 6.07-20.954 6.07c-17.379 0-26.193-10.561-26.193-30.267c0-19.54 12.223-30.184 27.523-30.184c8.316 0 14.553 2.91 19.292 7.068z"/>
        <path fill="#505A60" d="M241.958 339.052c0 30.311-15.011 53.116-75.922 108.542h79.963l-4.619 34.93H114.363v-32.332c65.529-64.663 80.829-82.272 80.829-106.521c0-15.877-9.815-25.403-25.115-25.403c-14.145 0-23.96 6.351-35.218 20.207l-27.135-21.362c15.588-20.496 37.816-33.775 66.973-33.775c43.589 0 67.261 25.403 67.261 55.714zm162.318 101.325h-21.939v42.147h-44.456l-.289-42.147H255.32v-30.311l55.426-127.594l38.682 14.434l-47.054 109.408h35.796l4.907-46.188h39.26v46.188h21.939v34.063z"/>
    </svg>
] ];

const ShopItemAssignments = ({
    board = {},
    course = {},
    hsl = {},
    premium = {},
    school = {},
    schoolYear = {},
    unitObj = {},
    updateUnitObj = ( newUnitObj, board ) => {},
}) => {
    const [ days, setDays ] = useState({ odd: true, even: true });
    const [ isDurationByBoards, setIsDurationByBoards ] = useState( true );

    const [ placeholderAssignments, yearMonthDay ] = [ {}, getFirstFriday() ];
    if ( !Object.values( placeholderAssignments )[ 0 ] ) unitObj?.boardNums?.forEach( ( boardNum, i ) => {
        const nearestFriday = getComingFriday();

        placeholderAssignments[ boardNum ] = Array(
            premium ? premium.rules.board.size : courseInfo.getPremiumObj().rules.board.size
        ).fill().map( ( f, j ) => courseInfo.getAssignmentObj(
            j,
            i,
            j >= 2,
            j < 2 ? 'assessment' : 'assignment',
            j < 2 ? 10 : 0,
            [ nearestFriday[ 2 ] + ( i * 7 ), nearestFriday[ 1 ], nearestFriday[ 0 ] ],
            today.slice().reverse(),
            today.slice().reverse(),
        ) );

        placeholderAssignments[ boardNum ].forEach(
            assignment => assignment.directions = assignment.isPrereq ? 'Prerequisite Assignment' : 'Required Assignment'
        );
    } )

    const completion = board.assignments.map( assignment => !!assignment.directions );
    completion.splice( 1, 0, false );

    // FUNCTIONS
    function getAssignmentLi( assignment={} ) {
        return <li
            className={ styles.assignment }
            key={ assignment.id + '-for-unit' }
        >
            <div className={ styles.button }>{ getButton({ isEditButton: true, label: assignment.id }) }</div>
            <div className={ styles.content }>
                <AttachmentContainer
                    asSquares
                    className={ styles.link }
                    // EditButton={}
                    // getCloseButton={}
                    // getResetButton={}
                    // id=''
                    // isForDisplay
                    // isSmall
                    links={ assignment.links }
                    // showButton
                    // showCheckBox
                    // stackVertically
                />
                <header>{ assignment.directions }</header>
            </div>
        </li>
    }

    function getBoard( assignmentsArr=[], i=0 ) {
        return <section className={ styles.board } key={ 'board-' + i }>
            <header>
                <h2><Button
                    className={ styles[ 'edit-button' ] }
                    label={ <SVG type={{ edit: true }} fontSize='.75em'/> }
                    onClick={ () => console.log('editing BOARD #'+ (i+1) ) }
                />BOARD #{ i + 1 }</h2>
                <p>DUE: { getDate( Object.values( assignmentsArr[ 0 ].date.due ).reverse() )
                    .toLocaleDateString( 'en', { dateStyle: 'medium' } ).split( ',' )[ 0 ] }</p>
            </header>

            <ul className={ styles[ 'board-assignments' ] }>
                { assignmentsArr.map( assignment => getAssignmentLi( assignment ) ) }
            </ul>
        </section>
    }

    function getBoards( assignments={} ) {
        const isPlaceholder = Array.isArray( assignments );
        const assignmentsNestedArr = isPlaceholder ? assignments : Object.values( assignments );

        return <>{ assignmentsNestedArr.map( ( assignmentsArr, i ) => getBoard( assignmentsArr, i ) ) }</>
    }

    function getButton({
        isRemoveButton = false,
        isEditButton = false,
        isSaveButton = false,
        i = 0,
        label = '',
    }) {
        const props = {
            className: styles[ ( isEditButton ? 'edit' : isSaveButton ? 'save' : 'remove' ) + '-button' ],
            label: isSaveButton ? 'save'
                : isRemoveButton ? <SVG type={{ close: true }}/>
                : <SVG fontSize='.75em' type={{ edit: true }}/>,
            onClick: () => ( isRemoveButton ? resetAssignment : toggleEditing )( label )
        };

        if ( isRemoveButton ) props.id = 'remove-at-index-' + i
        return <Button { ...props }/>
    }

    function getCalendarMonth( monthObj={} ) {
        const month = schoolYear.months[ monthObj.i ];
        let calendarDays = getAllCalendarDays( month );

        const firstWeek = getFirstWeek( month );
        const firstWeekLeadingDays = getFirstWeekLeadingDays( firstWeek, month );
        firstWeekLeadingDays[ 0 ] && calendarDays.unshift( ...firstWeekLeadingDays );

        const calendarWeeks = getCalendarMonthWeeks( calendarDays );
        calendarDays = calendarWeeks.flat();
        const calendarEvents = getCalendarEvents( calendarWeeks, month, schoolYear );

        return <section className={ styles.month } key={ 'month-' + month.i }>
            <header>
                <h2>{ getDate([ month.year.full, month.i, 1 ])
                    .toLocaleDateString( 'en', { month: 'long' } ) }</h2>
            </header>

            <ul className={ styles.days }>{ calendarDays.map( ( day, k ) => {
                const currentHSL = 'odd' in hsl ? hsl[ month.days.odd.includes( day ) ? 'odd' : 'even' ] : hsl;
                const calendarWeek = getCalendarMonthWeek( calendarWeeks, k );
                const props = getCalendarMonthDayProps( calendarWeek, day, month );

                const eventsArr = calendarEvents[ k ].map( str => Object.fromEntries([
                    [ 'str', str ], [
                        str.startsWith( 'Req' ) ? 'isReq' : str.startsWith( 'Prereq' ) ? 'isPrereq' : 'isHoliday',
                        true
                    ]
                ]) );

                return <li { ...props }>
                    <span className={ styles.date }>{ day }</span>

                    { eventsArr.map( ( event, l ) => ( event.isReq || event.isPrereq ) ? getPill(
                        event, currentHSL, l, props.key
                    ) : <div
                        className={ get.classNameFromArr([ styles.pill, styles[ 'day-off' ] ])}
                        style={{ gridArea: `${ l + 2 } / 1 / ${ l + 5 } / 3` }}
                    >{ event.str }</div> ) }
                </li>
            } ) }</ul>
        </section>
    }

    function getCalendarMonthDayProps( calendarWeek=[], day=0, month={} ) {
        const [ oddDays, evenDays ] = calendarWeek.reduce(
            ( result, d ) => month.days.odd.includes( d ) ? ( result[ 0 ].push( d ) && result )
                : month.days.even.includes( d ) ? ( result[ 1 ].push( d ) && result )
                : result, [ [], [] ]
        );

        const [ isCurrentMonth, isOddDay, isEvenDay ] = [
            month.i === today[ 1 ], oddDays.includes( day ), evenDays.includes( day )
        ];

        const props = {
            key: [ day, month.i ].join( '-' ) + ( isCurrentMonth ? '' : `-in-month-${ month.i }` ),
            className: get.classNameFromArr([
                styles.day,
                !isCurrentMonth && styles.grey,
                isOddDay ? styles.odd : isEvenDay ? styles.even : styles.holiday,
                ( ( !days.odd && isOddDay ) || ( !days.even && !isOddDay ) ) && styles.inactive
            ]),
        };

        if ( isCurrentMonth ) props.style = { backgroundColor: get.hslFromName(
            ( isOddDay || isEvenDay ) ? school.colours[ isOddDay ? 0 : 1 ] : 'gold', true
        ) }

        return props
    }

    function getComingFriday() {
        const [ year, month, day ] = today;

        const date = getDate([ year, month, day ]);
        const weekdayNum = date.getDay();
        const increment = weekdayNum < 6 ? ( 5 - weekdayNum ) : 6;
        return [ year, month, day + increment ]
    }

    function getFirstFriday() {
        const [ year, month, day ] = (
            schoolYear.start.year <= today[ 0 ] && ( schoolYear.start.month <= today[ 1 ] || (
                schoolYear.start.month === today[ 1 ] && schoolYear.start.day < today[ 2 ]
            ) )
        ) ? today : [
            schoolYear.start.year,
            schoolYear.start.month,
            schoolYear.start.day
        ];

        const date = getDate([ year, month, day ]);
        const weekdayNum = date.getDay();
        const increment = weekdayNum < 6 ? ( 5 - weekdayNum ) : 6;

        return [ year, month, day + increment ]
    }

    function toggleDays({ target: { value } }) {
        const key = value.split(' ')[ 0 ];
        setDays( days => ({ ...days, [ key ]: !days[ key ] }) )
    }

    function toggleDurationUnits() { setIsDurationByBoards( isDurationByBoards => !isDurationByBoards ) }

    function toggleEditing( label='', assignmentsArr=null ) {
        if ( board.num < 0 ) {
            const [ , boardNum, , i, ] = label.split( '-' );
            updateUnitObj( null, {
                assignments: unitObj.assignments?.[ boardNum ] || placeholderAssignments[ boardNum ],
                i: i * 1,
                num: boardNum * 1
            })

        } else {
            if ( !unitObj.assignments ) {
                unitObj.assignments = placeholderAssignments;
                unitObj.assignments[ board.num ] = board.assignments;
            } else { unitObj.assignments[ board.num ] = assignmentsArr || board.assignments }

            updateUnitObj( unitObj, { assignments: [], num: -1, i: -1 } )
        }
    }

    function updateActiveSquare({ target: { id='' } }) {
        updateUnitObj( null, { ...board, i: !id ? 2 : id.split( '-' )[ 3 ] } )
    }

    function resetAssignment( label='' ) {
        const [ , , assignmentType, assignmentI, ] = label.split( '-' );
        const isReq = assignmentType === 'req';

        board.assignments[ assignmentI ].directions = [ isReq ? 'Required' : 'Prerequisite', 'Assignment' ].join(' ');
        board.assignments[ assignmentI ].links = [];
        board.assignments[ assignmentI ].points = 0;
        updateUnitObj( null, board )
    }

    // COMPONENTS
    const ViewToggle = <ToggleRadio
        className={ styles[ 'toggle-boards' ] }
        ids={ [ 'calendar', 'board' ] }
        isVertical
        groupName='toggle-boards'
        labels={ toggleLabels }
        passValueUp={ toggleDurationUnits }
        startOff={ !isDurationByBoards }
    />

    const DayTypeToggle = <RadioGroup
        defaultCheckedButtons={[ 'odd days', 'even days' ]}
        groupName='day-types'
        hsl={ hsl.odd || hsl }
        mainLabelType='checkbox'
        mainLabels={[ 'odd days', 'even days' ]}
        passValueUp={ toggleDays }
    />

    const DateToggle = <DateInput
        dashedYearMonthDay={ yearMonthDay.map(
            ( e, i ) => ( i > 0 && ( e + '' ).length < 2 ) ? ( e + '' ).padStart( 2, '0' ) : e
        ).join( '-' ) }
        id=''
        label='Start On'
        labelPositionOn=''
        schoolYear={ schoolYear }
    />

    // RENDER
    return <>
        <fieldset className={ styles[ 'inputs-container' ] }>
            { ViewToggle }
            { DayTypeToggle }
            { DateToggle }
        </fieldset>

        { isDurationByBoards ? getBoards( unitObj.assignments || placeholderAssignments )
            : unitObj.months.flatMap( monthObj => getCalendarMonth( monthObj ) ) }

        { board.num < 0 || <ModalContainer
            Children={ <AssignmentEditor
                activeSquare={ board.i }
                assignments={ board.assignments }
                boardObj={ courseInfo.getBoardObj(
                    unitObj.months[ 0 ].i,
                    unitObj.months[ 0 ].weekNums,
                    board.num
                ) }
                completion={ completion }
                courseObj={ course }
                hsl={{ h: hsl.odd[ '--h' ], s: hsl.odd[ '--s' ], l: hsl.odd[ '--l' ] }}
                showBoardNum
                showMiniBoard
                updateActiveSquare={ updateActiveSquare }
                updateAssignmentsFromParent={ ( arr=[] ) => toggleEditing( null, arr ) }
            /> }
            // isLocked
            isOpenFromParent={ board.num >= 0 }
            maxWidthPercent={ 100 }
            updateParentState={ toggleEditing }
        />}
    </>
}

// OTHER FUNCTIONS
function getAllCalendarDays( month={} ) {
    return get.numRange( 1, month.days.max ).filter( num => num >= month.days.all[ 0 ] )
}

function getCalendarEvents( calendarWeeks=[], month={}, schoolYear={} ) {
    const [ req, prereq ] = [ 'Req', 'Prereq' ];

    return calendarWeeks.flatMap( week => {
        const weekDayTypes = week.map( day => month.days.odd.includes( day ) ? 'odd'
            : month.days.even.includes( day ) ? 'even' : 'off' );

        return week.map( ( day, i ) => {
            if ( weekDayTypes[ i ] === 'off' ) {
                const holidayReason = schoolYear.holidays[ month.i ].reasons?.[
                    schoolYear.holidays[ month.i ].days.findIndex( d => d === day )
                ];

                return [ get.holidayEmoji( holidayReason ) + ' ' + holidayReason ]
            }

            const arr = week.filter( ( d, j ) => weekDayTypes[ j ] === weekDayTypes[ i ] );
            const [ dayTypeI, numDays ] = [ arr.findIndex( d => d === day ), arr.length - 1 ];

            return numDays === 0 ? Array( 5 ).fill( 'Ass\'t' ).map(
                ( suffix, i ) => ( i > 2 ? req : prereq ) + ' ' + suffix
            ) : numDays === 1 ? Array(
                3 - Math.floor( dayTypeI ? dayTypeI / numDays : dayTypeI )
            ).fill( 'Ass\'t' ).map(
                suffix => ( dayTypeI === numDays ? req : prereq ) + ' ' + suffix
            ) : Array(
                2 - Math.floor( dayTypeI / numDays ) ).fill( 'Ass\'t'
            ).map( ( suffix, i ) => (
                ( dayTypeI === 0 || ( dayTypeI === 1 && i === 0 ) ) ? prereq : req
            ) + ' ' + suffix )
        } )
    } )
}

function getCalendarMonthWeek( calendarMonthWeeks=[], i=0 ) {
    return calendarMonthWeeks[ Math.floor( i / 5 ) ]
}

function getCalendarMonthWeeks( calendarDays=[] ) {
    const totalWeeks = Math.ceil( calendarDays.length / 7 );
    return Array( totalWeeks ).fill().map( ( e, i ) => calendarDays.slice( i * 7, ( i * 7 ) + 5 ) );
}

function getDate( yearMonthDay=[], dayIncrement=0 ) {
    return new Date( yearMonthDay[ 0 ], yearMonthDay[ 1 ], yearMonthDay[ 2 ] + dayIncrement )
}

function getFirstWeek( month={} ) {
    return Object.values( month.weeks ).find( week => week.days.includes( month.days.all[ 0 ] ) )
}

function getFirstWeekLeadingDays( firstWeek={}, month={} ) {
    const i = firstWeek.days.findIndex( d => d === month.days.all[ 0 ] );
    return firstWeek.days.slice( 0, i )
}

function getPill( event={}, hsl={}, i=0, key='' ) {
    return <Pill
        key={ [ key, i ].join( '-' ) }
        activeState
        className={ get.classNameFromArr([ styles.pill, event.isReq ? styles.req : styles.prereq ]) }
        gradientDuo={ event.isReq ? [
            `hsl( ${ hsl['--h'] }, ${ hsl['--s'] }, ${ hsl['--l'].split( '%' )[ 0 ] * .9 }% )`,
            `hsl( ${ hsl['--h'] }, ${ hsl['--s'] }, ${ hsl['--l'].split( '%' )[ 0 ] * .9 }% )`,
        ] : [
            `hsl( ${ hsl['--h'] }, ${ hsl['--s'] }, ${ hsl['--l'].split( '%' )[ 0 ] * 1.5 }% )`,
            `hsl( ${ hsl['--h'] }, ${ hsl['--s'] }, ${ hsl['--l'].split( '%' )[ 0 ] * 1.5 }% )`,
        ] }
        label={ event.str }
        onClick={ () => { console.log( 'clicked on ' + event.str ) } }
        style={{ gridArea: `${ i + 2 } / 1 / ${ i + 2 } / 3` }}
    />
}

export default ShopItemAssignments;