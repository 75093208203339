import { useEffect, useState } from 'react';
import get from '../../../../constants/get';
import SVG from '../../../modular/Images/SVG';
import Button from '../../Button';
import styles from './SideBar.module.css';
import EventForm from './EventForm';

const SideBar = ({
    actionOnClose = null,
    activeDayI = 17,
    activeMonthI = 0,
    className = '',
    lang = 'en',
    monthIsGreaterThan = () => {},
    monthIsLessThan = () => {},
    school = {},
    schoolYear = {},
    updateSchoolYear = ( keys=[ '' ], values=[ {} ] ) => {}
}) => {
    const [ showSidebar, setShowSidebar ] = useState( false );
    const [ editActiveDayOffReason, setEditActiveDayOffReason ] = useState( false );
    const [ addedEvent, setAddedEvent ] = useState( null );
    const { months, holidays, observances } = schoolYear;
    // const [ holidayDayMonths, setHolidayDayMonths ] = useState( [] );

    // when props.activeDayI changes, turn off edit mode, toggle the sidebar, and determine whether or not it is a student holiday
    useEffect( () => {
        setShowSidebar( () => !!activeDayI )
        setEditActiveDayOffReason( () => false )
        setAddedEvent( () => null )
    }, [ activeDayI, schoolYear.holidays ] )

    // log holiday when addedEvent changes
    useEffect( () => {
        activeDayI && get.log({
            fileName: 'SideBar.js',
            functionDirectionsOrComponentName: `${ activeDayI }.${ activeMonthI } holiday:`,
            hasEmoji: true,
            lineNumber: 38,
            format: { isNeeded: true, isTrueFalseToggle: true },
            str: ( get.holidayEmoji( holidays[ activeMonthI ]?.reasons?.[
                holidays[ activeMonthI ]?.days?.findIndex( day => day === activeDayI )
            ]) + ' ' + holidays[ activeMonthI ]?.reasons?.[
                holidays[ activeMonthI ]?.days?.findIndex( day => day === activeDayI )
            ] ) || 'no holiday',
            bool: { trueFalse: holidays[ activeMonthI ]?.days?.includes( activeDayI ) }
        });
    }, [ activeDayI, activeMonthI, holidays, addedEvent ] )

    const [ activeDayIsDayOff, quarters ] = [
        holidays[ activeMonthI ]?.days?.includes( activeDayI ) || (
            observances[ activeMonthI ]?.days
                && !observances[ activeMonthI ]?.days?.includes( activeDayI )
        ) || false,
        activeMonthI ? getQuarters() : []
    ];

    const sectionClassNamesAndHeaders = [
        [ 'date-and-type', [
            schoolYear.months[ activeMonthI ]?.name?.short || activeMonthI,
            activeDayI
        ].join( '. ' ) ],
        [ 'quarters', `quarter${ quarters.length === 1 ? '' : 's' }` ],
        [ 'events', 'event(s)' ],
    ];

    // FUNCTIONS
    function populateHolidaysOrObservances(
        holidaysOrObservancesMonth = {},
        eventDays = [],
        eventReason = ''
    ) {
        // console.log( holidaysOrObservancesMonth, eventDays, eventReason );
        if ( 'days' in holidaysOrObservancesMonth ) {
            eventDays.forEach( dayNum => {
                if ( holidaysOrObservancesMonth.days.includes( dayNum ) ) {
                    holidaysOrObservancesMonth.reasons[
                        holidaysOrObservancesMonth.days.findIndex( day => day === dayNum )
                    ] = eventReason;
                } else {
                    holidaysOrObservancesMonth.days.push( dayNum )
                    holidaysOrObservancesMonth.reasons.push( eventReason )
                }
            } )
        } else {
            holidaysOrObservancesMonth.days = eventDays;
            holidaysOrObservancesMonth.reasons = eventDays.map( () => eventReason )
        }

        return holidaysOrObservancesMonth
    }

    function addEvent( obj={ days: [], months: [], key: '', reason: '' } ) {
        const newSchoolYear = { ...schoolYear };

        if ( obj.days[ 0 ] ) {
            newSchoolYear.months = months;
            const eventMonthsAndI = [];
            obj.months.forEach( ( monthNum, i ) => eventMonthsAndI.includes( monthNum ) || eventMonthsAndI.push([ monthNum, i ]) );

            let [ eventDays, holidaysOrObservancesMonth ] = [
                obj.days, newSchoolYear[ obj.key ][ eventMonthsAndI[ 0 ] ]
            ];

            if ( eventMonthsAndI.length > 1 ) {
                eventMonthsAndI.forEach( ( monthNumAndIArr, i ) => {
                    const [ monthNum, j ] = monthNumAndIArr;

                    populateHolidaysOrObservances(
                        newSchoolYear[ obj.key ][ monthNum ],
                        obj.days.slice( j, eventMonthsAndI[ i + 1 ]?.[ 1 ] || obj.days.length ),
                        obj.reason
                    );
                } )
            } else { populateHolidaysOrObservances( holidaysOrObservancesMonth, eventDays, obj.reason ) }
            // console.log( obj, newSchoolYear );

            if ( obj.reason.includes( 'psat' ) ) {
                const [ holidayMonth, month ] = [
                    newSchoolYear.holidays[ obj.months[ 0 ] ],
                    newSchoolYear.months[ eventMonthsAndI[ 0 ][ 0 ] ]
                ];
                // const dayType = month.days.odd.includes( eventDays[ 0 ] ) ? 'odd' : 'even';
                month.days.all = month.days.all.filter( dayNum => !eventDays.includes( dayNum ) );

                if ( 'days' in holidayMonth ) {
                    // holidayMonth.days.push( ...obj.days );
                    // holidayMonth.reasons.push( ...obj.days.map( () => obj.reason ) )
                    obj.days.forEach( day => {
                        if ( holidayMonth.days.includes( day ) ) {
                            holidayMonth.reasons[
                                holidayMonth.days.findIndex( dayNum => dayNum === day )
                            ] = obj.reason;
                        } else {
                            holidayMonth.days.push( day );
                            holidayMonth.reasons.push( obj.reason );
                        }
                    } )
                } else {
                    [ 'days', 'reasons' ].forEach(
                        ( e, j ) => holidayMonth[ e ] = j === 0 ? obj.days
                            : obj.days.map( () => obj.reason )
                    )
                }
            } else {
                obj.months.forEach( ( monthNum, i ) => {
                    const [ day, holidayMonth ] = [
                        obj.days[ i ],
                        newSchoolYear[ obj.key ][ monthNum ]
                    ];

                    if ( 'days' in holidayMonth ) {
                        if ( holidayMonth.days.includes( day ) ) {
                            holidayMonth.reasons[
                                holidayMonth.days.findIndex( dayNum => dayNum === day )
                            ] = obj.reason;
                        } else {
                            holidayMonth.days.push( day );
                            holidayMonth.reasons.push( obj.reason );
                        }
                    } else {
                        [ 'days', 'reasons' ].forEach(
                            ( e, j ) => holidayMonth[ e ] = j === 0 ? [ day ] : [ obj.reason ]
                        )
                    }
                } )
                obj.key === 'holidays' && updateSubsequentOddAndEvenDays(
                    obj.months[ 0 ],
                    newSchoolYear,
                    [ obj.days, obj.months ]
                );
            }

            updateSchoolYear( [ 'schoolYear' ], [ newSchoolYear ] )
            setAddedEvent( () => obj.reason )
            toggleEditActiveDayOffReason()
        }
    }

    function getDayType() {
        const [ dayTypes, isOddDay ] = [
            school?.colours || [ 'odd', 'even' ],
            months[ activeMonthI ].days.odd.includes( activeDayI ),
        ];

        const dayNums = ( activeDayIsDayOff ? 'holidays' : 'observances' )[ activeMonthI ]?.days;
        const hsl = school?.colours && ( school?.hsls ? school.hsls[ isOddDay ? 0 : 1 ]
            : [ ...school.colours, 'grey' ].map( colour => get.hslFromName( colour, true ) )[
                activeDayIsDayOff ? 2 : isOddDay ? 0 : 1
            ] );

        let reasonsI = dayNums && dayNums.findIndex( num => num === activeDayI );
        if ( typeof reasonsI === 'number' && reasonsI < 0 ) reasonsI = null

        const [ dayType, props ] = [
            activeDayIsDayOff ? 'no classes' : dayTypes[ isOddDay ? 0 : 1 ] + ' day',
            { className: styles[ 'day-type' ] }
        ];

        if ( school?.colours ) props.style = {
            background: `hsl( ${ hsl.h }, ${ hsl.s }, ${ hsl.l }, .75 )`,
            border: `2px solid hsl( ${ hsl.h }, ${ hsl.s }, ${ ( hsl.l.split( '%' )[ 0 ] * 1 ) * .5 }% )`,
            color: get.textColour(
                get.hexFromHSL(
                    hsl.h,
                    hsl.s.split( '%' )[ 0 ] * 1,
                    hsl.l.split( '%' )[ 0 ] * 1,
                ), false
            )
        }

        return <p { ...props }>
            { get.capitalise( dayType ) }
            { school?.colours && dayType !== 'no classes' && ` (${ isOddDay ? 'odd' : 'even' })` }
        </p>
    }

    function getEvents() {
        const { days: dayNums, reasons } = ( activeDayIsDayOff ? holidays : observances )[ activeMonthI ];

        let [ dayEventStr, props ] = [
            addedEvent || reasons?.[ dayNums?.findIndex( day => day === activeDayI ) ], {
                Button: { onClick: editActiveDayOffReason ? addEvent : toggleEditActiveDayOffReason },
                EventForm: { activeDayI, activeMonthI, monthIsGreaterThan, schoolYear, passValueUp: addEvent }
            }
        ];

        props.Button.label = <SVG
            type={{ [ editActiveDayOffReason ? 'save' : dayEventStr ? 'edit' : 'add' ] : true }}
            fontSize={ ( !editActiveDayOffReason && dayEventStr ) ? '.65em' : '1em' }
        />;

        if ( !editActiveDayOffReason ) {
            if ( !dayEventStr ) {
                props.Button.label = <>{ props.label } add event</>
            } else {
                props.Button.className = styles[ 'edit-event-button' ];
            }
        }

        return editActiveDayOffReason ? <EventForm { ...props.EventForm }/> : <>
            <Button { ...props.Button }/>
            { dayEventStr && typeof dayEventStr === 'string' && <p className={ styles[ 'event-label' ] }>
                { get.holidayEmoji( dayEventStr ) }&nbsp;
                <span>{ dayEventStr }</span>
            </p> }
        </>
    }

    function getQuarters() {
        const [ month, quarters ] = [ months[ activeMonthI ], schoolYear.quarters ];

        return [ quarters.find( quarter => month.year.full >= quarter.start.year && (
            ( month.i === quarter.start.month && activeDayI >= quarter.start.day )
            || ( month.i === quarter.end.month && activeDayI <= quarter.end.day )
            || ( monthIsGreaterThan( month.i, quarter.start.month, false )
                && monthIsLessThan( month.i, quarter.end.month, false )
            )
        ) ) ]
    }

    function toggleEditActiveDayOffReason() { setEditActiveDayOffReason( editActiveDayOffReason => !editActiveDayOffReason ) }

    function toggleSidebar( e ) {
        if ( actionOnClose !== null ) actionOnClose( e )
        setShowSidebar( showSidebar => !showSidebar )
    }

    function updateSubsequentOddAndEvenDays(
        monthNum = 0,
        newSchoolYear = {},
        daysAndMonthsOff = [ [], [] ]
    ) {
        const month = newSchoolYear.months[ monthNum ];
        const prevMonth = newSchoolYear.months[ month.prevI ];

        const startWithOddDay = prevMonth.days.even.includes(
            get.lastArrayElement( prevMonth.days.all )
        );

        const [ aDay, bDay ] = startWithOddDay ? [ 'odd', 'even' ] : [ 'even', 'odd' ];
        let [ daysOff, monthsOff ] = daysAndMonthsOff;

        if ( monthsOff.includes( month.i ) ) {
            daysOff = daysOff.filter( ( day, i ) => monthsOff[ i ] === month.i );
            // console.log( month.name.full, `(${ month.i }) is in`, monthsOff );
            // console.log( 'so filter out', daysOff, 'from', month.days.all );
            month.days.all = month.days.all.filter( day => !daysOff.includes( day ) );

            const [ allSchoolDays, psatDay ] = [
                month.days.all.slice(), holidays[ month.i ]?.days?.[
                    holidays[ month.i ]?.reasons?.findIndex( reason => reason.includes( 'psat' ) )
                ]
            ];
            psatDay && allSchoolDays.splice( allSchoolDays.findIndex( day => day > psatDay ), 0, psatDay );

            // console.log( allSchoolDays );
            // get.log({
            //     fileName: 'SideBar.js',
            //     functionDirectionsOrComponentName: '[ allSchoolDays ] & month.days.all',
            //     lineNumber: 251,
            //     str: { allSchoolDays, allDaysInMonth: month.days.all }
            // });

            [ month.days.odd, month.days.even ] = [ [], [] ];
            allSchoolDays.forEach( ( day, i ) => month.days[ i % 2 ? bDay : aDay ].push( day ) )

        } else if ( month.days.odd.includes( month.days.all[ 0 ] )
            && prevMonth.days.odd.includes( get.lastArrayElement( prevMonth.days.all ) ) ) {
            // console.log( month.name.full, `(${ month.i }) is NOT in`, monthsOff );
            const [ oddDays, evenDays ] = [ month.days.odd.slice(), month.days.even.slice() ];
            // console.log( 'FLIPSIES' );
            // console.log( oddDays, '=>', month.days.odd );
            // console.log( evenDays, '=>', month.days.even );
            [ month.days.odd, month.days.even ] = [ evenDays, oddDays ];
        }

        // console.log( month.name.full, month.days, daysOff, monthsOff );
        // console.log();
        return monthNum !== schoolYear.end.month && updateSubsequentOddAndEvenDays(
            newSchoolYear.months[ monthNum ].nextI, newSchoolYear, daysAndMonthsOff
        )
    }

    // PROPS & ATTRIBUTES
    const props = {
        aside: {
            className: get.classNameFromArr([
                className,
                styles.sidebar,
                !showSidebar && styles.hide,
            ])
         },
        CloseButton: {
            className: styles.close,
            label: <SVG type={{ close: true }}/>,
            onClick: toggleSidebar,
        }
    };

    sectionClassNamesAndHeaders.forEach( arr => arr.push({
        className: get.classNameFromArr([ styles.section, styles[ arr[ 0 ] ] ]),
        key: [ arr[ 0 ], 'section' ].join( '-' )
    }) )

    // RENDER
    return activeDayI ? <aside { ...props.aside }>
        <Button { ...props.CloseButton }/>

        { sectionClassNamesAndHeaders.map( ( arr, i ) => {
            const [ headerLabel, sectionProps ] = [ get.capitalise( arr[ 1 ] ), arr[ 2 ] ];

            return <section { ...sectionProps }>
                <header>{ i === 0 ? <>
                    <span>
                        { headerLabel }
                        <sup>{ get.ordinalSuffix( activeDayI, lang ) }</sup>
                    </span>
                    {/* <ToggleRadio
                        className={ styles[ 'day-type-toggle' ] }
                        ids={[ 'school', 'no-school' ]}
                        isVertical
                        groupName='day-type-toggle'
                        labels={[ '👩🏾‍🏫', '🏖️' ]}
                        passValueUp={ toggleActiveDayIsDayOff }
                        // startOff={ activeDayIsDayOff === null ? true : activeDayIsDayOff }
                        startOff={ activeDayIsDayOff || false }
                    /> */}
                </> : headerLabel }</header>

                { i === 1 ? <ul className={ styles[ 'quarter-list' ] }>{
                    quarters.map( quarter => <span
                        className={ get.classNameFromArr([
                            styles.quarter,
                            quarters.length === 0 ? styles.none : styles.some
                        ]) }
                        key={ 'quarter-' + quarter.id }
                    >
                        { quarter.id }
                        <sup>{ get.ordinalSuffix( quarter.id, lang ) }</sup>
                    </span> ) }
                </ul> : i === 0 ? getDayType() : getEvents() }
                {/* : <Button
                 label={ <><SVG type={{ add: true }}/> add event</> }
                 onClick={ toggleEditActiveDayOffReason }
                /> */}
            </section>
        } )}
    </aside> : <aside { ...props.aside }/>
}

export default SideBar;