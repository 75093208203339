// import styles from './ToggleButton.module.css';

const ToggleButton = ({ backgroundClass='', buttonClass='', toggle=() => {} }) => {
    return <button
        className={ backgroundClass }
        onClick={ toggle }
        type='button'
    >
        <div className={ buttonClass }></div>
    </button>
}

export default ToggleButton;