import { useContext, useEffect, useState } from 'react';
import BoardIcon from '../../../modular/Images/BoardIcon';
import HiveContext from '../../../../contexts/HiveContext';
import PlanAssignmentForm from '../../../modular/Form/PlanAssignmentForm';
// import Button from '../../Button';
import styles from './AssignmentEditor.module.css';
import UserContext from '../../../../contexts/UserContext';
import SVG from '../../../modular/Images/SVG';
import Button from '../../Button';
import courseInfo from '../../../../constants/courseInfo';
import get from '../../../../constants/get';

const getTitle = ( assignment={} ) => get.assignmentTitle(
    assignment.boardNum,
    assignment.id.split( '-' )[ 3 ] * 1
)

const AssignmentEditor = ({
    activeSquare=0,
    assignments=[],
    boardObj={},
    completion=[],
    courseObj={},
    hsl={},
    showBoardNum = false,
    showMiniBoard = true,
    updateActiveSquare = null,
    updateAssignmentsFromParent = null
}) => {
    // console.log( activeSquare, 'BOARD', board, '\nHSL', hsl );
    // console.log( assignments );
    let { activeBoard, course } = useContext( HiveContext );
    const { updateAssignments } = useContext( UserContext );

    const [ activeAssignment, setActiveAssignment ] = useState( assignments[ activeSquare ] );
    useEffect( () => setActiveAssignment( assignments[ activeSquare ] ), [ activeSquare, assignments ] )

    const ids = assignments.map( assignment => assignment.id );
    const plannerIDs = [ ...ids.slice( 2, 5 ), ids[ 0 ], 'directions-box', ids[ 1 ], ...ids.slice( 5 ) ];
    const background = `hsl( ${ [ hsl.h, hsl.s, '92.5%' ].join( ', ' ) } )`;

    if ( !activeBoard.num ) activeBoard = boardObj
    if ( !course.id ) course = courseObj

    // FUNCTIONS
    const deleteAssignment = () => {
        assignments[ activeSquare ] = getBlankAssignment();
        const notBlankAssignments = assignments.filter( assignment => assignment.directions );
        const newAssignment = notBlankAssignments.length === 0 ? null : (
            notBlankAssignments[ activeSquare ] || notBlankAssignments[ notBlankAssignments.length - 1 ]
        );

        updateAssignmentsFromParent ? updateAssignmentsFromParent( assignments )
            : updateAssignments( assignments, activeBoard, course );

        newAssignment?.id ? updateActiveSquare({ target: { id: newAssignment.id } })
            : updateActiveSquare({ target: {} })
    }

    const getActiveHue = ( hue=0 ) => {
        hue *= 1;

        return hue < 20 ? hue - 20 + 360 : hue - 20
    }

    const getAssignmentI = ( forNextAssignment=true ) => {
        const i = activeSquare + ( forNextAssignment ? 1 : -1 );

        return i >= assignments.length ? 0
            : i < 0 ? assignments.length - 1
            : i
    }

    // eslint-disable-next-line
    const getValidAssignmentI = ( forNextAssignment=true ) => {
        const notBlankAssignments = assignments.filter( assignment => assignment.directions );
        if ( notBlankAssignments.length <= 1 ) return null
        // console.log( notBlankAssignments );

        const findNextValidAssignment = ( i=0, increment=1 ) => assignments[ i ]?.directions ? i
            : findNextValidAssignment(
                ( i + increment ) >= assignments.length ? 0
                : ( i + increment ) < 0 ? assignments.length - 1
                : i + increment
            )

        return forNextAssignment ? findNextValidAssignment( activeSquare + 1, 1 )
            : findNextValidAssignment( activeSquare - 1, -1 )
    }

    const getBlankAssignment = () => {
        const assignment = assignments[ activeSquare ];

        return courseInfo.getAssignmentObj(
            activeSquare,
            assignment.boardNum,
            assignment.isPrereq,
            assignment.category,
            assignment.points,
            [ assignment.date.due.day, assignment.date.due.month, assignment.date.due.year ]
        )
    }

    const getButton = ( propsSVG={}, label='', propsButton={} ) => <Button
        { ...propsButton }
        label={ <>
            { Object.keys( propsSVG ).length > 0 && <SVG { ...propsSVG }/> }
            { label }
        </> }
    />

    const resetAssignment = () => window.location.hostname === 'localhost' && console.log( 'reseting current assignment to default values...' )

    const saveAssignment = e => {
        e.preventDefault();
        const elements = Array.from( e.target.elements )
            .filter( element => element?.id?.includes( '[' ) && element.value );
        // console.log( e, assignments, activeSquare, elements );

        const date = new Date();
        const [ day, month, year ] = [ date.getDate(), date.getMonth(), date.getFullYear() ];
        const assignmentData = {
            ...activeAssignment,
            date: { ...activeAssignment.date, edited: { day, month, year } },
            points: 0,
            isGraded: false,
            links: [],
            title: getTitle( activeAssignment )
        };

        elements.forEach( element => {
            const getBool = ( str='' ) => element.id.includes( str )

            if ( getBool( 'directions' ) ) assignmentData.directions = element.value
            if ( getBool( 'category' ) ) assignmentData.category = element.value
            if ( getBool( 'attachments' ) ) assignmentData.links.push( element.value )

            if ( getBool( 'points' ) ) {
                assignmentData.points = element.value * 1;
                assignmentData.isGraded = true;
            }

            if ( getBool( 'due-date' ) ) {
                const [ newDate, oldDate ] = [ new Date( element.value ), activeAssignment.date.due ];
                const [ day, month, year ] = [
                    newDate.getDate(), newDate.getMonth(), newDate.getFullYear()
                ];

                const isSpecificDueDate = day !== oldDate.day
                    && month !== oldDate.month
                    && year !== oldDate.year;

                if ( isSpecificDueDate ) assignmentData.date.due = { day, month, year }
            }
        } )

        if ( 'isPrivate' in activeAssignment ) delete activeAssignment.isPrivate;
        assignments[ activeSquare ] = assignmentData;

        window.location.hostname === 'localhost' && console.log( 'SAVING ASSIGNMENTS:', assignments, '\n', assignmentData );
        updateAssignmentsFromParent ? updateAssignmentsFromParent( assignments )
            : updateAssignments( assignments, activeBoard, course );

        updateActiveSquare({ target: { id: assignments[ activeSquare === assignments.length - 1 ? 0 : activeSquare + 1 ].id } })
        // setAssignmentI( assignmentI => assignmentI === assignments.length - 1 ? 0 : assignmentI + 1 )
    }

    // COMPONENTS
    const [ CopyButton, ResetButton, LeftCaret, RightCaret ] = [
        // getButton( { type: { close: true }, fontSize: '1em' }, '', { onClick: updateActiveSquare } ),  // CloseButton
        getButton( { type: { trash: true }, fontSize: '1em' }, 'delete', { onClick: deleteAssignment } ),
        getButton( { type: { reset: true }, fontSize: '1em' }, 'reset', { onClick: resetAssignment } ),
        getButton( { type: { 'left caret': true }, fontSize: '1em' }, '', {
            id: `board-${ activeBoard.num }-assignment-${ getAssignmentI( false ) }`,
            looksLikeLink: true,
            fillSVGOnHover: true,
            onClick: updateActiveSquare
        }),
        getButton( { type: { 'right caret': true }, fontSize: '1em' }, '', {
            id: `board-${ activeBoard.num }-assignment-${ getAssignmentI( true ) }`,
            looksLikeLink: true,
            fillSVGOnHover: true,
            onClick: updateActiveSquare
        }),
    ];

    const MiniBoard = !showMiniBoard ? <SVG type={{ 'new assignment': true }}/> : <BoardIcon
        activeI={ activeSquare }
        activeColour={`hsl( ${ getActiveHue( hsl.h ) }, ${ hsl.s }, ${ hsl.l } )`}
        borderColour={`hsl( ${ hsl.h }, ${ hsl.s }, ${ hsl.l.split( '%' )[ 0 ] * 1 - 10 }% )`}
        canHover
        hsl={ hsl }
        completion={ completion }
        ids={ plannerIDs }
        onClick={ updateActiveSquare }
        size='1.75rem'
    />;

    // console.log( 'EDITOR ASSIGNMENTS:', activeSquare, board, assignments[ activeSquare ], '\n', assignments[ 2 ] );
    // console.log( activeAssignment );

    // RENDER
    return <article className={ styles.container } style={{ background }}>
        {/* { CloseButton } */}
        <header>
            <span className={ styles[ 'board-num' ] }>{ showBoardNum && `BOARD #${ activeBoard.num + 1 }` }</span>
            <nav>
                { LeftCaret }
                <header>{ activeAssignment?.title }</header>
                { RightCaret }
            </nav>
        </header>

        <section className={ styles[ 'button-section' ] }>
            {/* { showBoardNum && <header>BOARD #{ board.num + 1 }</header> } */}
            { MiniBoard }
            <fieldset className={ styles.buttons }>
                { CopyButton }
                { ResetButton }
            </fieldset>
        </section>

        <section className={ styles[ 'form-section' ] }>
            {/* <header>{ assignments[ activeSquare ].title }</header> */}
            { activeAssignment && <PlanAssignmentForm
                assignment={ activeAssignment }
                saveAssignment={ saveAssignment }
            /> }
        </section>
    </article>
}

export default AssignmentEditor;