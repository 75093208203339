import { createContext } from 'react';

const HiveContext = createContext({
    activeBoard: {},
    activeQuarter: {},
    changeBoardOrQuarter() {},
    county: {},
    course: {},
    currentBoard: {},
    currentQuarter: {},
    currentWeekNum: 0,
    getQuarter({ id='', weekNum=0 }) { return {} },
    Header: <></>,
    isActuallyQueenBee: true,
    isQueenBee: true,
    langs: [],
    lang: '',
    placeholderBoards: [],
    rules: {},
    school: {},
    schoolYear: {},
    toggleBeeType() {},
    toggleLanguage() {}
});

export default HiveContext;