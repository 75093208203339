import glossaryExports from './Glossary';
import PrivacyPolicy from './PrivacyPolicy';
import TermsAndServices from './TermsOfService';
import get from '../../../constants/get';
import styles from './Policy.module.css';
import Nav from '../Nav';
import AnimatedLogo from '../../modular/Images/AnimatedLogo';
import Footer from '../../modular/Footer';

const [ dates, tableOfContentsSections ] = [
    {
        privacy: [ 2023, 8, 12 ],
        terms: [ 2023, 8, 12 ],
    }, {
        privacy: {
            policy: false,
            tableOfContents: false,
            intro: false,

            accounts: {
                forChildren: false,
                termination: false,
                security: false,
            },
            contactInformation: false,
            education: {
                commitment: false,
                accounts: false,
                information: false,
            },
            glossary: false,
            generalOverview: false,
            information: {
                access: false,
                collection: false,
                erasure: false,
                management: false,
                'security&Sharing': false,
                'storage&Retention': false,
                use: false,
            },
            thirdParties: {
                list: false,
            },
            userRights: {
                jurisdictions: false,
                ifAcquired: false,
            },
        },
        terms: {
            policy: false,
            tableOfContents: false,
            intro: false,

            glossary: false,
        },
    },
];

const Policy = ({ type={ privacy: false, terms: false }, isEUCompliant=false }) => {
    let page = Object.keys( type );
    page = page.length === 1 ? page[ 0 ] : null;

    const sections = tableOfContentsSections[ page ] || {};
    const [ sections1D, sectionsInTableOfContents, sectionsWithContent ] = [
        Object.keys( sections ).filter( e => typeof sections[ e ] === 'boolean' ),
        Object.keys( sections ).slice( 3 ),
        {},
    ];

    let glossary = glossaryExports.getGlossary({ EUcompliant: isEUCompliant, privacy: true });  // privacy OR terms : true
    glossary = { ...glossary, ...glossaryExports.getGlossary(
        { EUcompliant: isEUCompliant, education: true },
        Object.keys( glossary ).length
    ) };

    if ( isEUCompliant ) glossary = glossaryExports.getGlossary(
        { EUcompliant: isEUCompliant },
        Object.keys( glossary ).length
    )

    // FUNCTIONS
    function getID( str='' ) {
        const arr = str.toLocaleLowerCase().split(' ');

        return arr[ 1 ] ? arr.join( '-' ) : arr[ 0 ]
    }

    function getNav() {
        const List = Object.keys( sectionsWithContent ).map( ( section, i ) => {
            const [ isNestedObj, props, title ] = [
                typeof sectionsWithContent[ section ] !== 'boolean',
                { className: styles[ 'menu-item' ], key: 'menu-item-' + i },
                getSpacedTitleCaseFromCamelCase( section )
            ];

            return getNavLi(
                props,
                title,
                null,
                isNestedObj ? <ul>{
                    Object.keys( sectionsWithContent[ section ] ).map( ( subSection, j ) => getNavLi(
                        { className: styles[ 'sub-menu' ], key: 'sub-menu-item-' + j },
                        getSpacedTitleCaseFromCamelCase( section + subSection[ 0 ].toLocaleUpperCase() + subSection.slice( 1 ) ),
                        isNestedObj
                    ) )
                }</ul> : null
            )
        } );

        return <nav><ul className={ styles.menu }>{ List }</ul></nav>
    }

    function getNavLi( props={}, title='', isNested=false, NestedList ) {
        const nestedTitle = title.split(' ').slice( 1 ).join(' ');

        return <li { ...props }>
            { glossaryExports.getLink( isNested ? nestedTitle : title, '#' + getID( title ) ) }
            { NestedList }
        </li>
    }

    function getSection( obj=sections, Children, headerNum=0 ) {
        const [ entries, extra ] = Object.entries( obj );
        if ( extra?.[ 0 ] ) return 'INVALID SECTION'
        if ( obj.tableOfContents ) headerNum = 2

        const [ objKey, objValue ] = entries;
        const sectionIsFilled = updateSectionsWithContent( objKey, objValue, Children?.props?.children );

        const section = getSectionStr( typeof objValue === 'boolean' ? objKey : obj );
        const title = getSpacedTitleCaseFromCamelCase( section === 'information' ? 'information (Data )' : section );

        const props = { id: getID( section === 'information' ? section : title ) };
        if ( obj.tableOfContents || obj.policy ) props.className = obj.policy ? styles.right : styles.left

        return sectionIsFilled && <section { ...props }>
            { headerNum > 0 && wrapInHeader( headerNum, title ) }
            { obj.tableOfContents ? getNav() : Children }
        </section>
    }

    function getSectionLink(
        keyword='',
        category={
            education: false,
            information: false,
            userRights: false,
            thirdParties: false
        }
    ) {
        let suffix = keyword.includes( '-' ) ? keyword.split( '-' ).slice( 1 ).join( '' ) : '';
        if ( suffix ) {
            suffix = get.capitalise( suffix );
            keyword = keyword.split( '-' )[ 0 ];
        }

        const categoryKey = Object.keys( category ).find( e => category[ e ] );
        const key = Object.keys( categoryKey ? sections[ categoryKey ] : sections )
            .find( e => e.toLocaleLowerCase().includes( keyword.toLocaleLowerCase() ) );

        const camelTitle = ( categoryKey || '' ) + key[ 0 ].toLocaleUpperCase() + key.slice( 1 ) + suffix;
        const title = getSpacedTitleCaseFromCamelCase( camelTitle );
        return glossaryExports.getLink( title, '#' + getID( title ) )
    }

    function getSectionStr( sectionKey ) {
        if ( typeof sectionKey !== 'string' ) {
            const [ str1, obj ] = Object.entries( sectionKey )[ 0 ];
            const str2 = Object.keys( obj )[ 0 ];

            sectionKey = str1 + str2[ 0 ].toLocaleUpperCase() + str2.slice( 1 );
        }

        return sectionKey
    }

    function getSpacedTitleCaseFromCamelCase( camelStr='', delimeter=' ', spacedStr='' ) {
        if ( spacedStr.includes( '&' ) ) spacedStr = spacedStr.replace( '&', ' And ' )
        const i = camelStr.slice( 1 ).search( '[A-Z]' );

        return i !== -1 ? getSpacedTitleCaseFromCamelCase(
            camelStr.slice( i + 1 ),
            delimeter,
            spacedStr + delimeter + get.capitalise( camelStr.slice( 0, i + 1 ) )
        ) : spacedStr.length > 0 ? ( spacedStr + delimeter + camelStr ).trim()
        : camelStr[ 0 ].toLocaleUpperCase() + camelStr.slice( 1 )
    }

    function isValidDate( day=17, month=0, year=1967 ) {
        const today = new Date();
        const isValid = [
            year >= today.getFullYear(),
            month >= today.getMonth(),
            day >= today.getDate(),
        ];

        return !isValid.some( e => e )
    }

    // FILL {} used to populate table of contents
    function updateSectionsWithContent( key='', value, JSXchildren ) {
        if ( sections1D.includes( key ) ) {
            if ( sectionsInTableOfContents.includes( key ) === false ) {
                return true

            } else if ( !!JSXchildren ) {
                sectionsWithContent[ key ] = value;
                return true
            }
        } else if ( typeof value === 'boolean' ) {
            if ( JSXchildren?.[ 0 ]?.type !== 'section' || key in sectionsWithContent ) {
                if ( key in sectionsWithContent === false ) sectionsWithContent[ key ] = {}
                return true
            }
        } else if ( !!JSXchildren ) {
            if ( key in sectionsWithContent ) {
                const nestedEntries = Object.entries( value )[ 0 ];

                sectionsWithContent[ key ][ nestedEntries[ 0 ] ] = nestedEntries[ 1 ];
            } else { sectionsWithContent[ key ] = value }
            return true
        }

        return false
    }

    function wrapInHeader( headerNum=2, text ) {
        switch ( headerNum ) {
            case 1: return <h1>{ text }</h1>;
            case 2: return <h2>{ text }</h2>;
            case 3: return <h3>{ text }</h3>;
            case 4: return <h4>{ text }</h4>;
            case 5: return <h5>{ text }</h5>;

            default: return <h6>{ text }</h6>;
        }
    }

    // component functions
    const [
        Accronym,
        AccreditedSchoolOrCounty,
        Alias,
        ChoiceBoard,
        EducationAccount,
        EducationRecord,
        Hive,
        LegalGuardian,
        LongUpdateDate,
        OfAgeStudent,
        Pronoun,
        QueenBee,
        ShortUpdateDate,
        Student,
        Instructor,
        User,
        Visitor,
        WorkerBee
    ] = [
        ( acronym='' ) => glossaryExports.getTermFor( acronym.toLocaleUpperCase(), glossary ),
        ( suffix='y' ) => glossaryExports.getTermFor( 'Educational Entit' + ( suffix === 's' ? 'ies' : suffix ), glossary ),
        ( suffix='' ) => glossaryExports.getTermFor( 'Alias' + ( suffix === 's' ? 'es' : suffix ), glossary ),
        ( suffix='' ) => glossaryExports.getTermFor( 'BumbleBoard' + suffix, glossary ),
        ( suffix='' ) => glossaryExports.getTermFor( 'Education Account' + suffix, glossary ),
        ( suffix='' ) => glossaryExports.getTermFor( 'Education Record' + suffix, glossary ),
        ( suffix='' ) => glossaryExports.getTermFor( 'Hive' + suffix, glossary ),
        ( suffix='' ) => glossaryExports.getTermFor( 'Legal Guardian' + suffix, glossary ),
        ( updateDate ) => <span className={ styles.date }>{
            updateDate.toLocaleDateString( undefined, { month: 'long', day: 'numeric', year: 'numeric' } )
        }</span>,
        ( suffix='' ) => glossaryExports.getTermFor( 'Eligible Learner' + suffix, glossary ),
        ( pronoun='' ) => glossaryExports.getTermFor( pronoun, glossary ),
        ( suffix='' ) => glossaryExports.getTermFor( 'Queen Bee' + suffix, glossary ),
        ( updateDate ) => <span className={ styles.date }>{
            [ updateDate.getMonth() + 1, updateDate.getDate(), updateDate.getFullYear() ].join( '.' )
        }</span>,
        ( suffix='' ) => glossaryExports.getTermFor( 'Learner' + suffix, glossary ),
        ( suffix='' ) => glossaryExports.getTermFor( 'Educator' + suffix, glossary ),
        ( suffix='' ) => glossaryExports.getTermFor( 'User' + suffix, glossary ),
        ( suffix='' ) => glossaryExports.getTermFor( 'Visitor' + suffix, glossary ),
        ( suffix='' ) => glossaryExports.getTermFor( 'Worker Bee' + suffix, glossary ),
    ];

    const ContactUs = ( verbEnding='' ) => {
        const title = getSpacedTitleCaseFromCamelCase(
            Object.keys( sections ).find( e => e.toLocaleLowerCase().includes( 'contact' ) )
        );

        return <>contact{ verbEnding } { Pronoun( 'us' ) } as detailed under { glossaryExports.getLink( title, '#' + getID( title ) ) }</>
    }

    // PROPS, CONTEXT, & ATTRIBUTES
    const props = {
        isEUCompliant,
        info: {
            ...glossaryExports,
            isValidDate, getSection, getSectionLink,

            componentFunctions: { Accronym, AccreditedSchoolOrCounty, Alias, ChoiceBoard, ContactUs, EducationAccount, EducationRecord, Hive, LegalGuardian, LongUpdateDate, OfAgeStudent, Pronoun, QueenBee, ShortUpdateDate, Student, Instructor, User, Visitor, WorkerBee },
            components: {
                BumbleBoard: <>the { glossaryExports.getTermFor( 'Service', glossary ) }</>,
                Glossary: glossaryExports.getGlossaryComponent( glossary, styles, 'glossary', 'Policy' ),
                PersonalLearnerInformation: glossaryExports.getTermFor( 'Personal Learner Information', glossary ),
                PersonalUserInformation: glossaryExports.getTermFor( 'Personal User Information', glossary ),
            },
            sections: sections[ page ] || {},
        },
        updateYearMonthDay: dates[ page ] || [ 1967, 0, 17 ],
    };

    // COMPONENTS
    let Page;
    switch ( page ) {
        case 'privacy': Page = <PrivacyPolicy { ...props }/>; break;
        case 'terms': Page = <TermsAndServices { ...props }/>; break;

        default: Page = <div>Under Construction...</div>; break;
    };

    // RENDER
    return <article className={ styles.page }>
        <Nav brandLinkTo='/' centreBrand leftIcon={ <AnimatedLogo /> } rightIcon={ false } />

        { Page }

        <Footer />
    </article>
}

export default Policy;