// Import the functions you need from the SDKs you need
// import firebase from 'firebase'
import { initializeApp } from 'firebase/app';
// import { getAnalytics } from "firebase/analytics";
// import { getDatabase, ref, set } from "firebase/database";
import {
    getFirestore,
    query,
    getDocs,
    collection,
    where,
    addDoc
} from 'firebase/firestore';
import {
    GoogleAuthProvider,
    getAuth,
    signInWithPopup,
    signInWithEmailAndPassword,
    createUserWithEmailAndPassword,
    sendPasswordResetEmail,
    onAuthStateChanged,
    signOut
} from 'firebase/auth';

// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
// Update Your .env.development.local file for these values
const firebaseConfig = {
    apiKey: process.env.REACT_APP_API_KEY,
    authDomain: process.env.REACT_APP_AUTH_DOMAIN,
    projectId: process.env.REACT_APP_PROJECT_ID,
    storageBucket: process.env.REACT_APP_STORAGE_BUCKET,
    messagingSenderId: process.env.REACT_APP_MESSAGING_SENDER_ID,
    appId: process.env.REACT_APP_APP_ID,
    measurementId: process.env.REACT_APP_MEASUREMENT_ID
};

// Initialize Firebase
const app = initializeApp( firebaseConfig );
// const analytics = firebase.getAnalytics(app);
const db = getFirestore( app );
const auth = getAuth( app )
// export const provider = new GoogleAuthProvider()

// https://blog.logrocket.com/user-authentication-firebase-react-apps/
const googleProvider = new GoogleAuthProvider();

const signInWithGoogle = async () => {
    try {
        const response = await signInWithPopup(auth, googleProvider);
        const user = response.user;
        window.location.hostname === 'localhost' && console.log( user, response );

        const q = query(
            collection( db, 'users' ),
            where( 'uid', '==', user.uid )
        );

        const docs = await getDocs( q );

        if ( docs.docs.length === 0 ) {
            await addDoc(
                collection( db, 'users' ), {
                    uid: user.uid,
                    name: user.displayName,
                    authProvider: "google",
                    email: user.email,
                }
            );
        }
    } catch( error ) {
        console.error( error );
        alert( error.message );
    }
}

const logInWithEmailAndPassword = async ( email='', password='' ) => {
    try {
        await signInWithEmailAndPassword( auth, email, password );
    } catch( error ) {
        console.error( error );
        alert( error.message );
    }
}

const registerWithEmailAndPassword = async ( name='', email='', password='' ) => {
    try {
        // console.log("set res")
        // console.log(name, email, password)
        const response = await createUserWithEmailAndPassword( auth, email, password);
        // console.log("user")
        const user = response.user;

        // Add Record Doc for User in Native Project
        await addDoc( collection( db, 'users' ), {
            uid: user.uid,
            name,
            authProvider: "local",
            email,
        } )

        // Add Record Doc for User in Backend API Project
        await addDoc( collection( db, 'users' ), {
            uid: user.uid,
            name,
            authProvider: "local",
            email,
        } )
    } catch( error ) {
        console.error( error );
        alert( error.message );
    }
}

// User is signed in, see docs for a list of available properties
  // https://firebase.google.com/docs/reference/js/firebase.User
const getAuthState = async ( auth, user ) => {
    try {
        onAuthStateChanged( auth, user );
    } catch ( error ) { console.error( `❌ ${ error.message }` ) }
}

const logout = () => signOut( auth )

const sendPasswordReset = async ( email='' ) => {
    try {
        await sendPasswordResetEmail( auth, email );
        alert( 'Password reset link sent!' );
    } catch( error ) {
        console.error( '❌', error );
    }
}

export {
  auth,
  db,
  getAuthState,
  logInWithEmailAndPassword,
  logout,
  registerWithEmailAndPassword,
  sendPasswordReset,
  signInWithGoogle,
};