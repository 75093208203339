import { useCallback, useEffect, useMemo, useRef } from 'react';
import styles from './ModalContainer.module.css';
import get from '../../constants/get';
import Button from './Button';
import SVG from '../modular/Images/SVG';

const Modal = ({
    Children,
    isLocked = false,
    isOpen = false,
    maxHeight = null,
    maxWidth = null,
    position = 'centre',
    toggleModal = () => {},
}) => {
    const modalRef = useRef( null );

    // useCallback
    const getDialogElement = useCallback( () => modalRef.current, [ modalRef ] )

    const closeModal = useCallback( () => {
        getDialogElement().close();
        toggleModal();
    }, [ toggleModal, getDialogElement ] )

    const closeModalOnCancel = useCallback( e => {
        e.preventDefault();
        isLocked || closeModal()
    }, [ isLocked, closeModal ] )

    const closeModalOnClickOutsideModal = useCallback( ({ target }) => {
        if ( target === getDialogElement() && !isLocked ) closeModal()
    }, [ isLocked, closeModal, getDialogElement ] )

    const closeModalWhenAnimationEnds = useCallback(
        () => { if ( isOpen ) closeModal() }, [ isOpen, closeModal ]
    )

    // showModal() if isOpen
    useEffect( () => { if ( isOpen ) getDialogElement().showModal() }, [ isOpen, getDialogElement ] )

    const classNames = useMemo( () => get.classNameFromArr([
        styles.modal,
        styles[ position ],
        !isOpen && styles[ 'is-closing' ]
    ]), [ isOpen, position ] );

    // PROPS & ATTRIBUTES
    const style = { maxHeight, maxWidth };
    if ( position !== 'centre' ) style[ `margin${ get.capitalise([ position ]) }` ] = 0;

    // COMPONENTS
    const CloseButton = <Button
        className={ styles[ 'close-button' ] }
        label={ <SVG type={{ xmark: true }}/> }
        onClick={ closeModal }
    />;

    // RENDER
    return <dialog
        ref={ modalRef }
        className={ classNames }
        onCancel={ closeModalOnCancel }
        onClick={ closeModalOnClickOutsideModal }
        onAnimationEnd={ closeModalWhenAnimationEnds }
        style={ style }
    >
        { CloseButton }
        <div className={ styles.container }>{ Children }</div>
    </dialog>
}

export default Modal;