import { useContext, useEffect, useMemo, useState } from 'react';
import styles from './Units.module.css';
import UserContext from '../../../../contexts/UserContext';
// import { arrayUnion, collection, doc, getDoc, getDocs, setDoc, updateDoc, where } from 'firebase/firestore';
import { collection, doc, getDoc, getDocs, setDoc, where } from 'firebase/firestore';
import { db } from '../../../../firebase_setup/firebase';
import get from '../../../../constants/get';
import Button from '../../Button';
import courseInfo from '../../../../constants/courseInfo';
import FilterAndSort from '../../FilterAndSort';
import Curriculum from './Curriculum';

const Units = ({ county={} }) => {
    const user = useContext( UserContext );
    const [ units, setUnits ] = useState({});
    const [ unitsView, setUnitsView ] = useState({});
    const [ selection, setSelection ] = useState({});
    const [ formMode, setFormMode ] = useState( false );

    const school = useMemo( () => county?.schools?.[ user?.schoolIDs[ 0 ] ]
        || county?.schools?.[ 0 ]
        || { countyID: 'us-no-county' }, // or bumbleboard-county, [] );
    [ county, user?.schoolIDs ] )

    useEffect( () => {
        const unitsObj = {};

        getCountyUnits( unitsObj, user, county, school ).then( () => {
            const placeholdersUnits = getPlaceholdersObj( unitsObj.topics );
            Object.values( placeholdersUnits ).forEach( unit => unitsObj[ unit.subject ] = unit );

            setUnits( () => unitsObj )
            setUnitsView ( () => Object.fromEntries( Object.keys( unitsObj )
                .filter( subject => subject !== 'topics' )
                .map( subject => [ subject, { collapsed: false, 'showAll': false } ] ) ) )
        } )
    }, [ county, school, user ] );

    // const userUnitKey = [ get.username( user.email ), user.data.uid ].join( '-' );
    const isBumbleBoard = user.data.email.startsWith( 'bumbleboard@' );

    const schoolYear = county?.schoolYear || {};
    const fakeUnit = courseInfo.getUnitObj({
        background: { hex: get.hexFromHSL(
            school.hsls[ 0 ].h, school.hsls[ 0 ].s, school.hsls[ 0 ].l
        ), ...school.hsls[ 0 ] },
        blocks: 20,
        level: 'any',
        subject: 'any',
        theme: 'test unit',
        schoolYear,
    });

    [ fakeUnit.creatorID, fakeUnit.countyID, fakeUnit.schoolID, fakeUnit.creatorEmail ] = [
        user.data.uid, county.id, school.id, user.data.email
    ];

    // FUNCTIONS
    function changeSubject({ target: { id } }) {
        const currentUnit = units[ selection.subject ];
        const currentLevel = selection.level;
        const [ levelI, subject ] = [
            currentUnit.levels.arr.findIndex( level => level === currentLevel ),
            id.slice( 10 )
        ];

        const newUnit = units[ subject ];
        const level = newUnit.levels[ currentLevel ] ? currentLevel
            : ( newUnit.levels.arr[ levelI ] || newUnit.levels.arr[ 0 ] );

        setSelection( () => ({ subject, level }) )
        setUnitsView( unitsView => ({
            ...unitsView, [ subject ]: {
                showAll: false, collapsed: false, [ level ]: true
            }
        }) )
    }

    function getPlaceholdersObj( curriculum={} ) {
        if ( !Object.keys( curriculum )[ 0 ] ) return {}
        // const defaultObj = { emoji: '📚', levels: { arr: [] }, label: '' };

        const languageObj = {
            de: { emoji: '🥨', flag: '🇩🇪', levels: { arr: [] }, label: 'deutsch', subject: 'german' },
            es: { emoji: '🥘', flag: '🇪🇸', levels: { arr: [] }, label: 'español', subject: 'spanish' },
            fr: { emoji: '🥐', flag: '🇫🇷', levels: { arr: [] }, label: 'français', subject: 'french' },
            en: { emoji: '☕️', flag: '🇬🇧', levels: { arr: [] }, label: 'english', subject: 'english' }
        };

        // console.log({ curriculum });
        const colours = curriculum.colourSchemes.rainbow;
        const languages = Object.keys( curriculum[ 'level-1' ][ 0 ] )
            .filter( key => key !== 'emoji' );

        [ 'level-1', 'level-2', 'level-3', 'level-4', 'level-5', 'level-ap' ].forEach( ( levelKey, i ) => {
            const level = levelKey.split( '-' )[ 1 ];
            const themes = curriculum[ levelKey ];
            // console.log({ level, levelKey });
            languages.forEach( lang => {
                const langThemes = themes.map( obj => obj[ lang ] || obj.en );

                languageObj[ lang ].levels.arr.push( level );
                languageObj[ lang ].levels[ level ] = Object.fromEntries( langThemes.map(
                    ( theme, j ) => [ theme, {
                        theme: lang === 'en' ? get.capitalise( theme )
                            : lang === 'de' ? theme
                            : theme[ 0 ].toLocaleUpperCase() + theme.slice( 1 ),
                        duration: { classes: 20, boards: 9 },
                        background: { hex: j === ( colours.length - 1 ) ? '#4cbb17' : colours[ j + 1 ] },
                        emoji: curriculum[ levelKey ][ j ].emoji || languageObj[ lang ].emoji,
                        isPlaceholder: true
                    } ]
                ) );
            } );
        } );

        return languageObj;
    }

    function handleSelections( selection={} ) {
        selection = Object.values( selection )[ 0 ];
        const subject = Object.keys( unitsView ).find( subject => selection.includes( subject ) );
        const level = units[ subject ].levels.arr.find( level => selection.includes( level ) );

        setSelection( () => ({ subject, level }) );
        setUnitsView( unitsView => ({ ...unitsView, [ subject ]: {
            collapsed: !!unitsView[ subject ].collapsed,
            showAll: !!unitsView[ subject ].showAll,
            [ level ]: true
        } }) )
    }

    // async function pushUnitsToDB( unitsArr = [] ) {
    //     if ( unitsArr[ 0 ] ) {
    //         const newUnits = { ...units };
    //         // debugger;

    //         Promise.all( unitsArr.map( unit => {
    //             let { subject, level, theme } = unit;
    //             if ( !theme ) return null

    //             if ( !subject ) subject = 'any'
    //             if ( !level ) level = 'any'

    //             return Promise.all([
    //                 getDoc( doc( db, 'units', subject ) ),
    //                 getDoc( doc( db, 'units', subject, level, theme ) ),
    //             ]);

    //         } ) ).then( dbArr => {
    //             dbArr.forEach( ( response, i ) => {
    //                 const [ subjectDoc, themeDoc ] = response;
    //                 const [ dbSubject, dbUnit, dbSubjectExists, dbUnitExists, unit ] = [
    //                     subjectDoc.data(),
    //                     themeDoc.data() && ( themeDoc.data()?.[''] || themeDoc.data()?.primary ),
    //                     subjectDoc.exists(),
    //                     themeDoc.exists(),
    //                     unitsArr[ i ]
    //                 ];

    //                 let { subject, level, theme } = dbUnitExists ? dbUnit : unit;
    //                 const details = { [ theme ]: unit };

    //                 let { levels, description } = dbSubjectExists ? dbSubject : {
    //                     levels: { arr: [ level ], [ level ]: { ...details } },
    //                     description: `for all ${ subject === 'any' ? 'miscellaneous' : subject } units`,
    //                     subject,
    //                     department: {
    //                         name: 'world languages',
    //                         subjects: [ 'french', 'spanish', 'german' ]
    //                     }
    //                 };
    //                 console.log( levels );

    //                 const subjectRef = doc( db, 'units', subject );
    //                 const unitRef = doc( subjectRef, level, theme );
    //                 console.log({ newUnits });

    //                 if ( !dbSubjectExists ) { // if there is no subject doc in the db
    //                     newUnits[ subject ] = { description, levels };
    //                     setDoc( subjectRef, { description, levels: levels.arr }, { merge: true } );

    //                 } else if ( dbSubject.levels ) { // if the subject doc is in the db & has a levels array
    //                     if ( Array.isArray( levels ) ) levels = newUnits[ subject ].levels = { arr: levels }
    //                     console.log({ levels });

    //                     if ( !levels.arr?.includes( level ) ) {
    //                         levels.push( level ) && newUnits[ subject ].levels.push( level );
    //                         updateDoc( subjectRef, { levels: arrayUnion( level ) } );
    //                     }

    //                     if ( !levels[ level ] ) levels[ level ] = newUnits[ subject ].levels[ level ] = { ...details }
    //                 } else { // the subject exists in the db, but there is no levels array
    //                     levels.arr = newUnits[ subject ].levels.arr = [ level ];
    //                     setDoc( subjectRef, { levels: levels.arr }, { merge: true } );
    //                 }

    //                 if ( dbUnitExists ) {
    //                     updateDoc( unitRef, { [ [ county.id, school.id, user.data.uid ].join( '.' ) ]: arrayUnion( unitsArr[ i ] ) } );
    //                 } else {
    //                     setDoc( unitRef, { [ county.id ]: { [ school.id ]: { [ user.data.uid ]: [ unitsArr[ i ] ] } } }, { merge: true } );
    //                     updateDoc( unitRef, { counties: arrayUnion( county.id ) } );
    //                 }
    //             } );

    //             setUnits( () => newUnits )
    //         } );
    //     } else { console.error( '❌ THERE IS NO UNIT TO PUSH TO DB' ); }
    // }

    function toggleFormMode({ target: { id } }) {
        setFormMode( formMode => {
            if ( !!formMode ) return false
            const [ subject, level ] = id.split(' ');
            const isNewSubject = id === 'new-subject';

            const relevantCourses = [ user.courses.queen || [], user.courses.worker || [] ].flat()
                .filter( course => course.subject.toLocaleLowerCase() === subject.toLocaleLowerCase() );

            const obj = isNewSubject ? { subject: '', level: '', levels: { arr: [] } }
                : { subject, level, levels: { arr: relevantCourses.map( course => course.level || 'any' ) || [] } };

            if ( !isNewSubject ) relevantCourses.forEach( course => obj.levels[ course.level || 'any' ] = { ...course } )
            console.log({ id, subject, level, relevantCourses, obj });

            return obj
        } )
    }
    console.log({ formMode, selection, school, units });

    function updateUnits( unitDetails={} ) {
        console.log( `UNIT #${ unitDetails.unitI + 1 }`, unitDetails, units );
        const { subject, level, unitI, details } = unitDetails;
        updateUnitsInDB( unitDetails );

        setUnitsView( unitsView => ({ ...unitsView, [ subject ]: {
            showAll: false, collapsed: false, [ level ]: !!unitsView[ subject ][ level ]
        } }) )

        setUnits( units => {
            const unitsAreInArray = Array.isArray( units[ subject ].levels[ level ] );
            if ( !unitsAreInArray ) units[ subject ].levels[ level ] = Object.values( units[ subject ].levels[ level ] )

            const unit = units[ subject ].levels[ level ][ unitI ];
            delete unit.isPlaceholder;
            delete unit.emoji;

            Object.keys( details ).forEach( key => {
                if ( key === 'theme' ) unit.translatedTheme = details[ key ]
                unit[ key ] = details[ key ]
            } )
            return units
        } )
    }

    async function updateUnitsInDB( unit={} ) {
        const [ dept, level, subject, subjects ] = [
            get.stringInNewFormat( unit.department.name, ' ', { dashed: true } ),
            unit.level * 1 || unit.level,
            unit.subject === 'english' ? 'english-wl' : ( unit.subject || 'miscellaneous' ),
            unit.department?.subjects ? unit.department.subjects.filter(
                e => e !== 'english-wl'
            ) : []
        ];

        const [ subjectIsLanguage, levelKey, subjectKey, subjectsStr ] = [
            isLanguage( subject ),
            'level-' + level,
            subject.includes(' ') ? subject.toLocaleLowerCase().split(' ').join( '-' ) : subject.toLocaleLowerCase(),
            [
                ...subjects.slice( 0, subjects.length - 1 ),
                'and ' + get.lastArrayElement( subjects )
            ].join( ', ' ),
        ];

        const unitKey = get.stringInNewFormat( subjectIsLanguage ? units.topics[ levelKey ][ unit.unitI ].en : unit.details.theme, ' ', { dashed: true } ) + ( isBumbleBoard ? '-' + school.countyID : '' );

        Promise.all([
            getDoc( doc( db, 'units', dept ) ),
            getDoc( doc( db, 'units', dept, 'subjects', subjectKey ) ),
            getDoc( doc( db, 'units', dept, 'subjects', subjectKey, levelKey, unitKey ) ),
        ]).then( dbDocsArr => {
            const [ departmentDoc, subjectDoc, unitDoc ] = dbDocsArr;
            console.log({ departmentDoc, subjectDoc, unitDoc });

            if ( !departmentDoc.exists() || !subjectDoc.exists() ) setDoc( departmentDoc.ref, {
                name: unit.department.name,
                iconKey: unit.department.name,
                subjects: unit.department.subjects,
                description: `the ${ get.stringInNewFormat( unit.department.name, ' ', { titleCase: true } ) }, for all ${ subject === 'any' ? 'miscellaneous' : subjectsStr } courses`,
                levelsBySubject: Object.fromEntries( subjects.map(
                    subj => [ subj, units[ subject.includes( 'wl' ) ? 'english' : subject ].levels.arr ]
                ) )
            }, { merge: true } )

            if ( !subjectDoc.exists() ) {
                const subjectDetails = {
                    countiesWithCurriculum: {
                        'us-no-county': Object.keys( units[ subject.includes( 'wl' ) ? 'english' : subject ].levels[ level ] )
                    },
                    department: unit.department.name,
                    icon: units[ subject.includes( 'wl' ) ? 'english' : subject ].icon || units[ subject.includes( 'wl' ) ? 'english' : subject ].emoji || '🫥',
                    levels: units[ subject.includes( 'wl' ) ? 'english' : subject ].levels.arr,
                    name: subject,
                };

                if ( isBumbleBoard ) {
                    subjectDetails.countiesWithCurriculum[ 'us-no-county' ] = Object.keys( units[ subject.includes( 'wl' ) ? 'english' : subject ].levels[ level ] ) || [];
                } else if ( school.countyID !== 'us-no-county' ) {
                    subjectDetails.countiesWithCurriculum[ school.countyID ] = Object.keys( units[ subject.includes( 'wl' ) ? 'english' : subject ].levels[ level ] ) || [];
                }

                if ( subjectIsLanguage ) {
                    subjectDetails.flag = units[ subject.includes( 'wl' ) ? 'english' : subject ].flag || '🏳️';
                    if ( subjectDetails.icon === '🫥' ) subjectDetails.icon = subjectDetails.flag;

                    subjectDetails.translation = subject.includes( 'wl' ) ? 'english' : getLanguageTranslation( subject );
                }

                // if ( units[ subject.includes( 'wl' ) ? 'english' : subject ]?.countiesWithCurriculum?.[ isBumbleBoard ? 'us-no-county' : school.countyID ] ) ''

                setDoc( subjectDoc.ref, subjectDetails, { merge: true } )
            }

            const unitDetails = {
                ...unit.details, level,
                i: unit.unitI,
                background: { hex: getUnitHex( unit.unitI ) }
            };

            if ( subjectIsLanguage ) {
                unitDetails.theme = units.topics[ levelKey ][ unit.unitI ].en;
                unitDetails.translation = unit.details.theme
            }

            setDoc( unitDoc.ref, unitDetails, { merge: true } )
        } );
    }

    // async function setDepartmentDoc( departmentDoc, departmentDetails={} ) {
    //     setDoc( departmentDoc.ref, departmentDetails, { merge: true } )
    // }

    // async function setSubjectDoc( subjectDoc, subject='', level, icon, flag ) {
    //     const subjectDetails = { icon };

    //     if ( isLanguage( subject ) ) subjectDetails.flag = flag
    //     setDoc( subjectDoc, subjectDetails, { merge: true } )
    // }

    // PROPS & ATTRIBUTES
    // COMPONENTS
    const Result = selection.subject ? <Curriculum
        changeSubject={ changeSubject }
        className={ styles[ 'curriculum-container' ] }
        curriculum={ formMode || units[ selection.subject ] }
        isForm={ !!formMode }
        subject={ selection.subject || formMode?.subject }
        unitsView={ unitsView }
        updateUnits={ updateUnits }
    /> : <ul>{[
        ...user.courses.queen, ...( user.courses.worker || [] ), { subject: '', level: '' }
    ].map( course => <li key={ [ course.subject, course.level || 'any', 'li' ].join( '-' ) }>
        <Button
            label={ !course.subject ? 'new subject' : [ '+', course.subject, course.level || '' ].join(' ') }
            id={ !course.subject ? 'new-subject' : [ course.subject, course.level || 'any' ].join(' ') }
            onClick={ toggleFormMode }
        />
    </li> ) }</ul>;

    const SidebarContents = formMode && <div>
        <ul>
            <li>
                <header className={ styles.label }>SUBJECT</header>
                <span className={ styles.details }>{ formMode.subject || 'new subject' }</span>
            </li>
            <li>
                <header className={ styles.label }>THEMES/TOPICS</header>
                { formMode.levels.arr[ 0 ] ? <ul className={ styles.details }>{ formMode.levels.arr.map(
                    level => <li key={ [ formMode.subject, level, 'in-sidebar' ].join( '-' ) }>{ level }</li>
                ) }</ul> : <i>N/A</i> }
            </li>
        </ul>
    </div>;

    // RENDER
    return <section className={ styles.units }>
        <FilterAndSort
            filters={{ categories: [ 'Languages' ], subCategories: [ Object.keys( unitsView ).flatMap(
                subject => units[ subject ]?.levels?.arr[ 0 ] ? units[ subject ].levels.arr.map(
                    level => [ subject, `${ level }`.toLocaleUpperCase() ].join(' ')
                ) : subject
            ) ] }}
            passSelectionUp={ handleSelections }
            Result={ Result }
            SidebarContents={ SidebarContents }
        />
        {/* <br /> */}
        {/* <Button onClick={ () => pushUnitsToDB([ fakeUnit ]) } label='ADD UNIT'/> */}
    </section>
}

// OTHER FUNTIONS
function addUnitToUnitsObj( unitsObj={}, subjectID='', subjectData={}, subjects=[], levels=[] ) {
    const isCurriculum = subjectID === 'topics';
    unitsObj[ subjectID ] = isCurriculum ? subjectData : { levels: getUnitsByLevel( subjectData?.levels || [] ) };

    unitsObj[ subjectID ][
        isCurriculum ? 'levels' : 'description'
    ] = isCurriculum ? { arr: [] } : subjectData.description;

    if ( unitsObj[ subjectID ].levels.arr[ 0 ] ) subjects.push( subjectID )
        && levels.push( unitsObj[ subjectID ].levels.arr )
}

async function getCountyUnits( unitsObj={}, user={}, county={}, school={} ) {
    try {
        await getDocs( collection( db, 'units' ) ).then( querySnapshot => {
            const [ levels, subjects ] = [ [], [] ];

            querySnapshot.forEach( subject => {
                const isValid = !user.isLimitedToSubject ? true
                    : user.courses?.queen?.some( course => course.subject === subject.id );
                isValid && addUnitToUnitsObj( unitsObj, subject.id, subject.data(), subjects, levels );
            } );

            // console.log({ subjects, levels });
            Promise.all( subjects.map( ( subject, i ) => getSubjectLevelData( subject, levels[ i ], unitsObj, user, county, school ) ) )
        } )
    } catch ( error ) {
        console.error( '❌ COULDN\'T GET UNITS:', error )
    }
}

function getLanguageTranslation( language='' ) {
    language = language.toLocaleLowerCase();

    if ( language !== 'asl' ) {
        switch ( language ) {
            case 'french': return 'français';
            case 'spanish': return 'español';
            case 'italian': return 'italiano';
            case 'portuguese': return 'português';
            case 'german': return 'deutsch';
            case 'japanese': return '日本語 (nihongo)';
            case 'korean': return '한국어 (hangugeo)';
            case 'mandarin': return '普通话 (pǔtōng huà)';
            case 'chinese': return '普通话 (pǔtōng huà)';
            case 'latin': return 'latīna || latīnus';

            default: return 'english-wl';
        }
    } else { return language }
}

async function getSubjectLevelData( subject='', levels=[], unitsObj={}, user={}, county={}, school={} ) {
    if ( !Array.isArray( levels ) ) levels = [ levels ]

    try {
        // console.log(`🚨 query ${ levels.map( ( level, i ) => [ ( i && i === levels.length - 1 ) ? '& db' : 'db', 'units', subject, level ].join( '/' ) ).join('\n') } for unit docs wherein the ${ user.isLimitedToSchool ? 'schoolID' : user.isLimitedToCounty ? 'countyID' : 'creatorID' } is ${ user.isLimitedToSchool ? school.id : user.isLimitedToCounty ? county.id : 'anything BUT "x"' }`);

        Promise.all( levels.map( level => {
            const levelRef = collection( db, 'units', subject, level );

            return ( !user.isLimitedToCounty && !user.isLimitedToSchool ) ? getDocs( levelRef )
                : getDocs( levelRef, where( 'counties', 'array-contains', county.id ) );
        } ) ).then( arr => arr.forEach( ( snapshots, levelI ) => {
            const level = levels[ levelI ];
            unitsObj[ subject ].levels[ level ] = {};

            snapshots.docs.forEach( snapshot => {
                const theme = snapshot.id;
                unitsObj[ subject ].levels[ level ][ theme ] = [];

                if ( user.isLimitedToSchool ) {
                    Object.values( snapshot.data()?.[ county.id ]?.[ school.id ] ).forEach(
                        userUnits => unitsObj[ subject ].levels[ level ][ theme ].push( ...Object.values( userUnits ) )
                    );
                } else if ( user.isLimitedToCounty ) {
                    Object.values( snapshot.data()?.[ county.id ] ).forEach(
                        schoolObj => Object.values( schoolObj ).forEach(
                            userUnits => unitsObj[ subject ].levels[ level ][ theme ].push( ...Object.values( userUnits ) )
                        )
                    );
                } else {
                    Object.values( snapshot.data() ).forEach(
                        countyObj => Array.isArray( countyObj ) || Object.values( countyObj ).forEach(
                            schoolObj => Object.values( schoolObj ).forEach(
                                userUnits => unitsObj[ subject ].levels[ level ][ theme ].push( ...Object.values( userUnits ) )
                            )
                        )
                    );
                }

                if ( unitsObj[ subject ].levels[ level ][ theme ].length === 1 ) unitsObj[ subject ].levels[ level ][ theme ] = unitsObj[ subject ].levels[ level ][ theme ][ 0 ]
            } )
        } ) );
    } catch ( error ) { console.error( `❌ COULDN'T GET ${ subject } ${ levels.join( '/' ) } UNITS:`, error ) }
}

function getUnitHex( unitI=0 ) {
    switch ( unitI ) {
        case 0: return '#e60073';
        case 1: return '#e62600';
        case 2: return '#e67300';
        case 3: return '#ffaa00';
        case 4: return '#ffd500';
        case 5: return '#4cbb17';

        default: return '#bfbfbf';
    }
}

function getUnitsByLevel( levels=[] ) {
    const obj = { arr: levels.slice() };
    levels.forEach( level => obj[ level ] = {} );

    return obj
}

function isLanguage( subject='' ) {
    return [ 'french', 'spanish', 'german', 'english-wl', 'japanese', 'mandarin', 'chinese', 'korean', 'asl', 'vietnamese', 'italian', 'portuguese' ].includes( subject.toLocaleLowerCase() )
}

export default Units;