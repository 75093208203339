import { Link, useParams } from 'react-router-dom';
import Nav from '../Nav';
import styles from './Shop.module.css';
import { useContext, useMemo, useState } from 'react';
import get from '../../../constants/get';
import Button from '../Button';
import ShopSidebar from './ShopSidebar';
import LoadingHive from '../../modular/Loaders/LoadingHive';
import ShopContext from '../../../contexts/ShopContext';

const Shop = () => {
    const { courses, getRelevantCoursesArr, languages, languageUnits, relevantCoursesArr, units, username } = useContext( ShopContext );
    const { subject, level } = useParams();

    let dashedID = subject ? subject.split(' ')?.join( '-' ) : '';
    if ( level ) dashedID = dashedID + '-' + level

    const all = {
        courses,
        subjects: getLevelsOrSubjectsArr( relevantCoursesArr, false ),
        levels: getFilteredLevelsArr( relevantCoursesArr ),
    };

    const [ active, setActive ] = useState({
        subjects: all.subjects,
        levels: all.levels
    });

    const levelsBySubject = getLevelsBySubject( relevantCoursesArr );
    const urlPieces = [ '', username ];
    if ( dashedID ) urlPieces.push( relevantCoursesArr[ 0 ].id.db, 'calendar' )

    // FUNCTIONS
    function getFilteredLevelsArr( coursesArr=null ) {
        coursesArr = coursesArr || getRelevantCoursesArr( 'subject', all.subjects );

        return getLevelsOrSubjectsArr( coursesArr, true )
    }

    function getLevelsOrSubjectsArr( coursesArr=[], getLevelsArr=false ) {
        const arr = coursesArr.map( course => course[ getLevelsArr ? 'level' : 'subject' ]
            && ( '' + course[ getLevelsArr ? 'level' : 'subject' ] ) )
            .filter( ( e, i, arr ) => e && arr.findIndex( f => f === e ) === i );

        return arr
    }

    function getLevelsBySubject( coursesArr=[] ) {
        const obj = {};

        coursesArr.forEach( course => {
            const [ isLanguageCourse, level ] = [
                languages.includes( course.subject ),
                course.level ? course.level + '' : ''
            ];

            const units = isLanguageCourse ? languageUnits[ course.level ] : [
                username === 'bumbleboard' ? 'ADD' : 'NO',
                'UNITS FOR',
                course.subject.toLocaleUpperCase() + ( course.level ? ' ' + course.level : '' )
            ].join(' ');

            if ( course.subject in obj === false ) {
                obj[ course.subject ] = {
                    units: level ? { [ level ]: units } : units,
                    levels: level ? [ level ] : []
                };

            } else if ( !course.level || !obj[ course.subject ].levels.includes( course.level ) ) {
                obj[ course.subject ].levels.push( level );
                obj[ course.subject ].units[ level ] = units;
            }
        } );

        return obj
    }

    function updateActiveSubjects({ target: { id } }) {
        const isBecomingActive = active.subjects.includes( id ) === false;

        setActive( active => {
            const subjects = isBecomingActive ? [ ...active.subjects, id ]
                : active.subjects.filter( subject => subject !== id );

            let coursesArr = all.courses.queen.filter( course => subjects.includes( course.subject ) );
            return { subjects, levels: getLevelsOrSubjectsArr( coursesArr, true ) }
        } )
    }

    function updateActiveLevels({ target: { id } }) {
        const isBecomingActive = active.levels.includes( id ) === false;

        setActive( active => {
            const levels = isBecomingActive ? [ ...active.levels, id ]
                : active.levels.filter( level => level !== id );

            let coursesArr = all.courses.queen.filter( course => levels.includes( course.level + '' ) );
            return { levels, subjects: getLevelsOrSubjectsArr( coursesArr, false ) }
        } )
    }

    // COMPONENTS
    const Units = useMemo( () => {
        const arr = active.subjects.map( subject => {
            const { levels } = levelsBySubject[ subject ];
            // console.log({ levels, units, relevantCoursesArr, subject, level }, get.courseFromLevelAndSubject( level*1, subject, relevantCoursesArr ));

            return levels.map( level => active.levels.includes( level )
                && units
                && units.filter( unit => unit.level === level * 1 ).map( ( unit, i ) => <li
                    className={ styles[ 'unit-square' ] }
                    key={ [ subject, level, unit.theme.split(' ').join( '-' ) ].join( '-' ) }
                ><Button
                    className={ styles[ 'unit-link' ] }
                    isLink
                    linkTarget={[
                        '',
                        username,
                        'shop',
                        subject,
                        level,
                        unit.theme.split(' ').join( '-' ),
                        i,
                        get.courseFromLevelAndSubject( level*1, subject, relevantCoursesArr ).id.db
                    ].join( '/' )}
                    label={ <>
                        <p className={ styles[ 'subject-banner' ] }>{ get.capitalise( subject + ' ' + level ) }</p>
                        <header>{ get.capitalise( unit.theme ) }</header>
                        <p className={ styles[ 'unit-length' ] }>
                            <span className={ styles.blocks }>{ unit.duration.classes } classes</span>
                            <br />
                            <span className={ styles.boards }>{ unit.duration.boards } boards</span>
                        </p>
                    </> }
                /></li>
            ) )
        } ).flat( 2 );

        // console.log( arr.length, arr );
        return arr[ 0 ] ? arr : <li className={ styles[ 'no-units' ] }>NO UNITS AVAILABLE</li>
    }, [ active, levelsBySubject, relevantCoursesArr, units, username ] );

    // RENDER
    return <article className={ styles.container }>
        <Nav
            middleMenu={ 'SHOP' }
            rightIcon={<Link
                to={ urlPieces.join( '/' ) }
                className={ styles[ 'back-button' ] }
            >BACK</Link>}
        />

        <section className={ styles.layout }>
            <ShopSidebar
                active={ active }
                all={ all }
                handleClickOn={{ subjects: updateActiveSubjects, levels: updateActiveLevels }}
            />

            { units === null ? <LoadingHive/> : <div className={ styles.content }>
                <ul className={ styles.units }>{ Units }</ul>
            </div> }
        </section>

    </article>
}

export default Shop;