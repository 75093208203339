import { useContext, useMemo, useState } from 'react';
import styles from './EditUnits.module.css';
import EditUnitsSquare from './EditUnitsSquare';
import EditUnitsHeader from './EditUnitsHeader';
import Button from '../../pages/Button';
import SVG from '../Images/SVG';
import { useNavigate } from 'react-router-dom';
import HiveContext from '../../../contexts/HiveContext';
import UserContext from '../../../contexts/UserContext';
import get from '../../../constants/get';

const EditUnits = ({ className='', passValueUp=() => {}, themes={} }) => {
    const navigate = useNavigate();
    const { course: { level, subject } } = useContext( HiveContext );
    const { email } = useContext( UserContext );
    const username = get.username( email );

    const [ units, setUnits ] = useState({
        titles: themes.titles,
        blocks: themes.blocks,
        backgrounds: themes.backgrounds,
    });

    // FUNCTIONS
    function addFromStore() { navigate( `/${ username }/shop/${ subject }/${ level }` ) }

    function addUnitSquare() {
        setUnits( units => Object.fromEntries( Object.keys( units ).map(
            unitKey => [ unitKey, [ ...units[ unitKey ], unitKey === 'blocks' ? 1
                : unitKey === 'background' ? '#000000' : '' ] ]
        ) ) );
    }

    function submitForm( e ) {
        e.preventDefault();
        passValueUp( e );
    }

    // COMPONENTS
    let UnitSquares = useMemo( () => {
        function generateSquare( i=0 ) {
            const theme = {
                background: units.backgrounds[ i ]?.hex || '#000000',
                blocks: units.blocks[ i ] || 1,
                title: units.titles[ i ] || '',
            };

            return <EditUnitsSquare
                i={ i }
                key={`edit-square-${ i }`}
                keys={ Object.keys( themes ) }
                removeSquare={ removeFieldset }
                theme={ theme }
                updateUsedBlocksArr={ updateUsedBlocksArr }
                updateUnits={ updateUnits }
            />
        }

        function removeFieldset({ target : { id } }) {
            const i = id.split( '-' ).pop() * 1;
            window.location.hostname === 'localhost' && console.log({ i, id });

            setUnits( units => {
                const obj = Object.fromEntries( Object.keys( units ).map( unitsKey => {
                    const arr = Array.isArray( units.titles ) ? units[ unitsKey ].slice() : [];
                    return [ unitsKey, !arr[ 0 ] ? arr : i === 0 ?
                        arr.slice( 1 ) : [ ...arr.slice( 0, i ), ...arr.slice( i + 1 ) ] ]
                } ) );

                return { ...obj }
            } );
        }

        function updateUnits( key='', value, i=0 ) {
            const newValues = units[ key ].slice();
            newValues.splice( i, 1, value );

            setUnits( units => ({ ...units, [ key ]: newValues }) )
        }

        function updateUsedBlocksArr({ target: { id, value } }) {
            const squareI = id.split( '-' ).pop() * 1;
            value *= 1;
            window.location.hostname === 'localhost' && console.log({ id, squareI, value });
            window.location.hostname === 'localhost' && console.log({ units });

            setUnits( units => {
                // units.squares[ squareI ] = generateSquare( squareI );
                // units.usedBlocksArr[ squareI ] = value;
                units.blocks[ squareI ] = value;
                window.location.hostname === 'localhost' && console.log({ units });

                return { ...units }
            } );
        }

        if ( units.titles[ 0 ] ) {
            const arr = units.titles.map( ( e, i ) => generateSquare( i ) );
            return arr.reverse()
        } else { return [ generateSquare() ] }
        // const arr = units.titles[ 0 ] ? units.titles.map( ( e, i ) => generateSquare( i ) )
        //     : generateSquare();
        // console.log( 'ARR:', arr );

        // return arr.reverse()

    }, [ themes, units ] );

    // RENDER
    return <form className={`${ className } ${ styles.form }` } onSubmit={ submitForm }>
        <fieldset className={ styles[ 'header-and-buttons' ] }>
            <EditUnitsHeader usedBlocksArr={ units.blocks }/>
            <fieldset className={ styles.buttons }>
                <Button
                    className={ styles.cancel }
                    onClick={ passValueUp }
                    label={ <><SVG fill1='orangered' fontSize='1.5rem' type={{ undo: true }}/>BACK</> }
                    fillSVGOnHover
                    looksLikeLink
                    // backgroundGradientColours={[ 'maroon' ]}
                />
                <Button
                    className={ styles.add }
                    onClick={ addFromStore }
                    label={ <>🛒 ADD</> }
                    fillSVGOnHover
                />
                <Button
                    className={ styles.create }
                    onClick={ addUnitSquare }
                    label={ <><SVG fill1='black' fontSize='1.5rem' type={{ add: true }}/>CREATE</> }
                    fillSVGOnHover
                />
                <Button
                    className={ styles.submit }
                    type='submit'
                    label={ <><SVG fill1='white' fontSize='1.5rem' type={{ save: true }}/>SAVE</> }
                    labelColour='white'
                    backgroundGradientColours={[ 'gold', 'orange' ]}
                />
            </fieldset>
        </fieldset>

        <fieldset className={ styles[ 'input-fieldset-container' ] }>
            { UnitSquares }
            {/* <button
                className={ styles.add }
                onClick={ addUnitSquare }
                type='button'
            ><i className='fa-solid fa-plus'/></button> */}
        </fieldset>
    </form>
}

export default EditUnits;