const StrToJsx = ( string='', conversions={x : 'strong'} ) => {
    const tags = [ 'i', 'br', 'em', 'strong', 'u', 'sup', 'sub', 'hr', 'b'];
    const needsClosingTag = ( tag='i' ) => !['br', 'hr'].includes(tag);
    const tagsHTML = {
        b: {
            html: ( content='', i=0 ) => <b key={`b-at-${i}-in-${string.slice(0, 3)}...`}>{content}</b>,
        },
        br: {
            html: () => <br />,
        },
        em: {
            html: ( content='', i=0 ) => <em key={`em-at-${i}-in-${string.slice(0, 3)}...`}>{content}</em>,
        },
        hr: {
            html: () => <hr />,
        },
        i: {
            html: ( content='', i=0 ) => <i key={`i-at-${i}-in-${string.slice(0, 3)}...`}>{content}</i>,
        },
        strong: {
            html: ( content='', i=0 ) => <strong key={`strong-at-${i}-in-${string.slice(0, 3)}...`}>{content}</strong>,
        },
        sub: {
            html: ( content='', i=0 ) => <sub key={`sub-at-${i}-in-${string.slice(0, 3)}...`}>{content}</sub>,
        },
        sup: {
            html: ( content='', i=0 ) => <sup key={`sup-at-${i}-in-${string.slice(0, 3)}...`}>{content}</sup>,
        },
        u: {
            html: ( content='', i=0 ) => <u key={`u-at-${i}-in-${string.slice(0, 3)}...`}>{content}</u>,
        },
    }
    
    const addTagsToObj = ( obj={} ) => tags.forEach( tag => {
        obj[tag].opening = `<${tag}>`;
        if ( needsClosingTag(tag) ) obj[tag].closing = `</${tag}>`
    })
    addTagsToObj( tagsHTML );

    const getTagsInString = ( str='' ) => tags.filter(
        tag => str.includes( tagsHTML[tag].opening )
    )

    const getTagIndexInString = ( tag='', str='' ) => str.indexOf( tagsHTML[tag].opening)

    const getEarliestTagInString = ( tags=[''], str='' ) => {
        const indexes = tags.map( tag => getTagIndexInString( tag, str ) );
        const lowestIndex = indexes.slice().sort( (a,b) => a - b )[0];

        return tags[ indexes.indexOf(lowestIndex) ]
    }

    const splitStringAtTag = ( tag='', str='', tagType='opening' ) => {
        const tagHTML = tagsHTML[tag][tagType];

        return [
        str.split( tagHTML )[0],
        str.substring( str.indexOf(tagHTML) + ( tagHTML.length ) ),
    ]};

    /* 
     * 1. get the tags in the string
     * 2. get the index of each one starting from i = 0
     * 3. pick the lowest index
     * 4. split string by the lowest-index-tag
     * 5. push that slice[0] to [ arr ]
     * 6a. if !{tagsHTML}.tag.closing, push {tagsHTML}.tag.html() to [arr] && string = slice[1]
     * 6b. if {tagsHTML}.tag.closing, split slice[1] by closing tag
     * 6b. push {tagsHTML}.tag.html( new-slice[0] ) to [arr] && string = new-slice[1]
     * 7. keep going until no more tags, but any time you do #2, remove any tags that yield -1
    */
    const generateArr = ( tagsInString=[], stringCopy='', arr=[] ) => {
        if ( !tagsInString[0] ) arr.push( stringCopy )

        while ( tagsInString.length > 0 ) {
            let tag = getEarliestTagInString( tagsInString, stringCopy );
            
            let [ beforeOpening, afterOpening ] = splitStringAtTag( tag, stringCopy, 'opening' );
            
            let [ beforeClosing, afterClosing ] = !tagsHTML[tag].closing ? [ '', afterOpening ] 
            : splitStringAtTag( tag, afterOpening, 'closing' );
            
            let i = stringCopy.indexOf(afterOpening);
            stringCopy = afterClosing;
            tag = conversions[tag] ? conversions[tag] : tag;
            tagsInString = getTagsInString(stringCopy)
            
            arr.push( [
                beforeOpening,
                tagsHTML[tag].html( beforeClosing, i ),
                afterClosing 
            ] );

        }
        return arr
    }
    return generateArr( getTagsInString(string), string.slice() )
}

export default StrToJsx;