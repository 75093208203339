import get from '../../../constants/get';
import DefinedTerm from '../../modular/DefinedTerm';

const [ address, company, EUrep, email, URL ] = [
    '8 The Green, Suite B, Dover, DE, 19901',
    'BumbleBoard LLC',
    null,
    'hello@bumbleboard.com',
    'bumbleboard.com',
];

const [ EUkeys, educationKeys, serviceKeys ] = [
    [
        'gdpr', 'eea', 'dpa', 'eu'
    ], [
        [ 'educational entity', 'educational entities' ],
        [ 'educator', 'educators' ],
        [ 'learner', 'learners' ],
        [ 'elibigle learner', 'eligible learners' ],
        [ 'legal guardian', 'legal guardians' ],
        'personal learner information',
        // 'US',
        'ferpa',
        'coppa',
        [ 'education record', 'education records' ],
        [ 'education account', 'education accounts' ],
    ], [
        [ 'you', 'your', 'user', 'users' ],
        [ 'visitor', 'visitors' ],
        'service',
        'personal user information',
        [ 'hive', 'hives' ],
        [ 'bumbleboard', 'bumbleboards' ],
        [ 'worker bee', 'worker bees' ],
        [ 'queen bee', 'queen bees' ],
        [ 'alias', 'aliases' ],
        [ 'we', 'us', 'our' ],
    ]
];

const [ acronyms, pronouns ] = [
    [ 'ferpa', 'coppa', 'US', ...EUkeys ],
    [ ...serviceKeys[ 0 ].slice( 0, 2 ), ...serviceKeys[ serviceKeys.length - 1 ] ]
];

// const glossaryKeys = [ ...serviceKeys, ...educationKeys ];

const pages = {
    landing: getLink( 'Landing', '/' ),
    login: getLink( 'Sign In', '/login' ),
    terms: getLink( 'Terms of Service', '/terms' ),
    privacy: {
        link: getLink( 'Privacy Policy', '/privacy' ),
        IDlink: getLink( 'Privacy Policy', '#intro' ),
    },
    schoolPrivacy: {
        link: getLink( 'Educational Use Policy', '/privacy#education' ),
        IDlink: getLink( 'Educational Use Policy', '#education' ),
    },
    schoolTerms: {
        link: getLink( 'Educational Terms of Service', '/terms#education' ),
        IDlink: getLink( 'Educational Terms of Service', '#education' ),
    },
};

let definitions = {
    user: 'a registered account holder',
    users: 'registered account holders',

    'educational entity': 'a school, university, education service/agency/cooperative, and/or school district',
    'educational entities': 'schools, universities, education services/agencies/cooperatives, and/or school districts',

    educator: 'a person involved in planning, implementing, and directing education (e.g. a Teacher, Professor, Instructional Coach/Assistant, Tutor, Department Chair, Administrator, etc.)',
    educators: 'persons involved in planning, implementing, and directing education (e.g. Teachers, Professors, Instructional Coaches/Assistants, Tutors, Department Chairs, Administrators, etc.)',

    US: 'United States of America',
    ferpa: 'Family Educational Rights and Privacy Act',
    coppa: 'Children\'s Online Privacy Protection Rule',

    gdpr: 'General Data Protection Regulation, Europe\'s new data privacy and security law',
    eea: 'European Economic Area',
    dpa: 'Data Protection Authority, an independent public authority that supervises, through investigative and corrective powers, the application of the data protection law',
    eu: 'European Union',

    alias: 'a username',
    aliases: 'usernames',

    'identifiable natural person': 'a person who can be directly or indirectly identified by reference to an identifier such as a name, an identification number, location data, an online identifier etc.',
};
definitions.we = definitions.us = definitions.our = company;
definitions.you = definitions.your = definitions.user;

definitions = {
    ...definitions,
    'education record': <>
        a document that directly relates to a student, and is maintained by an { getDefinedTerm( 'Educational Entity', definitions[ 'educational entity' ] ) } or by a person acting for such an entity [See { getLink( 'FERPA', 'https://www2.ed.gov/policy/gen/guid/fpco/ferpa/index.html' ) }]
    </>,

    you: <>a { getDefinedTerm( 'User', definitions.user ) }</>,
    your: <>a { getDefinedTerm( 'User', definitions.user ) }</>,

    visitor: <>
        a party without an account who is invited to view a choiceboard or progress report by a { getDefinedTerm( 'User', definitions.user ) }
    </>,
    visitors: <>
        parties without an account who are invited to view a choiceboard or progress report by a { getDefinedTerm( 'User', definitions.user ) }
    </>,

    'eligible learner': <>a student who is a legal adult (18 years old in the United States) or is attending a postsecondary institution at any age</>,
    'eligible learners': <>students who are legal adults (18 years old in the United States) or are attending a postsecondary institution at any age</>,

    service: <>any platform operated by { company } through which We serve Our { getDefinedTerm( 'Users', definitions.users ) }</>,
    website: <>{ URL }, where { company } serves its { getDefinedTerm( 'Users', definitions.users ) }</>,

    'education account': <>
        an account that is associated with an { getDefinedTerm( 'Educational Entity', definitions[ 'educational entity' ] ) }
    </>,
    'education accounts': <>
        accounts that are associated with an { getDefinedTerm( 'Educational Entity', definitions[ 'educational entity' ] ) }
    </>,

    'queen bee': <>
        a { getDefinedTerm( 'User', definitions.users ) } in a { getDefinedTerm( 'Hive', definitions.hive ) } with permission to assign { getDefinedTerm( 'BumbleBoards', definitions.bumbleboards ) } and grades
    </>,
    'queen bees': <>
        all { getDefinedTerm( 'Users', definitions.users ) } in a { getDefinedTerm( 'Hive', definitions.hive ) } with permission to assign { getDefinedTerm( 'BumbleBoards', definitions.bumbleboards ) } and grades
    </>,
    'worker bee': <>
        a { getDefinedTerm( 'User', definitions.user ) } in a { getDefinedTerm( 'Hive', definitions.hive ) } with permission to complete { getDefinedTerm( 'BumbleBoards', definitions.bumbleboards ) } and submit assignments
    </>,
    'worker bees': <>
        all { getDefinedTerm( 'Users', definitions.users ) } in a { getDefinedTerm( 'Hive', definitions.users ) } with permission to complete { getDefinedTerm( 'BumbleBoards', definitions.bumbleboards ) } and submit assignments
    </>,
};

definitions[ 'personal user information' ] = <>
    any information relating to a { getDefinedTerm( 'User', definitions.user ) } of the { getDefinedTerm( 'Service', definitions.service ) } that is not publicly available
    {/* any information relating to an { getDefinedTerm( 'Identifiable Natural Person', definitions[ 'identifiable natural person' ] ) } */}
</>

definitions.learner = <>a student user of an { getDefinedTerm( 'Educational Entity', definitions[ 'educational entity' ] ) } or of an {  getDefinedTerm( 'Educator', definitions.educator )}'s { getDefinedTerm( '', definitions.hive ) }</>;
definitions.learners = <>student users of an { getDefinedTerm( 'Educational Entity', definitions[ 'educational entity' ] ) } or of an {  getDefinedTerm( 'Educator', definitions.educator )}'s { getDefinedTerm( '', definitions.hive ) }</>;

definitions[ 'personal learner information' ] = <>
    a { getDefinedTerm( 'Learner', definitions.learner ) }'s' "education records" as defined by { getDefinedTerm( <dfn><abbr title={ definitions.ferpa }>FERPA</abbr></dfn>, definitions.ferpa ) } or other information protected by similar state student data privacy laws
</>;

definitions[ 'education record' ] = <>
    information and material created by or directly related to a { getDefinedTerm( 'Learner', definitions.learner ) } that is maintained by an { getDefinedTerm( 'Educational Entity', definitions[ 'educational entity' ] ) } or { getDefinedTerm( 'Educator', definitions.educator ) } acting for such an Entity [See { getLink( 'FERPA', 'https://www2.ed.gov/policy/gen/guid/fpco/ferpa/index.html' ) }]
</>;
definitions[ 'education records' ] = <>
    information and material created by or directly related to a { getDefinedTerm( 'Learner', definitions.learner ) } that are maintained by an { getDefinedTerm( 'Educational Entity', definitions[ 'educational entity' ] ) } or { getDefinedTerm( 'Educator', definitions.educator ) } acting for such an Entity [See { getLink( 'FERPA', 'https://www2.ed.gov/policy/gen/guid/fpco/ferpa/index.html' ) }]
</>;

definitions[ 'legal guardian' ] = <>A parent or legally appointed custodian of a { getDefinedTerm( 'Learner', definitions.learner ) } under the age of 18, with the legal authority to make decisions for said { getDefinedTerm( 'Learner', definitions.learner ) }</>;
definitions[ 'legal guardians' ] = <>Parents or legally appointed custodians of a { getDefinedTerm( 'Learner', definitions.learner ) } under the age of 18, with the legal authority to make decisions for said { getDefinedTerm( 'Learner', definitions.learner ) }</>;

definitions.hive = <>a course/class on the { getDefinedTerm( 'Website', definitions.website ) }</>
definitions.hives = <>courses/classes on the { getDefinedTerm( 'Website', definitions.website ) }</>

definitions.bumbleboard = <>a choiceboard as designed and presented on the { getDefinedTerm( 'Website', definitions.website ) }</>;
definitions.bumbleboards = <>choiceboards as designed and presented on the { getDefinedTerm( 'Website', definitions.website ) }</>;

// FUNCTIONS
function combineKeys( keysArr=[] ) {
    if ( Array.isArray( keysArr ) ) {
        const keys = keysArr.map( key => `"${ pronouns.includes( key ) ? key : get.capitalise( key ) }"`);
        const lastKey = 'and ' + keys.pop();

        return [ ...keys, lastKey ].join( keys.length > 1 ? ', ' : ' ' )
    } else { return keysArr }
}

function fillGlossary( glossary={}, keys=[], iOffset=0 ) {
    keys.forEach( ( key, i ) => glossary[ getGlossaryKey( key ) ] = {
        i: i + 1 + iOffset,
        id: getGlossaryID( key ),
        definition: getGlossaryDefinition( key )
    } )
}

function getDefinedTerm( term='', definition='', i=-1, definitionID='', hideStyle=false ) {
    const props = { definition, i, term, definitionID, hideStyle }; // className: styles[ 'defined-term' ]

    return <DefinedTerm { ...props }/>
}

function getGlossary( pageIs={
    EUcompliant: false,
    terms: false,
    privacy: false,
    education: false,
}, iOffset=0 ) {
    const [ glossary, notJustEUterms ] = [ {}, Object.values( pageIs ).slice( 1 ).some( e => e ) ];

    if ( notJustEUterms ) {
        const pageName = Object.keys( pageIs ).slice( 1 ).find( key => pageIs[ key ] );

        switch ( pageName ) {
            case 'education': fillGlossary( glossary, [ ...educationKeys, ...( pageIs.EUcompliant ? EUkeys : [] ) ], iOffset ); break;
            case 'privacy': fillGlossary( glossary, [ ...serviceKeys, ...( pageIs.EUcompliant ? EUkeys : [] ) ] ); break;
            case 'terms': fillGlossary( glossary, [ ...[], ...( pageIs.EUcompliant ? EUkeys : [] ) ] ); break;

            default: break;
        }
    } else { fillGlossary( glossary, EUkeys, iOffset ) }

    return glossary
}

function getGlossaryComponent( glossary={}, cssModule, id='', glossaryPurpose='policy' ) {
    const keys = Object.keys( glossary );

    return <section className={ cssModule[ 'glossary' ] } id={ id }>
        <header>
            <h2>Defining our terms</h2>
            <p>For the purpose of this { get.capitalise( glossaryPurpose ) },</p>
        </header>

        <ol className={ cssModule[ 'definitions' ] } start={ glossary[ keys[ 0 ] ].i }>{
            keys.map( term => <li
                className={ cssModule[ 'entry' ] }
                key={`glossary-entry-${ glossary[ term ].i }`}
            >
                <span className={ cssModule[ 'term' ] } id={ glossary[ term ].id }>{ term }</span>&nbsp;
                refer{ term.includes( '"' ) ? '' : 's' } to { glossary[ term ].definition }
            </li> )
        }</ol>
    </section>
}

function getGlossaryDefinition( key='' ) {
    return definitions[ Array.isArray( key ) ? key[ key.length - 1 ] : key ]
}

function getGlossaryID( key='' ) {
    if ( typeof key === 'string' ) key = key.toLocaleLowerCase().split(' ')

    return key.join( '-' )
}

function getGlossaryKey( key='' ) {
    return Array.isArray( key ) ? combineKeys( key )
        : acronyms.includes( key ) ? key.toLocaleUpperCase()
        : get.capitalise( key )
}

function getLink ( text='', href='' ) { return <a rel='noopener noreferrer' href={ href }>{ text }</a> }

function getTermFor( term='', glossary={} ) {
    let termKey = term === 'US' ? term
        : !term.includes( '(' ) ? term.toLocaleLowerCase()
        : term.split( ')' ).join( '' ).split( '(' ).join( '' ).toLocaleLowerCase();
    const [ glossaryKey, isAcronym, isPronoun ] = [
        Object.keys( glossary ).find( key => key.toLocaleLowerCase().includes( termKey ) ) || 'NOT FOUND',
        acronyms.includes( termKey ),
        pronouns.includes( termKey )
    ];

    if (
        termKey.includes( 'personal' )
        && termKey.includes( 'information' )
    ) termKey = termKey.includes( 'learner' ) ? 'personal learner information' : 'personal user information'

    const definition = definitions[ termKey ];
    if ( !definition ) return 'UNDEFINED TERM'

    const { i, id } = term in glossary ? glossary[ term ]
        : glossaryKey in glossary ? glossary[ glossaryKey ]
        : definitions[ termKey ];

    return getDefinedTerm(
        isAcronym ? <abbr title={ definition }>{ term }</abbr> : term,
        definition,
        isPronoun ? -1 : i,
        id,
        isPronoun
    )
}


// EXPORT
const glossaryExports = {
    address,
    company,
    email,
    EUrep,
    pages,
    // URL,
    getGlossary,
    getGlossaryComponent,
    getLink,
    getTermFor,
};

export default glossaryExports;