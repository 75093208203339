import { useContext, useEffect, useState } from 'react';
// import { useNavigate } from 'react-router-dom';
import { collection, getDocs } from 'firebase/firestore';
import { db } from '../../../../firebase_setup/firebase';
// import InfoButton from '../../../modular/Buttons/InfoButton';
import TextInput from '../../../modular/Form/Parts/TextInput';
// import UserContext from '../../../../contexts/UserContext';
import SelectInput from '../../../modular/Form/Parts/SelectInput';
import get from '../../../../constants/get';
// import firePush from '../../../../services/edding/pushFireData';
// import TokenInput from '../../../modular/Form/Parts/TokenInput';
import fireRead from '../../../../services/edding/readFireData';
import styles from './MrsBates.module.css';
import Stepper from '../../../modular/Form/Stepper/Stepper';
import productInfo from '../../../../constants/productInfo';
// import PricingMiniCard from '../../Pricing/PricingMiniCard';
// import RadioGroup from '../../../modular/Form/Parts/RadioGroup';
import Plans from './Plans';
// import courseInfo from '../../../../constants/courseInfo';
import AccountContext from '../../../../contexts/AccountContext';

const [ bumbleRoles, departments, grades, relationships, subjects, titles ] = [
    [
        'student',
        'teacher',
        'parent',
        'guardian',
        'admin'
    ], [
        'World Languages',
        'Maths',
        'Sciences',
        'Health & PE',
        'Fine & Performing Arts',
        'Social Studies',
        'Special Education',
        'ESOL'
    ], Array( 11 ).fill().map( ( e, i ) => i < 12 ?
        get.ordinalNum( i + 6 ) : i === 8 ?
        'freshman' : i === 9 ?
        'sophomore' : i === 10 ?
        'junior' : 'senior'
    ), [
        'mother',
        'father',
        'parent',
        'guardian',
    ], [
        'French',
        'Spanish',
        'Japanese',
        'Mandarin',
        'Vietnamese',
        'Latin',
        'ASL',
        'German'
    ], [
        'Dr.',
        'Prof.',
        'Mrs.',
        'Mr.',
        'Ms.',
        'Mx.',
        'Sr',
        'Sra',
        // 'Sig.',
        // 'Sig.ra',
        'Sensei',
        'Laoshi',
        'M.',
        'Mme',
        'Frau',
        'Herr',
    ]
];

const litePlanMonthlyUrl = process.env.REACT_APP_STRIPE_LITE_MONTHLY
const litePlanYearlyUrl = process.env.REACT_APP_STRIPE_LITE_YEARLY
const standardPlanMonthlyUrl = process.env.REACT_APP_STRIPE_STANDARD_MONTHLY
const standardPlanYearlyUrl = process.env.REACT_APP_STRIPE_STANDARD_YEARLY
const completePlanMonthlyUrl = process.env.REACT_APP_STRIPE_COMPLETE_MONTHLY
const completePlanYearlyUrl = process.env.REACT_APP_STRIPE_COMPLETE_YEARLY

const BuildProfile = ({ schoolYearID='2022 - 2023', updateUser=() => {}, user={} }) => {
    // const navigate = useNavigate();
    // const { state: userAuthObj } = useLocation();
    const { handleUserChange } = useContext( AccountContext );
    // const { id, idType } = useParams();
    // const user = useContext( UserContext );
    window.location.hostname === 'localhost' && console.log( '🟥', user );
    document.title = 'New User Info | BumbleBoard';

    let county = get.capitalise( user.countyIDs[ 0 ].split( '-' ).slice( 1 ) );
    let state = user.countyIDs[ 0 ].split( '-' ).shift().toLocaleUpperCase();
    if ( state === 'US' ) state = null
    window.location.hostname === 'localhost' && console.log( state, county );

    const [ locale, setLocale ] = useState({
        list: { states: get.USStates( true ), counties: [], schools: [] },
        // info: { county: {}, school: {}, course: idType !== 'course' ?
        //     {} : ( user?.courses?.[ 0 ] || fireRead.findCourseByID( id ) )
        // },
        state: user?.counties?.[ 0 ]?.state || state,
        county: user?.counties?.[ 0 ] || null,
        school: Object.values( user?.counties?.[ 0 ]?.schools || {} )[ 0 ] || '',
        user: {
            token: get.randomToken( true ),
            first: user.name.first,
            last: user.name.last,
            nickname: user.name.preferred
        }
    });

    const [ role, setRole ] = useState( user.role.isStudent || user.data.isGuest ? { isStudent: true }
        : user.role.isOther ? { isOther: '' }
        : user.role.isParent || user.role.isGuardian ? { isParent: true }
        : user.role.isAdmin ? { isAdmin: true } : { isTeacher: true },
    );
    window.location.hostname === 'localhost' && console.log( '🟧', role, locale );

    // get course or user info from db depending on the id in url
    // useEffect( () => {
    //     if ( !locale.db ) {
    //         console.log( `💡 Fetching ${ idType } data from database`);
    //         idType === 'course' ? fireRead.findCourseByID( id, [ 'students' ], schoolYearID ).then(
    //             courseData => setLocale( locale => ({ ...locale, db: [ courseData ] }) )
    //         ) : fireRead.allData( user.data ).then( userData => setLocale( locale => ({ ...locale, db: { ...userData } })) );
    //     }

    // }, [ idType, id, schoolYearID, user.data, locale.db ] );

    // Find counties by state
    useEffect( () => {
        window.location.hostname === 'localhost' && console.log( 'getting counties...' );
        // const isInEducation = ( role.isAdmin || role.isParent || role.isStudent || role.isTeacher );
        // if ( isInEducation && !!locale.state ) {
        if ( locale.state ) {
            window.location.hostname === 'localhost' && console.log( '💡 pulling state counties' );
            fireRead.getCountiesByState( locale.state ).then( counties => setLocale(
                locale => ({ ...locale, list: { ...locale.list, counties } })
            ) );
        }
    }, [ locale.state ] );

    // Find schools by county
    useEffect( () => {
        window.location.hostname === 'localhost' && console.log( 'getting schools...' );
        // const isInEducation = !role.isOther;
        // if ( isInEducation && !!locale.county?.id ) {
        if ( locale.county?.id ) {
            window.location.hostname === 'localhost' && console.log( '💡 pulling county schools' );

            Promise.all([
                getDocs( collection( db, 'counties', locale?.county?.id, 'schools' ) )
            ]).then( arr => {
                const schools = [];
                arr[ 0 ].forEach( school => schools.push( school.data() ) );
                schools.sort( ( a, b ) => a.toLocaleCompare( b ) );

                setLocale( locale => ({ ...locale, list: { ...locale.list, schools } }) )
            } );
        }
    }, [ locale.county ] );

    // FUNCTIONS
    const updateRole = ({ target: { innerText } }) => setRole( () => {
        innerText = innerText.trim().toLocaleLowerCase();
        window.location.hostname === 'localhost' && console.log( innerText );

        return innerText.includes( 'create' ) ? { isOther: innerText.split( 'create: ' )[ 1 ] }
            : [ bumbleRoles[ 0 ] ].includes( innerText ) ? { isStudent: true }
            : [ bumbleRoles[ 1 ] ].includes( innerText ) ? { isTeacher: true }
            : [ bumbleRoles[ 4 ] ].includes( innerText ) ? { isAdmin: true } : { isParent: true }
    } )

    const updateLocale = async ({ target: { id, value } }) => setLocale( locale => {
        const splitID = id?.split( '-' );
        const [ isName, isToken ] = [ id.includes( 'name' ), id.includes( 'token' ) ];

        let [ key, i ] = [ splitID[ 1 ], splitID.pop() ];
        if ( key === 'display' ) key = 'nickname'

        const listKey = key === 'county' ? 'counties'
            : isName || isToken ? key : key + 's';

        let listValue = isName ? get.capitalise( value.split(' ') )
            : isToken ? get.token( value ) : locale.list[ listKey ][ i ];

        if ( key === 'state' ) {
            listValue = listValue.toLocaleLowerCase()
            locale.county = locale.school = '';
        }
        // console.log( 'ℹ️', id, '\n', key, '\n', listKey, '\n', i, '\n', listValue );

        return isName || isToken ? { ...locale, user: { ...locale.user, [ key ]: listValue } } : { ...locale, [ key ]: listValue }
    } )

    const submitAndRedirect = async ( arr=[] ) => {
        // e.preventDefault();
        const [ bio, locale, plan ] = [ arr[ 0 ], arr[ 1 ], arr[ 2 ] ];
        const [ firstName, lastName, position ] = [ bio[ 0 ], bio[ 1 ], bio.pop() ];

        const [ bioObj, positionObj ] = [ {
            firstName, lastName, email: user.data.email
        }, { position } ];

        switch ( position ) {
            case 'student':
                positionObj.grade = bio[ 3 ];
                bioObj.preferredName = bio[ 2 ];
                break;

            case 'teacher':
                positionObj.subjects = bio[ 0 ];
                bioObj.preferredName = bio[ 3 ];
                bioObj.title = bio[ 2 ];
                positionObj.departments = [ 'World Languages' ];
                break;

            case 'admin':
                bioObj.title = bio[ 2 ];
                positionObj.departments = bio[ 3 ];
                break;

            default:
                bioObj.title = bio[ 2 ];
                positionObj.relationship = bio[ 3 ];
                break;
        }

        const [ billingI, planI ] = [
            plan[ 1 ].split( '-' ).pop() * 1,
            plan[ 0 ].split( '-' ).pop() * 1,
        ];

        function getPlanType() {
            switch ( planI ) {
                case 1: return 'isBasic';
                case 2: return 'isStandard';
                case 3: return 'isComplete';

                default: return 'isFree';
            }
        }

        user.name.first = firstName;
        user.name.last = lastName;
        user.name.title = !role.isStudent && !role.isOther && bio[ 2 ];
        user.position = position;
        user.role = { [ Object.keys( role )[ 0 ] ]: true };

        if (
            role.isTeacher
            || role.isParent
            || role.isStudent
        ) user.name.preferred = ( role.isTeacher || role.isParent ) ? bio[ 3 ] : bio[ 2 ]

        user.plan = {
            [ getPlanType() ]: true,
            billing: productInfo.billing.frequency[ billingI === 0 ? 'monthly' : 'yearly' ].split(' ')[ 0 ],
            i: planI
        };

        user.schoolIDs = locale.slice( 2 ).map(
            school => school.split(' ').join( '-' ).toLocaleLowerCase()
        );

        user.countyIDs = [ [
            locale[ 0 ],
            locale[ 1 ].split(' ').join( '-' )
        ].join( '-' ).toLocaleLowerCase() ];

        if ( role.isAdmin ) user.departments = bio.pop();
        if ( role.isTeacher ) user.subjects = bio.pop();
        if ( role.isParent ) user.relationship = bio.pop();
        if ( role.isStudent ) user.grade = bio.pop();

        window.location.hostname === 'localhost' && console.log( '👵', locale, '\n', user );
        handleUserChange( user );

        // if ( !user.plan.isFree ) window.location.href = 'https://www.stripe.com'
        if ( user.plan.billing.includes('yearly') ) {
            if ( user.plan.isLite ) {
                window.location.hostname === 'localhost' && console.log( '🟧 LITE plan, yearly', litePlanYearlyUrl );
                window.location.href = litePlanYearlyUrl;

            } else if ( user.plan.isStandard ) {
                window.location.hostname === 'localhost' && console.log( '🟧 STANDARD plan, yearly', standardPlanYearlyUrl );
                window.location.href = standardPlanYearlyUrl;

            } else if ( user.plan.isComplete ) {
                window.location.hostname === 'localhost' && console.log( '🟧 COMPLETE plan, yearly', completePlanYearlyUrl );
                window.location.href = completePlanYearlyUrl;
            }
          } else if ( user.plan.billing.includes('monthly') ) {
            if ( user.plan.isLite ) {
                window.location.hostname === 'localhost' && console.log( '🟧 LITE plan, yearly', litePlanMonthlyUrl );
                window.location.href = litePlanMonthlyUrl;

            } else if ( user.plan.isStandard ) {
                window.location.hostname === 'localhost' && console.log( '🟧 STANDARD plan, yearly', standardPlanMonthlyUrl );
                window.location.href = standardPlanMonthlyUrl;

            } else if ( user.plan.isComplete ) {
                window.location.hostname === 'localhost' && console.log( '🟧 COMPLETE plan, yearly', completePlanMonthlyUrl );
                window.location.href = completePlanMonthlyUrl;
            }
          }
        // if ( user.plan.isFree ) navigate( `/${ get.username( user.data.email ) }` )
    }

    // PROPS & ATTRIBUTES
    const props = {
        InfoButton: {
            realName: { pElement: <p>What the teacher sees</p> },
            displayName: { pElement: <p>What other students see</p> },
            common: { arrowDirection: 'left', hasArrow: true },
        },
        SelectInput: {
            school: {
                label: 'school',
                options: locale.list.schools.map( school => school?.name?.full ),
                colours: locale.list.schools.map( school => school?.colours?.[ 0 ] || 'black' ),
                canSelectMultiple: true,
            },
            county: {
                label: 'county',
                options: locale.list.counties.map( county => county?.name ) ,
                startingValue: locale.county?.name?.full,
            },
            state: {
                label: 'state',
                options: locale.list.states,
                canCreateOption: false,
            },
            role: {
                label: 'role',
                className: get.classNameFromArr([ styles.role, styles.select ]),
                options: bumbleRoles,
                passValueUp: updateRole,
                initialTags: role.isStudent ? ['student']
                    : role.isTeacher ? ['teacher']
                    : role.isParent ? ['parent']
                    : role.isAdmin ? ['admin'] : null,
                // labelPositionOn: '',
            },
            subject: {
                label: 'subject',
                className: get.classNameFromArr([ styles.subject, styles.select ]),
                options: subjects,
                // initialTags: [subjects[ 0 ]],
                canSelectMultiple: true,
            },
            department: {
                label: 'department',
                className: get.classNameFromArr([ styles.department, styles.select ]),
                options: departments,
                initialTags: [departments[ 0 ]],
                canSelectMultiple: true,
            },
            title: {
                label: 'title',
                className: get.classNameFromArr([ styles.title, styles.select ]),
                options: titles,
                initialTags: [ user.name.title ] || null,
            },
            relationship: {
                label: 'relationship',
                className: get.classNameFromArr([ styles.relationship, styles.select ]),
                options: relationships,
                initialTags: [relationships[ 0 ]],
            },
            grade: {
                label: 'grade',
                className: get.classNameFromArr([ styles.grade, styles.select ]),
                options: grades,
                initialTags: [grades[ 4 ]],
                canTypeAnswer: false,
                canCreateOption: false,
            },
            common: {
                className: styles.select,
                labelPositionOn: 'top',
                canSelectMultiple: false,
                canCreateOption: true,
                canReset: true,
                canTypeAnswer: true,
                passTargetUp: true,
                isVertical: true,
            },
        },
        TextInput: {
            common: { className: styles.input, transformLabel: 'to-top' },
            first: { label: 'First Name', value: locale.user.first },
            last: { label: 'Last Name', value: locale.user.last },
            preference: { label: `Preferred ${ role.isTeacher ? 'Last' : 'First' } Name` },
        },
    };

    if ( locale.state ) props.SelectInput.state.initialTags = [ locale.state ];
    if ( locale.school?.id ) props.SelectInput.school.initialTags = [ locale.school.name.full ];

    props.SelectInput.state.passValueUp = props.SelectInput.county.passValueUp = props.SelectInput.school.passValueUp = props.TextInput.first.passValueUp = props.TextInput.last.passValueUp = updateLocale;

    props.SelectInput.role.id = props.SelectInput.role.name = 'user-roles';
    props.SelectInput.state.id = props.SelectInput.state.name = 'user-state';
    props.SelectInput.county.id = props.SelectInput.county.name = 'user-county';
    props.SelectInput.school.id = props.SelectInput.school.name = 'user-school';
    props.SelectInput.grade.id = props.SelectInput.grade.name = 'user-grade';
    props.SelectInput.subject.id = props.SelectInput.subject.name = 'user-subject';
    props.SelectInput.department.id = props.SelectInput.department.name = 'user-department';
    props.SelectInput.title.id = props.SelectInput.title.name = 'user-title';

    props.TextInput.last.id = props.TextInput.last.name = 'user-last-name';
    props.TextInput.first.id = props.TextInput.first.name = 'user-first-name';
    props.TextInput.common.passTargetUp = props.TextInput.common.isRequired = true;
    props.TextInput.preference.id = props.TextInput.preference.name = 'user-preferred-name';

    // COMPONENTS
    // const Token = <TokenInput tokenParamsArr={ locale.user.token }/>
    const [ TextInputPreference, TextInputFirst, TextInputLast ] = [
        <TextInput { ...props.TextInput.common } { ...props.TextInput.preference }/>,
        <TextInput { ...props.TextInput.common } { ...props.TextInput.first }/>,
        <TextInput { ...props.TextInput.common } { ...props.TextInput.last }/>,
    ];

    // const SubmitButton = <button type='submit'>
    //     <span className={ styles.line }/> SUBMIT <span className={ styles.line }/>
    // </button>;

    const Locale = <fieldset className={ styles.locale }>
        <fieldset className={ styles.container }>
            <fieldset className={ styles.inputs }>
                <SelectInput { ...props.SelectInput.common } { ...props.SelectInput.state }/>
                { !role.isOther && !!locale.state && <SelectInput { ...props.SelectInput.common } { ...props.SelectInput.county }/> }
            </fieldset>
            { !role.isOther && <fieldset className={ styles.county }>
                { !!locale.county?.id && <SelectInput { ...props.SelectInput.common } { ...props.SelectInput.school }/>}
            </fieldset> }
        </fieldset>
    </fieldset>;

    const Bio = <fieldset className={ styles.bio }>
        <fieldset className={ styles.name }> { TextInputFirst } { TextInputLast } </fieldset>
        <fieldset className={ styles[ 'display-name' ] }>
            { ( role.isAdmin || role.isParent || role.isTeacher )
                && <SelectInput { ...props.SelectInput.common } { ...props.SelectInput.title }/> }
            { ( role.isStudent || role.isTeacher ) && TextInputPreference }
        </fieldset>
        <fieldset className={ styles.job }>
            { role.isAdmin ? <SelectInput { ...props.SelectInput.common } { ...props.SelectInput.department }/>
            : role.isStudent ? <SelectInput { ...props.SelectInput.common } { ...props.SelectInput.grade }/>
            : role.isTeacher ?  <SelectInput { ...props.SelectInput.common } { ...props.SelectInput.subject }/>
            : <SelectInput { ...props.SelectInput.common } { ...props.SelectInput.relationship }/> }
            <SelectInput { ...props.SelectInput.common } { ...props.SelectInput.role }/>
        </fieldset>
    </fieldset>;

    // const TokenAndAlias = <fieldset className={ styles[ 'display-name' ] }>
    //     { Token }
    //     <fieldset className={ styles.inputs }>
    //         <h2>{ role.isOther ? 'And my name is...' : 'But call me...' } <InfoButton { ...props.InfoButton.displayName }/></h2>
    //         { role.isOther ? <>{ TextInputFirst } { TextInputLast }</> : TextInputAlias }
    //     </fieldset>
    // </fieldset>

    const stepChildren = [{
        data: [ Bio ],
        header: 'Bio',
    }, {
        data: [ Locale ],
        header: 'Locale',
    }, {
        data: [ <Plans activePlanIFromParent={ 2 }/> ],
        header: 'Plan',
    }];

    // RENDER
    return <div className={ styles.page }>
        <header>
            <h1>
                <i className={ styles.bee }>🐝</i>
                {/* <p>
                    I am { role.isAdmin || (
                        typeof role.isOther === 'string' && 'aeiou'.includes( role?.isOther?.[ 0 ] )
                    ) ? ' an' : ' a' } { Object.keys( role )[ 0 ].slice( 2 ) }
                </p> */}
            </h1>
        </header>

        <section className={ styles.stepper }>
            <Stepper
                stepChildren={ stepChildren }
                stepChildrenAreForms
                submitResults={ submitAndRedirect }
            />
        </section>
        {/* <svg xmlns="http://www.w3.org/2000/svg" width="56" height="51" className="gg-c-checkbox__shine">
            <g fillRule="evenodd">
                <rect width="8" height="2" x="48" y="24" rx="2" />
                <rect width="8" height="2" x="11.73" y="2.46" rx="2" transform="rotate(60 15.73 4.46)"/>
                <rect width="8" height="2" x="35.73" y="2.46" rx="2" transform="rotate(120 39.73 4.46)"/>
                <rect width="8" height="2" x="12" y="45" rx="2" transform="scale(1 -1) rotate(60 97.4 0)"/>
                <rect width="8" height="2" x="36" y="45" rx="2" transform="scale(-1 1) rotate(-60 0 116.28)"/>
                <rect width="8" height="2" y="24" rx="2" />
            </g>
        </svg> */}
    </div>
}

export default BuildProfile;