import moduleInfo from './moduleInfo';
import functions from './get';
import getSchoolYearObj from './yearInfo';
import firePush from '../services/edding/pushFireData';

const colors = [ '#540b0e', '#9e2a2b', '#e09f3e', '#fff3b0' ];
const levels = [ 1, 2, 3, 4, 5, 'ap' ];
const subjects = { languages: [ 'french', 'german', 'spanish', 'japanese', 'vietnamese' ] };

const languageUnits = {
    [ levels[0] ]: [
        'about me',
        'my family',
        'my school',
        'food',
        'clothes'
    ],
    [ levels[1] ]: [
        'my activities',
        'my home life',
        'my town/travels',
        'health & fitness',
        'story'
    ],
    [ levels[2] ]: [
        'my memories',
        'teen life',
        'my future',
        'our planet',
        'literature & media'
    ],
    [ levels[3] ]: [
        'heritage & culture',
        'tech & innovation',
        'social issues',
        'world health & environment',
        'consumerism'
    ],
    [ levels[4] ]: [
        'music & art',
        'self-image',
        'people in society',
        'current events',
        'student choice'
    ],
    [ levels[5] ]: [
        'global challenges',
        'science & technology',
        'contemporary life',
        'personal & public identities',
        'families & communities',
        'beauty & aesthetics'
    ],
};
Object.keys( languageUnits ).forEach( i => languageUnits[ i ].unshift('intro') );
const subjectUnits = create().subjects( subjects.languages );

// FUNCTIONS
function add() {
    return {
        boardAssignments( boardNum=0, dates=[ [ 1967, 0, 17 ] ], i=0, assignments={} ) {
            return i < 8 ? this.boardAssignments( boardNum, dates, i + 1, {
                ...assignments,
                [ i ] : {
                    boardNum,
                    date: {
                        due: Object.fromEntries( dates[ 1 ].map( ( e, i ) => [
                            [ 'year', 'month', 'day' ][ i ], e
                        ] ) ),
                        starting: Object.fromEntries( dates[ 0 ].map( ( e, i ) => [
                            [ 'year', 'month', 'day' ][ i ], e
                        ] ) ),
                    },
                    directions: '',
                    isChecked: false,
                    isGraded: i < 2,
                    isPrereq: i > 1,
                    links: [],
                    points: 1,
                    submissions: {
                        timely: 0,
                        late: 0,
                        missing: 0,
                    },
                    title: `${ i > 1 ? 'Light' : 'Dark' } Box ${ i > 1 ? i - 1 : i + 1 }`,
                    type: moduleInfo.assignmentTypes.graded.type,
                }
            } ) : assignments
        },
        boards( i=8-1, schoolYear={}, boardNum=-1, boards={} ) {
            let month = schoolYear.months[ i ];

            for ( let weekNum in month.weeks ) {
                const thisWeek = month.weeks[ weekNum ];

                const [ firstDay, lastDay ] = [ thisWeek.daysInClass[ 0 ], thisWeek.daysInClass.slice().pop() ];
                const firstWeekdayI = thisWeek.days.findIndex( day => day === firstDay );
                const lastWeekdayI = thisWeek.days.findIndex( day => day === lastDay );
                const [ firstDayMonth, lastDayMonth ] = [ thisWeek.months[ firstWeekdayI ], thisWeek.months[ lastWeekdayI ] ];

                const isHolidayWeek = thisWeek.daysInClass.length === 0;
                const isRepeatWeek = boardNum > 0 && boards[ boardNum ].weekNum === weekNum;

                if ( !isHolidayWeek && !isRepeatWeek ) {
                    boardNum++;
                    boards[ boardNum ] = {
                        assignments: this.boardAssignments( boardNum, [
                            [ schoolYear.months[ firstDayMonth ].year.full, firstDayMonth, firstDay ],
                            [ schoolYear.months[ lastDayMonth ].year.full, lastDayMonth, lastDay ],
                        ] ),
                        completion: { req: 0, prereq: 0 },
                        monthI: thisWeek.months[ 0 ],
                        weekNum: weekNum,
                        isPrivate: true,
                        isMergedWith: null,
                    };
                }
            }

            return month.nextI !== schoolYear.first.month ? this.boards(
                month.nextI, schoolYear, boardNum, boards
            ) : boards
        },
        unitDetails( unitsArr=[], schoolYear={} ) {
            return unitsArr?.units ? {
                units: get().unitDays( unitsArr.units, schoolYear )
            } : get().unitDays( unitsArr, schoolYear )
        },
    }
}

function get() {
    return {
        quarter( month=0, day=17, schoolYear={} ) {
            // console.log( month, day );
            // console.log( '===| WEEK OF:', schoolYear.months[ month ].name.full, day, '|===' );
            if ( month < schoolYear.first.month ) month += 12;

            for ( let quarter in schoolYear.quarters ) {
                const quarterEnd = schoolYear.quarters[ quarter ].end;
                const quarterEndMonth = quarterEnd.month < schoolYear.first.month ? quarterEnd.month + 12 : quarterEnd.month;
                // console.log( 'QUARTER END:', schoolYear.months[ quarterEnd.month ].name.full, quarterEnd.day );

                const isThisQuarter = month <= quarterEndMonth || ( quarterEndMonth === month && day <= quarterEnd.day );
                // isThisQuarter && console.log( `It is Q${ quarter }` );
                // console.log( month, quarterEndMonth, quarter, isThisQuarter );
                if ( isThisQuarter ) return quarter
            }
        },
        unitBlocks( unitI=0, totalDays=86, takenDays=0 ) {
            unitI *= 1;
            if ( unitI === 5 ) takenDays = 2 + ( 18 * 4 ) + 6

            return unitI === 0 ? 2
                : unitI === 5 ? totalDays - takenDays
                : 18
        },
        unitDays( unitsArr=[], schoolYear={} ) {
            if ( !unitsArr[ 0 ] ) return []
            window.location.hostname === 'localhost' && console.log( '🟧', unitsArr );
            window.location.hostname === 'localhost' && console.log( '🟨', schoolYear );

            const backgrounds = [ '#bfbfbf', '#e60073', '#e62600', '#e67300', '#ffaa00', '#ffd500' ];
            const { totalDays, start, months } = schoolYear;
            const totalClassSessions = Math.min( totalDays.even, totalDays.odd );

            let odd = {
                days: months[ start.month ].days.odd.slice(),
                monthI: start.month,
                suffix: 'odd',
                unitI: 0,
            };

            let even = {
                days: months[ start.month ].days.even.slice(),
                monthI: odd.monthI,
                suffix: 'even',
                unitI: odd.unitI,
            };

            // CLEANUP unitsArr[ unitI ].days
            if ( unitsArr[ 0 ].days ) unitsArr.forEach( unit => {
                for ( let key in unit.days.even ) {
                    delete unit.days.even[ key ];
                }
                for ( let key in unit.days.odd ) {
                    delete unit.days.odd[ key ];
                }
            } )

            // FUNCTIONS
            const getObj = ( isOdd=true ) => isOdd ? odd : even
            const getMonth = ( obj={} ) => months[ obj.monthI ]
            const getDays = ( obj={} ) => {
                const { days } = getMonth( obj );
                const specificDays = days[ obj.suffix ].slice();
                const hasSchoolHoliday = specificDays.some(
                    day => schoolYear.holidays[ obj.monthI ].days.includes( day )
                );
                // schoolYear.holidays[ obj.monthI ].days[ 0 ]

                return !hasSchoolHoliday ? specificDays : specificDays.filter(
                    day => !schoolYear.holidays[ obj.monthI ].days.includes( day )
                )
            }

            const incrementMonth = ( obj={} ) => {
                obj.monthI += obj.monthI + 1 === 12 ? -11 : 1;
                obj.days = getDays( obj );
            }

            const cycleDays = ( isOdd=true ) => {
                const obj = getObj( isOdd );
                const unit = unitsArr[ obj.unitI ];

                let blocks = 'blocks' in unit ? unit.blocks : this.unitBlocks( obj.unitI, totalClassSessions );

                unit.background = 'background' in unit ? unit.background : {
                    hex: backgrounds[ obj.unitI ], ...functions.hslFromHex( backgrounds[ obj.unitI ] )
                };
                unit.blocks = 'blocks' in unit ? unit.blocks : blocks;
                unit.days = 'days' in unit ? unit.days : { even: {}, odd: {} };

                while ( blocks > 0 ) {
                    const numDays = obj.days.length;

                    if ( numDays > blocks ) {
                        unit.days[ obj.suffix ][ obj.monthI ] = obj.days.splice( 0, blocks );
                        blocks = 0;
                    } else if ( numDays <= blocks ) {
                        unit.days[ obj.suffix ][ obj.monthI ] = obj.days.splice( 0, numDays );
                        blocks -= numDays;
                        incrementMonth( obj );
                    }
                }

                obj.unitI += 1;
                return unitsArr[ even.unitI ] ? cycleDays( !isOdd ) : unitsArr
            }

            // RETURN
            return cycleDays()
        },
    }
}

function create() {
    return {
        levels( levelsArr=levels, result={} ) {
            const level = levelsArr[ 0 ];

            return level ? this.levels(
                levelsArr.slice( 1 ),
                { ...result, [ level ]: {
                    units: this.units( level )
                } }
            ) : result;
        },
        subjects( languagesArr=[], result={} ) {
            const language = languagesArr[ 0 ];

            return language ? this.subjects(
                languagesArr.slice( 1 ),
                { ...result, [ language ]: this.levels() }
            ) : result
        },
        units( level='', result={} ) {
            const [ i, levelUnits ] = [
                Object.keys( result ).length,
                languageUnits[ level ]
            ];

            return i < levelUnits.length ? this.units( level, {
                ...result, [ i ]: { theme: levelUnits[ i ] }
            } ) : result
        },
    }
}

const getCourseObj = (
    { dashedID = '', dbID = '' },
    language = '',
    splitCourseName = '',
    ownerID = '',
    schoolID = '',
    sections = [],
    schoolYear = {},
    isCollege = false,
) => {
    const level = splitCourseName.length > 1 ? splitCourseName.pop() * 1 : NaN;
    const subject = splitCourseName.join(' ').trim();

    const obj = {
        subject, level, ownerID, language, schoolID,
        title: [ functions.capitalise( splitCourseName ), level ].join(' '),
        id: { dashed: dashedID, db: dbID },
        guests: [],
        content: {
            assignments: {},
            boards: courseInfo.fillMissingBoards( schoolYear ),
            syllabus: {},
            units: [],
            team: { county: [ ownerID ], school: [ ownerID ] },
            students: Object.fromEntries( sections.map( section => [ section, []  ] ) ),
        },
    }
    obj.createdOn = obj.lastEditOn = obj.lastOpenOn = new Date();

    // Check for AP / IB courses
    const [ isAP, isIB ] = [ splitCourseName.includes( 'ap' ), splitCourseName.includes( 'ib' ) ];

    if ( isAP || isIB ) {
        const [ isIBhl, isIBsl ] = [
            splitCourseName.includes( 'ib' ) && splitCourseName.includes( 'hl' ),
            splitCourseName.includes( 'ib' ) && splitCourseName.includes( 'sl' )
        ];

        const str = isAP ? 'ap' : isIBhl ? 'hl' : isIBsl ? 'sl' : isIB ? 'ib' : null;
        const [ subjectPrefix, subjectToCapitalise ] = [
            isIBhl || isIBsl ? 'IB' : '',
            isAP || isIB ? splitCourseName.filter( word => word !== str )
                : isIBhl || isIBsl ? splitCourseName.filter( word => ![ str, 'ib' ].includes( word ) )
                : splitCourseName,
        ];

        obj.subject = [ subjectPrefix, functions.capitalise( subjectToCapitalise ) ].join(' ').trim();
        obj.level = str.toLocaleUpperCase();
        obj.title = isAP || isIB ? [ obj.level, obj.subject ].join(' ') : [ obj.subject, obj.level ].join(' ');
        obj.id.dashed = obj.title.toLocaleLowerCase().split(' ').join('-');

    } else if ( typeof level === 'number' && isNaN( level ) ) { // check for courses that don't end in a number
        obj.subject = splitCourseName.join(' ');
        obj.title = functions.capitalise( splitCourseName );
        obj.id.dashed = splitCourseName.join( '-' );
        delete obj.level;
    }

    if ( !isCollege && sections[ 0 ] ) {
        obj.sections = { all: sections.sort( ( a, b ) => a - b ) };
        obj.sections.even = sections.filter( section => section % 2 === 0 );
        obj.sections.odd = sections.filter( section => section % 2 === 1 );
    }

    return obj
}

const getSchoolObj = ( obj={
    colours: [ 'maroon', 'gold' ],
    countyID: 'us-no-county',
    countyLocale: 'en-US',
    holidayMonths: [ 0, 1 ],
    holidayDays: [ [ 17 ], [ 9 ] ],
    holidayReasons: [ [ 'ML\'s Birthday' ], [ 'DL\'s Birthday' ] ],
    id: 'bumbleboard-academy',
} ) => {
    const { colours, countyID, countyLocale, holidayDays, holidayMonths, holidayReasons, id } = obj;
    // const timestamp = new Date();
    return {
        colours, countyID, id,
        holidays: holidayReasons[ 0 ][ 0 ] === 'ML\'s Birthday' ? {} : Object.fromEntries(
            holidayMonths.map( ( monthNum, i ) => [ monthNum, { days: holidayDays, reasons: holidayReasons } ] )
        ),
        locale: countyLocale,
        // createdOn: timestamp,
        // lastLoginOn: timestamp,
        name: {
        full: functions.capitalise( id.split( '-' ) ),
        abbr: id.toLocaleUpperCase().split( '-' ).map( word => word[ 0 ] ).join( '' )
    } }
}

const getCountyObj = ( fullName='-- N/A --', state='va', countryCode='US', countyLocale='' ) => {
    state = state.toLocaleLowerCase();
    countyLocale = countyLocale === '' ? navigator.language : countyLocale;

    const isDefaultName = fullName === '-- N/A --';
    const id = isDefaultName ? 'us-no-county' : [
        countryCode === 'US' ? state : countryCode,
        ...fullName.toLocaleLowerCase().split(' ')
    ].join( '-' );

    const data = {
        id, name: {},
        locale: countyLocale.includes( '-' ) ? countyLocale : [ countyLocale, countryCode ].join( '-' ),
    };

    data.name.abbr = data.name.full = fullName;
    if ( !isDefaultName ) data.name.abbr = fullName.toLocaleUpperCase().split(' ').map( word => word[ 0 ] ).join('')
    if ( countryCode === 'US' ) { data.state = state } else { data.country = countryCode }

    return data
}

const getPremiumObj = (
    adminEmail='',
    adminLastName='',
    adminTitle='',
    countyLocale=''
) => {
    const data = {
        language: countyLocale.split( '-' )[ 0 ],
        admin: { email: adminEmail, lastName: adminLastName, title: adminTitle },
        rules: {
            board: { prereq: 3, req: 2, size: 8 },
        },
    };
    data.rules.board.isLinear = data.rules.board.isWeekly = true;

    return data
}

const getSubmissionObj = ( email='', boardID='', isChecked=true ) => ({
    isChecked,
    who: email,
    what: boardID,
    when: new Date()
})

const getUserObj = (
    authData = {},
    countyLocale = 'en',
    { position = 'teacher', departments = [], grade = null },
    { adminEmail='', adminLastName='', adminTitle='Mx.' },
    { email='', firstName='', lastName='', preferredName='', alias='random-flower', title='Mx.' },
    { isAdmin=false, isParent=false, isStudent=false, isTeacher=false },
    { countyIDs=[ 'us-no-county' ], courseIDs={ queen: [], worker: [] }, schoolIDs=[ 'bumbleboard-academy' ], schoolYearID = '' },
    { isFree=false, isBasic=false, isStandard=false, isComplete=false, billing='yearly', details={} },
) => {
    const data = {
        position, schoolIDs, courseIDs, countyIDs, email, departments,
        createdOn: new Date( authData.metadata.createdAt * 1 ),
        lastLoginOn: new Date( authData.metadata.lastLoginAt * 1 ),
        locale: countyLocale,
        name: { first: firstName, last: lastName, preferred: preferredName, alias, title },
        photoURL: authData.photoURL,
        plan: { billing, details },
        role: {},
        schoolYearID: schoolYearID || functions.schoolYearID()
        // permissions: {},
    };

    if ( isAdmin ) data.role.isAdmin = true
    if ( isParent ) data.role.isParent = true
    if ( isStudent ) data.role.isStudent = true
    if ( isTeacher ) data.role.isTeacher = true

    isFree = isFree || ( !isBasic && !isStandard && !isComplete );
    if ( isFree ) {
        data.plan.isFree = true
    } else {
        data.premium = getPremiumObj( adminEmail, adminLastName, adminTitle, countyLocale );
        data.plan[
            isStandard ? 'isStandard'
                : isBasic ? 'isBasic'
                : 'isComplete'
        ] = true;
    }

    if ( isStudent ) { data.grade = grade } else if ( isTeacher || isAdmin ) { data.departments = departments }

    // data.permissions.admin = data.permissions.parent = data.permissions.student = data.permissions.teacher = [];

    return data
}

const getBoardObj = ( monthNum=0, weekNum=3, boardsLength=0 ) => ({
    monthNum, weekNum,
    completion: { prereq: 0, req: 0 },
    isMergedWith: null,
    isPrivate: true,
    num: boardsLength,
})

const getAssignmentObj = (
    assignmentNum=0,
    boardNum=0,
    isPrereq=true,
    category='assignment',
    points=1,
    dueDayMonthYear=[],
    postedDayMonthYear=[],
    editedDayMonthYear=[],
) => {
    const getDayMonthYear = ( forDueDate=false, dayMonthYear=[] ) => {
        let date = dayMonthYear[ 2 ] ? new Date( ...dayMonthYear.slice().reverse() ) : new Date();
        if ( forDueDate ) {
            const weekdayNum = date.getDay();
            const dayIncrement = weekdayNum < 6 ? weekdayNum - 5 : 6;

            date = new Date( date.getFullYear(), date.getMonth(), date.getDate() + dayIncrement );
        }

        return [ date.getDate(), date.getMonth(), date.getFullYear() ]
    }

    // console.log( '🟥', dueDayMonthYear, postedDayMonthYear, editedDayMonthYear );
    // dueDayMonthYear = dueDayMonthYear[ 2 ] ? getDayMonthYear( true, dueDayMonthYear ) : getDayMonthYear( true );
    // postedDayMonthYear = postedDayMonthYear[ 2 ] ? getDayMonthYear( false, postedDayMonthYear ) : getDayMonthYear();
    // editedDayMonthYear = editedDayMonthYear[ 2 ] ? getDayMonthYear( false, editedDayMonthYear ) : getDayMonthYear();
    // console.log( '🟩', dueDayMonthYear, postedDayMonthYear, editedDayMonthYear );

    const obj = {
        category, boardNum, isPrereq, points,
        directions: '',
        id: functions.assignmentID( boardNum, isPrereq, assignmentNum ),
        isGraded: !isPrereq,
        links: [],
        submissions: { timely: [], late: [], missing: [] },
        title: functions.assignmentTitle( boardNum, assignmentNum ),
        date: { due: {}, posted: {}, edited: {} },
    };

    obj.isChecked = obj.isFakeChecked = false;
    [ dueDayMonthYear, postedDayMonthYear, editedDayMonthYear ].forEach( ( arr, i ) => {
        const key = Object.keys( obj.date )[ i ];
        [ obj.date[ key ].day, obj.date[ key ].month, obj.date[ key ].year ] = getDayMonthYear( i === 0, arr ) ;
    } );

    return obj
}

const getPlaceholderBoards = ( schoolYearObj={}, boards=[] ) => {
    const { quarters, months, start } = schoolYearObj;
    let month = !boards[ 0 ] ? months[ start.month ]
        : months[ functions.lastArrayElement( boards ).monthNum ];

    const lastWeekNum = quarters[ quarters.length - 1 ].weeks.slice().pop();

    const firstMonthWeeks = month.weeks;
    let weekNum = boards[ 0 ] ? functions.lastArrayElement( boards ).weekNum
        : Object.entries( firstMonthWeeks ).find(
            weekNumAndObj => weekNumAndObj[ 1 ].days.includes( start.day )
        )[ 0 ] * 1;

    for ( weekNum; weekNum <= lastWeekNum; weekNum++ ) {
        if ( `${ weekNum }` in month.weeks === false ) month = months[ month.nextI ]

        if ( month.weeks[ weekNum ]?.daysInClass?.length > 0 ) boards.push(
            getBoardObj( month.i, weekNum, boards.length )
        )
    }

    return boards
}

const fillMissingBoards = ( schoolYear={}, boards=[] ) => {
    const latestWeekNum = functions.currentWeekNum( schoolYear.months );
    if ( boards.length === latestWeekNum + 1 ) return boards

    let monthNum = schoolYear.start.month;
    const firstMonthWeeks = schoolYear.months[ monthNum ].weeks;
    let minWeekNum = Object.entries( firstMonthWeeks ).find(
        weekNumAndObj => weekNumAndObj[ 1 ].days.includes( schoolYear.start.day )
    )[ 0 ] * 1;

    for ( minWeekNum; minWeekNum <= latestWeekNum; minWeekNum++ ) {
        let monthWeeks = schoolYear.months[ monthNum ].weeks;
        if ( `${ minWeekNum }` in monthWeeks === false ) {
            monthNum = schoolYear.months[ monthNum ].nextI;
            monthWeeks = schoolYear.months[ monthNum ].weeks;
        }

        if ( monthWeeks[ minWeekNum ].daysInClass.length > 0 ) boards.push(
            getBoardObj( monthNum, minWeekNum, boards.length )
        )
    }

    return boards
}

const addMissingBoard = ( startingWeekNum=0, maxWeekNum=0, course={}, schoolYear={} ) => {
    let topmostBoard = course.content.boards.find( board => board.weekNum === startingWeekNum )
        || functions.lastArrayElement( course.content.boards );

    let { monthNum } = topmostBoard;
    let monthWeeks = schoolYear.months[ monthNum ].weeks;

    if ( `${ startingWeekNum }` in monthWeeks === false ) {
        monthNum = schoolYear.months[ monthNum ].nextI;
        monthWeeks = schoolYear.months[ monthNum ].weeks;
    }

    if ( monthWeeks[ startingWeekNum ].daysInClass.length > 0 ) {
        course.content.boards.push( getBoardObj( monthNum, startingWeekNum, course.content.boards.length ) );
        firePush.updateBoardsArr( course.id.db, schoolYear.id, course.content.boards );
    }
}

function getUnitObj({
    background = { h: 0, s: '0%', l: '100%', hex: '#ffffff' },
    blocks = 0,
    boardNumsStart = 0,
    level = 0,
    numOfBoards = 0,
    schoolYear = {},
    startingMonthNum = -1,
    startingWeekNum = -1,
    subject = '',
    theme = '',
}) {
    // const months = 'if there\'s a unit already, pick up where it ended. Else, start with the first month of the year and cycle through days.all until no mas. Also index which weekNums apply to those days --SEE <ShopItem />';
    const { months } = schoolYear;

    if ( Array.isArray( level ) ) {
        level.forEach( ( num, i ) => level[ i ] *= 1 )
    } else if ( !level ) {
        level = 'all';
    } else if ( typeof level === 'string' && level !== 'any' ) {
        level *= 1;
    }

    if ( months && [ startingMonthNum, startingWeekNum ].some(
        num => typeof num !== 'number' || num < 0 )
    ) {
        let [ dayNum, monthNum, ] = functions.dayMonthYearToday();
        if ( startingMonthNum === schoolYear.start.month && dayNum < schoolYear.start.day ) dayNum = schoolYear.start.day

        if ( typeof startingMonthNum !== 'number' || startingMonthNum < 0 ) startingMonthNum = monthNum * 1
        if ( typeof startingWeekNum !== 'number' || startingWeekNum < 0 ) startingWeekNum = Object.keys( months[ startingMonthNum ].weeks ).find(
            weekNum => months[ startingMonthNum ].weeks[ weekNum ].days.includes( dayNum )
        ) * 1;
    }

    if ( !background ) {
        const { h, s, l } = functions.randomHSL( false );
        const hex = functions.hexFromHSL( h, s, l );
        background = { h, s, l, hex };
    } else if ( background.hex && !background.h ) {
        const { h, s, l } = functions.hslFromHex( background.hex );
        [ background.h, background.s, background.l ] = [ h, s, l ];
    } else if ( background.h && !background.hex ) {
        background.hex = functions.hexFromHSL( background.h, background.s, background.l );
    }

    const unit = {
        background, level, subject, theme,
        // assignments: {}, // add these to a unit only in the store
        duration: {},
        lang: 'en',
        months: [],
        notes: {},
        targets: [],
        skills: {},
        expressions: {},
        structures: {},
        vocabulary: {},
        questions: [],
    };

    if ( [ 'spanish', 'french', 'german', 'japanese' ].includes( subject ) ) unit.department = 'world-languages'

    if ( numOfBoards ) unit.duration.boards = numOfBoards
    if ( blocks ) {
        unit.blocks = unit.duration.classes = blocks
    } else if ( numOfBoards ) {
        unit.blocks = unit.duration.classes = blocks = numOfBoards % 2 === 0 ? (numOfBoards/2) * 5
            : ( (numOfBoards-1/2) * 5 ) + 2;
    }

    if ( !months ) return unit

    const boardNums = [];
    const boardWeekNums = [];
    const [ originalNumOfBoards, originalBlocks ] = [ numOfBoards, blocks ];
    let [ oddBlocks, evenBlocks, monthWeekNums ] = [
        blocks, blocks, Object.keys( months[ startingMonthNum ].weeks )
    ];

    while ( blocks > 0 || numOfBoards > 0 ) {
        let [ oddDays, evenDays, month ] = [ [], [], months[ startingMonthNum ] ];
        const unitMonthObj = {
            boardNums: [],
            i: startingMonthNum,
            weekNums: [],
        };
        // if ( month.i === schoolYear.months[ schoolYear.end.month ].nextI ) {
        //     blocks = numOfBoards = 0;
        // }
        [ month.days.odd, month.days.even ].forEach( ( arr, i ) => {
            const isEvenDay = !!i;
            let sliceFromI = 0;

            let [ blocksNum, currentWeekNum, isLiminalMonth ] = [
                isEvenDay ? evenBlocks : oddBlocks,
                monthWeekNums.find( weekNum => {
                    if ( weekNum * 1 < startingWeekNum ) return

                    let { days, months: weekMonths } = month.weeks[ weekNum ];
                    days = days.filter( ( day, j ) => weekMonths[ j ] === startingMonthNum );
                    if ( days.includes( arr[ 0 ] ) ) return true

                    sliceFromI = arr.findIndex( day => days.includes( day ) );
                    // console.log({ days, weekMonths, weekNum, firstDay: arr[ 0 ] });

                    return sliceFromI >= 0
                } ) * 1,
                startingMonthNum >= schoolYear.end.month && startingMonthNum <= schoolYear.start.month
            ];

            // console.log('🔶', { currentWeekNum, month, arr });
            if ( !currentWeekNum ) return

            let currentWeek = month.weeks[ currentWeekNum ];
            // console.log('🔶', { currentWeekNum, currentWeek, month, arr });

            if ( currentWeek.months[ 0 ] === startingMonthNum && currentWeek.months.includes( month.nextI ) ) {
                const validWeekDays = currentWeek.days.filter(
                    ( day, j ) => currentWeek.months[ j ] === startingMonthNum
                );
                sliceFromI = arr.findIndex( day => validWeekDays.includes( day ) );
            } else if ( !currentWeek.days.includes( arr[ 0 ] ) ) {
                sliceFromI = arr.findIndex( day => currentWeek.days.includes( day ) );
            }
            // if ( sliceToI ) arr = arr.slice( 0, sliceToI )
            // if ( sliceFromI > 0 ) arr = arr.slice( sliceFromI )

            blocksNum > 0 && arr.forEach( ( day, j ) => {
                if ( blocksNum < 1 || j < sliceFromI ) return

                const isHolidayAndDayType = schoolYear.holidays[ startingMonthNum ]?.days?.includes( day );
                if ( isHolidayAndDayType ) return
                if ( isLiminalMonth && (
                    ( startingMonthNum > schoolYear.end.month && startingMonthNum < schoolYear.end.month )
                        || ( startingMonthNum === schoolYear.start.month && day < schoolYear.start.day )
                        || ( startingMonthNum === schoolYear.end.month && day > schoolYear.end.day )
                ) ) return

                // if the day is not in a valid week, skip it
                if ( !currentWeekNum ) return
                if ( !currentWeek.days.includes( day ) ) {
                    currentWeekNum = monthWeekNums.find( week => ( week * 1 ) > currentWeekNum
                    && month.weeks[ week ].days.includes( day ) ) * 1;
                    currentWeek = month.weeks[ currentWeekNum ];
                    // console.log( '🟢', { currentWeekNum, currentWeek, day, weeks: month.weeks, monthWeekNums } );
                }

                // if the day is not a valid school day, skip it
                if ( !currentWeek.daysInClass.includes( day ) ) return
                let boardNum = functions.lastArrayElement( boardNums );
                const isRepeatWeek = boardWeekNums.includes( currentWeekNum );

                if ( !isRepeatWeek ) {
                    boardNum = typeof boardNum !== 'number' ? ( boardNumsStart || 0 ) : ( boardNum + 1 );
                    numOfBoards--;
                }

                if ( !boardNums.includes( boardNum ) ) {
                    boardWeekNums.push( currentWeekNum ) && unitMonthObj.weekNums.push( currentWeekNum );
                    boardNums.push( boardNum ) && unitMonthObj.boardNums.push( boardNum );
                }

                ( isEvenDay ? evenDays : oddDays ).push( day );
                isEvenDay ? evenBlocks-- : oddBlocks--;
                blocksNum--;
            } )
        } );

        if ( oddDays[ 0 ] || evenDays[ 0 ] ) {
            // if ( evenBlocks < 1 && oddBlocks < 1 ) blocks = 0
            blocks = Math.max( evenBlocks, oddBlocks );
            // because of days like PSAT testing, we have to create [ odd/evenDays ] and THEN pass those days to { unitMonthObj }
            unitMonthObj.days = { odd: oddDays.slice(), even: evenDays.slice() };

            unitMonthObj.days.all = [ ...oddDays, ...evenDays ].sort( ( a, b ) => a - b );
            if ( unitMonthObj.days.all[ 0 ] && typeof unitMonthObj.boardNums[ 0 ] !== 'number' ) [
                functions.lastArrayElement( boardNums ), functions.lastArrayElement( boardWeekNums )
            ].forEach( ( boardOrWeekNum, i ) => {
                const arr = unitMonthObj[ i === 0 ? 'boardNums' : 'weekNums' ];
                arr.includes( boardOrWeekNum ) || arr.push( boardOrWeekNum )
            })

            unit.months.push( unitMonthObj );
        }

        if ( blocks > 0 ) {
            startingMonthNum = month.nextI;
            monthWeekNums = Object.keys( months[ startingMonthNum ].weeks );
            startingWeekNum = monthWeekNums.find(
                weekNum => months[ startingMonthNum ].weeks[ weekNum ].days.includes(
                    months[ startingMonthNum ].days.all[ 0 ]
                )
            ) * 1;
        }

        // months[ month.nextI ].weeks[ latestWeekNum ].days.includes(
        //     nextMonthFirstDay
        // ) ? latestWeekNum : Object.keys( months[ month.nextI ].weeks )[ 0 ] * 1
        // startingWeekNum = functions.lastArrayElement( boardWeekNums ) > startingWeekNum ? functions.lastArrayElement( boardWeekNums )
        //     : Object.keys( schoolYear.months[ month.nextI ].weeks ).find(
        //         weekNum => weekNum * 1 > startingWeekNum && schoolYear.months[ month.nextI ].weeks[ weekNum ].daysInClass?.[ 0 ]
        //     ) * 1;
        // console.log('🔶', { unitMonthObj, startingWeekNum, startingMonthNum, boardWeekNums });
    }

    [ unit.boardNums, unit.weekNums ] = [ boardNums, boardWeekNums ];
    const numOfClasses = Math.floor( unit.months.reduce(
        ( sum, monthObj ) => sum + monthObj.days.all.length, 0
    ) / 2 );
    //     boardNums.filter( ( num, i, nums ) => nums.findIndex( n => n === num ) === i ),
    //     boardWeekNums.filter( ( num, i, nums ) => nums.findIndex( n => n === num ) === i )
    // ];

    // unit.boardNums.forEach( ( boardNum, i, arr ) => {
    //     const unitMonth = unit.months?.find( obj => obj.boardNums.includes( boardNum ) );
    //     const [ schoolMonth, weekNum ] = [
    //         schoolYear.months[ unitMonth.i ],
    //         unitMonth.weekNums[ unitMonth.boardNums.findIndex( num => num === boardNum ) ]
    //     ];

    //     // const [ daysInClass, year ] = [ schoolMonth.weeks[ weekNum ].daysInClass, schoolMonth.year.full ];
    //     // const lastDayOfWeek = functions.lastArrayElement( daysInClass.filter( dayInClass => {
    //     //     const dayI = schoolMonth.weeks[ weekNum ].days.findIndex( dayNum => dayNum === dayInClass );
    //     //     return schoolMonth.weeks[ weekNum ].months[ dayI ] === unitMonth.i
    //     // } ) );

    //     // Array( 8 ).fill().forEach( ( e, j ) => {
    //     //     const [ points, type, dueDayMonthYear ] = [
    //     //         j > 1 ? 0 : 1,
    //     //         j > 1 ? 'assignment' : 'assessment',
    //     //         [ lastDayOfWeek, unitMonth.i, year ]
    //     //     ];

    //     //     const assignment = getAssignmentObj( j, boardNum, j > 1, type, points, dueDayMonthYear );
    //     //     if ( !unit.assignments[ boardNum ] ) {
    //     //         unit.assignments[ boardNum ] = [ assignment ]
    //     //     } else { unit.assignments[ boardNum ].push( assignment ) }
    //     // } );
    //     // unit.assignments.push( assignments );

    //     if ( i === 0 || i === ( arr.length - 1 ) ) {
    //         const week = schoolMonth.weeks[ weekNum ];
    //         const day = functions.lastArrayElement( week.daysInClass );
    //         const month = week.months[ week.days.findIndex( dayNum => dayNum === day ) ];
    //         const year = months[ month ].year.full;

    //         unit[ i === 0 ? 'start' : 'end' ] = { year, month, day };
    //         // unit[ i === 0 ? 'start' : 'end' ].day = i === 0 ? schoolMonth.weeks[ weekNum ].daysInClass[ 0 ] : lastDayOfWeek;
    //     }
    // } );

    const [ firstUnitMonthObj, lastUnitMonthObj ] = [ unit.months[ 0 ], functions.lastArrayElement( unit.months ) ];
    const [ firstUnitDateObj, lastUnitDateObj ] = [{
        year: months[ firstUnitMonthObj.i ].year.full,
        month: firstUnitMonthObj.i,
        day: firstUnitMonthObj.days.all[ 0 ]
    }, {
        year: months[ lastUnitMonthObj.i ].year.full,
        month: lastUnitMonthObj.i,
        day: functions.lastArrayElement( lastUnitMonthObj.days.all )
    }];

    [ 'start', 'end' ].forEach( ( e, i ) => unit[ e ] = i === 0 ? firstUnitDateObj : lastUnitDateObj );
    if ( !unit.duration.boards ) {
        unit.duration.boards = numOfBoards < 0 ? numOfBoards * -1 : unit.boardNums.length
    } else if ( numOfBoards !== 0 ) {
        unit.duration.boards = unit.boardNums.length;
    }

    if ( Math.abs( unit.duration.classes - numOfClasses ) > 2 ) {
        unit.duration.classes = numOfClasses
        if ( !unit.blocks ) unit.blocks = numOfClasses
    }
    return unit
}

// EXPORTS
const courseInfo = {
    add,
    addMissingBoard,
    colors,
    get,
    languages: subjects.languages,
    languageUnits,
    subjectUnits,
    fillMissingBoards,
    getAssignmentObj,
    getPlaceholderBoards,
    getPremiumObj,
    getCourseObj,
    getSchoolObj,
    getSchoolYearObj,
    getCountyObj,
    getBoardObj,
    getUnitObj,
    getUserObj,
    getSubmissionObj,
};

export default courseInfo;