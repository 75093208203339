import { Link } from 'react-router-dom'
import CallToAction from '../Buttons/CallToAction';
import Logo from '../Images/Logo';
import styles from './HiveNav.module.css';
import get from '../../../constants/get';

const HiveNav = ({ isOutsideHero=true, menu={} }) => {
    const labels = Object.values( menu.labels );

    // FUNCTIONS
    function generateLi( item={} ) {
        const label = typeof item.label === 'string' ? item.label.toLocaleUpperCase() : item.label;

        return <li
            key={`menu-item-${ 'URLlabel' in item ? label : 'home' }`}
            className={ styles[ !!item.callToAction ? 'call-to-action' : 'not-button' ] }
        ><Link to={ !!item.callToAction || item.to ? item.to : item.href }>
            { !!item.callToAction ? <CallToAction label={ label } /> : label }
        </Link></li>
    }

    function generateMenuItems ( labels=[ {} ], components=[] ) {
        return labels.length < 1 ? components
            : generateMenuItems( labels.slice( 1 ), [ ...components, generateLi( labels[ 0 ] ) ] )
    }

    // CONTEXTS, PROPS, & ATTRIBUTES
    const navClasses = get.classNameFromArr([
        menu.logo?.href === '#hero' ? styles[ `over-${ isOutsideHero ? 'sections' : 'hero' }` ] : '',
        styles[ 'main-nav' ],
        labels[ '1' ] ? '' : styles[ 'single-item' ],
    ]);

    // COMPONENTS
    const LogoComponent = menu.logo ? <Logo
        href={ menu.logo.href }
        isPremium={ menu.logo.isPremium }
    /> : false;

    // RENDER
    return <nav className={navClasses}>
        { !!LogoComponent && LogoComponent }
        <ul>{ generateMenuItems( labels ) }</ul>
    </nav>
}

export default HiveNav;