import { Link } from 'react-router-dom';
import get from '../../constants/get';
import styles from './Button.module.css';

const Button = ({
    activeLabelColour = '',
    backgroundGradientColours = [ 'white' ],
    boxShadowColours = [ 'orangered', 'gold' ],
    className = '',
    fillSVGOnHover = false,
    hoverColours = [],
    hoverTextColour = 'black',
    id='',
    isAnchor = false,
    isLink = false,
    isDisabled = false,
    isInput = false,
    isPrimary = false,
    isSecondary = false,
    inputValue = null,
    label = '',
    labelColour = 'black',
    linkTarget = '',
    looksLikeLink = false,
    onClick = null,
    propsFromParent = {},
    scaleSVGOnHover = false,
    type = 'button',
    withShakeLines = false,
    withDrip = false,
}) => {
    const nums = getNums();
    if ( isPrimary ) {
        boxShadowColours = backgroundGradientColours = [ 'orangered', 'orange' ];
        hoverColours = [ backgroundGradientColours[ 1 ] ];
        hoverTextColour = labelColour = '#fdfdfd';
    } else if ( isSecondary ) {
        looksLikeLink = true;
    }

    if ( isAnchor ) isLink = true

    // FUNCTIONS
    function getNums( nums=[], i=0 ) {
        if ( i === 3 ) return nums

        const [ maxNum, minNum ] = i === 0 ? [ 3, 2 ] : [ 5, 3 ];
        const num = get.randomNum( maxNum, true, minNum ) * .2;

        const badNumber = nums.includes( num );
        if ( !badNumber ) nums.push( num ) && i++

        return getNums( nums, i )
    }

    function getSVG() {
        // const tallestDripNum = Math.max( ...nums );

        return <svg
            width={ 90 }
            // height={ 16 * tallestDripNum }
            height={ 40 }
            className={ styles.drip }
            // height={ getStyle( 0, nums[ 2 ] ).height }
        >
            <defs>
                <linearGradient id='honey-gradient'>
                    <stop offset='0%' stopColor='darkorange'/>
                    <stop offset='40%' stopColor='orange'/>
                    <stop offset='100%' stopColor='gold'/>
                </linearGradient>
            </defs>
            { getSVGPath( 15, 10, 20 * nums[ 0 ], 5 ) }
            { getSVGPath( 50, 10, 20 * nums[ 1 ], 5 ) }
            { getSVGPath( 60, 10, 20 * nums[ 2 ], 5 ) }
        </svg>
    }

    function getSVGPath( x=0, width=10, height=20, curveSize=5, y=5 ) {
        return <path { ...attributes.path } d={`
            M ${ x } ${ y - 10 }
            v ${ height }
            C ${ x } ${ height + curveSize }, ${ x + width } ${ height + curveSize }, ${ x + width } ${ height },
            v -${ height }
            M ${ x + width } ${ y } h -${ width } Z
        `}/>
    }

    // function getStyle( i=0, num=.75 ) {
    //     const styleObj = {
    //         height: [ num, 'rem' ].join(''),
    //         transition: [ `${ .3 * num }s`, 'ease-in-out' ].join(' '),
    //         // backgroundPosition: `calc( 100% - calc( 100% - ${ i === 2 ? '1rem' : i === 1 ? '1.25rem' : '4rem' } ) )`,
    //     }

    //     if ( i === 0 ) styleObj.marginRight = '1rem'
    //     return styleObj
    // }

    // PROPS & ATTRIBUTES
    // <svg height="280" width="400">
    //     <path d="M30,30 v220 C 30 280, 70 280, 70 250 v-220 M70,30 h-40 Z" stroke="maroon" stroke-linecap="round" stroke-width="5" fill="pink" width="30" heigt="30"/>
    // Sorry, your browser does not support inline SVG.
    // </svg>
    const attributes = {
        button: {
            ...propsFromParent, id, disabled: isDisabled,
            style: {
                '--active-label-colour': boxShadowColours[ 0 ] || activeLabelColour,
                '--label-colour': labelColour,
                '--hover-text': hoverTextColour,
            },
            className: get.classNameFromArr([
                className,
                styles.button,
                fillSVGOnHover && styles[ 'fill-svg-on-hover' ],
                scaleSVGOnHover && styles[ 'scale-svg-on-hover' ],
                hoverColours[ 0 ] && styles[ 'can-fill-background' ],
                isLink && styles.link,
                looksLikeLink && styles[ 'looks-like-link' ],
            ])
        },
        path: {
            stroke: 'transparent',
            strokeLinecap: 'round',
            strokeWidth: 3,
            width: 20,
            height: 30
        },
    };

    if ( isLink ) attributes.link = { ...attributes.button, [ isAnchor ? 'href' : 'to' ]: linkTarget }
    if ( !isLink ) attributes.button.type = isInput ? 'submit' : type
    if ( isInput ) {
        attributes.button.value = inputValue;
        attributes.button.onClick = onClick;
    }

    if ( onClick !== null ) attributes.button.onClick = onClick

    attributes.button.style[ '--shadow-to' ] = boxShadowColours[ 0 ];
    attributes.button.style[ '--gradient-to' ] = backgroundGradientColours[ 0 ];
    attributes.button.style[ '--hover-to' ] = ( hoverColours[ 1 ] || hoverColours[ 0 ] )
        || ( backgroundGradientColours[ 1 ] || backgroundGradientColours[ 0 ] );

    attributes.button.style[ '--shadow-from' ] = boxShadowColours[ 1 ] || boxShadowColours[ 0 ];
    attributes.button.style[ '--hover-from' ] = hoverColours[ 0 ] || backgroundGradientColours[ 0 ];
    attributes.button.style[ '--gradient-from' ] = backgroundGradientColours[ 1 ]
        || backgroundGradientColours[ 0 ];

    // COMPONENTS
    const children = <>
        { withShakeLines && <span className={ styles.line }/> }
        { label }
        { withShakeLines && <span className={ styles.line }/> }
        { withDrip && getSVG() }
        {/* { withDrip && <div className={ styles.drips }>{ <svg
            width={ 'fit-content' }
            height={ 'fit-content' }
            // key={ `drip-${ i }` }
            className={ styles.drip }
            // height={ getStyle( 0, nums[ 2 ] ).height }
            fill='linear-gradient( to right, orangered, orange 40%, gold )'
        >
            <defs>
                <linearGradient id='honey-gradient'>
                    <stop offset='0%' stopColor='orangered'/>
                    <stop offset='40%' stopColor='orange'/>
                    <stop offset='100%' stopColor='gold'/>
                </linearGradient>
            </defs>
            <path { ...attributes.path } d='
                M 30 30 v 220
                C 30 280, 70 280, 70 250, v -220
                M 70 30 h -40 Z
            '/>
        </svg> }</div> } */}
    </>;

    // RENDER
    return isAnchor ? <a { ...attributes.link }>{ children }</a>
        : isLink ? <Link { ...attributes.link }>{ children }</Link>
        : isInput ? <input { ...attributes.button }/>
        : !isDisabled ? <button { ...attributes.button }>{ children }</button>
        : <button { ...attributes.button } disabled>{ children }</button>
}

export default Button;