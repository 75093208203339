import { useState } from 'react';
import styles from './Hamburger.module.css';

const Hamburger = ({ onClick=() => {} }) => {
    const [ isActivated, setIsActivated ] = useState( false );

    // FUNCTIONS
    const toggleHamburger = () => {
        setIsActivated( isActivated => !isActivated );
        onClick();
    }

    // PROPS & ATTRIBUTES
    const attributes = {
        container: {
            className: [ styles.lines, isActivated ? styles.open : styles.closed ].join(' '),
            onClick: toggleHamburger,
        },
        line: { className: styles.line },
        middleLine: { className: styles.middle },
        leftMiddleLine: { className: [ styles.line, styles.left ].join(' ') },
        rightMiddleLine: { className: [ styles.line, styles.right ].join(' ') },
    }

    // RENDER
    return <div { ...attributes.container }>
        <div { ...attributes.line }/>
        <div { ...attributes.middleLine }>
            <div { ...attributes.leftMiddleLine }/>
            <div { ...attributes.rightMiddleLine }/>
        </div>
        < div { ...attributes.line }/>
    </div>
}

export default Hamburger;