import { useEffect, useState } from 'react';
import styles from './TextInput.module.css';
import get from '../../../../constants/get';

const TextInput = ({
    autofocus = false,
    className = '',
    id = '',
    isDisabled = false,
    isRequired = false,
    label = '',
    min = null,
    max = null,
    name = '',
    passValueUp = null,
    passTargetUp = false,
    startingValue = null,
    styleVariables = {},
    transformLabel = 'on-left',
    type = 'text',
    value = null,
}) => {
    // transformLabel: hide, to-top, on-top, on-left
    const [ data, setData ] = useState( passValueUp !== null ? value
        : ( startingValue === null && !value ) ? '' : startingValue
    );
    // window.location.hostname === 'localhost' && type === 'textarea' && console.log(
    //     'STARTING VALUE:', startingValue,
    //     '\nVALUE:', value,
    //     '\nDATA:', value,
    // );

    useEffect( () => { setData( () => startingValue ) }, [ startingValue ] )

    // FUNCTIONS
    const handlePassingValueUp = ( target={} ) => {
        setData( () => '' );
        const canPassUp = passValueUp !== null;

        if ( canPassUp ) passTargetUp ? passValueUp({ target }) : passValueUp( target.id, target.value )
    }

    const handleTyping = ({ target }) => passValueUp === null ?
        setData( () => target.value ) : handlePassingValueUp( target )

    // PROPS & ATTRIBUTES
    const attributes = {
        common: {
            className: `${ styles.input } ${ data || value ? styles['has-value'] : '' }`,
            name,
            required: isRequired,
            onChange: handleTyping,
            // value: value ? value : data, // THIS SHOULD BE DATA, NO?
        },
        div: {
            className: get.classNameFromArr([
                styles[ transformLabel ],
                styles[ 'text-input' ],
                className,
            ]),
            style: { ...styleVariables },
        },
        text: {
            autoFocus: autofocus,
            disabled: isDisabled,
            type,
        },
        label: { className: styles.label }
    };

    if ( value !== null ) attributes.common.value = value // SHOULD BE JUST DATA, NO ?
    if ( startingValue !== null && type !== 'textarea' ) attributes.common.defaultValue = data
    if ( startingValue !== null && type === 'textarea' ) attributes.common.value = data

    attributes.common.id = attributes.label.htmlFor = id;
    if ( [ 'text', 'number' ].includes( type ) ) attributes.text = { ...attributes.text, ...attributes.common }
    if ( type === 'number' ) {
        if ( typeof min === type ) attributes.text.min = min
        if ( typeof max === type ) attributes.text.max = max
    }

    // COMPONENTS
    const textInput = type !== 'textarea' ? <input { ...attributes.text }/> : <textarea { ...attributes.common }/>

    // RENDER
    return <div { ...attributes.div }>
        { textInput }
        <label { ...attributes.label }>{ label }</label>
    </div>
}

export default TextInput;