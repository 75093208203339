import get from '../../../../constants/get';
import AttachmentContainer from '../../../modular/Attachment/AttachmentContainer';
import SVG from '../../../modular/Images/SVG';
import Button from '../../Button';
import styles from './AssignmentBar.module.css';

const AssignmentBar = ({
    activeUnitBoardNums = [],
    assignment = {},
    className = '',
    copy = () => {},
    idPrefix = 'bar',
    isDragOverlay = false,
    showCopyButton = false,
    showEditButton = false,
    toggleMode = () => {}
}) => {
    const isEmptyAssignment = !assignment.directions;
    const renderButtons = !isEmptyAssignment && ( showCopyButton || showEditButton );

    // PROPS & ATTRIBUTES
    const attributes = {
        li: {
            className: get.classNameFromArr([
                className,
                styles.assignment,
                activeUnitBoardNums.includes( assignment.boardNum ) && styles.disabled,
                isDragOverlay && styles.overlay,
                isEmptyAssignment && styles[ 'empty-assignment' ],
                ( !showCopyButton && !showEditButton ) && styles[ 'no-buttons' ]
            ]),
            id: [ idPrefix, assignment.id ].join( '-' )
        },
        AttachmentContainer: {
            className: get.classNameFromArr([
                styles.attachment,
                assignment.links.length > 1 && styles.multiple
            ]),
            links: assignment.links,
        },
    };

    attributes.AttachmentContainer.asSquares = attributes.AttachmentContainer.isSmall = attributes.AttachmentContainer.stackVertically = true;

    // COMPONENTS
    const [ CopyButton, EditButton ] = [
        showCopyButton && getButton( assignment, true, 'copy', copy ),
        showEditButton && getButton( assignment, false, 'edit', toggleMode ),
    ];

    // RENDER
    return <article { ...attributes.li }>
        { renderButtons && <fieldset className={ styles.buttons }>
            { CopyButton }
            { EditButton }
        </fieldset> }

        <span className={ isEmptyAssignment ? styles[ 'placeholder-text' ] : styles.directions }>
            { assignment.directions || 'add/drag assignment' }
        </span>

        <AttachmentContainer { ...attributes.AttachmentContainer }/>
    </article>
}

function getButton( assignment={}, looksLikeLink=false, type='', onClick=() => {} ) {
    const buttonType = type.includes( 'handle' ) ? 'handle' : type;

    const props = {
        looksLikeLink, className: styles.button,
        id: [ 'activate', assignment.id, buttonType ].join( '-' ),
        label: <SVG fontSize='.75em' type={{ [ type ]: true }}/>
    };

    if ( onClick !== null ) props.onClick = onClick
    return <Button { ...props }/>;
}

export default AssignmentBar