import { useContext, useState } from 'react';
import CourseContext from '../../../contexts/CourseContext';
import Board from '../Board/Board';
import CallToAction from '../Buttons/CallToAction';
import styles from './FillBoard.module.css';
import FillBoardBar from './Parts/FillBoardBar';
import RadioGroup from './Parts/RadioGroup';
import UserContext from '../../../contexts/UserContext';
import HiveContext from '../../../contexts/HiveContext';
import courseInfo from '../../../constants/courseInfo';
import get from '../../../constants/get';

// choiceboard vs learning path
const FillBoard = ({ assignmentI=0, board={} }) => {
    const { course, schoolYear } = useContext( HiveContext );
    const { locale, updateAssignments } = useContext( UserContext );
    const { activeBoardNum } = useContext( CourseContext );

    const [ teacherView, setTeacherView ] = useState( true );

    const { months } = schoolYear;
    const { monthNum, weekNum } = board;
    const dueDate = get.dueDayMonthYear( months, board );
    const assignments = get.boardAssignments( course, board.num, dueDate );

    const dateOptions = { day: 'numeric' };
    dateOptions.weekday = dateOptions.month = 'short';

    const weekObj = months[ monthNum ].weeks[ weekNum ];
    const schoolDayIndexes = [
        weekObj.days.findIndex( day => day === weekObj.daysInClass[ 0 ] ),
        weekObj.days.findIndex( day => day === weekObj.daysInClass[ weekObj.daysInClass.length - 1 ] )
    ];

    // FUNCTIONS
    function changeView({ target: { id, checked } }) {
        if ( id ) {
            let i = 1 * id.split( '-' ).pop();
            i = checked ? i
                : i === 0 ? 1 : 0;
            setTeacherView( () => [ true, false ][ i ] )
        }
    }

    function getDates( asString=true, i=0, arr=[] ) {
        if ( i === 2 ) return !asString ? arr
            : arr.map( date => date.toLocaleDateString( locale, dateOptions ) ).join(' - ')

        const j = schoolDayIndexes[ i ];
        const [ month, day ] = [ weekObj.months[ j ], weekObj.days[ j ] ];
        const date = [ months[ month ].year.full, month, day ];

        return getDates( asString, i + 1, [ ...arr, asString ? new Date( ...date ) : date ] )
    }

    function getUnits( i=0, units=[] ) {
        if ( i === 2 ) return units.filter( ( e, i, arr ) => arr.findIndex( () => e.theme ) === i )

        const j = schoolDayIndexes[ i ];
        const [ month, day ] = [ weekObj.months[ j ], weekObj.days[ j ] ];

        for ( let unit of course.content.units ) {
            const isUnit = unit.months?.find( monthObj => monthObj.i === month )?.days?.all?.includes( day );

            if ( isUnit ) return getUnits( i + 1, [ ...units, unit ] );
        }
    }

    // API Save assignments
    function submitForm( e ) {
        e.preventDefault();
        let elements = Array.from( e.target.elements );
        elements = elements.filter( el => ![ 'FIELDSET', 'BUTTON' ].includes( el.tagName )
            && !el.name.includes( 'input' ) && ( el.type !== 'radio' || el.checked ) );

        const formObj = course.content.assignments[ board.num ] || Array( 8 ).fill().map(
            ( e, i ) => courseInfo.getAssignmentObj(
                i, board.num, i > 1, i > 1 ? 'assignment' : 'assessment', 1, dueDate
            )
        );

        elements.forEach( el => {
            const id = el.id;
            let data = id.split( '[' ).map( (item, i) => i > 0 ? item.split( ']' )[ 0 ] : item );
            let [ assignmentI, assignmentDataType ] = [ data[ 1 ], data[ 2 ] ];

            const isAlreadyAttached = assignmentDataType === 'attachments'
                && !formObj[ assignmentI ].links.includes( el.value );

            switch ( assignmentDataType ) {
                case 'directions': formObj[ assignmentI ].directions = el.value; break;
                case 'isgraded': formObj[ assignmentI ].isGraded = data[ 3 ] === 'true'; break;
                case 'points': formObj[ assignmentI ].points = el.value; break;
                case 'attachments': isAlreadyAttached && formObj[ assignmentI ].links.push( el.value ); break;

                default: window.location.hostname === 'localhost' && console.log( 'There is no place for:', el );
            }
        } );

        updateAssignments( formObj, board, course );
    }

    // CONTEXTS, PROPS, & ATTRIBUTES
    const viewLabels = [ 'teacher view', 'student view' ];
    const props = {
        Board: {
            board,
            assignmentsArr: assignments,
            units: getUnits(),
        },
        CallToAction: {
            autoFocus: false,
            isPrimary: true,
            label: 'save assignments',
            type: 'submit'
        },
        ToggleRadio: {
            classPrefix: `board-${ board.num }`,
            defaultCheckedButtons: [ viewLabels[ 0 ] ],
            groupName: 'view',
            mainLabels: viewLabels,
            passValueUp: changeView
        },
    };

    // COMPONENTS
    const Inputs = assignments.map( ( assignment, i ) => <FillBoardBar
        details={ assignment }
        id={`board-${ board.num }-assignment[${ i }]`}
        isActiveAssignment={ board.num === activeBoardNum && i === assignmentI }
        key={`board-${ board.num }-assignment-${ i }`}
    /> );

    // RENDER
    return <article className={ styles.form }>
        <header>
            <h2>week { board.num + 1 }</h2>
            <p>{ getDates() }</p>
            <RadioGroup { ...props.ToggleRadio }/>
        </header>
        { teacherView ? <form onSubmit={ submitForm }>
            <fieldset className={ styles.assignments }>{ Inputs }</fieldset>
            <div className={ styles[ 'button-container' ] }><CallToAction { ...props.CallToAction }/></div>
        </form> : <Board { ...props.Board }/> }
    </article>
}

export default FillBoard;