import get from '../../../constants/get';
import styles from './BoardIcon.module.css';

const BoardIcon = ({
    activeI = null,
    activeColour = 'orange',
    borderColour = 'goldenrod',
    canHover = false,
    completion = [],
    emptyColour = '#fdfdfd',
    centreColour = null,
    hsl = { h: 0, s: '97%', l: '30%' },
    ids=[] ,
    onClick = null,
    size = '.4rem',
}) => {
    activeI = activeI === 0 ? 3
        : activeI === 1 ? 5
        : activeI < 5 ? activeI - 2
        : activeI + 1;

    const backgroundGradient = ( function() {
        const [ h, s, l ] = [ hsl.h, hsl.s.split( '%' )[ 0 ] * 1, hsl.l.split( '%' )[ 0 ] * 1 ];
        const [ sDifference, lDifference ] = [ 4, 13 ];

        const gradientColourSteps = [
            `hsl( ${ h }, ${ s - sDifference }%, ${ l - lDifference }% )`,
            `hsl( ${ h }, ${ s - sDifference }%, ${ l - lDifference }% )`,
            `hsl( ${ h }, ${ s }%, ${ l }% )`,
            `hsl( ${ h }, ${ s + sDifference }%, ${ l + lDifference }% )`,
        ];

        const gradientElements = [ '45deg', ...gradientColourSteps ];
        return `linear-gradient( ${ gradientElements.join(', ') } )`

    } )();
    const centreColourVal = centreColour || backgroundGradient;

    // FUNCTIONS
    const getSquares = () => Array( 9 ).fill().map( ( e, i ) => {
        const completionI = i < 3 ? i + 3
            : i < 6 ? i - 3
            : i

        const props = {
            className: get.classNameFromArr([
                styles.square,
                i < 3 ? styles.top : i < 6 ? styles.middle : styles.bottom,
                i % 3 === 0 ? styles.left : i % 3 === 1 ? styles.centre : styles.right,
                i % 2 === 0 && i !== 4 && styles.corner,
                typeof activeI === 'number' && activeI === i && styles.active,
                onClick !== null && styles.clickable,
                !completion[ completionI ] && styles.empty
            ]),
            key: [ 'square', i ].join( '-' ),
            style: {
                '--active-colour': ( i === 4 && centreColour ) ? centreColourVal : activeColour,
                '--border-colour': ( i === 4 && centreColour ) ? centreColourVal : borderColour,
                '--empty-colour': ( i === 4 && centreColour ) ? centreColourVal : emptyColour,
                '--size': size,
            }
        };

        if ( ids ) props.id = ids[ i ]
        if ( centreColour && i === 4 ) props.style.background = centreColour
        if ( props.className.includes( 'empty' ) ) props.style.borderWidth = '0px'

        return onClick !== null ? <button { ...props } onClick={ onClick }/> : <div { ...props }/>
    } )

    // PROPS & ATTRIBUTES
    const logoAttributes = {
        className: get.classNameFromArr([ styles.logo, canHover && styles.hoverable ]),
        style: { '--background': backgroundGradient }
    };

    // RENDER
    return <div { ...logoAttributes }>{ getSquares() }</div>
}

export default BoardIcon;