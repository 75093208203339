import { useState } from 'react';
import moduleInfo from '../../../constants/moduleInfo';
import Caret from '../Buttons/Caret';
import get from '../../../constants/get';
import styles from './Modal.module.css';

const Modal = ({
    caretsLoop = false,
    needsCarets = true,
    modalChildren = [],
    modalClass = '',
    startingI = 0,
    toggleModal=() => {}
}) => {
    const [ i, setI ] = useState( startingI );

    const { directions: { x: axis } } = moduleInfo;
    const maxI = modalChildren.length - 1;

    // FUNCTIONS
    const incrementI = ({ target }) => setI( i => {
        const increment = target.className.toLocaleLowerCase().includes( 'right' ) ? 1 : -1;
        const newI = i + increment;

        return caretsLoop && newI === -1 ? maxI : caretsLoop && newI === maxI + 1 ? 0 : newI
    } )

    const getCarets = ( carets=[] ) => carets[ 1 ] ? carets : getCarets([
        ...carets, <Caret { ...props.Caret }
            key={`modal-${ axis[ carets.length ] }-caret`}
            direction={ axis[ carets.length ] }
            disabled={ caretsLoop ? false : carets.length === 0 ? i === 0 : i === maxI }
        />
    ]);

    // PROPS & ATTRIBUTES
    const props = {
        div: {
            wrapper: {
                onClick: toggleModal,
                className: get.classNameFromArr([ styles[ 'modal-container' ], modalClass ]),
            },
            modal: {
                className: get.classNameFromArr([
                    styles.modal, styles[`${ needsCarets ? 'with' : 'sans' }-carets`]
                ])
            },
        },
        Caret: { onClick: incrementI },
    };
    props.Caret.isPrimaryColour = props.Caret.hasBorder = true;

    // COMPONENTS
    const [ LeftCaret, RightCaret ] = getCarets();

    // RENDER
    return <div { ...props.div.wrapper } >
        <div { ...props.div.modal }>{ modalChildren[ 1 ] ? [
            LeftCaret, modalChildren[ i ], RightCaret
        ] : modalChildren[ i ] }</div>
    </div>
}

export default Modal;