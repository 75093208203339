import StrToJsx from "../StrToJsx";

const ReviewText = ({ review='', className='', inFocus=false }) => {
    return <article className={ className }>
        <p>
            <i className='fa-solid fa-quote-left' />
            { inFocus ? <br /> : ' ' }
            { StrToJsx( review ) }
        </p>
    </article>
}

export default ReviewText;