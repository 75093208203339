import { useContext } from 'react';
import styles from './ContactForm.module.css';
import TextInput from './Parts/TextInput';
import SelectInput from './Parts/SelectInput';
import Stepper from './Stepper/Stepper';
import Button from '../../pages/Button';
import AccountContext from '../../../contexts/AccountContext';

const icons = {
    who: <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 24 24">
        <path fill="none" stroke="#888888" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M8 7a4 4 0 1 0 8 0a4 4 0 0 0-8 0M6 21v-2a4 4 0 0 1 4-4h3.5m5.5 7v.01M19 19a2.003 2.003 0 0 0 .914-3.782a1.98 1.98 0 0 0-2.414.483"/>
    </svg>,
    what: <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 24 24">
        <g fill="none" stroke="#888888" strokeLinecap="round" strokeWidth="1.5"><path d="M3.092 6.637L9.782 3.5a5.17 5.17 0 0 1 4.435 0l6.692 3.137c1.455.682 1.455 3.044 0 3.726L14.218 13.5a5.17 5.17 0 0 1-4.435 0L5 11.258"/><path strokeLinejoin="round" d="M2.5 15v-2.862c0-1.28 0-1.92.33-2.42c.33-.501.919-.753 2.094-1.257L6 8"/><path d="M19 11.5v5.125c0 1.008-.503 1.952-1.385 2.44C16.146 19.88 13.796 21 12 21c-1.796 0-4.146-1.121-5.615-1.934C5.504 18.577 5 17.633 5 16.626V11.5"/></g>
    </svg>,
    where: <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 24 24">
        <g fill="none" stroke="#888888" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2"><path d="M9 11a3 3 0 1 0 6 0a3 3 0 0 0-6 0"/><path d="M14.997 19.317L13.414 20.9a2 2 0 0 1-2.827 0l-4.244-4.243A8 8 0 1 1 20 11.073M19 22v.01M19 19a2.003 2.003 0 0 0 .914-3.782a1.98 1.98 0 0 0-2.414.483"/></g>
    </svg>,
    how: <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 24 24">
        <g fill="none" stroke="#888888" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2"><path d="M14.69 18.498c-.508.21-.885.65-1.015 1.185c-.426 1.756-2.924 1.756-3.35 0a1.724 1.724 0 0 0-2.573-1.066c-1.543.94-3.31-.826-2.37-2.37a1.724 1.724 0 0 0-1.065-2.572c-1.756-.426-1.756-2.924 0-3.35a1.724 1.724 0 0 0 1.066-2.573c-.94-1.543.826-3.31 2.37-2.37c1 .608 2.296.07 2.572-1.065c.426-1.756 2.924-1.756 3.35 0a1.724 1.724 0 0 0 2.573 1.066c1.543-.94 3.31.826 2.37 2.37a1.724 1.724 0 0 0 1.065 2.572a1.67 1.67 0 0 1 1.179.982"/><path d="M14.95 12.553a3 3 0 1 0-1.211 1.892M19 22v.01M19 19a2.003 2.003 0 0 0 .914-3.782a1.98 1.98 0 0 0-2.414.483"/></g>
    </svg>,
    sign: <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 256 256">
        <path fill="white" d="M232 168H63.86c2.66-5.24 5.33-10.63 8-16.11c14.93 1.65 32.62-8.8 52.74-31.22c.6 1.66 1.27 3.37 2 5.1c6.51 15.25 14.91 23.94 25 25.85c10.34 2 20.58-3.23 31.08-15.82C189.5 143.87 203.5 152 232 152a8 8 0 0 0 0-16c-30.63 0-39.55-10.59-40-16.22a8 8 0 0 0-15.51-2.54c-12.17 18.25-19.38 19.14-22 18.66c-8.33-1.57-16.08-20.93-18.69-33.51a8 8 0 0 0-14.8-2.23c-19.8 24.62-33.08 33-41.41 35.14c8.49-18.88 14.83-35.45 18.89-49.4c6.82-23.44 7.32-39.83 1.51-50.1c-3-5.36-9.29-11.75-21.91-11.8h-.25c-16 .11-28.6 15.3-34.62 41.7c-3.59 15.71-4.18 33.19-1.63 48s7.86 25.51 15.55 31.89c-3.72 7.73-7.53 15.28-11.23 22.43H24a8 8 0 0 0 0 16h13.41c-11.32 21-20.12 35.64-20.26 35.88a8 8 0 1 0 13.71 8.24c.15-.26 11.27-18.79 24.7-44.12H232a8 8 0 0 0 0-16Zm-40-48v-.21a1.11 1.11 0 0 1 0 .21ZM58.79 69.26C62.78 51.78 70.48 40 78 40c5.25 0 7 1.86 8 3.67c3 5.33 6.52 24.19-21.65 86.37c-8.19-11.29-10.98-37.04-5.56-60.78Z"/>
    </svg>,
};

const ContactForm = ({ isWaitlist=false }) => {
    const { handleLogin } = useContext( AccountContext );

    // FUNCTIONS
    const getTextInput = ({ obj, obj: {
        className,
        id,
        isRequired,
        label,
        max,
        min,
        passTargetUp,
        passValueUp,
        startingValue,
        styleVariables,
        transformLabel,
        type,
        value
    } }) => <TextInput { ...obj }/>

    const getSelectInput = ({ obj, obj: {
        canCreateOption,
        canReset,
        canSelectMultiple,
        canTypeAnswer,
        className,
        colours,
        defaultTags,
        hideTypingFieldWhenTagIsSelected,
        id,
        initialTags,
        isVertical,
        label,
        labelPositionOn,
        name,
        options,
        passTargetUp,
        passValueUp,
        startingValue
    } }) => <SelectInput { ...obj }/>

    const getButton = ({ obj, obj: {
        activeLabelColour,
        backgroundGradientColours,
        boxShadowColours,
        className,
        hoverColours,
        hoverTextColour,
        id,
        inputValue,
        isDisabled,
        isInput,
        isLink,
        label,
        labelColour,
        linkTarget,
        looksLikeLink,
        onClick
    } }) => <Button { ...obj }/>

    const loginUser = ( userObj={} ) => {
        const { email, password, ...user } = userObj;
        const isGoogleLogin = !email && !password;
        const args = isGoogleLogin ? [] : [ user, email, password ];

        handleLogin( ...args )
    }

    // COMPONENTS
    const [ Who, What, Where, How, Sign ] = Object.keys( icons ).map( ( e, i ) => {
        const props = Array( i === 2 ? 3 : 2 ).fill().map( ( e, j ) => {
            const [ isText, isButton ] = [ ![ 1, 2, 4 ].includes( i ), i === 4 ];
            let prop = {
                className: isButton ? styles.button : styles.input,
                label: i === 0 ? j === 0 ? 'first name' : 'last name'
                    : i === 1 ? j === 0 ? 'subjects' : 'position'
                    : i === 3 ? j === 0 ? 'I wish...' : 'but...'
                    : i === 4 ? j === 0 ? 'Sign with Google' : 'Sign with email' // send them temporary password but also log them in direct & have them change it
                    : j === 0 ? 'state' : j === 1 ? 'county' : 'school',
            };

            prop.key = prop.id = i === 0 ? j === 0 ? 'first-name' : 'last-name'
                : i === 1 ? j === 0 ? 'subjects' : 'position'
                : i === 3 ? j === 0 ? 'pedagogy-aspiration' : 'pedagody-reality'
                : i === 4 ? j === 0 ? 'google-auth' : 'email-auth'
                : j === 0 ? 'state' : j === 1 ? 'county' : 'school';

            window.location.hostname === 'localhost' && console.log( prop.id, 'is a', isButton ? 'button' : isText ? 'text input' : 'select input' );

            if ( isText ) {
                prop.isRequired = true;
                prop.transformLabel = 'top';
            } else if ( isButton ) {
                prop.looksLikeLink = j === 1;
                prop.onClick = loginUser;
                // prop.activeLabelColour = 'black';
                // prop.backgroundGradientColours = ;
                // prop.boxShadowColours = [ 'white', 'white' ];
                // prop.hoverColours = ;
                // prop.hoverTextColour = ;
                // prop.inputValue = ;
                // prop.isDisabled = ;
                // prop.isInput = ;
                // prop.isLink = ;
                // prop.labelColour = ;
                // prop.linkTarget = ;
            } else {
                prop.canCreateOption = prop.canReset = prop.canTypeAnswer = prop.isVertical = true;
                prop.canSelectMultiple = i === 1 || j !== 0;
                prop.labelPositionOn = 'top';
                prop.options = [ 'x', 'y', 'z' ];
                // colours: [],
                // defaultTags,
                // hideTypingFieldWhenTagIsSelected,
                // initialTags,
                // passTargetUp,
                // passValueUp,
                // startingValue
            }

            return prop
        } );

        return {
            data: props.map( prop => 'canCreateOption' in prop ? getSelectInput({ obj: prop })
                : 'looksLikeLink' in prop ? getButton({ obj: prop }) : getTextInput({ obj: prop }) ),
            header: e,
            icon: icons[ e ],
        }
    } )

    // RENDER
    return <Stepper stepChildrenAreForms stepChildren={[ Who, What, Where, How, Sign ]}/>
}

// state, county, school, role, name
// what's your pedagogy
// email ( google vs non-google )

export default ContactForm;

/*
 * Hi { teacher } !

    My name is Edding, and I am the founder of BumbleBoard LLC. It's a platform that organises your assignments to motivate your students to do the work. All you do is drop your assignments and we make sure your students can get to what they are supposed to do in 2 clicks.

    After 9 years in the classroom, this yielded the best student performance of my
 */