import { useState } from 'react';
import Logo from '../Images/Logo';
import SideNav from '../Nav/SideNav';
import CallToAction from './CallToAction';
import styles from './SideNavButton.module.css';

const SideNavButton = ({ buttonLabel='', className='', menuItems=[''] }) => {
    const [ showSideBar, setShowSideBar ] = useState( false );

    const toggleSideBar = () => setShowSideBar( showSideBar => !showSideBar )

    return <div className={ styles[`${ showSideBar ? 'open' : 'closed' }-menu`] }>
        <CallToAction
            className={`${ styles.button } ${ className }`}
            label={ buttonLabel ? buttonLabel : [
                <Logo isBoardCutOut key='logo-1'/>,
                <Logo isJustBee key='logo-2'/>,
            ] }
            onClick={ toggleSideBar }
        />
        <SideNav
            className={`${ styles.menu } ${ className }`}
            menuItems={ menuItems }
            toggleSideNav={ toggleSideBar }
        />
    </div>
}

export default SideNavButton;