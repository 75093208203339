import styles from './UnitsBanner.module.css';
import UnitSquare from './UnitSquare';
import { useContext, useState } from 'react';
import {
    closestCenter,
    DndContext,
    KeyboardSensor,
    PointerSensor,
    useSensor,
    useSensors,
} from '@dnd-kit/core';
import { arrayMove, sortableKeyboardCoordinates } from '@dnd-kit/sortable';
import EditUnits from '../Form/EditUnits';
import get from '../../../constants/get';
import SVG from '../Images/SVG';
import SortableWrapper from '../DragAndDrop/SortableWrapper';
import Button from '../../pages/Button';
import HiveContext from '../../../contexts/HiveContext';

const UnitsBanner = ({
    className = '',
    onClick = null,
    themes = {},
    units = [],
    updateUser = () => {}
}) => {
    const { schoolYear: { months } } = useContext( HiveContext );
    // const { schoolYear: { months }, course: { content } } = useContext( HiveContext );
    // if ( ! units[ 0 ] ) units = content.units

    const [ editThemes, setEditThemes ] = useState( false );
    const [ themeTitles, setThemeTitles ] = useState( themes.titles );
    const [ activeThemeID, setActiveThemeID ] = useState( null );

    const [ currentMonth, currentWeekNum ] = [ get.dayMonthYearToday()[ 1 ], get.currentWeekNum( months ) ];
    let currentUnitI = ( () => {
        let i = units.findIndex( unit => {
            const unitMonth = unit.months.find( month => month.i === currentMonth );
            return unitMonth?.weekNums?.includes( currentWeekNum )
            // unit.months.find( month => month.i === currentMonth )
            // && unit.weeks[ currentMonth ].includes( currentWeekNum )
        } );
        return i < 0 ? units.length - 1 : i
    } )();

    // SORTABLE
    const sensors = useSensors(
        useSensor( PointerSensor ),
        useSensor( KeyboardSensor, { coordinateGetter: sortableKeyboardCoordinates } )
    );

    // FUNCTIONS
    const changeBarBackground = ( i=0, hex='' ) => {
        let backgrounds = themes.backgrounds.slice();
        backgrounds.splice( i, 1, hex );

        updateUser({ backgrounds });
    }

    const getFormValues = ( elements=[] ) => {
        const values = { backgrounds: [], blocks: [], titles: [] };
        elements.filter( e => e.tagName === 'INPUT' && e.value ).forEach( e => {
            let [ key, i ] = e.id.split('-');
            const value = key.includes( 'title' ) ? e.value
                : key.includes( 'block' ) ? e.value * 1
                : { hex: e.value || '#ffffff', ...get.hslFromHex( e.value || '#ffffff' ) };

            if ( key === 'backgrounds' ) value.h *= 1
            values[ key ][ i * 1 ] = value;
        } )

        console.log({ values, elements });
        return values;
    }

    // console.log( 'themes: ', themes.backgrounds );
    const generateSquare = ( title='', i=-1 ) => <UnitSquare
        barBackground={ themes.backgrounds[ i ]?.hex || '#D3D3D3' }
        changeBarBackground={ changeBarBackground }
        i={ i }
        key={`${ title }-theme${ i }`}
        onClick={ i === -1 ? toggleEditThemes : onClick }
        theme={ title || 'edit' }
    />

    const handleDragStart = ({ active: { id } }) => setActiveThemeID( () => id )

    const handleDragEnd = ({ active, over }) => {
        // console.log( active.id , over.id );
        if ( activeThemeID !== over.id ) {
            const [ oldI, newI ] = [ themeTitles.indexOf( activeThemeID ), themeTitles.indexOf( over.id ) ];

            // console.log( themeTitles );
            setThemeTitles( themeTitles => arrayMove( themeTitles, oldI, newI ) );
            updateUser( oldI, newI );
        }

        setActiveThemeID( () => null )
    }

    const toggleEditThemes = ({ target: { className, elements } }) => {
        setEditThemes( editThemes => !editThemes );
        className.includes( 'form' ) && updateUser( getFormValues( Array.from( elements ) ) )
    }

    // PROPS & ATTRIBUTES
    const props = {
        DndContext: {
            collisionDetection: closestCenter,
            onDragEnd: handleDragEnd,
            onDragStart: handleDragStart,
            sensors,
        },
        EditUnits: {
            themes,
            className: styles.form,
            passValueUp: toggleEditThemes,
        },
        SortableContext: {
            items: themeTitles,
            handlePosition: { bottom: true },
            header: 'banner'
        },
    };

    const headerAttributes = {
        className: get.classNameFromArr([
            styles[ themeTitles[ 0 ] ? 'has-units' : 'is-missing-units' ],
            styles[ editThemes ? 'form-header' : 'bar-header' ],
            styles.themes,
            className,
        ]),
    };

    // COMPONENTS
    props.SortableContext.Children = themeTitles.map( ( title, i ) => generateSquare( title, i ) );

    const UnitsForm = <EditUnits { ...props.EditUnits }/>;
    const [ AddButton, EditButton, PlanButton ] = [ 'add', 'edit', 'plan' ].map( ( e, i ) => <Button
        // activeLabelColour=''
        backgroundGradientColours={ e === 'plan' ? [ 'orange', 'gold' ] : [ 'white' ] }
        // boxShadowColours={}
        className={ get.classNameFromArr([ styles.button, styles[ e ] ]) }
        fillSVGOnHover
        hoverColours={ e === 'plan' ? [ 'gold' ] : [ 'white' ] }
        id={ e !== 'plan' ? `toggle-${ e === 'add' ? `${ e }-to` : e }-banner-mode`
            : `plan-unit-${ currentUnitI }` }
        label={ <><SVG
            fill1='black'
            fontSize={ e === 'add' ? '2rem' : '1.5rem' }
            type={{ [ e === 'add' ? `${ e } to group`
                : e === 'edit' ? e
                : 'planner' ]: true }}
        />{ get.capitalise( e ) } Units</> }
        onClick={ e !== 'plan' ? toggleEditThemes : onClick }
    /> );

    const SortableBar = <DndContext { ...props.DndContext }>
        <fieldset className={ styles.buttons }>
            { themeTitles.length < 1 ? AddButton : EditButton }
            { themeTitles.length > 1 && PlanButton }
        </fieldset>

        <SortableWrapper { ...props.SortableContext }/>
    </DndContext>;

    // RENDER
    return <header { ...headerAttributes }>{ editThemes ? UnitsForm : SortableBar }</header>
}

export default UnitsBanner;