import { useState } from 'react';
import RadioGroup from '../../../modular/Form/Parts/RadioGroup';
import productInfo from '../../../../constants/productInfo';
// import PricingMiniCard from '../../Pricing/PricingMiniCard';
import RadioSquare from '../../../modular/Form/Parts/RadioSquare';
import styles from './Plans.module.css';
import PricingBar from '../../Pricing/PricingBar';
import get from '../../../../constants/get';

const [ features, plans ] = [
    productInfo.getFeatures(),
    productInfo.getFullPlans( 'honey' )
];
// console.log( plans );

const Plans = ({ passValueUp=null, activePlanIFromParent=null }) => {
    const [ isYearly, setIsYearly ] = useState( true );
    const [ activePlanI, setActivePlanI ] = useState(
        activePlanIFromParent === null ? 1 : activePlanIFromParent
    );

    const billingFrequencies = productInfo.getBillingFrequencies( plans[ activePlanI ].discount );

    // FUNCTIONS
    const purchasePlan = ({ target: { id } }) => window.location.hostname === 'localhost' && console.log( 'plan:' + id + '\ngoing to Stripe', plans[ activePlanI ] );

    const togglePaymentFrequency = () => setIsYearly( isYearly => !isYearly )

    const toggleActivePlan = ({ target: { id } }) => {
        const i = plans.findIndex( plan => id.split( '-' ).pop() * 1 === plan.i );
        passValueUp === null ? setActivePlanI( () => i ) : passValueUp( plans[ i ] )
    }

    // PROPS & ATTRIBUTES
    // COMPONENTS
    const Features = <ul className={ styles.features }>{ features.map( ( feature, i ) => <li
        className={ get.classNameFromArr([
            styles.feature, (
                'longAlternative' in plans[ activePlanI ].features[ i ]
                || plans[ activePlanI ].features[ i ].applies
            ) && styles.applies
        ]) }
        key={ `feature-${ i }` }
        style={{ '--i': i }} // for staggered bolding when the feature applies or un-applies
    >{ plans[ activePlanI ].features[ i ].longAlternative || feature.feature }</li> ) }</ul>

    const BillingFrequency = <div className={ styles.billing }>
        { isYearly && <div className={ styles.guarantee }>
            <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 24 24"><g>
                <path fill='goldenrod' d="M19 9A7 7 0 1 1 5 9a7 7 0 0 1 14 0Z"/>
                <path fill='gold' d="m7.093 15.941l-.379 1.382c-.628 2.292-.942 3.438-.523 4.065c.147.22.344.396.573.513c.652.332 1.66-.193 3.675-1.243c.67-.35 1.006-.524 1.362-.562a1.87 1.87 0 0 1 .398 0c.356.038.691.213 1.362.562c2.015 1.05 3.023 1.575 3.675 1.243c.229-.117.426-.293.573-.513c.42-.627.105-1.773-.523-4.065l-.379-1.382A8.461 8.461 0 0 1 12 17.5a8.46 8.46 0 0 1-4.907-1.559Z"/>
            </g></svg>
            <span>100% 30-day money back guarantee</span>
        </div> }
        <RadioGroup
            classPrefix='billing'
            defaultCheckedButtons={ billingFrequencies[ 1 ] }
            groupName='frequency'
            mainLabels={ billingFrequencies }
            passValueUp={ togglePaymentFrequency }
        />
    </div>

    // const PlanCards = plans.map( ( plan, i ) => <li key={ `plan-${ i - 1 }` }>
    //     <PricingMiniCard
    //         isActive={ plans[ activePlanI ].title === plan.title }
    //         isYearly={ isYearly }
    //         passValueUp={ toggleActivePlan }
    //         plan={ plan }
    //     />
    // </li> );

    const StudentAttributes = <ul className={ styles.list }>{ plans.map( ( plan, i ) => {
        const Label = <>
            { plan.i > 0 ? 'more' : '' }
            <span className={ styles.attribute }>{ plan.studentState }</span>
            { plan.i > 0 ? 'students' : 'of student' }
        </>;

        return <li
            className={ styles.option }
            key={ `attribute-${ plan.i }` }
        ><RadioSquare
            category={ Label }
            className={ styles.square }
            groupName='attributes'
            i={ plan.i }
            isDefaultChecked={ activePlanI === i }
            passValueUp={ toggleActivePlan }
        /></li>
    } ) }</ul>

    // RENDER
    return <section className={ styles.plans }>
        <fieldset className={ styles[ 'student-attributes' ] }>
            <span className={ styles.prompt }>I want</span>
            { StudentAttributes }
        </fieldset>

        <div className={ styles[ 'plans-and-features' ] }>
            <ul className={ styles[ 'plans-and-frequency' ] }>
                { BillingFrequency }
            </ul>

            <section className={ styles[ 'bar-and-features' ] }>
                <PricingBar
                    isYearly={ isYearly }
                    onSelect={ purchasePlan }
                    plan={ plans[ activePlanI ] }
                />
                { Features }
            </section>
        </div>
    </section>
}

export default Plans;