import functions from './get';

const getSchoolYearObj = (
    startDayMonthYear = [],
    endDayMonthYear = [],
    countyID = '',
    countyObservanceReasonsAndDays = [ {} ],
    countyHolidayReasonsAndDays = [ {} ],
    schoolHolidayReasonsAndDays = {},
    quarterIDs = [ '' ],
    quarterEndDayMonthYears = [ [] ],
    locale = 'en-US'
) => {
    if ( startDayMonthYear.length < 3 ) startDayMonthYear = functions.dayMonthYearArr( startDayMonthYear, true )
    if ( endDayMonthYear.length < 3 ) endDayMonthYear = functions.dayMonthYearArr( startDayMonthYear )

    const schoolYear = {
        countyID, end: {}, start: {},
        // end: {
        //     year: endDayMonthYear[ 2 ],
        //     month: endDayMonthYear[ 1 ],
        //     day: endDayMonthYear[ 0 ]
        // },
        holidays: Array( 12 ).fill().map( ( e, i ) => ({ ...countyHolidayReasonsAndDays[ i ] }) ),
        id: [ startDayMonthYear[ 2 ], endDayMonthYear[ 2 ] ].join(' - '),
        months: Array( 12 ).fill().map( ( e, i ) => {
            const month = { i };
            month.days = { max: get().monthMaxDays( i ) };
            month.nextI = i === 11 ? 0 : i + 1;
            month.weeks = {};
            month.name = {
                full: functions.monthName( i, locale, false ),
                short: functions.monthName( i, locale, true ),
            };
            month.year = {
                full: get().year( i, startDayMonthYear[ 1 ], startDayMonthYear[ 2 ], endDayMonthYear[ 2 ], false ),
                short: get().year( i, startDayMonthYear[ 1 ], startDayMonthYear[ 2 ], endDayMonthYear[ 2 ], true )
            };

            return month
        } ),
        observances: Array( 12 ).fill().map( ( e, i ) => ({ ...countyObservanceReasonsAndDays[ i ] }) ),
        // start: {
        //     year: startDayMonthYear[ 2 ],
        //     month: startDayMonthYear[ 1 ],
        //     day: startDayMonthYear[ 0 ]
        // },
    };
    [ schoolYear.start.day, schoolYear.start.month, schoolYear.start.year ] = startDayMonthYear;
    [ schoolYear.end.day, schoolYear.end.month, schoolYear.end.year ] = endDayMonthYear;

    schoolYear.totalDays = get().schoolDays( schoolYear, schoolYear.holidays, schoolHolidayReasonsAndDays );
    get().weeks( schoolYear, schoolHolidayReasonsAndDays )
    schoolYear.quarters = get().quarters(
        quarterIDs,
        quarterEndDayMonthYears.map( arr => ({ day: arr[ 0 ], month: arr[ 1 ], year: arr[ 2 ] }) ),
        schoolYear
    );

    return schoolYear
}

// FUNCTIONS
function get() {
    return {
        monthMaxDays( monthI=0 ) {
            const year = this.year( monthI );
            const isLeapYear = () => ( year % 4 === 0 ) && ( year % 100 === 0 || year % 400 === 0 )

            return [ 0, 2, 4, 6, 7, 9, 11 ].includes( monthI ) ?
                31 : [ 3, 5, 8, 10 ].includes( monthI ) ?
                30 : isLeapYear() ? 29 : 28
        },
        oddAndEvenDays( schoolYear={}, holidays={} ) {
            const { start, end, months: monthsArr } = schoolYear;
            let { day, month, year } = start;

            const startDate = new Date( year, month, day );
            let weekday = startDate.getDay();

            // If it's Friday, set weekday to Monday, else move to next weekday
            const incrementWeekday = () => weekday += weekday === 5 ? -4 : 1

            // if month === 12, set it to 0 and increment the year, else move to next month
            const incrementMonth = () => {
                month++;

                if ( month > 11 ) {
                    month -= 12;
                    year++;
                }
            }

            // if it's Friday, add 3 to today's date, else move to the next day
            // then incrementWeekday to match
            const incrementDay = () => {
                day += weekday === 5 ? 3 : 1;
                incrementWeekday();
            }

            // if day > months[month].maxDays, reset to next month's start day
            const resetDay = ( maxDays=28 ) => {
                day -= maxDays;
                incrementMonth();
            }

            const addOffMonths = () => {
                if ( month === start.month ) return

                monthsArr[ month ].days.odd = [];
                monthsArr[ month ].days.even = [];

                resetDay( monthsArr[ month ].days.max );
                return addOffMonths()
            }

            // for each day this month, if it's NOT a day off, and
            // it is NOT the last month of school, -OR- it is but it's before the last day (inclusive),
            // then add this day to odd or evenDays depending on the boolean var isOdd
            // then toggle var isOdd and repeat the loop.
            // Populate { months.month.days: { even, odd, total } },
            // then start the next month & recurse or exit
            const addSchoolDays = ( isLastMonth=false, isOdd=true ) => {
                const { days } = monthsArr[ month ];
                let [ oddDays, evenDays, isBeforeLastDay ] = [ [], [], true ];

                for ( day; day <= days.max; incrementDay() ) {
                    if ( isLastMonth ) isBeforeLastDay = day > end.day

                    if ( !holidays[ month ]?.days?.includes( day ) && ( !isLastMonth || isBeforeLastDay ) ) {
                        ( isOdd ? oddDays : evenDays ).push( day );
                        isOdd = !isOdd;
                    }
                }

                [ days.even, days.odd ] = [ evenDays, oddDays ];
                resetDay( days.max );

                return isLastMonth ? addOffMonths() : addSchoolDays( month === end.month + 1, isOdd )
            }

            // ====| RUN |====
            addSchoolDays()
        },
        quarters( quarterIDs=[ '' ], quarterEndDayMonthYears=[ {} ], schoolYear={} ) {
            const quarters = quarterIDs.map( ( id, i ) => ({
                id,
                end: quarterEndDayMonthYears[ i ],
                start: i === 0 ? { ...schoolYear.start }
                    : this.quarterStartDate( quarterEndDayMonthYears[ i - 1 ] )
            }) );

            quarters.forEach( obj => obj.weeks = functions.quarterWeekNums( obj, schoolYear.months ) );
            return quarters
        },
        quarterStartDate( previousQuarterEndDayMonthYear={} ) {
            const startDate = new Date(
                previousQuarterEndDayMonthYear.year,
                previousQuarterEndDayMonthYear.month,
                previousQuarterEndDayMonthYear.day + 1,
            );

            return { year: startDate.getFullYear(), month: startDate.getMonth(), day: startDate.getDate() }
        },
        schoolDays( schoolYear={}, schoolHolidays={} ) {//
            const [ countyHolidays, monthsArr ] = [ schoolYear.holidays, schoolYear.months ];

            // generate add & even days array for each month
            this.oddAndEvenDays( schoolYear, countyHolidays );

            // generate the days obj below based on the above
            const days = { even: 0, odd: 0, off: 0 };

            for ( let month of monthsArr ) {
                if ( month.days?.odd[ 0 ] ) days.odd += this.subtractSchoolHoliday( month, true, schoolHolidays )
                if ( month.days?.even[ 0 ] ) days.even += this.subtractSchoolHoliday( month, false, schoolHolidays )

                if ( schoolHolidays[ month.i ] ) days.off += schoolHolidays[ month.i ]?.days?.length
                if ( countyHolidays[ month.i ]?.days?.[ 0 ] ) days.off += countyHolidays[ month.i ]?.days?.length
            }

            days.inSchool = days.odd + days.even;
            return days
        },
        subtractSchoolHoliday( month={}, isOddDay=true, holidays={} ) {
            const hasSchoolHoliday = holidays[ month.i ]?.days?.length > 0;
            const daysArr = month.days[ isOddDay ? 'odd' : 'even' ];
            let daysLength = daysArr.length;

            if ( hasSchoolHoliday ) holidays[ month.i ].days.forEach( day => {
                if ( daysArr.includes( day ) ) daysLength -= 1
            } )

            return daysLength
        },
        weeks( schoolYear={}, schoolHolidays={} ) {
            const {
                start: { year: firstYear, month: firstMonth },
                end: { year: lastYear },
                months
            } = schoolYear;

            let [ year, month, day ] = [ firstYear, firstMonth, 1 ];
            let monthObj = months[ month ];
            let weekNum = -1;

            const firstDayDate = new Date( year, month, day );
            const firstWeekday = firstDayDate.getDay();
            day += ( firstWeekday * -1 ) + 1 - 2;

            // FUNCTIONS
            const addWeekToMonths = ( week={} ) => getUniqueMonths( week.months ).forEach( monthNum => {
                const hasLooped = year === lastYear && week.months.includes( firstMonth );

                if ( !hasLooped ) months[ monthNum ].weeks[ weekNum ] = week
            } )

            const incrementDay = () => {
                const isLastDay = day === monthObj.days.max;

                if ( isLastDay ) {
                    day -= monthObj.days.max;
                    month = monthObj.nextI;
                    monthObj = months[ month ];
                    if ( month === 0 ) year = lastYear
                }

                day++;
            }

            const incrementWeek = () => {
                weekNum++;
                incrementDay();
                incrementDay();
            }

            const getDaysOff = () => [
                ...schoolYear.holidays[ month ]?.days || [],
                ...( `${ month }` in schoolHolidays ? schoolHolidays[ month ].days : [] )
            ]

            const getUniqueMonths = ( monthsArr=[] ) => monthsArr.filter( ( month, i ) => monthsArr.indexOf( month ) === i );

            const generateWeek = ( week={}, i=0 ) => {
                if ( i === 0 ) week = { days: [], daysInClass: [], months: [] }

                week.days.push( day );
                week.months.push( month );
                getDaysOff().includes( day ) || week.daysInClass.push( day );

                incrementDay();
                return i === 4 ? week : generateWeek( week, i + 1 )
            }

            const generateWeeks = ( isNextYear=false ) => {
                if ( isNextYear ) return

                incrementWeek();
                addWeekToMonths( generateWeek() );
                return generateWeeks( year === lastYear && month === firstMonth )
            }

            // =====| RUN |=====
            generateWeeks()
        },
        year( monthNum=0, startMonth=0, startYear=1967, endYear=1967, abbr=false ) {
            const fullYear = monthNum < startMonth ? endYear : startYear;
            const yearStr = `${ fullYear }`;
            const decrement = yearStr.slice( 0, yearStr.length - 2 ) * 100;

            return abbr ? fullYear - decrement : fullYear
        },
    }
}

// EXPORT
export default getSchoolYearObj;