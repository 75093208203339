import { useDraggable } from '@dnd-kit/core';
import { CSS } from '@dnd-kit/utilities';
import styles from './Draggable.module.css';
import Button from '../../pages/Button';
import SVG from '../Images/SVG';
import get from '../../../constants/get';

const Draggable = ({
    Component,
    dragHandleIsOn = { top: false, right: false, bottom: false, left: false },
    dragHandleIsDotted = true,
    id = '',
    isDisabled = false,
    role = '',
    roleDescription = ''
}) => {
    const {
        attributes,
        listeners,
        setNodeRef,
        setActivatorNodeRef,
        transform,
    } = useDraggable({ id, disabled: isDisabled });

    attributes.role = role;
    attributes[ 'aria-roledescription' ] = roleDescription;

    const hasDragHandle = Object.values( dragHandleIsOn ).some( position => position );
    const dragHandleKey = [
        dragHandleIsDotted ? 'dotted' : 'solid',
        ( dragHandleIsOn.left || dragHandleIsOn.right ) ? 'vertical' : 'horizontal',
        'drag handle'
    ].join(' ');

    // PROPS & ATTRIBUTES
    const props = {
        div: {
            ...attributes,
            ...( hasDragHandle ? {} : listeners ),
            className: get.classNameFromArr([
                styles.draggable,
                isDisabled && styles.disabled,
                hasDragHandle && styles[ 'with-handle' ]
            ]),
            ref: setNodeRef,
            style: {
                transform: CSS.Translate.toString( transform )
            }
        },
        handle: {
            ...( hasDragHandle ? listeners : {} ),
            className: get.classNameFromArr([ styles.handle, isDisabled && styles.disabled ])
        }
    }

    if ( hasDragHandle ) props.ref = setActivatorNodeRef

    // RENDER
    return <div { ...props.div }>{
        hasDragHandle ? <>
            <Button
                label={ <SVG type={{ [ dragHandleKey ]: true }} fontSize='1em'/> }
                looksLikeLink
                propsFromParent={ props.handle }
            />{ Component }
        </> : Component
    }</div>
}

export default Draggable;