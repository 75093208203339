import { useState } from 'react';
import PricingCard from './PricingCard';
import Table from './Table';
import RadioGroup from '../../modular/Form/Parts/RadioGroup';
import Nav from '../Nav';
import HiveNav from '../../modular/Nav/HiveNav';
import AnimatedLogo from '../../modular/Images/AnimatedLogo';
import Button from '../Button';
import { Link } from 'react-router-dom';
import productInfo from '../../../constants/productInfo';
import get from '../../../constants/get';
import styles from './Pricing.module.css'

// const getFeatures = () => [
//     'unlimited courses',
//     'unlimited students',
//     'text to speech',
//     // 'assignment types',
//     'custom syllabus',
//     'gamified productivity',
//     // 'lesson plans',
//     // 'parent view',
//     'roll course over',
//     'searchable boards',
//     'collaborative teams',
//     'admin console',
//     'school assets',
//     'student & team data',
// ].map( feature => ({ feature }) )

const [ newPriceStart, newPriceDecrement ] = [ 250, .002 ];
const plans = productInfo.getFullPlans( styles.honey );

const Pricing2 = () => {
    document.title = 'BumbleBoard | Pricing';
    const [ selectedPlanI, setSelectedPlanI ] = useState( 2 );
    const [ isYearly, setIsYearly ] = useState( true );

    const billingFrequencies = productInfo.getBillingFrequencies( plans[ selectedPlanI ].discount );

    // FUNCTIONS
    function getNewPrice( price=9.99, teachers=2 ) {
        if ( teachers < newPriceStart ) return price
        teachers -= newPriceStart;

        const exponent = `${ newPriceDecrement }`.split( '.' )[ 1 ].length;
        let decrementMultiplierToInteger = 10 ** exponent;
        const decrementMultiplierToHundrethsPlace = 10 ** ( exponent <= 2 ? 0 : exponent - 2 );

        const decrement = newPriceDecrement * decrementMultiplierToInteger * teachers;
        price = ( price * decrementMultiplierToInteger ) - decrement;

        if ( decrementMultiplierToHundrethsPlace > 0 ) {
            price = Math.ceil( price / decrementMultiplierToHundrethsPlace );
            decrementMultiplierToInteger /= decrementMultiplierToHundrethsPlace;
        }

        return price / decrementMultiplierToInteger
    }

    function selectPlan({ target: { className } }) {
        setSelectedPlanI( () => className.split(' ').pop().split( '-' ).pop() * 1 )
    }

    function togglePaymentFrequency() { setIsYearly( isYearly => !isYearly ) }

    // COMPONENTS
    const [ BillingFrequency, Plans ] = [
        <RadioGroup
            classPrefix={ styles.group }
            defaultCheckedButtons={ billingFrequencies[ 1 ] }
            groupName='billing-frequency'
            mainLabels={ billingFrequencies }
            passValueUp={ togglePaymentFrequency }
        />,
        plans.map( plan => <PricingCard
            className={ get.classNameFromArr([
                styles.card,
                plan.i === 0 ? styles.free : styles.paid,
                selectedPlanI === plan.i ? styles.active : ''
            ]) }
            getSliderValue={ getNewPrice }
            isFocus={ plan.title === plans[ 2 ].title }
            isSelected={ selectedPlanI === plan.i }
            isYearly={ isYearly }
            key={ `plan-${ plan.i }` }
            onSelect={ selectPlan }
            plan={ plan }
        /> )
    ];

    // RENDER
    return <article className={ styles.page }>
        <Nav
            leftIcon={ <AnimatedLogo /> }
            middleMenu={ <HiveNav menu={{ labels: { 1: { to: '/', label: 'Home' } } }}/> }
            rightElement={ <Link to={ '/login' }>login</Link>}
            rightIcon={ <Button label='sign up'/> }
        />
        <header>
            <h1>
                Buy yourself some time&nbsp;
                <span className={ styles.subtitle }>and look good doing it.</span>
            </h1>
            <div>
                { isYearly && <div className={ styles.guarantee }>
                    <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 24 24"><g>
                        <path fill='goldenrod' d="M19 9A7 7 0 1 1 5 9a7 7 0 0 1 14 0Z"/>
                        <path fill='gold' d="m7.093 15.941l-.379 1.382c-.628 2.292-.942 3.438-.523 4.065c.147.22.344.396.573.513c.652.332 1.66-.193 3.675-1.243c.67-.35 1.006-.524 1.362-.562a1.87 1.87 0 0 1 .398 0c.356.038.691.213 1.362.562c2.015 1.05 3.023 1.575 3.675 1.243c.229-.117.426-.293.573-.513c.42-.627.105-1.773-.523-4.065l-.379-1.382A8.461 8.461 0 0 1 12 17.5a8.46 8.46 0 0 1-4.907-1.559Z"/>
                    </g></svg>
                    <span>100% 30-day money back guarantee</span>
                </div> }
                { BillingFrequency }
            </div>
        </header>

        <section className={ styles.cards }>
            <ul>{[ ...Plans.slice( 1 ), Plans[ 0 ] ]}</ul>
        </section>

        <section className={ styles.table }>
            <Table plans={ plans }/>
        </section>
    </article>
}

export default Pricing2;