import { useContext } from 'react';
import styles from './BoardProgress.module.css';
import Bee from '../../../modular/Images/Sprites/Bee';
import HiveContext from '../../../../contexts/HiveContext';

// const colours = [ 'forest', 'ocean', 'violet', 'rose', 'spice', 'citrus', 'gold', 'powder', 'grey', 'onyx' ];
// const avatars = {
//     finished: [
//         'https://docs.google.com/drawings/d/e/2PACX-1vTg-Zp9ROWzYphQ9XduGSc3X3_5E1vZr0EcL6wT6LpURywsSlKVfZvuZocZgFPF6-l235brZMK_GsnN/pub?w=407&h=327',
//         'https://docs.google.com/drawings/d/e/2PACX-1vRDeYklqUIddhMJr121HcF9ykDqM0Tc3ZA2yRD23EKscETluJlDttblWzugQ_UYNnOHZnjbSTuLNcRh/pub?w=407&h=327',
//         'https://docs.google.com/drawings/d/e/2PACX-1vTJ7GdL_AA0oTP8pQuoEZsWfji1296eaGFo9_Aw6bl8_oT-JNxHNBVP98kTtGN_hk2HAy6FRGx66bUx/pub?w=407&h=327',
//         'https://docs.google.com/drawings/d/e/2PACX-1vTRkKd4WKIlR_T4_6kSuyLGKiqsk1SEmEid-p2GVsWUupBTomVNTWnxRsKUBnRT1rhzZ66Y2YQykicA/pub?w=407&h=327',
//         'https://docs.google.com/drawings/d/e/2PACX-1vRuOrIBQkxj_S5khMRnWrjlEVEr6jCJGLZaEg7jNvPq3X1N0I9MleSY7NPdnqH5m0RjUnYuJIirwthQ/pub?w=407&h=327',
//         'https://docs.google.com/drawings/d/e/2PACX-1vSuwB7oZJ5VveVXoh0uoRwkolwonMJweQKsFMcnLL6QQV7D2jbl7a2gsgbj-V3zwFdnpJYOhfM7JB4p/pub?w=407&h=327 ',
//         'https://docs.google.com/drawings/d/e/2PACX-1vSyv_swX7D79RSZMgNno71Fbl3AVzq1FsLuDhvDkrgWdW6uXpUhlXCJ9MUhz9cqtpzbXBctrcRd51t2/pub?w=407&h=327',
//         'https://docs.google.com/drawings/d/e/2PACX-1vSHNc6wAljg6-gnZvQm81RR-vG8-6qhu1gos8vfuetiP-hP9_iNi0wR0GMXydJtVXVsOAL7HNRdWu_0/pub?w=407&h=327',
//         'https://docs.google.com/drawings/d/e/2PACX-1vTSIYMGP2P0GSFhuVRpa9KW27k-GRGTzUvxDA0O2zyn272QuFtRKUANiS9egflrTeSe-ym0NiK2uYe9/pub?w=407&h=327',
//         'https://docs.google.com/drawings/d/e/2PACX-1vRohZ30YIbi6M8VE_vs7e9lVVs4kiKSTl1AbGbfgAZZPMR59vni5rxSZPx5y1EtJvMs9GkrJ6aV3F9o/pub?w=407&h=327',
//     ],
//     resting: [
//         'https://docs.google.com/drawings/d/e/2PACX-1vQWbfa5xa9N5L59dzfGce4NLlP_xxCXzp5UDC-Uc-v2cntzcdfAlv8IyZ4wX2IgABBFrYTKV46CvW8v/pub?w=246&h=301',
//         'https://docs.google.com/drawings/d/e/2PACX-1vR9u1Z6LvmJY0nMwR1fMqFmTIp2UD53DfZf0K_sizc3x3M4ct6nLfhXvQpvQRztbG7gqo2dG5v459dm/pub?w=246&h=301',
//         'https://docs.google.com/drawings/d/e/2PACX-1vSrWUGOHS6j117IHB4GV0kuPGaIwWgkbUYiWVDeUGNWVIXGORW7TS_nHmbjUV83WeP7A7BOJHgCcYnM/pub?w=246&h=301',
//         'https://docs.google.com/drawings/d/e/2PACX-1vSnmuFuIP7naJDK4pZKKR8U7iW252w9PD_nDIbOv0COp1l6la-iU0b1_1vDgwXmR9ra6VwH8_t18WZ0/pub?w=246&h=301',
//         'https://docs.google.com/drawings/d/e/2PACX-1vRM293aAzgHnNtmObkAF_kTRFnTm8ThGgS7H2Su5ykZEjzQoKeBq6zLrH10tKsGtehaADndqJYs-qAA/pub?w=246&h=301',
//         'https://docs.google.com/drawings/d/e/2PACX-1vQqkz-IrHqHcY7hRljXt67QEtXQ7va_fc_cEHISpiM3MPTfy_QI_RCcCZPSB193-ElSbxoTn0RBA7_t/pub?w=246&h=301',
//         'https://docs.google.com/drawings/d/e/2PACX-1vRxB4rEzTGTU4EylAeIU6VzvJjOrLwgAyrNdrgHwTAtcutv6O2mGy7uCwf4eYMhfkDhuH6tY3p3s4l3/pub?w=246&h=301',
//         'https://docs.google.com/drawings/d/e/2PACX-1vSmJK8boyTVtYIOOnq1ca3kD882BMMjoFXIAgNfNIkt7N1lY6qz0UH68XLDnldlfFq4huB9bsFJG9T0/pub?w=246&h=301 ',
//         'https://docs.google.com/drawings/d/e/2PACX-1vRgG8CxbgCzI0JzS4TQ8Sc4XYKfEVHUhMZ7MFseufO2diZYSIe5rH9oiQLvmbyx7zj4BmTkoEYaYEFO/pub?w=246&h=301',
//         'https://docs.google.com/drawings/d/e/2PACX-1vTNeJLTPnS90DOE8mINPOLF_7RI3QSNmepjP2y7BRKNx3-WJjZ8gv-KNtJXLx_MuOWA6Na1ar6YlehM/pub?w=246&h=301',
//     ],
//     running: [
//         'https://docs.google.com/drawings/d/e/2PACX-1vR49aloNlZSWImB2f_JNXxAXdmKG7wt6En-cITEgEN6gdmxq3k1-2za2k8RxfQDsalIgc_9Dti2mScZ/pub?w=416&h=428',
//         'https://docs.google.com/drawings/d/e/2PACX-1vROsICSGvjNIrFPp-H4p-UzJNszv0cCmlmfhT3VwRwSMaqHhDM3HUxQaoP5Saeb6kFGw8bu7b6qRYnJ/pub?w=416&h=428',
//         'https://docs.google.com/drawings/d/e/2PACX-1vQqMi4N70krs1DIH3i1AWnxDvdOSjWpLEhmYMP4yjG96O9Mhmk_8NFLKeRhqoPOBYuX2gSGnm8ou9ni/pub?w=416&h=428',
//         'https://docs.google.com/drawings/d/e/2PACX-1vSkfTU-_Q2zFOxwuWCvq_jnxr9hW78ZfhpcJyF_RKskUkq2wTUesLkjR28NndbAvwGtPm3joaYvHh-4/pub?w=416&h=428',
//         'https://docs.google.com/drawings/d/e/2PACX-1vSbv1Vd5igaiu60-OxgURAruzyl5TILkZl85ql7Ln57ubY6ariNcgWwXEky3VpHUtrF6d0iWh3Lp3lV/pub?w=416&h=428',
//         'https://docs.google.com/drawings/d/e/2PACX-1vQ6NtgkC7y--SHySiOYB4RXks32WO1rQJnYbaU_3iCsHrCHPK7m7GH-CWMjwn-jLm9ekYdOw1rXaQ8u/pub?w=416&h=428',
//         'https://docs.google.com/drawings/d/e/2PACX-1vT1Bpgo_E4R_kpJeFJFQ47ekiAJmGzZmPimVWHYyxHCJMQEx4VC-rf-4p7fn_ZNgEClNfATUZc5evE7/pub?w=416&h=428',
//         'https://docs.google.com/drawings/d/e/2PACX-1vTY5l-yUY8u0ZYQjsEUWY6dqf3TLAPJfiWaurefmMOOS1QyYxcuJQWNDxpnhqJTnZXT91GFhLtlXOQv/pub?w=416&h=428',
//         'https://docs.google.com/drawings/d/e/2PACX-1vQAbP_jv0hyiFJYGMPy9bIUmzJ8iUChRGd0RIDlJSUj9I-xozKJJ8HYIDnSzw4fAO7_chFqHKb2Z-gb/pub?w=416&h=428',
//         'https://docs.google.com/drawings/d/e/2PACX-1vTzcNK-jIoP40xwkKRkKobjv4PnkIIqj4q9Co-_irSTmnaP5O3qQfeBL9Ev5UPhhuWun2Pe0G6zVs-J/pub?w=416&h=428',
//     ],
//     sleeping: [
//         'https://docs.google.com/drawings/d/e/2PACX-1vRocRIsjPW57gYKm7ewoI1024GmDBsT8J3Ja6AbiK0BQAa7Sk2qG-RSyKhXV4N0CHMeAHYOxG8teftT/pub?w=331&h=334',
//         'https://docs.google.com/drawings/d/e/2PACX-1vSwk9jGpUXfv40O7Jo8NiKamT0RN3Vrck72hdwk_6KeI-rI5xncPPfYvFqh2KEdCL4cCClgU74zl4Ae/pub?w=331&h=334',
//         'https://docs.google.com/drawings/d/e/2PACX-1vSF2yi7u0pb7A3aZ8J937hcLfHax9QHIQ6CZS1vyXJ1U0sR2em4dbwxuIGe9iJytGYCqv1gefm2TAZG/pub?w=331&h=334',
//         'https://docs.google.com/drawings/d/e/2PACX-1vTrbGtDuMZHvymX1UT-tSq1bZ2pJIIC-2zFNJcno6s4cChSNumVQb9Jnhf0FJFeeMbHa4JTskQQ6vst/pub?w=331&h=334',
//         'https://docs.google.com/drawings/d/e/2PACX-1vT8yTCLzeGnxgKIWorIWXVeHPgf-cvQXpha87Kr0Es4N_7DmF9YhRuPIxr1aSpEk4cJ8PoHBcU1eAGI/pub?w=331&h=334',
//         'https://docs.google.com/drawings/d/e/2PACX-1vRBSzmVSFRvV71uBdGrjqexCLUafxQKoMy_1Eco6mIm9QRaTeh7Naot33aovhcbdyx99m_cXf4vaJPy/pub?w=331&h=334',
//         'https://docs.google.com/drawings/d/e/2PACX-1vQn3iuWhCQbhTRrba1ItFSdfgqYAT9qzPa1dKCOW9IUmDGS3Q_JF5PZv0E4Q68H06PxTQVPRtxNU-vh/pub?w=331&h=334',
//         'https://docs.google.com/drawings/d/e/2PACX-1vS3656bgsARSYOvAiN4CQ-YrHfbzh7GVEqnnjRj6HGzbXc4VEpI0tCSclAtu-u5b6fbvTs-W6SZR4ey/pub?w=331&h=334',
//         'https://docs.google.com/drawings/d/e/2PACX-1vSPuNirKrz93MXb0mxFIA4HMYutZXRjmUmRXAnRqzqCAwAWOQlPlbQUe5XGTX9x3KrTHP_pI7U8Q6sP/pub?w=331&h=334',
//         'https://docs.google.com/drawings/d/e/2PACX-1vTk0xLHIFejub2P8EhM65S5SpkbmgumZgFcWsrZ_AhFPFcmkFPvmcnwXdXh64FBV066AowAgEysNJ4r/pub?w=331&h=334',
//     ],
//     victory: [
//         'https://docs.google.com/drawings/d/e/2PACX-1vR_l9YNMPuvA4gLQSmm_9OEzrZ6Z7jDKPnlSLg_TyGpwpvTcIRnBvfOaD_5LUI65F6drOf3KhtR6UqJ/pub?w=392&h=698',
//         'https://docs.google.com/drawings/d/e/2PACX-1vR2CgCSU4K5OwR2gQcM-h0WK1Uixuhtdq1O_2gyYeSGMPtE-ujVVzzfujNNmLtsWuR3t5Br-cWPd833/pub?w=392&h=698',
//         'https://docs.google.com/drawings/d/e/2PACX-1vRbtUqswSWlfuMeliKpjG-jxnyQV5s0dRk0M-_DLOb7Z0WtcyqE1FObz0RhY8HLC6ST6sDcrxMTUS30/pub?w=392&h=698',
//         'https://docs.google.com/drawings/d/e/2PACX-1vSQBVdNfB-fpbJm7St3ldoHmakJLxp83K0NpLm5Jr71FGa06Li3Nc5IYVM8csDwdDradCYysQU90RC-/pub?w=392&h=698',
//         'https://docs.google.com/drawings/d/e/2PACX-1vRvIRLsskBvV8wlF_YxF6mkh0-U6RDZxHqKIHNjF8bMtkTHMv6sZZEWpv7ZGr-RLeDMGP8obWSNgHaS/pub?w=392&h=698',
//         'https://docs.google.com/drawings/d/e/2PACX-1vQD07jq7cgTZ39hl2kcsXORPjlPtaYijPU1cNA-CRZtHPAlMFHtb4bfgEO-pZIF0HzCRD0IfR3mqbtD/pub?w=392&h=698',
//         'https://docs.google.com/drawings/d/e/2PACX-1vRPG3nTWUfrJoqIsgO5pysaEdD-JxVjr44rIrb9HBcSKx7QbwrXXPTanVEEPoSJWC5bdmvnofZHoLfI/pub?w=392&h=698',
//         'https://docs.google.com/drawings/d/e/2PACX-1vQoO2DcGB_JcEz0IC8QO_zu2VBpDs7a6BLo8c0GFVpSJU9SjDJ0l5imnwC8GJc572S7_R2Q5aTgQNG4/pub?w=392&h=698',
//         'https://docs.google.com/drawings/d/e/2PACX-1vReDhu1Fa3UnTa-FhyY7ROW0eklgmmg8A3L_4pq66LyZkBHgQU6JTNRScmjfYXig6gU1B2KGwzfTZIg/pub?w=392&h=698',
//         'https://docs.google.com/drawings/d/e/2PACX-1vQMedAFrkKXKliGggpXHv-_1mTL-cJv5ObqJ--oMApvVFmLqaPWOwQGki_cuomJNUiv7iebFdLqWo9C/pub?w=392&h=698',
//     ],
// };

const BoardProgress = ({ checkedList=[], hsl={} }) => {
    const { rules } = useContext( HiveContext );
    // const [ colourI, setColourI ] = useState( getAvatarColour() );

    // console.log( '🟥', checkedList );
    const completion = {
        req: checkedList.filter( boxID => !boxID.includes( 'prereq' ) ).length,
        prereq: checkedList.filter( boxID => boxID.includes( 'prereq' ) ).length
    };

    [ completion.prereq, completion.req ] = completion.prereq < rules.prereq ? [ completion.prereq, 0 ]
        : completion.req < rules.req ? [ rules.prereq, completion.req ]
        : [ completion.prereq, completion.req ];
    const completedBoxes = completion.req + completion.prereq;

    const iconState = completedBoxes === 0 ? 'sleeping'
        : completedBoxes < 5 ? 'running'
        : completedBoxes === 5 ? 'finished'
        : completedBoxes < 8 ? 'victory' : 'resting';

    const completionRate = completedBoxes / 8 * 100;
    const style = {
        '--completion-rate': completionRate + '%',
        '--icon-position': `${ iconState === 'finished' ? completionRate - 10
            : iconState === 'resting' ? completionRate - 7.5
            : iconState !== 'sleeping' ? completionRate - 5 : completionRate }%`,
        '--h-increment-direction': ( hsl.h > 30 && hsl.h < 55 ) ? -1 : 1,
        '--h': hsl.h,
        '--s': hsl.s,
        '--l': hsl.l,
    };

    // RENDER
    return <div className={ styles.progress } style={ style }>
        {/* <div className={ styles.icon }><img
            alt=''
            onClick={ changeColour }
            src={ avatars[ iconState ][ colourI ] }
        /></div> */}
        <div className={ styles.icon }><Bee hue={ hsl.h }/></div>
        <div className={ styles.bar }>
            <div className={ styles.fill }/>
        </div>
    </div>
}

export default BoardProgress;