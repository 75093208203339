import { useState } from 'react';
import SliderCard from '../../modular/Form/Parts/SliderCard';
import Button from '../Button';
import styles from './PricingCard.module.css';
import get from '../../../constants/get';

const PricingCard = ({
    className = '',
    getSliderValue = () => {},
    isFocus = false,
    isSelected = false,
    isYearly = true,
    onSelect = null,
    plan = {},
}) => {
    const [ sliderValue, setSliderValue ] = useState( plan?.slider?.min || null );

    const price = plan.price[ isYearly ? 'yearly' : 'monthly' ];
    const isFree = price === 0;

    // FUNCTIONS
    const updateSliderValue = ({ target: { value } }) => setSliderValue( () => value )

    // PROPS & ATTRIBUTES
    const props = {
        button: {
            className: get.classNameFromArr([ styles.button, `plan-at-index-${ plan.i }` ]),
            label: isSelected ? plan.linkLabel : plan.buttonLabel,
            hoverTextColour: 'white',
            isLink: isSelected && true,
            linkTarget: plan.linkTarget,
        },
        container: {
            className: get.classNameFromArr([
                className,
                styles.card,
                isFree && styles.free,
                isFocus && styles.focus,
                isSelected && styles.selected,
            ])
        },
    };

    props.button.boxShadowColours = props.button.hoverColours = [ 'orange', 'gold' ];
    if ( isFree ) props.button.boxShadowColours = props.button.hoverColours = [ 'grey' ]

    if ( onSelect !== null ) props.button.onClick = onSelect
    if ( isFocus ) {
        props.button.backgroundGradientColours = props.button.hoverColours;
        props.button.labelColour = props.button.hoverTextColour;
    }

    // COMPONENTS
    const Features = plan.features.map( ( obj, i ) => (
        ( isFree && obj.longAlternative ) || !isFree
    ) && <li
        className={ get.classNameFromArr([
            styles.feature,
            ( obj.longAlternative || obj.applies ) && styles.applies
        ]) }
        key={`feature-${ i }`}
    ><span className={ styles.description }>{ obj.longAlternative || obj.feature }</span></li> );

    const Slider = sliderValue !== null && <SliderCard
        categorySingularPlural={[ plan.slider.category.singular, plan.slider.category.plural ]}
        className={ styles.slider }
        controlledVal={ sliderValue }
        id={ plan.slider.id }
        max={ plan.slider.max }
        min={ plan.slider.min }
        updateVal={ updateSliderValue }
    />;

    // RENDER
    return <li { ...props.container }>
        <button className={`button-cover-${ plan.i }`} onClick={ onSelect }/>

        <header>
            <section className={ styles[ 'plan-name' ] }>
                { plan.icon }
                <div className={ styles.text }>
                    <h2 className={ styles.title }>{ plan.title }</h2>
                    <p className={ styles.audience }>{ plan.audience }</p>
                </div>
            </section>

            <section className={ styles.billing }>
                <span className={ styles.price }>
                    ${ 'slider' in plan ? getSliderValue( price, sliderValue ) : price }
                    { !isFree && <span className={ styles.frequency }>/ month</span> }
                </span>
                { !isFree && isYearly && <p className={ styles.frequency }>billed annually</p> }
            </section>
        </header>

        <ul className={ styles.features }>
            { Features }
            { Slider }
        </ul>

        <Button { ...props.button }/>
    </li>
}

export default PricingCard;