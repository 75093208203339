import { useState } from 'react';
import styles from './SliderCard.module.css';
import get from '../../../../constants/get';

const SliderCard = ({
    categorySingularPlural = [ '', '' ],
    className = '',
    controlledVal = null,
    id = '',
    isCard = false,
    max = 0,
    min = 0,
    startingVal = null,
    updateVal = () => {}
}) => {
    const [ value, setValue ] = useState( startingVal || min );

    // FUNCTIONS
    const updateValue = ({ target: { value } }) => setValue( () => value )

    // RENDER
    return <div className={ get.classNameFromArr([
        isCard ? styles.card : styles.container, className
    ]) }>
        <header>
            <span className={ styles.value }>{ controlledVal !== null ? controlledVal : value }</span>

            <label className={ styles.label } htmlFor={ id }>
                { ( value === 1 || controlledVal === 1 ) ?
                    categorySingularPlural[ 0 ] : categorySingularPlural[ 1 ] }<br />
            </label>
        </header>

        <input
            className={ styles.slider }
            onChange={ controlledVal !== null ? updateVal : updateValue }
            type='range'
            id={ id }
            name={ id }
            min={ min }
            max={ max }
            step={ 1 }
            value={ controlledVal !== null ? controlledVal : value }
        />
    </div>
}

export default SliderCard;