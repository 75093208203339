import styles from './RadioButton.module.css';

const RadioButton = ({
    checked = null,
    className = '',
    defaultChecked = false,
    id = '',
    label = '',
    type = 'radio',
    groupName = '',
    toggleSecondary = () => {}
}) => {
    id = id === '' ? label : id;

    // PROPS & ATTRIBUTES
    const inputProps = {
        className: `${ className } ${ styles.button }`,
        id: id,
        key: id,
        name: groupName,
        onChange: toggleSecondary,
        type: type,
        value: typeof label === 'string' ? label.toLocaleLowerCase() : label,
    };
    const labelProps = {
        className: `${ className } ${ styles.label }`,
        htmlFor: id,
        key: `${ id }-label`,
    };

    if ( checked !== null ) inputProps.checked = checked
    if ( defaultChecked ) inputProps.defaultChecked = defaultChecked

    // RENDER
    return [
        <input { ...inputProps }/>,
        <label { ...labelProps }>{ label }</label>
    ]
}

export default RadioButton;