import styles from './ReviewFooter.module.css';

const ReviewFooter = ({ className='', children=[], inFocus=false }) => {
    const classNames = [
        className,
        styles[ inFocus ? 'in-focus' : 'not-in-focus' ]
    ].join().trim();

    return <footer className={ classNames }>
        <hr />
        { children }
    </footer>
}

export default ReviewFooter;