import get from '../../../../constants/get';
import styles from './StepNav.module.css';

const StepNav = ({
    headers = [],
    isHorizontal = true,
    toggleStep = null,
    stepIcons = [],
    stepCompletions = [],
}) => {
    // FUNCTIONS
    // COMPONENTS
    const CompleteIcon = <svg
        className={ styles.complete }
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 24 24"
    ><path
        fill="none"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2.5"
        d="M20 7L10 17l-5-5"
    /></svg>;

    const StepIconAndHeader = stepIcons.map( ( icon, i ) => {
        const attributes = {
            step: { style: {}, className: styles.step },
            cover: { onClick: toggleStep, className: styles.cover }
        };

        attributes.cover.id = attributes.cover.key = [ 'step', i ].join( '-' );
        if ( toggleStep !== null ) attributes.step.style.cursor = 'pointer'

        return <li { ...attributes.step }>
            <span className={ styles.icon }>
                <div { ...attributes.cover }/>
                { stepCompletions[ i ] ? CompleteIcon : ( icon || i + 1 ) }
            </span>
            { headers[ i ] && <span className={ styles.header }>{ headers[ i ] }</span> }
        </li>
    } );

    // RENDER
    return <nav className={ get.classNameFromArr([
        styles.nav, isHorizontal && styles.horizontal
    ]) }>
        <ul>{ StepIconAndHeader }</ul>
    </nav>
}

export default StepNav;