import get from '../../../constants/get';
import Button from '../Button';
import styles from './Table.module.css';

const Table = ({ plans=[], isYearly=true }) => {
    // FUNCTIONS
    // const getButtonColour = ( i=0 ) => i === plans.length - 2 ?
    //     [ 'gold', 'orangered' ] : [ 'white' ]

    const getTableCell = ( key='', className='', children, isHeader=false ) => isHeader ?
        <th key={ key } className={ className }>{ children }</th>
        : <td key={ key } className={ className }>{ children }</td>

    const wrapInTableRow = ( children, { isHeader=false, isFooter=false, key = '' } ) => {
        const props = {
            className: get.classNameFromArr([
                isFooter && styles.footer,
                isHeader && styles.header,
                styles.row,
            ])
        };
        if ( !!key ) props.key = key

        return <tr { ...props }>{ children }</tr>
    }

    // COMPONENTS
    let [ Footers, Headers, Rows ] = [
        [ <td key={ `blank-footer` }/> ],
        [ <th key={ `blank-header` }/> ],
        plans[ 0 ].features.map( ( feature, i ) => [
            getTableCell( `feature-desc-${ i }`, styles[ 'feature-list-item' ], feature.feature )
        ] )
    ];

    plans.forEach( plan => {
        const price = plan.price[ isYearly ? 'yearly' : 'monthly' ];
        const isFree = price === 0;

        Headers.push( getTableCell( `plan-${ plan.i }-header`, styles.header, <>
            <span className={ styles.title }>{ plan.title }</span><br />
            <span className={ styles.price }>${ price }</span>
            <br />{ !isFree && <sup className={ styles.frequency }>/Month</sup> }
            <br /><p className={ styles.audience }>{ plan.description }</p>
        </>, true ) );

        Footers.push( getTableCell( `plan-${ plan.i }-header`, styles.button, <Button
            backgroundGradientColours={ plan.i === plans.length - 2 ? [ 'orange', 'orangered' ] : [ 'white' ] }
            labelColour={ plan.i === plans.length - 2 ? 'white' : 'black' }
            className={ get.classNameFromArr([ styles.button, `plan-at-index-${ plan.i }` ]) }
            label={ plan.i === 0 ? <i className='fa-solid fa-arrow-right'/> : plan.buttonLabel }
        /> ) );

        plan.features.forEach( ( feature, i ) => Rows[ i ].push( getTableCell(
            `plan-${ plan.i }-feature-${ i }`,
            get.classNameFromArr([
                styles.feature,
                feature.applies ? styles.applies : feature.alternative ? styles[ 'has-text' ] : ''
            ]), feature.alternative || ( feature.applies ? <i className='fa-solid fa-minus'/> : '' )
        ) ) );
    } );

    [ Footers, Headers, Rows ] = [
        wrapInTableRow( Footers, { isFooter: true } ),
        wrapInTableRow( Headers, { isHeader: true } ),
        Rows.map( ( row, i ) => wrapInTableRow( row, { key: `feature-row-${ i }` } ) )
    ];

    // RENDER
    return <table className={ styles.table }>
        <thead>{ Headers }</thead>
        <tbody>{ Rows }</tbody>
        <tfoot>{ Footers }</tfoot>
    </table>
}

export default Table;