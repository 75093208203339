import { collection, collectionGroup, doc, getDoc, getDocs, query, where } from 'firebase/firestore';
import functions from '../../constants/get';
import { db } from '../../firebase_setup/firebase';
import get from '../../constants/get';
import firePush from './pushFireData';

const allData = async( userData={}, i=0, data={}, subData={ courses: [] } ) => {
    window.location.hostname === 'localhost' && console.log( `🚨 READING FIREBASE ${ i === 0 ? 'USER' : i === 1 ? 'COUNTY' : 'COURSES' } DATA 🚨` );
    let [ userID, email, tempUsername ] = [ userData.uid, userData.email, userData.email.split( '@' )[ 0 ] ];
    window.location.hostname === 'localhost' && console.log( `👤 USER`, userData );

    try {
        switch ( i ) {
            case 0:
                await userCollections( userID, tempUsername )
                    .then( receipt => { return data = { ...receipt, ...data } } );
                await userCollections( userID, tempUsername, data.schoolYearID )
                    .then( receipt => { return subData.premium = receipt } );

                // console.log( '💻 USER & PREMIUM DATA:', data, subData );
                return allData( userData, i + 1, data, subData )

            case 1:
                await loopCountyCollections( data.countyIDs, data.schoolIDs, data.schoolYearID )
                    .then( receipt => { return subData = { ...subData, ...receipt } } );

                // console.log( '💻 COUNTIES DATA:', subData );
                return allData( userData, i + 1, data, subData )

            case 2:
                await loopCourseCollections( userID, data.schoolYearID, true )
                    .then( receipt => { return subData.courses = [ ...subData.courses, ...receipt ] } );
                await loopCourseCollections( email, data.schoolYearID, false )
                    .then( receipt => { return subData.courses = [ ...subData.courses, ...receipt ] } );

                // console.log( '💻 COURSES DATA:', subData );
                return { ...data, ...subData }

            default: break;
        }
    } catch( error ) { console.error( `❌ ERROR READING ALL ${ i === 0 ? 'USER' : i === 1 ? 'COUNTY' : 'COURSE' } DATA`, error ) }
}

const loopCountyCollections = async ( countyIDs=[ '' ], schoolIDs=[ '' ], schoolYearID='2022 - 2023' ) => {
    window.location.hostname === 'localhost' && console.log( `🔄 LOOPING ${ schoolYearID } COUNTIES & SCHOOLS...` );
    const data = { counties: [] };
    // console.log( countyIDs, schoolIDs, schoolYearID );

    try {
        for ( let countyID of countyIDs ) {
            await countyCollections({ countyID }).then( countyObj => data.counties.unshift( countyObj ) );
            await countyCollections({ schoolYearID, countyID }).then( obj => { data.counties[ 0 ].schoolYear = obj } );

            for ( let schoolID of schoolIDs ) {
                await countyCollections({ countyID, schoolID })
                    .then( obj => { data.counties[ 0 ].schools = { [ schoolID ]: obj } } );
            }
        }

        return data
    } catch( error ) { console.error( '❌ ERROR READING COUNTIES!', error ) }
}

const loopCourseCollections = async (
    uidOrEmail = '',
    schoolYearID = '',
    subcollections = [ 'Units', 'Syllabus', 'Students', 'Boards', 'Assignments', 'Submissions' ]
) => {
    const [ courses, isOwnCourses ] = [ [], !uidOrEmail.includes( '@' ) ];
    window.location.hostname === 'localhost' && console.log( `🔄 LOOPING COURSES...` );

    try {
        const coursesRef = collection( db, 'courses' );
        const q = query( coursesRef, where(
            isOwnCourses ? 'ownerID' : 'guests',
            isOwnCourses ? '==' : 'array-contains',
            uidOrEmail
        ) );

        await getDocs( q ).then( async querySnapshots => {
            querySnapshots.forEach( snapshot => courses.push( snapshot.data() ) )
        } );

        for ( let course of courses ) {
            course.content = {};
            const args = [ course.title, course.id.db, schoolYearID ];

            for ( let subcollection of subcollections ) {
                await courseCollections( { [ 'read' + subcollection ]: true }, ...args ).then( receipt => {
                    course.content = { ...course.content, ...receipt };
                });
            }

            // console.log( `📸 ${ schoolYearID } ${ course.title || 'course' }`, course );
        }

        return courses
    } catch( error ) { console.error( '❌ ERROR READING COURSES!', error ) }
}

const loopSpecificCourseCollections = async ( courseID='', schoolYearID='', result={} ) => {
    try {
        const courseDoc = doc( db, 'courses', courseID );
        await getDoc( courseDoc ).then( async courseData => {
            const course = { ...courseData.data(), content: {} };
            const [ args, subcollections ] = [
                [ course.title, course.id.db, schoolYearID ],
                [ 'Units', 'Syllabus', 'Students', 'Boards', 'Assignments', 'Submissions' ]
            ];

            for ( let subcollection of subcollections ) {
                await courseCollections( { [ 'read' + subcollection ]: true }, ...args )
                    .then( receipt => { course.content = { ...course.content, ...receipt } });
            }

            result = { ...course }
        } );

        return result

    } catch( error ) { console.error( '❌ ERROR READING COURSE!', error ) }
}

const countyCollections = async ({ countyID='', schoolYearID='', schoolID='' }) => {
    const [ readSchools, readSchoolYears ] = [ !!schoolID, !!schoolYearID ];
    const schoolName = readSchools && functions.capitalise( schoolID.split( '-' ) );

    const [ countyName, docStr, errorDocStr ] = [
        functions.capitalise( countyID.split( '-' ).slice( 1 ) ),
        readSchools ? [ schoolName, 'doc' ].join(' ')
            : readSchoolYears ? `${ schoolYearID } school year doc` : 'doc',
        readSchools ? [ schoolName.toLocaleUpperCase(), 'DOC' ].join(' ')
            : readSchoolYears ? [ ' ', schoolYearID, 'SCHOOL YEAR DOC' ].join(' ') : 'DOC',
    ];

    try {
        const countyDoc = doc( db, 'counties', countyID );
        const querySnapshot = readSchools ? await getDoc( doc( countyDoc, 'schools', schoolID ) )
            : readSchoolYears ? await getDoc( doc( countyDoc, 'schoolYears', schoolYearID ) )
            : await getDoc( countyDoc );

            window.location.hostname === 'localhost' && console.info( `📸 ${ countyName }'s ${ docStr }`, querySnapshot.data() )
        return querySnapshot.data()

    } catch( error ) { console.error( `❌ ERROR READING ${ countyName }' ${ errorDocStr }`, error ) }
}

const courseCollections = async ({
    readAssignments=false,
    readBoards=false,
    readStudents=false,
    readSyllabus=false,
    readUnits=false,
    readSubmissions=false
}, courseName='',
    courseID='',
    schoolYearID=''
) => {
    const docStr = readAssignments ? 'assignments'
        : readBoards ? 'boards'
        : readStudents ? 'students'
        : readSyllabus ? 'syllabus'
        : readUnits ? 'units'
        : readSubmissions ? 'submissions' : '';
    const errorDocStr = docStr.toLocaleUpperCase();
    // window.location.hostname === 'localhost' && console.log( docStr, courseName, courseID, schoolYearID );
    const courseDoc = doc( db, 'courses', courseID );

    try {
        // const courseDoc = doc( db, 'courses', courseID );
        const querySnapshot = docStr ? await getDoc( doc( courseDoc, docStr, schoolYearID ) ) : await getDoc( courseDoc );
        window.location.hostname === 'localhost' && console.log( '👑', querySnapshot.data() || `no ${ docStr } to read` );
        if ( querySnapshot.data() ) return querySnapshot.data()

    } catch( error ) { console.error( `❌ ERROR READING ${ courseName || 'COURSE' } (${ schoolYearID }) ${ errorDocStr } DOC`, error ) }

    if ( readBoards || readSubmissions ) return { [ docStr ]: [] }
    if ( readStudents ) return { [ docStr ]: { all: [] } }

    const querySnapshot = await getDocs( collection( db, 'courses', courseID, docStr ) );
    const lastYearDocs = get.lastArrayElement( querySnapshot.docs ).data();
    return lastYearDocs
}

const userCollections = async ( uid='', preferredName='', schoolYearID='' ) => {
    const docStr = get.classNameFromArr([ !!schoolYearID && [ schoolYearID, 'premium' ].join(' '), 'doc' ]);
    const errorDocStr = docStr.toLocaleUpperCase();

    try {
        const userDoc = doc( db, 'users', uid );
        const querySnapshot = schoolYearID === '' ? await getDoc( userDoc )
            : await getDoc( doc( userDoc, 'premium', schoolYearID ) );

        window.location.hostname === 'localhost' && console.info( `📸 ${ preferredName }'s ${ docStr }`, querySnapshot.data() );
        return querySnapshot.data()

    } catch( error ) { console.error( `❌ ERROR READING ${ preferredName }'s ${ errorDocStr }`, error ) }
}

const teamCollections = async ( dashedCourseID='' ) => {
    return { county: [], school: [], custom: [] }
}

const findCourseByID = async ( courseID='', subcollections=[ '' ], schoolYearID='' ) => {
    try {
        const mainQuerySnapshot = await getDoc( doc( db, 'courses', courseID ) );
        const course = { ...mainQuerySnapshot.data() };

        Promise.all( subcollections.map( subcollection => courseCollections(
            { [ 'read' + subcollection ]: true }, '', courseID, schoolYearID
        ) ) ).then( receipts => { course.content = { ...receipts } } );

        return course

    } catch( error ) { console.error( '❌ ERROR GETTING HIVE', courseID, error ) }
}

const findCourseDocData = async ( courseID='', schoolYearID='', subcollections=[], alreadyHaveCourse=false ) => {
    try {
        let course = {}

        if ( !alreadyHaveCourse ) {
            const mainQuerySnapshot = await getDoc( doc( db, 'courses', courseID ) );
            course = mainQuerySnapshot.data();
        }

        if ( subcollections[ 0 ] ) await Promise.all( subcollections.map( subcollection => courseCollections(
            { [ 'read' + subcollection ]: true }, '', courseID, schoolYearID
        ) ) ).then( receipts => {
            const content = {};

            receipts.forEach( data => content[ Object.keys( data ) ] = Object.values( data )[ 0 ] );
            course.content = content;
        } )

        return alreadyHaveCourse ? course.content : course

    } catch( error ) { console.error( '❌ ERROR GETTING COURSE DATA', error ) }
}

const findCountyBySchoolID = async ( schoolID='', schoolYearID='' ) => {
    let county = {};

    try {
        await findSchoolByID( schoolID ).then( async school => {
            county.schools = { [ schoolID ]: school };
            // console.log( '📸 COUNTY STEP 1', county );

            return await findCountyByID( school.countyID )
        } ).then( async countyData => {
            county = { ...countyData, ...county };
            // console.log( '📸 COUNTY STEP 2', county );

            return await countyCollections({ countyID: county.id, schoolYearID })
        } ).then( schoolYear => { county.schoolYear = schoolYear } );

        // console.log( '📸 COUNTY STEP 3', county );
        return county

    } catch( error ) { console.error( '❌ ERROR GETTING COUNTY OBJ', error, county ) }
}

const findAllCounties = async () => {
    const counties=[];

    try {
        await getDocs( collection( db, 'counties' ) ).then( querySnapshot => {
            querySnapshot.forEach( county => counties.push( county.data() ) )
        } );

        return counties
    } catch( error ) { console.error( error) }
}

const findCountyByID = async ( countyID='', schoolYearID='', schoolIDs=[] ) => {
    if ( !schoolYearID && !schoolIDs[ 0 ] ) schoolYearID = get.schoolYearID()
    window.location.hostname === 'localhost' && console.log( '🟥', countyID, schoolYearID, schoolIDs );

    try {
        const querySnapshot = await getDoc( doc( db, 'counties', countyID ) );
        let [ countyData, countyHasSchoolInDB ] = [ querySnapshot.data(), false ];
        // console.log( '🟧', querySnapshot.data() );

        if ( !!countyData ) {
            countyData.schools = {};

            // console.log( '🟧', countyData );
            await getDoc( doc( db, 'counties', countyID, 'schoolYears', schoolYearID ) )
                .then( schoolYearData => { countyData.schoolYear = schoolYearData.data() } );
            // console.log( '🟧', countyData );

            if ( schoolIDs[ 0 ] ) await Promise.all( schoolIDs.map( schoolID => getDoc(
                doc( db, 'counties', countyID, 'schools', schoolID )
            ) ) ).then( async snapshot => snapshot.forEach( e => {
                if ( e.exists() ) {
                    const school = e.data();
                    countyData.schools[ school.id ] = school;
                    countyHasSchoolInDB = true;
                }
            } ) )
        }  // else { countyData = await firePush.pushCounty(); }
        if ( !countyHasSchoolInDB ) countyData = await firePush.pushCounty()


        window.location.hostname === 'localhost' && console.log( '🟧', countyData );
        return countyData

    } catch( error ) { console.error( '❌ ERROR GETTING COUNTY', countyID, error ) }
}

const findUserByID = async ( userID='', isPremium=false, schoolYearID='' ) => {
    try {
        const docRef = isPremium ? doc( db, 'users', userID, 'premium', schoolYearID ) : doc( db, 'users', userID );
        const querySnapshot = await getDoc( docRef );
        // console.log( querySnapshot.data() );

        return querySnapshot.exists() ? querySnapshot.data() : false

    } catch( error ) { console.error( '❌ ERROR GETTING QUEEN BEE', userID, error ) }
}

const findSchoolByID = async ( schoolID='' ) => {
    let school = {};

    try {
        const allSchoolsCollections = collectionGroup( db, 'schools' );
        const q = query( allSchoolsCollections, where( 'id', '==', schoolID ) );

        const querySnapshot = await getDocs( q );
        querySnapshot.forEach( queryDoc => school = { ...queryDoc.data() } );

        return school

    } catch( error ) { console.error( '❌ ERROR GETTING SCHOOL', schoolID, error ) }
}

const findSchoolInCounty = async ( schoolID='', countyID='' ) => {
    try {
        const schoolDoc = await getDoc( doc( db, 'counties', countyID, 'schools', schoolID ) );
        return schoolDoc.exists ? schoolDoc.data() : {}

    } catch( error ) { console.error( '❌ ERROR GETTING SCHOOL IN COUNTY', schoolID, error ) }
}

const findAllSchoolsInCounty = async ( countyID='' ) => {
    const schools = {};

    try {
        const countyDocs = await getDocs( collection( db, 'counties', countyID, 'schools' ) );
        countyDocs.forEach( school => schools[ school.id ] = school.data() );

        return schools

    } catch( error ) { console.error( '❌ ERROR GETTING SCHOOL IN', countyID, error ) }
}

const findNotOwnedCourses = async ( email='', schoolYearID='' ) => {
    const courseIDs = [];

    try {
        const readOnlyCollections = collectionGroup( db, 'courses' );
        const q = query( readOnlyCollections, where( 'guests', 'array-contains', email ) );

        const querySnapshot = await getDocs( q );
        querySnapshot.forEach( queryDoc => {
            const data = queryDoc.data();
            window.location.hostname === 'localhost' && console.log( `✅ ${ email } is a worker bee in ${ data.title }`, data );
            courseIDs.push( data )
        } );

        await loopCourseCollections( email, schoolYearID, false );
    } catch( error ) { console.error( `❌ ERROR SEARCHING FOR ${ email } IN COURSES`, error ) }
}

const findOwnedCourses = async ( userID='', schoolYearID='' ) => {
    const courses = [];

    try {
        const q = query( collection( db, 'courses' ), where( 'ownerdID', 'array-contains', userID ) );
        const querySnapshots = await getDocs( q );
        querySnapshots.forEach( queryDoc => courses.push( queryDoc.data() ) );

        await loopCourseCollections( userID, schoolYearID, true ).then( coursesArr => {
            // console.log( '👑 QUEEN COURSES', coursesArr );
            courses.push( ...coursesArr );
        } );

        return courses

    } catch( error ) { console.error( `❌ ERROR SEARCHING FOR ${ userID }'S COURSE` ) }
}

const findUser = async ( email='', getData=false ) => {
    const q = query( collection( db, 'users' ), where( 'email', '==', email ) );

    const querySnapshots = await getDocs( q );
    const userExists = [];

    querySnapshots.forEach( snapshot => userExists.push({
        ...snapshot.data(), id: snapshot.id
    }) );
    return getData ? userExists[ 0 ] : userExists.length > 0
}

const getCountiesByState = async ( state='' ) => {
    try {
        const docRef = doc( db, 'lists', 'countiesByState' );
        const allCounties = await getDoc( docRef );
        const counties = allCounties.data();

        return !state ? counties
            : state in counties ? counties[ state ] : []
    } catch( error ) { console.error( '❌', error ) }
}

const fireRead = {
    allData,
    countyCollections,
    courseCollections,
    getCountiesByState,
    findAllCounties,
    findAllSchoolsInCounty,
    findCourseByID,
    findCourseDocData,
    findCountyByID,
    findCountyBySchoolID,
    findNotOwnedCourses,
    findOwnedCourses,
    findSchoolByID,
    findSchoolInCounty,
    findUser,
    findUserByID,
    loopSpecificCourseCollections,
    teamCollections,
    userCollections,
};
export default fireRead;