import { useState } from 'react';
import styles from './Kebab.module.css';
import get from '../../../constants/get';

// on menu item click, change kebab icon to menu item icon with the confetti-esque twitter love button delight effect
const Kebab = ({ className='', icons=[ '' ], menuLabels=[ '' ], menuOnClicks=[ () => {} ] }) => {
    const [ showMenu, setShowMenu ] = useState( false );

    // FUNCTIONS
    const getSpanLabel = ( i=0, label='', needsIcon=false ) => !needsIcon ? label
        : <><i className={ [ icons[ i ], 'fa-fw' ].join(' ') }/>{ label }</>

    const toggleMenu = () => setShowMenu( showMenu => !showMenu )

    // PROPS & ATTRIBUTES
    const attributes = {
        button: {
            className: get.classNameFromArr([ styles.kebab, className ]),
            onClick: toggleMenu,
        },
        menu: {
            className: get.classNameFromArr([
                styles.menu,
                showMenu ? styles.show : styles.hide,
            ])
        }
    }

    // COMPONENTS
    const MenuItems = <nav { ...attributes.menu }><ul>{
        menuLabels.map( ( label, i ) => <li
            className={ styles[ 'menu-item' ] }
            key={ `kebab-menu-list-item-${ i }` }
            onClick={ menuOnClicks[ i ] }
        >{ getSpanLabel( i, label, !!icons[ 0 ] ) }</li> )
    }</ul></nav>

    // RENDER
    return <button { ...attributes.button }>
        <i className='fa-solid fa-ellipsis-vertical'/>
        { MenuItems }
    </button>
}

export default Kebab;