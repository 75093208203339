import get from '../../../constants/get';
import moduleInfo from '../../../constants/moduleInfo';
import CheckBox from '../Board/CheckBox';
import styles from './AttachmentContainer.module.css';

const AttachmentContainer = ({
    asSquares = false,
    EditButton,
    className = '',
    getResetButton = null,
    getCloseButton = null,
    id = '',
    isForDisplay = false,
    isSmall = false,
    links = [],
    showButton = false,
    showCheckBox = false,
    stackVertically = false,
    CheckBoxProps = null,
}) => {
    const { assignmentTypes: {
        file: fileObj,
        folder: folderObj,
        link: linkObj,
    } } = moduleInfo;

    // FUNCTIONS
    function findKeyInObj( str='', obj={}, defaultKey='default' ) {
        for ( let key in obj ) {
            if ( key !== defaultKey && str.includes( key ) ) return key
        }
        return false
    }

    function getAttachmentValue( attachment ) {
        const value = !attachment ? ''
            : isString( attachment ) ? get.capitalise( attachment )
            : attachment.name;

        return value
    }

    function getIcon( urlOrName='', isLink=true ) {
        let iconSrc = ( isLink ? ( linkObj || Object.fromEntries( urlOrName.split( '.' ) ) )
            : isFolder( urlOrName ) ? folderObj
            : fileObj ).icon;
        if ( isFolder( urlOrName ) ) iconSrc = iconSrc.open

        if ( isLink ) {
            const key = findKeyInObj( urlOrName, iconSrc );
            iconSrc = key ? iconSrc[ key ] : iconSrc.default;

            if ( key === 'google' ) {
                const newKey = findKeyInObj( urlOrName, iconSrc );
                iconSrc = iconSrc[ newKey ];
            }
        }

        window.location.hostname === 'localhost' && window.location.pathname !== '/' && console.log( iconSrc );

        return iconSrc?.startsWith( 'fa' ) ? <i className={ iconSrc }/>
            : <img alt='' src={ iconSrc }/>
    }

    function isString( e ) { return typeof e === 'string' }
    function isFolder( e ) { return e === 'folder' }

    function wrapInLink( href='', Child ) {
        // eslint-disable-next-line
        if ( isForDisplay ) return <a>{ Child }</a>

        const URLprefix = href.startsWith( 'www' ) ? 'https://'
            : href.startsWith( 'http' ) ? ''
            : 'https://www.';

        return <a
            className={ styles.link }
            target='_blank'
            rel='noreferrer noopener'
            href={ URLprefix + href }
        >
            <span className={ styles.href }>{ href }</span>
            { Child }
        </a>
    }

    // ATTRIBUTES
    const attributes = {
        div: {
            attachment: { className: styles.attachment },
            container: {
                className: get.classNameFromArr([
                    className,
                    asSquares ? styles.squares : styles.bars,
                    getResetButton !== null && styles[ 'with-reset-button' ],
                    getCloseButton !== null && styles[ 'with-close-button' ],
                    isSmall && styles.small,
                    links[ 0 ] ? styles.container : styles.empty,
                    stackVertically ? styles.vertical : styles.horizontal,
                ]),
            }
        },
        input: { type: 'hidden' },
    }

    attributes.input.id = attributes.input.name = `${ id }[value]`;
    window.location.hostname === 'localhost' &&  window.location.pathname !== '/' && links[ 0 ] && console.log( links );

    // COMPONENTS
    const Attachments = links.map( ( attachment, i ) => {
        const isLink = isString( attachment ) || 'type' in attachment === false;

        const [ iconContents, labelContents ] = [
            getIcon( isLink ? ( attachment?.url || attachment ) : attachment.type, isLink ),
            getAttachmentValue( attachment )
        ];

        let siteName = ( isSmall || asSquares || isLink ) ? labelContents
            // : isLink ? attachment?.url // findKeyInObj( attachment, linkObj.icon )
            : isFolder( attachment.type ) ? get.capitalise( attachment.name )
            : attachment.type.split('/')[ 1 ].toLocaleUpperCase();

        if ( siteName !== labelContents ) siteName = !siteName ?
            attachment.url.split( '.' )[ attachment.url.includes( 'www' ) ? 1 : 0 ]
            : isLink ? siteName[ 0 ].toLocaleUpperCase() + siteName.slice( 1 )
            : siteName

        const Contents = [
            <div className={ styles.icon } key='content-icon'>{ iconContents }</div>,
            <div className={ styles.url } key='content-url'>
                <span className={ styles.label }>{ siteName }</span>
            </div>
        ];

        return <div { ...attributes.div.attachment }
            id={ `${ id }[${ i }]` }
            key={ `${ id }[${ i }]` }
        >
            { getResetButton && getResetButton( labelContents, styles.reset, false ) }
            { getCloseButton && getCloseButton( labelContents, styles.close, true ) }
            <input { ...attributes.input } value={ labelContents }/>
            <div className={ styles.content }>
                { isLink ? wrapInLink( attachment?.url || labelContents, Contents ) : Contents }
            </div>
        </div>
    } );

    // RENDER
    return <div { ...attributes.div.container }>
        { showButton ? EditButton : ( showCheckBox && <CheckBox { ...CheckBoxProps }/> ) }
        { Attachments }
    </div>
}

export default AttachmentContainer;