// import { useState } from 'react';
import get from '../../../constants/get';
import styles from './Pill.module.css';

const Pill = ({
    activeState=null,
    className = '',
    gradientDuo = [ 'orangered', 'orange' ],
    label = '',
    noTransition = false,
    onClick = null,
    style = {}
}) => {
    // const [ isActive, setIsActive ] = useState( activeState === null ? true : activeState );

    // FUNCTIONS
    const togglePill = e => onClick( e )
    // const togglePill = e => onClick ? onClick( e ) : setIsActive( isActive => !isActive )

    // PROPS & ATTRIBUTES
    const props = {
        onClick: togglePill,
        id: label.toLocaleLowerCase().split(' ')[ 0 ],
        className: get.classNameFromArr([
            className, styles.pill,
            activeState ? styles.active : styles.inactive,
            noTransition && styles[ 'sans-transition' ]
        ]),
        style: {
            ...style,
            '--inactive-colour': gradientDuo[ 0 ],
            '--active-background': `linear-gradient( to top right, ${ gradientDuo[ 0 ] }, ${ gradientDuo[ 1 ] } )`
        },
    };

    // RENDER
    return onClick === null ? <div { ...props }>{ label }</div> : <button { ...props }>{ label }</button>
}

export default Pill;