import styles from './CheckBox.module.css';

const CheckBox = ({ defaultChecked = false, id = '', onChange = () => {}, type = 'checkbox' }) => {
    // PROPS & ATTRIBUTES
    const attributes = { defaultChecked, id, onChange, type, className: styles.box };
    attributes.key = attributes.name = attributes.id;

    // RENDER
    return <input { ...attributes }/>
}

export default CheckBox;