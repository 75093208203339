// import { useEffect, useState } from 'react';
import DateInput from '../../../modular/Form/Parts/DateInput';
import ToggleRadio from '../../../modular/Form/Parts/ToggleRadio';
import SVG from '../../../modular/Images/SVG';
import Button from '../../Button';
import TextInputButton from '../../TextInputButton';
import styles from './EventForm.module.css';
import get from '../../../../constants/get';

const EventForm = ({
    activeDayI = 17,
    activeMonthI = 0,
    monthIsGreaterThan = () => {},
    passValueUp = () => {},
    schoolYear = {}
}) => {
    // const [ activeDayIsDayOff, setActiveDayIsDayOff ] = useState(
    //     schoolYear.holidays[ activeMonthI ]?.days?.includes( activeDayI )
    // );

    const { months } = schoolYear;
    // console.log( schoolYear, activeDayIsDayOff, activeMonthI, activeDayI );
    // console.log( activeDayIsDayOff ? `${ months[ activeMonthI ].name.short }. ${ activeDayI } is a DAY OFF` : `${ months[ activeMonthI ].name.short }. ${ activeDayI } is a SCHOOL DAY` );

    // useEffect( () => {
    //     setActiveDayIsDayOff( schoolYear.holidays[ activeMonthI ]?.days?.includes( activeDayI ) )
    // }, [ activeMonthI, activeDayI ] )

    // FUNCTIONS
    function enumerateDaysMonthsAndReasons(
        startMonthDay = [ 0, 17 ],
        endMonthDay = [ 9, 8 ],
        obj = { days: [], months: [] }
    ) {
        const [ monthNum, dayNum ] = startMonthDay;
        const month = months[ monthNum ];
        const nextMonth = months[ month.nextI ];

        const nextMonthDay = month.days.all[ month.days.all.length - 1 ] === dayNum ? [
            nextMonth.i, nextMonth.days.all[ 0 ]
        ] : [ monthNum, month.days.all.find( day => day > dayNum ) ];
        // console.log( startMonthDay, nextMonthDay, endMonthDay );
        const isLastDay = startMonthDay.every( ( monthOrDay, i ) => i === 1 ? monthOrDay >= endMonthDay[ i ]
            : monthIsGreaterThan( monthOrDay, endMonthDay[ i ], true ) );

        if ( !dayNum ) {
            if ( nextMonthDay[ 1 ] < 1 ) {
                enumerateDaysMonthsAndReasons( nextMonthDay, endMonthDay, obj )
            } else if ( isLastDay ) { return obj }
        }

        obj.days.push( dayNum );
        obj.months.push( monthNum );
        // console.log( `${ month.name.short }. ${ dayNum } ${ isLastDay ? '=' : '!' }== ${ months[ endMonthDay[ 0 ] ].name.short }. ${ endMonthDay[ 1 ] }` );

        return isLastDay ? obj : enumerateDaysMonthsAndReasons( nextMonthDay, endMonthDay, obj )
    }

    function handleSubmit( e ) {
        e.preventDefault();
        const elements = Array.from( e.target.elements )
            .filter( f => f.id.includes( 'school' ) ? f.id === 'no-school' : f.value );
        const elementsObj = Object.fromEntries( elements.map( f => [
            f.id.includes( 'school' ) ? get.stringInNewFormat( f.id, '-', { camelCase: true } )
                : f.id.split( '-' )[ 1 ],
            f.id.includes( 'reason' ) ? f.value.toLocaleLowerCase()
                : f.id.includes( 'school' ) ? f.checked
                : f.value.split( '-' ).slice( 1 ).map( ( f, i ) => ( f * 1 ) - ( i === 0 ? 1 : 0 ) )
        ] ) );

        // console.log( elementsObj );
        const obj = {
            reason: elementsObj.reason,
            key: elementsObj.noSchool ? 'holidays' : 'observances',
            ...enumerateDaysMonthsAndReasons( elementsObj.start, elementsObj.end )
        };
        // console.log( obj );
        get.log({
            fileName: 'EventForm.js',
            functionDirectionsOrComponentName: 'passing this obj up',
            lineNumber: 78,
            str: obj
        });
        passValueUp( obj )
        // passValueUp( 'array with name--change this function in parent' )
    }

    // function toggleActiveDayIsDayOff() { setActiveDayIsDayOff( activeDayIsDayOff => !activeDayIsDayOff ) }

    // PROPS & ATTRIBUTES
    const props = {};
    [ 'event', 'start', 'end' ].forEach( ( e, i ) => props[ e ] = {
        [ i === 0 ? 'inputLabel' : 'label' ]: e,
        [ i === 0 ? 'inputID' : 'id' ]: [
            i === 0 ? e : 'event', i === 0 ? 'reason' : e
        ].join( '-' ),
    } )

    props.start.dashedYearMonthDay = props.end.dashedYearMonthDay = [
        months[ activeMonthI ].year.full,
        `${ activeMonthI }`.padStart( 2, '0' ),
        `${ activeDayI }`.padStart( 2, '0' )
    ].join( '-' );

    // props.fontSize = '1em';
    // props.passValueUp = () => {};
    props.event.autocomplete = props.event.fillAllBorders = true;
    props.start.schoolYear = props.end.schoolYear = schoolYear;
    // console.log( '🟩', props );

    // RENDER
    return <form className={ styles.form } onSubmit={ handleSubmit }>
        <TextInputButton { ...props.event }/>
        <DateInput { ...props.start }/>
        <DateInput { ...props.end }/>
        <fieldset className={ styles.buttons }>
            <ToggleRadio
                className={ styles.toggle }
                ids={[ 'school', 'no-school' ]}
                isVertical
                groupName='day-type-toggle'
                labels={[ '👩🏾‍🏫', '🏖️' ]}
                // passValueUp={ toggleActiveDayIsDayOff }
                startOff={ schoolYear.holidays[ activeMonthI ]?.days?.includes( activeDayI ) || true }
            />
            <Button
                label={ <SVG type={{ save: true }}/> }
                type='submit'
            />
        </fieldset>
    </form>
}

export default EventForm;