import get from '../../../constants/get';
import Button from '../Button';
import styles from './PricingBar.module.css';

const PricingBar = ({ isHorizontal=false, isYearly=false, onSelect=() => {}, plan={} }) => {
    // RENDER
    return <div className={ get.classNameFromArr([
        styles.bar,
        isHorizontal ? styles.horizontal : styles.vertical
    ]) }>
        <header className={ styles.heading }>
            <section className={ styles.icon }>{ plan.icon }</section>

            <section className={ styles.price }>
                <span className={ styles.title }>{ plan.title }</span>
                ${ plan.price[ isYearly ? 'yearly' : 'monthly' ] }
            </section>

            { plan.price.yearly > 0 && <section className={ styles.billing }>
                / month{ isYearly && <span className={ styles.frequency }>,<br />billed annually</span> }
            </section> }
        </header>

        <section className={ styles.audience }>{ plan.audience }</section>

        <Button label='start now' onClick={ onSelect }/>
    </div>
}

export default PricingBar;