// import { useState } from 'react';
import styles from './FilterAndSort.module.css';
import FilterSidebar from './FilterSidebar';

const FilterAndSort = ({
    filters={ categories: [], subcategories: [] },
    passSelectionUp=( selection={} ) => {},
    Result=<></>,
    SidebarContents=<></>
}) => {
    // const [ selection, setSelection ] = useState({});
    console.log( Result );

    // RENDER
    return <article className={ styles.interface }>
        { SidebarContents || <FilterSidebar filters={ filters } passSelectionUp={ passSelectionUp }/> }
        <section className={ styles.content }>{ Result }</section>
    </article>
}

export default FilterAndSort;