const fetch = require( "node-fetch" );

const base_URL = process.env.REACT_APP_BACKEND_API_BASE_URL

export async function getAllCourses() {
    const full_URL = base_URL + '/course/v0/'
    window.location.hostname === 'localhost' && console.log( '==============', '\nGET ALL COURSES\n', full_URL );
    try {
        const response = await fetch( full_URL, {
            method: 'GET',
            headers: {'Accept': 'application/json'}
        } ).then( response => response.json() );
        // window.location.hostname === 'localhost' && console.error("success running getAllCourses")
        window.location.hostname === 'localhost' && console.error("print response:", response)
        return response
    } catch( error ) {
        window.location.hostname === 'localhost' && console.error("error running getAllCourses", error )
        return [];
    }
}

// Gets a Course Doc based on Auth/Coursen UId
export async function getCourseId( id='' ) {
    const full_URL = base_URL + '/course/v0/' + id;

    try {
        const response = await fetch( full_URL, {
            method: 'GET',
            headers: {'Accept': 'application/json'}
        } ).then( response => response.json() );

        return response
    } catch( error ) {
        // window.location.hostname === 'localhost' && console.error("error running getAllCourses")
        return [];
    }
}

// Create a new course
export async function createCourse(courseId='', courseData={} ) {
    const full_URL = base_URL + '/course/v0/' + courseId

    try {
        const response = await fetch( full_URL, {
            method: 'POST',
            headers: {'Content-Type': 'application/json'},
            body: JSON.stringify( courseData )
        } ).then( response => response.json() );
        window.location.hostname === 'localhost' && console.log("Printing response from createCourse: ", response )
        return response
    } catch( error ) {
        // window.location.hostname === 'localhost' && console.error("error running getAllcourses")
        return [];
    }
}

// Update a Existing course
export async function updateCourse(courseId='', courseData={} ) {
    const full_URL = base_URL + '/course/v0/' + courseId

    try {
        const response = await fetch( full_URL, {
            method: 'PUT',
            headers: {'Content-Type': 'application/json'},
            body: JSON.stringify( courseData )
        } ).then( response => response.json() );
        window.location.hostname === 'localhost' && console.log("Printing response from updateCourse: ", response )
        return response
    } catch( error ) {
        // window.location.hostname === 'localhost' && console.error("error running getAllcourses")
        return [];
    }
}

// Delete Existing Course
export async function deleteCourse(courseId='') {
    const full_URL = base_URL + '/course/v0/' + courseId

    try {
        const response = await fetch( full_URL, {
            method: 'DELETE',
            headers: {'Content-Type': 'application/json'}
        } ).then( response => response.json() );
        window.location.hostname === 'localhost' && console.log("Printing response from deleteCourse: ", response )
        return response
    } catch( error ) {
        // window.location.hostname === 'localhost' && console.error("error running getAllcourses")
        return [];
    }
}

// Comment out when not testing
// module.exports = { getAllCourses, getCourseId, createCourse, updateCourse, deleteCourse };