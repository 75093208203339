import { useContext, useState } from 'react';
import CourseCard from './CourseCard';
import UserContext from '../../../contexts/UserContext';
import styles from './CourseloadPane.module.css';
import courseInfo from '../../../constants/courseInfo';
import { collection, doc } from 'firebase/firestore';
import { db } from '../../../firebase_setup/firebase';
import get from '../../../constants/get';
import Pill from '../../modular/Buttons/Pill';

const [ addButtonText, all, sortingKey, queen, worker ] = [
    'add-course', 'all', 'title', 'queen', 'worker'
];

const CourseloadPane = () => {
    const user = useContext( UserContext );

    const [ filter, setFilter ] = useState( 'all' );
    const [ courses, setCourses ] = useState( initiateState() );

    const [ domain, schoolYear ] = [ window.location.origin, user?.counties?.[ 0 ]?.schoolYear ];
    const currentWeekNum = get.currentWeekNum( schoolYear.months );

    // console.log( courses.details.map( course => course?.content?.boards?.[ course?.content?.boards?.length - 1 ] ) );
    const placeholderBoards = courseInfo.getPlaceholderBoards( schoolYear );
    const currentBoards = !user.plan.isFree ? courses.details.map(
        course => course?.content?.boards?.find( board => board?.weekNum === currentWeekNum )
            || placeholderBoards.find( board => board.weekNum === currentWeekNum )
            || course?.content?.boards?.[ get.lastArrayElement( placeholderBoards ).num ]
            || get.lastArrayElement( placeholderBoards )
    ) : Array( courses.details.length ).fill(
        placeholderBoards.find( board => board.weekNum === currentWeekNum )
        || get.lastArrayElement( placeholderBoards )
    );
    // console.log( currentBoards, currentWeekNum, placeholderBoards );

    //FUNCTIONS
    async function addCourse( e ) {
        e.preventDefault();

        const inputs = Array.from( e.target.elements ).filter( el => el.id );
        const courseNameInput = inputs.find( input => input.tagName === 'INPUT'
            && input.id === addButtonText && !input.id.includes( 'section' ) );

        const [ courseID, courseName ] = [ courseNameInput.id, courseNameInput.value.toLocaleLowerCase() ];

        const courseSections = inputs.filter( input => input.id.includes( courseID ) && input.id.includes( 'section' ) );
        const sections = courseSections.map( section => section.value )
            .filter( e => e )
            .sort( ( a, b ) => a - b );

        const splitCourseName = courseName.split(' ').filter( e => e );
        const courseRef = doc( collection( db, 'courses' ) );
        // console.log( '🟧', inputs, courseNameInput, courseName, splitCourseName, sections );

        const newCourse = courseInfo.getCourseObj(
            { dashedID: courseName.split(' ').join( '-' ), dbID: courseRef.id },
            user.locale.split( '-' )[ 0 ],
            splitCourseName,
            user.data.uid,
            Object.keys( user.counties[ 0 ].schools )[ 0 ],
            sections,
            schoolYear,
            false
        );

        let isNewCourse = courses.details.filter( course => course.ownerID === user.data.uid )
            .every( course => course.title !== newCourse.title );

        if ( isNewCourse ) {
            const details = get.alphabetiseObjArr( sortingKey, [ ...courses.details, newCourse ] );
            const [ queenHives, ids ] = [
                details.filter( course => isQueenHive( course ) ),
                [ addButtonText, ...details.map( course => course.id.db ) ]
            ];

            setCourses( () => ({ details, ids }) );
            user.addCourse( newCourse, queenHives, queenHives.map( course => course.id.db ) );
        }
    }

    function deleteCourse({ target: { id } }) {
        setCourses( courses => {
            const idToDelete = id.split( '-' )[ 1 ];
            const i = courses.ids.findIndex( courseID => courseID === idToDelete );
            // console.log( i, idToDelete );

            const newCourses = {
                details: get.arrWithoutElementAtI( i - 1, courses.details ),
                ids: get.arrWithoutElementAtI( i, courses.ids ),
            };
            // console.log( '🟥', courses, '\n', i, '\n', newCourses, '\n', courses.details[ i - 1 ] );

            user.deleteCourse(
                newCourses.details,
                newCourses.details.filter( course => isQueenHive( course ) ).map( course => course.id.db ),
                courses.details[ i - 1 ]
            );

            return newCourses
        } )
    }

    function filterCards({ target: { id } }) {
        setFilter( filter => {
            const opposite = { queen: worker, worker: queen };

            return filter !== all && id !== filter ? all : opposite[ id ]
        } )
    }

    function initiateState() {
        let details = orderCourses([ ...user.courses.queen.filter( e => e ), ...user.courses.worker ]);
        details = details.filter( e => e );

        return { details, ids: [ addButtonText, ...details.map( course => course.id.db ) ] }
    }

    function isQueenHive( course={} ) { return course?.ownerID === user.data.uid }

    function orderCourses( coursesArr=[] ) {
        return [
            ...get.alphabetiseObjArr( sortingKey, coursesArr.filter( course => isQueenHive( course ) ) ),
            ...get.alphabetiseObjArr( sortingKey, coursesArr.filter( course => !isQueenHive( course ) ) ),
        ];
    }

    // COMPONENTS
    const CourseCardList = courses.ids.map( ( dbID, i, arr ) => {
        const course = i > 0 ? courses.details[ i - 1 ] : dbID;

        const isFilteredOut = typeof course !== 'string'
            && ( ( !isQueenHive( course ) && filter === queen )
            || ( isQueenHive( course ) && filter === worker ) );

        if ( isFilteredOut ) return null
        const isValidCourse = typeof course !== 'string' || dbID === addButtonText;

        return isValidCourse && <CourseCard
            addCourse={ course === dbID ? addCourse : null }
            course={ course }
            currentBoard={ dbID.includes( 'course' ) ? {} : currentBoards[ i - 1 ] }
            deleteCourse={ deleteCourse }
            domain={ domain }
            key={ `${ dbID }-CourseCard` }
        />
    } );

    //RENDER
    return <div className={ styles.pane }>
        <nav className={ styles[ 'view-filter' ] }>
            <span>{ courses.ids[ 1 ] && 'filter :'}</span>
            { courses.ids[ 1 ] && <ul>
                { courses.details.find( course => isQueenHive( course ) ) && <li><Pill
                    label={`${ queen } hives`}
                    onClick={ filterCards }
                    activeState={ filter === queen || filter === all }
                /></li> }

                { courses.details.find( course => !isQueenHive( course ) ) && <li><Pill
                    gradientDuo={[ 'hsl( 30, 100%, 50% )', 'hsl( 48, 100%, 50% )' ]}
                    label={`${ worker } hives`}
                    onClick={ filterCards }
                    activeState={ filter === worker || filter === all }
                /></li> }
            </ul> }
        </nav>
        <div className={ styles[ 'courses-container' ] }>
            <ul className={ styles.courses }>{ CourseCardList }</ul>
        </div>
    </div>
}

export default CourseloadPane;