import { Timestamp } from 'firebase/firestore';
import courseInfo from './courseInfo';

function alphabetiseObjArr( key='', arr=[ {} ] ) {
    return arr.length < 2 ? arr : arr.sort( ( a, b ) => a[ key ].localeCompare( b[ key ] ) )
}

function arrWithElementAtI( i=0, arr=[], elementsToAdd=[], amountToDeleteAtI=0 ) {
    elementsToAdd = Array.isArray( elementsToAdd ) ? elementsToAdd : [ elementsToAdd ];
    arr = arr.slice();

    i === arr.length - 1 ? arr.push( ...elementsToAdd )
        : i === 0 ? arr.unshift( ...elementsToAdd )
        : arr.splice( i, amountToDeleteAtI, ...elementsToAdd );

    return arr
}

function arrWithoutElementAtI( i=0, arr=[] ) {
    return i === 0 ? arr.slice( 1 ) : [ ...arr.slice( 0, i ), ...arr.slice( i + 1 ) ]
}

function assignmentsArr( assignments={} ) {
    return Object.values( assignments ).map(
        arr => arr.filter( assignment => assignment.directions )
    ).flat()
}

function assignmentsByBoardNum( boardNum=0, assignments=[] ) { return assignments[ boardNum ] }

function assignmentID( boardNum=0, isPrereq=true, assignmentI=0 ) {
    return [
        'board',
        boardNum,
        isPrereq ? 'prereq' : 'req',
        assignmentI,
        assignmentI + ( 8 * boardNum )
    ].join( '-' )
}

function assignmentObj(
    assignmentNum = 0,
    boardNum = 0,
    isPrereq = false,
    category = 'assignment',
    points = 1,
    dueDayMonthYear = [],
    postedDayMonthYear = [],
    editedDayMonthYear = []
) {
    if ( !postedDayMonthYear[ 0 ] ) dayMonthYearToday()
    if ( !editedDayMonthYear[ 0 ] ) dayMonthYearToday()

    const assignment = {
        boardNum, category, isPrereq, points,
        directions: '',
        id: assignmentID( boardNum, isPrereq, assignmentNum ),
        isChecked: false,
        isGraded: !isPrereq,
        links: [],
        submissions: { timely: [], late: [], missing: [] },
        title: assignmentTitle( boardNum, assignmentNum ),
        date: { due: {}, posted: {}, edited: {} },
    };

    [ assignment.date.due.day, assignment.date.due.month, assignment.date.due.year ] = dueDayMonthYear;
    [ assignment.date.posted.day, assignment.date.posted.month, assignment.date.posted.year ] = postedDayMonthYear;
    [ assignment.date.edited.day, assignment.date.edited.month, assignment.date.edited.year ] = editedDayMonthYear;
    return assignment
}

function assignmentTitle ( boardNum=0, assignmentI=0 ) {
    const isDarkBox = assignmentI < 2;
    const [ isOnLeft, isOnRight, isOnTop ] = [
        isDarkBox ? assignmentI === 0 : ( assignmentI % 3 === 2 ),
        isDarkBox ? assignmentI === 1 : ( assignmentI % 3 === 1 ),
        assignmentI < 5
    ];

    const titlePredicate = ( isDarkBox ? [ isOnLeft ? 'Left' : 'Right', 'Dark' ] : [
        isOnTop ? 'Top' : 'Bottom',
        isOnLeft ? 'Left' : isOnRight ? 'Right' : 'Middle'
    ] ).join(' ')

    return [ `Week ${ boardNum + 1 } -`, titlePredicate, 'Box' ].join(' ')
}

function boardAssignments( course={}, boardNum=0, dueDate=[] ) {
    let assignments = course.content.assignments[ boardNum ];

    if ( !assignments ) {
        course.content.assignments[ boardNum ] = [];
        Array( 8 ).fill().forEach( ( e, i ) => course.content.assignments[ boardNum ].push( courseInfo.getAssignmentObj(
            i,
            boardNum,
            i > 1,
            i > 1 ? 'assignment' : 'assessment',
            i > 1 ? 0 : 1,
            dueDate
        ) ) );

        assignments = course.content.assignments[ boardNum ].filter( assignment => assignment.boardNum === boardNum );
    }

    return assignments.slice( 0, 8 );
}

function boardByWeekNum( weekNum=0, boards=[] ) { return boards.find( board => board.weekNum === weekNum ) }
function boardByBoardNum( boardNum=0, boards=[] ) { return boards.find( board => board.num === boardNum ) }

function boardCompletion( assignments=[] ) {
    const reorderedAssignments = [
        ...assignments.slice( 2, 5 ),
        assignments[ 0 ],
        { directions: 'middle box' },
        assignments[ 1 ],
        ...assignments.slice( 5 )
    ];

    return reorderedAssignments.map( assignment => !!assignment.directions )
}

function boardNum( year=1967, month=0, day=17, weekDay=2, user={}, courseID='', num=0 ) {
    const { quarters } = user.schoolYear;
    const { weeks } = user.schoolYear.months[ month ];

    if ( [ 0, 6 ].includes( weekDay ) ) {
        const increment = weekDay === 0 ? 1 : -1;
        [ year, month, day, weekDay ] = yearMonthDayWeekday( year, month, day + increment );
    }

    const weekNum = Object.keys( weeks ).find( n => weeks[ n ].days.includes( day ) );
    Object.keys( quarters ).forEach( quarterNum => {
        const [ quarter, course ] = [ quarters[ quarterNum ], user.courseload[ courseID ] ];
        let [ isCorrectYear, isCorrectMonth, isCorrectDay ] = [ year <= quarter.end.year, false, false ];
        if ( isCorrectYear ) isCorrectMonth = year === quarter.end.year ? month <= quarter.end.month : year < quarter.end.year
        if ( isCorrectMonth ) isCorrectDay = month === quarter.end.month ? day <= quarter.end.day : month < quarter.end.month

        if ( isCorrectYear && isCorrectMonth && isCorrectDay ) num = quarter.boards[ courseID ]
            .find( boardNumStr => course.boards[ boardNumStr ].weekNum === weekNum )
    } );

    if ( !num ) [ year, month, day, weekDay ] = yearMonthDayWeekday( year, month, day + 7 )
    return num ? num * 1 - 1 : boardNum( year, month, day, weekDay, user, courseID, num )
}

function calendarDayMonthYear(
    dayMonthYear = [ 17, 0, 1967 ],
    week = {},
    monthHolidays = {},
) {
    const date = new Date( ...dayMonthYear.slice().reverse() );
    const [ day, month ] = [ date.getDate(), date.getMonth() ];

    week.days.push( day );
    week.months.push( month );
    // if ( !monthHolidays?.days?.includes( day ) || !start.month || !end.month
    //     // || ( dayMonthYear[ 1 ] < start.month && dayMonthYear[ 1 ] > end.month )
    //     // || ( dayMonthYear[ 1 ] === start.month && dayMonthYear[ 0 ] < start.day )
    //     // || ( dayMonthYear[ 1 ] === end.month && dayMonthYear[ 0 ] > end.day )
    // ) week.daysInClass.push( day )
    // console.log( '🟥', week, day, month );

    return week.days.length === 5 ? day + 3 : calendarDayMonthYear(
        [ day + 1, month, dayMonthYear[ 2 ] ], week, monthHolidays
    )
}

function calendarMonth( mondayDayMonthYear=[ 17, 0, 1967 ], latestWeekNum=0, monthHolidays={}, locale='en-US' ) {
    const [ day, month, year ] = mondayDayMonthYear;

    const monthObj = {
        weeks: {},
        i: month,
        nextI: month === 11 ? 0 : ( month + 1 ),
        prevI: month === 0 ? 11 : ( month - 1 ),
        year: { full: year, short: `${ year }`.slice( 2 ) * 1 },
        name: {
            full: monthName( month, locale, false ),
            short: monthName( month, locale, true )
        },
        days: {
            odd: [],
            even: [],
            all: [],
            max: monthMaxDays( month, year ),
        },
    }

    return monthObj
}

function calendarMonths(
    start = {},
    end = {},
    holidays = {},
    locale = 'en-US',
    weekNum = 0,
    months = {},
    monthI = -1,
    year = -1
) {
    if ( monthI < 0 ) monthI = start?.month || 7
    if ( year < 0 ) year = start?.year || schoolYearID().split( ' - ' )[ 0 ]

    const firstMondayOfMonth = firstMondayOfMonthDayMonthYear( monthI, year );
    months[ monthI ] = calendarMonth( firstMondayOfMonth );
    const prevMonth = months[ months[ monthI ].prevI ];

    weekNum = calendarMonthWeeks(
        months[ monthI ],
        holidays[ monthI ],
        weekNum,
        firstMondayOfMonth,
        !prevMonth || prevMonth.days.even[ prevMonth.days.even.length - 1 ] === prevMonth.days.all[ prevMonth.days.all.length - 1 ],
        start,
        end
    );
    // console.log( 'ℹ️', weekNum );
    // console.log( months[ monthI ].name.full, months[ monthI ].year.full, months[ monthI ].weeks );
    // console.log( 'ℹ️', startingMonth, startingYear, locale, weekNum, firstMondayOfMonth, months )

    return Object.keys( months ).length === 12 ? months : calendarMonths(
        start, end, holidays, locale, weekNum, months,
        months[ monthI ].nextI,
        monthI === 11 ? ( year + 1 ) : year,
    )
}

function calendarMonthWeek(
    mondayDayMonthYear = [ 17, 0, 1967 ],
    monthHolidays = {},
    start = {},
    end = {}
) {
    const obj = {
        daysInClass: [],
        days: [],
        months: [],
    };

    const nextMondayDay = calendarDayMonthYear( mondayDayMonthYear, obj, monthHolidays );
    obj.days.forEach( ( day, i ) => {
        const month = obj.months[ i ];
        // console.log( month, start.month, end.month );
        const isOffDay = ( month < start.month && month > end.month )
            || ( month === start.month && day < start.day )
            || ( month === end.month && day > end.day );

        !isOffDay && obj.daysInClass.push( day );
    } )

    return [ obj, nextMondayDay ]
}

function calendarMonthWeeks(
    month = {},
    monthHolidays = {},
    weekNum = 0,
    mondayDayMonthYear = [],
    firstDayIsOdd = false,
    start = {},
    end = {},
) {
    const isOffMonth = month.i > end.month && month.i < start.month;
    const [ obj, nextMondayDay ] = calendarMonthWeek(
        mondayDayMonthYear,
        monthHolidays,
        start, end
    );

    month.weeks[ weekNum ] = obj;
    const monthIsComplete = nextMondayDay > month.days.max || (
        Object.keys( month.weeks ).length > 4 && nextMondayDay < 7
    );
    // console.log( 'next monday', nextMondayDay );
    // console.log( nextMondayDay, '>', month.days.max, '?' );
    // console.log( `${ month.name.full } weeks are${ monthIsComplete ? '' : 'NOT' } full` );

    if ( monthIsComplete ) {
        // console.log( `There is ${ isOffMonth ? 'NO ' : '' }school in ${ month.name.full }` );
        // const [ aDay, bDay ] = firstDayIsOdd ? [ 'odd', 'even' ] : [ 'even', 'odd' ];

        month.days.all = isOffMonth ? [] : Object.values( month.weeks )
            .map( weekObj => weekObj.daysInClass.filter(
                ( day, i ) => weekObj.months[ i ] === month.i
            ) ).flat( 2 );

        oddAndEvenDays( month, firstDayIsOdd );

        // month.days.all.forEach( ( dayNum, i ) => month.days[ i % 2 ? bDay : aDay ].push( dayNum ) );
    }
    // console.log( month.weeks );

    return !monthIsComplete ? calendarMonthWeeks(
        month,
        monthHolidays,
        weekNum + 1,
        [ nextMondayDay, month.i, month.year.full ],
        firstDayIsOdd,
        start,
        end
    ) : month.weeks[ weekNum ].months.includes( month.nextI ) ? weekNum
        : weekNum + 1
}

function capitalise( textArr=[ '' ] ) {
    if ( typeof textArr === 'string' ) textArr = textArr.includes(' ') ? textArr.split(' ') : [ textArr ]

    return textArr.map(
        str => !str ? str : str.trim()[ 0 ].toLocaleUpperCase() + str.trim().slice( 1 ).toLocaleLowerCase()
    ).join(' ')
}

function classNameFromArr( arr=[], delimeter=' ' ) { return arr.filter( e => e ).join( delimeter ) }

function colourFromName( colour={
    black: false,
    grey: false, darkgrey: false, lightgrey: false, palegrey: false, gray: false, darkgray: false, lightgray: false, palegray: false,
    white: false,
    pink: false, palevioletred: false,
    red: false, darkred: false, maroon: false,
    orange: false, coral: false, orangered: false, darkorange: false,
    brown: false, sienna: false,
    gold: false, goldenrod: false,
    green: false, darkgreen: false, forestgreen: false,
    teal: false, darkcyan: false,
    blue: false, darkslateblue: false, darkblue: false, navy: false, mediumblue: false, slateblue: false, steelblue: false, deepskyblue: false,
    purple: false, indigo: false,
}) {
    const [ colourName, colours ] = [ Object.keys( colour ).find( key => colour[ key ] ), {
        black: [ 0, 0, 0 ], // ⚫️
        grey: [ 0, 0, 50 ], // 🪙
        gray: [ 0, 0, 50 ],
        darkgrey: [ 0, 0, 40 ], // actually dimgray
        darkgray: [ 0, 0, 40 ], // actually dimgray
        lightgrey: [ 0, 0, 66 ], // actually darkgray
        lightgray: [ 0, 0, 66 ], // actually darkgray
        palegrey: [ 0, 0, 83 ], // actually lightgray
        palegray: [ 0, 0, 83 ], // actually lightgray
        white: [ 0, 0, 100 ], // ⚪️
        pink: [ 350, 100, 88 ], // 🎀
        palevioletred: [ 340, 60, 65 ],
        red: [ 0, 100, 50 ], // 🔴
        darkred: [ 0, 100, 27 ],
        maroon: [ 0, 100, 25 ],
        orange: [ 39, 100, 50 ], // 🟠
        orangered: [ 16, 100, 50 ],
        coral: [ 16, 100, 66 ],
        darkorange: [ 33, 100, 50 ],
        brown: [ 25, 76, 31 ], // actually saddleBrown 🟤
        sienna: [ 19, 56, 40 ],
        gold: [ 50, 100, 50 ], // 🟡
        goldenrod: [ 43, 74, 49 ],
        green: [ 120, 100, 25 ], // 🟢
        darkgreen: [ 120, 100, 20 ],
        forestgreen: [ 120, 61, 34 ],
        teal: [ 180, 100, 25 ], // 🩱
        darkcyan: [ 180, 100, 27 ],
        blue: [ 240, 100, 50 ], // 🔵
        darkslateblue: [ 248, 39, 39 ],
        darkblue: [ 240, 100, 27 ],
        navy: [ 240, 100, 27 ],
        mediumblue: [ 240, 100, 40 ],
        slateblue: [ 248, 53, 58 ],
        steelblue: [ 207, 44, 49 ],
        deepskyblue: [ 195, 100, 50 ],
        purple: [ 300, 100, 25 ], // 🟣
        indigo: [ 275, 100, 25 ],
    }];

    return colourName ? colours[ colourName ] : colours
}

function colourName( hue=0 ) {
    return hue < 20 ? '🔴 RED'
    : hue <= 45 ? '🟠 ORANGE'
    : hue <= 65 ? '🟡 YELLOW'
    : hue <= 165 ? '🟢 GREEN'
    : hue <= 255 ? '🔵 BLUE'
    : hue <= 295 ? '🟣 PURPLE'
    : hue <= 340 ? '💞 PINK' : '🔴 RED';
}

function courseFromLevelAndSubject( level=1, subject='', courses=[{}] ) {
    return courses.find( course => course.subject === subject && `${ course.level }` === `${ level }` )
}

function currentWeekNum( months={} ) {
    let [ year, month, day, weekday ] = yearMonthDayWeekday();
    if ( [ 0, 6 ].includes( weekday ) ) [ year, month, day, weekday ] = yearMonthDayWeekday(
        year, month, day + ( weekday === 0 ? 1 : 2 )
    )

    // console.log( '🟥', `${ weekdayName( weekday ) } ${ months[ month ].name.full } the ${ get.ordinalNum( day ) }, ${ year }` );
    // console.log( '🟥', months[ month ].weeks );

    const weekNum = Object.entries( months[ month ].weeks ).find( entry => {
        const week = entry[ 1 ];
        const dayIndex = week.days.findIndex( dayNum => dayNum === day );

        if ( dayIndex < 0 ) return false
        return week.months[ dayIndex ] === month
    } )?.[ 0 ] * 1;

    log({
        fileName: 'get.js',
        functionDirectionsOrComponentName: 'currentWeekNum',
        isFunction: true,
        hasEmoji: true,
        lineNumber: 406,
        str: `📆 WEEK #${ weekNum }`,
        format: { isNeeded: true }
    })
    // console.log( 'WEEK #:', weekNum );


    return typeof weekNum === 'number' ? weekNum
        : typeof weekNum === 'string' ? weekNum * 1
        : 0
}

function date(
    date = {},
    locale = 'en-US',
    alphaMonth = false,
    dayFirst = true,
    delimiter = '.',
    showYear = false,
) {
    let { day, month, year, hour, minutes } = date;
    // month--;

    // const locales = [ 'en-US', 'en-GB', 'fr-FR'];
    const isCustomDate = true;

    let newDate = new Date( ...[ year, month, day, hour, minutes ].filter( e => e !== undefined) );
    // console.log( newDate, date );

    let [ options, dateStyle, customDate ] = [
        {}, {
            dateStyle: 'medium'
        }, {  // full, long, medium, short
            weekday: 'short', // long, short, narrow
            // year: '2-digit', // numeric, 2-digit
            month: 'short', // numeric, 2-digit, long, short, narrow
            day: 'numeric', // numeric, 2-digit
        }, // hide any of these and they won't appear
    ];

    if ( hour !== undefined ) {
        options = {
            hour12: true, // true, false
            hourCycle: 'h23', //h11, h12, h23, h24 (overriden if hour12 is present)
        };
        customDate.hour = 'numeric';
        customDate.minute = '2-digit';
    }

    options = { ...options, ...( isCustomDate ? customDate : dateStyle ) }

    // const [ first, second, third ] = [
    //     dayFirst ? this.date.day : this.date.month,
    //     dayFirst ? this.date.month : this.date.day,
    //     showYear && this.date.year,
    // ];
    // return this.date.day + delimiter + this.date.month
    return newDate.toLocaleDateString( locale, options )
}

function dateOfWeekdayOfTheMonth( weekDay={
    monday: false, tuesday: false, wednesday: false, thursday: false, friday: false, saturday: false, sunday: false
}, monthNum=-1, year=1967, isForFirstWeekday=false ) {
    if ( year === 1967 || monthNum === -1 ) {
        const dateArr = dayMonthYearToday();
        if ( year === 1967 ) year = dateArr[ 2 ]
        if ( monthNum < 0 ) monthNum = dateArr[ 1 ]
    }

    let [ dateNum, weekDayNum ] = [ 1, 1 ];
    if ( isForFirstWeekday ) dateNum = monthMaxDays( monthNum, year )

    let date = new Date( year, monthNum, dateNum );
    switch ( Object.keys( weekDay ).filter( day => weekDay[ day ] )[ 0 ] ) {
        case 'monday': weekDayNum = 1; break;
        case 'tuesday': weekDayNum = 2; break;
        case 'wednesday': weekDayNum = 3; break;
        case 'thursday': weekDayNum = 4; break;
        case 'friday': weekDayNum = 5; break;
        case 'saturday': weekDayNum = 6; break;
        case 'sunday': weekDayNum = 0; break;
        default: break;
    }

    while( date.getDay() !== weekDayNum ) {
        dateNum--;
        date = new Date( year, monthNum, dateNum )
    }

    return dateNum
}

function dateTime(
    date=new Date(),
    locale='',
    getDay=true,
    getTime=false,
    options={ date: { month: 'short', day: 'numeric' }, time: { timeStyle: 'short' } }
) {
    date = date.seconds ? new Timestamp( date.seconds, date.nanoseconds ).toDate() : new Date( date );
    const [ day, time ] = [
        getDay ? date.toLocaleDateString( locale, options.date ) : '',
        getTime ? date.toLocaleTimeString( locale, options.time ) : ''
    ];
    // console.log( '⏰', day, time );

    return [ day, time ].join(', ')
}

function firstMondayOfMonthDayMonthYear( monthNum=0, year=1967 ) {
    if ( year === 1967 ) year = new Date().getFullYear()

    const date = new Date( year, monthNum, 1 );
    let [ day, weekday ] = [ date.getDate(), date.getDay() ];

    day -= weekday === 1 ? 0
        : weekday === 0 ? -1
        : weekday === 6 ? -2
        : weekday - 1
    // const mondayDate = new Date( year, monthNum, day );

    return [ day, monthNum, year ]
}

function dayMonthYearToday() {
    const today = new Date();
    return [ today.getDate(), today.getMonth(), today.getFullYear() ]
}

function dayMonthYearArr( dayMonthYear=[], isStartingYear=false, inReverseOrder=false ) {
    const [ len, year ] = [
        dayMonthYear.length,
        schoolYearID().split( ' - ' )[ isStartingYear ? 0 : 1 ] * 1
    ];

    if ( len >= 2 ) {
        const arr = len === 3 ? dayMonthYear : [ ...dayMonthYear, year ];
        return inReverseOrder ? arr.reverse() : arr
    }

    const month = isStartingYear ? 7 : 5; // august if it's the year start, else june
    if ( len === 1 ) return dayMonthYearArr( [ ...dayMonthYear, month ], isStartingYear, inReverseOrder )

    return dayMonthYearArr([
        isStartingYear ? dateOfWeekdayOfTheMonth( { monday: true }, month, year, true )
            : dateOfWeekdayOfTheMonth( { friday: true }, month, year, false )
    ], isStartingYear, inReverseOrder )
}

function defaultSyllabus() {
    return {
        rules: {},
        assignmentCategories: {
            assignment: {
                defaultPoints: 1,
                weight: .5
            },
            assessment: {
                defaultPoints: 1,
                weight: .2
            },
            application: {
                defaultPoints: 1,
                weight: .3
            },
        }
    }
}

function dueDayMonthYear( months={}, board={} ) {
    const date = new Date(
        months[ board.monthNum ].year.full,
        board.monthNum,
        months[ board.monthNum ].weeks[ board.weekNum ].daysInClass.slice().pop()
    );

    return [ date.getDate(), date.getMonth(), date.getFullYear() ]
}

function emptyAssignmentIndexInArr( assignments=[], startingI=0 ) {
    for ( let assignmentI = startingI; assignmentI < assignments.length; assignmentI++ ) {
        if ( !assignments[ assignmentI ].directions ) return assignmentI
    }

    return -1
}

function holidayEmoji( holidayReason='' ) {
    holidayReason = holidayReason.toLocaleLowerCase();

    return holidayReason.includes( 'winter' ) ? '❄️'
        : holidayReason.includes( 'christmas' ) ? '🎄'
        : [ 'election', 'inauguration', 'independence', 'primary' ].some(
            e => holidayReason.includes( e )
        ) ? '🇺🇸'
        : holidayReason.includes( 'mlk' ) ? '💭'
        : holidayReason.includes( 'orthodox' ) ? '☦'
        : holidayReason.includes( 'president' ) ? '🏛️'
        : holidayReason.includes( 'spring' ) ? '🌸'
        : [ 'memorial', 'veteran' ].some( e => holidayReason.includes( e ) ) ? '🎗️'
        : holidayReason.includes( 'summer' ) ? '☀️'
        : holidayReason.includes( 'labor' ) ? '💪'
        : [ 'kippur', 'rosh', 'kah', 'passover' ].some( e => holidayReason.includes( e ) ) ? '✡️'
        : [ 'eid', 'ramad' ].some( e => holidayReason.includes( e ) ) ? '☪️'
        : holidayReason.includes( 'diwali' ) ? '🕉️'
        : holidayReason.includes( 'indigenous' ) ? '🪶'
        : holidayReason.includes( 'thanksgiving' ) ? '🦃' : '🧑‍🏫'
}

function hslFromHex( hex='' ) {
    if ( !hex ) return
    // console.log( '🟥', hex );

    let [ r, g, b ] = rgbFromHex( hex );
    let [ min, max ] = [ Math.min(r, g, b), Math.max(r, g, b) ];

    let h, s, l = ( max + min ) / 2;

    if ( max === min ){
        h = s = 0; // achromatic

    } else {
        const d = max - min;
        s = l > .5 ? d / ( 2 - max - min ) : d / ( max + min );

        switch( max ) {
            case r: h = ( g - b ) / d + ( g < b ? 6 : 0 ); break;
            case g: h = ( b - r ) / d + 2; break;
            case b: h = ( r - g ) / d + 4; break;
            default: window.location.hostname === 'localhost' && console.log( 'Sorry; there is no R, G, or B value' );
        }

        h /= 6;
    }

    h = `${ Math.round( h * 360 ) }`;
    s = `${ Math.round( s * 100 ) }%`;
    l = `${ Math.round( l * 100 ) }%`;

    return { h, s, l };
}

function hslRange( startingHSL={}, numColours=5, range=[] ) {
    if ( range.length === numColours ) return range

    const hues = hueRange();
    const nextHueObj = Object.values( hues )
        .find( hueObj => hues[ hueObj.prev ].isHue( startingHSL.h ) );

    return hslRange(
        { ...startingHSL, h: nextHueObj.randomHue() },
        numColours,
        [ ...range, startingHSL ]
    )
}

function hexFromHSL( h=360, s=100, l=50 ) {
    if ( typeof s === 'string' ) s = s.split( '%' )[ 0 ] * 1
    if ( typeof l === 'string' ) l = l.split( '%' )[ 0 ] * 1
    // console.log( h, s, l );

    l /= 100;
    const a = s * Math.min( l, 1 - l ) / 100;
    const f = n => {
        const k = ( n + h / 30 ) % 12;
        const colour = l - a * Math.max(
            Math.min( k - 3, 9 - k, 1 ), -1
        );

        return Math.round( 255 * colour )
            .toString( 16 )
            .padStart( 2, '0' );   // convert to Hex and prefix "0" if needed
    };

    const hex = `#${ f( 0 ) }${ f( 8 ) }${ f( 4 ) }`;
    // console.log( hex );
    return hex
}

function hslFromName( colourName='', returnPercentages=false ) {
    const colours = colourFromName();

    return Object.fromEntries( ( colours[ colourName ] || colours.black ).map( ( num, i ) => [
        i === 0 ? 'h' : i === 1 ? 's' : 'l',
        ( returnPercentages && i > 0 ) ? num + '%' : num
    ] ) )
}

function hueToRGB( p=0, q=0, t=0 ) {
    t += t < 0 ? 1 : t > 1 ? -1 : 0;

    if ( t < 1/6 ) return p + ( q - p ) * 6 * t
    if ( t < 1/2 ) return q
    if ( t < 2/3 ) return p + ( q - p ) * ( 2/3 - t ) * 6
    return p
}

function hueRange() {
    const rangeMax = {
        20: 'red',
        40: 'orange',
        65: 'yellow',
        90: 'yellow-green',
        160: 'green',
        185: 'cyan',
        200: 'light-blue',
        250: 'blue',
        295: 'purple',
        340: 'pink',
    };

    const entries = Object.fromEntries(
        Object.entries( rangeMax ).map( ( entry, i, arr ) => {
            const isLastArrElement = i === arr.length - 1;
            const [ prevI, nextI ] = [
                ( i === 0 ? arr.length : i ) - 1,
                isLastArrElement ? 0 : i + 1,
            ];

            const rangeMin = arr[ prevI ][ 0 ] * 1;
            let [ rangeMax, colour ] = entry;
            rangeMax *= 1;

            return [ colour, {
                prev: arr[ prevI ][ 1 ],
                next: arr[ nextI ][ 1 ],
                min: rangeMin,
                max: rangeMax,
                randomHue() {
                    return i === 0 ? (
                        randomNum( rangeMax, false, 0 )
                        || randomNum( 360, true, rangeMin )
                    ) : randomNum( rangeMax, false, rangeMin )
                },
                isHue( hue = 0 ) {
                    return i === 0 ? (
                        isWithinRange( hue, 0, rangeMax, true, false )
                        || isWithinRange( hue, rangeMin, 360, true, true )
                    ) : isWithinRange( hue, rangeMin, rangeMax, true, false )
                }
            } ]
        } )
    );

    return entries
}

function isWithinRange( num=0, min=0, max=100, minInclusive=false, maxInclusive=false ) {
    const [ isGreaterThanMin, isLessThanOrEqualToMax ] = [
        minInclusive ? num >= min : num > min,
        maxInclusive ? num <= max : num < min,
    ];

    return isGreaterThanMin && isLessThanOrEqualToMax
}

function lastArrayElement( arr=[] ) { return arr[ arr.length - 1 ] }

function log({
    fileName = '',
    functionDirectionsOrComponentName = '',
    hasEmoji = false,
    isFunction = false,
    isComponent = false,
    lineNumber = 0,
    str = '',
    format = { str: '', isNeeded: false, isTrueFalseToggle: false },
    bool = { trueFalse: false }
}) {
    if ( window.location.hostname === 'localhost' ) {
        if ( format.str ) format.isNeeded = true
        if ( !isComponent && !isFunction ) isComponent = typeof str === 'object' && 'key' in str

        const [ checkpointStr, file, line, loggedStr ] = [
            isFunction ? functionDirectionsOrComponentName + '()'
                : isComponent ? `<${ functionDirectionsOrComponentName } />`
                : [ '--', functionDirectionsOrComponentName, '--' ].join(' '),
            fileName ? `📄 ${ fileName }\n` : '',
            lineNumber ? `line #${ lineNumber }\n\n` : '',
            [ '💬' ]
        ];

        const [ addendums, checkpoint, isObject ] = [
            [], functionDirectionsOrComponentName ? `🏁 ${ checkpointStr }\n` : '', !isComponent && typeof str === 'object'
        ];

        if ( isComponent || isObject ) addendums.push( str )
        if ( format.isNeeded ) {
            loggedStr.push( '%c' );

            if ( format.isTrueFalseToggle && !hasEmoji ) loggedStr.push( bool.trueFalse ? '🟢' : '❌' )
            if ( typeof str === 'string' ) loggedStr.push( typeof str === 'string' ? str : isComponent ? '%O' : '%o' )

            addendums.unshift( format.str || `background: white; color: ${ bool.trueFalse ? 'green' : 'red' }; border: 1.5px solid ${ bool.trueFalse ? 'yellowgreen' : 'red' }; border-radius: 1em; padding: .5em 1em` );
        }

        // LOG
        if ( str ) {
            console.log(`ℹ️\nHOST: ${ window.location.host }\nHOSTNAME: ${ window.location.hostname }\nPORT: ${ window.location.port }\nPROTOCOL: ${ window.location.protocol }\nPATHNAME: ${ window.location.pathname }\nSEARCH: ${ window.location.search }\nHASH: ${ window.location.hash }\nHREF: ${ window.location.href }\n`);

            console.log(`${ file }${ checkpoint }${ line }${ loggedStr.join(' ') }`, ...addendums );
        }

    } else { console.log(`ℹ️\nHOST: ${ window.location.host }\nHOSTNAME: ${ window.location.hostname }\nPORT: ${ window.location.port }\nPROTOCOL: ${ window.location.protocol }\nPATHNAME: ${ window.location.pathname }\nSEARCH: ${ window.location.search }\nHASH: ${ window.location.hash }\nHREF: ${ window.location.href }\n`); }
}

function monthMaxDays( monthNum=-1, year=1967 ) {
    const isLeapYear = () => ( year % 4 === 0 ) && ( year % 100 === 0 || year % 400 === 0 )

    if ( year === 1967 || monthNum < 0 ) {
        const dateArr = dayMonthYearToday();
        if ( year === 1967 ) year = dateArr[ 2 ]
        if ( monthNum < 0 ) monthNum = dateArr[ 1 ]
    }

    return [ 0, 2, 4, 6, 7, 9, 11 ].includes( monthNum ) ?
        31 : [ 3, 5, 8, 10 ].includes( monthNum ) ?
        30 : isLeapYear() ? 29 : 28
}

function monthName( monthNum=0, locale='en-US', isShort=false ) {
    const date = new Date( 2023, monthNum, 1 );

    return date.toLocaleDateString( locale, { month: isShort ? 'short' : 'long' } );
}

function monthNumsInOrderArr( schoolYear={} ) {
    const { start: { month: firstMonth }, end: { month: lastMonth }, months } = schoolYear;

    const moveToNextMonth = ( monthNum=0, monthNums=[], i=0 ) => (
        i === 11 || ( i > 0 && monthNum === lastMonth + 1 )
    ) ? monthNums : moveToNextMonth( months[ monthNum ].nextI, [ ...monthNums, monthNum ], i + 1 )

    return moveToNextMonth( firstMonth )
}

function nextOrPreviousArrayElement( arr=[], currentI=0, getNext=false ) {
    const i = getNext ? (
        i === ( arr.length - 1 ) ? 0 : currentI + 1
    ) : ( i === 0 ? arr.length : currentI ) - 1;

    return arr[ i ]
}

function numRange( min=0, max=28, range=[] ) {
    return min <= max ? numRange(
        min + 1, max, [ ...range, min ]
    ) : range
}

function object( entries=[ [ 'key', 'value' ] ] ) {
    return Object.fromEntries( entries )
}

function oddAndEvenDays( month={}, startWithOddDay=false, holidayDayNum=-1 ) {
    if ( holidayDayNum >= 0 ) startWithOddDay = month.days.odd.includes( holidayDayNum );
    const [ aDay, bDay ] = startWithOddDay ? [ 'odd', 'even' ] : [ 'even', 'odd' ];

    if ( Array.isArray( holidayDayNum ) || holidayDayNum >= 0 ) {
        const isHolidayArr = Array.isArray( holidayDayNum );
        [ month.days.odd, month.days.even ] = [ [], [] ];

        month.days.all = isHolidayArr ? month.days.all.filter(
            dayNum => !holidayDayNum.includes( dayNum )
        ) : month.days.all.filter( dayNum => holidayDayNum === dayNum );
    }

    month.days.all.forEach( ( dayNum, i ) => month.days[ i % 2 ? bDay : aDay ].push( dayNum ) )
}

function ordinalNum( num=0, lang='en' ) {
    return num + ordinalSuffix( num, lang )
}

function ordinalSuffix( num=0, lang='en', isMasc=true ) {
    const n = ( lang === 'en' && num < 21 ) ? num * 1 : num % 10;
    const suffixes = {
        en: ( n < 1 || n > 3 ) ? 'th'
            : n === 3 ? 'rd'
            : n === 2 ? 'nd' : 'st',
        fr: num !== 1 ? 'e' : isMasc ? 'er' : 're',
        es: isMasc ? 'o' : 'a',
    }

    return suffixes[ lang ]
}

function quarterWeekNums( quarterObj={}, monthsArr=[] ) {
    let weekNumsHasFirstWeek = false;
    let [ dayStart, dayEnd, monthStart, monthEnd, weekNums ] = [
        quarterObj.start.day, quarterObj.end.day,
        quarterObj.start.month, quarterObj.end.month,
        []
    ];

    monthsArr = monthEnd > monthStart ? monthsArr.slice( monthStart, monthEnd + 1 )
        : [ ...monthsArr.slice( monthStart ), ...monthsArr.slice( 0, monthEnd + 1 ) ];

    const [ weekdayStart, weekdayEnd ] = [
        new Date( quarterObj.start.year, monthStart, dayStart ).getDay(),
        new Date( quarterObj.end.year, monthEnd, dayEnd ).getDay(),
    ];

    if ( [ 0, 6 ].includes( weekdayStart ) ) {
        const newStartDate = new Date( quarterObj.start.year, monthStart, dayStart + ( weekdayStart === 0 ? 1 : 2 ) );
        [ dayStart, monthStart ] = [ newStartDate.getDate(), newStartDate.getMonth() ];
    }

    if ( [ 0, 6 ].includes( weekdayEnd ) ) {
        const newEndDate = new Date( quarterObj.end.year, monthEnd, dayEnd + ( weekdayEnd === 0 ? 1 : 2 ) );
        [ dayEnd, monthEnd ] = [ newEndDate.getDate(), newEndDate.getMonth() ];
    }

    for ( let monthObj of monthsArr ) {
        const weeks = Object.entries( monthObj.weeks );

        for ( let week of weeks ) {
            const [ weekNum, weekObj ] = week;

            if ( monthObj.i === monthStart && weekObj.days.includes( dayStart ) ) {
                weekNumsHasFirstWeek = true;
                weekNums.push( weekNum * 1 );

            } else if ( monthObj.i === monthEnd && weekObj.days.includes( dayEnd ) ) {
                weekNums.push( weekNum * 1 );
                break;

            } else if ( weekNumsHasFirstWeek ) { weekNums.push( weekNum * 1 ) }
        }
    }

    return weekNums.filter( ( numStr, i, arr ) => arr.findIndex( e => e === numStr ) === i )
}

function randomHSL( asCSSString=true, hueMin=15, hueMax=345 ) {
    const [ h, s, l, opacity ] = [
        randomNum( hueMax, true, hueMin ),
        `${ randomNum( 90, true, 75 ) }%`,
        `${ randomNum( 75, true, 50 ) }%`,
        1
    ];

    return !asCSSString ? { h, s, l } : `hsl( ${ h }, ${ s }, ${ l }, ${ opacity } )`
}

function randomNum( max=10, maxInclusive=false, min=0 ) {
    // fixing issues in case min > max
    // -OR- if they are strings and/or floats
    [ max, min ] = min < max ? [ max * 1, min * 1 ]
        : [ min * 1, max * 1 ];
    [ max, min ] = [ Math.round( max ), Math.round( min ) ];
    max += maxInclusive ? 1 : 0;

    return Math.floor( Math.random() * ( max - min ) + min )
}

function randomToken( getDatabaseArray=false ) {
    const list = tokenList( null );

    const listKeys = Object.keys( list );
    const category = listKeys[ 1 ] ? randomNum( listKeys.length, false, 0 ) : listKeys[ 0 ];

    const subcategoryListKeys = Object.keys( list[ category ] );
    const subcategory = subcategoryListKeys[ 1 ] ? subcategoryListKeys[ randomNum( subcategoryListKeys.length, false, 0 ) ]
        : subcategoryListKeys[ 0 ];

    const tokenArr = list[ category ][ subcategory ];
    const i = randomNum( tokenArr.length, false, 0 );
    const databaseArray = [ null, subcategory.includes( 'emoji' ), category, i ];

    return getDatabaseArray ? databaseArray : token( ...databaseArray )
}

function reorderObject( units=[], themes={}, schoolYear={}, newArr=[] ) {
    units = units.filter( unit => 'background' in unit );
    const { titles } = themes;

    while ( units.length < titles.length ) {
        const i = units.length;
        units.push({
            background: {
                hex: themes.backgrounds[ i ],
                ...hslFromHex( themes.backgrounds[ i ] )
            },
            blocks: themes.blocks[ i ] * 1,
            days: { even: {}, odd: {} },
            theme: themes.titles[ i ],
        });
    }

    return updateObjectProps( themes, units.slice(), schoolYear )
}

function rgbFromHex( hex='' ) {
    if ( !hex ) return []
    const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec( hex );

    return Array( 3 ).fill().map( ( e, i ) => parseInt( result[ i + 1 ], 16 ) / 255 )
}

function schoolYearID( startingMonth=7, differenceFromCurrentSchoolYear=0 ) {
    const today = new Date();
    const [ month, year ] = [ today.getMonth(), today.getFullYear() + differenceFromCurrentSchoolYear ];
    const startingYear = month >= startingMonth ? year : year - 1;

    return [ startingYear, startingYear + 1 ].join(' - ')
}

// function schoolYearRange( blocks=1, schoolYear={} ) {}

function stringFromArray( arr=[ '' ] ) {
    const [ lastString, penultimateI ] = [ arr.pop(), arr.length ];

    return arr[ 1 ] ? [ arr.slice( 0, penultimateI ).join( ', ' ), lastString ].join( '& ' )
        : arr[ 0 ] ? [ arr[ 0 ], lastString ].join( '& ' ) : lastString
}

function stringInNewFormat(
    str='',
    delimeter='-',
    newFormat={ dashed: false, camelCase: false, titleCase: false }
) {
    const newDelimeter = newFormat.dashed ? '-' : newFormat.titleCase ? ' ' : '';
    return newDelimeter === delimeter ? str : str.toLocaleLowerCase()
        .split( delimeter )
        .map( ( e, i ) => ( newFormat.titleCase || ( newFormat.camelCase && i > 0 ) ? capitalise( e ) : e )  )
        .join( newDelimeter )
}

function subjectIcon( subject='', isForDepartment=false ) {
    subject = subject.toLocaleLowerCase();
    let [ emoji, department ] = [ '', '' ];
    const translatedSubjects = [ 'maths', 'english', 'social studies', 'computer science', 'sciences', 'world languages' ];

    const languages = {
        asl: {
            arr: [ 'asl' ],
            department: 'world languages',
            emoji: '🤟',
        },
        french: {
            arr: [ 'french', 'français', 'francais' ],
            department: 'world languages',
            emoji: '🇫🇷',
        },
        german: {
            arr: [ 'german', 'deutsch' ],
            department: 'world languages',
            emoji: '🇩🇪',
        },
        japanese: {
            arr: [ 'japanese', '日本語', 'nihongo' ],
            department: 'world languages',
            emoji: '🇯🇵',
        },
        korean: {
            arr: [ 'korean', '한국어', 'hangugeo' ],
            department: 'world languages',
            emoji: '🇰🇷',
        },
        latin: {
            arr: [ 'latin', 'latīna', 'latina', 'latīnus', 'latinus' ],
            department: 'world languages',
            emoji: '🕊️',
        },
        mandarin: {
            arr: [ 'mandarin', '普通话', 'chinese', 'pǔtōnghuà','putonghua' ],
            department: 'world languages',
            emoji: '🇨🇳',
        },
        spanish: {
            arr: [ 'spanish', 'español', 'espanol' ],
            department: 'world languages',
            emoji: '🇪🇸',
        },
    };

    const subjects = [
        [ 'math', 'algebra', 'calculus', 'geometry', 'trigonometry' ],
        [ 'esol', 'second language', 'english', 'poet', 'writing', 'composition' ],
        [ 'history', 'geography', 'social studies', 'psychology', 'sociology' ],
        [ 'computer', 'programming', 'system', 'oracle', 'java', 'python', 'sql', 'css' ],
        [ 'science', 'chem', 'bio', 'neuro', 'physic', 'astro' ],
    ];

    const getLanguageEmoji = () => Object.values( languages ).find( obj => obj.arr.includes( subject ) )?.emoji || '🫥'
    const getSubjectEmoji = ( arr=[], i=0 ) => arr[ subjects[ i ].findIndex( subj => subject.includes( subj ) ) ]

    let departmentI = translatedSubjects[ subjects.findIndex( arr => arr.includes( subject ) ) ];
    // if ( departmentI < 0 ) {
    //     department = lastArrayElement( translatedSubjects );
    // } else { department = translatedSubjects[ departmentI ] }

    if ( isForDepartment ) {
        emoji = departmentI === 0 ? '⨸'
            : departmentI === 1 ? '📖'
            : departmentI === 2 ? '🌍'
            : departmentI === 3 ? '💻'
            : departmentI === 4 ? '🔬'
            : '🗣️';
    } else {
        emoji = departmentI === 0 ? getSubjectEmoji( [ '⨸', '⎰x', '⎰x', '📐', '📐' ], departmentI )
            : departmentI === 1 ? getSubjectEmoji( [ '🗣️', '🗣️', '📖', '📖', '📖', '📖' ], departmentI )
            : departmentI === 2 ? getSubjectEmoji( [ '⏳', '🌍', '👥', '🧠', '👥' ], departmentI )
            : departmentI === 3 ? '💻'
            : departmentI === 4 ? getSubjectEmoji( [ '🔬', '🧪', '🧬', '🧠', '🧲', '🪐' ], departmentI )
            : getLanguageEmoji();
    }

    return emoji
}

function textColour( hex='#000', printOut=false ) {
    let [ r, g, b ] = Array( 3 ).fill( 0 );
    let [ hexName, shouldBeWhite ] = [ '', false ];
    // const contrastThreshold = 186; // Standard recommendation
    // const contrastThreshold = 150; // Alternative internet preference
    const contrastThreshold = 160;

    if ( typeof hex === 'string' && hex.startsWith( '#' ) ) {
        [ r, g, b ] = [ 1, 3, 5 ].map( i => parseInt( hex.slice( i, i + 2 ), 16 ) );
        shouldBeWhite = [ .299, .587, .114 ].reduce(
            ( total, multiplier, i ) => ( [ r, g, b ][ i ] * multiplier ) + total , 0
        ) <= contrastThreshold;
        // shouldBeWhite = ( ( r * .299 ) + ( g * .587 ) + ( b * .114 ) ) >= contrastThreshold;

    } else if ( 'h' in hex ) {
        let { h, s, l } = hex;
        [ s, l ] = [ s.split( '%' )[ 0 ] * 1, l.split( '%' )[ 0 ] * 1 ];
        const [ isNeitherGreenNorYellow, isDarkish, isVerySaturated ] = [
            h < 60 && h > 120,
            l <= 60,
            ( h >= 215 && h <= 285 ) ? 90 : 85
        ];

        shouldBeWhite = isNeitherGreenNorYellow && isDarkish && isVerySaturated;
        window.location.hostname === 'localhost' && printOut && console.log( `${ isNeitherGreenNorYellow ? '✅' : '❌' } NOT green/yellow`, `\n${ isDarkish ? '✅' : '❌' } DARK-ish`, `\n${ isVerySaturated ? '✅' : '❌' } SATURATED` );
        hexName = colourName( h );

        // STANDARD RECOMMENDATION
        // const [ hDecimal, sDecimal, lDecimal ] = [ h / 100, s / 100, l / 100 ];
        // if ( s === 0 ) {
        //     r = g = b = lDecimal
        // } else {
        //     let q = lDecimal < .5 ? lDecimal * ( 1 + sDecimal )
        //     : lDecimal + sDecimal - lDecimal * sDecimal;
        //     let p = 2 * lDecimal - q;

        //     [ r, g, b ] = [
        //         hueToRGB( p, q, hDecimal + 1/3 ) * 255,
        //         hueToRGB( p, q, hDecimal ) * 255,
        //         hueToRGB( p, q, hDecimal - 1/3 ) * 255
        //     ];
        // }

        // shouldBeWhite = ( ( r * .299 ) + ( g * .587 ) + ( b * .114 ) ) >= contrastThreshold;
    }

    window.location.hostname === 'localhost' && printOut && console.log( '🐝', hex, `\n${ shouldBeWhite ? '⚪️' : '⚫️' } text on ${ hexName }`);
    return shouldBeWhite ? 'white' : 'black'
}

// This might belong in its own component instead !!!!!!!!
function token( key='', getEmoji=true, category='animals', i=null ) {
    const [ arr, attributes ] = [ tokenList( category, getEmoji ), key === null ? {} : { key } ]

    if ( i === null ) i = randomNum( arr.length, false, 0 )
    if ( !getEmoji ) attributes.className = `fa-solid fa-${ arr[ i ] } fw`

    return <i { ...attributes }>{ getEmoji ? arr[ i ] : '' }</i>
}

function tokenList( category='animals', getEmoji=true ) {
    const list = {
        animals: {
            emoji: '🐝 🐶 🐱 🐭 🐹 🐰 🦊 🐻 🐼 🐻‍❄️ 🐨 🐯 🦁 🐮 🐷 🐸 🐵 🐔 🐧 🐦 🐥 🦉 🦇 🦄 🐴 🐗 🐺 🐞 🐌 🦋 🐛 🐢 🦎 🐍 🐙 🦑 🦐 🦀 🦞 🐡 🐟 🐳 🐬 🐋 🦈 🦚 🐓 🦃 🦜 🦢 🦩 🕊️ 🦝 🐇 🦨 🦦 🦥 🐀 🐁 🐿️ 🐲 🦔'.split(' '),
            fontAwesome: [ 'hippo', 'otter', 'dog', 'cat', 'cow', 'fish', 'dragon', 'worm', 'spider', 'shrimp', 'mosquito', 'kiwi-bird', 'locust', 'horse-head', 'horse', 'frog', 'fish-fins', 'dove', 'crow' ],
        },
    };

    const iconType = getEmoji ? 'emoji' : 'fontAwesome';
    return list?.[ category ]?.[ iconType ] || list
}

function unit( board={}, course={} ) {
    const { units } = course.content;
    const unit = units?.find( unit => unit.boardNums.includes( board.num ) );

    // const flattenedUnitBoards = units?.map( unit => unit.blocks ? Object.values( unit.boards ).flat() : null );
    // console.log( flattenedUnitBoards );
    // if ( !flattenedUnitBoards ) return false

    // const i = flattenedUnitBoards.findIndex( boards => boards.includes( board.num ) );

    // console.log( 'BOARDS', flattenedUnitBoards, '\nI', i, '\nUNIT', units[ i ] );
    // const unit = units[ i ];
    unitBackgroundObject( unit );

    return unit
}

function unitBackgroundObject( unit={} ) {
    if ( typeof unit.background === 'string' ) {
        unit.background = { hex: unit.background, ...hslFromHex( unit.background ) }
        unit.background.h *= 1;
    }
}

function updateObjectDays( units=[], schoolYear={} ) {
    return courseInfo.add().unitDetails( units, schoolYear )
}

function updateObjectProps( themes={}, units=[], schoolYear={} ) {
    const themeKeys = Object.keys( themes );
    const keys = {
        backgrounds: 'background',
        blocks: 'blocks',
        titles: 'theme',
    };

    units.forEach( ( unit, i ) => themeKeys.forEach( key => {
        let [ unitKey, value ] = [ keys[ key ], themes[ key ][ i ] ];
        if ( key === keys.blocks ) value *= 1

        return unit[ unitKey ] = value
    } ) )

    return updateObjectDays( units, schoolYear )
}

function username( email='' ) {
    const delimeters = [ ' ', '.', '-', '_', '#' ];

    let emailPrefix = email.split( '@' )[ 0 ];
    emailPrefix = emailPrefix.split( '' ).map( char => delimeters.includes( char ) ? '_' : char ).join( '' );

    return emailPrefix
}

function USStates( getAbbr=true, getFull=false ) {
    const usStates = [
        { name: 'ALABAMA', abbreviation: 'AL'},
        { name: 'ALASKA', abbreviation: 'AK'},
        { name: 'AMERICAN SAMOA', abbreviation: 'AS'},
        { name: 'ARIZONA', abbreviation: 'AZ'},
        { name: 'ARKANSAS', abbreviation: 'AR'},
        { name: 'CALIFORNIA', abbreviation: 'CA'},
        { name: 'COLORADO', abbreviation: 'CO'},
        { name: 'CONNECTICUT', abbreviation: 'CT'},
        { name: 'DELAWARE', abbreviation: 'DE'},
        { name: 'DISTRICT OF COLUMBIA', abbreviation: 'DC'},
        { name: 'FEDERATED STATES OF MICRONESIA', abbreviation: 'FM'},
        { name: 'FLORIDA', abbreviation: 'FL'},
        { name: 'GEORGIA', abbreviation: 'GA'},
        { name: 'GUAM', abbreviation: 'GU'},
        { name: 'HAWAII', abbreviation: 'HI'},
        { name: 'IDAHO', abbreviation: 'ID'},
        { name: 'ILLINOIS', abbreviation: 'IL'},
        { name: 'INDIANA', abbreviation: 'IN'},
        { name: 'IOWA', abbreviation: 'IA'},
        { name: 'KANSAS', abbreviation: 'KS'},
        { name: 'KENTUCKY', abbreviation: 'KY'},
        { name: 'LOUISIANA', abbreviation: 'LA'},
        { name: 'MAINE', abbreviation: 'ME'},
        { name: 'MARSHALL ISLANDS', abbreviation: 'MH'},
        { name: 'MARYLAND', abbreviation: 'MD'},
        { name: 'MASSACHUSETTS', abbreviation: 'MA'},
        { name: 'MICHIGAN', abbreviation: 'MI'},
        { name: 'MINNESOTA', abbreviation: 'MN'},
        { name: 'MISSISSIPPI', abbreviation: 'MS'},
        { name: 'MISSOURI', abbreviation: 'MO'},
        { name: 'MONTANA', abbreviation: 'MT'},
        { name: 'NEBRASKA', abbreviation: 'NE'},
        { name: 'NEVADA', abbreviation: 'NV'},
        { name: 'NEW HAMPSHIRE', abbreviation: 'NH'},
        { name: 'NEW JERSEY', abbreviation: 'NJ'},
        { name: 'NEW MEXICO', abbreviation: 'NM'},
        { name: 'NEW YORK', abbreviation: 'NY'},
        { name: 'NORTH CAROLINA', abbreviation: 'NC'},
        { name: 'NORTH DAKOTA', abbreviation: 'ND'},
        { name: 'NORTHERN MARIANA ISLANDS', abbreviation: 'MP'},
        { name: 'OHIO', abbreviation: 'OH'},
        { name: 'OKLAHOMA', abbreviation: 'OK'},
        { name: 'OREGON', abbreviation: 'OR'},
        { name: 'PALAU', abbreviation: 'PW'},
        { name: 'PENNSYLVANIA', abbreviation: 'PA'},
        { name: 'PUERTO RICO', abbreviation: 'PR'},
        { name: 'RHODE ISLAND', abbreviation: 'RI'},
        { name: 'SOUTH CAROLINA', abbreviation: 'SC'},
        { name: 'SOUTH DAKOTA', abbreviation: 'SD'},
        { name: 'TENNESSEE', abbreviation: 'TN'},
        { name: 'TEXAS', abbreviation: 'TX'},
        { name: 'UTAH', abbreviation: 'UT'},
        { name: 'VERMONT', abbreviation: 'VT'},
        { name: 'VIRGIN ISLANDS', abbreviation: 'VI'},
        { name: 'VIRGINIA', abbreviation: 'VA'},
        { name: 'WASHINGTON', abbreviation: 'WA'},
        { name: 'WEST VIRGINIA', abbreviation: 'WV'},
        { name: 'WISCONSIN', abbreviation: 'WI'},
        { name: 'WYOMING', abbreviation: 'WY' }
    ];

    return usStates.map( state => getAbbr && getFull ? [ state.name, state.abbreviation ]
        : getFull ? state.name : state.abbreviation )
}

function weekdayName( weekDayNum=0 ) {
    switch ( weekDayNum ) {
        case 1: return 'monday';
        case 2: return 'tuesday';
        case 3: return 'wednesday';
        case 4: return 'thursday';
        case 5: return 'friday';
        case 6: return 'saturday';
        case 0: return 'sunday';

        default: return 'INVALID WEEKDAYNUM'
    }
}

function workerBeeSubmissions( boardNum=null, courseSubmissions=[], userEmail='', justCheckedOff=false ) {
    const userSubmissions = courseSubmissions.filter(
        submission => submission.who === userEmail && 'isChecked' in submission
    );

    let [ boardSubmissions, checked, unchecked ] = [
        userSubmissions.filter( submission => submission.what.includes( `board-${ boardNum }-` ) ), [], []
    ];

    for ( let i = boardSubmissions.length - 1; i >= 0; i-- ) {
        const submission = boardSubmissions[ i ];

        if ( !checked.includes( submission.what ) && !unchecked.includes( submission.what ) ) {
            submission.isChecked ? checked.push( submission.what ) : unchecked.push( submission.what );
        }
    }
    // console.log( '🟣', boardSubmissions, userSubmissions, checked, unchecked, justCheckedOff, boardNum, courseSubmissions, userEmail );

    return justCheckedOff ? checked : boardNum === null ? userSubmissions : boardSubmissions.map( e => e.what )
}

function yearMonthDayWeekday( year=1967, month=0, day=17 ) {
    const forToday = year === 1967;
    const date = forToday ? new Date() : new Date( year, month, day );
    return [ date.getFullYear(), date.getMonth(), date.getDate(), date.getDay() ]
}

const get = {
    alphabetiseObjArr,
    arrWithElementAtI,
    arrWithoutElementAtI,
    assignmentsArr,
    assignmentsByBoardNum,
    assignmentID,
    assignmentObj,
    assignmentTitle,
    boardAssignments,
    boardByBoardNum,
    boardByWeekNum,
    boardCompletion,
    boardNum,
    calendarMonths,
    capitalise,
    classNameFromArr,
    colourFromName,
    colourName,
    courseFromLevelAndSubject,
    currentWeekNum,
    date,
    dateOfWeekdayOfTheMonth,
    dateTime,
    dayMonthYearArr,
    dayMonthYearToday,
    defaultSyllabus,
    dueDayMonthYear,
    emptyAssignmentIndexInArr,
    hexFromHSL,
    holidayEmoji,
    hslFromHex,
    hslFromName,
    hslRange,
    hueRange,
    hueToRGB,
    isWithinRange,
    lastArrayElement,
    log,
    monthMaxDays,
    monthName,
    monthNumsInOrderArr,
    nextOrPreviousArrayElement,
    numRange,
    oddAndEvenDays,
    object,
    ordinalNum,
    ordinalSuffix,
    quarterWeekNums,
    randomHSL,
    randomNum,
    randomToken,
    reorderObject,
    rgbFromHex,
    schoolYearID,
    stringFromArray,
    stringInNewFormat,
    subjectIcon,
    textColour,
    token,
    tokenList,
    unit,
    unitBackgroundObject,
    updateObjectProps,
    username,
    USStates,
    weekdayName,
    workerBeeSubmissions,
    yearMonthDayWeekday,
};

export default get;