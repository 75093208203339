import { useContext, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { doc, setDoc } from 'firebase/firestore';
import { db } from '../../../firebase_setup/firebase';
import Nav from '../Nav';
import Button from '../Button';
import AttachmentContainer from '../../modular/Attachment/AttachmentContainer';
import UserContext from '../../../contexts/UserContext';
import ShopContext from '../../../contexts/ShopContext';
import ShopItemDetails from './Item/ShopItemDetails';
import ShopItemAssignments from './Item/ShopItemAssignments';
import SVG from '../../modular/Images/SVG';
import get from '../../../constants/get';
import styles from './ShopItem.module.css';

const [ tabs, placeholderLinks ] = [
    [ 'assignments', 'details', 'resources' ], [
        { url: 'sheets.google.com', name: 'unit vocab list' },
        { url: 'sheets.google.com', name: 'unit Q&A starters' },
        { url: 'slides.google.com', name: 'unit project' },
        { type: 'folder', name: 'unit notes' },
        { type: 'folder', name: 'unit lesson slides' },
    ]
];

const ShopItem = () => {
    const navigate = useNavigate();
    const { subject, level, unit, unitI, dbID } = useParams();
    const { courses: { queen: queenCourses }, counties, premium, email } = useContext( UserContext );
    const { units } = useContext( ShopContext );

    const [ activeTab, setActiveTab ] = useState( tabs[ 1 ] );
    const [ board, setBoard ] = useState({ assignments: [], num: -1, i: -1 });
    const [ unitObj, setUnitObj ] = useState( units[ unitI ] );

    useEffect( () => {
        if ( unitObj ) {
            const course = getCourse( queenCourses, dbID );
            const county = getCounty( counties, course );
            console.log( 'saving unit to the DB' );

            setDoc( doc(
                db,
                'units',
                unitObj.department,
                'subjects',
                subject,
                'level-' + level,
                unitObj.theme.toLocaleLowerCase().split( ' ' ).join( '-' ) + '-' + county.id
            ), unitObj, { merge: true } )
        }
    }, [ counties, dbID, level, subject, queenCourses, unitObj ] )

    const course = getCourse( queenCourses, dbID );
    const county = getCounty( counties, course );
    const { schoolYear, schools: { [ course.schoolID ]: school } } = county;
    const hsl = school?.colours?.[ 0 ] ? Object.fromEntries( school.colours.map(
        ( colour, i ) => [ i === 0 ? 'odd' : 'even', Object.fromEntries(
            Object.entries( get.hslFromName( colour, true ) ).map(
                varAndValue => [ '--' + varAndValue[ 0 ], varAndValue[ 1 ] ]
            )
        ) ]
    ) ) : { '--h': 45, '--s': '100%', '--l': '50%' };

    window.location.hostname === 'localhost' && console.log({ unitObj, activeTab, course, school, county, schoolYear, units, unit });

    // FUNCTIONS
    function changeTab({ target: { id: newTab } }) { newTab !== activeTab && setActiveTab( () => newTab ) }

    function saveToCourse() {
        const { background, ...unitObjCopy } = unitObj;
        unitObjCopy.background = { hex: background.hex };

        course.content.units.push( unitObj );
        navigate( [ '', get.username( email ), course.id.db, 'calendar' ].join( '/' ));
    }

    function updateUnitObj( newUnitObj, board ) {
        console.log({ newUnitObj });
        newUnitObj && setUnitObj( () => ({ ...newUnitObj }) )
        board && setBoard( () => ({ ...board }) )
    }

    // PROPS & ATTRIBUTES
    const props = activeTab === tabs[ 0 ] ? { board, course, hsl, premium, school, schoolYear, unitObj, updateUnitObj }
        : activeTab === tabs[ 1 ] ? { subject, unitObj, updateUnitObj }
        : { className: styles.attachments, links: placeholderLinks, stackVertically: true }

    // COMPONENTS
    const ActiveTabContent = activeTab === tabs[ 0 ] ? <ShopItemAssignments { ...props }/>
        : activeTab === tabs[ 1 ] ? <ShopItemDetails { ...props }/>
        : <AttachmentContainer { ...props }/>;

    const HeaderBanner = <header className={ styles[ 'unit-header' ] }>
        <h1>{ get.capitalise( unitObj.theme ) }</h1>
        <Button
            className={ styles[ 'add-to-course' ] }
            onClick={ saveToCourse }
            label={ <SVG type={{ 'add to group': true }} fontSize='2em'/>}
        />

        <p>{ get.username( email ) } || { get.capitalise( subject ) + ' ' + level }</p>
        <p className={ styles[ 'bolded-subtitle' ] }> {[
            unitObj.duration.boards, 'Boards', '|', unitObj.duration.classes, 'Classes'
        ].join(' ')} </p>
    </header>;

    const Tabs = tabs.map( tab => <Button
        className={ get.classNameFromArr([ styles.tab, activeTab === tab && styles.active ]) }
        backgroundGradientColours={ tab === activeTab && [
            `hsl( ${ ( hsl.odd || hsl )['--h'] }, ${ ( hsl.odd || hsl )['--s'] }, ${ ( hsl.odd || hsl )['--l'].split( '%' )[ 0 ] * .9 }% )`,
            `hsl( ${ ( hsl.odd || hsl )['--h'] }, ${ ( hsl.odd || hsl )['--s'] }, ${ ( hsl.odd || hsl )['--l'].split( '%' )[ 0 ] * 1.5 }% )`,
        ] }
        hoverColours={[
            `hsl( ${ ( hsl.odd || hsl )['--h'] }, ${ ( hsl.odd || hsl )['--s'] }, ${ ( hsl.odd || hsl )['--l'].split( '%' )[ 0 ] * 1.5 }% )`,
            `hsl( ${ ( hsl.odd || hsl )['--h'] }, ${ ( hsl.odd || hsl )['--s'] }, ${ ( hsl.odd || hsl )['--l'].split( '%' )[ 0 ] * 1.75 }% )`,
        ]}
        id={ tab }
        key={ [ tab, 'tab' ].join( '-' ) }
        label={ tab }
        onClick={ changeTab }
    /> );

    // RENDER
    return <article className={ styles.page } style={ hsl?.odd || hsl }>
        <Nav rightIcon={ <Button
            className={ styles[ 'back-button' ] }
            label='BACK'
            onClick={ () => navigate( -1 ) }
        >BACK</Button> }/>

        <section>
            { HeaderBanner }

            <div>
                <article className={ styles.path }></article>

                <article className={ styles.details }>
                    <header className={ styles.tabs }>{ Tabs }</header>
                    <section className={ styles.info }>{ ActiveTabContent }</section>
                </article>
            </div>
        </section>
    </article>
}

// OTHER FUNCTIONS
function getCounty( counties={}, course={} ) {
    return counties.length === 1 ? counties[ 0 ] : counties.find( countyObj => course.schoolID in countyObj.schools );
}

function getCourse( queenCourses=[], dbID='' ) {
    return queenCourses.find( courseObj => courseObj.id.db === dbID )
}

export default ShopItem;