import { useContext } from 'react';
import styles from './EditUnits.module.css';
import HiveContext from '../../../contexts/HiveContext';

const EditUnitsHeader = ({ usedBlocksArr=[] }) => {
    const { schoolYear: { totalDays: { even, odd } } } = useContext( HiveContext );
    // const { totalDays: { even, odd } } = schoolYear;

    let usedBlocks = usedBlocksArr.reduce( ( sum, num ) => sum + num, 0 );
    const blocks = {
        even: even - usedBlocks,
        odd: odd - usedBlocks
    };

    return <header className={ styles[ 'days-left' ] }>
        <h3>days left: <span>odd { blocks.odd }</span> || <span>{ blocks.even } even</span></h3>
    </header>
}

export default EditUnitsHeader;