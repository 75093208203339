import { useDroppable } from '@dnd-kit/core';
import styles from './Droppable.module.css';
import get from '../../../constants/get';

const Droppable = ({ Container, Directions, id='', isDisabled=false, isWrappingSortable=false }) => {
    const { isOver, setNodeRef } = useDroppable({ id, disabled: isDisabled });

    // PROPS & ATTRIBUTES
    const props = {
        className: get.classNameFromArr([
            styles.droppable,
            isOver && styles.over,
            isDisabled && styles.disabled,
            isWrappingSortable && styles[ 'wrapping-sortable' ]
        ]),
        ref: setNodeRef
    };

    // RENDER
    return <div { ...props }>{ Directions }{ Container }</div>
}

export default Droppable