import { createContext } from 'react';

const CalendarContext = createContext({
    changeMonth: null,
    getTodayDate: null,
});

const MonthContext = createContext({
    month: null,
});

const Contexts = {
    Calendar: CalendarContext,
    Month: MonthContext,
};

export default Contexts;