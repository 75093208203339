import get from '../../../constants/get';
import styles from './Button.module.css'

const Button = ({
    autoFocus = false,
    className = '',
    disabled = false,
    id = '',
    label = 'BUTTON',
    onClick = () => {},
    style = {},
    type = 'button',
}) => {
    const classNames = className.split(' ').map(
        className => className.includes('_') ? className : styles[className]
    );

    return (<button
        autoFocus={ autoFocus }
        className={ get.classNameFromArr([ styles.button, ...classNames]) }
        disabled={ disabled }
        id={ id }
        onClick={ onClick }
        style={ style }
        type={ type }
    >{ label }</button> )
}

export default Button;