import get from '../../../constants/get';
import BoardIcon from '../Images/BoardIcon';
import styles from './FillBoardButton.module.css';

const FillBoardButton = ({
    boardCompletion = { req: 0, prereq: 0 },
    board = {},
    handleTogglingModal = null,
    isLastWeekOfMonth = false,
    mergeBoards = null,
    mergedWith = false,
}) => {
    let [ isEmptyBoard, isHolidayWeek, isShortWeek, isMerged, isMergedUp ] = [
        getCompletionSum() === 0,
        handleTogglingModal === null,
        mergeBoards !== null,
        typeof mergedWith === 'number',
        board.num > mergedWith ? true : false,
    ];
    isMerged = board.isMergedWith;

    // FUNCTIONS
    function getCompletionSum() { return boardCompletion.req + boardCompletion.prereq }

    const getFillButtonContent = () => isMerged ? <i className={`fa-solid fa-caret-${ isMergedUp ? 'down' : 'up' }`}/>
        : isEmptyBoard ? SquarePlus : <BoardIcon completion={ boardCompletion } />

    const getMergeButtonContent = () => <span>
        { isMerged ? 'unmerge ' : 'merge ' }
        <i className='fa-solid fa-arrows-up-down'/>
    </span>

    // ATTRIBUTES
    const attributes = {
        buttonsDiv: {
            className: get.classNameFromArr([
                isMerged ? isMergedUp ? styles.up : styles.down : '',
                isHolidayWeek ? styles[ 'holiday-week' ] : '',
                styles.buttons,
                isShortWeek ? styles.three : styles.one,
                isMerged ? styles.merged : '',
                isLastWeekOfMonth ? styles[ 'last-week' ] : '',
            ]),
            style: { '--i': getCompletionSum() },
        },
        fillBoard: { className: styles[ 'fill-board' ] }
    };

    if ( !isHolidayWeek ) attributes.fillBoard.onClick = handleTogglingModal;
    if ( isShortWeek ) {
        attributes.merge = {
            up: { id: `board-${ board.num }-merge-up` },
            down: { id: `board-${ board.num }-merge-down` },
        };
        attributes.merge.up.onClick = attributes.merge.down.onClick = mergeBoards;
        attributes.merge.up.className = attributes.merge.down.className = styles.merge;
    }

    // COMPONENTS
    const SquarePlus = isHolidayWeek ? '' : <div className={ styles.plus }>
        { Array( 4 ).fill().map( ( e, i ) => <div
            className={ [ styles.cluster, styles[ `num${ i + 1 }` ] ].join(' ') }
            key={`cluster-${ i + 1 }`}
        >{ Array( 3 ).fill().map( ( f, j ) => <div
            className={ styles.square }
            key={`square-${ 3 * i + j }`}
        /> ) }</div> ) }
        <div className={ styles.cover } onClick={ attributes.fillBoard.onClick }/>
    </div>

    // RENDER
    return <div { ...attributes.buttonsDiv }>
        { isShortWeek && <button { ...attributes.merge.up }>{ getMergeButtonContent() }</button> }
        <button { ...attributes.fillBoard }>{ getFillButtonContent() }</button>
        { isShortWeek && <button { ...attributes.merge.down }>{ getMergeButtonContent() }</button> }
    </div>
}

export default FillBoardButton;