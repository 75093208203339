import { createContext } from "react";

const AccountContext = createContext({
    auth: null,
    callLogout: () => {},
    data: {},
    db: null,
    emailLogin: () => {},
    emailPasswordRecover: () => {},
    emailRegister: () => {},
    getAuthState: () => {},
    googleLogin: () => {},
    handleLogin: () => {},
    handleUserChange: () => {},
})

export default AccountContext;