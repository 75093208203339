import StrToJsx from '../StrToJsx';
import get from '../../../constants/get';
import styles from './Avatar.module.css';

const Avatar = ({ reviewDetails={}, className='', mini=false }) => {
    // PROPS & ATTRIBUTES
    const attributes = {
        figure: {}, img: {
            src: reviewDetails.img.src[ mini ? 'mini' : 'full' ],
            alt: reviewDetails.img.alt,
        }
    };

    attributes[ mini ? 'figure' : 'img' ].className = get.classNameFromArr([
        className, styles[ mini ? 'mini' : 'full' ]
    ]);

    // COMPONENTS
    const IMG = <img { ...attributes.img } alt={ attributes.img.alt }/>;

    // RENDER
    return mini ? <figure { ...attributes.figure }>
        <div className={ styles.wrapper }><img { ...attributes.img } alt={ attributes.img.alt }/></div>
        <figcaption>
            <span>{ reviewDetails.name }</span>
            <br />
            <span>{ StrToJsx( reviewDetails.credential ) }</span>
        </figcaption>
    </figure> : IMG
}

export default Avatar;