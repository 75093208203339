import { useState } from 'react';
import Button from '../../../pages/Button';
import styles from './Stepper.module.css';
import get from '../../../../constants/get';

const Step = ({
    BackButton = null,
    backButtonLabel = null,
    contentIsForm = false,
    isActive = true,
    lastStepI = 0,
    NextButton = null,
    nextButtonLabel = null,
    stepChildren,
    stepI = 0,
    toggleStep = () => {},
}) => {
    const [ formName, setFormName ] = useState( 'continue' );
    const [ isFirstStep, isLastStep ] = [ stepI === 0, stepI === lastStepI ];

    // FUNCTIONS
    const getButton = ( forGoingBack=false, label ) => {
        const props = {
            label,
            id: ( isLastStep && !forGoingBack ) ? 'submit' : `to-${ getITo( forGoingBack ) }`,
            className: get.classNameFromArr([
                styles.button,
                forGoingBack ? styles.back : styles.next,
                isLastStep ? styles.last : isFirstStep ? styles.first : null
            ]),
        };

        if ( !contentIsForm ) props.onClick = toggleStep
        if ( contentIsForm ) {
            props.isInput = true;
            props.inputValue = label;
            props.onClick = toggleFormName;
        }

        if ( forGoingBack ) {
            if ( isFirstStep ) props.disabled = true
            props.looksLikeLink = true;
        }
        if ( !forGoingBack ) {
            props.backgroundGradientColours = props.boxShadowColours = [ 'orange', 'gold' ];
            props.hoverColours = [ props.backgroundGradientColours[ 0 ] ];
            props.hoverTextColour = props.labelColour = 'white';
        }

        return <Button { ...props }/> // activeLabelColour=''
    }

    const getITo = ( forGoingBack=false ) => {
        let newI = stepI + ( forGoingBack ? -1 : 1 );

        return newI < 0 ? lastStepI
            : newI > lastStepI ? 0 : newI
    }

    const toggleFormName = ({ target: { value } }) => setFormName( () => value.toLocaleLowerCase() )

    // COMPONENTS
    if ( BackButton === null ) BackButton = !isFirstStep && getButton( true, backButtonLabel || 'Back' )
    if ( NextButton === null ) NextButton = getButton(
        false, nextButtonLabel || ( isLastStep ? 'Finish' : 'Continue' )
    )

    const Buttons = <fieldset className={ styles.buttons }>
        { BackButton }
        { NextButton }
    </fieldset>;

    const Children = contentIsForm ? <form
        id={ `form-${ isLastStep ? 'final-' : '' }step-${ stepI + 1 }` }
        onSubmit={ toggleStep }
        className={ styles.form }
        name={ formName }
    >
        { stepChildren }
        { Buttons }
    </form> : stepChildren;

    // RENDER
    return <div className={ styles.step }>{ Children }</div>
}

export default Step;