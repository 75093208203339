import { useContext, useState } from 'react';
import Assignment from './Assignment';
import Section from './Section';
import styles from './Bar.module.css';
import { Link } from 'react-router-dom';
import get from '../../../constants/get';
import UserContext from '../../../contexts/UserContext';

const Bar = ({
    id = '',
    details = {},
    isOverlay = false,
    isExpandable = false,
    toggleForm = () => {}
}) => {
    const { email } = useContext( UserContext );

    const [ isExpanded, setIsExpanded ] = useState( isOverlay );
    const [ courseName, maxI ] = [
        details.title,
        details.sections.all.length
    ];
    putDetailsInOrder();

    // FUNCTIONS
    function expandBar() { setIsExpanded( isExpanded => !isExpanded ) }

    const generateSections = ( liArr=[], i=1 ) => i > maxI ? liArr : generateSections([
        ...liArr, <Section
            num={ details.sections.all[ i - 1 ] }
            key={`li-at-i-${ i }`}
            className={ styles.section }
            courseId={ id }
        />
    ], i + 1 )

    function putDetailsInOrder() {
        return details.sections.all.sort( ( a, b ) => a > b ? 1
            : a < b ? -1 : 0
    ) }

    // CONTEXTS, PROPS, & ATTRIBUTES
    const attributes = {
        article: {
            className: styles.content,
            id: id,
        },
        button: {
            className: isExpanded ? styles[ 'is-expanded' ] : '',
            onClick: expandBar,
        },
        div: {
            className: get.classNameFromArr([
                styles.bar,
                styles[ isExpanded ? 'expanded' : 'collapsed' ]
            ]),
        },
    };

    // COMPONENTS
    const AssignmentsSection = <section className={ styles.assignments }><ul>
        { details.assignments.map( ( assignment, i ) => <li key={`assignment-#${ i }`}>
            <Assignment details={ assignment }/>
        </li> ) }
    </ul></section>;

    const ExpandButton = <button { ...attributes.button }><i className={'fa-solid fa-caret-right'}/></button>

    const Sections = <section className={ styles.sections }><ul>
        { generateSections([ <Section
            num={ -1 }
            courseId={ id }
            key='li-at-i-0'
            toggleForm={ toggleForm }
            className={ styles.section }
        /> ]) }
    </ul></section>;

    // RENDER
    return <article { ...attributes.article }>
        <div { ...attributes.div }>
            { isExpandable && !isOverlay && ExpandButton }

            <header className={ styles.header }>
                <div className={ styles.title }>
                    <Link to={`/${ get.username( email ) }/${ courseName }/`}><h2>{ details.title }</h2></Link>
                </div>
                { isExpanded && Sections }
            </header>

            { isExpanded ? AssignmentsSection : Sections }
        </div>
    </article>
}

export default Bar;