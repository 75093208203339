import { useState } from 'react';
import get from '../../../../../constants/get';
import SortableWrapper from '../../../../modular/DragAndDrop/SortableWrapper';
import SVG from '../../../../modular/Images/SVG';
// import Button from '../../../Button';
import AssignmentBar from '../AssignmentBar';
import styles from './AssignmentTypeSection.module.css';

const AssignmentTypeSection = ({
    activeUnitBoardNums = [],
    assignments = [],
    boardNum = 0,
    isForDarkBoxes = false,
    toggleMode = () => {},
    unit = {}
}) => {
    // eslint-disable-next-line
    const [ sectionAssignments, setSectionAssignments ] = useState( assignments );
    // const filteredAssignments = sectionAssignments.filter( assignment => assignment.directions );
    // const maxAssignments = isForDarkBoxes ? 2 : 6;
    // const firstEmptyAssignmentI = sectionAssignments.findIndex( assignment => !assignment.directions );

    // FUNCTIONS
    // const getPlaceholderButton = ( id='' ) => <Button
    //     className={ styles[ 'placeholder-button' ] }
    //     id={`activate-${ id }-edit`}
    //     label={<>
    //         <SVG fill='black' fontSize='1rem' type='add'/>&nbsp;
    //         ADD { isForDarkBoxes ? 'TEACHER' : 'STUDENT' } CHOICE
    //     </>}
    //     looksLikeLink
    //     onClick={ toggleMode }
    // />

    // PROPS & ATTRIBUTES
    const props = {
        section: {
            className: get.classNameFromArr([
                styles.section,
                isForDarkBoxes ? styles[ 'dark-boxes' ] : styles[ 'light-boxes' ]
            ]),
            style: {
                '--h': unit?.background?.h,
                '--s': unit?.background?.s,
                '--l': unit?.background?.l,
            },
        },
        SortableWrapper: {
            ButtonOnClick: toggleMode,
            ButtonSVG: <SVG fontSize='1rem' type={{ add: true }}/>,
            ChildrenClassNames: sectionAssignments.map(
                assignment => assignment.directions ? styles.sortable : styles[ 'placeholder-bar' ]
            ),
            // ComponentWhenEmpty: filteredAssignments.length < maxAssignments ? getPlaceholderButton() : null,
            // placeholderClassName: styles.empty,
            header: isForDarkBoxes ? `Teacher Choice ${ boardNum }` : `Student Choice ${ boardNum }`,
            handlePosition: { left: true },
            items: sectionAssignments.map( assignment => 'inContainer'
                + assignment.id[ 0 ].toLocaleUpperCase()
                + assignment.id.slice( 1 )
            ),
            maxCapacity: isForDarkBoxes ? 2 : 6,
            sortableTypeWithArticle: `an assignment (${ isForDarkBoxes ? 'req' : 'choice' })`,
            // sortableTypeWithArticle: `a ${ isForDarkBoxes ? 'required' : 'choice' } assignment`,
            sortingStrategy: { vertical: true }
        },
        WrapperChildren: {
            activeUnitBoardNums, toggleMode,
            className: styles.bar,
            idPrefix: isForDarkBoxes ? 'requiredBar' : 'choiceBar',
        },
    };

    props.SortableWrapper.wrapInDroppable = props.WrapperChildren.showDragHandle = props.WrapperChildren.showEditButton = props.SortableWrapper.generatePlaceholderWhenNotFull = true;

    // COMPONENTS
    props.SortableWrapper.Children = sectionAssignments.map(
        ( assignment, i ) => !!assignment.directions && <AssignmentBar
            { ...props.WrapperChildren }
            assignment={ assignment }
            key={ [ 'sortableBoard', boardNum, 'assignment', i ].join( '-' ) }
        />
    );

    // RENDER
    return <section { ...props.section }>
        <SortableWrapper { ...props.SortableWrapper }/>
    </section>
}

export default AssignmentTypeSection;