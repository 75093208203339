import { createContext } from "react";

const ShopContext = createContext({
    courses: [],
    counties: [],
    getRelevantCoursesArr: ( filterKey='', filterValue=[] ) => {},
    languages: [],
    languageUnits: [],
    relevantCoursesArr: [],
    units: [],
    username: '',
})

export default ShopContext;