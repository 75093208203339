import { useEffect, useState } from 'react';
import get from '../../constants/get';
import Button from './Button';
import styles from './TextInputButton.module.css';

function getHex( value, isObject=false, isHSLString=false ) {
    const hex = !value ? '#edd149'
    : isObject ? get.hexFromHSL( ...Object.values( value ) )
    : isHSLString ? get.hexFromHSL(
        ...value.split( '(' )[ 1 ].split( ')' )[ 0 ].split( ',' ).map(
            e => ( e.endsWith( '%' ) ? e.split( '%' )[ 0 ] : e ).trim() * 1
        )
    ) : value;

    // console.log( '🎨 VALUE:', value, '\n🎨 HEX:', hex );
    return hex
}

const TextInputButton = ({
    autocomplete = false,
    buttonLabel = '',
    className = '',
    controlledValue = null,
    fontSize = '1em',
    inputIcon = '',
    inputID = '',
    inputLabel = '',
    inputLabelTransform = { hide: false, toTop: false },
    inputType = 'text',
    isDisabled = false,
    isRequired = false,
    passValueUp = null,
    fillAllBorders = false,
    uncontrolledValue = null,
}) => {
    const [ value, setValue ] = useState(
        controlledValue !== null ? controlledValue
            : uncontrolledValue !== null ? uncontrolledValue
            : inputType === 'number' ? 0 : ''
    );

    useEffect( () => {
        if ( inputType === 'color' ) setValue( oldValue => getHex(
            oldValue,
            typeof oldValue !== 'string',
            typeof oldValue === 'string' && oldValue.startsWith( '#' ) === false
        ) )
    }, [ inputType ] )

    useEffect( () => {
        controlledValue && setValue( () => controlledValue )
    }, [ controlledValue ] )

    const showButton = !!buttonLabel;
    // console.log( uncontrolledValue, controlledValue, inputID, inputType );
    // inputType === 'color' && console.log( value );
    // console.log( value );


    // FUNCTIONS
    const handleTyping = e => {
        if ( controlledValue !== null && passValueUp !== null ) {
            passValueUp( e );
        } else {
            let { value } = e.target;
            setValue( () => inputType === 'number' ? value * 1 : value );
        }
    }

    const submit = e => {
        e.preventDefault();
        const elementWithValue = Array.from( e.target.elements ).filter( e => e.value );
        window.location.hostname === 'localhost' && console.log( elementWithValue );

        if ( passValueUp !== null ) passValueUp( elementWithValue )
    }

    const wrapInForm = ( Children ) => <form
        className={ get.classNameFromArr([ className, showButton ? styles.form : styles.fieldset ]) }
        onSubmit={ submit }
    >{ Children }</form>

    // PROPS & ATTRIBUTES
    const ButtonProps = { type: 'submit', label: buttonLabel };
    const fieldsetAttributes = {
        label: {}, input: {
            // defaultValue: value,
            disabled: isDisabled,
            onChange: handleTyping,
            required: isRequired,
            style: { fontSize },
            type: inputType,
        },
    };

    if ( !autocomplete ) fieldsetAttributes.input.autoComplete = 'off'
    if ( inputType === 'color' ) {
        const { h, s, l } = value?.s ? value : get.hslFromHex( value );

        fieldsetAttributes.input.style[ '--h' ] = h;
        fieldsetAttributes.input.style[ '--s' ] = s;
        fieldsetAttributes.input.style[ '--l' ] = l;
        fieldsetAttributes.input.value = value;
        // fieldsetAttributes.input.onChange = e => { console.log( e ) };
    } else { fieldsetAttributes.input.defaultValue = value }

    fieldsetAttributes.input.id = fieldsetAttributes.input.name = fieldsetAttributes.label.htmlFor = inputID;

    // COMPONENTS
    const Fieldset = <fieldset className={ get.classNameFromArr([
        styles[ 'input-fieldset' ],
        !showButton && className,
        inputIcon && styles[ 'with-icon' ],
        inputType === 'color' && styles.colour,
        fillAllBorders && styles[ 'fill-all-borders' ],
        ( value || controlledValue || value === 0 || controlledValue === 0 ) && styles[ 'has-value' ],
    ]) }>
        { inputIcon && <div className={ styles.icon }>{ inputIcon }</div> }
        <input { ...fieldsetAttributes.input }/>
        { inputLabel && <label { ...fieldsetAttributes.label }>{ inputLabel }</label> }
    </fieldset>;

    // RENDER
    return showButton ? wrapInForm( <>{ Fieldset }<Button { ...ButtonProps }/></> ) : Fieldset
}

export default TextInputButton;