import get from '../../constants/get';
import styles from './ToolTip.module.css';

const ToolTip = ({ arrowDirection='left', hasArrow=true, onClick=null, pElement, style={} }) => {
    // PROPS & ATTRIBUTES
    const attributes = {
        style, className: get.classNameFromArr([
            hasArrow ? styles.arrow : '',
            styles.tooltip,
            arrowDirection !== '' ? styles[ arrowDirection ] : styles.down
        ]),
    };
    if ( onClick !== null ) attributes.onClick = onClick

    // RENDER
    return <span { ...attributes }>{ pElement.props.children }</span>
}

export default ToolTip;