import { GoogleLogin } from '@react-oauth/google';
import { useContext, useState } from 'react';
import AccountContext from '../../../contexts/AccountContext';
import Button from '../Button';
import styles from './SignInButton.module.css';
import TextInput from '../../modular/Form/Parts/TextInput';
import SVG from '../../modular/Images/SVG';

const SignInButton = ({ isGoogle=false, isMobile=false, toCreateAccount=false }) => {
    const { handleLogin } = useContext( AccountContext );
    const [ showInput, setShowInput ] = useState();

    // FUNCTIONS
    const loginWithGoogle = e => handleLogin( e.credential )

    const sendLink = e => {
        e.preventDefault();
        const email = Array.from( e.target.elements ).filter( element => element.value )[ 0 ].value;
        // console.log( '📧', email );
        handleLogin( null, email );
    }

    const toggleInput = () => setShowInput( showInput => !showInput )

    // COMPONENTS
    const PaperPlane = <p className={ styles[ 'paper-plane' ] }>
        <span className={ styles.label }>send</span>
        <SVG type={{ 'paper plane': true }} fill1='orange'/>
    </p>;

    const EmailLabel = <p className={ styles.label }>Sign { toCreateAccount ? 'up' : 'in' } with email</p>

    // PROPS & ATTRIBUTES
    const props = { ux_mode: isMobile ? 'redirect' : 'popup' };
    props.cancel_on_tap_outside = props.itp_support = props.auto_select = props.useOneTap = true;
    // isGoogle && console.log( props );

    // RENDER
    return isGoogle ? <GoogleLogin
        { ...props }
        click_listener={ e => window.location.hostname === 'localhost' && console.log( e ) }
        onError={ () => { window.location.hostname === 'localhost' && console.log( 'login failed' ) } }
        onSuccess={ loginWithGoogle }
        shape='pill'
        size='large'
        text='continue_with'
        theme='outline'
        type='standard'
    /> : showInput ? <form
        className={ styles.form }
        onSubmit={ sendLink }
    >
        <TextInput
            className={ styles.input }
            label='email'
            transformLabel='to-top'
        />
        <Button
            className={ styles.submit }
            label={ PaperPlane }
            looksLikeLink
            type='submit'
        />
    </form> : <Button
        className={ styles.email }
        onClick={ toggleInput }
        label={ EmailLabel }
        looksLikeLink
    />
}

export default SignInButton;